import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Col, Row, Form, Schema, Loader, Panel, RadioGroup, Radio } from 'rsuite';
import { useSelector } from 'react-redux';
import api from '~/services/api';
import history from '~/services/history';
import { ButtonFullBlue } from '../../../components/Buttons/ButtonFullBlue/styles';

import { Container } from './styles';

import CamposDadosEstabelecimento from '../components/CamposDadosEstabelecimento';
import CamposEndereco from '../components/CamposEnderecoCriacao';
import CamposDadosFuncionamento from '../components/CamposDadosFuncionamento';
import CampoObservacoesEComentarios from '../components/CampoObservacoesEComentarios';

const { StringType, NumberType, ArrayType } = Schema.Types;

const modelo = Schema.Model({
  nome_fantasia: StringType().isRequired('Nome Fantasia obrigatório'),
  pessoa_responsavel: StringType().isRequired('Pessoa responsável obrigatório'),
  email: StringType().isEmail('Email inválido').isRequired('Email obrigatório'),
  senha: StringType()
    .minLength(6, 'Senha muito pequena')
    .isRequired('Senha obrigatório'),
  confirmarSenha: StringType()
    .addRule((valor, campos) => {
      if (valor !== campos.senha) {
        return false;
      }
      return true;
    }, 'Senhas precisam ser iguais')
    .isRequired('Confirmar Senha obrigatória'),
  cpf_cnpj: StringType().isRequired('CPF/CNPJ obrigatório'),
  telefone: StringType()
    .minLength(14, 'Telefone inválido')
    .isRequired('Telefone obrigatório'),
  // foto_perfil: StringType().isRequired('Foto perfil obrigatória'),
  logradouro: StringType().isRequired('Logradouro obrigatório'),
  cep: StringType().isRequired('Cep obrigatorio'),
  numero: NumberType('Número inválido'),
  complemento: StringType(),
  referencia: StringType(),
  bairro: StringType().isRequired('Bairro obrigatório'),
  cidade: StringType().isRequired('Cidade obrigatório'),
  estado: StringType().isRequired('Estado obrigatório'),
  uf: StringType().isRequired('Uf obrigatório'),
  qtd_funcionarios: StringType(),
  tipo_estabelecimento: NumberType().isRequired('Preferência obrigatória'),
  dias_funcionamento: ArrayType().of(NumberType()).isRequired('Dias de funcionamento obrigatório'),
  periodos_funcionamento: ArrayType().of(NumberType()).isRequired('Períodos de funcionamento obrigatório'),
  profissoes: ArrayType().of(NumberType()).isRequired('Serviços obrigatório'),
  obs: StringType(),
});

function CriarEstabelecimento() {
  const loggeduser = useSelector((state) => state.setUser.data);

  const [carregando, setCarregando] = useState(false);
  const [dadosFuncionamento, setDadosFuncionamento] = useState({
    periodoFuncionamento: [],
    diasFuncionamento: [],
    tiposEstabelecimentos: [],
    profissoes: [],
    habilidades: [],
  })
  const [filtroNumero, setFiltroNumero] = useState(false)
  const [qtdColaborador, setQtdColaborador] = useState('')
  const [camposInput, setCamposInput] = useState({});
  const [logo, setLogo] = useState();
  const [cardapio, setCardapio] = useState();
  let erro = {};
  const [profissoes, setProfissoes] = useState([]);
  const [filtroRadioRefeicao, setFiltroRadioRefeicao] = useState('');

  function exibirMensagemErro() {
    Object.values(erro).forEach((e) => {
      toast.error(e);
    });
  }

  async function salvarUsuario() {
    const habilidadesFormatadas = []
    dadosFuncionamento.habilidades.map((servico) => {
      return servico.map((hab) => habilidadesFormatadas.push({
        id_profissao: hab.profissao.id,
        habilidade: hab.habilidade
      }))
    })
    setCarregando(true);
    try {
      if (logo) {
        const formData = new FormData();
        formData.append('logo', logo);
        const respostaDocumento = await api.post(
          'logo-estabelecimento',
          formData
        );
        camposInput.logo = respostaDocumento.data.id;
      }
      if (cardapio) {
        const formData = new FormData();
        formData.append('cardapio', cardapio);
        const respostaDocumento = await api.post('cardapio', formData);
        camposInput.cardapio = respostaDocumento.data.id;
      }
      const profissoesId = dadosFuncionamento.profissoes.map((profissao) => profissao.id)
      const body = {
        nome_fantasia: camposInput.nome_fantasia,
        pessoa_responsavel: camposInput.pessoa_responsavel,
        razao_social: '',
        email: camposInput.email,
        senha: camposInput.senha,
        cpf_cnpj: camposInput.cpf_cnpj,
        telefone: camposInput.telefone,
        logradouro: camposInput.logradouro,
        logo: camposInput.logo,
        cardapio: camposInput.cardapio,
        refeicao: filtroRadioRefeicao,
        endereco: {
          logradouro: camposInput.logradouro,
          cep: camposInput.cep,
          numero: filtroNumero === true ? 0 : camposInput.numero,
          complemento: camposInput.complemento,
          referencia: camposInput.referencia,
          bairro: {
            nome: camposInput.bairro,
            cidade: {
              nome: camposInput.cidade,
              estado: {
                uf: camposInput.uf,
                nome: camposInput.estado,
              },
            },
          },
        },
        tipo_estabelecimento: [dadosFuncionamento.tiposEstabelecimentos],
        dias_funcionamento: dadosFuncionamento.diasFuncionamento,
        qtd_funcionarios: qtdColaborador,
        id_descobriu_switch: camposInput.id_descobriu_switch,
        periodos_funcionamento: dadosFuncionamento.periodoFuncionamento,
        profissoes: profissoesId,
        customerSkills: habilidadesFormatadas,
        habilidades_outro: [],
        obs: camposInput.obs,
      };
      const resposta = await api.post(
        `estabelecimento/${loggeduser && loggeduser.id}`,
        body
      );
      toast.success('Cliente criado com sucesso');
      history.push(`/home/estabelecimentos/editar/${resposta.data.id}`);
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
  }
  const marginPersonalizada = { marginTop: '20px' }

  function test(){
      console.log(qtdColaborador)
  }

  return (
    <Container>
      <Form
        model={modelo}
        onChange={setCamposInput}
        formValue={camposInput}
        onSubmit={(semErro) =>
          semErro ? salvarUsuario() : exibirMensagemErro()
        }
        onError={(err) => {
          erro = err;
        }}
        layout="horizontal"
      >
        <CamposDadosEstabelecimento
          marginPersonalizada={marginPersonalizada}
          qtdColaborador={qtdColaborador}
          setQtdColaborador={setQtdColaborador}
          camposInput={camposInput}
          logoAlterada={setLogo}
          cardapioAlterado={setCardapio}
        />

        <CamposEndereco
          camposInput={camposInput}
          atualizarCampos={(camposEndereco) => {
            setCamposInput({ ...camposInput, ...camposEndereco });
          }}
          setFiltroNumero={setFiltroNumero}
          filtroNumero={filtroNumero}
        />
        <Row>
              <Col md={12} sm={24} xs={24}>
                <Panel
                  header={<h4>Refeição</h4>}
                  eventKey="123"
                  bordered
                  collapsible
                  defaultExpanded
                >
                  <RadioGroup
                    onChange={(value) => {
                      setFiltroRadioRefeicao(value);
                    }}
                    value={filtroRadioRefeicao}
                    inline
                  // defaultValue="nao_informado"
                  >
                    <Radio style={{ marginRight: '-10px', fontSize: '12px' }} value="sim">Sim</Radio>
                    <Radio style={{ fontSize: '12px' }} value="nao">Não</Radio>
                    <Radio style={{ fontSize: '12px' }} value="nao_informado">Não informado</Radio>
                  </RadioGroup>
                </Panel>
              </Col>
            </Row>

        <CamposDadosFuncionamento
        dadosFuncionamento={dadosFuncionamento}
        setDadosFuncionamento={setDadosFuncionamento}
        />
        <CampoObservacoesEComentarios
          camposInput={camposInput}
          atualizarCampos={(camposObs) => {
            setCamposInput({ ...camposInput, ...camposObs });
          }}

        />

        <Row style={{ margin: '0', marginBottom: '5px' }}>
          <Col sm={6} />
          <Col sm={12}>
            <ButtonFullBlue
              appearance="primary"
              type="submit"
              size="lg"
              loading={carregando}
              block
            >
              Salvar
            </ButtonFullBlue>
          </Col>
          <Col sm={6} />
        </Row>
      </Form>
      {carregando && <Loader backdrop center />}
    </Container>
  );
}

export default CriarEstabelecimento;
