/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import { Plus, RemindFill } from '@rsuite/icons';

import {
  Table,
  Button,
  FlexboxGrid,
  Modal,
  Form,
  IconButton,
  Schema,
  SelectPicker,
} from 'rsuite';
import { toast } from 'react-toastify';

import { Container } from '~/styles/tabela';

import api from '~/services/api';
import {
  CelulaEditavel,
  CelulaAcao,
} from '~/components/Celulas/components/CelulaEditavel';
import { TabelaSemPaginacao } from '~/components/TabelaSemPaginacao';
import SearchTable from '../components/SearchTable';
import { ButtonFullBlue } from '../../../components/Buttons/ButtonFullBlue/styles';

const { Cell } = Table;

const { HeaderCell, Column } = Table;

const { StringType, NumberType } = Schema.Types;

const modelo = Schema.Model({
  treinamento: StringType().isRequired('Treinamento Obrigatório'),
  id_profissao: NumberType().isRequired('ID Serviço Obrigatório'),
});

function Treinamentos() {
  const [dados, setDados] = useState({
    treinamentosLista: [],
    treinamentosListaFiltrada: [],
    profissaoLista: [],
    filtro: '',
    objeto: {},
  });
  const [apagarTreinamentoDiaLog, setapagarTreinamentoDiaLog] = useState({
    exibir: false,
    treinamento: {},
  });
  const [carregando, setCarregando] = useState(true);
  const [adicionarTreinamentoDialog, setadicionarTreinamentoDialog] =
    useState(false);
  const [editarTreinamentoDialog, setEditarTreinamentoDialog] = useState(false);
  const [camposInput, setCamposInput] = useState({});

  async function buscarTreinamentos() {
    const profissao = await api.get('profissao');
    const resposta = await api.get('treinamento');
    const resposta1 = await api.get('treinamento');
    const lista = resposta.data.map((t) => ({
      ...t,
      id_profissao: t.profissao ? t.profissao.id : null,
      profissaoNome: t.profissao ? t.profissao.profissao : null,
    }));
    setDados({
      treinamentosLista: lista,
      treinamentosListaFiltrada: lista,
      listaInicial: resposta1.data,
      profissaoLista: profissao.data,
    });
    setCarregando(false);
  }

  useEffect(() => {
    buscarTreinamentos();
  }, []);

  function filtrarLista(texto) {
    setDados({
      ...dados,
      treinamentosListaFiltrada: dados.treinamentosLista.filter((treinamento) =>
        treinamento.treinamento
          .toString()
          .toUpperCase()
          .includes(texto ? texto.toUpperCase() : '')
      ),
      filtro: texto,
    });
  }

  function abrirApagarDialog(treinamento) {
    setapagarTreinamentoDiaLog({
      exibir: true,
      treinamento,
    });
  }

  function fecharApagarDialog() {
    setapagarTreinamentoDiaLog({
      exibir: false,
      treinamento: {},
    });
  }

  async function excluirTreinamento() {
    try {
      await api.delete(
        `/treinamento/${apagarTreinamentoDiaLog.treinamento.id}`
      );
      toast.success('Treinamento excluido com sucesso');
      fecharApagarDialog();
      buscarTreinamentos();
    } catch (e) {
      toast.error(e.message);
    }
  }

  async function adicionarTreinamento() {
    try {
      setCarregando(true);
      await api.post(`/treinamento`, camposInput);
      toast.success('Treinamento criado com sucesso');
      setadicionarTreinamentoDialog(false);
      buscarTreinamentos();
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
  }

  async function editarTreinamento() {
    try {
      setCarregando(true);
      await api.put(`/treinamento/${camposInput.id}`, camposInput);
      toast.success('Treinamento editado com sucesso');
      setEditarTreinamentoDialog(false);
      buscarTreinamentos();
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
  }

  function alterarTextoCelula(id, key, value) {
    const nextData = Object.assign([], dados.treinamentosLista);
    nextData.find((item) => item.id === id)[key] = value;
    setDados({ ...dados, treinamentosLista: nextData });
    filtrarLista(dados.filtro);
  }

  // async function alterarEstadoCelula(id) {
  //   const novaLista = Object.assign([], dados.treinamentosLista);
  //   const item = novaLista.find((treinamento) => treinamento.id === id);
  //   const itemIncial = dados.listaInicial.find(
  //     (treinamento) => treinamento.id === id
  //   );
  //   if (item.status === 'EDIT' && item.treinamento !== itemIncial.treinamento) {
  //     try {
  //       setCarregando(true);
  //       await api.put(`treinamento/${id}`, item);
  //       toast.success('Treinamento atualizado com sucesso');
  //       buscarTreinamentos();
  //     } catch (error) {
  //       toast.error(error.message);
  //     }
  //     setCarregando(false);
  //   } else {
  //     item.status = item.status ? null : 'EDIT';
  //     setDados({ ...dados, treinamentosLista: novaLista });
  //     filtrarLista(dados.filtro);
  //   }
  // }

  return (
    <Container>
      <h2>Treinamentos</h2>
      <FlexboxGrid justify="start" style={{ gap: '10px' }}>
        <SearchTable estilo={{ width: '300px' }} filtrarLista={filtrarLista} />
        <IconButton
          style={{ marginTop: '0' }}
          icon={<Plus />}
          onClick={() => {
            setadicionarTreinamentoDialog(true);
          }}
        />
      </FlexboxGrid>
      <FlexboxGrid style={{ width: '100%' }}>
        <TabelaSemPaginacao
          carregando={carregando}
          dados={dados.treinamentosListaFiltrada}
        >
          <Column resizable width={240} sortable>
            <HeaderCell>Nome</HeaderCell>
            <CelulaEditavel dataKey="treinamento" onChange={alterarTextoCelula} />
          </Column>

          <Column resizable width={240} sortable>
            <HeaderCell>Profissão</HeaderCell>
            <Cell dataKey="profissaoNome" />
          </Column>

          <Column resizable width={200}>
            <HeaderCell>Editar</HeaderCell>
            <CelulaAcao
              dataKey="id"
              onClick={(id) => {
                const treinamento = dados.treinamentosLista.find(
                  (t) => t.id === id
                );
                setCamposInput(treinamento);
                setEditarTreinamentoDialog(true);
              }}
              onDelete={abrirApagarDialog}
            />
          </Column>
        </TabelaSemPaginacao>
      </FlexboxGrid>
      <Modal
        backdrop="static"
        open={editarTreinamentoDialog}
        onClose={() => setEditarTreinamentoDialog(false)}
        size="xs"
      >
        <Form
          onChange={(campos) => setCamposInput({ ...camposInput, ...campos })}
          onSubmit={(status) => status && editarTreinamento()}
        >
          <Modal.Header>
            <h3>Editar Treinamento</h3>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.ControlLabel>Treinamento</Form.ControlLabel>
              <Form.Control
                name="treinamento"
                value={camposInput.treinamento}
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>ID Serviço</Form.ControlLabel>
              <Form.Control
                name="id_profissao"
                accepter={SelectPicker}
                placeholder="Selecione"
                data={dados.profissaoLista.map((p) => ({
                  label: p.profissao,
                  value: p.id,
                }))}
                value={camposInput.id_profissao}
                style={{ width: '300px' }}
              />
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button
              onClick={() => setEditarTreinamentoDialog(false)}
              appearance="subtle"
            >
              Cancelar
            </Button>
            <ButtonFullBlue type="submit" appearance="primary">
              Editar
            </ButtonFullBlue>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        backdrop="static"
        open={adicionarTreinamentoDialog}
        onClose={() => setadicionarTreinamentoDialog(false)}
        size="xs"
      >
        <Form
          model={modelo}
          onChange={setCamposInput}
          onSubmit={(status) => status && adicionarTreinamento()}
        >
          <Modal.Header>
            <h3>Adicionar Treinamento</h3>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.ControlLabel>Treinamento</Form.ControlLabel>
              <Form.Control name="treinamento" />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>ID Serviço</Form.ControlLabel>
              <Form.Control
                name="id_profissao"
                accepter={SelectPicker}
                placeholder="Selecione"
                data={dados.profissaoLista.map((p) => ({
                  label: p.profissao,
                  value: p.id,
                }))}
                style={{ width: '300px' }}
              />
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button
              onClick={() => setadicionarTreinamentoDialog(false)}
              appearance="subtle"
            >
              Cancelar
            </Button>
            <ButtonFullBlue type="submit" appearance="primary">
              Adicionar
            </ButtonFullBlue>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        backdrop="static"
        open={apagarTreinamentoDiaLog.exibir}
        onClose={fecharApagarDialog}
        size="xs"
      >
        <Modal.Header>
          <h3>Apagar Treinamento</h3>
        </Modal.Header>
        <Modal.Body>
          Você realmente deseja apagar este treinamento? <br /> <br />
          id: {apagarTreinamentoDiaLog.treinamento.id} <br />
          treinamento: {apagarTreinamentoDiaLog.treinamento.treinamento} <br />
          Essa ação é irreversível{' '}
          <RemindFill
            style={{
              color: '#ffb300',
              fontSize: 24,
            }}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={fecharApagarDialog} appearance="subtle">
            Cancelar
          </Button>
          <ButtonFullBlue
            onClick={() => excluirTreinamento()}
            appearance="primary"
          >
            Deletar
          </ButtonFullBlue>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default Treinamentos;
