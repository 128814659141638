import styled from 'styled-components';
import theme from '~/styles/theme';

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  & p {
    padding-top: 7px;
  }
`;
export const CardRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  gap: 40px;
  padding: 0 30px;
`;
export const Button1 = styled.button`
  &:focus {
    background-color: rgb(220, 220, 220, 0.5);
  }
  color: ${(props) => `${props.numberColor}`};
  background-color: transparent;
  font-size: 25px;
  font-weight: bold;
  margin-top: 10px;
  width: 73px;
  &:hover {
    background-color: rgb(220, 220, 220, 0.5);
  }
`;
export const Button2 = styled.button`
  &:focus {
    background-color: rgb(220, 220, 220, 0.5);
  }
  color: ${(props) => props.darkMode === true ? 'gray' : theme.darkGray};
  background-color: transparent;
  font-size: 25px;
  font-weight: bold;
  margin-top: 10px;
  width: 73px;
  &:hover {
    background-color: rgb(220, 220, 220, 0.5);
  }

`;
export const Card = styled.button`
  display: flex;
  margin-top: 10px;
  padding: 10px 0;
  border-radius: 10px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-bottom: 5px;
  margin-left: 10px;
  background-color: ${props => props.darkMode === true ? theme.rsuiteGray : 'white'}

`;
