import styled from 'styled-components';
import { Form } from 'rsuite';

export const Responsive = styled(Form.Group)`
  & input {
    width: 100% !important;
  }
  .rs-form-control-wrapper {
    width: 100% !important;
  }
  .rs-control-label {
    width: 100% !important;
  }
  .rs-form-control-label {
    width: 100% !important;
  }
  textarea {
    width: 100% !important;
  }
`;
