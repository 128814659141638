/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import { Plus } from '@rsuite/icons';

import { Table, FlexboxGrid, Schema } from 'rsuite';
import { toast } from 'react-toastify';

import { Container } from '~/styles/tabela';

import api from '~/services/api';
import {
  CelulaEditavel,
  CelulaAcao,
} from '~/components/Celulas/components/CelulaEditavel';
import { Modal1 } from '~/components/ModalCriacao/Modal1';
import { ModalIcon } from '~/components/ModalCriacao/ModalIcon';
import { Modal2 } from '~/components/ModalCriacao/Modal2';
import { TabelaSemPaginacao } from '~/components/TabelaSemPaginacao';
import SearchTable from '../components/SearchTable';

const { HeaderCell, Column } = Table;

const { StringType } = Schema.Types;

const modelo = Schema.Model({
  escolaridade: StringType().isRequired('Idioma Obrigatório'),
});

function Escolaridades() {
  const [dados, setDados] = useState({
    listaInicial: [],
    escolaridadeLista: [],
    escolaridadeListaFiltrada: [],
    filtro: '',
  });
  const [apagarEscolaridadeDialog, setApagarEscolaridadeDialog] = useState({
    exibir: false,
    escolaridade: {},
  });
  const [carregando, setCarregando] = useState(true);
  const [adicionarEscolaridadeDialog, setEscolaridadeDialog] = useState(false);
  const [camposInput, setCamposInput] = useState({});

  async function buscarEscolaridades() {
    const resposta = await api.get('escolaridade');
    const resposta1 = await api.get('escolaridade');
    const lista = resposta.data;
    setDados({
      listaInicial: resposta1.data,
      escolaridadeLista: lista,
      escolaridadeListaFiltrada: lista,
    });
    setCarregando(false);
  }

  useEffect(() => {
    buscarEscolaridades();
  }, []);

  function filtrarLista(texto) {
    setDados({
      ...dados,
      escolaridadeListaFiltrada: dados.escolaridadeLista.filter(
        (escolaridade) =>
          escolaridade.escolaridade
            .toString()
            .toUpperCase()
            .includes(texto ? texto.toUpperCase() : '')
      ),
      filtro: texto,
    });
  }

  function abrirApagarDialog(escolaridade) {
    setApagarEscolaridadeDialog({
      exibir: true,
      escolaridade,
    });
  }

  function fecharApagarDialog() {
    setApagarEscolaridadeDialog({
      exibir: false,
      escolaridade: {},
    });
  }

  async function excluirEscolaridade() {
    try {
      await api.delete(
        `/escolaridade/${apagarEscolaridadeDialog.escolaridade.id}`
      );
      toast.success('Escolaridade excluida com sucesso');
      fecharApagarDialog();
      buscarEscolaridades();
    } catch (e) {
      toast.error(e.message);
    }
  }

  async function adicionarEscolaridade() {
    try {
      setCarregando(true);
      await api.post(`/escolaridade`, camposInput);
      toast.success('Escolaridade criada com sucesso');
      setCarregando(false);
      setEscolaridadeDialog(false);
      buscarEscolaridades();
    } catch (e) {
      toast.error(e.message);
    }
  }

  function alterarTextoCelula(id, key, value) {
    const novaLista = Object.assign([], dados.escolaridadeLista);
    novaLista.find((escolaridade) => escolaridade.id === id)[key] = value;
    setDados({ ...dados, escolaridadeLista: novaLista });
    filtrarLista(dados.filtro);
  }

  async function alterarEstadoCelula(id) {
    const novaLista = Object.assign([], dados.escolaridadeLista);
    const item = novaLista.find((escolaridade) => escolaridade.id === id);
    const itemIncial = dados.listaInicial.find(
      (escolaridade) => escolaridade.id === id
    );
    if (
      item.status === 'EDIT' &&
      item.escolaridade !== itemIncial.escolaridade
    ) {
      try {
        setCarregando(true);
        await api.put(`escolaridade/${id}`, item);
        toast.success('Escolaridade atualizada com sucesso');
        buscarEscolaridades();
      } catch (error) {
        toast.error(error.message);
      }
      setCarregando(false);
    } else {
      item.status = item.status ? null : 'EDIT';
      setDados({ ...dados, escolaridadeLista: novaLista });
      filtrarLista(dados.filtro);
    }
  }

  return (
    <Container>
      <h2>Escolaridade</h2>
      <FlexboxGrid justify="start" style={{ gap: '10px' }}>
        <SearchTable estilo={{ width: '300px' }} filtrarLista={filtrarLista} />
        <ModalIcon
          style={{ marginTop: '0' }}
          icon={<Plus />}
          onClick={() => {
            setEscolaridadeDialog(true);
          }}
        />
      </FlexboxGrid>
      <FlexboxGrid style={{ width: '100%' }}>
        <TabelaSemPaginacao
          carregando={carregando}
          dados={dados.escolaridadeListaFiltrada}
        >
          <Column resizable width={240} sortable>
            <HeaderCell>Escolaridade</HeaderCell>
            <CelulaEditavel
              dataKey="escolaridade"
              onChange={alterarTextoCelula}
            />
          </Column>

          <Column resizable width={200}>
            <HeaderCell>Editar</HeaderCell>
            <CelulaAcao
              dataKey="id"
              onClick={alterarEstadoCelula}
              onDelete={abrirApagarDialog}
            />
          </Column>
        </TabelaSemPaginacao>
      </FlexboxGrid>

      {/* <Modal
        backdrop="static"
        show={adicionarEscolaridadeDialog}
        onClose={() => setEscolaridadeDialog(false)}
        size="xs"
      >
        <Form
          model={modelo}
          onChange={setCamposInput}
          onSubmit={(status) => status && adicionarEscolaridade()}
        >
          <Modal.Header>
            <h3>Adicionar Escolaridade</h3>
          </Modal.Header>
          <Modal.Body>
            < Form.Group>
              <Form.ControlLabel>Idioma</Form.ControlLabel>
              <Form.Control name="escolaridade" />
            </ Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button
              onClick={() => setEscolaridadeDialog(false)}
              appearance="subtle"
            >
              Cancelar
            </Button>
            <ButtonFullBlue type="submit" appearance="primary">
              Adicionar
            </ButtonFullBlue>
          </Modal.Footer>
        </Form>
      </Modal> */}
      <Modal1
        show={adicionarEscolaridadeDialog}
        onHide={() => setEscolaridadeDialog(false)}
        size="xs"
        title="Adicionar Escolaridade"
        label="Escolaridade"
        name="escolaridade"
        model={modelo}
        onSubmit={(status) => status && adicionarEscolaridade()}
        onChange={setCamposInput}
        onClick={() => setEscolaridadeDialog(false)}
      />

      <Modal2
        show={apagarEscolaridadeDialog.exibir}
        onHide={fecharApagarDialog}
        title="Apagar Escolaridade"
        text=" Você realmente deseja apagar esta escolaridade?"
        apagarId={apagarEscolaridadeDialog.escolaridade.id}
        apagarDado={apagarEscolaridadeDialog.escolaridade.escolaridade}
        fecharApagarDialog={fecharApagarDialog}
        excluir={() => excluirEscolaridade()}
      />
      {/* <Modal
        backdrop="static"
        show={apagarEscolaridadeDialog.exibir}
        onHide={fecharApagarDialog}
        size="xs"
      >
        <Modal.Header>
          <h3>Apagar Escolaridade</h3>
        </Modal.Header>
        <Modal.Body>
          Você realmente deseja apagar esta escolaridade? <br /> <br />
          id: {apagarEscolaridadeDialog.escolaridade.id} <br />
          escolaridade: {
            apagarEscolaridadeDialog.escolaridade.escolaridade
          }{' '}
          <br />
          Essa ação é irreversível{' '}
          <RemindFill
            style={{
              color: '#ffb300',
              fontSize: 24,
            }}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={fecharApagarDialog} appearance="subtle">
            Cancelar
          </Button>
          <ButtonFullBlue
            onClick={() => excluirEscolaridade()}
            appearance="primary"
          >
            Deletar
          </ButtonFullBlue>
        </Modal.Footer>
      </Modal> */}
    </Container>
  );
}

export default Escolaridades;
