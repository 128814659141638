import React, { useState } from 'react';
import { AutoComplete } from 'rsuite';

import { useDebouncedCallback } from 'use-debounce/lib';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import api from '~/services/api';

function FiltroExperiencias({ onItemAdicionado }) {
  const [profissoes, setProfissoes] = useState([]);

  const buscarProfissoes = useDebouncedCallback(async (texto) => {
    if (texto) {
      try {
        const resposta = await api.get('profissao', {
          params: {
            nome: texto,
          },
        });
        setProfissoes(resposta.data);
      } catch (error) {
        toast.error(error.message);
      }
    }
  }, 500).callback;

  return (
    <AutoComplete
      data={profissoes
        .map((b) => ({
          label: b.profissao,
          value: b.profissao,
          id: b.id,
        }))
        .slice(0, 10)}
      onSelect={onItemAdicionado}
      onChange={buscarProfissoes}
      placeholder="Experiências"
    />
  );
}

FiltroExperiencias.propTypes = {
  onItemAdicionado: PropTypes.func.isRequired,
};

export default FiltroExperiencias;
