/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import { Button, Modal } from 'rsuite';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import api from '~/services/api';
import { ButtonFullBlue } from '../../../../components/Buttons/ButtonFullBlue/styles';

function ExcluirCurriculo({ exibir, fechar, atualizar, curriculo }) {
  const [carregando, setCarregando] = useState(false);

  async function excluirCurriculo() {
    setCarregando(true);
    try {
      await api.delete(`/profissional/curriculo/${curriculo.id}`);
      toast.success('Curriculo removido');
      atualizar();
      fechar();
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
  }

  return (
    <Modal open={exibir} onClose={() => fechar()} size="xs">
      <Modal.Header>
        <Modal.Title>Excluir Curriculo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Você irá excluir definitivamente o curriculo</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => fechar()} appearance="subtle">
          Cancelar
        </Button>
        <ButtonFullBlue
          type="submit"
          onClick={excluirCurriculo}
          loading={carregando}
          appearance="primary"
        >
          Confirmar
        </ButtonFullBlue>
      </Modal.Footer>
    </Modal>
  );
}

ExcluirCurriculo.propTypes = {
  exibir: PropTypes.bool.isRequired,
  fechar: PropTypes.func.isRequired,
  atualizar: PropTypes.func.isRequired,
  curriculo: PropTypes.object.isRequired,
};

export default ExcluirCurriculo;
