import React, { useState } from 'react';
import {
  Modal,
  AutoComplete,
  Form,
  Button,
  CheckPicker,
  Popover,
  Whisper,
  IconButton,
} from 'rsuite';
import InfoRoundIcon from '@rsuite/icons/InfoRound';
import { addDays, isBefore, subDays } from 'date-fns';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import useSearchNames from '~/services/useSearchNames';
import api from '~/services/api';
import { InputData } from '~/components/InputData';
import formatCurrency from '~/utils/formatCurrency';
import { ButtonBorderBlue } from '../../../components/Buttons/ButtonBorderBlue/styles';
import theme from '../../../styles/theme';

const GerarFatura = ({ setCarregando, buscarRelatorios }) => {
  const [exibir, setExibir] = useState(false);
  const [nomesEstabelecimentos, setNomesEstabelecimentos] = useState([]);
  const [filtroEstabelecimento, setFiltroEstabelecimento] = useState();
  const [numPedido, setNumPedido] = useState([]);
  const [pedidoSelecionado, setPedidoSelecionado] = useState([]);
  const [itemselecionado, setItemselecionado] = useState([]);

  const today = addDays(new Date(), 2);
  const [filtroData, setFiltroData] = useState(today);
  const [filtroValor, setFiltroValor] = useState(0);

  const locale = {
    sunday: 'Dom',
    monday: 'Seg',
    tuesday: 'Ter',
    wednesday: 'Qua',
    thursday: 'Qui',
    friday: 'Sex',
    saturday: 'Sab',
    ok: 'Ok',
    today: 'Hoje',
    yesterday: 'Ontem',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos',
  };

  const resposta = async (id) => {
    await api.get(`pedidos/cliente/faturas/${id}`).then((response) => {
      setNumPedido(response.data);
    });
  };

  const cancelSubmit = () => {
    setFiltroValor(0);
    setExibir(false);
    setPedidoSelecionado([]);
    setNumPedido([]);
  };

  const salvarResposta = async () => {
    if (itemselecionado.length > 30) {
      return toast.warning(
        'Há mais de 30 pedidos selecionados, remova até que esteja dentro do limite'
      );
    }
    setCarregando(true);
    cancelSubmit();

    const respostaSelecionada = await api
      .post(`faturas-iugu/novo`, {
        cliente: filtroEstabelecimento,
        data_vencimento: filtroData,
        items: itemselecionado,
        idPedido: pedidoSelecionado.map((item) => item.id),
        numeroPedido: pedidoSelecionado.map((item) => item.numero_pedido),
      })
      .then(() => {
        setCarregando(false);
        toast.success('Fatura gerada com sucesso');
        buscarRelatorios();
      })
      .catch((err) => {
        setCarregando(false);
        toast.error(err.message);
      });
    return respostaSelecionada;
  };

  const buscarNomesEstabelecimentos = useSearchNames(
    setNomesEstabelecimentos,
    'estabelecimentos'
  );

  const speaker = (
    <Popover>
      <p>Limite de 30 pedidos por fatura.</p>
    </Popover>
  );

  return (
    <>
      <ButtonBorderBlue appearance="ghost" onClick={() => setExibir(true)}>
        Gerar Fatura
      </ButtonBorderBlue>
      <Modal
        open={exibir}
        onClose={() => {
          setExibir(false);
          cancelSubmit();
        }}
        size="sm"
      >
        <Form onSubmit={salvarResposta}>
          <Modal.Header>
            <Modal.Title>Gerar Fatura</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.ControlLabel>Cliente</Form.ControlLabel>
              <AutoComplete
                name="nome_estabelecimento"
                style={{ marginRight: '10px' }}
                data={nomesEstabelecimentos.map((p) => ({
                  label: p.nome_fantasia,
                  value: p.nome_fantasia,
                  id: p.id,
                }))}
                placeholder="Nome do Cliente"
                onSelect={async (value, item) => {
                  setFiltroEstabelecimento(item.id);
                  resposta(item.id);
                }}
                onChange={buscarNomesEstabelecimentos}
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                N. Pedido
                <Whisper
                  placement="bottom"
                  trigger="hover"
                  controlId="control-id-hover"
                  speaker={speaker}
                >
                  <IconButton
                    style={{
                      backgroundColor: 'transparent',
                      marginRight: '15px',
                    }}
                    size="xs"
                    icon={<InfoRoundIcon />}
                  />
                </Whisper>
              </Form.ControlLabel>
              <CheckPicker
                style={{ marginRight: '15px', width: '450px' }}
                appearance="default"
                placeholder="Pedido"
                virtualized
                data={numPedido.map((p) => ({
                  label: p.label,
                  value: {
                    valor: p.valor,
                    id: p.id,
                    label: p.label,
                    numero_pedido: p.numero_pedido,
                  },
                }))}
                value={pedidoSelecionado}
                onChange={(value) => {
                  setPedidoSelecionado(value);
                  if (value.length > 0) {
                    setFiltroValor(
                      value
                        .map((item) => item.valor)
                        .reduce((acc, atual) => acc + atual)
                    );
                  } else {
                    setFiltroValor(0);
                  }
                  setItemselecionado(
                    value.map((item) => ({
                      description: item.label,
                      quantity: 1,
                      price_cents: (item.valor * 100).toFixed(0),
                    }))
                  );
                }}
              />
            </Form.Group>

            <Form.Group>
              <InputData
                cleanable={false}
                ranges={[]}
                isoWeek
                oneTap
                locale={locale}
                value={filtroData}
                onChange={setFiltroData}
                disabledDate={(data) => isBefore(data, subDays(new Date(), 1))}
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Valor</Form.ControlLabel>
              <Form.Control
                name="Valor"
                placeholder="Valor"
                accepter={AutoComplete}
                value={formatCurrency(filtroValor)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => cancelSubmit()} appearance="subtle">
              Cancelar
            </Button>
            <Button
              appearance="primary"
              type="submit"
              style={{ background: `${theme.primaryColor}` }}
            >
              Salvar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};
GerarFatura.propTypes = {
  setCarregando: PropTypes.bool.isRequired,
  buscarRelatorios: PropTypes.func.isRequired,
};

export default GerarFatura;
