import React from 'react';
import { SelectPicker } from 'rsuite';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

export default function SelectState({
  selectedState,
  setSelectedStates,
  marginRight,
}) {
  const states = useSelector((state) => state.states);

  return (
    <SelectPicker
      appearance="default"
      style={{ marginRight: marginRight ? '15px' : 0 }}
      placeholder="Estado"
      data={states.map((estado) => ({
        label: estado.uf,
        value: estado.id,
      }))}
      value={selectedState}
      onChange={(value) => setSelectedStates(value)}
    />
  );
}

SelectState.propTypes = {
  selectedState: PropTypes.number,
  setSelectedStates: PropTypes.func.isRequired,
  marginRight: PropTypes.bool,
};

SelectState.defaultProps = {
  marginRight: false,
  selectedState: ''
};
