import { all, takeLatest, call, put } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import api from '~/services/api';
import history from '~/services/history';

import { logarSucesso, logarFalha, retirarCarregamento } from './actions';

export function* logar({ payload }) {
  try {
    const { email, senha } = payload;

    const response = yield call(api.post, 'login-administrador', {
      email,
      senha,
    });

    const { token } = response.data;

    api.defaults.headers.Authorization = `Bearer ${token}`;
    localStorage.setItem('token-login', token);

    yield put(logarSucesso(token));

    history.push('/home');
  } catch (err) {
    console.tron.error(err);
    toast.error('Falha na autenticação, verifique seus dados');
    yield put(logarFalha());
  }
}

export function* atualizarToken({ payload }) {
  if (!payload) return;

  const { token } = payload.autenticacao;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
  yield put(retirarCarregamento());
}

export function signOut() {
  history.push('/');
}

export default all([
  takeLatest('persist/REHYDRATE', atualizarToken),
  takeLatest('@autenticacao/LOGAR_REQUISICAO', logar),
  takeLatest('@autenticacao/DESLOGAR', signOut),
]);
