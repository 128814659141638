/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Panel, Loader, FlexboxGrid, Col, Form, Grid, Row } from 'rsuite';

import InputMascara from '~/components/InputMascara';

import api from '~/services/api';
import { ResponsiveForm } from '~/components/ResponsiveForm';

function CamposEndereco({ atualizarCampos, camposInput }) {
  const [erroCep, setErroCep] = useState();
  const [carregando, setCarregando] = useState(false);
  const [disabled, setDisabled] = useState(true);

  async function buscarCep(cep) {
    setCarregando(true);
    try {
      const resultado = await api.get(`/cep/${cep}`);
      const { endereco } = resultado.data;
      if (!endereco.bairro.bairro) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
      atualizarCampos({
        cep,
        logradouro: endereco.logradouro,
        bairro: endereco.bairro.bairro,
        cidade: endereco.bairro.cidade.cidade,
        estado: endereco.bairro.cidade.estado.estado,
        uf: endereco.bairro.cidade.estado.uf,
      });
      setErroCep();
    } catch (error) {
      setErroCep('Erro ao buscar dados do CEP');
    }
    setCarregando(false);
  }

  return (
    <Panel
      header={
        <div style={{ display: 'flex' }}>
          <h4>Endereço</h4> {carregando && <Loader size="md" />}
        </div>
      }
      bordered
      collapsible
      defaultExpanded
    >
      <FlexboxGrid justify="flex-start" style={{ textAlignLast: 'start' }}>
        {/* <FlexboxGrid.Item componentClass={Col} colspan={6} md={8}> */}
        <Grid>
          <Row>
            <Col md={6} sm={24} xs={24}>
              <ResponsiveForm
                label="Cep"
                name="cep"
                value={camposInput.cep || ''}
                mask={[/[0-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                accepter={InputMascara}
                onChange={(texto) => texto.length === 9 && buscarCep(texto)}
                errorMessage={erroCep}
              />
            </Col>
            <Col md={6} sm={24} xs={24}>
              {/* </FlexboxGrid.Item> */}
              {/* <FlexboxGrid.Item componentClass={Col} colspan={6} md={6}> */}
              <ResponsiveForm
                label="Logradouro"
                name="logradouro"
                value={camposInput.logradouro || ''}
                disabled={carregando}
              />
            </Col>
            <Col md={6} sm={24} xs={24}>
              {/* </FlexboxGrid.Item> */}
              {/* <FlexboxGrid.Item componentClass={Col} colspan={6} md={6}> */}
              <ResponsiveForm
                label="Numero"
                name="numero"
                value={camposInput.numero || ''}
                disabled={carregando}
              />
              {/* </FlexboxGrid.Item> */}
            </Col>
            <Col md={6} sm={24} xs={24}>
              <ResponsiveForm
                label="Complemento"
                name="complemento"
                value={camposInput.complemento || ''}
                disabled={carregando}
              />
            </Col>
          </Row>

          <Row>
            <Col md={6} sm={24} xs={24}>
              <ResponsiveForm
                label="Bairro"
                name="bairro"
                value={camposInput.bairro || ''}
                disabled={disabled}
              />
            </Col>
            <Col md={6} sm={24} xs={24}>
              <ResponsiveForm
                label="Cidade"
                name="cidade"
                value={camposInput.cidade || ''}
                disabled
              />
            </Col>
            <Col md={6} sm={24} xs={24}>
              <ResponsiveForm
                label="Estado"
                name="estado"
                value={camposInput.estado || ''}
                disabled
              />
            </Col>
            <Col md={6} sm={24} xs={24}>
              <ResponsiveForm
                label="uf"
                name="uf"
                value={camposInput.uf || ''}
                disabled
              />
            </Col>
          </Row>
        </Grid>
      </FlexboxGrid>
    </Panel>
  );
}

CamposEndereco.propTypes = {
  atualizarCampos: PropTypes.func.isRequired,
  camposInput: PropTypes.object,
};

export default CamposEndereco;
