import axios from 'axios';
import { deslogar } from '../store/modulos/autenticacao/actions';

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export function adicionarInterceptor(store) {
  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      let mensagem = '';
      if (error.response) {
        if (error.response.status === 500) {
          mensagem = 'Erro interno da API';
        } else if (error.response.status === 401) {
          mensagem = 'Token expirou';
          store.dispatch(deslogar());
        } else if (error.response.status === 413) {
          mensagem = 'Arquivo excede o tamanho de 10mb';
        } else if (error.response.status === 400) {
          mensagem = error.response.data.message;
        } else if (error.response.status !== 400) {
          mensagem = 'Erro';
        } else if (error.message) {
          mensagem = error.message;
        }
      } else {
        mensagem = 'Algum erro aconteceu';
      }

      return Promise.reject(new Error(mensagem));
    }
  );
}

export default api;
