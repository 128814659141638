/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import { DatePicker, Panel as PanelRsuite } from 'rsuite';
import styled, { css } from 'styled-components';
import theme from '~/styles/theme';

export const HidePicker = styled.div`
  .rs-picker-toolbar {
    display: none !important;
  }
  .rs-picker-toolbar-right {
    display: none !important;
  }
`;

export const Panel = styled(PanelRsuite)`
  .rs-panel-title {
    display: flex;
    place-content: space-between;
  }
  .rs-toggle-checked .rs-toggle-presentation {
    background-color: ${theme.primaryColor};
  }
`;

export const DatePickerValueTransparent = styled(DatePicker)`

  .rs-picker-toggle-value {
    color: transparent !important;
  }
  .rs-picker-toolbar {
    display: none !important;
  }
  // .rs-picker-toggle-textbox {
  //   color: transparent !important;
  // }
`;

export const Content = styled.div`
  .rs-input-group {
    width: 100% !important;
  }

  ${({ theme }) => css`
    @media (max-width: 768px) {
      width: 100% !important;
    }
  `}
`;

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 10px;
    @media (max-width: 768px) {
      flex-direction: column;
      .rs-form-group {
        margin-bottom: 21px;
      }
    }
  `}
`;

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  `}
`;
