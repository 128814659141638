import styled from "styled-components";

export const ModalContainer = styled.div`
    display: flex;
    height: 36px;
    flex-direction: row;
    gap: 15px;
`

export const CheckboxDiv = styled.div`
    display: flex;
    flex-direction: column;

    p {
        margin-left: 10px;
    }

`

export const PrincipalDiv = styled.div`
    display: flex;
    flex-direction: row;

`

