import React, { useState } from 'react';
import { AutoComplete } from 'rsuite';

import PropTypes from 'prop-types';
import useSearchNames from '~/services/useSearchNames';

function FiltroProfissionais({ onItemAdicionado }) {
  const [profissionalNome, setProfissionalNome] = useState([]);

  const buscarNomesProfissionais = useSearchNames(
    setProfissionalNome,
    'profissionais'
  );

  return (
    <AutoComplete
      data={profissionalNome.map((p) => ({
        label: p.nome,
        value: p.nome,
        id: p.id,
      }))}
      onSelect={onItemAdicionado}
      onChange={buscarNomesProfissionais}
      placeholder="Profissionais"
    />
  );
}

FiltroProfissionais.propTypes = {
  onItemAdicionado: PropTypes.func.isRequired,
};

export default FiltroProfissionais;
