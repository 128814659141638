import styled from 'styled-components';

export const Tabela = styled.table`
  transition: all 300ms;

  max-width: 100%;
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
  font-family: arial;
  position: relative;

  border-bottom: #999999 solid 1px;
  width: 100%;
  height: 100%;
  margin-top: 0px;
  margin-bottom: 5px;

  th,
  td {
    font-size: 12px;
    padding: 8px;
    line-height: 20px;
    text-align: left;
    vertical-align: middle;
  }

  td:last-child {
    border-right: 0;
  }

  thead > tr > th {
    position: sticky;
    top: -20px;
    /* background-color: #f8f8f8; */
  }

  thead th {
    font-weight: normal;
    // background-color: #005dab;
    // color: #fff;
    font-size: 15px;
    border-bottom: #999999 solid 1px;
    align: center;
  }

  /* tbody > tr:nth-child(odd) > td,
  tbody > tr:nth-child(odd) > th {
    background-color: #f8f8f8;
  } */

  .trTablePedLogs:nth-child(odd) {
    background: #fff;
    .rs-table-cell {
      background: #fff;
    }
  }
  .trTablePedLogs:nth-child(even) {
    background: #f8f8f8;
    .rs-table-cell {
      background: #f8f8f8;
    }
  }

  // tr:hover {
  //   filter: brightness(25%);
  // }
`;

export const Linha = styled.tr`
  // background: #f2f2f2;
`;

export const Container = styled.div`
  width: 100%;
  height: 800px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow: auto;

  & + div {
    display: flex;
  }

  h2 {
    margin-bottom: 20px;
    font-size: 36px;
  }

  h6 {
    color: #8bc01d;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  p {
    font-weight: bold;
    font-size: 16px;
  }
  .rs-list > .rs-list-item:nth-of-type(odd) {
    background-color: #f2f2f2;
  }
  .rs-flex-box-grid-item:last-of-type {
    padding-left: 15%;
    text-align: left;
  }
`;

export const ContainerTable = styled.div`
  width: 100%;
  height: 900px;
  display: flex;
  flex-direction: column;
  overflow: auto;

  & + div {
    display: flex;
  }
`;
