/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';

import { FlexboxGrid, CheckPicker, Input, SelectPicker } from 'rsuite';

import { InputDataRange } from '~/components/InputDataRange';
import { Container } from '~/styles/tabela';
import { locale } from '~/common/variaveis';
import api from '~/services/api';
import formatCurrency from '~/utils/formatCurrency';
import { TableWithSub } from '../tabela';
import {
  recrutamento,
  motivosRecrutamento,
  columnsRecrutamento,
  columnsRecrutamentoSubTables,
} from '../../common';

export const RecrutamentoLogs = () => {
  const [categoria, setCategoria] = useState(24);
  const [carregando, setCarregando] = useState(true);
  const [data, setData] = useState([]);
  const [motivo, setMotivo] = useState([]);
  const [pagina, setPagina] = useState(1);
  const [pedido, setPedido] = useState();
  const [logsPedido, setLogsPedido] = useState({
    lista: [],
    quantidade: 0,
  });

  useEffect(() => {
    const buscaAPI = async () => {
      setCarregando(true);
      try {
        const result = await api.post('/logs/usuarios', {
          pagina,
          categoria,
          id_registro: pedido,
          motivo,
          responsavel: null,
          data_inicio: data[0]
            ? moment(data[0]).format('YYYY-MM-DD')
            : undefined,
          data_fim: data[0] ? moment(data[1]).format('YYYY-MM-DD') : undefined,
        });
        if (result.data.lista.length > 0) {
          const newRes = {
            quantidade: result.data.quantidade,
            lista: result.data.lista.map((res) => ({
              ...res,
              tipo_estabelecimento: res.descricao.tipo_estabelecimento
                ? res.descricao.tipo_estabelecimento
                : null,
              recrutamento: res.descricao.recrutamento
                ? {
                  ...res.descricao.recrutamento,
                  data_solicitacao: moment(
                    res.descricao.recrutamento.data_solicitacao
                  ).format('DD/MM/YYYY'),
                  status:
                    res.descricao.recrutamento.status === true
                      ? 'Resolvido'
                      : 'Pendente',
                  status_contratacao:
                    res.descricao.recrutamento.status_contratacao === true
                      ? 'Aprovado'
                      : 'Reprovado',
                }
                : {
                  ...res.descricao,
                  status_contratacao:
                    res.descricao.status_contratacao === true
                      ? 'Aprovado'
                      : 'Reprovado',
                },
            })),
          };
          setLogsPedido(newRes);
        } else {
          setLogsPedido({
            lista: [],
            quantidade: 0,
          });
        }
        setCarregando(false);
      } catch (err) {
        setCarregando(false);
        toast.error('Erro ao realizar a busca dos logs');
      }
    };
    buscaAPI();
  }, [pagina, pedido, motivo, data, categoria]);

  return (
    <Container>
      <FlexboxGrid justify="start" style={{ gap: '10px' }}>
        <SelectPicker
          appearance="default"
          style={{ width: '200px' }}
          searchable={false}
          data={recrutamento}
          value={categoria}
          cleanable={false}
          onChange={(value) => setCategoria(value)}
          defaultValue={[{ label: 'Edição', value: 'Edição' }]}
        />
        <CheckPicker
          appearance="default"
          style={{ width: 200 }}
          searchable={false}
          value={motivo}
          cleanable={false}
          onChange={(value) => setMotivo(value)}
          data={motivosRecrutamento[categoria].map((moti) => ({
            label: moti.label,
            value: moti.value,
          }))}
          defaultValue={{ label: 'Criação', value: 'Criação' }}
        />
        <Input
          style={{ width: '200px' }}
          placeholder={`ID ${recrutamento
            .filter((o) => o.value === categoria)[0]
            .label.slice(0, 15)}. Ex: 20`}
          onChange={(value) => setPedido(value)}
        />
        <InputDataRange
          style={{ width: '250px' }}
          placeholder="Data"
          ranges={[]}
          onClean={() => setData(['', ''])}
          onChange={(value) => setData(value)}
          locale={locale}
        />
      </FlexboxGrid>

      <TableWithSub
        // title="Profissionais"
        lista={logsPedido.lista || []}
        quantidade={logsPedido.quantidade}
        paginationBarTop
        paginationBarLow
        setPagina={setPagina}
        columns={columnsRecrutamento[categoria]}
        childrens={columnsRecrutamentoSubTables[categoria]}
      />
      <></>
    </Container>
  );
};
