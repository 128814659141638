/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Loader, Panel, Table } from 'rsuite';
import api from '~/services/api';
import { TabelaSemPaginacao } from '~/components/TabelaSemPaginacao';
import PropTypes from 'prop-types';

const { HeaderCell, Column, Cell } = Table;

function Tabelas({ label, route, filtroData, periodoSelecionado, labels = [] }) {
  const [carregando, setCarregando] = useState(true);
  const [dados, setDados] = useState([{}]);

  async function buscaDados() {
    setCarregando(true);
    try {
      const response = await api.get(`relatorios/graficos/${route}`, {
        params: {
          // datas: filtroData && filtroData,
          filtroData,
          periodo: periodoSelecionado,
        },
      });
      let list = []
      if (response.data.length > 0) {
        list = response.data.map((r, indice) => ({
          ...r,
          valoresFormatados: r.valores
            ? r.valores.toLocaleString('pt-br', {
              style: 'currency',
              currency: 'BRL',
            })
            : 'R$ 0,00',
          label: labels.length > 0 ? labels[indice] : r.label,
          percentual:
            r.percentual &&
            `${Number(r.percentual).toLocaleString('pt-br', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}%`,
        }));
      } else {
        list = [{ labal: '', valoresFormatados: 0, qtd_estab: 0, percentual: '100%' }]
      }
      setDados(list);
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
  }

  useEffect(() => {
    buscaDados();
  }, [route, filtroData, periodoSelecionado]);

  return (
    <>
      <Panel header={label} shaded>
        <TabelaSemPaginacao carregando={carregando} dados={dados}>
          <Column resizable width={250}>
            <HeaderCell>Nome Fantasia</HeaderCell>
            <Cell dataKey="label" />
          </Column>
          <Column resizable width={200} sortable>
            <HeaderCell>Valor</HeaderCell>
            <Cell dataKey="valoresFormatados" />
          </Column>
          {dados[0].qtd_estab && (
            <Column resizable width={150}>
              <HeaderCell>Estabelecimentos</HeaderCell>
              <Cell dataKey="qtd_estab" />
            </Column>
          )}
          {dados[0].percentual && (
            <Column resizable width={150} sortable>
              <HeaderCell>Percentual</HeaderCell>
              <Cell dataKey="percentual" />
            </Column>
          )}
        </TabelaSemPaginacao>
      </Panel>
      {carregando && <Loader center size="lg" vertical />}
    </>
  );
}

Tabelas.propTypes = {
  route: PropTypes.func.isRequired,
  filtroData: PropTypes.func.isRequired,
  labels: PropTypes.func.isRequired,
  label: PropTypes.func.isRequired,
  periodoSelecionado: PropTypes.string.isRequired,
};

export default Tabelas;
