import produce from 'immer';

const ESTADO_INICIAL = {
  token: null,
  logado: false,
  carregando: false,
};

export default function autenticacao(state = ESTADO_INICIAL, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@autenticacao/LOGAR_REQUISICAO': {
        draft.carregando = true;
        break;
      }

      case '@autenticacao/LOGAR_SUCESSO': {
        draft.token = action.payload.token;
        draft.logado = true;
        draft.carregando = false;
        break;
      }

      case '@autenticacao/LOGAR_FALHA': {
        draft.carregando = false;
        break;
      }

      case '@autenticacao/DESLOGAR': {
        draft.token = null;
        draft.logado = false;
        break;
      }

      case '@autenticacao/RETIRAR_CARREGAMENTO': {
        draft.carregando = false;
        break;
      }

      default:
    }
  });
}
