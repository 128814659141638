/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';

import { FlexboxGrid, Table, CheckPicker, Input } from 'rsuite';

import { InputDataRange } from '~/components/InputDataRange';
import { Container } from '~/styles/tabela';
import { locale } from '~/common/variaveis';
import api from '~/services/api';
import { TableWithSub } from '../tabela';
import { motivosAdm } from '../../common';

export const AdministracaoLogs = () => {
  const [carregando, setCarregando] = useState(true);
  const [data, setData] = useState([]);
  // const [data, setData] = useState([new Date(), new Date()]);
  const [motivo, setMotivo] = useState([]);
  const [pagina, setPagina] = useState(1);
  const [pedido, setPedido] = useState();
  const [logsPedido, setLogsPedido] = useState({
    lista: [],
    quantidade: 0,
  });
  const [orderBy, setOrderBy] = useState();

  useEffect(() => {
    const buscaAPI = async () => {
      setCarregando(true);
      try {
        const result = await api.post('/logs/usuarios', {
          pagina,
          categoria: 19,
          id_registro: pedido,
          motivo,
          responsavel: null,
          data_inicio: data[0]
            ? moment(data[0]).format('YYYY-MM-DD')
            : undefined,
          data_fim: data[0] ? moment(data[1]).format('YYYY-MM-DD') : undefined,
        });
        if (result.data.lista.length > 0) {
          const newRes = {
            quantidade: result.data.quantidade,
            lista: result.data.lista.map((res) => ({
              ...res,
              nome: res.descricao.nome ? res.descricao.nome : null,
              email: res.descricao.email ? res.descricao.email : null,
              rule: res.descricao.rule ? res.descricao.rule : null,
            })),
          };
          setLogsPedido(newRes);
        } else {
          setLogsPedido([
            {
              lista: [],
              quantidade: 0,
            },
          ]);
        }
        setCarregando(false);
      } catch (err) {
        setCarregando(false);
        toast.error('Erro ao realizar a busca dos logs');
      }
    };
    buscaAPI();
  }, [pagina, pedido, motivo, data]);

  return (
    <Container>
      <FlexboxGrid justify="start" style={{ gap: '10px' }}>
        <CheckPicker
          appearance="default"
          style={{ width: 200 }}
          searchable={false}
          value={motivo}
          cleanable={false}
          onChange={(value) => setMotivo(value)}
          data={motivosAdm.map((moti) => ({
            label: moti.label,
            value: moti.value,
          }))}
          defaultValue={[{ labael: 'Edição', value: 'Edição' }]}
        />
        <Input
          style={{ width: '200px' }}
          placeholder="ID Usuário. Ex: 21 "
          onChange={(value) => setPedido(value)}
        />
        <InputDataRange
          style={{ width: '250px' }}
          placeholder="Data"
          ranges={[]}
          onClean={() => setData(['', ''])}
          onChange={(value) => setData(value)}
          locale={locale}
        />
      </FlexboxGrid>

      <TableWithSub
        // title="Profissionais"
        lista={logsPedido.lista}
        quantidade={logsPedido.quantidade}
        paginationBarTop
        paginationBarLow
        setPagina={setPagina}
        columns={[
          { title: 'Responsável', value: 'adm_nome' },
          { title: 'ID Pro', value: 'id_registro' },
          { title: 'Usuário', value: 'nome' },
          { title: 'Email', value: 'email' },
          { title: 'Rule', value: 'rule' },
          { title: 'Motivo', value: 'motivo' },
          { title: 'Data de Criação', value: 'createdAt' },
        ]}
        childrens={[
          {
            title: 'Cargo',
            list: ['descricao', 'cargo'],
            columns: [
              { title: 'Nome', value: 'administracao' },
              { title: 'estabelecimentos', value: 'estabelecimentos' },
              { title: 'iugu', value: 'iugu' },
              { title: 'nome', value: 'nome' },
              { title: 'notificacoes', value: 'notificacoes' },
              { title: 'opcoes', value: 'opcoes' },
              { title: 'pedidos', value: 'pedidos' },
              { title: 'profissionais', value: 'profissionais' },
              { title: 'relatorios', value: 'relatorios' },
              { title: 'tarifas', value: 'tarifas' },
            ],
          },
        ]}
      />
    </Container>
  );
};
