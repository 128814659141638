/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Table } from 'rsuite';

import PropTypes from 'prop-types';

const { Cell } = Table;

export const CelulaNotifcacao = ({ rowData, dataKey, ...props }) => (
  <Cell
    {...props}
    style={{
      background: !rowData.lida && '#37AB00' /* '#98FB98' */,
      color: 'white'
    }}
  >
    {rowData[dataKey]}
  </Cell>
);

CelulaNotifcacao.propTypes = {
  rowData: PropTypes.object,
  dataKey: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

CelulaNotifcacao.defaultProps = {
  rowData: {},
  onChange: () => { },
};
