import produce from 'immer';

const ESTADO_INICIAL = {
  carregando: false,
  camposInput: {
    nome_estabelecimento: '',
    id_estabelecimento: null,
    status: '',
    valor: '',
    status_pagamento: '',
    id_uniforme: null,
    data: null,
    hora_inicio_texto: '',
    hora_fim_texto: '',
    intervalo: null,
    intervalo_texto: '',
    observacao: '',
    obs_profissionais: '',
    feedback_estb: '',
    tarifa_dinamica: 0,
    id_cupom: null,
    cupom_texto: '',
  },
  cupom: {
    cup: ''
  },
  registros: [],
  pedido: {},
  profissionalRegistrosDia: {}
};

export default function pedido(state = ESTADO_INICIAL, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@pedido/ACEITAR_REGISTRO':
      case '@pedido/CONFIRMAR_PROFISSIONAL':
      case '@pedido/CANCELAR_REGISTRO':
      case '@pedido/REMOVER_PROFISSIONAL':
      case '@pedido/MARCAR_FALTA_PROFISSIONAL':
      case '@pedido/FINALIZAR_PEDIDO':
      case '@pedido/CRIAR_PEDIDO':
      case '@pedido/CRIAR_PEDIDO_ANTIGO':
      case '@pedido/VERIFICAR_CUPOM':
      case '@pedido/BUSCAR_PEDIDO':
      case '@pedido/SALVAR_PEDIDO':
      case '@pedido/AUTORIZAR_PEDIDO':
      case '@pedido/CONFIRMAR_PEDIDO':
      case '@pedido/CANCELAR_PEDIDO': {
        draft.carregando = true;
        break;
      }

      case '@pedido/REQUISICAO_FINALIZADA': {
        draft.carregando = false;
        break;
      }

      case '@pedido/BUSCAR_PEDIDO_SUCESSO': {
        draft.carregando = false;
        draft.camposInput = action.payload.camposInput;
        draft.registros = action.payload.registros;
        draft.pedido = action.payload.pedido;
        draft.cupom = action.payload.cupom;
        break;
      }

      case '@pedido/ATUALIZAR_REGISTROS': {
        draft.registros = action.payload.registros;
        break;
      }

      case '@pedido/ATUALIZAR_CAMPOS_INPUT': {
        const campos = {
          ...draft.camposInput,
          ...action.payload.camposInput,
        };
        draft.camposInput = campos;
        break;
      }

      case '@pedido/ATUALIZAR_REGISTRO_HORARIO': {
        draft.registros[action.payload.index].hora_inicio = action.payload.regist.hora_inicio
        draft.registros[action.payload.index].hora_fim = action.payload.regist.hora_fim
        draft.registros[action.payload.index].id_profissional = action.payload.regist.id_profissional
        draft.registros[action.payload.index].nome_profissional = action.payload.regist.nome_profissional
        break;
      }

      case '@pedido/ATUALIZAR_CAMPOS_CUPOM': {
        const campos = {
          ...draft.cupom,
          ...action.payload.cupom,
        };
        draft.cupom = campos;
        break;
      }

      case '@pedido/ATUALIZAR_ID_ESTABELECIMENTO': {
        draft.camposInput.id_estabelecimento = action.payload.idEstabelecimento;
        break;
      }

      case '@pedido/SALVAR_CUPOM': {
        draft.camposInput.id_cupom = action.payload.id_cupom;
        draft.carregando = false;
        break;
      }

      case '@pedido/SAIR_PAGINA': {
        draft.carregando = ESTADO_INICIAL.carregando;
        draft.camposInput = ESTADO_INICIAL.camposInput;
        draft.cupom = ESTADO_INICIAL.cupom;
        draft.registros = ESTADO_INICIAL.registros;
        draft.pedido = ESTADO_INICIAL.pedido;
        break;
      }

      case '@pedido/REGISTROS_DIA': {
        draft.profissionalRegistrosDia =  action.payload.profissional;
        break;
      }

      default:
    }
  });
}
