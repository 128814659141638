/* eslint-disable react/forbid-prop-types */
import React, { useState, useContext } from 'react';
import { Modal, Button } from 'rsuite';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import api from '~/services/api';
import { DarkModeContext } from '~/common/contexts/darkMode';
import PropTypes from 'prop-types';
import { ButtonBorderBlue } from '../../../../components/Buttons/ButtonBorderBlue/styles';
import { ButtonFullBlue } from '../../../../components/Buttons/ButtonFullBlue/styles';

function ModalEnviarNotificacao() {
    const [exibir, setExibir] = useState(false);
    const { toggleMode } = useContext(DarkModeContext);
    const [carregando, setCarregando] = useState(false)
    const loggeduser = useSelector((state) => state.setUser.data);

    async function enviarNotificacao() {

        setCarregando(true);

        try {
            const enviarNot = await api.post('/notificacao/confirmacao-servicos', {
                enviadoPor: loggeduser.nome
            })
            toast.success(enviarNot.data.message)

            setExibir(false)
        } catch (error) {
            console.log(error)
            toast.error(error.message);
        }


        setCarregando(false);
    }

    return (
        <>
            <ButtonBorderBlue appearance="ghost" onClick={() => setExibir(true)} darkMode={toggleMode} loading={carregando} >
                Enviar Notificação
            </ButtonBorderBlue>
            <Modal open={exibir} onClose={() => setExibir(false)} size="sm">
                <Modal.Header>
                    <Modal.Title>Enviar Notificação</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Enviar Notificação para todos os profissionais não confirmados?</h5>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setExibir(false)} appearance="subtle">
                        Cancelar
                    </Button>
                    <ButtonFullBlue
                        appearance="primary"
                        onClick={() => enviarNotificacao()}
                    >
                        Enviar
                    </ButtonFullBlue>
                </Modal.Footer>
            </Modal>
        </>
    );
}

ModalEnviarNotificacao.propTypes = {
};

export default ModalEnviarNotificacao;
