export const locale = {
  sunday: 'Dom',
  monday: 'Seg',
  tuesday: 'Ter',
  wednesday: 'Qua',
  thursday: 'Qui',
  friday: 'Sex',
  saturday: 'Sab',
  ok: 'Ok',
  today: 'Hoje',
  yesterday: 'Ontem',
  hours: 'Horas',
  minutes: 'Minutos',
  seconds: 'Segundos',
};

export const periodoDataGraficos = [
  {
    label: '1 Semana',
    value: '1semana',
  },
  {
    label: '1 Mês',
    value: '1mes',
  },
  {
    label: '6 Meses',
    value: '6meses',
  },
  {
    label: '1 Ano',
    value: '1ano',
  },
  {
    label: '2 Anos',
    value: '2anos',
  },
  {
    label: 'Todo Período',
    value: 'all',
  },
];

export const anosGraficos = [
  {
    label: '2020',
    value: '2020',
  },
  {
    label: '2021',
    value: '2021',
  },
  {
    label: '2022',
    value: '2022',
  },
  {
    label: '2023',
    value: '2023',
  },
  {
    label: '2024',
    value: '2024',
  },
];
