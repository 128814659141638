/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-prop-types */

import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import produce from 'immer';
import { addDays, format, isBefore, setHours, setMinutes } from 'date-fns';
import copy from 'copy-to-clipboard';
import {
  ArrowDownLine,
  Block,
  Check,
  CheckOutline,
  Edit,
  Plus,
} from '@rsuite/icons';

import InfoOutlineIcon from '@rsuite/icons/InfoOutline';
import TrashIcon from '@rsuite/icons/Trash';

import { Panel, IconButton, Table, Whisper, Popover, Dropdown, Checkbox } from 'rsuite';

import { useDispatch, useSelector } from 'react-redux';
// import CelulaAcao from '~/components/Celulas/components/CelulaAcao';
import api from '~/services/api';
import {
  atualizarRegistros,
  cancelarRegistro,
  marcarFaltaProfissional,
  removerProfissional,
  editaHorario,
} from '~/store/modulos/pedido/actions';
import { TabelaSemPaginacao } from '~/components/TabelaSemPaginacao';
import theme from '~/styles/theme';
import moment from 'moment';
import CelulaAcao from './components/CelulaAcao';
import { minutesToHourTime } from '../../../../utils/minutesToHour';
import ExcluirRegistroDialog from '../ExcluirRegistroDialog';
import AdicionarRegistroDialog from '../AdicionarRegistroDialog';
import EditarRegistroDialog from '../EditarRegistroDialog';
import AdicionarProfissionalDialog from '../AdicionarProfissionalDialog';
import ImportarCandidatosDialog from '../ImportarCandidatosDialog';
import ListaAlteracoesDialog from '../ListaAlteracoesDialog';
import EdicaoRegistroCriacao from '../EdicaoRegistroCriacao';

import ConfirmarProfissionalDialog from '../ConfirmarProfissionalDialog';
import formatCurrency from '../../../../utils/formatCurrency';
import ImportarMelhoresNomesDialog from '../ImportarMelhoresNomesDialog';
import { CelulaStatusFormatada } from './components/CelulaStatusFormatada';
import CelulaEdit from './components/CelulaEdit';
import CelulaAlteracao from './components/CelulaAlteracao';
import CelulaVisualizado from './components/CelulaVisualizado';
import FavoritarBloquearDialog from '../FavoritarBloquearDialog';

const { HeaderCell, Column, Cell } = Table;

function ListaRegistro({
  pedidoAntigo,
  idPedido,
  criacao,
  // eslint-disable-next-line react/prop-types
  filtroCancelado,
  // eslint-disable-next-line react/prop-types
  setFiltroCancelado,
  setModalAberta,
  tempoDeTrabalhoEmMinutos,
}) {
  const [profissoes, setProfissoes] = useState([]);

  const dispatch = useDispatch();
  const pedido = useSelector((state) => state.pedido.pedido);
  const registros = useSelector((state) => state.pedido.registros);
  const camposInput = useSelector((state) => state.pedido.camposInput);

  const triggerRef = useRef(null);
  const [exibirAdicionarDialog, setExibirAdicionarDialog] = useState(false);
  const [exibirExcluirDialog, setExibirExcluirDialog] = useState(false);
  const [exibirEditarDialog, setExibirEditarDialog] = useState(false);
  const [exibirEditarCriacao, setExibirEditarCriacao] = useState(false);
  const [exibirAceitarDialog, setExibirAceitarDialog] = useState(false);
  const [favoritarBloquearDialog, setFavoritarBloquearDialog] = useState(false);
  const [exibirConfirmarDialog, setExibirConfirmarDialog] = useState(false);
  const [exibirCandidadosDialog, setExibirCandidatosDialog] = useState(false);
  const [exibirMelhoresNomesDialog, setExibirMelhoresNomesDialog] =
    useState(false);
  const [registroSelecionado, setRegistroSelecionado] = useState({
    profissao: {},
  });
  const [editarHorario, setEditarHorario] = useState({});

  const [registroCriacao, setRegistroCriacao] = useState({});
  const [registro, setRegistro] = useState([]);
  const [exibirAlteracoes, setExibirAlteracoes] = useState(false);
  const [uniformes, setUniformes] = useState([]);
  const [dadosEstab, setDadosEstab] = useState();
  const [calculoRegistro, setCalculoRegistro] = useState({
    feriado: 0,
    registros: [],
    tarifaFeriado: 0,
    valor: 0,
  });
  const [registrosProfissionais, setRegistrosProfissionais] = useState([]);
  const [registrosRemanescentes, setRegistrosRemanescentes] = useState([]);
  const [finishedTimeout, setFinishedTimeout] = useState(false);
  const [portal, setPortal] = useState(true);

  useEffect(() => {
    const buscarDadosApi = async () => {
      try {
        const resposta = await api.get('profissao');
        setProfissoes(resposta.data);
      } catch (error) {
        toast.error(error.message);
      }
    };

    buscarDadosApi();
  }, []);

  useEffect(() => {
    const buscarEstabelecimentos = async () => {
      const idEstabelecimento = camposInput.id_estabelecimento;
      if (idEstabelecimento === null) return;
      try {
        const resposta = await api.get(`estabelecimento/${idEstabelecimento}`);
        setDadosEstab(resposta.data);
      } catch (error) {
        toast.error(error.message);
      }
    };

    buscarEstabelecimentos();
  }, [tempoDeTrabalhoEmMinutos, camposInput]);

  useEffect(() => {
    if (!finishedTimeout) {
      setTimeout(() => {
        setFinishedTimeout(true);
      }, 1000);
      return;
    }
    if (registros.length === 0) {
      return;
    }
    const dadosRegistroCriacao = async () => {
      if (!criacao) {
        return;
      }
      try {
        const horaInicio = camposInput.hora_inicio_texto.substr(0, 2);
        const minutosInicio = camposInput.hora_inicio_texto.substr(2, 3);
        const horaFim = camposInput.hora_fim_texto.substr(0, 2);
        const minutosFim = camposInput.hora_fim_texto.substr(2, 3);
        const dataPedido = pedidoAntigo
          ? camposInput.data
          : camposInput.data[0];
        const dataInicio = setMinutes(
          setHours(dataPedido, horaInicio),
          minutosInicio
        );

        let newDataFim = setMinutes(setHours(dataPedido, horaFim), minutosFim);

        if (isBefore(newDataFim, dataInicio)) {
          newDataFim = addDays(newDataFim, 1);
        }

        const horarioInicioSemFuso = dataInicio.toString();
        const horarioFimSemFuso = newDataFim.toString();
        const dataPedidoSemFuso = dataPedido.toString();

        const registroBody = registros.map((item) => ({
          fake_id: item.fake_id,
          data: dataPedidoSemFuso,
          hora_inicio: item.hora_inicio
            ? item.hora_inicio
            : horarioInicioSemFuso,
          hora_fim: item.hora_fim ? item.hora_fim : horarioFimSemFuso,
          id_profissional: item.id_profissional ? item.id_profissional : '',
          nome: item.nome_profissional ? item.nome_profissional : '',
          id_profissao: item.profissao.id,
        }));

        const body = {
          id_estab: camposInput.id_estabelecimento,
          intervalo: 0,
          registros: registroBody,
          portal,
        };
        const result = await api.post(`/calcular-valor-adm/pedido`, body);
  
        const registrosMap = result.data.registros.map((item) => item);
        setCalculoRegistro({
          feriado: result.data.feriado,
          registros: registrosMap,
          tarifaFeriado: result.data.tarifaFeriado,
          valor: result.data.valor,
        });
      } catch (error) {
        console.log(error);
        toast.error(error.message);
      }
    };
    dadosRegistroCriacao();
  }, [registros]);

  const editorHorario = async (regist) => {
    const findRegIndex = calculoRegistro.registros.findIndex(
      (find) => find.fake_id === regist.fake_id
    );

    const body = {
      id_estab: camposInput.id_estabelecimento,
      intervalo: 0,
      registros: [regist],
      portal,
    };

    const result = await api.post(`/calcular-valor-adm/pedido`, body);

    dispatch(editaHorario(regist, findRegIndex));

    setCalculoRegistro((prevState) => {
      return produce(prevState, (draft) => {
        draft.registros[findRegIndex].hora_inicio = regist.hora_inicio;
        draft.registros[findRegIndex].hora_fim = regist.hora_fim;
        draft.registros[findRegIndex].valor = result.data.registros[0].valor;
        draft.registros[findRegIndex].valor_bruto =
          result.data.registros[0].valor_bruto;
        draft.registros[findRegIndex].nome = regist.nome_profissional;
      });
    });

    setExibirEditarCriacao(false);
  };

  const getDadosTabelaCriacao = useCallback(() => {
    return calculoRegistro.registros.map((r) => {
      const valorEventual =
        registros.length > 0
          ? registros &&
          registros.find((reg) => reg.fake_id === r.fake_id).valor_eventual
          : 0;
        const nomeProfissional = registros.length > 0 ? registros.find((reg) => reg.fake_id === r.fake_id).profissional.nome : ""
      return {
        ...r,
        id_profissao: r.id_profissao,
        profissao: profissoes.find((p) => p.id === r.id_profissao)
          ? profissoes.find((p) => p.id === r.id_profissao).profissao
          : '',
        checkin: r.checkin ? 'Realizado' : 'Aguardando',
        hora_inicio: format(new Date(r.hora_inicio), 'HH:mm'),
        hora_fim: format(new Date(r.hora_fim), 'HH:mm'),
        registro: r,
        nome: nomeProfissional,
        tarifa_estado: r.tarifa_estado,
        tarifa_dinamica: r.tarifa_dinamica,
        tarifa_feriado: r.tarifa_feriado,
        tarifa_repasse: r.tarifa_repasse,
        valor: formatCurrency(r.valor + valorEventual),
        valor_bruto: formatCurrency(r.valor_bruto),
        valor_eventual: formatCurrency(valorEventual),
        visualizado: r.visualizado
      };
    });
  }, [calculoRegistro]);
  useEffect(() => {
    if (registros.length === 0) {
      setCalculoRegistro({
        ...calculoRegistro,
        registros: [],
      });
    }
  }, [registros]);

  /* ----------------------------------- */

  function adicionarRegistro(registroLista) {
    if (!camposInput.id_estabelecimento) {
      toast.error('Precisa haver estabelecimento para criar registro');
      setExibirAdicionarDialog(false);
      return;
    }
    if (!camposInput.id_uniforme) {
      toast.error('Precisa escolher uniforme para criar registro');
      setExibirAdicionarDialog(false);
      return;
    }
    if (!camposInput.data) {
      toast.error('Precisa escolher data para criar registro');
      setExibirAdicionarDialog(false);
      return;
    }
    if (!camposInput.hora_inicio_texto) {
      toast.error('Precisa escolher horário de Início para criar registro');
      setExibirAdicionarDialog(false);
      return;
    }
    if (!camposInput.hora_fim_texto) {
      toast.error('Precisa escolher horário de fim para criar registro');
      setExibirAdicionarDialog(false);
      return;
    }
    const novosRegistros = [...registros];
    registroLista.forEach((r) => novosRegistros.push(r));
    dispatch(atualizarRegistros(novosRegistros));
    setExibirAdicionarDialog(false);
  }

  function editarRegistro(regist) {
    const index = registros.findIndex(
      (reg) => JSON.stringify(reg) === JSON.stringify(registroSelecionado)
    );
    const novosRegistros = [...registros];
    novosRegistros[index] = regist;
    dispatch(atualizarRegistros(novosRegistros));
    setExibirEditarDialog(false);
    setExibirEditarCriacao(false);
  }

  function removerRegistro(regist) {
    const registrosMantidos = registros.filter((r) => {
      if (r.id) return r.id !== regist.id;
      return r.fake_id !== regist.fake_id;
    });
    dispatch(atualizarRegistros(registrosMantidos));
    if (registrosMantidos.length === 0) {
      setCalculoRegistro({
        ...calculoRegistro,
        registros: [],
      });
    }
  }

  const getDadosTabela = useCallback(() => {

    let newReg = registros

    if (filtroCancelado === true) {

      newReg = registros.filter((r) => r.status !== 'Cancelado' && r.status !== 'Faltou')
    }



    return newReg.map((r) => {
      const profissionalDados = {};
      if (r.profissional) {
        profissionalDados.id_profissional = r.profissional.id;
        profissionalDados.nome = r.profissional.nome;
        profissionalDados.email = r.profissional.email;
        profissionalDados.telefone = r.profissional.telefone1;
      }


      return {
        ...r,
        id_profissao: r.profissao.id,
        profissao: profissoes.find((p) => p.id === r.profissao.id)
          ? profissoes.find((p) => p.id === r.profissao.id).profissao
          : '',
        ...profissionalDados,
        checkin: r.checkin ? 'Realizado' : 'Aguardando',
        registro: r,
        valor: r.valor ? formatCurrency(r.valor) : 0,
        valor_bruto: r.valor_bruto ? formatCurrency(r.valor_bruto) : 0,
        valor_eventual: r.valor_eventual ? formatCurrency(r.valor_eventual) : 0,
      };
    });
  }, [registros, profissoes, filtroCancelado]);

  useEffect(() => {
    const buscarUniformes = async () => {
      try {
        const resposta = await api.get('uniforme');
        setUniformes(resposta.data);
      } catch (error) {
        toast.error(error.message);
      }
    };

    buscarUniformes();
  }, []);

  const copiaAreaTransferencia = (rowData) => {
    const endereçoDoRegistro = `${rowData.endereco.logradouro}, ${rowData.endereco.numero === 0 ? 'S/N' : rowData.endereco.numero
      }. ${rowData.endereco.bairro.bairro} - ${rowData.endereco.bairro.cidade.cidade
      } / ${rowData.endereco.bairro.cidade.estado.estado}`;
    const formatRefeicao = () => {
      const { refeicao } = camposInput;
      if (refeicao === 'nao_informado') {
        return 'Não informado';
      }
      if (refeicao === 'sim') {
        return 'Sim';
      }
      return 'Não';
    };
    const uniforme = uniformes.find((u) => u.id === camposInput.id_uniforme);
    if (
      copy(`
*Nome:* ${camposInput.nome_estabelecimento}
*Serviço:* ${rowData.profissao.profissao}
*Data:* ${format(camposInput.data, 'dd/MM/yyyy')}
*Horário Início:* ${rowData.hora_inicio}
*Horário Fim:* ${rowData.hora_fim}
*Intervalo:* ${minutesToHourTime(camposInput.intervalo)}
*Repasse:* ${formatCurrency(rowData.valor)}
*Uniforme:* *Blusa:* ${uniforme.blusa}, *Calça:* ${uniforme.calca
        }, *Calçado:* ${uniforme.calcado}
*Endereço:* ${endereçoDoRegistro}
*Fornece refeição?:* ${formatRefeicao()}`)
    ) {
      document.getElementById('clipboardCopyRegistry').textContent =
        'Info Copiada';
    }
  };

  return (
    <>
      <Panel
        header={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h5>Registros</h5>{' '}
            <IconButton
            disabled={camposInput.id_estabelecimento === null || camposInput.id_uniforme === null || camposInput.data === null || camposInput.hora_inicio_texto === '' || camposInput.hora_fim_texto === ''}
              style={{ marginTop: '0', marginLeft: 7}}
              icon={<Plus />}
              onClick={() => {
                // setExibirEditarDialog(false);
                setExibirAdicionarDialog(true);
              }}
            />

            {!criacao && (
              <Checkbox
                title='Ocultar Cancelados'
                style={{marginLeft: '30px', marginTop: 0}}
                value={filtroCancelado}
                defaultChecked={filtroCancelado}
                onChange={(a, valor) => {
                  setFiltroCancelado(valor)
                }}

              >Ocultar Cancelados/Faltou</Checkbox>
            )}

          </div>
        }
      >

        <TabelaSemPaginacao dados={(criacao || pedidoAntigo) ? getDadosTabelaCriacao() : getDadosTabela()}>


          {criacao && <Column resizable flexGrow={1}>
            <HeaderCell>Editar</HeaderCell>
            <Cell dataKey="id" style={{ padding: '5px', marginTop: '-10px' }}>
              {(rowData) => (<IconButton
                appearance="subtle"
                onClick={() => {
                  setRegistroCriacao(rowData.registro);
                  setExibirEditarCriacao(true);
                }}
                icon={<Edit />}
                style={{
                  alignItems: 'center',
                  marginTop: '0px',
                }}
              />)}
            </Cell>
          </Column>}
          {!criacao && <Column resizable width={200}>
            <HeaderCell>Editar</HeaderCell>
            <CelulaEdit
              dataKey="id"
              setRegistroSelecionado={setRegistroSelecionado}
              setExibirEditarDialog={setExibirEditarDialog}
              setExibirAceitarDialog={setExibirAceitarDialog}
              setExibirConfirmarDialog={setExibirConfirmarDialog}
              setModalAberta={setModalAberta}
              setExibirExcluirDialog={setExibirExcluirDialog}
              setFavoritarBloquearDialog={setFavoritarBloquearDialog}
              triggerRef={triggerRef}
              setExibirCandidatosDialog={setExibirCandidatosDialog}
              copiaAreaTransferencia={copiaAreaTransferencia}
              registros={registros}
              setExibirMelhoresNomesDialog={setExibirMelhoresNomesDialog}
            />
          </Column>}
          <Column resizable width={240}>
            <HeaderCell>Nome do Profissional</HeaderCell>
            <CelulaStatusFormatada dataKey="nome" isRegistro/>
          </Column>
          <Column resizable width={140} sortable>
            <HeaderCell>Serviço</HeaderCell>
            <CelulaStatusFormatada dataKey="profissao" isRegistro/>
          </Column>
          <Column resizable width={150}>
            <HeaderCell>Telefone</HeaderCell>
            <CelulaStatusFormatada dataKey="telefone" isRegistro/>
          </Column>
          <Column resizable width={130}>
            <HeaderCell>Numero Registro</HeaderCell>
            <CelulaStatusFormatada dataKey="numero_registro" isRegistro/>
          </Column>
          {pedido.status === 'Finalizado' ? (
            <Column resizable width={70}>
              <HeaderCell>Avaliação</HeaderCell>
              <CelulaStatusFormatada 
              dataKey="avaliacao_prof" 
              isRegistro
              />
            </Column>
          ) : (
            <Column resizable width={0}>
              <HeaderCell>Avaliação</HeaderCell>
              <CelulaStatusFormatada dataKey="avaliacao_prof" isRegistro/>
            </Column>
          )}
          <Column resizable width={100} sortable>
            <HeaderCell>Status</HeaderCell>
            <CelulaStatusFormatada dataKey="status" isRegistro/>
          </Column>
          <Column resizable width={130} sortable>
            <HeaderCell>Check in</HeaderCell>
            <CelulaStatusFormatada dataKey="checkin" isRegistro/>
          </Column>
          <Column resizable width={130} sortable>
            <HeaderCell>Início</HeaderCell>
            <CelulaStatusFormatada dataKey="hora_inicio" isRegistro/>
          </Column>
          <Column resizable width={130} sortable>
            <HeaderCell>Fim</HeaderCell>
            <CelulaStatusFormatada dataKey="hora_fim" isRegistro/>
          </Column>
          {
              criacao && (
          <>
          <Column resizable width={130} sortable>
            <HeaderCell>Tarifa Estado</HeaderCell>
            <CelulaStatusFormatada dataKey="tarifa_estado" isRegistro/>
          </Column>
          <Column resizable width={130} sortable>
            <HeaderCell>Tarifa Dinânica</HeaderCell>
            <CelulaStatusFormatada dataKey="tarifa_dinamica" isRegistro/>
          </Column>
          <Column resizable width={130} sortable>
            <HeaderCell>Tarifa Feriado</HeaderCell>
            <CelulaStatusFormatada dataKey="tarifa_feriado" isRegistro/>
          </Column>
          <Column resizable width={130} sortable>
            <HeaderCell>Tarifa Repasse</HeaderCell>
            <CelulaStatusFormatada dataKey="tarifa_repasse" isRegistro/>
          </Column>
          </>
              )
            }
          
          <Column resizable width={100} sortable>
            <HeaderCell>Repasse</HeaderCell>
            <CelulaStatusFormatada dataKey="valor" isRegistro/>
          </Column>
          <Column resizable width={120} sortable>
            <HeaderCell>V. Eventual</HeaderCell>
            <CelulaStatusFormatada dataKey="valor_eventual" isRegistro/>
          </Column>         
          <Column resizable width={110} sortable>
            <HeaderCell>V.Registro</HeaderCell>
            <CelulaStatusFormatada dataKey="valor_bruto" isRegistro/>
          </Column>         
          {!criacao && (
            <Column resizable width={130}>
              <HeaderCell>Cancelar</HeaderCell>
              <CelulaAcao
                criacao
                dataKey="id"
                onDeletar={(regist) => {
                  dispatch(cancelarRegistro(regist));
                }}
                isRegistro
              />
            </Column>
          )}
          <Column resizable width={150}>
            <HeaderCell>Alterações</HeaderCell>
            <CelulaAlteracao
              criacao={criacao}
              setRegistro={setRegistro}
              setExibirAlteracoes={setExibirAlteracoes}
              isRegistro
            />
          </Column>
          {/* <Column resizable width={90}>
            <HeaderCell>Visualizado</HeaderCell>
            <CelulaVisualizado
            criacao={criacao}
            />
          </Column>   */}
          {criacao && (
            <Column resizable width={150}>
              <HeaderCell>Deletar</HeaderCell>
              <Cell
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '-15px',
                }}
              >
                {(rowData) => {
                  return (
                    <IconButton
                      icon={<TrashIcon size="5em" style={{ color: 'red' }} />}
                      style={{
                        color: '#fff',
                        backgroundColor: 'transparent',
                      }}
                      onClick={() => {
                        removerRegistro(rowData);
                        // deletarRegistroCriacao(rowData);
                      }}
                    />
                  );
                }}
              </Cell>
            </Column>
          )}
        </TabelaSemPaginacao>
      </Panel>

      <ExcluirRegistroDialog
        exibir={exibirExcluirDialog}
        registroExcluir={registroSelecionado}
        onExcluir={() => {
          removerRegistro(registroSelecionado);
          setExibirExcluirDialog(false);
        }}
        fechar={() => setExibirExcluirDialog(false)}
      />

      <FavoritarBloquearDialog
        exibir={favoritarBloquearDialog}
        onFechar={() => setFavoritarBloquearDialog(false)}
        registro={registroSelecionado}
        estabelecimento={camposInput.nome_estabelecimento}
        dadosEstabelecimento={dadosEstab}
      />

      <AdicionarRegistroDialog
        pedidoAntigo={pedidoAntigo}
        exibir={exibirAdicionarDialog}
        onFechar={() => setExibirAdicionarDialog(false)}
        onRegistrosAdicionado={adicionarRegistro}
        profissoes={profissoes}
        criacao={criacao}
      />
      <EditarRegistroDialog
        exibir={exibirEditarDialog}
        onFechar={() => setExibirEditarDialog(false)}
        onRegistroEditado={editarRegistro}
        profissoes={profissoes}
        registro={registroSelecionado}
        dadosEstabelecimento={dadosEstab}
      />

      <EdicaoRegistroCriacao
        exibir={exibirEditarCriacao}
        registro={registroCriacao}
        onFechar={() => setExibirEditarCriacao(false)}
        onRegistroEditado={editorHorario}
      />
      <AdicionarProfissionalDialog
        exibir={exibirAceitarDialog}
        onFechar={() => setExibirAceitarDialog(false)}
        registro={registroSelecionado}
      />
      <ImportarCandidatosDialog
        exibir={exibirCandidadosDialog}
        onFechar={() => setExibirCandidatosDialog(false)}
        registro={registroSelecionado}
      />
      <ImportarMelhoresNomesDialog
        exibir={exibirMelhoresNomesDialog}
        onFechar={() => setExibirMelhoresNomesDialog(false)}
        registro={registroSelecionado}
      />
      <ConfirmarProfissionalDialog
        idPedido={idPedido}
        exibir={exibirConfirmarDialog}
        onFechar={() => setExibirConfirmarDialog(false)}
        setModalAberta={setModalAberta}
        registro={registroSelecionado}
      />
      <ListaAlteracoesDialog
        registro={registro}
        exibir={exibirAlteracoes}
        setExibir={setExibirAlteracoes}
        onFechar={() => setExibirAlteracoes(false)}
      />
    </>
  );
}

ListaRegistro.propTypes = {
  pedidoAntigo: PropTypes.bool,
  criacao: PropTypes.bool,
  tempoDeTrabalhoEmMinutos: PropTypes.func,
  setModalAberta: PropTypes.func,
  idPedido: PropTypes.number.isRequired,
};

ListaRegistro.defaultProps = {
  pedidoAntigo: false,
  criacao: true,
  tempoDeTrabalhoEmMinutos: () => { },
  setModalAberta: () => { }
};

export default ListaRegistro;
