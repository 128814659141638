/* eslint-disable prettier/prettier */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import { Table } from 'rsuite';

import PropTypes from 'prop-types';
import theme from '../../../styles/theme';
import { DarkModeContext } from '../../../common/contexts/darkMode';

const { Cell } = Table;


export const fundo = (data) => {
  let cor;
  if (data.status_invoice && data.status_boleto === 'Pendente' || data.status_invoice === 'Pendente' && data.status_boleto === 'Criado') {
    cor = theme.yellowColor;
  } else if (data.status_boleto === 'Pago' || data.status_invoice === 'Pago') {
    cor = theme.darkGreen;
  } else if (data.status_invoice && data.status_boleto === 'Cancelado') {
    cor = theme.lightRed;
  } else if (data.status === 'Parcialmente Pago') {
    cor = theme.lightGreen;
  } else if (data.status === 'Devolvido') {
    cor = theme.darkRed;
  } else if (data.status === 'Expirado') {
    cor = theme.darkGray;
  } else if (data.status === 'Autorizado') {
    cor = theme.primaryColor;
  }
  return cor;
};

export const texto = (data) => {
  let cor;
  if (data.status === 'Confirmado' && data.checkin === 'Realizado') {
    cor = '#fafafa';
  }
  if (data.status_invoice && data.status_boleto === 'Cancelado') {
    cor = '#fafafa';
  } else if (data.status === 'Finalizado') {
    cor = '#fafafa';
  }
  return cor;
};
export const CelulaFormatada = ({ rowData, dataKey, ...props }) => {
  const { toggleMode } = useContext(DarkModeContext)

  return (
  <Cell
    {...props}
    style={{
      background: fundo(rowData),
      // color: '#fff', // texto(rowData),
      color: toggleMode ? '#fff' : '#000', // texto(rowData),
    }}
  >
    {rowData[dataKey]}
  </Cell>
)};

CelulaFormatada.propTypes = {
  rowData: PropTypes.object,
  dataKey: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

CelulaFormatada.defaultProps = {
  rowData: {},
  onChange: () => { },
};
