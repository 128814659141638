import styled from 'styled-components';

export const Tabela = styled.table`
  transition: all 300ms;

  max-width: 100%;
  background-color: transparent;
  border-collapse: collapse;
  border-spacing: 0;
  font-family: arial;

  border-bottom: #999999 solid 1px;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;

  thead > tr > th {
    position: sticky;
    top: -20px;
    /* background-color: #fff; */
  }

  th,
  td {
    font-size: 12px;
    padding: 8px;
    line-height: 20px;
    text-align: left;
    vertical-align: middle;
  }

  td:last-child {
    border-right: 0;
  }

  thead th {
    font-weight: normal;
    // background-color: #005dab;
    // color: #fff;
    font-size: 15px;
    border-bottom: #999999 solid 1px;
    align: center;
  }

  .trTableLogs:nth-child(odd) {
    background: #fff;
    .rs-table-cell {
      background: #fff;
    }
  }
  .trTableLogs:nth-child(even) {
    background: #f8f8f8;
    .rs-table-cell {
      background: #f8f8f8;
    }
  }

  /* tbody > tr:nth-child(even) > td,
  tbody > tr:nth-child(even) > th {
    background-color: #f8f8f8;
  } */
  /* .rs-theme-dark tbody > tr:nth-child(odd) > td,
  .rs-theme-dark tbody > tr:nth-child(odd) > th {
    background-color: #f0f;
  } */

  /* .rs-theme-dark .trTableLogs:nth-child(even) {
    background: #ff0;
    .rs-table-cell {
      background: #ff0;
    }
  } */

  // tr:hover {
  //   filter: brightness(25%);
  // }
`;

export const Linha = styled.tr`
  alignitems: 'center';
`;
