/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import { Plus, RemindFill } from '@rsuite/icons';

import {
  Table,
  Button,
  FlexboxGrid,
  Modal,
  Form,
  IconButton,
  Schema,
  SelectPicker,
  InputPicker,
  TagGroup,
  Tag,
  Popover,
  Whisper,
} from 'rsuite';
import { toast } from 'react-toastify';
import InfoOutlineIcon from '@rsuite/icons/InfoOutline';

import { Container } from '~/styles/tabela';

import api from '~/services/api';
import { CelulaAcao } from '~/components/Celulas/components/CelulaEditavel';
import { TabelaSemPaginacao } from '~/components/TabelaSemPaginacao';
import SearchTable from '../components/SearchTable';
import { ButtonFullBlue } from '../../../components/Buttons/ButtonFullBlue/styles';
import FiltroCidades from '../../Mensagens/components/FiltroCidades';
import FiltroEstabalecimentos from '../../Mensagens/components/FiltroEstabelecimentos';

const { HeaderCell, Column, Cell } = Table;

const { StringType, NumberType } = Schema.Types;

const modelo = Schema.Model({
  cupom: StringType().isRequired('Cupom Obrigatório'),
  desconto: NumberType()
    .min(0, 'Desconto deve ser maior do que zero')
    .max(100, 'Desconto deve ser menor do que 100')
    .isRequired('Desconto Obrigatório'),
});

function Cupons() {
  const [dados, setDados] = useState({
    listaInicial: [],
    cupomLista: [],
    cupomListaFiltrada: [],
    filtro: '',
  });
  const [carregando, setCarregando] = useState(true);
  const [apagarDiaDialog, setApagarDiaDialog] = useState({
    exibir: false,
    cupom: {},
  });
  const [adicionarCupomDialog, setAdicionarCupomDialog] = useState(false);
  const [editarCupomDialog, setEditarCupomDialog] = useState({
    exibir: false,
    cupom: {},
  });
  const [camposInput, setCamposInput] = useState({});
  const [filtroCidade, setFiltroCidade] = useState([]);
  const [filtroEstabelecimento, setFiltroEstabelecimento] = useState([]);

  async function buscarCupons() {
    const resposta = await api.get('cupom');
    const resposta1 = await api.get('cupom');
    const lista = resposta.data.map((item) => ({
      ...item,
      estabelecimento:
        (item.estabelecimento && item.estabelecimento.nome_fantasia) || '-',
      cidade: (item.cidade && item.cidade.cidade) || '-',
      ativo: item.ativo ? 'Ativo' : 'Desativado',
      desconto: `${item.desconto * 100} %`,
    }));
    setDados({
      listaInicial: resposta1.data,
      cupomLista: lista,
      cupomListaFiltrada: lista,
    });
    setCarregando(false);
  }

  const speaker = (
    <Popover>
      <p>
        Não é possível adicionar filtro de Estabelecimentos e Cidades ao mesmo
        cupom.
      </p>
    </Popover>
  );

  useEffect(() => {
    buscarCupons();
  }, []);

  function filtrarLista(texto) {
    setDados({
      ...dados,
      cupomListaFiltrada: dados.cupomLista.filter((cupom) =>
        cupom.cupom
          .toString()
          .toUpperCase()
          .includes(texto ? texto.toUpperCase() : '')
      ),
      filtro: texto,
    });
  }

  function abrirApagarDialog(cupom) {
    setApagarDiaDialog({
      exibir: true,
      cupom,
    });
  }

  function fecharApagarDialog() {
    setApagarDiaDialog({
      exibir: false,
      cupom: {},
    });
  }

  async function excluirCupom() {
    try {
      await api.delete(`/cupom/${apagarDiaDialog.cupom.id}`);
      toast.success('Cupom excluido com sucesso');
      fecharApagarDialog();
      buscarCupons();
    } catch (e) {
      toast.error(e.message);
    }
  }

  async function adicionarCupom() {
    try {
      if (camposInput.ativo !== null) {
        setCarregando(true);
        const body = {
          ...camposInput,
          desconto: camposInput.desconto / 100,
          cidades: filtroCidade.map((city) => city.id),
          estabelecimentos: filtroEstabelecimento.map((estab) => estab.id),
        };
        await api.post(`/cupom`, body);
        toast.success('Cupom criado com sucesso');
        setAdicionarCupomDialog(false);
        setFiltroCidade([]);
        setFiltroEstabelecimento([]);
        buscarCupons();
      } else {
        toast.error('Ativo obrigatório');
      }
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
  }

  async function salvarCupom(cupom) {
    try {
      let body = {};
      setCarregando(true);
      body = {
        ...cupom,
        desconto: cupom.desconto / 100,
      };
      await api.put(`/cupom/${cupom.id}`, body);
      toast.success('Cupom atualizado com sucesso');
      setEditarCupomDialog({ exibir: false, cupom: {} });
      buscarCupons();
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
  }

  return (
    <Container>
      <h2>Cupons</h2>
      <FlexboxGrid justify="start" style={{ gap: '10px' }}>
        <SearchTable estilo={{ width: '300px' }} filtrarLista={filtrarLista} />
        <IconButton
          style={{ marginTop: '0' }}
          icon={<Plus />}
          onClick={() => {
            setAdicionarCupomDialog(true);
          }}
        />
      </FlexboxGrid>
      <FlexboxGrid style={{ width: '100%' }}>
      <TabelaSemPaginacao
        carregando={carregando}
        dados={dados.cupomListaFiltrada}
      >
        <Column resizable width={240} sortable>
          <HeaderCell>Cupom</HeaderCell>
          <Cell dataKey="cupom" />
        </Column>
        <Column resizable width={150} sortable>
          <HeaderCell>Desconto</HeaderCell>
          <Cell dataKey="desconto" />
        </Column>
        <Column resizable width={150} sortable>
          <HeaderCell>Ativo</HeaderCell>
          <Cell dataKey="ativo" />
        </Column>
        <Column resizable width={150} sortable>
          <HeaderCell>Cidade</HeaderCell>
          <Cell dataKey="cidade" />
        </Column>
        <Column resizable width={330} sortable>
          <HeaderCell>Estabelecimento</HeaderCell>
          <Cell dataKey="estabelecimento" />
        </Column>

        <Column resizable width={200}>
          <HeaderCell>Editar</HeaderCell>
          <CelulaAcao
            dataKey="id"
            onClick={(id) => {
              const cupom = dados.listaInicial.find((c) => c.id === id);
              setEditarCupomDialog({
                exibir: true,
                cupom: {
                  ...cupom,
                  desconto: cupom.desconto * 100,
                },
              });
            }}
            onDelete={abrirApagarDialog}
          />
        </Column>
      </TabelaSemPaginacao>
      </FlexboxGrid>
      <Modal
        backdrop="static"
        open={adicionarCupomDialog}
        onClose={() => setAdicionarCupomDialog(false)}
        size="xs"
      >
        <Form
          model={modelo}
          onChange={setCamposInput}
          onSubmit={(status) => status && adicionarCupom()}
        >
          <Modal.Header>
            <h3>Adicionar Cupom</h3>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.ControlLabel>Cupom</Form.ControlLabel>
              <Form.Control name="cupom" />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Desconto %</Form.ControlLabel>
              <Form.Control name="desconto" type="number" />
            </Form.Group>
            <Form.ControlLabel>
              <p style={{ display: 'flex', alignItems: 'center' }}>
                Filtros{' '}
                <Whisper
                  placement="bottom"
                  trigger="hover"
                  controlId="control-id-hover"
                  speaker={speaker}
                >
                  <IconButton
                    style={{
                      backgroundColor: 'transparent',
                      marginRight: '15px',
                    }}
                    size="xs"
                    icon={<InfoOutlineIcon />}
                  />
                </Whisper>{' '}
              </p>
            </Form.ControlLabel>
            <TagGroup style={{ marginBottom: '15px', marginTop: '15px' }}>
              {filtroEstabelecimento.map((estabelecimento) => (
                <Tag
                  closable
                  onClose={() => {
                    const novaLista = filtroEstabelecimento.filter(
                      (e) => e.id !== estabelecimento.id
                    );
                    setFiltroEstabelecimento(novaLista);
                  }}
                  key={estabelecimento.id}
                >
                  Estabelecimento: {estabelecimento.label}
                </Tag>
              ))}
              {filtroCidade.map((cidade) => (
                <Tag
                  closable
                  onClose={() => {
                    const novaLista = filtroCidade.filter(
                      (c) => c.id !== cidade.id
                    );
                    setFiltroCidade(novaLista);
                  }}
                  key={cidade.id}
                >
                  Cidade: {cidade.label}
                </Tag>
              ))}
            </TagGroup>
            <Form.Group>
              <FiltroCidades
                disabled={filtroEstabelecimento.length > 0}
                onItemAdicionado={(value, item) => {
                  setFiltroCidade([
                    ...filtroCidade,
                    {
                      id: item.id,
                      label: value,
                    },
                  ]);
                }}
              />
            </Form.Group>
            <Form.Group>
              <FiltroEstabalecimentos
                disabled={filtroCidade.length > 0}
                onItemAdicionado={(value, item) => {
                  setFiltroEstabelecimento([
                    ...filtroEstabelecimento,
                    {
                      id: item.id,
                      label: value,
                    },
                  ]);
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Ativo</Form.ControlLabel>
              <SelectPicker
                name="ativo"
                data={[
                  {
                    value: 0,
                    label: 'Desativado',
                  },
                  {
                    value: 1,
                    label: 'Ativo',
                  },
                ]}
                searchable={false}
                cleanable={false}
                onSelect={(value) => {
                  if (value === 0) {
                    camposInput.ativo = false;
                  } else {
                    camposInput.ativo = true;
                  }
                }}
                placeholder="Selecione"
              />
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button
              onClick={() => setAdicionarCupomDialog(false)}
              appearance="subtle"
            >
              Cancelar
            </Button>
            <ButtonFullBlue type="submit" appearance="primary">
              Adicionar
            </ButtonFullBlue>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        backdrop="static"
        open={editarCupomDialog.exibir}
        onClose={() => setEditarCupomDialog({ exibir: false, cupom: {} })}
        size="xs"
      >
        <Form
          model={modelo}
          onSubmit={(status) => status && salvarCupom(editarCupomDialog.cupom)}
          onChange={(valor) => {
            setEditarCupomDialog({
              exibir: true,
              cupom: valor,
            });
          }}
          formValue={editarCupomDialog.cupom}
        >
          <Modal.Header>
            <h3>Editar Cupom</h3>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.ControlLabel>Cupom</Form.ControlLabel>
              <Form.Control
                name="cupom"
                value={editarCupomDialog.cupom.cupom || ''}
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Desconto %</Form.ControlLabel>
              <Form.Control
                name="desconto"
                type="number"
                value={editarCupomDialog.cupom.desconto || ''}
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Ativo</Form.ControlLabel>
              <InputPicker
                name="ativo"
                data={[
                  {
                    value: 0,
                    label: 'Desativado',
                  },
                  {
                    value: 1,
                    label: 'Ativo',
                  },
                ]}
                value={editarCupomDialog.cupom.ativo ? 1 : 0}
                onSelect={(value) => {
                  if (value === 0) {
                    setEditarCupomDialog({
                      ...editarCupomDialog,
                      cupom: { ...editarCupomDialog.cupom, ativo: false },
                    });
                  } else {
                    setEditarCupomDialog({
                      ...editarCupomDialog,
                      cupom: { ...editarCupomDialog.cupom, ativo: true },
                    });
                  }
                }}
                placeholder="Selecione"
              />
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button
              onClick={() => setEditarCupomDialog({ exibir: false, cupom: {} })}
              appearance="subtle"
            >
              Cancelar
            </Button>
            <ButtonFullBlue type="submit" appearance="primary">
              Adicionar
            </ButtonFullBlue>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        backdrop="static"
        open={apagarDiaDialog.exibir}
        onClose={fecharApagarDialog}
        size="xs"
      >
        <Modal.Header>
          <h3>Apagar Cupom</h3>
        </Modal.Header>
        <Modal.Body>
          Você realmente deseja apagar este cupom? <br /> <br />
          id: {apagarDiaDialog.cupom.id} <br />
          cupom: {apagarDiaDialog.cupom.cupom} <br />
          desconto: {apagarDiaDialog.cupom.desconto} <br />
          Essa ação é irreversível{' '}
          <RemindFill
            style={{
              color: '#ffb300',
              fontSize: 24,
            }}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={fecharApagarDialog} appearance="subtle">
            Cancelar
          </Button>
          <ButtonFullBlue onClick={() => excluirCupom()} appearance="primary">
            Deletar
          </ButtonFullBlue>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default Cupons;
