/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import { Modal, FlexboxGrid, Button } from 'rsuite';
import PropTypes from 'prop-types';
import { addDays, isBefore, subDays } from 'date-fns';
import { toast } from 'react-toastify';
import format from 'date-fns/format';
import { InputData } from '~/components/InputData';
import api from '~/services/api';

const GerarSegundaVia = ({
  exibir,
  setExibir,
  setCarregando,
  buscarRelatorios,
  idFatura,
}) => {
  const today = addDays(new Date(), 2);
  const [filtroData, setFiltroData] = useState(today);

  async function segundaVia() {
    setExibir(false);
    setCarregando(true);
    await api
      .post(`iugu/faturas/segunda-via/${idFatura}`, {
        due_date: format(new Date(filtroData), 'yyyy-MM-dd'),
      })
      .then(async () => {
        await buscarRelatorios();
        setCarregando(false);
        toast.success('Segunda via gerada com sucesso!');
      })
      .catch((err) => {
        toast.error(err.message);
        setCarregando(false);
      });
  }

  const locale = {
    sunday: 'Dom',
    monday: 'Seg',
    tuesday: 'Ter',
    wednesday: 'Qua',
    thursday: 'Qui',
    friday: 'Sex',
    saturday: 'Sab',
    ok: 'Ok',
    today: 'Hoje',
    yesterday: 'Ontem',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos',
  };

  return (
    <Modal open={exibir} onClose={() => setExibir(false)} size="xs">
      <Modal.Header>Gerar Segunda Via</Modal.Header>
      <Modal.Body>
        <FlexboxGrid
          style={{ justifyContent: 'center', alignItems: 'center', gap: 10 }}
        >
          <p>Informe a nova data de vencimento:</p>
          <InputData
            cleanable={false}
            ranges={[]}
            isoWeek
            oneTap
            locale={locale}
            value={filtroData}
            onChange={setFiltroData}
            disabledDate={(data) => isBefore(data, subDays(new Date(), 1))}
          />
        </FlexboxGrid>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setExibir(false)} appearance="subtle">
          Cancelar
        </Button>
        <Button appearance="primary" type="button" onClick={segundaVia}>
          Gerar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

GerarSegundaVia.propTypes = {
  exibir: PropTypes.bool.isRequired,
  setExibir: PropTypes.bool.isRequired,
  setCarregando: PropTypes.bool.isRequired,
  buscarRelatorios: PropTypes.func.isRequired,
  idFatura: PropTypes.string.isRequired,
};

export default GerarSegundaVia;
