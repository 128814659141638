/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { Form, Schema, Button, Modal, SelectPicker } from 'rsuite';
import PropTypes from 'prop-types';
import api from '~/services/api';
import { ButtonFullBlue } from '../../../../components/Buttons/ButtonFullBlue/styles';

const { StringType, NumberType } = Schema.Types;

const modelo = Schema.Model({
  estabelecimento: StringType().isRequired('Cliente Obrigatório'),
  profissaoId: NumberType().isRequired('Cliente Obrigatório'),
  tempoExperienciaId: NumberType().isRequired('Cliente Obrigatório'),
});

function AdicionarExperiencia({ exibir, fechar, experiencia }) {
  const [profissaoLista, setProfissaoLista] = useState([]);
  const [tempoExperienciaLista, setTempoExperienciaLista] = useState([]);
  const [camposInput, setCamposInput] = useState({
    estabelecimento: null,
    tempoExperienciaId: null,
    profissaoId: null,
  });

  useEffect(() => {
    setCamposInput({
      estabelecimento: experiencia.estabelecimento,
      tempoExperienciaId: experiencia.tempo_experiencia
        ? experiencia.tempo_experiencia.id
        : null,
      profissaoId: experiencia.profissao ? experiencia.profissao.id : null,
    });
    const carregarListas = async () => {
      const [profissao, tempoExperiencia] = await Promise.all([
        api.get('profissao'),
        api.get('tempo-experiencia'),
      ]);
      setProfissaoLista(profissao.data);
      setTempoExperienciaLista(tempoExperiencia.data);
    };

    carregarListas();
  }, [experiencia]);

  function salvarExperiencia() {
    const experienciaSalva = {
      profissao: profissaoLista.find((p) => p.id === camposInput.profissaoId),
      tempo_experiencia: tempoExperienciaLista.find(
        (t) => t.id === camposInput.tempoExperienciaId
      ),
      estabelecimento: camposInput.estabelecimento,
      id: experiencia.id,
    };

    setCamposInput({});

    experiencia = undefined;

    fechar(experienciaSalva);
  }

  return (
    <Modal open={exibir} onClose={() => fechar(null)} size="xs">
      <Form
        fluid
        model={modelo}
        onChange={setCamposInput}
        formDefaultValue={camposInput}
        onSubmit={(erro) => erro && salvarExperiencia()}
      >
        <Modal.Header>
          <Modal.Title>Adicionar Experiencia</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.ControlLabel>Cliente</Form.ControlLabel>
            <Form.Control name="estabelecimento" />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>Serviço</Form.ControlLabel>
            <Form.Control
              name="profissaoId"
              accepter={SelectPicker}
              placeholder="Selecione"
              data={profissaoLista.map((p) => {
                return {
                  label: p.profissao,
                  value: p.id,
                  role: 'profissão',
                };
              })}
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>Tempo de Experiencia</Form.ControlLabel>
            <Form.Control
              name="tempoExperienciaId"
              accepter={SelectPicker}
              placeholder="Selecione"
              data={tempoExperienciaLista.map((t) => {
                return {
                  label: t.tempo_experiencia,
                  value: t.id,
                  role: 'tempo-experiencia',
                };
              })}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => fechar(null)} appearance="subtle">
            Cancel
          </Button>
          <ButtonFullBlue type="submit" appearance="primary">
            Confirmar
          </ButtonFullBlue>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

AdicionarExperiencia.propTypes = {
  exibir: PropTypes.bool.isRequired,
  fechar: PropTypes.func.isRequired,
  experiencia: PropTypes.object,
};

AdicionarExperiencia.defaultProps = {
  experiencia: {},
};

export default AdicionarExperiencia;
