import { SelectPicker } from 'rsuite';
import PropTypes from 'prop-types';
import React from 'react';

const year = [
  {
    label: '2019',
    value: '2019',
  },
  {
    label: '2020',
    value: '2020',
  },
  {
    label: '2021',
    value: '2021',
  },
  {
    label: '2022',
    value: '2022',
  },
  {
    label: '2023',
    value: '2023',
  },
  {
    label: '2024',
    value: '2024',
  },
  {
    label: '2025',
    value: '2025',
  },
];

export function NovoFiltro({ anoAtual, setAnoAtual, setPeriodoSelecionado }) {
  return (
    <SelectPicker
      cleanable={false}
      searchable={false}
      defaultValue={String(anoAtual)}
      data={year}
      style={{ width: 224 }}
      onSelect={(value) => {
        setAnoAtual(value);
        setPeriodoSelecionado(null);
      }}
    />
  );
}

NovoFiltro.propTypes = {
  anoAtual: PropTypes.number.isRequired,
  setAnoAtual: PropTypes.func.isRequired,
  setPeriodoSelecionado: PropTypes.func.isRequired,
};
