/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import styled, { css } from 'styled-components';
import theme from '~/styles/theme';
import { FlexboxGrid as Flexgrid } from 'rsuite';
import { Container as container } from '~/styles/tabela';

export const NotificationToggle = styled.div`
  .rs-toggle-checked .rs-toggle-presentation {
    background-color: ${theme.darkGreen};
  }
${props => props.darkMode ? css`
.rs-toggle-presentation {
      background: ${theme.darkGray};
  }` : css`
  .rs-toggle-presentation {
  background: ${theme.lightGray};
  }`}
`;

export const Container = styled(container)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    @media (max-width: 968px) {
      flex-direction: column;
    }
  `}
`;

export const Content = styled(Flexgrid)`
  ${({ theme }) => css`
    width: 50%;
    @media (max-width: 968px) {
      width: 100%;
    }
  `}
`;
