/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './styles.css'

import {
  Panel,
  TagPicker,
  SelectPicker,
  Grid,
  Row,
  Col,
  Form,
  FlexboxGrid,
  Input,
  TagGroup,
  Tag,
  IconButton,
} from 'rsuite';
import PlusIcon from '@rsuite/icons/Plus';

import api from '~/services/api';
import { ResponsiveForm } from '~/components/ResponsiveForm';
import { RenderInput } from './components/RenderInput';

function CamposDadosFuncionamento({ dadosFuncionamento, setDadosFuncionamento }) {
  const ref = useRef(null);
  const [dadosApi, setDadosApi] = useState({
    periodoFuncionamento: [],
    diasFuncionamento: [],
    tiposEstabelecimentos: [],
    profissoes: [],
    habilidades: [],
  });
  const [typing, setTyping] = useState(false);
  const [typingIdServico, setTypingIdServico] = useState();
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    const buscarDados = async () => {
      const [
        tiposEstabelecimentosLista,
        diasFuncionamentoLista,
        periodoFuncionamentoLista,
        profissaoLista,
      ] = await Promise.all([
        api.get('tipo-estabelecimento'),
        api.get('dia-funcionamento'),
        api.get('periodo-funcionamento'),
        api.get('profissao'),
      ]);
      setDadosApi({
        periodoFuncionamento: periodoFuncionamentoLista.data,
        diasFuncionamento: diasFuncionamentoLista.data,
        tiposEstabelecimentos: tiposEstabelecimentosLista.data,
        profissoes: profissaoLista.data,
        habilidades: profissaoLista.data.map((profissao) => profissao.habilidades)
      })
    };

    buscarDados();
  }, []);


  /* ============Remove tag============== */

  const removeTag = (tag) => {
    const newHabilidades = dadosFuncionamento.habilidades.map((servico) => {
      if(servico[0].profissao.id === tag.profissao.id) {
        const nextTags = servico.filter(hab => hab.id !== tag.id)
        return nextTags
      }
      return servico
    })
    setDadosFuncionamento({
      ...dadosFuncionamento,
      habilidades: newHabilidades
    });
  };
  /* ========================== */


  const handleProfissoes = (ids) => {
    // const inclusao = dadosFuncionamento.profissoes.length < ids.length
    setDadosFuncionamento({
      ...dadosFuncionamento,
      profissoes: ids ? dadosApi.profissoes.filter((profissao) => ids.includes(profissao.id)) : [],
      habilidades: ids ? dadosApi.habilidades.filter((habilidade) => ids.includes(habilidade[0].profissao.id)): []
    }
    )
  }

  const handleDias = (parametros) => {
    setDadosFuncionamento({
      ...dadosFuncionamento,
      diasFuncionamento: parametros
    })
  }

  const handleAtuacao = (parametros) => {
    setDadosFuncionamento({
      ...dadosFuncionamento,
      tiposEstabelecimentos: parametros
    })
  }

  const handleFuncionamento = (parametros) => {
    setDadosFuncionamento({
      ...dadosFuncionamento,
      periodoFuncionamento: parametros
    })
  }

  return (
    <Panel
      header={<h4>Dados Funcionamento</h4>}
      collapsible
      bordered
      defaultExpanded
    >
      <Grid fluid>
        <Row style={{ textAlignLast: 'start' }}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Form.Group>
              <ResponsiveForm
                label="Dias de Funcionamento"
                name="dias_funcionamento"
                accepter={TagPicker}
                placeholder="Selecione"
                placement="topStart"
                block
                onChange={handleDias}
                data={dadosApi.diasFuncionamento.map((t) => {
                  return {
                    label: t.dia,
                    value: t.id,
                  };
                })}
              />
            </Form.Group>
            <Form.Group>
              <ResponsiveForm
                label="Período de Funcionamento"
                name="periodos_funcionamento"
                accepter={TagPicker}
                placeholder="Selecione"
                placement="topStart"
                block
                onChange={handleFuncionamento}
                data={dadosApi.periodoFuncionamento.map((t) => {
                  return {
                    label: t.periodo,
                    value: t.id,
                  };
                })}
              />
            </Form.Group>
          </Col>

          <Col xs={24} sm={24} md={12} lg={12}>
            <Form.Group>
              <ResponsiveForm
                label="Área de Atuação"
                name="tipo_estabelecimento"
                accepter={SelectPicker}
                placeholder="Selecione"
                placement="topStart"
                block
                onChange={handleAtuacao}
                data={dadosApi.tiposEstabelecimentos.map((t) => {
                  return {
                    label: t.tipo_estabelecimento,
                    value: t.id,
                  };
                })}
              />
            </Form.Group>
            <Form.Group>
              <ResponsiveForm
                label="Serviços"
                name="profissoes"
                accepter={TagPicker}
                virtualized
                placeholder="Selecione"
                placement="topStart"
                block
                onChange={(ids) => {
                  handleProfissoes(ids)
                }}
                data={dadosApi.profissoes.map((t) => {
                  return {
                    label: t.profissao,
                    value: t.id,
                  };
                })}
              />
            </Form.Group>
          </Col>
        </Row>
        <br />
        <FlexboxGrid justify="space-between">
          {dadosFuncionamento.profissoes && dadosFuncionamento.profissoes.map((profissao, index) => {
            const profissaoEncontrada = dadosApi.profissoes.find((p) => p.id === profissao.id);
            if (profissaoEncontrada && profissaoEncontrada.profissao !== 'Outros') {
              return (

                <FlexboxGrid.Item className='habGridItem' componentClass={Col} colspan={12} md={12}>
                  <Form.Group key={profissao.id}>
                    <Form.ControlLabel className='habLabel'>{`Habilidades para ${profissao.profissao}`}</Form.ControlLabel>

                    <TagGroup className='habTagGroup'>
                          {dadosFuncionamento.habilidades && dadosFuncionamento.habilidades[index].map((tag, indice) => (
                            <Tag className='habTagItem' name="habilidades" key={indice} closable onClose={() => removeTag(tag)}>
                              {tag.habilidade}
                            </Tag>
                          ))}
                        <RenderInput
                        props={dadosFuncionamento.habilidades[index]}
                        setDadosFuncionamento={setDadosFuncionamento}
                        dadosFuncionamento={dadosFuncionamento}
                        />
                    </TagGroup>
                  </Form.Group>
                </FlexboxGrid.Item>
              );
            }
            return null;
          })}
        </FlexboxGrid>
      </Grid>
    </Panel>
  );
}

CamposDadosFuncionamento.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  dadosFuncionamento: PropTypes.array,
  setDadosFuncionamento: PropTypes.func,
};

CamposDadosFuncionamento.defaultProps = {
  setDadosFuncionamento: () => { },
  dadosFuncionamento: [],
};

export default CamposDadosFuncionamento;
