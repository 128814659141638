/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Button } from 'rsuite';
import { toast } from 'react-toastify';
import api from '~/services/api';
import { ButtonFullBlue } from '../../../../../components/Buttons/ButtonFullBlue/styles';

function AdicionarImagemDialog({ exibir, uniforme, onFechar }) {
  const [imagem, setImagem] = useState();
  const [carregando, setCarregando] = useState(false);

  async function salvarImagem() {
    if (!imagem) {
      toast.error('Imagem é obrigatória');
      return;
    }
    setCarregando(true);
    try {
      const formData = new FormData();
      formData.append('uniforme', imagem);
      await api.put(`/uniforme/${uniforme.id}/imagem`, formData);
      toast.success('Imagem salva com sucesso!');
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
    onFechar();
  }

  return (
    <Modal backdrop="static" open={exibir} onClose={onFechar} size="xs">
      <Form onSubmit={(status) => status && salvarImagem()}>
        <Modal.Header>
          <h3>Adicionar Imagem</h3>
        </Modal.Header>
        <Modal.Body>
          {uniforme.imagem && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h6>Imagem atual: </h6>
              <img src={uniforme.imagem.caminho} height={100} alt="Icone" />
            </div>
          )}
          <Form.Group>
            <Form.ControlLabel>Blusa</Form.ControlLabel>
            <Form.Control readOnly value={uniforme.blusa || ''} />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>Calça</Form.ControlLabel>
            <Form.Control readOnly value={uniforme.calca || ''} />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>Calçado</Form.ControlLabel>
            <Form.Control readOnly value={uniforme.calcado || ''} />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>Cabelo</Form.ControlLabel>
            <Form.Control readOnly value={uniforme.cabelo || ''} />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>Barba</Form.ControlLabel>
            <Form.Control readOnly value={uniforme.barba || ''} />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>Imagem</Form.ControlLabel>
            <Form.Control
              name="imagem"
              type="file"
              accept="image/*"
              onChange={(_, evento) => {
                setImagem(evento.target.files[0]);
              }}
            />
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={onFechar} appearance="subtle">
            Cancelar
          </Button>
          <ButtonFullBlue
            type="submit"
            loading={carregando}
            appearance="primary"
          >
            Adicionar
          </ButtonFullBlue>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

AdicionarImagemDialog.propTypes = {
  exibir: PropTypes.bool.isRequired,
  onFechar: PropTypes.func.isRequired,
  uniforme: PropTypes.object.isRequired,
};

export default AdicionarImagemDialog;
