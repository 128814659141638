/* eslint-disable react/forbid-prop-types */
import React, { useState, useContext } from 'react';
import { Modal, Button } from 'rsuite';
import { useSelector } from 'react-redux'; 
import { toast } from 'react-toastify';
import api from '~/services/api';
import { DarkModeContext } from '~/common/contexts/darkMode';
import PropTypes from 'prop-types';
import { ButtonBorderBlue } from '../../../../components/Buttons/ButtonBorderBlue/styles';
import { ButtonFullBlue } from '../../../../components/Buttons/ButtonFullBlue/styles';

// eslint-disable-next-line react/prop-types
function ModalEnviarNotificacaoInadimplentes({filtroData, textoDigitado, statusFiltro, atributo}) {
  const [exibir, setExibir] = useState(false);
  const { toggleMode } = useContext(DarkModeContext)
  const [atributoSelecionado, setAtributoSelecionado] = useState('nome');
  const loggeduser = useSelector((state) => state.setUser.data);


  async function enviarNotificacao() {
           
    try {
      setAtributoSelecionado(atributo)
        const enviarNot = await api.post('/notificacao/notificacao-inadimplentes',
          { 
          datas: filtroData,
          [atributoSelecionado]: textoDigitado,
          enviadoPor: loggeduser.nome,
          status: statusFiltro,
         }    
        )
        toast.success(enviarNot.data.message)

        setExibir(false)
    } catch (error) {
        console.log(error)
        toast.error(error.message);
    }
   
  }

  return (
    <>
      <ButtonBorderBlue appearance="ghost" onClick={() => setExibir(true)} darkMode={toggleMode} >
        Enviar Notificação
      </ButtonBorderBlue>
      <Modal open={exibir} onClose={() => setExibir(false)} size="sm">
        <Modal.Header>
          <Modal.Title>Enviar Notificação</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Enviar Notificação para todos os estabelecimentos inadimplentes filtrados ?</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setExibir(false)} appearance="subtle">
            Cancelar
          </Button>
          <ButtonFullBlue
            appearance="primary"
            onClick={() => enviarNotificacao()}
          >
            Enviar
          </ButtonFullBlue>
        </Modal.Footer>
      </Modal>
    </>
  );
}



export default ModalEnviarNotificacaoInadimplentes;