/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Panel, Button, Toggle, Grid, Row, Col, Form, RadioGroup, Radio, SelectPicker} from 'rsuite';
import api from '~/services/api';
import VisulizarImagem from '~/components/VisualizarImagem';
import InputMascara from '~/components/InputMascara';
import { mascaraCpfCnpj, mascaraTelefone } from '~/components/Mascaras';
import { ResponsiveForm } from '~/components/ResponsiveForm';
import {
  TogglesFav,
  TogglesBloq,
} from '../../../relatorios/CarteiraClientes/styles';
import { ModalNewPassword } from './ModalNewPassword';

function CamposDadosProfissional({
  editar,
  camposInput,
  qtdColaborador,
  setQtdColaborador,
  logoAlterada,
  cardapioAlterado,
  recuperarSenha,
  marginPersonalizada,
  novaSenha,
}) {
  const [cardapio, setCardapio] = useState();
  
  const [open, setOpen] = useState();
  const [estadoCarregado, setEstadoCarregado] = useState(false)
  const [campoColaboradores, setCampoColaboradores] = useState([])
  const [descobriuInfo, setDescobriuInfo] = useState([])
  const [logo, setLogo] = useState();

  useEffect(() => {
    const buscarQuantidadeColaborador = async () => {
      try {
        const response = await api.get(`/qtd-colaboradores`);
        
        const descobriuSwitch = await api.get('descobriu-switch')

        setCampoColaboradores(response.data.quantidades);

        setDescobriuInfo(descobriuSwitch.data)
      } catch (error) {
        console.log(error);
      }
    }

    return buscarQuantidadeColaborador();
  }, [])

  function test() {
    console.log(descobriuInfo)
    console.log(camposInput)
  }

  return (
    <Panel header={<h4>Dados Cliente</h4>} collapsible bordered defaultExpanded>
      <Grid fluid>
        <Row className="show-grid" style={{ textAlignLast: 'start' }}>
          <Col xs={24} sm={24} md={8}>
            <Form.Group>
              <ResponsiveForm
                label="Email"
                name="email"
                value={camposInput.email || ''}
              />
            </Form.Group>
            {!editar && (
              <>
                <Form.Group>
                  <ResponsiveForm
                    label="Senha"
                    name="senha"
                    value={camposInput.senha || ''}
                    type="password"
                  />
                </Form.Group>
                <Form.Group>
                  <ResponsiveForm
                    label="Confirmar Senha"
                    name="confirmarSenha"
                    type="password"
                    value={camposInput.confirmarSenha || ''}
                  />
                </Form.Group>
              </>
            )}
            <Form.Group>
              <ResponsiveForm
                label="Nome do Cliente"
                name="nome_fantasia"
                value={camposInput.nome_fantasia || ''}
              />
            </Form.Group>
           
            <ResponsiveForm
                label="Como descobriu a Switch"
                name="id_descobriu_switch"
                accepter={SelectPicker}
                searchable={false}
                placeholder="Selecione"
                style={{ width: '100%' }}
                data={descobriuInfo.map((t) => {
                  return {
                    label: t.descricao,
                    value: t.id,
                  };
                })}
              />

            {recuperarSenha && (
              <Form.Group>
                <Form.ControlLabel>Recuperar Senha</Form.ControlLabel>
                <Button onClick={recuperarSenha} style={{ marginTop: '0px' }}>
                  Enviar Email
                </Button>
              </Form.Group>
            )}
            {novaSenha && (
              <Form.Group>
                <Form.ControlLabel>Alterar Senha</Form.ControlLabel>
                <Button onClick={setOpen} style={{ marginTop: '0px' }}>
                  Criar Senha
                </Button>
              </Form.Group>
            )}
            {editar && (
              <Form.Group>
                <TogglesBloq>
                  <ResponsiveForm
                    label="Perfil Bloqueado"
                    name="perfil_bloqueado"
                    checked={camposInput.perfil_bloqueado}
                    accepter={Toggle}
                  />
                </TogglesBloq>
              </Form.Group>
            )}

          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Group>
              <ResponsiveForm
                label="Cpf/Cnpj"
                name="cpf_cnpj"
                value={camposInput.cpf_cnpj || ''}
                // eslint-disable-next-line react/jsx-no-bind
                mask={mascaraCpfCnpj}
                accepter={InputMascara}
              />
            </Form.Group>
            <Form.Group>
              <ResponsiveForm
                label="Nome do Responsável"
                name="pessoa_responsavel"
                value={camposInput.pessoa_responsavel || ''}
              />
            </Form.Group>
            <Form.Group>
              <ResponsiveForm
                label="Telefone"
                name="telefone"
                value={camposInput.telefone || ''}
                // eslint-disable-next-line react/jsx-no-bind
                mask={mascaraTelefone}
                accepter={InputMascara}
              />
            </Form.Group>
            <Form.Group>
              <ResponsiveForm
                label="Desconto (%)"
                name="desconto"
                type="number"
                value={camposInput.desconto >= 0 ? camposInput.desconto : ''}
              />
            </Form.Group>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Group>
              <ResponsiveForm
                // estilo={{ marginBotton: '10px' }}
                label="Logo (Opcional)"
                name="logo_foto"
                type="file"
                accept="image/*"
                value={camposInput.logo_foto || ''}
                onChange={async (_, evento) => {
                  const reader = new FileReader();
                  reader.onload = (e) => {
                    setLogo(e.target.result);
                  };
                  reader.readAsDataURL(evento.target.files[0]);
                  logoAlterada(evento.target.files[0]);
                }}
              />
              <VisulizarImagem
                titulo="Ver Documento"
                imagem={logo || camposInput.logo}
              />
            </Form.Group>
            
            <ResponsiveForm
                label="Quantidade de Colaboradores"
                name="qtd_funcionarios"
                accepter={SelectPicker}
                searchable={false}
                placeholder="Selecione"
                style={{ width: '100%' }}
                data={campoColaboradores.map((t) => {
                  return {
                    label: t.quantidade,
                    value: t.quantidade,
                  };
                })}
              />
              
            <Form.Group style={{ ...marginPersonalizada }}>
              <ResponsiveForm
                label="Cardápio (Opcional)"
                name="cardapio_foto"
                type="file"
                accept="image/*"
                value={camposInput.cardapio_foto || ''}
                onChange={async (_, evento) => {
                  const reader = new FileReader();
                  reader.onload = (e) => {
                    setCardapio(e.target.result);
                  };
                  reader.readAsDataURL(evento.target.files[0]);
                  cardapioAlterado(evento.target.files[0]);
                }}
              />
              <VisulizarImagem
                titulo="Ver Documento"
                imagem={cardapio || camposInput.cardapio}
              />
            </Form.Group>
            {editar && (
              <Form.Group>
                <TogglesFav>
                  <ResponsiveForm
                    label="Tarifa Feriado"
                    name="tarifa_feriado"
                    checked={camposInput.tarifa_feriado}
                    accepter={Toggle}
                  />
                </TogglesFav>
              </Form.Group>
            )}
          </Col>
        </Row>
      </Grid>
      <ModalNewPassword
        open={open}
        setOpen={setOpen}
        novaSenha={novaSenha}
      />
    </Panel>
  );
}

CamposDadosProfissional.propTypes = {
  editar: PropTypes.bool,
  camposInput: PropTypes.object,
  marginPersonalizada: PropTypes.object,
  logoAlterada: PropTypes.func.isRequired,
  novaSenha: PropTypes.func,
  cardapioAlterado: PropTypes.func.isRequired,
  recuperarSenha: PropTypes.func,
};

CamposDadosProfissional.defaultProps = {
  editar: false,
  camposInput: {},
  marginPersonalizada: {},
  recuperarSenha: null,
  novaSenha: null,
};

export default CamposDadosProfissional;
