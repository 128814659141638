/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Table, FlexboxGrid } from 'rsuite';
import { toast } from 'react-toastify';
import { format, parseISO, eachDayOfInterval } from 'date-fns';
import api from '~/services/api';
import { InputDataRange } from '~/components/InputDataRange';
import { TablePagination } from '~/components/TablePagination';

const { Cell, Column, HeaderCell } = Table;

export default function DetalhesRelatorio({
  exibir,
  id_estabelecimento,
  onFechar,
  filtroData,
  dataInicial,
}) {
  const locale = {
    sunday: 'Dom',
    monday: 'Seg',
    tuesday: 'Ter',
    wednesday: 'Qua',
    thursday: 'Qui',
    friday: 'Sex',
    saturday: 'Sab',
    ok: 'Ok',
    today: 'Hoje',
    yesterday: 'Ontem',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos',
  };
  const [pagina, setPagina] = useState(1);
  const [registros, setRegistros] = useState([]);
  const [carregando, setCarregando] = useState(false);
  const [dataFiltro, setDataFiltro] = useState();
  const [datas, setDatas] = useState();
  const [estabelecimento, setEstabelecimento] = useState({});

  const buscarRegistros = useCallback(async () => {
    setCarregando(true);
    if (!id_estabelecimento) return;
    try {
      const resposta = await api.get(
        `/relatorios/detalhes-estabelecimento/${id_estabelecimento}`,
        {
          params: {
            pagina,
            datas: dataFiltro,
          },
        }
      );
      const lista = resposta.data.lista.map((i) => ({
        ...i,
        profissional: i.profissional.nome ? i.profissional.nome : 'Excluido',
        profissao: i.profissao.profissao ? i.profissao.profissao : 'Excluido',
        avaliacao: i.avaliacao ? i.avaliacao : ' ',
        valor: i.valor ? `R$ ${i.valor.toFixed(2)}` : `R$ ${0}`,
        hora_inicio: format(parseISO(i.hora_inicio), 'HH:mm'),
        hora_fim: format(parseISO(i.hora_fim), 'HH:mm'),
        data_formatada: format(parseISO(i.data), 'dd/LL/yyyy'),
        tarifa_dinamica: `${(i.pedido.tarifa_dinamica * 100 - 100).toFixed(
          0
        )}%`,
        cupom: i.pedido.cupom ? i.pedido.cupom.cupom : '-',
      }));

      setRegistros({ lista, quantidade: resposta.data.quantidade });
    } catch (error) {
      toast.error(error.message);
    }
    setCarregando(false);
  }, [id_estabelecimento, dataFiltro, pagina]);

  useEffect(() => {
    const buscarEstabelecimento = async () => {
      setCarregando(true);
      try {
        const resposta = await api.get(
          `/estabelecimento/${id_estabelecimento}`
        );

        setEstabelecimento(resposta.data);
      } catch (error) {
        toast.error(error.message);
      }
    };

    if (id_estabelecimento) {
      buscarEstabelecimento();
    }

    setCarregando(false);
  }, [id_estabelecimento]);

  useEffect(() => {
    buscarRegistros();
  }, [buscarRegistros]);

  useEffect(() => {
    setDataFiltro(filtroData);
    setDatas(dataInicial);
  }, [filtroData, dataInicial]);

  return (
    <Modal
      open={exibir}
      overflow={false}
      onEntered={() => {
        buscarRegistros();
      }}
      onExited={() => {
        setRegistros([]);
      }}
      onClose={onFechar}
      full
    >
      <Modal.Header>
        <Modal.Title>Detalhes - {estabelecimento.nome_fantasia}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputDataRange
          ranges={[]}
          value={datas}
          onClean={() => {
            setDataFiltro(null);
            setDatas([]);
          }}
          onChange={(datasSelecionadas) => {
            setDatas(datasSelecionadas);
            if (datasSelecionadas && datasSelecionadas.length !== 0) {
              const datasIntervalo = eachDayOfInterval({
                start: datasSelecionadas[0],
                end: datasSelecionadas[1],
              });
              setDataFiltro(datasIntervalo);
            } else {
              setDataFiltro(null);
            }
          }}
          locale={locale}
        />
      <FlexboxGrid style={{ width: '1110px' }}>
        <TablePagination
          dados={registros.lista}
          quantidade={registros.quantidade}
          onChangePage={setPagina}
          carregando={carregando}
        >
          <Column resizable width={200} sortable>
            <HeaderCell>Nome Profissional</HeaderCell>
            <Cell dataKey="profissional" />
          </Column>
          <Column resizable width={150} sortable>
            <HeaderCell>Serviço</HeaderCell>
            <Cell dataKey="profissao" />
          </Column>
          <Column resizable width={110} sortable>
            <HeaderCell>Data</HeaderCell>
            <Cell dataKey="data_formatada" />
          </Column>
          <Column resizable width={100} sortable>
            <HeaderCell>Avalição</HeaderCell>
            <Cell dataKey="avaliacao" />
          </Column>
          <Column resizable width={100} sortable>
            <HeaderCell>Valor</HeaderCell>
            <Cell dataKey="valor" />
          </Column>
          <Column resizable width={110} sortable>
            <HeaderCell>Hora Inicio</HeaderCell>
            <Cell dataKey="hora_inicio" />
          </Column>
          <Column resizable width={110} sortable>
            <HeaderCell>Hora Fim</HeaderCell>
            <Cell dataKey="hora_fim" />
          </Column>
          <Column resizable width={130} sortable>
            <HeaderCell>Tarifa Dinâmica</HeaderCell>
            <Cell dataKey="tarifa_dinamica" />
          </Column>
          <Column resizable width={100} sortable>
            <HeaderCell>Cupom</HeaderCell>
            <Cell dataKey="cupom" />
          </Column>
        </TablePagination>
        </FlexboxGrid>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onFechar} appearance="subtle">
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

DetalhesRelatorio.propTypes = {
  exibir: PropTypes.bool.isRequired,
  onFechar: PropTypes.func.isRequired,
  id_estabelecimento: PropTypes.number,
  filtroData: PropTypes.array,
  dataInicial: PropTypes.array,
};

DetalhesRelatorio.defaultProps = {
  id_estabelecimento: null,
  filtroData: [],
  dataInicial: [],
};
