import React from 'react';
import { Sector } from 'recharts';
import api from '~/services/api';
import { toast } from 'react-toastify';

export const renderActiveShape = (props) => {
  const toggleMode = localStorage.getItem("toggle-mode") === 'true'
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 10) * cos;
  const my = cy + (outerRadius + 20) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 12;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * -15}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill={toggleMode ? "#fff" : "#000"}
        style={{ fontWeight: 'bold' }}
        fontSize="12"
      >
        {`${payload.value} (${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

export const colors = [
  '#37AB00',
  '#FF1616',
  '#008B8B',
  '#0e385b',
  '#9DEB38',
  '#FC6666',
  '#FBC017',
  '#1A8CEB',
  '#464749',
  '#000000',
];

export async function buscarDados(
  setData,
  endPoint,
  params = {},
  setCarregando = null
) {
  if (setCarregando) setCarregando(true);
  try {
    const resposta = await api.get(endPoint, { params });
    setData(resposta.data);
  } catch (e) {
    toast.error(e.message);
  }
  if (setCarregando) setCarregando(false);
}
