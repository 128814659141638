/* eslint-disable react/prop-types */
import React from 'react';
import { FlexboxGrid } from 'rsuite';
import Table from './components/Table';
import Timeline from './components/Timeline';
import Services from './components/Services';

export default function DetalhesCliente({ano, anos, anoSelecionado, setAno}) {
  return (
    <FlexboxGrid
      style={{
        alignItems: 'start',
        gap: '20px',
        marginTop: 10,
        // display: 'block',
      }}
    >
      <FlexboxGrid.Item colspan={6}>
        <Table />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item
        style={{
          marginLeft: -84,
          marginRight: 190,
        }}
        colspan={6}
      >
        <Services />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={6}>
        <Timeline 
        ano={ano}
        anos={anos}
        anoSelecionado={anoSelecionado}
        setAno={setAno}
        />
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
}
