import { DatePicker } from 'rsuite';
import React from 'react';

export function InputData(props) {
  // const locale = {
  //   sunday: 'Dom',
  //   monday: 'Seg',
  //   tuesday: 'Ter',
  //   wednesday: 'Qua',
  //   thursday: 'Qui',
  //   friday: 'Sex',
  //   saturday: 'Sab',
  //   ok: 'Ok',
  //   today: 'Hoje',
  //   yesterday: 'Ontem',
  //   hours: 'Horas',
  //   minutes: 'Minutos',
  //   seconds: 'Segundos',
  // };

  return (
    <DatePicker
      style={{ marginRight: '15px' }}
      format="yyyy-MM-dd"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}
