import { all, takeLatest, call, put } from 'redux-saga/effects';

import { toast } from 'react-toastify';
import { format } from 'date-fns';
import api from '~/services/api';
import firebase from '~/services/firebase';

import { useEffect } from 'react';
import {
  buscarNotificacoes,
  requisicaoFinalizada,
  buscarNotificacoesSucesso,
  salvarTokenNotificacao,
} from './actions';

export function* buscar({ payload }) {
  try {
    const { pagina, titulo, dataInicio, dataFim, mensagem } = payload;

    const resposta = yield call(api.get, 'notificacao', {
      params: {
        pagina,
        titulo,
        dataInicio,
        dataFim,
        mensagem,
      },
    });

    const notificacoesNaoLidas = Number(resposta.data.totais[0].total)
    const pedidosRecebidosNaoLidos = Number(resposta.data.totais[0].newpedido)
    const pedidosCanceladosNaoLidos = Number(resposta.data.totais[0].pedidoscancelados)
    const novosProfissionaisCriadosNaoLidos = Number(resposta.data.totais[0].newpro)
    const novosEstabelecimentosCriadosNaoLidos = Number(resposta.data.totais[0].newestab)
    const localizacaoNaoLidas = Number(resposta.data.totais[0].localizacao)
    const ajusteDeHorarioNaoLidos = Number(resposta.data.totais[0].ajustehorario)
    const contadorFalhaCobranca = Number(resposta.data.totais[0].falhaCobranca)

    const listaFormatada = resposta.data.lista.map((notificacao) => ({
      ...notificacao,
      data_formatada: format(
        new Date(notificacao.data),
        "dd-MM-yyyy' | 'HH:mm"
      ),
    }));

    yield put(
      buscarNotificacoesSucesso({
        notificacoes: {
          lista: listaFormatada,
          quantidade: resposta.data.quantidade,
        },
        notificacoesNaoLidas,
        pedidosRecebidosNaoLidos,
        pedidosCanceladosNaoLidos,
        novosProfissionaisCriadosNaoLidos,
        novosEstabelecimentosCriadosNaoLidos,
        localizacaoNaoLidas,
        ajusteDeHorarioNaoLidos,
        contadorFalhaCobranca,
      })
    );
  } catch (e) {
    console.tron.error(e);
    toast.error(e.message);
    yield put(requisicaoFinalizada());
  }
}

export function* atualizarNotificacoesLidas({ payload }) {
  try {
    const { pagina, titulo } = payload;

    yield call(api.put, 'notificacao', { titulo });
    yield put(buscarNotificacoes({ pagina, titulo }));
  } catch (err) {
    console.tron.error(err);
    toast.error('Erro ao atualizar as notificações');
    yield put(requisicaoFinalizada());
  }
}

async function buscarToken() {
  const messaging = firebase.messaging();
  const token = await messaging.getToken({
    vapidKey:
      'BJ86KEFh4-Gg40lXEETOvOmwRmjU6d438cdfWEC-S66gLjcUxOIhMdO0zM-obkvhaUHcLbk2zyCdUQok1I6wI-I',
  });
  return token;
}

function* verificarTokenNotificacao() {
  try {
    const tokenNotificacao = yield call(buscarToken);
    if (tokenNotificacao) {
      yield call(api.post, 'notificacao-token', {
        token: tokenNotificacao,
      });
      yield put(salvarTokenNotificacao({ tokenNotificacao }));
    } else {
      toast.info('Permissão para enviar notificações negada');
    }
  } catch (e) {
    toast.info(
      `Para poder receber noticações, confirme a permissão no superior esquerdo`
    );
  }
}

export default all([
  takeLatest(
    '@notificacao/VERIFICAR_TOKEN_NOTIFICACAO',
    verificarTokenNotificacao
  ),
  takeLatest('@autenticacao/LOGAR_SUCESSO', verificarTokenNotificacao),
  takeLatest('@notificacao/BUSCAR_NOTIFICACOES', buscar),
  takeLatest(
    '@notificacao/MARCAR_NOTIFICACOES_LIDAS',
    atualizarNotificacoesLidas
  ),
]);
