import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Button, Schema, AutoComplete } from 'rsuite';
import { ButtonFullBlue } from '~/components/Buttons/ButtonFullBlue/styles';

export const InformacoesProfissional = ({ exibir, onFechar, inforPro }) => {
  return (
    <Modal open={exibir} onClose={onFechar} size="xs">
      <Modal.Header>
        <Modal.Title>Informações do Profissional</Modal.Title>
      </Modal.Header>
      <Modal.Body>{inforPro}</Modal.Body>
      <Modal.Footer>
        <Button onClick={() => onFechar()} appearance="subtle">
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

InformacoesProfissional.propTypes = {
  exibir: PropTypes.bool.isRequired,
  onFechar: PropTypes.func.isRequired,
  inforPro: PropTypes.string.isRequired,
};
