/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';

import { FlexboxGrid, Table, CheckPicker, Input, SelectPicker } from 'rsuite';

import { InputDataRange } from '~/components/InputDataRange';
import { Container } from '~/styles/tabela';
import { locale } from '~/common/variaveis';
import api from '~/services/api';
import { TableWithSub } from '../tabela';
import {
  opcoes,
  columnsOpcoes,
  columnsSubTables,
  motivosOpcoesII,
} from '../../common';

export const OpcoesLogs = () => {
  const [categoria, setCategoria] = useState(4);
  const [carregando, setCarregando] = useState(true);
  const [data, setData] = useState([]);
  const [motivo, setMotivo] = useState([]);
  const [pagina, setPagina] = useState(1);
  const [pedido, setPedido] = useState();
  const [logsPedido, setLogsPedido] = useState({
    lista: [],
    quantidade: 0,
  });

  useEffect(() => {
    const buscaAPI = async () => {
      setCarregando(true);
      try {
        const result = await api.post('/logs/usuarios', {
          pagina,
          categoria,
          id_registro: pedido,
          motivo,
          responsavel: null,
          data_inicio: data[0]
            ? moment(data[0]).format('YYYY-MM-DD')
            : undefined,
          data_fim: data[0] ? moment(data[1]).format('YYYY-MM-DD') : undefined,
        });
        if (result.data.lista.length > 0) {
          const newRes = {
            quantidade: result.data.quantidade,
            lista: result.data.lista.map((res) => ({
              ...res,
              idioma: res.descricao.idioma ? res.descricao.idioma : null,
              profissao: res.descricao.profissao
                ? res.descricao.profissao
                : null,
              habilidade: res.descricao.habilidade
                ? res.descricao.habilidade
                : null,
              valor_cobrar: res.descricao.valor_cobrar
                ? res.descricao.valor_cobrar
                : null,
              valor_repassar: res.descricao.valor_repassar
                ? res.descricao.valor_repassar
                : null,
              tipo_estabelecimento: res.descricao.tipo_estabelecimento
                ? res.descricao.tipo_estabelecimento
                : null,
              escolaridade: res.descricao.escolaridade
                ? res.descricao.escolaridade
                : null,
              tempo_experiencia: res.descricao.tempo_experiencia
                ? res.descricao.tempo_experiencia
                : null,
              dia: res.descricao.dia ? res.descricao.dia : null,
              periodo: res.descricao.periodo ? res.descricao.periodo : null,
              blusa: res.descricao.blusa ? res.descricao.blusa : null,
              calca: res.descricao.calca ? res.descricao.calca : null,
              calcado: res.descricao.calcado ? res.descricao.calcado : null,
              cabelo: res.descricao.cabelo ? res.descricao.cabelo : null,
              barba: res.descricao.barba ? res.descricao.barba : null,
              treinamento: res.descricao.treinamento
                ? res.descricao.treinamento
                : null,
              status:
                res.descricao && res.descricao.length > 0
                  ? // ? res.descricao[0].ativo
                  res.descricao[0].ativo === true
                    ? 'Ativo'
                    : 'Inativo'
                  : res.descricao.ativo
                    ? res.descricao.ativo === true
                      ? 'Ativo'
                      : 'Inativo'
                    : null,
              cupom:
                res.descricao && res.descricao.length > 0
                  ? // ? res.descricao[0].ativo
                  res.descricao[0].cupom
                  : res.descricao.cupom
                    ? res.descricao.cupom
                    : null,
              desconto:
                res.descricao && res.descricao.length > 0
                  ? // ? res.descricao[0].ativo
                  res.descricao[0].desconto
                  : res.descricao.desconto
                    ? res.descricao.desconto
                    : null,
              id_cidade:
                res.descricao && res.descricao.length > 0
                  ? // ? res.descricao[0].ativo
                  res.descricao[0].id_cidade
                  : res.descricao.id_cidade
                    ? res.descricao.id_cidade
                    : null,
            })),
          };

          setLogsPedido(newRes);
        } else {
          setLogsPedido({
            lista: [],
            quantidade: 0,
          });
        }
        setCarregando(false);
      } catch (err) {
        setCarregando(false);
        toast.error('Erro ao realizar a busca dos logs');
      }
    };
    buscaAPI();
  }, [pagina, pedido, motivo, data, categoria]);

  return (
    <Container>
      <FlexboxGrid justify="start" style={{ gap: '10px' }}>
        <SelectPicker
          appearance="default"
          style={{ width: '200px' }}
          searchable={false}
          data={opcoes}
          value={categoria}
          cleanable={false}
          onChange={(value) => setCategoria(value)}
          defaultValue={[{ label: 'Edição', value: 'Edição' }]}
        />
        <CheckPicker
          appearance="default"
          style={{ width: 200 }}
          searchable={false}
          value={motivo}
          cleanable={false}
          onChange={(value) => setMotivo(value)}
          data={motivosOpcoesII.map((moti) => ({
            label: moti.label,
            value: moti.value,
          }))}
          defaultValue={{ label: 'Edição', value: 'Edição' }}
        />
        <Input
          style={{ width: '200px' }}
          placeholder={`ID ${opcoes
            .filter((o) => o.value === categoria)[0]
            .label.slice(0, 15)}. Ex: 20`}
          onChange={(value) => setPedido(value)}
        />
        <InputDataRange
          style={{ width: '250px' }}
          placeholder="Data"
          ranges={[]}
          onClean={() => setData(['', ''])}
          onChange={(value) => setData(value)}
          locale={locale}
        />
      </FlexboxGrid>

      <TableWithSub
        // title="Profissionais"
        lista={logsPedido.lista || []}
        quantidade={logsPedido.quantidade}
        paginationBarTop
        paginationBarLow
        setPagina={setPagina}
        columns={columnsOpcoes[categoria]}
        childrens={columnsSubTables[categoria]}
      />
    </Container>
  );
};
