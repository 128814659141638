/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Table, CheckPicker, AutoComplete, FlexboxGrid } from 'rsuite';
import { toast } from 'react-toastify';
import { eachDayOfInterval, format, parseISO } from 'date-fns';
import api from '~/services/api';
import { InputDataRange } from '~/components/InputDataRange';
import { TabelaPaginacao } from '~/components/TabelaPaginacao';
import useSearchNames from '~/services/useSearchNames';
import { TabelaSemPaginacao } from '../../../../../components/TabelaSemPaginacao';
import formatCurrency from '../../../../../utils/formatCurrency';

const { Cell, Column, HeaderCell } = Table;

export default function DetalhesCarteiraDialog({
  exibir,
  id_profissional,
  onFechar,
  datas,
}) {
  const [registros, setRegistros] = useState({
    lista: [],
    quantidade: 0,
    bloq: [],
    fav: [],
  });
  const [carregando, setCarregando] = useState(false);
  const [dataFiltro, setDataFiltro] = useState();
  const [pagina, setPagina] = useState(1);
  const [servicoSelecionado, setServicoSelecionado] = useState();
  const [services, setServices] = useState([]);
  const [nomesEstabelecimentos, setNomesEstabelecimentos] = useState([]);
  const [idEstabelecimentoFiltro, setIdEstabelecimentoFiltro] = useState();
  const [profissional, setProfissional] = useState({});
  const [resumoProfissional, setResumoProfissional] = useState([]);


  const buscarNomesEstabelecimentos =
   useSearchNames(setNomesEstabelecimentos, 'estabelecimentos');

   useEffect(() => {
    const buscarProfissional = async () => {
      setCarregando(true);
      try {
        const resposta = await api.get(`/profissional/${id_profissional}`);
        setProfissional(resposta.data);
      } catch (error) {
        toast.error(error.message);
      }
    };

    if (id_profissional) {
      buscarProfissional();
    }

    setCarregando(false);
  }, [id_profissional]);



  const buscarRegistros = useCallback(async () => {
    let datasIntervalo = []
    if (dataFiltro && dataFiltro.length !== 0) {
      datasIntervalo = eachDayOfInterval({
        start: dataFiltro[0],
        end: dataFiltro[1],
      });
    }


    setCarregando(true);
    if (!id_profissional) return;
    try {
      const resposta = await api.get(
        `/relatorios/carteira-profissionais/${id_profissional}`,
        {
          params: {
            datas: datasIntervalo,
            servico: servicoSelecionado,
            estabelecimento: idEstabelecimentoFiltro,
            pagina
          },
        }
      );
      const lista = resposta.data.lista.map((i) => ({
        ...i,
        profissional: i.profissional !== null ? i.profissional.nome : '',
        feedback_prof: i.feedback_prof !== null ? i.feedback_prof : 0,
        avaliacao_est: i.avaliacao_est !== null ? i.avaliacao_est : 0,
        avaliacao_prof: i.avaliacao_prof !== null ? i.avaliacao_prof : 0,
        profissao: i.profissao.profissao,
        estabelecimento: i.pedido.estabelecimento.nome_fantasia,
        data: format(parseISO(i.data), 'dd/MM/yyyy'),
        pedido: i.pedido.id,
      }));

      setRegistros({
        lista,
        quantidade: resposta.data.quantidade,
        bloq: resposta.data.estb_bloq || [],
        fav: resposta.data.estb_fav || [],
      });

      const respostaResumoProfissional = await api.get('relatorios/carteira-profissionais', {
        params: {
          id: id_profissional,
        },
      });

      const listaResumo = respostaResumoProfissional.data.lista.map((i) => ({
        ...i,
        bairro: i.bairro ? i.bairro : '',
        cidade: i.cidade ? i.cidade : '',
        avaliacao: i.avaliacao ? i.avaliacao.toFixed(2) : '',
        valor: i.recebido ? formatCurrency(i.recebido) : '',
        horas: i.horas ? i.horas : '',
        shifts: i.shifts ? i.shifts : '',
        faltas: i.faltas ? i.faltas : '',
        cancelamentos: i.cancelamentos ? i.cancelamentos : '',
        bloqueios: i.qtd_block ? i.qtd_block : '',
        favoritos: i.qtd_fav ? i.qtd_fav : '',
      }))

      setResumoProfissional(listaResumo)

    } catch (error) {
      toast.error(error.message);
    }
    setCarregando(false);
  }, [id_profissional, dataFiltro, servicoSelecionado, pagina, idEstabelecimentoFiltro]);

  useEffect(() => {

  }, [nomesEstabelecimentos]);

  useEffect(() => {
    buscarRegistros();
  }, [buscarRegistros]);

  useEffect(() => {
    setDataFiltro(datas);
  }, [datas]);

  const buscarProfissao = async() => {
    try {
      const profissao = await api.get('profissao');
      setServices(profissao.data);
    } catch(er) {
      console.log('er')
    }
  }
  useEffect(() => {
    buscarProfissao()
  }, []);

  return (
    <Modal
      open={exibir}
      overflow={false}
      onEntered={() => {
        buscarRegistros();
      }}
      onExited={() => {
        setRegistros({
          lista: [],
          quantidade: 0,
          bloq: [],
          fav: [],
        });
      }}
      onClose={onFechar}
      full
    >
      <Modal.Header>
        <Modal.Title>
        <FlexboxGrid justify="start" align="middle" style={{ gap: '10px' }}>
          Detalhes - {profissional.nome}
          <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
            <p style={{fontSize: '15px'}} ><span style={{fontSize: '15px', color: 'green'}}>Favorito:</span> {registros.fav.map(f => f.nome).join(', ')}</p>
            <p style={{fontSize: '15px'}} ><span style={{fontSize: '15px', color: 'red'}}>Bloqueados:</span>  {registros.bloq.map(b=> b.nome).join(', ')}</p>
          </div>
        </FlexboxGrid>
          </Modal.Title>
      </Modal.Header>
      <FlexboxGrid style={{ width: '1350px' }}>
      <TabelaSemPaginacao
       dados={resumoProfissional}
       height={70}
       carregando={carregando}
       semPaginationBar
       >
        <Column resizable width={240}>
          <HeaderCell>Bairro</HeaderCell>
          <Cell dataKey="bairro" />
        </Column>
        <Column resizable width={110}>
          <HeaderCell>Cidade</HeaderCell>
          <Cell dataKey="cidade" />
        </Column>
        <Column resizable width={130}>
          <HeaderCell>Avaliação</HeaderCell>
          <Cell dataKey="avaliacao" />
        </Column>
        <Column resizable width={110}>
          <HeaderCell>Recebido</HeaderCell>
          <Cell dataKey="valor" />
        </Column>
        <Column resizable width={100}>
          <HeaderCell>Horas</HeaderCell>
          <Cell dataKey="horas" />
        </Column>
        <Column resizable width={130}>
          <HeaderCell>Shifts</HeaderCell>
          <Cell dataKey="shifts" />
        </Column>
        <Column resizable width={80}>
          <HeaderCell>Faltas</HeaderCell>
          <Cell dataKey="faltas" />
        </Column>
        <Column resizable width={150}>
          <HeaderCell>Cancelamentos</HeaderCell>
          <Cell dataKey="cancelamentos" />
        </Column>
        <Column resizable width={150}>
          <HeaderCell>Favorito</HeaderCell>
          <Cell dataKey="favoritos" />
        </Column>
        <Column resizable width={150}>
          <HeaderCell>Block</HeaderCell>
          <Cell dataKey="bloqueios" />
        </Column>
      </TabelaSemPaginacao>
      </FlexboxGrid>
      <Modal.Body>
      <FlexboxGrid justify="start" align="middle" style={{ gap: '10px' }}>
        <InputDataRange
          ranges={[]}
          value={dataFiltro}
          onChange={setDataFiltro}
        />

        <AutoComplete
          placeholder="Cliente"
          style={{ marginRight: '15px', marginLeft: '15px', width: 300 }}
          data={nomesEstabelecimentos.map((p) => ({
            label: p.nome_fantasia,
            value: p.nome_fantasia,
            id: p.id,
          }))}
          onSelect={async (value, item) => {

            setTimeout(() => {
              setIdEstabelecimentoFiltro(item.id);
            }, 1000);
          }}
          onChange={(value) => {
            if (value !== '') {
              buscarNomesEstabelecimentos(value);
            } else {
              setIdEstabelecimentoFiltro('');
            }
          }}
        />
        <CheckPicker
          style={{ marginRight: '15px', width: 180 }}
          appearance="default"
          placeholder="Serviços"
          data={services.map((s) => ({
            label: s.profissao,
            value: s.id,
          })) }
          value={servicoSelecionado}
          onChange={(value) => setServicoSelecionado(value)}
        />


      </FlexboxGrid>

      <FlexboxGrid style={{ width: '1350px' }}>
        <TabelaPaginacao dados={registros.lista} carregando={carregando} onChangePage={setPagina} quantidade={registros.quantidade}>
          <Column resizable width={240} sortable>
            <HeaderCell>Nome Cliente</HeaderCell>
            <Cell dataKey="estabelecimento" />
          </Column>
          <Column resizable width={110} sortable>
            <HeaderCell>Data</HeaderCell>
            <Cell dataKey="data" />
          </Column>
          <Column resizable width={130}>
            <HeaderCell>Código do Pedido</HeaderCell>
            <Cell dataKey="pedido" />
          </Column>
          <Column resizable width={110} sortable>
            <HeaderCell>Status</HeaderCell>
            <Cell dataKey="status" />
          </Column>
          <Column resizable width={100} sortable>
            <HeaderCell>Serviço</HeaderCell>
            <Cell dataKey="profissao" />
          </Column>
          <Column resizable width={130} sortable>
            <HeaderCell>Valor Repasse</HeaderCell>
            <Cell dataKey="valor_repasse" />
          </Column>
          <Column resizable width={80} sortable>
            <HeaderCell>Horas</HeaderCell>
            <Cell dataKey="horas" />
          </Column>
          <Column resizable width={150} sortable>
            <HeaderCell>Nota dada pelo Estb</HeaderCell>
            <Cell dataKey="avaliacao_prof" />
          </Column>
          <Column resizable width={150} sortable>
            <HeaderCell>Nota dada pelo Prof</HeaderCell>
            <Cell dataKey="avaliacao_est" />
          </Column>
          <Column resizable width={150} sortable>
            <HeaderCell>Feedback</HeaderCell>
            <Cell dataKey="feedback_prof" />
          </Column>
        </TabelaPaginacao>
        </FlexboxGrid>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onFechar} appearance="subtle">
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

DetalhesCarteiraDialog.propTypes = {
  exibir: PropTypes.bool.isRequired,
  onFechar: PropTypes.func.isRequired,
  id_profissional: PropTypes.number,
  datas: PropTypes.array,
};


DetalhesCarteiraDialog.defaultProps = {
  id_profissional: null,
  datas: [],
};
