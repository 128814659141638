export default {
  primaryColor: '#0e385b',
  secondaryColor: '#215a85',
  lightBlue: '#1A8CEB',
  darkRed: '#FF1616',
  lightRed: '#FC6666',
  yellowColor: '#FBC017',
  darkGreen: '#37AB00',
  lightGreen: '#9DEB38',
  darkGray: '#464749',
  lightGray: '#D2D2D2',
  blackColor: '#000000',
  whiteColor: '#FFFFFF',
  darkBlue: '#033259',
  rsuiteGray: '#1a1d24',
  smallButtonDarkTheme: '#34c3ff',
  smallButtonLightTheme: '#1675e0',

};
