/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import { isBefore, isEqual, parse } from 'date-fns';
import { Divider, Table } from 'rsuite';
import PropTypes from 'prop-types';
import { Container, PaginationBar, StyledPagination } from './styles';

export const TabelaPaginacao = ({
  dados,
  carregando,
  children,
  quantidade,
  onChangePage,
}) => {
  const [page, setPage] = useState(1);
  const [sortType, setSortType] = useState();
  const [sortColumn, setSortColumn] = useState();

  function getData() {
    let dadosOrdenados;
    if (sortColumn && sortType) {
      dadosOrdenados = dados.sort((a, b) => {
        const x = a[sortColumn].toString();
        const y = b[sortColumn].toString();
        if (x.includes('/') && y.includes('/')) {
          const xDate = parse(x, 'dd/LL/yyyy', new Date());
          const yDate = parse(y, 'dd/LL/yyyy', new Date());
          if (isBefore(xDate, yDate)) {
            return -1;
          }
          if (isEqual(xDate, yDate)) {
            return 0;
          }
          return 1;
        }
        return x.localeCompare(y, 'pt-BR', { numeric: true });
      });
      if (sortType === 'desc') {
        dadosOrdenados.sort().reverse();
      }
    }
    if (!dadosOrdenados) dadosOrdenados = dados;

    return dadosOrdenados;
  }

  function handleSortColumn(sortColumn1, sortType1) {
    setSortColumn(sortColumn1);
    setSortType(sortType1);
  }

  const dadosTabela = getData();
  return (
    <Container>
      <PaginationBar>
        <span style={{ marginLeft: 20 }}>
          <Divider vertical />
          Total: {quantidade}
        </span>
        <StyledPagination
          prev
          next
          maxButtons={5}
          showLengthMenu={false}
          first
          last
          activePage={page}
          total={quantidade}
          onChangePage={(pagina) => {
            setPage(pagina);
            onChangePage(pagina);
          }}
        />
      </PaginationBar>
      <Table
        loading={carregando}
        data={dadosTabela}
        sortType={sortType}
        sortColumn={sortColumn}
        // eslint-disable-next-line react/jsx-no-bind
        onSortColumn={handleSortColumn}
        height={1420}
      >
        {children}
      </Table>
      <PaginationBar>
        <span style={{ marginLeft: 20 }}>
          <Divider vertical />
          Total: {quantidade}
        </span>
        <StyledPagination
          prev
          next
          maxButtons={5}
          showLengthMenu={false}
          first
          last
          activePage={page}
          total={quantidade}
          onChangePage={(pagina) => {
            setPage(pagina);
            onChangePage(pagina);
          }}
        />
      </PaginationBar>
    </Container>
  );
};

TabelaPaginacao.propTypes = {
  dados: PropTypes.array.isRequired,
  carregando: PropTypes.bool.isRequired,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  quantidade: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
};
