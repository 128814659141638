/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Button, Schema, AutoComplete } from 'rsuite';
import useSearchNames from '~/services/useSearchNames';
import { toast } from 'react-toastify';
import api from '~/services/api';
import { ButtonFullBlue } from '../../../../components/Buttons/ButtonFullBlue/styles';

const { NumberType, StringType } = Schema.Types;

const modelo = Schema.Model({
  id_profissional: NumberType().isRequired('Profissional Obrigatório'),
  nome_profissional: StringType()
    .addRule((_, dados) => {
      if (!dados.id_profissional) return false;

      return true;
    }, 'Selecione um Profissional')
    .isRequired('Obrigatório'),
});

function AdicionarProfissionalDialog({
  exibir,
  onFechar,
  setNewPro,
  idPedido,
}) {
  const [camposInput, setCamposInput] = useState({});
  const [profissionais, setProfissionais] = useState([]);

  const buscarNomesProfissionais = useSearchNames(
    setProfissionais,
    'profissionais'
  );

  const incluirPro = async () => {
    if (!camposInput.id_profissional) {
      toast.error('Selecione um Profissional');
      return;
    }
    try {
      const data = {
        id_recrutamento: idPedido,
        id_profissional: camposInput.id_profissional,
        infor_pro: 'ok',
      };
      await api.post('/recrutamento/add-pro', data);
      setNewPro((p) => !p);
      toast.success('Profissional adicionado com sucesso');
      setCamposInput({});
      onFechar();
    } catch (e) {
      toast.error(e.message);
    }
  };

  return (
    <Modal open={exibir} onClose={onFechar} size="xs">
      <Form
        fluid
        model={modelo}
        formValue={camposInput}
        onChange={(valor) => setCamposInput(valor)}
        onSubmit={() => incluirPro()}
      >
        <Modal.Header>
          <Modal.Title>Adicionar Profissional</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.ControlLabel>Nome do Profissional</Form.ControlLabel>
            <Form.Control
              name="nome_profissional"
              accepter={AutoComplete}
              data={profissionais.map((p) => ({
                label: p.nome,
                value: p.nome,
                id: p.id,
              }))}
              onSelect={(value, item) => {
                camposInput.id_profissional = item.id;
              }}
              onEntered={() => {
                camposInput.id_profissional = null;
              }}
              value={camposInput.nome_profissional}
              onChange={buscarNomesProfissionais}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onFechar} appearance="subtle">
            Cancelar
          </Button>
          <ButtonFullBlue type="submit" appearance="primary">
            Salvar
          </ButtonFullBlue>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

AdicionarProfissionalDialog.propTypes = {
  exibir: PropTypes.bool.isRequired,
  onFechar: PropTypes.func.isRequired,
  setNewPro: PropTypes.func.isRequired,
  idPedido: PropTypes.number.isRequired,
};

export default AdicionarProfissionalDialog;
