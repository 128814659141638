/* eslint-disable prettier/prettier */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Table } from 'rsuite';

import PropTypes from 'prop-types';

const { Cell } = Table;

export const fundo = (data) => {
  let cor;
  if (data.status === 'Confirmado' && data.checkin === 'Realizado') {
    cor = '#37AB00';
  } else if (data.status === 'Confirmado' && data.checkin === 'Aguardando') {
    cor = '#ffffe0';
  } else if (data.status === 'Cancelado') {
    cor = '#ff1616';
  } else if (data.status === 'Aguardando') {
    cor = '#ffffe0';
  } else if (data.status === 'Finalizado') {
    cor = '#464749';
  }
  return cor;
};

export const texto = (data) => {
  let cor;
  if (data.status === 'Confirmado' && data.checkin === 'Realizado') {
    cor = '#fafafa';
  }
  if (data.status === 'Confirmado' && data.checkin === 'Aguardando') {
    cor = 'black'
  }
  if (data.status === 'Cancelado') {
    cor = '#fafafa';
  } else if (data.status === 'Finalizado') {
    cor = '#fafafa';
  }
  return cor;
};
export const CelulaFormatada = ({ rowData, dataKey, ...props }) => (
  <Cell
    {...props}
    style={{
      background: fundo(rowData),
      color: texto(rowData),
    }}
  >
    {rowData[dataKey]}
  </Cell>
);

CelulaFormatada.propTypes = {
  rowData: PropTypes.object,
  dataKey: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

CelulaFormatada.defaultProps = {
  rowData: {},
  onChange: () => { },
};
