/* eslint-disable react/jsx-no-bind */
import React, { useState, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Sidenav, Nav, Navbar, Dropdown, Toggle, IconButton } from 'rsuite';
import GearIcon from '@rsuite/icons/Gear';
import ArrowLeftLineIcon from '@rsuite/icons/ArrowLeftLine';
import ArrowRightLineIcon from '@rsuite/icons/ArrowRightLine';
import { useHistory } from 'react-router-dom';
import Exit from '@rsuite/icons/Exit';
import UserBadge from '@rsuite/icons/UserBadge';
import { TorsoBusiness } from '@styled-icons/foundation/TorsoBusiness';
import { Business } from '@styled-icons/boxicons-solid/Business';

import { deslogar } from '~/store/modulos/autenticacao/actions';
import { PersonPlusFill } from 'styled-icons/bootstrap';
import { Options } from '@styled-icons/ionicons-outline/Options';
import { Report } from '@styled-icons/boxicons-solid/Report';
import { Notifications } from '@styled-icons/ionicons-sharp/Notifications';
import { BiMessageDetail } from 'react-icons/bi';
import { IoIosPeople } from 'react-icons/io';
import { AdminPanelSettings } from '@styled-icons/material/AdminPanelSettings';
import GrowthIcon from '@rsuite/icons/Growth';
import { FaMoon } from 'react-icons/fa';
import { BsFillSunFill } from 'react-icons/bs';

import { ExpandirDiv, MenuLateral, Navegacao } from './styles';
import theme from '../../styles/theme';
import { DarkModeContext } from '../../common/contexts/darkMode';

function Menu() {
  const [expandido, setExpandido] = useState(true);
  // const notificacoesNaoLidas = useSelector(
  //   (state) => state.notificacao.notificacoesNaoLidas
  // );
  const pedidosRecebidosNaoLidos = useSelector(
    (state) => state.notificacao.pedidosRecebidosNaoLidos
  );
  const pedidosCanceladosNaoLidos = useSelector(
    (state) => state.notificacao.pedidosCanceladosNaoLidos
  );
  const novosEstabelecimentosCriadosNaoLidos = useSelector(
    (state) => state.notificacao.novosEstabelecimentosCriadosNaoLidos
  );
  const localizacaoNaoLidas = useSelector(
    (state) => state.notificacao.localizacaoNaoLidas
  );
  const novosProfissionaisCriadosNaoLidos = useSelector(
    (state) => state.notificacao.novosProfissionaisCriadosNaoLidos
  );
  const ajusteDeHorarioNaoLidos = useSelector(
    (state) => state.notificacao.ajusteDeHorarioNaoLidos
  );
  const falhaNaCobrancaCount = useSelector(
    (state) => state.notificacao.falhaNaCobrancaCount
  );
  const calculoAlertaPedidos =
    pedidosRecebidosNaoLidos +
    ajusteDeHorarioNaoLidos +
    pedidosCanceladosNaoLidos;
  const permissions = useSelector((state) => state.permissions.permissions);
  const loggeduser = useSelector((state) => state.setUser.data);
  const dispatch = useDispatch();
  const history = useHistory();

  const permicoes = permissions && {
    profissionais:
      permissions.profissionais && permissions.profissionais.split(','),
    estabelecimentos:
      permissions.estabelecimentos && permissions.estabelecimentos.split(','),
    pedidos: permissions.pedidos && permissions.pedidos.split(','),
    opcoes: permissions.opcoes && permissions.opcoes.split(','),
    relatorios: permissions.relatorios && permissions.relatorios.split(','),
    iugu: permissions.iugu && permissions.iugu.split(','),
    stark: permissions.stark && permissions.stark.split(','),
    notificacoes:
      permissions.notificacoes && permissions.notificacoes.split(','),
    administracao:
      permissions.administracao && permissions.administracao.split(','),
    tarifas: permissions.tarifas && permissions.tarifas.split(','),
    recrutamento:
      permissions.recrutamento && permissions.recrutamento.split(','),
  };

  const { toggleMode, setToggleMode } = useContext(DarkModeContext);

  useEffect(() => {
    localStorage.setItem('toggle-mode', toggleMode);
  }, [toggleMode]);

  const toggleModeFunc = () => {
    setToggleMode(!toggleMode);
  };
console.log(permissions, 'permissions')
console.log(permicoes, 'permicoes')
  return (
    <MenuLateral
      width={expandido ? 260 : 56}
      collapsible
      style={expandido ? { overflowY: 'scroll' } : { overflowY: 'visible' }}
    >
      <Navbar
        appearance="inverse"
        className="nav-toggle"
        style={{ backgroundColor: theme.primaryColor }}
      >
        <Navbar.Body>
          <Nav
            style={{
              display: 'flex',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <Dropdown
              placement="bottomStart"
              trigger="click"
              icon={<GearIcon />}
              noCaret
              style={{ zIndex: 1000 }}
            >
              <Dropdown.Item onSelect={() => history.replace('/home/usuario')}>
              <p style={{color: toggleMode && '#fff'}}><UserBadge style={{marginRight: '5px'}} /> Minha Conta</p>
              </Dropdown.Item>
              <Dropdown.Item onSelect={() => dispatch(deslogar())}>
              <p style={{color: toggleMode && '#fff', padding: '0px'}}> <Exit style={{marginRight: '5px'}} /> Sair </p>
              </Dropdown.Item>
            </Dropdown>

            {expandido ? (
              <p
                style={{
                  width: '100%',
                  textAlign: 'end',
                  marginLeft: '16px',
                }}
              >
                <b>
                  {loggeduser &&
                    `${loggeduser.nome.split(' ')[0]}
                    ${loggeduser.nome.split(' ')[1]} `}
                </b>
                {/* ===== */}
                <IconButton
                  appearance="primary"
                  onClick={toggleModeFunc}
                  color="red"
                  icon={toggleMode ? <BsFillSunFill /> : <FaMoon />}
                />
                {/* ===== */}
              </p>
            ) : (
              ''
            )}
          </Nav>
          {!expandido ? (
            <Navbar
              appearance="subtle"
              className="nav-toggle"
              style={{ backgroundColor: theme.primaryColor }}
            >
              <Navbar.Body>
                <Nav pullRight>
                  <Nav.Item>
                    <IconButton
                      onClick={toggleModeFunc}
                      color="red"
                      icon={toggleMode ? <BsFillSunFill /> : <FaMoon />}
                    />
                  </Nav.Item>
                </Nav>
              </Navbar.Body>
            </Navbar>
          ) : (
            ''
          )}

          {/* <Nav pullRight>
            <Nav.Item
              onClick={() => setExpandido(!expandido)}
              style={{ width: 56, textAlign: 'center' }}
            >
              {expandido ? <ArrowLeftLineIcon /> : <ArrowRightLineIcon />}
            </Nav.Item>
          </Nav> */}
        </Navbar.Body>
      </Navbar>
      <Navegacao
        expanded={expandido}
        defaultOpenKeys={['3']}
        appearance="inverse"
      >
        <Sidenav.Body>
          <Nav>
            {permissions && permicoes.profissionais && (
              <Dropdown
                eventKey="1"
                trigger="hover"
                title={<p style={{color: toggleMode && '#fff'}}>Profissionais ({novosProfissionaisCriadosNaoLidos})</p>}
                icon={
                  <TorsoBusiness
                    size="23"
                    style={{
                      position: 'absolute',
                      left: 0,
                      marginLeft: '16px',
                    }}
                  />
                }
                placement="rightStart"
              >
                {permissions &&
                  permicoes.profissionais &&
                  (permicoes.profissionais.includes('pro-1') ||
                    permicoes.profissionais.includes('pro-all')) && (
                    <Dropdown.Item
                      onSelect={() => history.replace('/home/profissionais')}
                      eventKey="1-1"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Lista</p>
                    </Dropdown.Item>
                  )}
                {permissions &&
                  permicoes.profissionais &&
                  (permicoes.profissionais.includes('pro-2') ||
                    permicoes.profissionais.includes('pro-all')) && (
                    <Dropdown.Item
                      onSelect={() =>
                        history.replace('/home/profissionais/bloqueados')
                      }
                      eventKey="1-2"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Lista - Profissionais Bloqueados</p>
                    </Dropdown.Item>
                  )}
                {permissions &&
                  permicoes.profissionais &&
                  (permicoes.profissionais.includes('pro-3') ||
                    permicoes.profissionais.includes('pro-all')) && (
                    <Dropdown.Item
                      onSelect={() =>
                        history.replace('/home/profissionais/criar')
                      }
                      eventKey="1-3"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Criar</p>
                    </Dropdown.Item>
                  )}
                {permissions &&
                  permicoes.profissionais &&
                  (permicoes.profissionais.includes('pro-4') ||
                    permicoes.profissionais.includes('pro-all')) && (
                    <Dropdown.Item
                      onSelect={() => {
                        history.replace(
                          '/home/relatorios/carteira-profissionais'
                        );
                      }}
                      eventKey="1-4"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Carteira</p>
                    </Dropdown.Item>
                  )}
                {permissions &&
                  permicoes.profissionais &&
                  (permicoes.profissionais.includes('pro-5') ||
                    permicoes.profissionais.includes('pro-all')) && (
                    <Dropdown.Item
                      onSelect={() => {
                        history.replace('/home/profissionais/disponiveis');
                      }}
                      eventKey="1-5"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Disponibilidade Registrada</p>
                    </Dropdown.Item>
                  )}
                {permissions &&
                  permicoes.profissionais &&
                  (permicoes.profissionais.includes('pro-6') ||
                    permicoes.profissionais.includes('pro-all')) && (
                    <Dropdown.Item
                      onSelect={() => {
                        history.replace('/home/profissionais/emergencia');
                      }}
                      eventKey="1-6"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Disponíveis Emergência</p>
                    </Dropdown.Item>
                  )}
                {permissions &&
                  permicoes.profissionais &&
                  (permicoes.profissionais.includes('pro-7') ||
                    permicoes.profissionais.includes('pro-all')) && (
                    <Dropdown.Item
                      onSelect={() =>
                        history.replace('/home/alertas/novo-profissional')
                      }
                      eventKey="1-7"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Novo Profissional ({novosProfissionaisCriadosNaoLidos})</p>
                    </Dropdown.Item>
                  )}
                {permissions &&
                  permicoes.profissionais &&
                  (permicoes.profissionais.includes('pro-8') ||
                    permicoes.profissionais.includes('pro-all')) && (
                    <Dropdown.Item
                      onSelect={() => {
                        history.replace(
                          '/home/relatorios/trabalhos-semana'
                        );
                      }}
                      eventKey="1-4"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Trabalhos da Semana</p>
                    </Dropdown.Item>
                  )}
              </Dropdown>
            )}
            {permissions && permicoes.estabelecimentos && (
              <Dropdown
                eventKey="2"
                trigger="hover"
                title={<p style={{color: toggleMode && '#fff'}}>Clientes ({novosEstabelecimentosCriadosNaoLidos})</p>}
                icon={
                  <Business
                    size="23"
                    style={{
                      position: 'absolute',
                      left: 0,
                      marginLeft: '16px',
                    }}
                  />
                }
                placement="rightStart"
              >
                {permissions &&
                  permicoes.estabelecimentos &&
                  (permicoes.estabelecimentos.includes('estab-1') ||
                    permicoes.estabelecimentos.includes('estab-all')) && (
                    <Dropdown.Item
                      onSelect={() => history.replace('/home/estabelecimentos')}
                      eventKey="2-1"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Lista</p>
                    </Dropdown.Item>
                  )}
                {permissions &&
                  permicoes.estabelecimentos &&
                  (permicoes.estabelecimentos.includes('estab-2') ||
                    permicoes.estabelecimentos.includes('estab-all')) && (
                    <Dropdown.Item
                      onSelect={() =>
                        history.replace('/home/estabelecimentos/bloqueados')
                      }
                      eventKey="2-2"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Lista - Clientes Bloqueados</p>
                    </Dropdown.Item>
                  )}
                {permissions &&
                  permicoes.estabelecimentos &&
                  (permicoes.estabelecimentos.includes('estab-3') ||
                    permicoes.estabelecimentos.includes('estab-all')) && (
                    <Dropdown.Item
                      onSelect={() =>
                        history.replace('/home/estabelecimentos/criar')
                      }
                      eventKey="2-3"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Criar</p>
                    </Dropdown.Item>
                  )}
                {permissions &&
                  permicoes.estabelecimentos &&
                  (permicoes.estabelecimentos.includes('estab-4') ||
                    permicoes.estabelecimentos.includes('estab-all')) && (
                    <Dropdown.Item
                      onSelect={() => {
                        history.replace('/home/relatorios/carteira-clientes');
                      }}
                      eventKey="2-4"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Carteira</p>
                    </Dropdown.Item>
                  )}
                {permissions &&
                  permicoes.estabelecimentos &&
                  (permicoes.estabelecimentos.includes('estab-2') ||
                    permicoes.estabelecimentos.includes('estab-all')) && (
                    <Dropdown.Item
                      onSelect={() =>
                        history.replace('/home/alertas/novo-estabelecimento')
                      }
                      eventKey="2-5"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Novo Cliente ({novosEstabelecimentosCriadosNaoLidos})</p>
                    </Dropdown.Item>
                  )}
              </Dropdown>
            )}

            {permissions && permicoes.pedidos && (
              <Dropdown
                eventKey="3"
                trigger="hover"
                title={<p style={{color: toggleMode && '#fff'}}>Pedidos ({calculoAlertaPedidos})</p>}
                icon={
                  <PersonPlusFill
                    size="23"
                    style={{
                      position: 'absolute',
                      left: 0,
                      marginLeft: '16px',
                    }}
                  />
                }
                placement="rightStart"
              >
                {permissions &&
                  permicoes.pedidos &&
                  (permicoes.pedidos.includes('ped-1') ||
                    permicoes.pedidos.includes('ped-all')) && (
                    <Dropdown.Item
                      onSelect={() => history.replace('/home/pedidos')}
                      eventKey="3-1"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Lista</p>
                    </Dropdown.Item>
                  )}
                {permissions &&
                  permicoes.pedidos &&
                  (permicoes.pedidos.includes('ped-2') ||
                    permicoes.pedidos.includes('ped-all')) && (
                    <Dropdown.Item
                      onSelect={() => history.replace('/home/pedidos/criar')}
                      eventKey="3-2"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Criar</p>
                    </Dropdown.Item>
                  )}

                {permissions &&
                  permicoes.pedidos &&
                  (permicoes.pedidos.includes('ped-3') ||
                    permicoes.pedidos.includes('ped-all')) && (
                    <Dropdown.Item
                      onSelect={() =>
                        history.replace('/home/pedidos/registros-dia')
                      }
                      eventKey="3-3"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Registros do Dia</p>
                    </Dropdown.Item>
                  )}
                {permissions &&
                  permicoes.pedidos &&
                  (permicoes.pedidos.includes('ped-3') ||
                    permicoes.pedidos.includes('ped-all')) && (
                    <Dropdown.Item
                      onSelect={() =>
                        history.replace('/home/pedidos/retorno-confirmacoes')
                      }
                      eventKey="3-3"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Retorno das Confirmações</p>
                    </Dropdown.Item>
                  )}

                {permissions &&
                  permicoes.pedidos &&
                  (permicoes.pedidos.includes('ped-4') ||
                    permicoes.pedidos.includes('ped-all')) && (
                    <Dropdown.Item
                      onSelect={() =>
                        history.replace('/home/alertas/alteracao-pedido')
                      }
                      eventKey="3-4"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Ajuste de Horário ({ajusteDeHorarioNaoLidos})</p>
                    </Dropdown.Item>
                  )}
                {permissions &&
                  permicoes.pedidos &&
                  (permicoes.pedidos.includes('ped-5') ||
                    permicoes.pedidos.includes('ped-all')) && (
                    <Dropdown.Item
                      onSelect={() =>
                        history.replace('/home/alertas/pedido-recebido')
                      }
                      eventKey="3-5"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Recebidos ({pedidosRecebidosNaoLidos})</p>
                    </Dropdown.Item>
                  )}
                {permissions &&
                  permicoes.pedidos &&
                  (permicoes.pedidos.includes('ped-6') ||
                    permicoes.pedidos.includes('ped-all')) && (
                    <Dropdown.Item
                      onSelect={() =>
                        history.replace('/home/alertas/pedido-cancelado')
                      }
                      eventKey="3-6"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Cancelados ({pedidosCanceladosNaoLidos})</p>
                    </Dropdown.Item>
                  )}

                {permissions &&
                  permicoes.pedidos &&
                  (permicoes.pedidos.includes('ped-7') ||
                    permicoes.pedidos.includes('ped-all')) && (
                    <Dropdown.Item
                      onSelect={() =>
                        history.replace('/home/pedidos/escala-fixa')
                      }
                      eventKey="3-7"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Escala Fixa</p>
                    </Dropdown.Item>
                  )}
              </Dropdown>
            )}
            {/* recrutamento e seleção */}
            {permissions && permicoes.recrutamento && (
              <Dropdown
                eventKey="10"
                trigger="hover"
                title={<p style={{color: toggleMode && '#fff'}}>Recrutamento</p>}
                icon={
                  <IoIosPeople
                    size="23"
                    style={{
                      position: 'absolute',
                      left: 0,
                      marginLeft: '16px',
                    }}
                  />
                }
                placement="rightStart"
              >
                {permissions &&
                  permicoes.recrutamento &&
                  (permicoes.recrutamento.includes('recrut-1') ||
                    permicoes.recrutamento.includes('recrut-all')) && (
                    <Dropdown.Item
                      onSelect={() =>
                        history.replace('/home/recrutamento-selecao')
                      }
                      eventKey="10-1"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Lista</p>
                    </Dropdown.Item>
                  )}
                {permissions &&
                  permicoes.recrutamento &&
                  (permicoes.recrutamento.includes('recrut-2') ||
                    permicoes.recrutamento.includes('recrut-all')) && (
                    <Dropdown.Item
                      onSelect={() =>
                        history.replace('/home/recrutamento-selecao/criar')
                      }
                      eventKey="10-2"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Criar</p>
                    </Dropdown.Item>
                  )}
                {permissions &&
                  permicoes.recrutamento &&
                  (permicoes.recrutamento.includes('recrut-3') ||
                    permicoes.recrutamento.includes('recrut-all')) && (
                    <Dropdown.Item
                      onSelect={() =>
                        history.replace('/home/recrutamento-lista')
                      }
                      eventKey="10-3"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Lista Pipeline</p>
                    </Dropdown.Item>
                  )}
                {permissions &&
                  permicoes.recrutamento &&
                  (permicoes.recrutamento.includes('recrut-3') ||
                    permicoes.recrutamento.includes('recrut-all')) && (
                    <Dropdown.Item
                      onSelect={() =>
                        history.replace('/home/recrutamento-cadidatos-gerais')
                      }
                      eventKey="10-3"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Cadidatos Gerais</p>
                    </Dropdown.Item>
                  )}
              </Dropdown>
            )}

            {permissions && permicoes.opcoes && (
              <Dropdown
                eventKey="4"
                trigger="hover"
                title={<p style={{color: toggleMode && '#fff'}}>Opções</p>}
                icon={
                  <Options
                    size="23"
                    style={{
                      position: 'absolute',
                      left: 0,
                      marginLeft: '16px',
                    }}
                  />
                }
                placement="rightStart"
              >
                {permissions &&
                  permicoes.opcoes &&
                  (permicoes.opcoes.includes('op-1') ||
                    permicoes.opcoes.includes('op-all')) && (
                    <Dropdown.Item
                      onSelect={() => history.replace('/home/idiomas')}
                      eventKey="4-1"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Idiomas</p>
                    </Dropdown.Item>
                  )}
                  {permissions &&
                  permicoes.opcoes &&
                  (permicoes.opcoes.includes('op-15') ||
                    permicoes.opcoes.includes('op-all')) && (
                    <Dropdown.Item
                      onSelect={() => history.replace('/home/ComoDescobriu')}
                      eventKey="4-15"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Como Descobriu</p>
                    </Dropdown.Item>
                  )}
                  {permissions &&
                  permicoes.opcoes &&
                  (permicoes.opcoes.includes('op-15') ||
                    permicoes.opcoes.includes('op-all')) && (
                    <Dropdown.Item
                      onSelect={() => history.replace('/home/quantidade-colaboradores')}
                      eventKey="4-15"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Quantidade de Colaboradores</p>
                    </Dropdown.Item>
                  )}
                  {permissions &&
                  permicoes.opcoes &&
                  (permicoes.opcoes.includes('op-16') ||
                    permicoes.opcoes.includes('op-all')) && (
                    <Dropdown.Item
                      onSelect={() => history.replace('/home/generos')}
                      eventKey="4-15"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Generos</p>
                    </Dropdown.Item>
                  )}
                {permissions &&
                  permicoes.opcoes &&
                  (permicoes.opcoes.includes('op-2') ||
                    permicoes.opcoes.includes('op-all')) && (
                    <Dropdown.Item
                      onSelect={() => history.replace('/home/profissoes')}
                      eventKey="4-2"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Serviços</p>
                    </Dropdown.Item>
                  )}
                {permissions &&
                  permicoes.opcoes &&
                  (permicoes.opcoes.includes('op-3') ||
                    permicoes.opcoes.includes('op-all')) && (
                    <Dropdown.Item
                      onSelect={() =>
                        history.replace('/home/tipos-estabelecimentos')
                      }
                      eventKey="4-3"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Áreas de Atuação</p>
                    </Dropdown.Item>
                  )}
                {permissions &&
                  permicoes.opcoes &&
                  (permicoes.opcoes.includes('op-4') ||
                    permicoes.opcoes.includes('op-all')) && (
                    <Dropdown.Item
                      onSelect={() => history.replace('/home/escolaridades')}
                      eventKey="4-4"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Escolaridade</p>
                    </Dropdown.Item>
                  )}
                {permissions &&
                  permicoes.opcoes &&
                  (permicoes.opcoes.includes('op-5') ||
                    permicoes.opcoes.includes('op-all')) && (
                    <Dropdown.Item
                      onSelect={() =>
                        history.replace('/home/tempo-experiencias')
                      }
                      eventKey="4-5"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Tempo de Experiência</p>
                    </Dropdown.Item>
                  )}
                {permissions &&
                  permicoes.opcoes &&
                  (permicoes.opcoes.includes('op-6') ||
                    permicoes.opcoes.includes('op-all')) && (
                    <Dropdown.Item
                      onSelect={() => history.replace('/home/habilidades')}
                      eventKey="4-6"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Habilidades</p>
                    </Dropdown.Item>
                  )}

                {permissions &&
                  permicoes.opcoes &&
                  (permicoes.opcoes.includes('op-7') ||
                    permicoes.opcoes.includes('op-all')) && (
                    <Dropdown.Item
                      onSelect={() =>
                        history.replace('/home/dias-funcionamento')
                      }
                      eventKey="4-7"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Dias de Funcionamento</p>
                    </Dropdown.Item>
                  )}

                {permissions &&
                  permicoes.opcoes &&
                  (permicoes.opcoes.includes('op-8') ||
                    permicoes.opcoes.includes('op-all')) && (
                    <Dropdown.Item
                      onSelect={() =>
                        history.replace('/home/periodos-funcionamento')
                      }
                      eventKey="4-8"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Períodos de Funcionamento</p>
                    </Dropdown.Item>
                  )}
                {permissions &&
                  permicoes.opcoes &&
                  (permicoes.opcoes.includes('op-9') ||
                    permicoes.opcoes.includes('op-all')) && (
                    <Dropdown.Item
                      onSelect={() => history.replace('/home/uniformes')}
                      eventKey="4-8"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Uniformes</p>
                    </Dropdown.Item>
                  )}
                {permissions &&
                  permicoes.opcoes &&
                  (permicoes.opcoes.includes('op-10') ||
                    permicoes.opcoes.includes('op-all')) && (
                    <Dropdown.Item
                      onSelect={() => history.replace('/home/cupons')}
                      eventKey="4-9"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Cupons</p>
                    </Dropdown.Item>
                  )}
                {permissions &&
                  permicoes.opcoes &&
                  (permicoes.opcoes.includes('op-11') ||
                    permicoes.opcoes.includes('op-all')) && (
                    <Dropdown.Item
                      onSelect={() => history.replace('/home/treinamentos')}
                      eventKey="4-10"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Treinamentos</p>
                    </Dropdown.Item>
                  )}
                {permissions &&
                  permicoes.opcoes &&
                  (permicoes.opcoes.includes('op-12') ||
                    permicoes.opcoes.includes('op-all')) && (
                    <Dropdown.Item
                      onSelect={() =>
                        history.replace('/home/motivo-cancelamento')
                      }
                      eventKey="4-12"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Motivos de Cancelamento</p>
                    </Dropdown.Item>
                  )}
              </Dropdown>
            )}
            {permissions && permicoes.tarifas && (
              <Dropdown
                eventKey="8"
                trigger="hover"
                title={<p style={{color: toggleMode && '#fff'}}>Tarifas</p>}
                icon={
                  <GrowthIcon
                    size="23"
                    style={{
                      position: 'absolute',
                      left: 0,
                      marginLeft: '16px',
                    }}
                  />
                }
                placement="rightStart"
              >
                {permissions &&
                  permicoes.tarifas &&
                  (permicoes.tarifas.includes('tarif-1') ||
                    permicoes.tarifas.includes('tarif-all')) && (
                    <Dropdown.Item
                      onSelect={() => history.replace('/home/estado')}
                      eventKey="8-1"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Estados</p>
                    </Dropdown.Item>
                  )}
                {permissions &&
                  permicoes.tarifas &&
                  (permicoes.tarifas.includes('tarif-2') ||
                    permicoes.tarifas.includes('tarif-all')) && (
                    <Dropdown.Item
                      onSelect={() => history.replace('/home/tarifa-feriado')}
                      eventKey="8-2"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Tarifas Feriados</p>
                    </Dropdown.Item>
                  )}
                {/* {permissions &&
                  permicoes.tarifas &&
                  (permicoes.tarifas.includes('tarif-3') ||
                    permicoes.tarifas.includes('tarif-all')) && (
                    <Dropdown.Item
                      onSelect={() => history.replace('/home/tarifa-repasse')}
                      eventKey="8-2"
                    >
                      Tarifas Repasses
                    </Dropdown.Item>
                  )} */}
              </Dropdown>
            )}
            {permissions && permicoes.relatorios && (
              <Dropdown
                eventKey="5"
                trigger="hover"
                title={<p style={{color: toggleMode && '#fff'}}>Relatórios</p>}
                icon={
                  <Report
                    size="23"
                    style={{
                      position: 'absolute',
                      left: 0,
                      marginLeft: '16px',
                    }}
                  />
                }
                placement="rightStart"
              >
                {permissions &&
                  permicoes.relatorios &&
                  (permicoes.relatorios.includes('rel-1') ||
                    permicoes.relatorios.includes('rel-all')) && (
                    <Dropdown.Item
                      onSelect={() =>
                        history.replace('/home/relatorios/profissional')
                      }
                      eventKey="5-1"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Profissional</p>
                    </Dropdown.Item>
                  )}
                {permissions &&
                  permicoes.relatorios &&
                  (permicoes.relatorios.includes('rel-2') ||
                    permicoes.relatorios.includes('rel-all')) && (
                    <Dropdown.Item
                      onSelect={() => {
                        history.replace('/home/relatorios/estabelecimento');
                      }}
                      eventKey="5-2"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Cliente</p>
                    </Dropdown.Item>
                  )}
                {permissions &&
                  permicoes.relatorios &&
                  (permicoes.relatorios.includes('rel-8') ||
                    permicoes.relatorios.includes('rel-all')) && (
                    <Dropdown.Item
                      onSelect={() => {
                        history.replace('/home/relatorios/pedidos-cancelados');
                      }}
                      eventKey="5-2"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Pedidos Cancelados</p>
                    </Dropdown.Item>
                  )}
                {permissions &&
                  permicoes.relatorios &&
                  (permicoes.relatorios.includes('rel-4') ||
                    permicoes.relatorios.includes('rel-all')) && (
                    <Dropdown.Item
                      onSelect={() => {
                        history.replace('/home/relatorios/dashboard');
                      }}
                      eventKey="5-4"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Dashboard</p>
                    </Dropdown.Item>
                  )}

                {permissions &&
                  permicoes.relatorios &&
                  (permicoes.relatorios.includes('rel-5') ||
                    permicoes.relatorios.includes('rel-all')) && (
                    <Dropdown.Item
                      onSelect={() => {
                        history.replace('/home/relatorios/metricas');
                      }}
                      eventKey="5-6"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Métricas</p>
                    </Dropdown.Item>
                  )}
                {permissions &&
                  permicoes.relatorios &&
                  (permicoes.relatorios.includes('rel-6') ||
                    permicoes.relatorios.includes('rel-all')) && (
                    <Dropdown.Item
                      onSelect={() => {
                        history.replace('/home/relatorios/clientes-ativados');
                      }}
                      eventKey="5-7"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Clientes Ativados</p>
                    </Dropdown.Item>
                  )}
                {permissions &&
                  permicoes.relatorios &&
                  (permicoes.relatorios.includes('rel-8') ||
                    permicoes.relatorios.includes('rel-all')) && (
                    <Dropdown.Item
                      onSelect={() => {
                        history.replace(
                          '/home/relatorios/clientes-inadimplentes'
                        );
                      }}
                      eventKey="5-7"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Clientes Inadimplentes</p>
                    </Dropdown.Item>
                  )}
                {permissions &&
                  permicoes.relatorios &&
                  (permicoes.relatorios.includes('rel-7') ||
                    permicoes.relatorios.includes('rel-all')) && (
                    <Dropdown.Item
                      onSelect={() => {
                        history.replace('/home/relatorios/notificacoes');
                      }}
                      eventKey="5-8"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Relatório Notificações</p>
                    </Dropdown.Item>
                  )}
                {permissions &&
                  permicoes.relatorios &&
                  (permicoes.relatorios.includes('rel-7') ||
                    permicoes.relatorios.includes('rel-all')) && (
                    <Dropdown.Item
                      onSelect={() => {
                        history.replace('/home/relatorios/recorrencia');
                      }}
                      eventKey="5-9"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Análise Recorrência</p>
                    </Dropdown.Item>
                  )}
              </Dropdown>
            )}

            {/* IUGU */}

            {permissions && permicoes.iugu && (
              <Dropdown
                eventKey="9"
                trigger="hover"
                title={<p style={{color: toggleMode && '#fff'}}>Iugu ({falhaNaCobrancaCount})</p>}
                icon={
                  <Report
                    size="23"
                    style={{
                      position: 'absolute',
                      left: 0,
                      marginLeft: '16px',
                    }}
                  />
                }
                placement="rightStart"
              >
                {permissions &&
                  permicoes.iugu &&
                  (permicoes.iugu.includes('iugu-1') ||
                    permicoes.iugu.includes('iugu-all')) && (
                    <Dropdown.Item
                      onSelect={() => history.replace('/home/iugu/index')}
                      eventKey="9-1"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Faturas</p>
                    </Dropdown.Item>
                  )}
                {permissions &&
                  permicoes.iugu &&
                  (permicoes.iugu.includes('iugu-2') ||
                    permicoes.iugu.includes('iugu-all')) && (
                    <Dropdown.Item
                      onSelect={() =>
                        history.replace('/home/alertas/falha-cobranca')
                      }
                      eventKey="9-2"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Falha na Cobrança ({falhaNaCobrancaCount})</p>
                    </Dropdown.Item>
                  )}
              </Dropdown>
            )}

            {/* STARK */}

            {/* {permissions && permicoes.stark && ( */}
              <Dropdown
                eventKey="11"
                trigger="hover"
                title={<p style={{color: toggleMode && '#fff'}}>Stark ({falhaNaCobrancaCount})</p>}
                icon={
                  <Report
                    size="23"
                    style={{
                      position: 'absolute',
                      left: 0,
                      marginLeft: '16px',
                    }}
                  />
                }
                placement="rightStart"
              >
                {/* {permissions &&
                  permicoes.stark &&
                  (permicoes.stark.includes('stark-1') ||
                    permicoes.stark.includes('stark-all')) && ( */}
                    <Dropdown.Item
                      onSelect={() => history.replace('/home/stark/index')}
                      eventKey="11-1"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Faturas</p>
                    </Dropdown.Item>
                  {/* )} */}
              </Dropdown>
            {/* )} */}


{/* <Dropdown
                eventKey="11"
                trigger="hover"
                title={<p style={{color: toggleMode && '#fff'}}>Stark22 ({falhaNaCobrancaCount})</p>}
                icon={
                  <Report
                    size="23"
                    style={{
                      position: 'absolute',
                      left: 0,
                      marginLeft: '16px',
                    }}
                  />
                }
                placement="rightStart"
              >

                    <Dropdown.Item
                      onSelect={() => history.replace('/home/stark/index')}
                      eventKey="11-1"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Faturas22</p>
                    </Dropdown.Item>
              </Dropdown> */}

            {permissions && permicoes.notificacoes && (
              <Dropdown
                eventKey="6"
                trigger="hover"
                title={<p style={{color: toggleMode && '#fff'}}>Mensagens</p>}
                icon={
                  <BiMessageDetail
                    size="23"
                    style={{
                      position: 'absolute',
                      left: 0,
                      marginLeft: '16px',
                    }}
                  />
                }
                placement="rightStart"
              >
                {permissions &&
                  permicoes.notificacoes &&
                  (permicoes.notificacoes.includes('noti-1') ||
                    permicoes.notificacoes.includes('noti-all')) && (
                    <Dropdown.Item
                      onSelect={() =>
                        history.replace('/home/popup')
                      }
                      eventKey="6-2"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Enviar popup</p>
                    </Dropdown.Item>
                  )}
                {permissions &&
                  permicoes.notificacoes &&
                  (permicoes.notificacoes.includes('noti-1') ||
                    permicoes.notificacoes.includes('noti-all')) && (
                    <Dropdown.Item
                      onSelect={() =>
                        history.replace('/home/popup/list')
                      }
                      eventKey="6-2"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Lista de popups</p>
                    </Dropdown.Item>
                  )}
                {permissions &&
                  permicoes.notificacoes &&
                  (permicoes.notificacoes.includes('noti-2') ||
                    permicoes.notificacoes.includes('noti-all')) && (
                    <Dropdown.Item
                      onSelect={() =>
                        history.replace('/home/notificacoes/profissionais')
                      }
                      eventKey="6-2"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Enviar notificações profissionais</p>
                    </Dropdown.Item>
                  )}
                {permissions &&
                  permicoes.notificacoes &&
                  (permicoes.notificacoes.includes('noti-3') ||
                    permicoes.notificacoes.includes('noti-all')) && (
                    <Dropdown.Item
                      onSelect={() =>
                        history.replace('/home/announcements/professional/list')
                      }
                      eventKey="6-3"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Comunicados de Profissionais</p>
                    </Dropdown.Item>
                  )}
                {permissions &&
                  permicoes.notificacoes &&
                  (permicoes.notificacoes.includes('noti-4') ||
                    permicoes.notificacoes.includes('noti-all')) && (
                    <Dropdown.Item
                      onSelect={() =>
                        history.replace('/home/notificacoes/estabelecimentos')
                      }
                      eventKey="6-4"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Enviar notificações estabelecimentos</p>
                    </Dropdown.Item>
                  )}
                {permissions &&
                  permicoes.notificacoes &&
                  (permicoes.notificacoes.includes('noti-5') ||
                    permicoes.notificacoes.includes('noti-all')) && (
                    <Dropdown.Item
                      onSelect={() =>
                        history.replace(
                          '/home/announcements/estabelecimento/list'
                        )
                      }
                      eventKey="6-5"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Comunicados de Estabelecimentos</p>
                    </Dropdown.Item>
                  )}
              </Dropdown>
            )}

            {/* {permissions && permicoes.notificacoes && (
              <Dropdown
                eventKey="6"
                trigger="hover"
                title={
                  localizacaoNaoLidas
                    ? `Alertas (${localizacaoNaoLidas})`
                    : 'Alertas'
                }
                icon={
                  <Notifications
                    size="23"
                    style={{
                      position: 'absolute',
                      left: 0,
                      marginLeft: '16px',
                    }}
                  />
                }
                placement="rightStart"
              >


                {permissions &&
                  permicoes.notificacoes &&
                  (permicoes.notificacoes.includes('noti-3') ||
                    permicoes.notificacoes.includes('noti-all')) && (
                    <Dropdown.Item
                      onSelect={() =>
                        history.replace('/home/alertas/localizacao-recebida')
                      }
                      eventKey="5-2"
                    >
                      Localização Recebida {localizacaoNaoLidas}
                    </Dropdown.Item>
                  )}

              </Dropdown>
            )} */}

            {permissions && permicoes.administracao && (
              <Dropdown
                eventKey="7"
                trigger="hover"
                title={<p style={{color: toggleMode && '#fff'}}>Administração</p>}
                icon={
                  <AdminPanelSettings
                    size="23"
                    style={{
                      position: 'absolute',
                      left: 0,
                      marginLeft: '16px',
                    }}
                  />
                }
                placement="rightStart"
              >
                {permissions &&
                  permicoes.administracao &&
                  (permicoes.administracao.includes('adm-1') ||
                    permicoes.administracao.includes('adm-all')) && (
                    <Dropdown.Item
                      onSelect={() =>
                        history.replace('/home/administracao/usuarios')
                      }
                      eventKey="7-1"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Usuários</p>
                    </Dropdown.Item>
                  )}
                {permissions &&
                  permicoes.administracao &&
                  (permicoes.administracao.includes('adm-1') ||
                    permicoes.administracao.includes('adm-all')) && (
                    <Dropdown.Item
                      onSelect={() =>
                        history.replace('/home/administracao/logs')
                      }
                      eventKey="7-1"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Logs</p>
                    </Dropdown.Item>
                  )}
                {permissions &&
                  permicoes.administracao &&
                  (permicoes.administracao.includes('adm-1') ||
                    permicoes.administracao.includes('adm-all')) && (
                    <Dropdown.Item
                      onSelect={() =>
                        history.replace('/home/administracao/controle')
                      }
                      eventKey="7-1"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Controle de Matchings</p>
                    </Dropdown.Item>
                  )}
                    {permissions &&
                  permicoes.administracao &&
                  (permicoes.administracao.includes('adm-1') ||
                    permicoes.administracao.includes('adm-all')) && (
                    <Dropdown.Item
                      onSelect={() =>
                        history.replace('/home/administracao/controle-liberacao')
                      }
                      eventKey="7-1"
                    >
                      <p style={{color: toggleMode && '#fff' }}>Controle de Liberação de Cadastro</p>
                    </Dropdown.Item>
                  )}
              </Dropdown>
            )}
          </Nav>
        </Sidenav.Body>
      </Navegacao>
      <ExpandirDiv />
      <Navbar
        appearance="subtle"
        className="nav-toggle"
        style={{ backgroundColor: theme.primaryColor }}
      >
        <Navbar.Body>
          <Nav pullRight>
            <Nav.Item
              onClick={() => setExpandido(!expandido)}
              style={{ width: 56, textAlign: 'center' }}
            >
              {expandido ? <ArrowLeftLineIcon /> : <ArrowRightLineIcon />}
            </Nav.Item>
          </Nav>
        </Navbar.Body>
      </Navbar>
    </MenuLateral>
  );
}

export default Menu;
