/* eslint-disable no-shadow */
import produce from 'immer';
import types from './types';

const STATES = [];
const PERMISSIONS = {};
const USER = {};

export function states(state = STATES, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case types.GET_STATES:
        draft.push(...action.payload);
        break;
      default:
    }
  });
}

export function permissions(permissions = PERMISSIONS, action) {
  return produce(permissions, (draft) => {
    switch (action.type) {
      case types.SET_PERMISSIONS:
        draft.permissions = action.payload;
        break;
      default:
    }
  });
}

export function setUser(user = USER, action) {
  return produce(user, (draft) => {
    switch (action.type) {
      case types.SET_USER:
        draft.data = action.payload;
        break;
      default:
    }
  });
}
