import produce from "immer";

const ESTADO_INICIAL = {
  notificacoes: { lista: [], quantidade: 0 },
  notificacoesNaoLidas: 0,
  pedidosRecebidosNaoLidos: 0,
  pedidosCanceladosNaoLidos: 0,
  novosProfissionaisCriadosNaoLidos: 0,
  novosEstabelecimentosCriadosNaoLidos: 0,
  localizacaoNaoLidas: 0,
  ajusteDeHorarioNaoLidos: 0,
  AtualizacaoPaginaTempoReal: false,
  tokenNotificacao: '',
  location: false,
  falhaNaCobrancaCount: 0,
};

export default function autenticacao(state = ESTADO_INICIAL, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      // case '@notificacao/BUSCAR_NOTIFICACOES_SUCESSO': {
      //   draft.notificacoes = action.payload.notificacoes;
      //   draft.notificacoesNaoLidas = action.payload.notificacoesNaoLidas;
      //   break;
      // }
      case '@notificacao/BUSCAR_NOTIFICACOES_SUCESSO': {
        draft.notificacoes = action.payload.notificacoes;
        draft.notificacoesNaoLidas = action.payload.notificacoesNaoLidas;
        draft.pedidosRecebidosNaoLidos = action.payload.pedidosRecebidosNaoLidos;
        draft.pedidosCanceladosNaoLidos = action.payload.pedidosCanceladosNaoLidos;
        draft.novosProfissionaisCriadosNaoLidos = action.payload.novosProfissionaisCriadosNaoLidos;
        draft.novosEstabelecimentosCriadosNaoLidos = action.payload.novosEstabelecimentosCriadosNaoLidos;
        draft.localizacaoNaoLidas = action.payload.localizacaoNaoLidas;
        draft.ajusteDeHorarioNaoLidos = action.payload.ajusteDeHorarioNaoLidos;
        draft.falhaNaCobrancaCount = action.payload.contadorFalhaCobranca;
        break;
      }
      case '@notificacao/BUSCAR_NOTIFICACOES_PEDIDOS_RECEBIDOS_NAO_LIDOS_SUCESSO': {
        draft.pedidosRecebidosNaoLidos = action.payload.pedidosRecebidosNaoLidos;
        break;
      }
      case '@notificacao/BUSCAR_NOTIFICACOES_PEDIDOS_CANCELADOS_NAO_LIDOS_SUCESSO': {
        draft.pedidosCanceladosNaoLidos = action.payload.pedidosCanceladosNaoLidos;
        break;
      }
      case '@notificacao/BUSCAR_NOTIFICACOES_NOVOS_PROFISSIONAIS_CRIADOS_NAO_LIDOS_SUCESSO': {
        draft.novosProfissionaisCriadosNaoLidos = action.payload.novosProfissionaisCriadosNaoLidos;
        break;
      }
      case '@notificacao/BUSCAR_NOTIFICACOES_NOVOS_ESTABELECIMENTOS_CRIADOS_NAO_LIDOS_SUCESSO': {
        draft.novosEstabelecimentosCriadosNaoLidos = action.payload.novosEstabelecimentosCriadosNaoLidos;
        break;
      }
      case '@notificacao/BUSCAR_NOTIFICACOES_LOCALIZACAO_NAO_LIDAS_SUCESSO': {
        draft.localizacaoNaoLidas = action.payload.localizacaoNaoLidas;
        break;
      }
      case '@notificacao/BUSCAR_NOTIFICACOES_AJUSTE_DE_HORARIO_NAO_LIDOS_SUCESSO': {
        draft.ajusteDeHorarioNaoLidos = action.payload.ajusteDeHorarioNaoLidos;
        break;
      }

      case '@FALHA_NA_COBRANCA_COUNT': {
        draft.falhaNaCobrancaCount = action.payload.falhaNaCobrancaCount;
        break;
      }

      case '@notificacao/SALVAR_TOKEN_NOTIFICACAO': {
        draft.tokenNotificacao = action.payload.tokenNotificacao;
        break;
      }

      case '@notificacao/NOVA_NOTIFICACAO': {
        draft.notificacoesNaoLidas += 1;
        break;
      }

      case '@notificacao/NOVA_NOTIFICACAO_NOVO_PEDIDO': {
        draft.pedidosRecebidosNaoLidos += 1;
        break;
      }

      case '@notificacao/NOVA_NOTIFICACAO_PEDIDO_CANCELADO': {
        draft.pedidosCanceladosNaoLidos += 1;
        break;
      }

      case '@notificacao/NOVA_NOTIFICACAO_NOVO_PROFISSIONAL': {
        draft.novosProfissionaisCriadosNaoLidos += 1;
        break;
      }

      case '@notificacao/NOVA_NOTIFICACAO_NOVO_ESTABELECIMENTO': {
        draft.novosEstabelecimentosCriadosNaoLidos += 1;
        break;
      }

      case '@notificacao/NOVA_NOTIFICACAO_LOCALIZACAO': {
        draft.localizacaoNaoLidas += 1;
        break;
      }

      case '@notificacao/NOVA_NOTIFICACAO_AJUSTE_DE_HORARIO': {
        draft.ajusteDeHorarioNaoLidos += 1;
        break;
      }

      case '@notificacao/NOVA_NOTIFICACAO_REDUZIR_ALTERACAO_PEDIDO': {
        draft.ajusteDeHorarioNaoLidos -= 1;
        break;
      }
      case '@notificacao/ACRESCENTAR_CONTADOR_FALHA_NA_COBRANCA': {
        draft.falhaNaCobrancaCount += 1;
        break;
      }
      case '@notificacao/REDUZIR_CONTADOR_FALHA_NA_COBRANCA': {
        draft.falhaNaCobrancaCount -= 1;
        break;
      }

      case '@ATUALIZACAO_GLOBAL_TEMPO_REAL': {
        draft.AtualizacaoPaginaTempoReal = !draft.AtualizacaoPaginaTempoReal;
        draft.notificacoes = action.payload.notificacoes;
        draft.notificacoesNaoLidas = action.payload.notificacoesNaoLidas;
        draft.pedidosRecebidosNaoLidos = action.payload.pedidosRecebidosNaoLidos;
        draft.pedidosCanceladosNaoLidos = action.payload.pedidosCanceladosNaoLidos;
        draft.novosProfissionaisCriadosNaoLidos = action.payload.novosProfissionaisCriadosNaoLidos;
        draft.novosEstabelecimentosCriadosNaoLidos = action.payload.novosEstabelecimentosCriadosNaoLidos;
        // draft.localizacaoNaoLidas = action.payload.localizacaoNaoLidas;
        draft.ajusteDeHorarioNaoLidos = action.payload.ajusteDeHorarioNaoLidos;
        break;
      }

      case '@notificacao/GET_LOCATION': {
        if (draft.location) {
          draft.location = false;
        } else {
          draft.location = true;
        }
        break;
      }

      default:
    }
  });
}
