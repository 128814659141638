/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import { isBefore, isEqual, parse } from 'date-fns';
import { Divider, Table } from 'rsuite';
import PropTypes from 'prop-types';
import { Container, PaginationBar } from '../TabelaPaginacao/styles';

export function TabelaSemPaginacao({
  dados,
  carregando,
  children,
  semPaginationBar,
}) {
  // const [page, setPage] = useState(1);
  const [sortType, setSortType] = useState();
  const [sortColumn, setSortColumn] = useState();
  const page = 1;

  function getData() {
    let dadosOrdenados;
    if (sortColumn && sortType) {
      dadosOrdenados = dados.sort((a, b) => {
        if (a[sortColumn] && b[sortColumn]) {
          const x = a[sortColumn].toString();
          const y = b[sortColumn].toString();
          if (x.includes('/') && y.includes('/')) {
            const xDate = parse(x, 'dd/LL/yyyy', new Date());
            const yDate = parse(y, 'dd/LL/yyyy', new Date());
            if (isBefore(xDate, yDate)) {
              return -1;
            }
            if (isEqual(xDate, yDate)) {
              return 0;
            }
            return 1;
          }
          return x.localeCompare(y, 'pt-BR', { numeric: true });
        }

        return 0;
      });
      if (sortType === 'desc') {
        dadosOrdenados.sort().reverse();
      }
    }
    if (!dadosOrdenados) dadosOrdenados = dados;

    const dadosFiltrados = dadosOrdenados.filter((v, i) => {
      const start = 100 * (page - 1);
      const end = start + 100;
      return i >= start && i < end;
    });
    return dadosFiltrados;
  }

  function handleSortColumn(sortColumn1, sortType1) {
    setSortColumn(sortColumn1);
    setSortType(sortType1);
  }

  const dadosTabela = getData();
  return (
    <Container>
      <Table
        loading={carregando}
        data={dadosTabela}
        sortType={sortType}
        sortColumn={sortColumn}
        // eslint-disable-next-line react/jsx-no-bind
        onSortColumn={handleSortColumn}
        autoHeight
        autoWidth
      >
        {children}
      </Table>
      {semPaginationBar ? null : (
        <PaginationBar>
          <span style={{ marginLeft: 20 }}>
            <Divider vertical />
            Total: {dados.length}
          </span>
        </PaginationBar>
      )}
    </Container>
  );
}

TabelaSemPaginacao.propTypes = {
  dados: PropTypes.array,
  carregando: PropTypes.bool.isRequired,
  semPaginationBar: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

TabelaSemPaginacao.defaultProps = {
  semPaginationBar: false,
  dados: [],
};
