import createSagaMiddleware from 'redux-saga';
import { persistStore } from 'redux-persist';

import { adicionarInterceptor } from '~/services/api';
import persistirReducer from './persistirReducer';
import criarStore from './criarStore';
import rootReducer from './modulos/rootReducer';
import rootSaga from './modulos/rootSaga';

// const sagaMonitor =
//   process.env.NODE_ENV === 'development'
//     ? console.tron.createSagaMonitor()
//     : null;

// const sagaMiddleware = createSagaMiddleware(sagaMonitor);
const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

const store = criarStore(persistirReducer(rootReducer), middlewares);
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

adicionarInterceptor(store);

export { store, persistor };
