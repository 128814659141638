/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import {
  FlexboxGrid,
  Grid,
  Row,
  Col,
  Form,
  Schema,
  Loader,
  Panel,
  Drawer,
  Divider,
} from 'rsuite';
import {
  buscarPedido,
  atualizarCamposInput,
  salvarPedido,
  sairPagina,
} from '~/store/modulos/pedido/actions';
import { ResponsiveForm, ResponsiveFormTextArea } from '~/components/ResponsiveForm';
import { format } from 'date-fns';
import { ButtonFullBlue } from '../../../components/Buttons/ButtonFullBlue/styles';

import { Input, Container } from './styles';
import ListaRegistro from '../components/ListaRegistro';
import CamposDadosPedido from '../components/CamposDadosPedido';

import FinalizarPedidoDialog from '../components/FinalizarPedidoDialog';
import { ButtonBorderBlue } from '../../../components/Buttons/ButtonBorderBlue/styles';
import api from '../../../services/api';

const { StringType, NumberType, ArrayType, DateType } = Schema.Types;

function EditarPedido({
  idPedido,
  showEditarPedido,
  codPedido,
  setShowEditarPedido,
  fecharEditarPedido,
  setAtualizar,
}) {
  const dispatch = useDispatch();
  const carregando = useSelector((state) => state.pedido.carregando);
  const camposInput = useSelector((state) => state.pedido.camposInput);
  const registros = useSelector((state) => state.pedido.registros);

  const [exibirFinalizarDialog, setExibirFinalizarDialog] = useState(false);
  const [uniformes, setUniformes] = useState([]);
  const [filtroCancelado, setFiltroCancelado] = useState(false);
  const [modalAberta, setModalAberta] = useState(false)

  const modelo = Schema.Model({
    id_estabelecimento: NumberType('Número inválido').isRequired(
      'Selecione um Cliente'
    ),
    id_uniforme: NumberType('Número inválido').isRequired(
      'Uniforme obrigatório'
    ),
    observacao: StringType(),
    obs_profissionais: StringType(),
    intervalo_texto: StringType()
      .addRule((valor) => {
        const horas = valor.substr(0, 2);
        const minutos = valor.substr(2, 4);
        if (horas === '' || minutos === '') return false;

        return true;
      }, 'Intervalo Inválido')
      .isRequired(),
    data: DateType().isRequired(),
    tarifa_dinamica: NumberType('Apenas números inteiros')
      .max(1000)
      .min(0)
      .isRequired('Tarifa Dinamica obrigatoria'),
    hora_inicio_texto: StringType()
      .addRule((valor) => {
        const horas = valor.substr(0, 2);
        const minutos = valor.substr(2, 4);
        if (horas === '' || minutos === '') return false;
        return true;
      }, 'Data inválida')
      .isRequired(),
    hora_fim_texto: StringType()
      .addRule((valor) => {
        const horas = valor.substr(0, 2);
        const minutos = valor.substr(2, 4);
        if (horas === '' || minutos === '') return false;
        return true;
      }, 'Data inválida')
      .isRequired(),
    profissoes: ArrayType().of(NumberType()),
  });

  let erro = {};

  useEffect(() => {
    idPedido && dispatch(buscarPedido(idPedido));
    return () => {
      dispatch(sairPagina());
    };
  }, [idPedido, dispatch]);

  function exibirMensagemErro() {
    Object.values(erro).forEach((e) => {
      toast.error(e);
    });
  }

  async function finalizar(semErro) {
    semErro ? await dispatch(salvarPedido()) : await exibirMensagemErro();
    setAtualizar((status) => !status)
    fecharEditarPedido();
  }

  useEffect(() => {
    const buscarUniformes = async () => {
      try {
        const resposta = await api.get('uniforme');
        setUniformes(resposta.data);
      } catch (error) {
        toast.error(error.message);
      }
    };

    buscarUniformes();
  }, []);



  return (
    <Container>

      <Drawer
        full
        open={showEditarPedido}
        keyboard={!modalAberta}
        onClose={() => setShowEditarPedido(false)}
      >
        <Drawer.Header>
          <Drawer.Title>Editar Pedido {codPedido}</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <Form
            id="editarPedido"
            model={modelo}
            onChange={(campos) => {
              dispatch(atualizarCamposInput(campos));
            }}
            formValue={camposInput}
            onSubmit={(semErro) => finalizar(semErro)}
            onError={(err) => {
              erro = err;
            }}
          >
            <CamposDadosPedido
              editarPedido
              finalizarPedido={() => setExibirFinalizarDialog(true)}
              fecharEditarPedido={() => fecharEditarPedido()}
              atualizarCamposInput={atualizarCamposInput}
              setAtualizar={setAtualizar}
            />
            <Divider style={{ margin: '1px' }} />
            <ListaRegistro
            criacao={false}
              idPedido={idPedido}
              filtroCancelado={filtroCancelado}
              setFiltroCancelado={setFiltroCancelado}
              setModalAberta={setModalAberta}
            />
            <Divider style={{ margin: '1px' }} />
            <Panel header={<h5>Dados do Cliente</h5>}>
              <Grid fluid style={{ gap: '10px' }}>
                <Row>
                  <Col md={8} sm={24} xs={24}>
                    <ResponsiveForm
                      label="Nome Fantasia"
                      name="nome_fantasia"
                      style={Input}
                      readOnly
                      value={camposInput.nome_fantasia || ''}
                    />
                  </Col>
                  <Col md={8} sm={24} xs={24}>
                    <ResponsiveForm
                      label="Email"
                      name="email"
                      style={Input}
                      readOnly
                      value={camposInput.email || ''}
                    />
                  </Col>
                  <Col md={8} sm={24} xs={24}>
                    <ResponsiveForm
                      label="Pessoa Responsável"
                      name="pessoa_responsavel"
                      style={Input}
                      readOnly
                      value={camposInput.pessoa_responsavel || ''}
                    />
                  </Col>
                </Row>
                <Row >
                  <Col md={8} sm={24} xs={24}>
                    <ResponsiveForm
                      label="Endereço"
                      name="endereco"
                      style={Input}
                      readOnly
                      value={camposInput.endereco || ''}
                    />
                  </Col>
                  <Col md={8} sm={24} xs={24}>
                    <ResponsiveForm
                      label="Telefone"
                      name="telefone"
                      style={Input}
                      readOnly
                      value={camposInput.telefone || ''}
                    />
                  </Col>
                  <Col md={8} sm={24} xs={24}>
                    <ResponsiveForm
                      label="CNPJ"
                      name="cpf_cnpj"
                      style={{ width: '200px' }}
                      readOnly
                      value={camposInput.cpf_cnpj || ''}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={24} sm={24} xs={24}>
                    <ResponsiveFormTextArea
                      label="Observação"
                      name="obs"
                      value={camposInput.obs || ''}
                      disabled
                    />
                  </Col>
                </Row>
              </Grid>
            </Panel>
          </Form>
          <Drawer.Actions
            style={{
              position: 'fixed',
              width: '90%',
              bottom: 2,
              zIndex: 999,
            }}
          >
            <FlexboxGrid justify="center">
              <FlexboxGrid.Item colspan={6}>
                <ButtonFullBlue
                  appearance="primary"
                  type="submit"
                  loading={carregando}
                  block
                  form="editarPedido"
                >
                  Salvar
                </ButtonFullBlue>
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </Drawer.Actions>
          {carregando && <Loader backdrop center />}
        </Drawer.Body>
      </Drawer>
      <FinalizarPedidoDialog
        exibir={exibirFinalizarDialog}
        onFechar={() => setExibirFinalizarDialog(false)}
        fecharEditarPedido={fecharEditarPedido}
      />
    </Container >
  );
}

EditarPedido.propTypes = {
  idPedido: PropTypes.func.isRequired,
  showEditarPedido: PropTypes.func.isRequired,
  codPedido: PropTypes.string.isRequired,
  setShowEditarPedido: PropTypes.func.isRequired,
  fecharEditarPedido: PropTypes.func.isRequired,
  setAtualizar: PropTypes.func.isRequired,
};

export default EditarPedido;
