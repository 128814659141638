/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  TagPicker,
  SelectPicker,
  Button,
  Toggle,
  Form,
  Grid,
  Row,
  Col,
  Modal,
} from 'rsuite';
import { toast } from 'react-toastify';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import { ResponsiveForm } from '~/components/ResponsiveForm';
import VisulizarImagem from '~/components/VisualizarImagem';
import InputMascara from '~/components/InputMascara';
import { mascaraCpfCnpj, mascaraTelefone } from '~/components/Mascaras';
import api from '~/services/api';
import { TogglesBloq } from '../../../relatorios/CarteiraClientes/styles';

import * as Styled from './styles';

const autoCorrectedDatePipe = createAutoCorrectedDatePipe('dd/mm/yyyy');

function CamposDadosProfissionalCriacao({
  camposInput,
  documentoAlterado,
  imagemAlterada,
  excluirPro,
}) {
  const [imagem, setImagem] = useState();
  const [proExcluirModal, setproExcluirModal] = useState(false);
  const [carregando, setCarregando] = useState(false);
  const [idDescobriuSwitch, setIdDescobriuSwitch] = useState(camposInput.id_descobriu_switch || null);

  const [dadosApi, setDadosApi] = useState({
    escolaridades: [],
    idiomas: [],
    tiposEstabelecimentos: [],
    comoDescobriuProf: [],
    genero: []
  });

  useEffect(() => {
    const buscarDados = async () => {
      const [escolaridade, idiomas, tiposEstabelecimentos, comoDescobriuProf, genero] = await Promise.all([
        api.get('escolaridade'),
        api.get('idioma'),
        api.get('tipo-estabelecimento'),
        api.get('descobriu-switch'),
        api.get('genero')
      ]);
      setDadosApi({
        escolaridades: escolaridade.data,
        idiomas: idiomas.data,
        tiposEstabelecimentos: tiposEstabelecimentos.data,
        comoDescobriuProf: comoDescobriuProf.data,
        genero: genero.data
      });
    };

    buscarDados();
  }, []);

  async function proExcluir() {
    const { id, nome } = camposInput;
    setCarregando(true);
    setproExcluirModal(false);
    try {
      await api.delete(`profissional/${id}`);
      excluirPro();
      setCarregando(false);
      toast.success(`Profissional ${nome} excluido(a) com sucesso.`);
    } catch (e) {
      setCarregando(false);
      toast.error(e.message);
    }
  }

  const [url, setUrl] = useState('');

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const data = await camposInput.foto_perfil.caminho;
  //     setUrl(data);
  //   };

  //   fetchData();
  // }, [camposInput]);

  return (
    <Styled.Panel
      header={<h4>Dados Profissional</h4>}
      collapsible
      bordered
      defaultExpanded
      style={{ overflow: 'auto', textAlignLast: 'start', margin: 'auto' }}
    >
      <Grid>
        <Row className="show-grid">
          <Col md={24} xs={24} sm={24}>
            <Col style={{ marginTop: '20px' }} md={8} xs={24} sm={24} >
              <Form.Group controlId="imageProf">
                <Form.ControlLabel>Imagem</Form.ControlLabel>
                <Form.Control
                  name="imagem_foto"
                  type="file"
                  style={{ width: '100%' }}
                  accept="image/*"
                  value={camposInput.imagem_foto || ''}
                  onChange={async (_, evento) => {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                      setImagem(e.target.result);
                    };
                    reader.readAsDataURL(evento.target.files[0]);
                    imagemAlterada(evento.target.files[0]);
                  }}
                />
              </Form.Group>

              <ResponsiveForm
                label="Nome"
                name="nome"
                value={camposInput.nome || ''}
              />
              <ResponsiveForm
                name="email"
                value={camposInput.email || ''}
                label="Email"
              />
              <ResponsiveForm
                label="Senha"
                name="senha"
                value={camposInput.senha || ''}
                type="password"
              />
              <ResponsiveForm
                label="Confirmar Senha"
                name="confirmarSenha"
                type="password"
                value={camposInput.confirmarSenha || ''}
              />
            </Col>
            <Col md={8} sm={24} xs={24} style={{ marginTop: '20px' }}>
              <ResponsiveForm
                label="Data Nascimento"
                name="data_nascimento"
                mask={[
                  /\d/,
                  /\d/,
                  '/',
                  /\d/,
                  /\d/,
                  '/',
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                pipe={autoCorrectedDatePipe}
                keepCharPositions
                accepter={InputMascara}
                value={camposInput.data_nascimento || ''}
              />
              <ResponsiveForm
                accepter={InputMascara}
                mask={mascaraCpfCnpj}
                label="Cpf/Cnpj"
                name="cpf_cnpj"
                value={camposInput.cpf_cnpj || ''}
              />
              <ResponsiveForm
                label="Telefone1"
                name="telefone1"
                value={camposInput.telefone1 || ''}
                mask={mascaraTelefone}
                accepter={InputMascara}
              />
              <ResponsiveForm
                label="Telefone2"
                name="telefone2"
                value={camposInput.telefone2 || ''}
                mask={mascaraTelefone}
                accepter={InputMascara}
              />
              <ResponsiveForm
                label="Escolaridade"
                name="escolaridade"
                accepter={SelectPicker}
                placeholder="Selecione"
                style={{ width: '100%' }}
                data={dadosApi.escolaridades.map((t) => {
                  return {
                    label: t.escolaridade,
                    value: t.id,
                  };
                })}
              />
            </Col>
            <Col md={8} sm={24} xs={24} style={{ marginTop: '20px' }}>
              <ResponsiveForm
                label="Como descobriu a Switch"
                name="id_descobriu_switch"
                accepter={SelectPicker}
                placeholder="Selecione"
                style={{ width: '100%' }}
                value={idDescobriuSwitch}
                onChange={(value) => setIdDescobriuSwitch(value)}
                data={dadosApi.comoDescobriuProf.map((t) => {
                  return {
                    label: t.descricao,
                    value: t.id,
                  };
                })}
              />
              {idDescobriuSwitch === 6 && (
                <ResponsiveForm
                  label="Como conheceu ?"
                  name="obs_outro"
                  value={camposInput.obs_outro || ''}
                />
              )}
              <ResponsiveForm
                label="Genero"
                name="genero"
                accepter={SelectPicker}
                placeholder="Selecione"
                style={{ width: '100%' }}
                data={dadosApi.genero.map((t) => {
                  return {
                    label: t.genero,
                    value: t.id,
                  };
                })}
              />
              <ResponsiveForm
                label='Idiomas'
                name="idiomas"
                accepter={TagPicker}
                placeholder="Selecione"
                style={{ width: '100%' }}
                // style={{ width: '300px' }}
                data={dadosApi.idiomas.map((i) => {
                  return {
                    label: i.idioma,
                    value: i.id,
                  };
                })}
              />
              <ResponsiveForm
                label="Preferência"
                name="tipo_estabelecimento"
                accepter={SelectPicker}
                placeholder="Selecione"
                style={{ width: '100%' }}
                data={dadosApi.tiposEstabelecimentos.map((t) => {
                  return {
                    label: t.tipo_estabelecimento,
                    value: t.id,
                  };
                })}
              />
              <Form.Group>
                <Form.ControlLabel>Documento</Form.ControlLabel>
                <Form.Control
                  name="documento_foto"
                  type="file"
                  style={{ width: '100%' }}
                  accept="image/*"
                  value={camposInput.documento_foto || ''}
                  onChange={async (_, evento) => {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                      setImagem(e.target.result);
                    };
                    reader.readAsDataURL(evento.target.files[0]);
                    documentoAlterado(evento.target.files[0]);
                  }}
                />
                <VisulizarImagem
                  titulo="Ver Documento"
                  imagem={imagem || camposInput.documento}
                />
              </Form.Group>
              {/* <Form.Group>
                <Button
                  color="red" appearance="primary"
                  onClick={() => {
                    //setproExcluir(rowData)
                    setproExcluirModal(true)
                  }}
                  style={{ marginTop: '0px' }}
                >
                  Excluir Profissional
                </Button>
              </Form.Group> */}
            </Col>
          </Col>
        </Row>
      </Grid>
      <Modal
        open={proExcluirModal}
        onClose={() => setproExcluirModal(false)}
        size="xs"
      >
        <Modal.Header>Excluir Profissional</Modal.Header>
        <Modal.Body>
          <p>Deseja realmente excluir este Profissional?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setproExcluirModal(false)}
            appearance="subtle"
          >
            Cancelar
          </Button>
          <Button
            appearance="primary"
            type="button"
            onClick={() => {
              setproExcluirModal(false)
              proExcluir()
            }}
          >
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </Styled.Panel>
  );
}

CamposDadosProfissionalCriacao.propTypes = {
  camposInput: PropTypes.object,
  documentoAlterado: PropTypes.func.isRequired,
  imagemAlterada: PropTypes.func.isRequired,
  excluirPro: PropTypes.func,
};

CamposDadosProfissionalCriacao.defaultProps = {
  camposInput: {},
  excluirPro: null,
};

export default CamposDadosProfissionalCriacao;