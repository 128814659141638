import React from 'react';
import PropTypes from 'prop-types';
import { RemindFill } from '@rsuite/icons';

import { Modal, Button } from 'rsuite';
// import { Container } from './styles';

function ExcluirRegistroDialog({ exibir, registroExcluir, fechar, onExcluir }) {
  return (
    <Modal open={exibir} onClose={() => fechar()} size="xs">
      <Modal.Header>
        <Modal.Title>Excluir Registro</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Você realmente deseja apagar este registro? <br /> <br />
        id: {registroExcluir.id || 'Não encontrado'} <br />
        profissao: {registroExcluir.profissao} <br />
        {registroExcluir.profissional && (
          <>
            profissional: {registroExcluir.profissional.nome}
            <br />
          </>
        )}
        Essa ação é irreversível{' '}
        <RemindFill
          style={{
            color: '#ffb300',
            fontSize: 24,
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => fechar()} appearance="subtle">
          Cancelar
        </Button>
        <Button onClick={() => onExcluir()} appearance="primary">
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ExcluirRegistroDialog.propTypes = {
  exibir: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  registroExcluir: PropTypes.object.isRequired,
  fechar: PropTypes.func.isRequired,
  onExcluir: PropTypes.func.isRequired,
};

export default ExcluirRegistroDialog;
