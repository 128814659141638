/* eslint-disable prettier/prettier */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Table } from 'rsuite';

import PropTypes from 'prop-types';

const { Cell } = Table;

export const fundo = (data, registro) => {
  let cor;
  if (data.status === 'Cancelado' && registro === true) {
    cor = '#ff1616';
  }else if (data.status === 'Faltou' && registro === true) {
    cor = '#8f0350';
  }else if (data.primeiro_pedido === true){
    cor = '#FFC757'
  }else {
    cor = ''
  }
  return cor;
};

export const fonte = (data, registro) => {
  let cor;
  if (data.status === 'Cancelado' && registro === true) {
    cor = '#fff';
  } else if (data.status === 'Faltou' && registro === true){
    cor = '#fff';
  }else if (data.primeiro_pedido === true){
    cor = '#000000'
  }else {
  
    cor = '';
  }
  return cor;
};


export const CelulaStatusFormatada = ({rowData, isRegistro, dataKey, ...props}) => (
  <Cell
  {...props}
  style={{
    background: fundo(rowData, isRegistro),
    color: fonte(rowData, isRegistro),
  }}
>
  {rowData[dataKey]}
</Cell>
)

CelulaStatusFormatada.propTypes = {
  rowData: PropTypes.object,
  isRegistro: PropTypes.bool,
  dataKey: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

CelulaStatusFormatada.defaultProps = {
  rowData: {},
  isRegistro: false,
  onChange: () => { },
};
