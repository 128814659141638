import styled from 'styled-components';
import { Sidebar, Sidenav } from 'rsuite';
import { Icon } from '@rsuite/icons';
import theme from '../../styles/theme';

export const ExpandirDiv = styled.div`
  flex-grow: 1;
  background: ${theme.primaryColor};
`;

export const MenuLateral = styled(Sidebar)`
  height: 100%;
  display: flex;
  flex-direction: column;
  color: #fff;
  z-index: 1000;

  i {
    color: #fff;
  }

  .rs-sidenav-nav > .rs-sidenav-item > .rs-icon:not(.rs-dropdown-toggle-caret),
  .rs-sidenav-nav
    > .rs-dropdown
    .rs-dropdown-toggle
    > .rs-icon:not(.rs-dropdown-toggle-caret) {
    margin-right: 20px;
  }

  .rs-navbar-nav > .rs-dropdown .rs-dropdown-toggle {
    padding-right: 0;
  }

  .rs-dropdown-header {
    color: ${theme.primaryColor};
  }

  .rs-dropdown-item {
    color: ${theme.primaryColor};
  }

  .rs-btn:focus {
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  & button {
    background-color: ${theme.primaryColor} !important;
  }
  .rs-sidenav-inverse .rs-dropdown .rs-dropdown-toggle:hover,
  .rs-sidenav-inverse .rs-dropdown-item:hover {
    background-color: ${theme.secondaryColor} !important;
  }
  & .rs-sidenav-inverse {
    background-color: ${theme.primaryColor} !important;
  }
  .rs-sidenav-inverse .rs-dropdown-item:focus {
    background-color: ${theme.secondaryColor} !important;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  .rs-dropdown-toggle .rs-btn .rs-btn-subtle {
    background: green !important;
  }
`;

export const Navegacao = styled(Sidenav)`
  a {
    color: ${(props) => (props.expanded ? '#fff' : '#575757')} !important;
    transition: color 1s;
    background-color: ${theme.primaryColor};
  }
`;

export const IconeConfiguracao = styled(Icon)`
  width: 56px;
  height: 56px;
  line-height: 56px;
  text-align: center;
`;
