/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell as CellPie, Legend } from 'recharts';
import PropTypes from 'prop-types';
import { renderActiveShape, colors, buscarDados } from './utils';

function GraficoRecharts({ id_estabelecimento }) {
  const [graficoMotivo, setGraficoMotivo] = useState([]);
  const [data01, setData01] = useState([]);

  useEffect(() => {
    const data = [];
    graficoMotivo.map((item) => {
      return data.push({ name: item.motivo, value: Number(item.quantidade) });
    });
    setData01(data);
  }, [graficoMotivo]);

  const [pieIndex, setPieIndex] = useState(0);
  const onPieEnter = (_, index) => {
    setPieIndex(index);
  };

  useEffect(() => {
    buscarDados(setGraficoMotivo, '/motivo-cancelamento-grafico', {
      id_estabelecimento,
    });
  }, [id_estabelecimento]);

  const style = {
    right: 0,
    transform: 'translate(0, -50%)',
    lineHeight: '15px',
    fontSize: '0.6rem',
  };

  return (
    <>
      <PieChart width={800} height={300} className="pieChartOverLabel">
        <Pie
          onMouseEnter={onPieEnter}
          data={data01}
          cx={300}
          cy={150}
          innerRadius={60}
          outerRadius={120}
          fill="#8884d8"
          paddingAngle={1}
          dataKey="value"
          activeIndex={pieIndex}
          activeShape={renderActiveShape}
        >
          {data01.map((entry, index) => (
            <CellPie
              key={`cell-${index}`}
              fill={colors[index % colors.length]}
            />
          ))}
        </Pie>
        <Legend
          iconSize={10}
          layout="centric"
          verticalAlign="middle"
          wrapperStyle={style}
        />
      </PieChart>
    </>
  );
}

GraficoRecharts.propTypes = {
  id_estabelecimento: PropTypes.func.isRequired,
};

export default GraficoRecharts;
