/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import NoticeIcon from '@rsuite/icons/Notice';
import { FaMapMarkedAlt } from 'react-icons/fa';
import { MdOutlineWrongLocation } from 'react-icons/md';
import theme from '~/styles/theme';
import { format, isAfter, isToday, parseISO } from 'date-fns';
// import { MdWrongLocation } from 'react-icons/md';
import { dadosRegistrosDia } from '~/store/modulos/pedido/actions';

import {
  Button,
  IconButton,
  Modal,
  Popover,
  Table,
  Toggle,
  Whisper,
} from 'rsuite';
import { toast } from 'react-toastify';
import { ButtonFullBlue } from '~/components/Buttons/ButtonFullBlue/styles';
import api from '~/services/api';
import { useDispatch } from 'react-redux';
import { fundo, texto } from './CelulaFormatada';
import { NotificationToggle } from '../../../Mensagens/EnviarNotificacaoProfissional/styles';

const { Cell } = Table;

const statusLocationDesabled = {
  backgroundColor: '#f00',
  color: 'white',
  marginLeft: '10px',
};
const statusLocationWaiting = {
  backgroundColor: '#ff0',
  marginLeft: '10px',
};

function CelulaAcao({ rowData, onCheckin, loading, getLocation, setLembreteEnviado, ...props }) {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  function onHide() {
    setShow(false);
  }

  const speaker = (
    <Popover title="Lembrete Check-in">
      <p>{rowData.nome} </p>
    </Popover>
  );

  async function enviarLembrete() {
    loading(true);
    await api
      .post('/notificacao/checkin', {
        data: {
          id: rowData.id_profissional,
          nome: rowData.nome,
          id_registro: rowData.id,
        },
      })
      .then((res) => {
        setLembreteEnviado(true)
        loading(false);
        toast.success(res.data);
      })
      .catch((err) => {
        loading(false);
        toast.error(err.message);
      });
  }

  if (rowData.status === 'Cancelado') {
    return (
      <Cell
        {...props}
        style={{
          background: fundo(rowData),
          color: texto(rowData),
        }}
      />
    );
  }

  return (
    <>
      <Cell
        {...props}
        style={{
          background: fundo(rowData),
          color: texto(rowData),
        }}
      >
        {onCheckin && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <NotificationToggle>
              <Toggle
                checked={rowData.checkinii}
                onChange={() => onCheckin(rowData)}
              />
            </NotificationToggle>
            {!rowData.checkinii && (
              <Whisper
                placement="left"
                trigger="hover"
                controlId="control-id-hover"
                speaker={speaker}
              >
                <IconButton
                  style={{ marginLeft: '10px', background: rowData.data_envio_lembrete && theme.darkGreen, color: rowData.data_envio_lembrete && 'white' }}
                  onClick={() => setShow(true)}
                  icon={<NoticeIcon />}
                  size="sm"
                />
              </Whisper>
            )}
            {!rowData.local || rowData.local.status !== 'Recebido' && !rowData.local.latitude && !rowData.local.longitude ? (
              <IconButton
                title={rowData.local.status && `${rowData.local.status}`}
                style={
                  // eslint-disable-next-line no-nested-ternary
                  rowData.local.status === 'Desativado'
                    ? {
                      ...statusLocationDesabled,
                      display: isToday(new Date(rowData.inicio)) ? 'flex' : 'none'
                    }
                    : rowData.local.status === 'Aguardando' && !rowData.local.latitude && !rowData.local.longitude
                      ? {
                        ...statusLocationWaiting,
                        display: isToday(new Date(rowData.inicio)) ? 'flex' : 'none'
                      }
                      : { marginLeft: '10px', display: isToday(new Date(rowData.inicio)) ? 'flex' : 'none' }
                }
                onClick={() => getLocation(rowData.id_profissional, rowData.id)}
                icon={<MdOutlineWrongLocation />}
                size="sm"
                alt="Texto"
              />
            ) : (
              <IconButton
                onClick={() => {
                  dispatch(dadosRegistrosDia(rowData));
                }}
                style={{
                  marginLeft: '10px',
                  // eslint-disable-next-line no-nested-ternary
                  backgroundColor: rowData.local.status === 'Aguardando' && rowData.local.latitude && rowData.local.longitude ? theme.yellowColor : rowData.local.status === 'Desativado' && rowData.local.latitude && rowData.local.longitude ? theme.darkRed : theme.darkGreen,
                  color: 'white',
                }}
                icon={<FaMapMarkedAlt />}
                size="sm"
                alt="Texto"
              />
            )}
          </div>
        )}
      </Cell>
      <Modal backdrop="static" open={show} onClose={onHide} size="xs">
        <Modal.Header>
          <h5>Enviar Lembrete</h5>
        </Modal.Header>
        <Modal.Body>
          Lembrar <strong>{rowData.nome}</strong>?
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={onHide} appearance="subtle">
            Cancelar
          </Button>
          <ButtonFullBlue
            onClick={async () => {
              setShow(false);
              loading(true);
              await enviarLembrete();
            }}
            appearance="primary"
          >
            Enviar
          </ButtonFullBlue>
        </Modal.Footer>
      </Modal>
    </>
  );
}

CelulaAcao.propTypes = {
  rowData: PropTypes.func,
  setLembreteEnviado: PropTypes.func,
  loading: PropTypes.func.isRequired,
  onBloquear: PropTypes.func,
  onCheckin: PropTypes.func,
  dataKey: PropTypes.string.isRequired,
  getLocation: PropTypes.func.isRequired
};

CelulaAcao.defaultProps = {
  rowData: {},
  setLembreteEnviado: null,
  onCheckin: null,
  onBloquear: null,
};

export default CelulaAcao;
