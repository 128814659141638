import React from 'react';
import { SelectPicker } from 'rsuite';
import PropTypes from 'prop-types';

const tiposCancelamentos = [
  {
    label: 'Profissional',
    value: 'profissional',
  },
  {
    label: 'Estabelecimento',
    value: 'estabelecimento',
  },
  {
    label: 'Admin',
    value: 'admin',
  },
  {
    label: 'Faltou',
    value: 'faltou',
  }
];

export function TCancelamento({
  cell = false,
  selected,
  setSelected,
  setFields,
  data,
  marginRight,
}) {



  return (
    <div>
      {!cell ? <p>Tipo Cancelamento</p> : ''}
      <SelectPicker
        placeholder="Tipo Cancelamentos"
        appearance="default"
        style={{ marginRight: marginRight ? '15px' : 0, width: 250 }}
        searchable={false}
        data={tiposCancelamentos}
        value={!cell ? selected : data.tipo_cancelamento}
        onChange={(value) => 
          {
            
            if(cell === false){
              setSelected(value);
              setFields((f) =>
              f.map((r) => ({
                ...r,
                tipo_cancelamento: value
                  
              }))
            )
            }else {
              setFields((f) =>
              f.map((r) => ({
                ...r,
                tipo_cancelamento:
                  r.id === data.id ? value : r.tipo_cancelamento,
                mcancell: r.id === data.id && value === 'faltou' ? null : r.mcancell,
              }))
            )}
            }
        }
      />
    </div>
  );
}

TCancelamento.defaultProps = {
  cell: false,
  selected: '',
  setSelected: () => {},
  data: {
    tipo_cancelamento: '',
  },
  setFields: () => {},
  marginRight: false,
};

TCancelamento.propTypes = {
  selected: PropTypes.string,
  setSelected: PropTypes.func,
  setFields: PropTypes.func,
  data: PropTypes.node,
  cell: PropTypes.bool,
  marginRight: PropTypes.bool,
};

export function TCancelamentoCell({ setFields, data, marginRight }) {
  return (
    <>
      <SelectPicker
        placeholder="Motivo Cancelamentos"
        appearance="default"
        style={{ marginRight: marginRight ? '15px' : 0 }}
        searchable={false}
        data={tiposCancelamentos}
        value={data.tcancell}
        onChange={(value) => {
          setFields((f) =>
            f.map((r) => ({
              ...r,
              tcancell: r.id === data.id ? value : r.tcancell,
            }))
          );
        }}
      />
    </>
  );
}

TCancelamentoCell.propTypes = {
  setFields: PropTypes.func.isRequired,
  data: PropTypes.node.isRequired,
  marginRight: PropTypes.bool,
};

TCancelamentoCell.defaultProps = {
  marginRight: false,
};
