import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Container } from '~/components/TablePagination/styles';
import { Linha, Tabela } from './styles';
// import { Container } from '../styles';
import { Pagination, Td, Th } from './Modules';
import { Iconchildren, TableChildrens } from './Childrens';

export function TableWithSub(props) {
  const {
    title,
    lista = [],
    quantidade = 0,
    setPagina,
    columns = [],
    childrens = [],
    paginationBarTop,
    paginationBarLow,
  } = props;
  const [items, setItems] = useState([]);

  const procurarLista = (obj, list) => {
    let resp = obj;
    list.map((el) => {
      Object.keys(resp).forEach((key) => {
        if (key === el) resp = resp[key];
      });
      return true;
    });
    return resp;
  };

  const exibirChildrens = (campo, id, icone, ex) => {
    const results = items.map((p) => {
      const resp = { ...p };
      if (p.id === id) {
        const list = procurarLista(p, childrens[campo].list);
        resp[`exibir${campo}`] = list ? ex : false;
        resp[`icone${campo}`] = icone;
      }
      return resp;
    });

    setItems(results);
  };

  const primeiraExibicao = () => {
    const newLista = lista.map((l) => {
      const resp = { ...l };
      childrens.map((c, index) => {
        const list = procurarLista(l, c.list);
        resp[`exibir${index}`] = false;
        resp[`icone${index}`] = !!list;
        return true;
      });
      return resp;
    });
    setItems(newLista);
  };

  useEffect(() => {
    primeiraExibicao();
  }, [lista]);

  return (
    <Container>
      {paginationBarTop && (
        <Pagination quantidade={quantidade} setPagina={setPagina} />
      )}
      <Tabela>
        {title && (
          <caption>
            <h4>{title}</h4>
          </caption>
        )}
        <thead className="thTableLogs">
          <tr>
            <Th title={childrens} />
            <Th title={columns} />
          </tr>
        </thead>
        <tbody>
          {items &&
            items.length > 0 &&
            items.map((log, index) => (
              <>
                <tr key={log.id} className="trTableLogs">
                  <Iconchildren
                    childrens={childrens}
                    log={log}
                    exibirChildrens={exibirChildrens}
                  />
                  <Td columns={columns} log={log} />
                </tr>
                {childrens &&
                  childrens.length > 0 &&
                  childrens.map(
                    (child, indice) =>
                      log[`exibir${indice}`] && (
                        <TableChildrens
                          childrens={child}
                          list={procurarLista(log, child.list)}
                          index={index}
                          log={log}
                        />
                      )
                  )}
              </>
            ))}
        </tbody>
      </Tabela>
      {paginationBarLow && (
        <Pagination quantidade={quantidade} setPagina={setPagina} />
      )}
    </Container>
  );
}

TableWithSub.defaultProps = {
  title: '',
  paginationBarTop: false,
  paginationBarLow: false,
};

TableWithSub.propTypes = {
  title: PropTypes.string,
  lista: PropTypes.arrayOf.isRequired,
  columns: PropTypes.arrayOf.isRequired,
  quantidade: PropTypes.number.isRequired,
  setPagina: PropTypes.func.isRequired,
  paginationBarTop: PropTypes.bool,
  paginationBarLow: PropTypes.bool,
  childrens: PropTypes.arrayOf.isRequired,
};
