/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import EditIcon from '@rsuite/icons/Edit';
import TrashIcon from '@rsuite/icons/Trash';

import { Table, IconButton, Divider, ButtonToolbar } from 'rsuite';
import {fundo, fonte} from './CelulaStatusFormatada';

const { Cell } = Table;


function CelulaAcao({ rowData, isRegistro, onDeletar, ...props }) {
   
  return (
    <Cell {...props} style={{ padding: '5px', background: fundo(rowData, isRegistro), color: fonte(rowData, isRegistro) }}>
      {onDeletar && (
        <IconButton
        style={{ padding: '5px', background: fundo(rowData), color: fonte(rowData)}}
          appearance="subtle"
          onClick={() => onDeletar(rowData)}
          color="red"
          icon={<TrashIcon />}
        />
      )}
    </Cell>
  );
}

CelulaAcao.propTypes = {
  // rowData: PropTypes.objectOf,
  onEditar: PropTypes.func,
  onDeletar: PropTypes.func,
  dataKey: PropTypes.string.isRequired,
};

CelulaAcao.defaultProps = {
  // rowData: {},
  onDeletar: null,
  onEditar: null,
};

export default CelulaAcao;
