/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import EditIcon from '@rsuite/icons/Edit';
import TrashIcon from '@rsuite/icons/Trash';

import { Table, IconButton, Divider, ButtonToolbar } from 'rsuite';
import NoticeIcon from '@rsuite/icons/Notice';
import CheckRoundIcon from '@rsuite/icons/CheckRound';
import {fundo, fonte} from './CelulaStatusFormatada';
import theme from '../../../../../styles/theme';

const { Cell } = Table;

function CelulaVisualizado({ rowData, criacao, setRegistro, setExibirAlteracoes, ...props }) {
  return (
    <Cell {...props} style={{
      background: fundo(rowData),
      color: fonte(rowData),
      display: 'flex',
      alignItems: 'center',
      marginTop: criacao ? '-15px' : '0px'
      }}>
        <IconButton
          disabled={!rowData.alteracoes}
          style={{
            color: '#fff',
            // backgroundColor: rowData.alteracoes
            //   ? theme.darkGreen
            //   : theme.lightGray,
            backgroundColor: 'transparent',
          }}
          onClick={() => {
            setRegistro(rowData);
            setExibirAlteracoes(true);
          }}
          color="red"
          // icon={<TrashIcon />}
          icon={rowData.registro.visualizado === false ? <NoticeIcon style={{ color: theme.darkGreen }} /> : <CheckRoundIcon style={{ color: theme.lightGray }} />}
        />
    </Cell>
  );
}

CelulaVisualizado.propTypes = {
  // rowData: PropTypes.objectOf,
  dataKey: PropTypes.string.isRequired,
};

CelulaVisualizado.defaultProps = {
  // rowData: {},
};

export default CelulaVisualizado;
