import React, { useState } from 'react';
import { AutoComplete } from 'rsuite';

import { useDebouncedCallback } from 'use-debounce/lib';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import api from '~/services/api';

function FiltroCidades({ onItemAdicionado, disabled, setFiltroCidades }) {
  const [cidades, setCidades] = useState([]);

  const buscarCidades = useDebouncedCallback(async (texto) => {
    if(texto === '') setFiltroCidades([])
    if (texto) {
      try {
        const resposta = await api.get('cidade', {
          params: {
            nome: texto,
          },
        });
        setCidades(resposta.data);
      } catch (error) {
        toast.error(error.message);
      }
    }
  }, 500).callback;

  return (
    <AutoComplete
      data={cidades
        .map((c) => ({
          label: c.cidade,
          value: c.cidade,
          id: c.id,
        }))
        .slice(0, 10)}
      onSelect={onItemAdicionado}
      onChange={buscarCidades}
      placeholder="Cidades"
      disabled={disabled}
    />
  );
}

FiltroCidades.propTypes = {
  onItemAdicionado: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  setFiltroCidades: PropTypes.func,
};

FiltroCidades.defaultProps = {
  disabled: false,
  setFiltroCidades: () => {},
};

export default FiltroCidades;
