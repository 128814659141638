import styled from 'styled-components';

export const ColContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
`
export const RowContainer = styled.div`
    display: flex;
    flex-direction: row;

`
