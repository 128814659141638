/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import { ResponsiveForm, ResponsiveFormNoNumber } from '~/components/ResponsiveForm';

import {
  Panel,
  Loader,
  Grid,
  Row,
  Col,
  Form,
  Checkbox
} from 'rsuite';


import InputMascara from '~/components/InputMascara';

import api from '~/services/api';

function CamposEndereco({ atualizarCampos, camposInput, filtroNumero, setFiltroNumero }) {
  const [erroCep, setErroCep] = useState();
  const [carregando, setCarregando] = useState(false);
  const [semNumero, setSemNumero] = useState('S/N')
  const numberRef = useRef('')

  async function buscarCep(cep) {
    setCarregando(true);
    try {
      const resultado = await api.get(`/cep/${cep}`);
      const { endereco } = resultado.data;
      atualizarCampos({
        cep,
        logradouro: endereco.logradouro,
        complemento: endereco.complemento,
        referencia: endereco.referencia,
        bairro: endereco.bairro.bairro,
        cidade: endereco.bairro.cidade.cidade,
        estado: endereco.bairro.cidade.estado.estado,
        uf: endereco.bairro.cidade.estado.uf,
      });
      setErroCep();
    } catch (error) {
      setErroCep('Erro ao buscar dados do CEP');
    }
    setCarregando(false);
  }
 
  function mostrarSemNumero(valor){
      if(valor === true) {
        camposInput.numero = '0'
      }
      if(valor === false) {
        // eslint-disable-next-line no-self-assign
        camposInput.numero = ''
      }
  }

  return (

    <Panel
      header={
        <div style={{ display: 'flex' }}>
          <h4>Endereço</h4> {carregando && <Loader size="md" />}
        </div>
      }
      bordered
      collapsible
      defaultExpanded
    >
      <Grid fluid style={{ gap: '10px' }}>
        <Row className="show-grid" style={{ textAlignLast: 'start' }}>
          <Col xs={24} sm={24} md={8}>
            <ResponsiveForm
              label='Cep'
              name="cep"
              value={camposInput.cep || ''}
              mask={[/[0-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
              accepter={InputMascara}
              onChange={(texto) => texto.length === 9 && buscarCep(texto)}
              errorMessage={erroCep}
            />
            <ResponsiveForm
              label='Logradouro'
              name="logradouro"
              value={camposInput.logradouro || ''}
              disabled={carregando}
            />
            
             
              <ResponsiveFormNoNumber
                name="numero"
                label='Número'
                formValor={filtroNumero ? semNumero : camposInput.numero}
                disabled={carregando || filtroNumero}
                filtroNumero={filtroNumero}
                setFiltroNumero={setFiltroNumero}
                // eslint-disable-next-line react/jsx-no-bind
                alteraValorInput={mostrarSemNumero}
              />
          </Col>
             
              
              
             
          <Col xs={24} sm={24} md={8}>
            <ResponsiveForm
              label='Complemento'
              name="complemento"
              value={camposInput.complemento || ''}
              disabled={carregando}
            />
            <ResponsiveForm
              label='Ponto de Referência'
              name="referencia"
              value={camposInput.referencia || ''}
              disabled={carregando}
            />
            <ResponsiveForm
              label='Bairro'
              name="bairro"
              value={camposInput.bairro || ''}
              disabled
            />

          </Col>
          <Col xs={24} sm={24} md={8}>
            <ResponsiveForm
              label='Cidade'
              name="cidade"
              value={camposInput.cidade || ''}
              disabled
            />
            <ResponsiveForm
              label='Estado'
              name="estado"
              value={camposInput.estado || ''}
              disabled
            />
            <ResponsiveForm
              label='Uf' name="uf" value={camposInput.uf || ''} disabled />

          </Col>
        </Row>
      </Grid>
    </Panel >
  );
}

CamposEndereco.propTypes = {
  atualizarCampos: PropTypes.func.isRequired,
  camposInput: PropTypes.object,
  filtroNumero: PropTypes.bool,
  setFiltroNumero: PropTypes.func
};

export default CamposEndereco;
