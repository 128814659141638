import styled from "styled-components";
import theme from "~/styles/theme";


export const Card = styled.div`
    display: flex;
    flex-direction: column;
    border: 1px solid ${theme.darkGray};
    width: 280px;
    height: 500px;
    border-bottom-left-radius: 4vh;
    border-bottom-right-radius: 4vh;
    margin-left: ${(props) => props.leftMargin || '0px'};
    overflow-y: scroll;
    

`

export const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    gap: 15px;

    `

export const ScrollContainer = styled.div`
    display: flex;
    overflow-x: scroll;

`

export const ColContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: ${(props) => props.leftMargin || '0px'};
    gap: ${(props) => props.gap || '0px'}
`

export const Header = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid ${theme.darkGray};
    border-top-left-radius: 4vh;
    border-top-right-radius: 4vh;
    background-color: ${(props) => props.color || 'black'};
    width: 100%;
    height: 50px;
    overflow: hidden;
    
    

    h6 {
        color: ${(props) => props.textColor || 'white'};
        font-size: 14px;
        margin-left: 8px;
    }
`

export const InfoDiv = styled.div`
    display: flex;
    padding: 6px;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid ${theme.darkGray};
    flex-direction: row;
    height: 80px;

`

export const TextDiv = styled.div`
    display: flex;
    white-space: nowrap;
    align-items: ${(props) => props.alignItems};
    flex-direction: column;
    justify-content: ${(props) => props.justify};
    margin-left: ${(props) => props.marginLeft};




`


