/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react'
import { toast } from 'react-toastify';
import {
  Modal,
  AutoComplete,
  Form,
  Button,
  CheckPicker
} from 'rsuite';
import { ButtonBorderBlue } from '~/components/Buttons/ButtonBorderBlue/styles';
import api from '~/services/api';
import useSearchNames from '~/services/useSearchNames';
import formatCurrency from '../../../utils/formatCurrency';



const GerarFaturaStark = ({ atualizarTabela }) => {

  const [exibir, setExibir] = useState(false);
  const [nomesEstabelecimentos, setNomesEstabelecimentos] = useState([]);
  const [itemselecionado, setItemselecionado] = useState([]);
  const [pedidoSelecionado, setPedidoSelecionado] = useState([]);
  const [numPedido, setNumPedido] = useState([]);
  const [filtroValor, setFiltroValor] = useState(0)
  const [cpnj, setCnpj] = useState('')
  const [filtroEstabelecimento, setFiltroEstabelecimento] = useState();
  const buscarNomesEstabelecimentos = useSearchNames(
    setNomesEstabelecimentos,
    'estabelecimentos'
  );

  const resposta = async (id) => {
    await api.get(`pedidos/cliente/faturas/${id}`).then((response) => {
      setNumPedido(response.data);
    });
  };

  const cancelSubmit = () => {
    setFiltroValor(0);
    setExibir(false);
    setPedidoSelecionado([]);
    setNumPedido([]);
  };

  async function enviaForm() {
    try {

      const enviaFormulario = await api.post('/pagamentos/postFatura', {
        valor: Number((filtroValor * 100).toFixed(0)),
        cliente: filtroEstabelecimento,
        items: itemselecionado,
        id_pedido: pedidoSelecionado.map((item) => item.id),
        numeroPedido: pedidoSelecionado.map((item) => item.numero_pedido),
      })

      cancelSubmit()
      atualizarTabela()
      toast.success('Fatura gerada com sucesso.')
      // setCarregando(false);
    } catch (err) {
      cancelSubmit()
      toast.error(err.message)
      // toast.error('Erro ao gerar a fatura')
      // setCarregando(false);
    }

  }

  return (
    <>
      <ButtonBorderBlue appearance="ghost" onClick={() => setExibir(true)}>
        Gerar Fatura
      </ButtonBorderBlue>
      <Modal
        open={exibir}
        onClose={() => {
          setExibir(false);
          cancelSubmit()
        }}
        size="sm"
      >

        <Form>
          <Modal.Header>
            <Modal.Title>Gerar Fatura</Modal.Title>
          </Modal.Header>
          <Modal.Body >
            <Form.Group>
              <Form.ControlLabel>Cliente</Form.ControlLabel>
              <AutoComplete
                name="nome_estabelecimento"
                style={{ marginRight: '10px' }}
                data={nomesEstabelecimentos.map((p) => ({
                  label: p.nome_fantasia,
                  value: p.nome_fantasia,
                  id: p.id,
                }))}
                placeholder="Nome do Cliente"
                onSelect={async (value, item) => {
                  setFiltroEstabelecimento(item.id)
                  resposta(item.id)
                }}
                onChange={buscarNomesEstabelecimentos}
              />
            </Form.Group>

            <Form.Group>
              <Form.ControlLabel
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                N. Pedido
              </Form.ControlLabel>
              <CheckPicker
                style={{ marginRight: '15px', width: '450px' }}
                appearance="default"
                placeholder="Pedido"
                virtualized
                data={numPedido.map((p) => ({
                  label: p.label,
                  value: {
                    valor: p.valor,
                    id: p.id,
                    label: p.label,
                    numero_pedido: p.numero_pedido,
                  },
                }))}
                value={pedidoSelecionado}
                onChange={(value) => {
                  setPedidoSelecionado(value);
                  if (value.length > 0) {
                    setFiltroValor(
                      value
                        .map((item) => item.valor)
                        .reduce((acc, atual) => acc + atual)
                    );
                  } else {
                    setFiltroValor(0);
                  }
                  setItemselecionado(
                    value.map((item) => ({
                      text: item.label,
                      amount: Number((item.valor * 100).toFixed(0)),
                    }))
                  );
                }}
              />
            </Form.Group>
            <Form.Group style={{ marginTop: 10 }}>
              <Form.ControlLabel>Valor</Form.ControlLabel>
              <Form.Control
                name="Valor"
                placeholder="Valor"
                // onChange={setFiltroValor}
                accepter={AutoComplete}
                value={formatCurrency(filtroValor)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              appearance="subtle"
              onClick={() => cancelSubmit()}
            >
              Cancelar
            </Button>
            <Button
              appearance="primary"
              type="submit"
              onClick={async () => enviaForm()}
            >
              Salvar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

export default GerarFaturaStark
