import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Form, Button, Loader, SelectPicker, Schema } from 'rsuite';

import { Container } from '~/styles/tabela';

import api from '~/services/api';

const { StringType } = Schema.Types;

const modelo = Schema.Model({
  status: StringType().isRequired('Status Obrigatorio'),
});

function CriarPedido() {
  const [camposInput, setCamposInput] = useState();
  const [carregando, setCarregando] = useState(false);
  const [arquivo, setArquivo] = useState();

  return (
    <Container>
      <Form
        model={modelo}
        formValue={camposInput}
        onChange={setCamposInput}
        onSubmit={async () => {
          try {
            setCarregando(true);
            const formData = new FormData();
            formData.append('arquivo', arquivo);
            const resposta = await api.post(
              `importar-pedido/${camposInput.status}`,
              formData
            );
            toast.success(resposta.data.message);
          } catch (e) {
            toast.error(e.message);
          }
          setCarregando(false);
        }}
      >
        <h2>Importar Pedidos (CSV)</h2>
        <Form.Group>
          <Form.ControlLabel>Arquivo</Form.ControlLabel>
          <Form.Control
            name="arquivo"
            type="file"
            accept=".csv"
            onChange={async (_, evento) => {
              setArquivo(evento.target.files[0]);
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Status</Form.ControlLabel>
          <Form.Control
            name="status"
            accepter={SelectPicker}
            data={[
              { value: 'finalizado', label: 'Finalizado' },
              { value: 'confirmado', label: 'Confirmado' },
            ]}
            placeholder="Selecione"
            cleanable={false}
            searchable={false}
          />
        </Form.Group>
        <Button appearance="primary" type="submit" size="lg">
          Salvar
        </Button>
      </Form>
      {carregando && <Loader backdrop size="lg" />}
    </Container>
  );
}

export default CriarPedido;
