/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Trash } from '@rsuite/icons';
import { Table, IconButton } from 'rsuite';

const { Cell } = Table;

function CelulaAcao({ rowData, onClick, ...props }) {
  return (
    <Cell {...props} style={{ padding: '5px' }}>
      <IconButton
        appearance="subtle"
        onClick={() => onClick(rowData)}
        color="red"
        icon={<Trash />}
      />
    </Cell>
  );
}

CelulaAcao.propTypes = {
  rowData: PropTypes.object,
  onDeletar: PropTypes.func,
  onClick: PropTypes.func.isRequired,
  dataKey: PropTypes.string.isRequired,
};

CelulaAcao.defaultProps = {
  rowData: {},
  onDeletar: null,
};

export default CelulaAcao;
