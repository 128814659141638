/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Form, Schema, Loader, Drawer, FlexboxGrid, Col, Row, RadioGroup, Radio, Panel } from 'rsuite';
import PropTypes from 'prop-types';

import api from '~/services/api';
import { ButtonFullBlue } from '../../../components/Buttons/ButtonFullBlue/styles';

import { Container } from './styles';

import CamposDadosEstabelecimento from '../components/CamposDadosEstabelecimento';
import CamposEndereco from '../components/CamposEndereco';
import CamposDadosFuncionamento from '../components/CamposDadosFuncionamento';
import ListaManuais from '../components/ListaManuais';
import CampoObservacoesEComentarios from '../components/CampoObservacoesEComentarios';

const { StringType, NumberType, ArrayType } = Schema.Types;

const modelo = Schema.Model({
  nome_fantasia: StringType().isRequired('Nome Fantasia obrigatório'),
  pessoa_responsavel: StringType().isRequired('Pessoa responsável obrigatório'),
  email: StringType().isEmail('Email inválido').isRequired('Email obrigatório'),
  desconto: NumberType()
    .min(0, 'desconto deve ser positivo')
    .max(100, 'valor máximo é de 100'),
  telefone: StringType()
    .minLength(14, 'Telefone inválido')
    .isRequired('Telefone obrigatório'),
  // foto_perfil: StringType().isRequired('Foto perfil obrigatória'),
  logradouro: StringType().isRequired('Logradouro obrigatório'),
  cep: StringType().isRequired('Cep obrigatorio'),
  numero: NumberType('Número inválido'),
  complemento: StringType(),
  referencia: StringType(),
  bairro: StringType().isRequired('Bairro obrigatório'),
  cidade: StringType().isRequired('Cidade obrigatório'),
  estado: StringType().isRequired('Estado obrigatório'),
  uf: StringType().isRequired('Uf obrigatório'),
  tipo_estabelecimento: NumberType().isRequired('Preferência obrigatória'),
  dias_funcionamento: ArrayType().of(NumberType()),
  periodos_funcionamento: ArrayType().of(NumberType()),
  profissoes: ArrayType().of(NumberType()),
  obs: StringType(),
});

function EditarEstabelecimento({
  showEditarEstabelecimento,
  setShowEditarEstabelecimento,
  idEstabelecimento,
  fecharEditarEstabelecimento,
}) {
  const [carregando, setCarregando] = useState(false);
  const [filtroRadioRefeicao, setFiltroRadioRefeicao] = useState('');
  const [camposInput, setCamposInput] = useState({
    profissoes: [],
  });
  const [logo, setLogo] = useState();
  const [qtdColaborador, setQtdColaborador] = useState('');
  const [cardapio, setCardapio] = useState();
  const [dadosFuncionamento, setDadosFuncionamento] = useState({
    periodoFuncionamento: [],
    diasFuncionamento: [],
    tiposEstabelecimentos: [],
    profissoes: [],
    habilidades: [],
  })

  let erro = {};

  useEffect(() => {
    const buscarEstabelecimento = async () => {
      setCarregando(true);
      try {
        const resposta = await api.get(`estabelecimento/${idEstabelecimento}`);
        const campos = resposta.data;

        console.log(resposta.data)

        campos.desconto = (resposta.data.desconto * 100).toFixed(2);
        campos.logradouro = campos.endereco.logradouro;
        campos.cep = campos.endereco.cep;
        campos.numero = campos.endereco.numero;
        campos.id_descobriu_switch = campos.descobriu_switch.length > 0 ? campos.descobriu_switch[0].id : 0
        campos.complemento = campos.endereco.complemento || '';
        campos.referencia = campos.endereco.referencia || '';
        campos.bairro = campos.endereco.bairro.bairro;
        campos.cidade = campos.endereco.bairro.cidade.cidade;
        campos.estado = campos.endereco.bairro.cidade.estado.estado;
        campos.uf = campos.endereco.bairro.cidade.estado.uf;
        campos.tipo_estabelecimento = campos.tipo_estabelecimento[0].id;
        campos.dias_funcionamento = campos.dias_funcionamento.map((d) => d.id);
        campos.periodos_funcionamento = campos.periodos_funcionamento.map(
          (p) => p.id
        );
        const [profissoesResposta, enderecosResposta] =
          await Promise.all([
            api.get(`estabelecimento/profissoes/${idEstabelecimento}`),
            api.get(`endereco/all/${idEstabelecimento}`),
          ]);

          const habilidadesFormatada = profissoesResposta.data.profissoes.map((servico) => {
            return campos.customerSkills.filter((habilidades) => {

              return habilidades.id_profissao === servico.id
            } ).map((item) => {
              return {
                id: item.id,
                habilidade: item.habilidade,
                id_profissao: item.id_profissao,
                profissao: {
                    id: item.id_profissao,
                    profissao: item.profi
                }
            }
            })
          })

          // const newHabilidades = campos.customerSkills.map((item) => {
          //   return {
          //     id: item.id,
          //     habilidade: item.habilidade,
          //     id_profissao: item.id_profissao,
          //     profissao: {
          //         id: item.id_profissao,
          //         profissao: item.profi
          //     }
          // }
          // })

        campos.profissoes = profissoesResposta.data.profissoes;
        campos.habilidades = habilidadesFormatada;
        campos.profissoes = campos.profissoes.map((p) => p.id);
        // campos.habilidades.forEach((habilidade) => {
        //   campos[habilidade.profissao.id] = campos.habilidades
        //     .filter((h) => h.profissao.id === habilidade.profissao.id)
        //     .map((h) => h.id);
        // });
        campos.enderecos = enderecosResposta.data.enderecos;
        setCamposInput(campos);
        setFiltroRadioRefeicao(campos.refeicao)

        setDadosFuncionamento({
          periodoFuncionamento: campos.periodos_funcionamento.map((p) => p.id),
          diasFuncionamento: campos.dias_funcionamento.map((d) => d.id),
          tiposEstabelecimentos: campos.tipo_estabelecimento,
          profissoes: profissoesResposta.data.profissoes,
          habilidades: habilidadesFormatada,
        })


      } catch (e) {
        console.log(e, 'error')
        toast.error('Erro ao carregar dados do cliente');
      }
      setCarregando(false);
    };
    return idEstabelecimento && buscarEstabelecimento();
  }, [idEstabelecimento, showEditarEstabelecimento]);

  function exibirMensagemErro() {
    Object.values(erro).forEach((e) => {
      toast.error(e);
    });
  }

  // function adicionarVerificaoHabilidades(profissoesLista) {
  //   profissoesLista
  //     .filter((p) => p.profissao !== 'Outros')
  //     .forEach((p) => {
  //       modelo.schema[p.id] = ArrayType()
  //         .of(NumberType())
  //         .isRequired(`${p.profissao} habilidade obrigatória`);
  //     });
  // }

  async function salvarUsuario() {
    const habilidadesFormatadas = []
    dadosFuncionamento.habilidades.map((servico) => {
      return servico && servico.map((hab) => habilidadesFormatadas.push({
        id_profissao: hab.profissao.id,
        habilidade: hab.habilidade
      }))
    })
    setCarregando(true);

    try {
      if (logo) {
        const formData = new FormData();
        formData.append('logo', logo);
        await api.post(`estabelecimento/logo/${idEstabelecimento}`, formData);
      }
      if (cardapio) {
        const formData = new FormData();
        formData.append('cardapio', cardapio);
        await api.post(
          `estabelecimento/cardapio/${idEstabelecimento}`,
          formData
        );
      }
      const body = {
        nome_fantasia: camposInput.nome_fantasia,
        pessoa_responsavel: camposInput.pessoa_responsavel,
        razao_social: '',
        email: camposInput.email,
        senha: camposInput.senha,
        cpf_cnpj: camposInput.cpf_cnpj,
        telefone: camposInput.telefone,
        logradouro: camposInput.logradouro,
        desconto: camposInput.desconto,
        perfil_bloqueado: camposInput.perfil_bloqueado,
        status: camposInput.status,
        tarifa_feriado: camposInput.tarifa_feriado,
        refeicao: filtroRadioRefeicao,
        endereco: {
          id: camposInput.endereco.id,
          logradouro: camposInput.logradouro,
          cep: camposInput.cep,
          numero: camposInput.numero,
          complemento: camposInput.complemento,
          referencia: camposInput.referencia,
          bairro: {
            id: camposInput.endereco.bairro.id,
            nome: camposInput.bairro,
            cidade: {
              id: camposInput.endereco.bairro.cidade.id,
              nome: camposInput.cidade,
              estado: {
                id: camposInput.endereco.bairro.cidade.estado.id,
                uf: camposInput.uf,
                nome: camposInput.estado,
              },
            },
          },
        },
        tipo_estabelecimento: [camposInput.tipo_estabelecimento],
        dias_funcionamento: camposInput.dias_funcionamento,
        periodos_funcionamento: camposInput.periodos_funcionamento,
        id_descobriu_switch: camposInput.id_descobriu_switch,
        qtd_funcionarios: qtdColaborador !== '' ? qtdColaborador : camposInput.qtd_funcionarios,
        profissoes: camposInput.profissoes,
        customerSkills: habilidadesFormatadas,
        habilidades_outro: [],
        obs: camposInput.obs,
      };
      await api.put(`estabelecimento/${idEstabelecimento}`, body);
      toast.success('Cliente atualizado com sucesso');     
      fecharEditarEstabelecimento();
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
  }

  async function recuperarSenha() {
    setCarregando(true);
    try {
      await api.post(
        `/recuperar-senha/estabelecimento?email=${camposInput.email}`
      );
      toast.success('Email enviado com sucesso');
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
  }

  async function novaSenha({ password, passwordConfirmation }) {
    setCarregando(true);
    try {
      const newPassword = {
        id: camposInput.id,
        password,
        passwordConfirmation,
      }
      await api.put(`/new-password/estabelecimento/adm`, newPassword);
      toast.success('Senha alterada com sucesso');
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
  }

  async function buscarManuais() {
    setCarregando(true);
    try {
      const resposta = await api.get(
        `estabelecimento/manual/${idEstabelecimento}`
      );
      setCamposInput({ ...camposInput, manuais: resposta.data });
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
  }

  const marginPersonalizada = { marginTop: '10px' }

  return (
    <Container>
      <Drawer
        full
        open={showEditarEstabelecimento}
        onClose={() => setShowEditarEstabelecimento(false)}
      >
        <Drawer.Header>
          <Drawer.Title>Editar Estabelecimento</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <Form
            model={modelo}
            onChange={setCamposInput}
            formValue={camposInput}
            onSubmit={(semErro) =>
              semErro ? salvarUsuario() : exibirMensagemErro()
            }
            onError={(err) => {
              erro = err;
            }}
            layout="horizontal"
            id="editarEstabelecimento"
          >
            <CamposDadosEstabelecimento
              marginPersonalizada={marginPersonalizada}
              editar
              qtdColaborador={qtdColaborador}
              setQtdColaborador={setQtdColaborador}
              camposInput={camposInput}
              logoAlterada={setLogo}
              cardapioAlterado={setCardapio}
              novaSenha={novaSenha}
              // eslint-disable-next-line react/jsx-no-bind
              recuperarSenha={recuperarSenha}
            />
            <CamposEndereco
              camposInput={camposInput}
              atualizarCampos={(camposEndereco) => {
                setCamposInput({ ...camposInput, ...camposEndereco });
              }}
            />
            <Row>
              <Col md={12} sm={24} xs={24}>
                <ListaManuais
                  idEstabelecimento={idEstabelecimento}
                  manuais={camposInput.manuais || []}
                  // eslint-disable-next-line react/jsx-no-bind
                  onListaAlterada={buscarManuais}
                />
              </Col>
              <Col md={12} sm={24} xs={24}>
                <Panel
                  header={<h4>Refeição</h4>}
                  eventKey="123"
                  bordered
                  collapsible
                  defaultExpanded
                >
                  <RadioGroup
                    onChange={(value) => {
                      setFiltroRadioRefeicao(value);
                    }}
                    value={filtroRadioRefeicao}
                    inline
                  // defaultValue="nao_informado"
                  >
                    <Radio style={{ marginRight: '-10px', fontSize: '12px' }} value="sim">Sim</Radio>
                    <Radio style={{ fontSize: '12px' }} value="nao">Não</Radio>
                    <Radio style={{ fontSize: '12px' }} value="nao_informado">Não informado</Radio>
                  </RadioGroup>
                </Panel>
              </Col>
            </Row>

            <CamposDadosFuncionamento
            dadosFuncionamento={dadosFuncionamento}
            setDadosFuncionamento={setDadosFuncionamento}
              // profissoesId={camposInput.profissoes}
              // eslint-disable-next-line react/jsx-no-bind
              // profissoesAlteradas={adicionarVerificaoHabilidades}
            />
            <CampoObservacoesEComentarios
              camposInput={camposInput}
              atualizarCampos={(camposObs) => {
                setCamposInput({ ...camposInput, ...camposObs });
              }}
            />
          </Form>
        </Drawer.Body>
        <Drawer.Actions
          style={{
            position: 'fixed',
            width: '90%',
            bottom: 2,
            zIndex: 999,
          }}
        >
          <FlexboxGrid justify="center">
            <FlexboxGrid.Item colspan={6}>
              <ButtonFullBlue
                appearance="primary"
                type="submit"
                size="lg"
                loading={carregando}
                block
                form="editarEstabelecimento"
              >
                Salvar
              </ButtonFullBlue>
            </FlexboxGrid.Item>
          </FlexboxGrid>
          {carregando && <Loader backdrop center />}
        </Drawer.Actions>
      </Drawer>
    </Container>
  );
}

EditarEstabelecimento.propTypes = {
  showEditarEstabelecimento: PropTypes.func.isRequired,
  setShowEditarEstabelecimento: PropTypes.func.isRequired,
  idEstabelecimento: PropTypes.string.isRequired,
  fecharEditarEstabelecimento: PropTypes.func.isRequired,
};

export default EditarEstabelecimento;
