/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { Edit, Plus } from '@rsuite/icons';
import api from '~/services/api';
import TrashIcon from '@rsuite/icons/Trash';
import { toast } from 'react-toastify';
import {
  Panel,
  Loader,
  FlexboxGrid,
  Col,
  Form,
  Popover,
  Button,
  Toggle,
  IconButton,
  Modal,
} from 'rsuite';
import { ResponsiveForm } from '~/components/ResponsiveForm';
import { TogglesFav } from '../../../relatorios/CarteiraClientes/styles';
import AdicionarEndereco from './components/AdicionarEndereco';

function CamposEndereco({ atualizarCampos, camposInput }) {
  const [erroCep, setErroCep] = useState();
  const [enderecos, setEnderecos] = useState(camposInput.enderecos);
  const [carregando, setCarregando] = useState(false);
  const [adicionarEnderecoDialog, setAdicionarEnderecoDialog] = useState(false);
  const [editarEnderecoDialog, setEditarEnderecoDialog] = useState(false);
  const [enderecoSelecionado, setEnderecoSelecionado] = useState({});
  const [exibirModalEndCancelar, setExibirModalEndCancelar] = useState(false);
  const [idEndereco, setIdEndereco] = useState();

  useEffect(() => {
    setEnderecos(camposInput.enderecos);
  }, [camposInput.enderecos]);

  async function adicionarEndereco(camposEnd) {
    setAdicionarEnderecoDialog(false);
    try {
      camposEnd.id_estabelecimento = camposInput.id;
      const update = await api.post(`endereco`, { endereco: camposEnd });
      const resultado = await api.get(`endereco/all/${camposInput.id}`);
      setEnderecos(resultado.data.enderecos);
      toast.success('Novo endereço adicionado com sucesso.');
    } catch (e) {
      toast.error(e.message);
    }
  }


  async function editarEndereco(camposEnd) {
    setEditarEnderecoDialog(false);
    try {
      camposEnd.id_estabelecimento = camposInput.id;
      await api.put(`endereco`, { endereco: camposEnd });
      const resultado = await api.get(`endereco/all/${camposInput.id}`);
      setEnderecos(resultado.data.enderecos);
      toast.success('Endereço atualizado com sucesso.');
    } catch (e) {
      toast.error(e.message);
    }
  }

  async function AlterarEndPrincipal(end) {
    setCarregando(true);
    try {
      const newEnd = {
        id_endereco: end.id,
        id_estabelecimento: camposInput.id,
      };
      await api.put(`/endereco/padrao`, newEnd);
      const resultado = await api.get(`endereco/all/${camposInput.id}`);
      setEnderecos(resultado.data.enderecos);
      toast.success('Endereço principal atualizado com sucesso');
    } catch (error) {
      toast.error(error);
      setErroCep('Erro ao atualizar endereço principal CEP');
    }
    setCarregando(false);
  }

  async function excluirEndereco() {
    setCarregando(true);
    const end = idEndereco;
    setExibirModalEndCancelar(false);
    try {
      const result = await api.delete(`/endereco/${end.id}`);
      const resultado = await api.get(`endereco/all/${camposInput.id}`);
      setEnderecos(resultado.data.enderecos);
      toast.success(`${result.data.result}`);
    } catch (error) {
      toast.error('Não é possível excluir endereço principal');
    }
    setCarregando(false);
  }

  const RenderEndereco = (end) => {
    const result = end.map((e) => {
      return (
        <Col xs={24} sm={24} md={8}>
          <FlexboxGrid.Item
            componentClass={Col}
            colspan={8}
            style={{ padding: '0 5px' }}
          >
            <Form.Group>
              <div
                style={{ height: 290, position: 'static', maginbottom: '15px' }}
              >
                <Popover title="Endereço" visible style={{ width: 350 }}>
                  <p>
                    Logradouro: {e.logradouro}, {e.numero === '0' || e.numero === 0 ? 'S/N' :` Nº ${e.numero}`}{' '}
                  </p>
                  <p> Complemento: {e.complemento} </p>
                  <p> Bairro: {e.bairro.bairro} </p>
                  <p>
                    {' '}
                    Cidade: {e.bairro.cidade.cidade} | Estado:{' '}
                    {e.bairro.cidade.estado.estado}. {e.bairro.cidade.estado.uf}{' '}
                  </p>
                  <hr />
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'right',
                      marginTop: '-30px',
                    }}
                  >
                    <p style={{ marginTop: '25px', marginRight: '10px' }}>
                      End Principal:{' '}
                    </p>
                    <TogglesFav
                      style={{
                        display: 'flex',
                        width: '50px',
                        marginBottom: '5px',
                      }}
                    >
                      <ResponsiveForm
                        name="tarifa_feriado"
                        checked={e.principalEndereco}
                        accepter={Toggle}
                        onChange={() => AlterarEndPrincipal(e)}
                      />
                    </TogglesFav>
                    <IconButton
                      title="Editar"
                      appearance="subtle"
                      onClick={() => {
                        setEditarEnderecoDialog(true);
                        setEnderecoSelecionado(e);
                      }}
                      icon={<Edit />}
                    />
                    <IconButton
                      title="Deletar"
                      appearance="subtle"
                      onClick={() => {
                        setIdEndereco(e);
                        setExibirModalEndCancelar(true);
                      }}
                      icon={<TrashIcon />}
                    />
                  </div>
                </Popover>
              </div>
            </Form.Group>
          </FlexboxGrid.Item>
          <Modal
            open={exibirModalEndCancelar}
            onClose={() => setExibirModalEndCancelar(false)}
            size="xs"
          >
            <Modal.Header>Excluir endereço</Modal.Header>
            <Modal.Body>
              <p>Deseja realmente excluir o endereço?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => setExibirModalEndCancelar(false)}
                appearance="subtle"
              >
                Cancelar
              </Button>
              <Button
                appearance="primary"
                type="button"
                onClick={() => {
                  excluirEndereco();
                }}
              >
                Confirmar
              </Button>
            </Modal.Footer>
          </Modal>
        </Col>
      );
    });
    return result;
  };

  return (
    <Panel
      header={
        <div style={{ display: 'flex' }}>
          <h4>Endereço </h4> {carregando && <Loader size="lg" />}
        </div>
      }
      bordered
      collapsible
      defaultExpanded
    >
      <div
        style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}
      >
        <h6>Adicionar Endereço </h6>
        <IconButton
          style={{ marginTop: '0' }}
          icon={<Plus />}
          onClick={() => {
            setAdicionarEnderecoDialog(true);
          }}
        />
      </div>
      {enderecos && RenderEndereco(enderecos)}

      <AdicionarEndereco
        exibir={adicionarEnderecoDialog}
        fechar={() => setAdicionarEnderecoDialog(false)}
        salvar={adicionarEndereco}
      />
      <AdicionarEndereco
        exibir={editarEnderecoDialog}
        fechar={() => setEditarEnderecoDialog(false)}
        salvar={editarEndereco}
        newEndereco={enderecoSelecionado}
      />
    </Panel>
  );
}

CamposEndereco.propTypes = {
  atualizarCampos: PropTypes.func.isRequired,
  camposInput: PropTypes.object,
};

export default CamposEndereco;
