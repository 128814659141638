import React, { useState } from 'react';
import { Col, Row, SelectPicker, Grid } from 'rsuite';
import PropTypes from 'prop-types';
import { GraphLineChart } from '../GraphLineChart';

function GraficoVendas({ route, titulo }) {
  const [filtroGrafico, setFiltroGrafico] = useState('Administrador');

  return (
    <Grid fluid>
      <Row justify="start" style={{ marginBottom: '20px' }}>
        <SelectPicker
          cleanable={false}
          searchable={false}
          defaultValue={filtroGrafico}
          data={[
            {
              label: 'Profissional',
              value: 'Profissional',
            },
            {
              label: 'Empresa',
              value: 'Estabelecimento',
            },
            {
              label: 'Administrador',
              value: 'Administrador',
            },
          ]}
          style={{ width: 224 }}
          onSelect={(value) => setFiltroGrafico(value)}
        />
      </Row>
      <Row>
        <Col xs={24}>
          <GraphLineChart
            route={route}
            titulo={titulo}
            filtroGrafico={filtroGrafico}
          />
        </Col>
      </Row>
    </Grid>
  );
}

GraficoVendas.propTypes = {
  route: PropTypes.func.isRequired,
  titulo: PropTypes.func.isRequired,
};

export default GraficoVendas;
