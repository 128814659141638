/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import { Plus } from '@rsuite/icons';

import { Table, FlexboxGrid, Schema } from 'rsuite';
import { toast } from 'react-toastify';

import { Container } from '~/styles/tabela';

import api from '~/services/api';
import {
  CelulaEditavel,
  CelulaAcao,
} from '~/components/Celulas/components/CelulaEditavel';
import { ModalIcon } from '~/components/ModalCriacao/ModalIcon';
import { Modal1 } from '~/components/ModalCriacao/Modal1';
import { Modal2 } from '~/components/ModalCriacao/Modal2';
import { TabelaSemPaginacao } from '~/components/TabelaSemPaginacao';
import SearchTable from '../components/SearchTable';

const { HeaderCell, Column } = Table;

const { StringType } = Schema.Types;

const modelo = Schema.Model({
  periodo: StringType().isRequired('Período Obrigatório'),
});

function DiaFuncionamento() {
  const [dados, setDados] = useState({
    listaIncial: [],
    periodoFuncionamentoLista: [],
    periodoFuncionamentoListaFiltrada: [],
    filtro: '',
  });
  const [carregando, setCarregando] = useState(true);
  const [apagarDiaDialog, setApagarDiaDialog] = useState({
    exibir: false,
    periodo: {},
  });
  const [
    adicionarPeriodoFuncionamentoDialog,
    setAdicionarPeriodoFuncionamentoDialog,
  ] = useState(false);
  const [camposInput, setCamposInput] = useState({});

  async function buscarPeriodosFuncionamento() {
    const resposta = await api.get('periodo-funcionamento');
    const resposta1 = await api.get('periodo-funcionamento');
    const lista = resposta.data;
    setDados({
      listaIncial: resposta1.data,
      periodoFuncionamentoLista: lista,
      periodoFuncionamentoListaFiltrada: lista,
    });
    setCarregando(false);
  }

  useEffect(() => {
    buscarPeriodosFuncionamento();
  }, []);

  function filtrarLista(texto) {
    setDados({
      ...dados,
      periodoFuncionamentoListaFiltrada: dados.periodoFuncionamentoLista.filter(
        (periodo) =>
          periodo.periodo
            .toString()
            .toUpperCase()
            .includes(texto ? texto.toUpperCase() : '')
      ),
      filtro: texto,
    });
  }

  function abrirApagarDialog(periodo) {
    setApagarDiaDialog({
      exibir: true,
      periodo,
    });
  }

  function fecharApagarDialog() {
    setApagarDiaDialog({
      exibir: false,
      periodo: {},
    });
  }

  async function excluirPeriodoFuncionamento() {
    try {
      await api.delete(`/periodo-funcionamento/${apagarDiaDialog.periodo.id}`);
      toast.success('Dia Funcionamento excluido com sucesso');
      fecharApagarDialog();
      buscarPeriodosFuncionamento();
    } catch (e) {
      toast.error(e.message);
    }
  }

  async function adicionarPeriodoFuncionamento() {
    try {
      setCarregando(true);
      await api.post(`/periodo-funcionamento`, camposInput);
      toast.success('Dia funcionamento criado com sucesso');
      setCarregando(false);
      setAdicionarPeriodoFuncionamentoDialog(false);
      buscarPeriodosFuncionamento();
    } catch (e) {
      toast.error(e.message);
    }
  }

  function alterarTextoCelula(id, key, value) {
    const novaLista = Object.assign([], dados.periodoFuncionamentoLista);
    novaLista.find((item) => item.id === id)[key] = value;
    setDados({ ...dados, periodoFuncionamentoLista: novaLista });
    filtrarLista(dados.filtro);
  }

  async function alterarEstadoCelula(id) {
    const novaLista = Object.assign([], dados.periodoFuncionamentoLista);
    const item = novaLista.find((periodo) => periodo.id === id);
    const itemInicial = dados.listaIncial.find((periodo) => periodo.id === id);
    if (item.status === 'EDIT' && item.periodo !== itemInicial.periodo) {
      try {
        setCarregando(true);
        await api.put(`periodo-funcionamento/${id}`, item);
        toast.success('Dia funcionamento atualizado com sucesso');
        buscarPeriodosFuncionamento();
      } catch (error) {
        toast.error(error.message);
      }
      setCarregando(false);
    } else {
      item.status = item.status ? null : 'EDIT';
      setDados({ ...dados, periodoFuncionamentoLista: novaLista });
      filtrarLista(dados.filtro);
    }
  }

  return (
    <Container>
      <h2>Período de Funcionamento</h2>
      <FlexboxGrid justify="start" style={{ gap: '10px' }}>
        <SearchTable estilo={{ width: '300px' }} filtrarLista={filtrarLista} />
        <ModalIcon
          style={{ marginTop: '0' }}
          icon={<Plus />}
          onClick={() => {
            setAdicionarPeriodoFuncionamentoDialog(true);
          }}
        />
      </FlexboxGrid>
      <FlexboxGrid style={{ width: '100%' }}>
        <TabelaSemPaginacao
          carregando={carregando}
          dados={dados.periodoFuncionamentoListaFiltrada}
        >
          <Column resizable width={240} sortable>
            <HeaderCell>Dia de Funcionamento</HeaderCell>
            <CelulaEditavel dataKey="periodo" onChange={alterarTextoCelula} />
          </Column>

          <Column resizable width={200}>
            <HeaderCell>Editar</HeaderCell>
            <CelulaAcao
              dataKey="id"
              onClick={alterarEstadoCelula}
              onDelete={abrirApagarDialog}
            />
          </Column>
        </TabelaSemPaginacao>
      </FlexboxGrid>

      <Modal1
        show={adicionarPeriodoFuncionamentoDialog}
        onHide={() => setAdicionarPeriodoFuncionamentoDialog(false)}
        size="xs"
        title="Adicionar Período de Funcionamento"
        label="Período de Funcionamento"
        name="periodo"
        model={modelo}
        onChange={setCamposInput}
        onSubmit={(status) => status && adicionarPeriodoFuncionamento()}
        onClick={() => setAdicionarPeriodoFuncionamentoDialog(false)}
      />

      <Modal2
        show={apagarDiaDialog.exibir}
        onHide={fecharApagarDialog}
        title="Apagar Periodo Funcionamento"
        text="Você realmente deseja apagar este periodo de funcionamento?"
        apagarId={apagarDiaDialog.periodo.id}
        apagarDado={apagarDiaDialog.periodo.periodo}
        fecharApagarDialog={fecharApagarDialog}
        excluir={() => excluirPeriodoFuncionamento()}
      />
    </Container>
  );
}

export default DiaFuncionamento;
