import React from 'react';
import { Button, Modal } from 'rsuite';

import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import theme from '../../../../styles/theme';
import { minutesToHourTime } from '../../../../utils/minutesToHour';

export default function ListaAlteracoesDialog({
  registro,
  exibir,
  setExibir,
  onFechar,
}) {
  function converterTimeZoneParaLocal(data) {
    // const date = new Date(data);
    return `${format(parseISO(data), 'dd/LL/yyyy')} ${format(parseISO(data), 'HH:mm')}`
  }
  return (
    <Modal open={exibir} onClose={onFechar} size="md">
      <Modal.Header>
        <Modal.Title>Alterações {`${registro.numero_registro}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {registro.alteracoes &&
            registro.alteracoes.map((reg) => {
              return (
                <>
                  <b
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    {reg.profissional ? 'Profissional' : 'Estabelecimento'}
                  </b>
                  <div
                    style={{
                      marginBottom: 20,
                      padding: 10,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'center',
                      }}
                    >
                      <p>Hora Inicio:</p>
                      <p>
                        {reg.profissional || reg.estabelecimento
                          ? format(parseISO(reg.hora_inicio), 'HH:mm')
                          : '-'}
                      </p>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'center',
                      }}
                    >
                      <p>Hora Fim:</p>
                      <p>
                        {reg.profissional || reg.estabelecimento
                          ? format(parseISO(reg.hora_fim), 'HH:mm')
                          : '-'}
                      </p>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'center',
                      }}
                    >
                      <p>Intervalo:</p>
                      <p>
                        {reg.profissional || reg.estabelecimento
                          ? minutesToHourTime(reg.intervalo)
                          : '-'}
                      </p>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'center',
                      }}
                    >
                      <p>Falta:</p>
                      <p
                        style={{
                          background: reg.falta && theme.darkRed,
                          color: reg.falta && '#fff',
                          padding: reg.falta && 10,
                        }}
                      >
                        {(reg.profissional || reg.estabelecimento) && reg.falta
                          ? 'Faltou'
                          : '-'}
                      </p>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'center',
                      }}
                    >
                      <p>Alteração:</p>
                      <p>
                        {reg.created_at ? converterTimeZoneParaLocal(reg.created_at) : '-'}
                      </p>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button appearance="subtle" onClick={() => setExibir(false)}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ListaAlteracoesDialog.propTypes = {
  registro: PropTypes.func.isRequired,
  exibir: PropTypes.func.isRequired,
  setExibir: PropTypes.string.isRequired,
  onFechar: PropTypes.func.isRequired,
};
