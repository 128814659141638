/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-return-assign */
import React, { useRef, useState, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Form, Button, Modal, DatePicker, AutoComplete } from 'rsuite';
import PropTypes from 'prop-types';
import InputMascara from '~/components/InputMascara';

import api from '~/services/api';
import { ButtonFullBlue } from '~/components/Buttons/ButtonFullBlue/styles';
import useSearchNames from '~/services/useSearchNames';

// const { NumberType } = Schema.Types;

// const modelo = Schema.Model({
//   id_profissao: NumberType().isRequired('Profissao Obrigatória'),
// });

function NovaEscala({ exibir, fechar, salvar, newEscala }) {
  const [camposInput, setCamposInput] = useState({});
  const [carregando, setCarregando] = useState(false);
  const [nomesEstabelecimentos, setNomesEstabelecimentos] = useState([]);
  const [filtroEstabelecimento, setFiltroEstabelecimento] = useState();

  const editorRef = useRef(null);

  const locale = {
    sunday: 'Dom',
    monday: 'Seg',
    tuesday: 'Ter',
    wednesday: 'Qua',
    thursday: 'Qui',
    friday: 'Sex',
    saturday: 'Sab',
    ok: 'Ok',
    today: 'Hoje',
    yesterday: 'Ontem',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos',
  };
  const range = [
    {
      label: 'Hoje',
      value: new Date(),
    },
  ];

  const buscarNomesEstabelecimentos = useSearchNames(
    setNomesEstabelecimentos,
    'estabelecimentos',
    setFiltroEstabelecimento
  );
  useEffect(() => {
    if (newEscala?.id_estabelecimento > 0) {
      setCamposInput({
        ...camposInput,
        id: newEscala.id,
        id_estabelecimento: newEscala.id_estabelecimento,
        escala: newEscala.escala,
        observacao: newEscala.observacao,
        ultimo_lancamento: new Date(newEscala.ultimo_lancamento),
        ultimo_dia_lancado: new Date(newEscala.ultimo_dia_lancado),
      });
    }
  }, [newEscala]);



  async function salvarConta() {
    setCarregando(true);
    await salvar(camposInput);
    setCamposInput({});
    setCarregando(false);
  }

  return (
    <Modal open={exibir} onClose={() => fechar()} size="xs">
      <Form
        fluid
        // model={modelo}
        onChange={setCamposInput}
        formDefaultValue={camposInput}
        onSubmit={() => salvarConta()}
      >
        <Modal.Header>
          <Modal.Title>Adicionar ou Editar Escala Fixa</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.ControlLabel>Estabelecimento:</Form.ControlLabel>
            <AutoComplete
              name="nome_estabelecimento"
              style={{ width: '220px' }}
              data={nomesEstabelecimentos.map((p) => ({
                label: p.nome_fantasia,
                value: p.nome_fantasia,
                id: p.id,
              }))}
              placeholder="Nome do Cliente"
              onSelect={async (value, item) => {
                setCamposInput({
                  ...camposInput,
                  id_estabelecimento: item.id,
                })
              }}
              onChange={buscarNomesEstabelecimentos}
            />
          </Form.Group>
          <Form.Group>
             <Form.ControlLabel>Escala:</Form.ControlLabel>
            <Editor
          onInit={(evt, editor) => (editorRef.current = editor)}
          apiKey="pv8hnike17xcj19zp9782tyg170swsns0wxumltslkzn2j8d"
          init={{
            menubar: 'custom',
            plugins: ['emoticons', 'link'],
            language: 'pt_BR',
            language_url: '/tiny_lang/pt_BR.js',
            toolbar:
              'emoticons | bold italic underline strikethrough | fontsizeselect | link',
            fontsize_formats: '16px 22px 26px',
            content_style: `
              body {text-align: start;}
             `,
            preview_styles: 'background-color',
            required: true,
          }}
          value={camposInput.escala}
          onEditorChange={(value) =>
            setCamposInput({
              ...camposInput,
              escala: value,
            })
          }
        />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>OBS:</Form.ControlLabel>
            <Form.Control
              name="observacao"
              value={camposInput.observacao || ''}
              onChange={(value) =>
                setCamposInput({
                  ...camposInput,
                  observacao: value,
                })
              }
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>Data do lançamento:</Form.ControlLabel>
            <DatePicker
              placeholder="DD/MM/AA"
              name="ultimo_lancamento"
              format="dd/MM/yy"
              cleanable={false}
              accepter={DatePicker}
              oneTap
              locale={locale}
              value={camposInput.ultimo_lancamento && camposInput.ultimo_lancamento}
              ranges={range}
              onChange={(data) =>
                setCamposInput({ ...camposInput, ultimo_lancamento: data })
              }
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>Data do ultimo lançamento:</Form.ControlLabel>


            <DatePicker
              placeholder="DD/MM/AA"
              name="ultimo_dia_lancado"
              format="dd/MM/yy"
              cleanable={false}
              accepter={DatePicker}
              oneTap
              locale={locale}
              value={camposInput.ultimo_dia_lancado && camposInput.ultimo_dia_lancado}
              ranges={[range]}
              onChange={(data) =>
                setCamposInput({ ...camposInput, ultimo_dia_lancado: data })
              }
            />
          </Form.Group>

        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => fechar()} appearance="subtle">
            Cancelar
          </Button>
          <ButtonFullBlue
            type="submit"
            loading={carregando}
            appearance="primary"
          >
            Confirmar
          </ButtonFullBlue>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

NovaEscala.propTypes = {
  exibir: PropTypes.bool.isRequired,
  fechar: PropTypes.func.isRequired,
  salvar: PropTypes.func.isRequired,
  newEscala: PropTypes.func.isRequired,
};

export default NovaEscala;
