/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button, FlexboxGrid, Table, Toggle, SelectPicker } from 'rsuite';

import { useDispatch, useSelector } from 'react-redux';
import { Container } from '~/styles/tabela';
import { parseISO } from 'date-fns';

import SearchInputTable from '~/components/SearchInputTable';
import { InputDataRange } from '~/components/InputDataRange';
import { TablePagination } from '~/components/TablePagination';
import { toast } from 'react-toastify';
import { CelulaNotifcacao } from '~/components/CelulaNotificacao';
import CelulaAcao from '~/components/Celulas/components/CelulaAcao';
import moment from 'moment';
import EditarPedido from '../EditarPedido';
import { TogglesStatus } from './styles';
import api from '../../../services/api';

const { HeaderCell, Column, Cell } = Table;

function AlteracaoPedido() {
  const [pagina, setPagina] = useState(1);
  const [data, setData] = useState([]);
  const [textodigitado, setTextoDigitado] = useState('');
  const [pedido, setPedido] = useState('');
  const [registro, setRegistro] = useState('');
  const [pedidoEditando, setPedidoEditando] = useState();
  const [showEditarPedido, setShowEditarPedido] = useState(false);
  const [carregando, setCarregando] = useState(true);
  const [ajusteHorario, setAjusteHorario] = useState({
    lista: [],
    quantidade: 0,
  });
  const [filtrarStatus, setFiltrarStatus] = useState();
  const ajusteDeHorarioNaoLidos = useSelector(
    (state) => state.notificacao.ajusteDeHorarioNaoLidos
  );

  async function atualizarDadosPagina() {
    setCarregando(true);
    try {
      const resposta = await api.get('ajustar-horario', {
        params: {
          status: filtrarStatus,
          pagina,
          pedido,
          registro,
          dataInicio: data.length > 0 ? data[0] : undefined,
          dataFim: data.length > 0 ? data[1] : undefined,
        },
      });
      const lista = resposta.data.lista.map((ped) => ({
        ...ped,
        numero_pedido: ped.id_pedido,
        numero_registro: `Ajuste de horário para o registro R-${ped.id_registro}`,
        data: moment(ped.created_at).format('DD/MM/YYYY | HH:mm'),
        status: ped.status,
      }));
      setAjusteHorario({
        lista,
        quantidade: resposta.data.quantidade,
      });
    } catch (error) {
      toast.error(error.message);
    }
    setCarregando(false);
  }

  useEffect(() => {
    if(ajusteHorario.quantidade > 0 && ajusteHorario.lista.length === 0) setPagina(1)
  }, [ajusteHorario.quantidade]);

  useEffect(() => {
    atualizarDadosPagina();
  }, [pagina, pedido, registro, data, ajusteDeHorarioNaoLidos, filtrarStatus]);

  async function alteracaoStatus(e, value) {
    if (value === true) {
      atualizarDadosPagina();
      return;
    }
    try {
      const statusValue = {
        id: e.id,
        status: !value,
      };
      setCarregando(true);
      await api.patch(`ajustar-horario`, statusValue);
      setCarregando(false);
      atualizarDadosPagina();
      toast.success('Status atualizado com sucesso');
    } catch (error) {
      toast.error(error.message);
    }
  }

  const CelulaAcaoStatus = ({ rowData, ...props }) => (
    <Cell {...props}>
      <TogglesStatus>
        <Toggle
          checkedChildren="Resolvido"
          unCheckedChildren="Pendente"
          defaultChecked={rowData.status}
          onChange={() => alteracaoStatus(rowData, rowData.status)}
        />
      </TogglesStatus>
    </Cell>
  );

  function abrirEditarPedido(ped) {
    setPedidoEditando(ped);
    setShowEditarPedido(true);
  }

  function fecharEditarPedido() {
    setShowEditarPedido(false);
  }

  return (
    <Container>
      <h2>Ajuste de Horário</h2>
      <FlexboxGrid align="middle" style={{ gap: '10px' }}>
        <SearchInputTable
          setTextoDigitado={setPedido}
          place="Pesquisar por Pedido"
          estilo={{ width: '220px' }}
        />
        <SearchInputTable
          setTextoDigitado={setRegistro}
          place="Pesquisar por Registro"
          estilo={{ width: '220px' }}
        />
        <InputDataRange
          ranges={[]}
          value={data}
          onChange={setData}
          onClean={() => setData([null, null])}
        />
        <SelectPicker
          name="status"
          value={filtrarStatus}
          searchable={false}
          placeholder="Status"
          data={[
            { label: 'Pendente', value: 'false' },
            { label: 'Resolvido', value: 'true' },
          ]}
          onChange={setFiltrarStatus}
        />
      </FlexboxGrid>
      <FlexboxGrid style={{ width: '100%' }}>
        <TablePagination
          carregando={carregando}
          dados={ajusteHorario.lista}
          quantidade={ajusteHorario.quantidade}
          onChangePage={setPagina}
        >
          <Column resizable width={85}>
            <HeaderCell>N° Pedido</HeaderCell>
            <Cell dataKey="numero_pedido" />
          </Column>
          <Column resizable width={325}>
            <HeaderCell>N° Registro</HeaderCell>
            <Cell dataKey="numero_registro" />
          </Column>
          <Column resizable width={175}>
            <HeaderCell>Estabelecimento</HeaderCell>
            <Cell dataKey="nome_fantasia" />
          </Column>
          <Column resizable width={170}>
            <HeaderCell>Função</HeaderCell>
            <Cell dataKey="profissao" />
          </Column>
          <Column resizable width={110}>
            <HeaderCell>Data Serviço</HeaderCell>
            <Cell dataKey="data_servico" />
          </Column>
          <Column resizable width={170}>
            <HeaderCell>Data</HeaderCell>
            <Cell dataKey="data" />
          </Column>
          <Column resizable width={120}>
            <HeaderCell>Status</HeaderCell>
            <CelulaAcaoStatus dataKey="status" />
          </Column>
          <Column resizable width={60}>
            <HeaderCell>Editar</HeaderCell>
            <CelulaAcao
              dataKey="id_registro"
              onEditar={(ped) => {
                abrirEditarPedido(ped);
              }}
            />
          </Column>
        </TablePagination>
      </FlexboxGrid>
      <EditarPedido
        idPedido={pedidoEditando && pedidoEditando.id_pedido}
        showEditarPedido={showEditarPedido}
        codPedido={pedidoEditando && pedidoEditando.id_pedido}
        setShowEditarPedido={setShowEditarPedido}
        fecharEditarPedido={fecharEditarPedido}
      />
    </Container>
  );
}

export default AlteracaoPedido;
