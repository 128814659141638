import React, { useRef, useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { Button, ButtonToolbar, IconButton } from 'rsuite';
import ContextMenu from '~/components/ContextMenu/ContextMenu';
import { useDrag, useDrop } from 'react-dnd';
import { FaUserCircle } from "react-icons/fa";
import { Container, InfoDiv, TextDiv } from './styles'
import PipeContext from '../../ListaPipeline/context'
import { ExcluirRecrutamento } from '../modal/ExcluirRecrutamento';
import { DarkModeContext } from '../../../../common/contexts/darkMode';



const CardPipe = ({ data, index, listIndex }) => {

    const { toggleMode } = useContext(DarkModeContext)

    const contextRef = useRef(null);
    const { selecionaItem, abrirEditarPedido, PipelineRecrutamento } = useContext(PipeContext);
    const [exluirRecrutamento, setExluirRecrutamento] = useState({});
    const [modalExcluir, setModalExcluir] = useState(false);
    const [contextMenu, setContextMenu] = useState({
        position: {
            x: 0,
            y: 0
        },
        toggled: false
    })

    const [atualizarLista, setAtualizarLista] = useState(false);



    const [{ isDragging }, dragRef] = useDrag(() => ({
        type: 'CardPipe',
        // eslint-disable-next-line no-sequences
        item: () => selecionaItem(data, index, listIndex),
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    }))

    function handleOnContextMenu(e, rightClick) {
        e.preventDefault();

        const contextMenuAttr = contextRef.current.getBoundingClientRect()

        const isLeft = e.clientX < window.innerWidth / 2

        let x = e.clientX
        const y = e.clientY

        if (isLeft) {
            x = e.clientX
        } else {
            x = e.clientX - contextMenuAttr.width
        }

        setContextMenu({
            position: {
                x,
                y
            },
            toggled: true
        })
    }

    function resetContextMenu() {
        setContextMenu({
            position: {
                x: 0,
                y: 0
            },
            toggled: false
        })
    }

    useEffect(() => {

        function handler(e) {
            if (contextRef.current) {
                if (!contextRef.current.contains(e.target)) {
                    resetContextMenu()
                }
            }

            console.log('entrei aqui o')
        }

        document.addEventListener('click', handler)

        return () => {
            document.removeEventListener('click', handler)
        }
    }, [])

    function handleTest() {
        console.log(contextRef.current)
    }

    return (

        <>

            <div ref={dragRef} onContextMenu={(e) => handleOnContextMenu(e, data.id)}>
                <InfoDiv isDragging={isDragging}>
                    <TextDiv justify='center' background='red' isDragging={isDragging}>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', textAlign: 'center'}}>
                        <p style={{ fontSize: 14, fontWeight: 'bold', color: toggleMode ? 'white' : 'black' }}>{data.profissao}</p>
                        <p style={{ textAlign: 'end' }}>{data.data_solicitacao}</p>
                        </div>
                        
                        {isDragging ? '' :
                            <ButtonToolbar>
                                <IconButton appearance="subtle" icon={<FaUserCircle style={{color: 'gray', marginRight: 6, width: 22, height: 20}}/>} style={{ color: '#0A59FF', width: '270px', display: 'flex', justifyContent: 'start', paddingLeft: '0px', fontWeight: 500, fontSize: 13 }} onClick={async () => abrirEditarPedido(data)}>{data.nome_fantasia}
                                </IconButton>
                            </ButtonToolbar>
                        }
                        
                        <p style={{ textAlign: 'end' }}>{data.bairro}, {data.cidade}</p>
                    </TextDiv>
                </InfoDiv>

                <ExcluirRecrutamento
                    modalExcluir={modalExcluir}
                    setModalExcluir={setModalExcluir}
                    exluirRecrutamento={exluirRecrutamento}
                    setAtualizarLista={setAtualizarLista}
                    buscarRecrutamento={PipelineRecrutamento}
                />


            </div>

            <ContextMenu
                contextMenuRef={contextRef}
                isToggled={contextMenu.toggled}
                positionX={contextMenu.position.x}
                positionY={contextMenu.position.y}
                buttons={[
                    {
                        text: "Excluir Recrutamento",
                        // eslint-disable-next-line no-alert
                        onClick: () => {
                            setExluirRecrutamento(data)
                            setModalExcluir(true)
                            resetContextMenu()

                        }
                    },
                    {
                        text: "Fechar",
                        // eslint-disable-next-line no-alert
                        onClick: () => resetContextMenu()
                    }
                ]}
            />

        </>




    )
}

CardPipe.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    listIndex: PropTypes.number.isRequired


};



export default CardPipe
