/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Plus } from '@rsuite/icons';
import EditIcon from '@rsuite/icons/Edit';
import TrashIcon from '@rsuite/icons/Trash';

import { IconButton, Panel } from 'rsuite';
import AdicionarExperiencia from '../AdicionarExperiencia';

import { Experiencia } from './styles';

function ListaExperiencias({ experiencias, onListaAlterada, children, setExperiencias }) {
  const [adicionarExperienciaDialog, setAdicionarExperienciaDialog] =
    useState(false);
  const [editarExperiencia, setEditarExperiencia] = useState();

  // function adicionarExperiencia(experiencia) {
  //   if (experiencia) {
  //     if (editarExperiencia !== undefined) {
  //       const index = experiencias.indexOf(editarExperiencia);
  //       const novasExperiencias = [...experiencias];
  //       novasExperiencias[index] = experiencia;
  //       onListaAlterada(novasExperiencias);
  //     } else {
  //       onListaAlterada([...experiencias, experiencia]);
  //     }
  //   }
  //   setAdicionarExperienciaDialog(false);
  //   setEditarExperiencia(undefined);
  // }
  function adicionarExperiencia(experiencia) {

    if (experiencia) {
      if (editarExperiencia !== undefined) {
        const index = experiencias.indexOf(editarExperiencia);
        const novasExperiencias = [...experiencias];
        novasExperiencias[index] = experiencia;
        setExperiencias(novasExperiencias);
      } else {
        setExperiencias([...experiencias, experiencia]);
      }
    }
    setAdicionarExperienciaDialog(false);
    setEditarExperiencia(undefined);
  }

  function removerExperiencia(experiencia) {
    let novaLista;
    if (experiencia.id !== undefined) {
      novaLista = experiencias.filter((exp) => exp.id !== experiencia.id);
    } else {
      novaLista = experiencias.filter(
        (exp) => exp.estabelecimento !== experiencia.estabelecimento
      );
    }
    setExperiencias(novaLista);
  }

  return (
    <Panel
      header={<h4>Experiências</h4>}
      eventKey="123"
      bordered
      collapsible
      defaultExpanded
    >
      <div
        style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}
      >
        <h6>Adicionar Experiencia</h6>
        <IconButton
          style={{ marginTop: '0' }}
          icon={<Plus />}
          onClick={(e) => {
            e.preventDefault();
            setAdicionarExperienciaDialog(true);
          }}
        />
      </div>
      {experiencias &&
        experiencias.map((experiencia) => (
          <Experiencia key={experiencia.id || experiencia.estabelecimento}>
            <div>
              <h5>Cliente: {experiencia.estabelecimento}</h5>
              <p>
                Profissão: {experiencia.profissao.profissao} <br />
                Tempo experiência:{' '}
                {experiencia.tempo_experiencia
                  ? experiencia.tempo_experiencia.tempo_experiencia
                  : 'Não encontrado'}
              </p>
            </div>
            <div>
              <IconButton
                onClick={() => {
                  setEditarExperiencia(experiencia);
                  setAdicionarExperienciaDialog(true);
                }}
                icon={<EditIcon />}
                circle
                size="md"
              />

              <IconButton
                onClick={() => removerExperiencia(experiencia)}
                icon={<TrashIcon />}
                circle
                size="md"
              />
            </div>
          </Experiencia>
        ))}
      <AdicionarExperiencia
        exibir={adicionarExperienciaDialog}
        experiencia={editarExperiencia}
        fechar={(exp) => adicionarExperiencia(exp)}
      />
      {children}
    </Panel>
  );
}

ListaExperiencias.propTypes = {
  experiencias: PropTypes.arrayOf(PropTypes.object).isRequired,
  onListaAlterada: PropTypes.func,
  setExperiencias: PropTypes.func.isRequired,
  children: PropTypes.arrayOf(PropTypes.element),
};

ListaExperiencias.defaultProps = {
  children: [],
  onListaAlterada: () => { }
};

export default ListaExperiencias;
