import React from 'react';

import PropTypes from 'prop-types';
import { logs } from '../../common';

const { Nav } = require('rsuite');

const styles = {
  // marginBottom: 50,
};

export const NavLogs = ({ active, onSelect }) => {
  return (
    <Nav activeKey={active} onSelect={onSelect} style={styles}>
      {logs.map((log) => {
        return <Nav.Item eventKey={log.value}>{log.label}</Nav.Item>;
      })}
    </Nav>
  );
};

NavLogs.propTypes = {
  onSelect: PropTypes.func.isRequired,
  active: PropTypes.string.isRequired,
};
