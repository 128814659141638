export function buscarNotificacoes({ pagina, titulo, dataInicio, dataFim, mensagem }) {
  return {
    type: '@notificacao/BUSCAR_NOTIFICACOES',
    payload: {
      pagina: pagina || 1,
      titulo,
      dataInicio,
      dataFim,
      mensagem,
    },
  };
}

export function buscarNotificacoesSucesso({
  notificacoes,
  notificacoesNaoLidas,
  pedidosRecebidosNaoLidos,
  pedidosCanceladosNaoLidos,
  novosProfissionaisCriadosNaoLidos,
  novosEstabelecimentosCriadosNaoLidos,
  localizacaoNaoLidas,
  ajusteDeHorarioNaoLidos,
  contadorFalhaCobranca,
}) {
  return {
    type: '@notificacao/BUSCAR_NOTIFICACOES_SUCESSO',
    payload: {
      notificacoes,
      notificacoesNaoLidas,
      pedidosRecebidosNaoLidos,
      pedidosCanceladosNaoLidos,
      novosProfissionaisCriadosNaoLidos,
      novosEstabelecimentosCriadosNaoLidos,
      localizacaoNaoLidas,
      ajusteDeHorarioNaoLidos,
      contadorFalhaCobranca,
    },
  };
}

export function buscarFalhaNaCobrancaCount({
  falhaNaCobrancaCount,
}) {
  return {
    type: '@FALHA_NA_COBRANCA_COUNT',
    payload: {
      falhaNaCobrancaCount: falhaNaCobrancaCount
        ? Number(falhaNaCobrancaCount)
        : 0,
    },
  };
}

export function salvarTokenNotificacao({ tokenNotificacao }) {
  return {
    type: '@notificacao/SALVAR_TOKEN_NOTIFICACAO',
    payload: {
      tokenNotificacao,
    },
  };
}

export function marcarNotificacoesLidas({ pagina, titulo }) {
  return {
    type: '@notificacao/MARCAR_NOTIFICACOES_LIDAS',
    payload: {
      pagina,
      titulo,
    },
  };
}

export function novaNotificacao() {
  return {
    type: '@notificacao/NOVA_NOTIFICACAO',
  };
}

export function novaNotificacaoNovoPedido() {
  return {
    type: '@notificacao/NOVA_NOTIFICACAO_NOVO_PEDIDO',
  };
}

export function novaNotificacaoPedidoCancelado() {
  return {
    type: '@notificacao/NOVA_NOTIFICACAO_PEDIDO_CANCELADO',
  };
}

export function novaNotificacaoNovoProfissional() {
  return {
    type: '@notificacao/NOVA_NOTIFICACAO_NOVO_PROFISSIONAL',
  };
}

export function novaNotificacaoNovoEstabelecimento() {
  return {
    type: '@notificacao/NOVA_NOTIFICACAO_NOVO_ESTABELECIMENTO',
  };
}

export function novaNotificacaoLocalizacao() {
  return {
    type: '@notificacao/NOVA_NOTIFICACAO_LOCALIZACAO',
  };
}

export function novaNotificacaoAjusteDeHorario() {
  return {
    type: '@notificacao/NOVA_NOTIFICACAO_AJUSTE_DE_HORARIO',
  };
}

export function ReduzirAlteracaoPedido() {
  return {
    type: '@notificacao/NOVA_NOTIFICACAO_REDUZIR_ALTERACAO_PEDIDO',
  };
}
export function atualizacaoGlobalTempoReal(atualizacao) {
  return {
    type: '@ATUALIZACAO_GLOBAL_TEMPO_REAL',
    payload: {
      notificacoes: atualizacao.notificacoes,
      notificacoesNaoLidas: atualizacao.notificacoesNaoLidas,
      pedidosRecebidosNaoLidos: atualizacao.pedidosRecebidosNaoLidos,
      pedidosCanceladosNaoLidos: atualizacao.pedidosCanceladosNaoLidos,
      novosProfissionaisCriadosNaoLidos: atualizacao.novosProfissionaisCriadosNaoLidos,
      novosEstabelecimentosCriadosNaoLidos: atualizacao.novosEstabelecimentosCriadosNaoLidos,
      // localizacaoNaoLidas: atualizacao.localizacaoNaoLidas,
      ajusteDeHorarioNaoLidos: atualizacao.ajusteDeHorarioNaoLidos,
    },
  };
}
export function acrescentarContadorFalhaNaCobranca() {
  return {
    type: '@notificacao/ACRESCENTAR_CONTADOR_FALHA_NA_COBRANCA',
  };
}
export function reduzirContadorFalhaNaCobranca() {
  return {
    type: '@notificacao/REDUZIR_CONTADOR_FALHA_NA_COBRANCA',
  };
}

export function requisicaoFinalizada() {
  return {
    type: '@notificacao/REQUISICAO_FINALIZADA',
  };
}

export function verificarTokenNotificacao() {
  return {
    type: '@notificacao/VERIFICAR_TOKEN_NOTIFICACAO',
  };
}

export function getLocation() {
  return {
    type: '@notificacao/GET_LOCATION',
  };
}
