/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';

import { Table, FlexboxGrid, Schema } from 'rsuite';
import { toast } from 'react-toastify';

import { Container } from '~/styles/tabela';

import api from '~/services/api';
import {
  CelulaEditavel,
  CelulaAcao,
} from '~/components/Celulas/components/CelulaEditavel';
import { ModalIcon } from '~/components/ModalCriacao/ModalIcon';
import { Modal2 } from '~/components/ModalCriacao/Modal2';
import { Modal1 } from '~/components/ModalCriacao/Modal1';
import { Plus } from '@rsuite/icons';
import { TabelaSemPaginacao } from '~/components/TabelaSemPaginacao';
import SearchTable from '../components/SearchTable';

const { HeaderCell, Column } = Table;

const { StringType } = Schema.Types;

const modelo = Schema.Model({
  tempo_experiencia: StringType().isRequired('Idioma Obrigatório'),
});

function TemposExperiencias() {
  const [dados, setDados] = useState({
    listaInicial: [],
    tempoExperienciaLista: [],
    tempoExperienciaListaFiltrada: [],
    filtro: '',
  });
  const [apagarTempoExperienciaDialog, setApagarTempoExperienciaDialog] =
    useState({
      exibir: false,
      tempo: {},
    });
  const [carregando, setCarregando] = useState(true);
  const [adicionarTempoExperienciaDialog, setAdicionarTempoExperienciaDialog] =
    useState(false);
  const [camposInput, setCamposInput] = useState({});

  async function buscarTempoExperiencias() {
    const resposta = await api.get('tempo-experiencia');
    const resposta1 = await api.get('tempo-experiencia');
    const lista = resposta.data;
    setDados({
      listaInicial: resposta1.data,
      tempoExperienciaLista: lista,
      tempoExperienciaListaFiltrada: lista,
    });
    setCarregando(false);
  }

  useEffect(() => {
    buscarTempoExperiencias();
  }, []);

  function filtrarLista(texto) {
    setDados({
      ...dados,
      tempoExperienciaListaFiltrada: dados.tempoExperienciaLista.filter(
        (tempo) =>
          tempo.tempo_experiencia
            .toString()
            .toUpperCase()
            .includes(texto ? texto.toUpperCase() : '')
      ),
      filtro: texto,
    });
  }

  function abrirApagarDialog(tempoExperiencia) {
    setApagarTempoExperienciaDialog({
      exibir: true,
      tempo: tempoExperiencia,
    });
  }

  function fecharApagarDialog() {
    setApagarTempoExperienciaDialog({
      exibir: false,
      tempo: {},
    });
  }

  async function excluirTempoExperiencia() {
    try {
      await api.delete(
        `/tempo-experiencia/${apagarTempoExperienciaDialog.tempo.id}`
      );
      toast.success('Tempo experiência excluido com sucesso');
      fecharApagarDialog();
      buscarTempoExperiencias();
    } catch (e) {
      toast.error(e.message);
    }
  }

  async function adicionarTempoExperiencia() {
    try {
      setCarregando(true);
      await api.post(`/tempo-experiencia`, camposInput);
      toast.success('Tempo experiência criado com sucesso');
      setCarregando(false);
      setAdicionarTempoExperienciaDialog(false);
      buscarTempoExperiencias();
    } catch (e) {
      toast.error(e.message);
    }
  }

  function alterarTextoCelula(id, key, value) {
    const novaLista = Object.assign([], dados.tempoExperienciaLista);
    novaLista.find((tipo) => tipo.id === id)[key] = value;
    setDados({ ...dados, tempoExperienciaLista: novaLista });
    filtrarLista(dados.filtro);
  }

  async function alterarEstadoCelula(id) {
    const novaLista = Object.assign([], dados.tempoExperienciaLista);
    const item = novaLista.find((tipo) => tipo.id === id);
    const itemInicial = dados.listaInicial.find((tipo) => tipo.id === id);
    if (
      item.status === 'EDIT' &&
      item.tempo_experiencia !== itemInicial.tempo_experiencia
    ) {
      try {
        setCarregando(true);
        await api.put(`tempo-experiencia/${id}`, item);
        toast.success('Tempo de experiência atualizado com sucesso');
        buscarTempoExperiencias();
      } catch (error) {
        toast.error(error.message);
      }
      setCarregando(false);
    } else {
      item.status = item.status ? null : 'EDIT';
      setDados({ ...dados, tempoExperienciaLista: novaLista });
      filtrarLista(dados.filtro);
    }
  }

  return (
    <Container>
      <h2>Tempo de Experiência</h2>
      <FlexboxGrid justify="start" style={{ gap: '10px' }}>
        <SearchTable estilo={{ width: '300px' }} filtrarLista={filtrarLista} />
        <ModalIcon
          style={{ marginTop: '0' }}
          icon={<Plus />}
          onClick={() => {
            setAdicionarTempoExperienciaDialog(true);
          }}
        />
      </FlexboxGrid>
      <FlexboxGrid style={{ width: '100%' }}>
        <TabelaSemPaginacao
          carregando={carregando}
          dados={dados.tempoExperienciaListaFiltrada}
        >
          <Column resizable width={240} sortable>
            <HeaderCell>Tempo de Experiência</HeaderCell>
            <CelulaEditavel
              dataKey="tempo_experiencia"
              onChange={alterarTextoCelula}
            />
          </Column>

          <Column resizable width={200}>
            <HeaderCell>Editar</HeaderCell>
            <CelulaAcao
              dataKey="id"
              onClick={alterarEstadoCelula}
              onDelete={abrirApagarDialog}
            />
          </Column>
        </TabelaSemPaginacao>
      </FlexboxGrid>

      <Modal1
        show={adicionarTempoExperienciaDialog}
        onHide={() => setAdicionarTempoExperienciaDialog(false)}
        size="xs"
        title="Adicionar Tempo Experiência"
        label="Tempo Experiência"
        name="tempo_experiencia"
        model={modelo}
        onChange={setCamposInput}
        onSubmit={(status) => status && adicionarTempoExperiencia()}
        onClick={() => setAdicionarTempoExperienciaDialog(false)}
      />

      <Modal2
        show={apagarTempoExperienciaDialog.exibir}
        onHide={fecharApagarDialog}
        title="Apagar Tempo Experiencia"
        text="Você realmente deseja apagar este tempo de experiência?"
        apagarId={apagarTempoExperienciaDialog.tempo.id}
        apagarDado={apagarTempoExperienciaDialog.tempo.tempo_experiencia}
        fecharApagarDialog={fecharApagarDialog}
        excluir={() => excluirTempoExperiencia()}
      />
    </Container>
  );
}

export default TemposExperiencias;
