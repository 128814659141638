import { IconButton } from 'rsuite'
import styled from 'styled-components'


export const ButtonIcon = styled(IconButton)`

    display: flex;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;

    @media (max-width: 1366px){
        right: 115px;
    }
`