/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState, useCallback, useContext } from 'react';

import {
  Table,
  FlexboxGrid,
  Button,
  SelectPicker,
  Checkbox,
  IconButton,
  Toggle,
} from 'rsuite';

import { FileDownload } from '@rsuite/icons';

import { format, eachDayOfInterval, parseISO, endOfWeek, startOfWeek } from 'date-fns';

import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import JSPDF from 'jspdf';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { TablePagination } from '~/components/TablePagination';
import { Container } from '~/styles/tabela';
import api from '~/services/api';
import { GerarCSV as CSV } from '~/utils/gerarCSV'
import { ModalPadrao } from '~/components/Modal';
import formatCurrency from '~/utils/formatCurrency';
import SearchInputTable from '~/components/SearchInputTable';
import { InputDataRange } from '~/components/InputDataRange';
import DetalhesRelatorio from './components/DetalhesRelatorio';
import ModalEnviarNotificacaoInadimplentes from './components/ModalEnviarNotificacaoIInadimplentes';
import { CelulaRelatorio } from './components/CelulaRelatorio';
import { CelulaAcaoRelatorio } from './components/CelulaAcaoRelatorio';
import { CelulaAcaoBaixarPdf } from './components/CelulaAcaoBaixarPdf';
import CardTotais from './components/CardTotais';
import 'jspdf-autotable';
import { CelulaAcaoInfo } from './components/CelulaAcaoInfo';
import { TogglesStatus } from './styles';
import { DarkModeContext } from '../../../common/contexts/darkMode';
import theme from '../../../styles/theme';
import { datasSelecionadasSemHorario } from '../../../utils/datasSelecionadasSemHorario';

const { HeaderCell, Column, Cell } = Table;

function RelatoriosEstabelecimento() {
  const { toggleMode } = useContext(DarkModeContext)

  // const [filtroData, setFiltroData] = useState();
  const [datas, setDatas] = useState([
    startOfWeek(new Date(), { weekStartsOn: 1 }),
    endOfWeek(new Date(), { weekStartsOn: 1 }),
  ]);
  const [filtroData, setFiltroData] = useState(eachDayOfInterval({
    start: startOfWeek(new Date(), { weekStartsOn: 1 }),
    end: endOfWeek(new Date(), { weekStartsOn: 1 }),
  }));


  const [relatorios, setRelatorios] = useState({
    lista: [],
    quantidade: 0,
    valorTotal: 0,
  });
  const [relatoriosPDF, setRelatoriosPDF] = useState({
    lista: [],
    quantidade: 0,
    valorTotal: 0,
  });
  const [atributoSelecionado, setAtributoSelecionado] = useState('nome');
  const [statusFiltro, setStatusFiltro] = useState();
  const [textoDigitado, setTextoDigitado] = useState();
  const [pagina, setPagina] = useState(1);
  const [carregando, setCarregando] = useState(false);
  const [inadimplentes, setInadimplentes] = useState(false);
  const [tipoData, setTipoData] = useState('semanal');
  const atributosFiltro = [
    { label: 'Nome', value: 'nome' },
    { label: 'CNPJ', value: 'cpf_cnpj' },
  ];
  const atributosData = [
    { label: 'Semanal', value: 'semanal' },
    { label: 'Livre', value: 'livre' },
  ];

  const locale = {
    sunday: 'Dom',
    monday: 'Seg',
    tuesday: 'Ter',
    wednesday: 'Qua',
    thursday: 'Qui',
    friday: 'Sex',
    saturday: 'Sab',
    ok: 'Ok',
    today: 'Hoje',
    yesterday: 'Ontem',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos',
  };
  const [showInfo, setShowInfo] = useState(false);
  const [estInfo, setEstInfo] = useState({ estabelecimento: { id: 0 } });
  const [orderBy, setOrderBy] = useState('order by semana_formatado desc');

  const [carregando2, setCarregando2] = useState(false);
  const [modalCSV, setModalCSV] = useState(false);

  const modalTitulo = 'Gerar CSV'
  const modalTexto = 'Tem certeza que deseja gerar este CSV com o filtro de data selecionado?'


  const buscarRelatorios = useCallback(async () => {
    setCarregando(true);
    try {
      const resposta = await api.get('relatorios/estabelecimento-adm', {
        params: {
          datas: filtroData,
          pagina,
          [atributoSelecionado]: textoDigitado,
          status: statusFiltro,
          inadimplentes,
          orderBy,
        },
      });
      const lista = resposta.data.lista.map((relatorio) => ({
        ...relatorio,
        pagamento_formatado: format(
          new Date(relatorio.pagamento),
          'dd/LL/yyyy'
        ),
        semana_formatado: format(new Date(relatorio.semana), 'dd/LL/yyyy'),
        valor: relatorio.valor ? formatCurrency(relatorio.valor) : 0.0,
        tarifa_dinamica: `${((Number(relatorio.tarifa_dinamica) - 1) * 100).toFixed()}%`,
        situacao: relatorio.situacao === 'liberado',
      }));
      setRelatorios({
        lista,
        quantidade: lista.length > 0 ? lista[0].full_count : 0,
        valorTotal: resposta.data.valorTotal
          ? resposta.data.valorTotal.toFixed(2)
          : 0,
      });
      if(relatorios.quantidade === 0) setPagina(1)
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
  }, [
    filtroData,
    pagina,
    statusFiltro,
    atributoSelecionado,
    textoDigitado,
    inadimplentes,
    orderBy,
    relatorios.quantidade,

  ]);

  // eslint-disable-next-line consistent-return
  const buscarRelatoriosPDF = async () => {
    setCarregando(true);
    try {
      const resposta = await api.get('relatorios/estabelecimento-adm', {
        params: {
          datas: filtroData,
          pagina: 1,
          [atributoSelecionado]: textoDigitado,
          status: statusFiltro,
          inadimplentes,
          orderBy,
          all: 'yes'
        },
      });


      const lista = resposta.data.lista.map((relatorio) => ({
        ...relatorio,
        pagamento_formatado: format(
          new Date(relatorio.pagamento),
          'dd/LL/yyyy'
        ),
        semana_formatado: format(new Date(relatorio.semana), 'dd/LL/yyyy'),
        valor: relatorio.valor ? formatCurrency(relatorio.valor) : 0.0,
        tarifa_dinamica: `${((Number(relatorio.tarifa_dinamica) - 1) * 100).toFixed()}%`,
        situacao: relatorio.situacao === 'liberado',
      }));


      setRelatoriosPDF({
        lista,
        quantidade: resposta.data.quantidade,
        valorTotal: resposta.data.valorTotal
          ? resposta.data.valorTotal.toFixed(2)
          : 0,
      });

      setCarregando(false);

      return lista
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
  };

  useEffect(() => {
    buscarRelatorios();
  }, [buscarRelatorios]);

  async function atualizarStatus(relatorio) {
    setCarregando(true);
    try {
      if (relatorio.status === 'A Pagar')
        await api.put(
          `/relatorios/estabelecimento/${relatorio.id_estabelecimento}`,
          {},
          {
            params: {
              semana: relatorio.semana,
            },
          }
        );
      else
        await api.put(`/relatorios/estabelecimento/cancelar/${relatorio.id}`);
      toast.success('Relatório atualizado com sucesso');
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
    buscarRelatorios();
  }
  function abreServicos(estabelecimento) {
    setEstInfo(estabelecimento);
    setShowInfo(true);
  }
  function fechaServicos() {
    setShowInfo(false);
  }

  function funcInadimplentes() {
    setInadimplentes(!inadimplentes);
    setFiltroData(null);
    setDatas([]);
    return inadimplentes === false && setStatusFiltro('A Pagar');
  }

  async function exportPDF(relatorio, zip) {
    setCarregando(true);
    if (!filtroData) {
      toast.error('Selecione uma data');
      setCarregando(false);
      return;
    }

    try {
      const resposta = await api.get(`/relatorios/detalhes-estabelecimento/pdf/${relatorio.id_estabelecimento}`, {
        params: {
          datas: filtroData,
        },
      });

      const lista = resposta.data.lista.map((i) => ({
        ...i,
        profissional: i.profissional.nome ? i.profissional.nome : 'Excluido',
        profissao: i.profissao.profissao ? i.profissao.profissao : 'Excluido',
        avaliacao: i.avaliacao ? i.avaliacao : ' ',
        valor: i.valor ? `R$ ${i.valor.toFixed(2)}` : `R$ ${0}`,
        valor_sem_formatacao: i.valor,
        hora_inicio: format(parseISO(i.hora_inicio), 'HH:mm'),
        hora_fim: format(parseISO(i.hora_fim), 'HH:mm'),
        data_formatada: format(parseISO(i.data), 'dd/LL/yyyy'),
        intervalo: i.pedido.intervalo
          ? `${i.pedido.intervalo} min`
          : `${0} min`,
        tarifa_dinamica: `${(i.tarifa_dinamica * 100 - 100).toFixed(
          0
        )}%`,
        tarifa_emergencia: `${(i.tarifa_emergencia * 100 - 100).toFixed()}%`,
        cupom: i.pedido.cupom ? i.pedido.cupom.cupom : '-',
      }));


      const valor = formatCurrency(lista.reduce((acc, ped) => acc + ped.valor_sem_formatacao, 0));
      const unit = 'pt';
      const size = 'A3'; // Use A1, A2, A3 or A4
      const orientation = 'landscape'; // portrait or landscape

      const marginLeft = 40;
      const doc = new JSPDF(orientation, unit, size);

      const title = `Relatório ${relatorio.nome_fantasia} | Total: ${valor}`;

      const columns = [
        { header: 'Nome Profissional', dataKey: 'profissional' },
        { header: 'Serviço', dataKey: 'profissao' },
        { header: 'Avaliação', dataKey: 'avaliacao' },
        { header: 'Valor', dataKey: 'valor' },
        { header: 'Hora Inicio', dataKey: 'hora_inicio' },
        { header: 'Hora Fim', dataKey: 'hora_fim' },
        { header: 'Data', dataKey: 'data_formatada' },
        { header: 'Tarifa Dinâmica', dataKey: 'tarifa_dinamica' },
        { header: 'Tarifa Prazo Curto', dataKey: 'tarifa_emergencia' },
        { header: 'Cupom', dataKey: 'cupom' },
        { header: 'Intervalo', dataKey: 'intervalo' },
      ];
      doc.text(title, marginLeft, 40);
      doc.autoTable({
        startY: 50,
        body: lista,
        theme: 'striped',
        columns,
      });

      if (zip) {
        zip.file(
          `${relatorio.nome_fantasia} ${format(
            new Date(relatorio.semana),
            'dd-LL-yyyy'
          )}.pdf`,
          doc.output('blob')
        );
      } else {
        doc.save(
          `${relatorio.nome_fantasia} ${format(
            new Date(relatorio.semana),
            'dd-LL-yyyy'
          )}.pdf`
        );
      }
    } catch (error) {
      toast.error(error.message);
    }
    setCarregando(false);
  }



  async function baixarTodos() {
    if (!filtroData) {
      toast.error('Selecione uma data');
      return;
    }
    const zip = new JSZip();
    const lista = await buscarRelatoriosPDF();
    await Promise.all(lista.map((l) => exportPDF(l, zip)));


    await zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, `Relatórios Clientes (pagina-${pagina}).zip`);
    });
  }




  async function relatorioUnificado() {
    if (!filtroData) {
      toast.error('Selecione uma data');
      setCarregando(false);
      return;
    }
    await exportPDF(relatorios.lista[0])
  }

  async function alterarStatus(e, value) {
    setCarregando(true);
    try {
      const statusValue = {
        id: e.id_estabelecimento,
        status: value ? 'bloqueado' : 'liberado',
      };
      await api.patch(`/estabelecimento/sitiacao`, statusValue);
      buscarRelatorios();
      toast.success('Status alterado com sucesso.');
    } catch (error) {
      toast.error(error.message);
      setCarregando(false);
    }
    setCarregando(false);
  }

  // async function relatorioCSVFechamento() {
  //   if (!filtroData) {
  //     toast.error('Selecione uma data');
  //     setCarregando(false);
  //     return;
  //   }

  // }

function converterTimeZoneParaLocal(data) {
  // const date = new Date(data);
  return `${format(parseISO(data), 'dd/LL/yyyy')} ${format(parseISO(data), 'HH:mm')}`
}

  const relatorioCSVFechamento = useCallback(async () => {
    const datasEscolhidasSemHorario = datasSelecionadasSemHorario(datas)
    // setCarregando2(true)
    setModalCSV(false)
    try {
      const resposta = await api.get('relatorios/estabelecimento-adm-fechamento-csv', {
        params: {
        datas: datasEscolhidasSemHorario,
      },
      });
      const lista = resposta.data.map((item) => {
        return {
          ...item,
          avaliacao_est: item.avaliacao_est !== null ? item.avaliacao_est.toFixed(2) : '',
          avaliacao_prof: item.avaliacao_prof !== null ? item.avaliacao_prof.toFixed(2) : '',
          created_at: converterTimeZoneParaLocal(item.created_at),
          data: format(parseISO(item.data), 'dd/LL/yyyy'),
          desc_cupom: item.desc_cupom.toFixed(2),
          desc_estab: item.desc_estab.toFixed(2),
          hora_fim: converterTimeZoneParaLocal(item.hora_fim),
          hora_inicio: converterTimeZoneParaLocal(item.hora_inicio),
          tarifa_dinamica: item.tarifa_dinamica.toFixed(2),
          tarifa_emergencia: item.tarifa_emergencia.toFixed(2),
          tarifa_estado: item.tarifa_estado.toFixed(2),
          tarifa_feriado: item.tarifa_feriado.toFixed(2),
          tarifa_repasse: item.tarifa_repasse.toFixed(2),
          valor: item.valor.toFixed(2),
          valor_bruto: item.valor_bruto.toFixed(2),
          valor_eventual: item.valor_eventual.toFixed(2),
          checkin_hora: item.checkin_hora ? converterTimeZoneParaLocal(item.checkin_hora) : '',
        }
      });
      if(!lista.length) {
        toast.error('Não há registros para essas datas.');
        return
      }

      CSV({
        data: lista,
        download: true,
      });

      setCarregando2(false);
      toast.success(`Foram exportados ${lista.length} registros.`);
    } catch (e) {
      setCarregando2(false);
      toast.error('Erro ao Exportar os dados');
    }
  }, [datas])


  const CelulaAcaoStatus = ({ rowData, ...props }) => (
    <Cell
    {...props}
    style={{
      background: rowData.status === 'Pago' && `${theme.darkGreen}`,
    }}
    >
      <TogglesStatus>
        <Toggle
          checkedChildren="Liberado"
          unCheckedChildren="Bloqueado"
          defaultChecked={rowData.situacao}
          onChange={() => alterarStatus(rowData, rowData.situacao)}
        />
      </TogglesStatus>
    </Cell>
  );


  CelulaAcaoStatus.propTypes = {
    rowData: PropTypes.objectOf.isRequired,
    props: PropTypes.node.isRequired,
    setNewPro: PropTypes.func.isRequired,
  };


  const DatePicker = () => {
    return (tipoData === 'semanal' ?
      <InputDataRange
        ranges={[]}
        isoWeek
        oneTap
        locale={locale}
        onClean={() => {
          setFiltroData(null);
          setDatas([]);
        }}
        onChange={(datasSelecionadas) => {
          setDatas(datasSelecionadas);
          if (datasSelecionadas && datasSelecionadas.length !== 0) {
            const datasIntervalo = eachDayOfInterval({
              start: datasSelecionadas[0],
              end: datasSelecionadas[1],
            });
            setFiltroData(datasIntervalo);
          } else {
            setFiltroData(null);
          }
        }}
        value={datas}
        hoverRange="week"
      />
      :
      <InputDataRange
        ranges={[]}
        isoWeek
        // oneTap
        locale={locale}
        onClean={() => {
          setFiltroData(null);
          setDatas([]);
        }}
        onChange={(datasSelecionadas) => {
          setDatas(datasSelecionadas);
          if (datasSelecionadas && datasSelecionadas.length !== 0) {
            const datasIntervalo = eachDayOfInterval({
              start: datasSelecionadas[0],
              end: datasSelecionadas[1],
            });
            setFiltroData(datasIntervalo);
          } else {
            setFiltroData(null);
          }
        }}
        value={datas}
      />
    )
  }

  return (
    <Container>
      <h2>Relatórios Cliente</h2>

      <FlexboxGrid justify="start" style={{ gap: '10px' }}>
        <SearchInputTable estilo={{ width: '300px' }} setTextoDigitado={setTextoDigitado} />
        <SelectPicker
          appearance="default"
          searchable={false}
          data={atributosFiltro}
          value={atributoSelecionado}
          cleanable={false}
          onChange={(value) => setAtributoSelecionado(value)}
        />
        <DatePicker />
        <SelectPicker
          appearance="default"
          searchable={false}
          data={atributosData}
          value={tipoData}
          cleanable={false}
          onChange={(value) => setTipoData(value)}
        />

        <SelectPicker
          value={statusFiltro}
          title="Filtro Status"
          toggleComponentClass={Button}
          appearance="default"
          searchable={false}
          placeholder="Selecione Status"
          data={[
            { label: 'A Pagar', value: 'A Pagar' },
            { label: 'Pago', value: 'Pago' },
          ]}
          onChange={setStatusFiltro}
        />
        <IconButton
          style={{ color: toggleMode ? '#fff' : '#575757' }}
          icon={<FileDownload />}
          onClick={() => baixarTodos()}
        >
          <p style={{ color: toggleMode ? '#fff' : '#575757' }}>Baixar Todos</p>
        </IconButton>
        <IconButton
          style={{ color: toggleMode ? '#fff' :  '#575757' }}
          icon={<FileDownload />}
          onClick={() => relatorioUnificado()}
        >
          <p style={{ color: toggleMode ? '#fff' : '#575757' }}>Relatório Unificado</p>
        </IconButton>
        <Checkbox onChange={funcInadimplentes} checked={inadimplentes}>
          Inadimplentes
        </Checkbox>
        <ModalEnviarNotificacaoInadimplentes
        filtroData={[filtroData]}
        textoDigitado={textoDigitado}
        statusFiltro={statusFiltro}
        atributo={atributoSelecionado}
        />
      </FlexboxGrid>
      <FlexboxGrid justify="start" style={{ gap: '10px' }}>
      <CardTotais
        numeroClientes={relatorios.quantidade}
        totalContratado={relatorios.valorTotal}
      />
      <IconButton
          style={{ color: toggleMode ? '#fff' : '#575757', marginTop: '20px' }}
          icon={<FileDownload />}
          onClick={() => setModalCSV(true)}
        >
          <p style={{ color: toggleMode ? '#fff' : '#575757' }}>Relatório Fechamento</p>
        </IconButton>
        </FlexboxGrid>
  <FlexboxGrid style={{ width: '100%' }}>
      <TablePagination
        carregando={carregando}
        dados={relatorios.lista}
        onChangePage={setPagina}
        quantidade={relatorios.quantidade}
        setOrderBy={setOrderBy}
      >
        <Column resizable width={80}>
          <HeaderCell>Detalhes</HeaderCell>
          <CelulaAcaoInfo toggleMode={toggleMode} abreServicos={abreServicos} />
        </Column>
        <Column resizable width={90}>
          <HeaderCell>Baixar PDF</HeaderCell>
          <CelulaAcaoBaixarPdf toggleMode={toggleMode} onClick={exportPDF} />
        </Column>
        <Column resizable sortable width={100}>
          <HeaderCell>Status</HeaderCell>
          <CelulaRelatorio dataKey="status" />
        </Column>

        <Column resizable width={220} sortable>
          <HeaderCell>Nome Fantasia</HeaderCell>
          <CelulaRelatorio dataKey="nome_fantasia" />
        </Column>

        <Column resizable width={180} sortable>
          <HeaderCell>Cpf/Cnpj</HeaderCell>
          <CelulaRelatorio dataKey="cpf_cnpj" />
        </Column>

        <Column resizable width={120}sortable>
          <HeaderCell>Semana</HeaderCell>
          <CelulaRelatorio dataKey="semana_formatado" />
        </Column>

        <Column resizable width={120}sortable >
          <HeaderCell>Pagamento</HeaderCell>
          <CelulaRelatorio dataKey="pagamento_formatado" />
        </Column>

        <Column resizable width={120} sortable>
          <HeaderCell>Quantidade</HeaderCell>
          <CelulaRelatorio dataKey="quantidade" />
        </Column>

        <Column resizable width={120} sortable>
          <HeaderCell>Valor</HeaderCell>
          <CelulaRelatorio dataKey="valor" />
        </Column>

        <Column resizable width={120}>
          <HeaderCell>Alterar Status</HeaderCell>
          <CelulaAcaoRelatorio onClick={atualizarStatus} />
        </Column>

        <Column resizable width={130} sortable>
          <HeaderCell>Situação</HeaderCell>
          <CelulaAcaoStatus dataKey="situacao" />
        </Column>
      </TablePagination>
      </FlexboxGrid>
      <DetalhesRelatorio
        exibir={showInfo}
        id_estabelecimento={estInfo.id_estabelecimento}
        onFechar={fechaServicos}
        filtroData={filtroData}
        dataInicial={datas}
      />
      <ModalPadrao
      openModal={modalCSV}
      carregando={carregando2}
      // csv
      setOpenModal={setModalCSV}
      title={modalTitulo}
      text={modalTexto}
      funcExec={relatorioCSVFechamento}
      />
    </Container>
  );
}

export default RelatoriosEstabelecimento;
