/* eslint-disable no-unused-expressions */
/* eslint-disable no-console */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';

import { Plus, Minus } from '@rsuite/icons';
import { IconButton } from 'rsuite';

import PropTypes from 'prop-types';
import { Linha, Tabela } from './styles';
import { Container } from '../profissionais/styles';
import { Th, Td } from './Modules';

export const TableChildrens = ({ childrens, list, index }) => {
  const { title, columns } = childrens;
  let newList = [];
  list && Array.isArray(list) ? (newList = list) : newList.push(list);
  return (
    <Linha key={index} colSpan={columns.length}>
      <td colSpan={columns.length} valign="middle">
        <Container>
          <Tabela>
            <caption>{title}</caption>
            <thead>
              <tr>
                <Th title={columns} />
              </tr>
            </thead>
            <tbody>
              {newList &&
                newList.length > 0 &&
                newList.map((li, indice) => (
                  <>
                    <tr key={indice}>
                      <Td columns={columns} log={li} />
                    </tr>
                  </>
                ))}
            </tbody>
          </Tabela>
        </Container>
      </td>
    </Linha>
  );
};

TableChildrens.propTypes = {
  childrens: PropTypes.arrayOf.isRequired,
  list: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export const Iconchildren = ({ childrens, log, exibirChildrens }) => {
  return childrens.map((child, index) => (
    <td>
      {log[`icone${index}`] ? (
        <IconButton
          appearance="subtle"
          onClick={() => exibirChildrens(index, log.id, false, true)}
          icon={<Plus />}
        />
      ) : (
        <IconButton
          appearance="subtle"
          onClick={() => exibirChildrens(index, log.id, true, false)}
          icon={<Minus />}
        />
      )}
    </td>
  ));
};

Iconchildren.propTypes = {
  childrens: PropTypes.arrayOf.isRequired,
  log: PropTypes.object.isRequired,
  exibirChildrens: PropTypes.func.isRequired,
};

export const Thchildren = () => {};

export default function Childrens(props) {
  const { lista = [] } = props;
}

Childrens.propTypes = {
  lista: PropTypes.arrayOf.isRequired,
};
