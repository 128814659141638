export const logs = [
  { label: 'Profissionais', value: 'profissionais' },
  { label: 'Clientes', value: 'clientes' },
  { label: 'Pedidos', value: 'pedidos' },
  { label: 'Opções', value: 'opções' },
  { label: 'Tarifas', value: 'tarifas' },
  { label: 'Administração', value: 'administração' },
  { label: 'Recrutamento', value: 'recrutamento' },
];

export const motivosPedidos = [
  { labael: 'Autorização', value: 'Autorização' },
  { labael: 'Cancelamento', value: 'Cancelamento' },
  { labael: 'Confirmação', value: 'Confirmação' },
  { labael: 'Criação', value: 'Criação' },
  { labael: 'Edição', value: 'Edição' },
  { labael: 'Finalização', value: 'Finalização' },
  { labael: 'Liberação', value: 'Liberação' },
  { labael: 'Visualização', value: 'Visualização' },
];

export const motivosPro = [
  { label: 'Criação', value: 'Criação' },
  { label: 'Edição', value: 'Atualização' },
  { label: 'Exclusão', value: 'Exclusão' },
  { label: 'Visualização', value: 'Visualização' },
  { label: 'Criação Dados Bancários', value: 'Criação Dados Bancários' },
  { label: 'Exclusão Dados Bancários', value: 'Exclusão Dados Bancários' },
];

export const motivosCli = [
  { label: 'Criação', value: 'Criação' },
  { label: 'Edição', value: 'Atualização' },
  { label: 'Visualização', value: 'Visualização' },
];

export const motivosAdm = [
  { label: 'Criação', value: 'Criação' },
  { label: 'Edição', value: 'Atualização' },
  { label: 'Remoção', value: 'Remoção' },
];

export const opcoes = [
  { label: 'Idiomas', value: 4 },
  { label: 'Serviços', value: 5 },
  { label: 'Áreas de Atuação', value: 6 },
  { label: 'Escolaridade', value: 7 },
  { label: 'Tempo de Experiência', value: 8 },
  { label: 'Habilidades', value: 9 },
  { label: 'Dias de Funcionamento', value: 10 },
  { label: 'Períodos de Funcionamento', value: 11 },
  { label: 'Uniforme', value: 12 },
  { label: 'Cupom', value: 13 },
  { label: 'Treinamentos', value: 14 },
  { label: 'ComoDescobriu', value: 15 },
  { label: 'Genero', value: 16 },
];

export const motivosOpcoesII = [
  { label: 'Criação', value: 'Criação' },
  { label: 'Edição', value: 'Edição' },
  { label: 'Exclusão', value: 'Exclusão' },
];

export const motivosOpcoes = {
  4: [
    { title: 'Responsável', value: 'adm_nome' },
    { title: 'ID Idioma', value: 'id_registro' },
    { title: 'Idioma', value: 'idioma' },
    { title: 'Motivo', value: 'motivo', width: '20%' },
    { title: 'Data de Criação', value: 'createdAt' },
  ],
  5: [
    { title: 'Responsável', value: 'adm_nome' },
    { title: 'ID Profissional', value: 'id_registro' },
    { title: 'Motivo', value: 'motivo', width: '20%' },
    { title: 'Data de Criação', value: 'createdAt' },
  ],
  6: [
    { title: 'Responsável', value: 'adm_nome' },
    { title: 'ID Profissional', value: 'id_registro' },
    { title: 'Motivo', value: 'motivo', width: '20%' },
    { title: 'Data de Criação', value: 'createdAt' },
  ],
  7: [
    { title: 'Responsável', value: 'adm_nome' },
    { title: 'ID Profissional', value: 'id_registro' },
    { title: 'Motivo', value: 'motivo', width: '20%' },
    { title: 'Data de Criação', value: 'createdAt' },
  ],
  8: [
    { title: 'Responsável', value: 'adm_nome' },
    { title: 'ID Profissional', value: 'id_registro' },
    { title: 'Motivo', value: 'motivo', width: '20%' },
    { title: 'Data de Criação', value: 'createdAt' },
  ],
  9: [
    { title: 'Responsável', value: 'adm_nome' },
    { title: 'ID Profissional', value: 'id_registro' },
    { title: 'Motivo', value: 'motivo', width: '20%' },
    { title: 'Data de Criação', value: 'createdAt' },
  ],
  10: [
    { title: 'Responsável', value: 'adm_nome' },
    { title: 'ID Profissional', value: 'id_registro' },
    { title: 'Motivo', value: 'motivo', width: '20%' },
    { title: 'Data de Criação', value: 'createdAt' },
  ],
  11: [
    { title: 'Responsável', value: 'adm_nome' },
    { title: 'ID Profissional', value: 'id_registro' },
    { title: 'Motivo', value: 'motivo', width: '20%' },
    { title: 'Data de Criação', value: 'createdAt' },
  ],
  12: [
    { title: 'Responsável', value: 'adm_nome' },
    { title: 'ID Profissional', value: 'id_registro' },
    { title: 'Motivo', value: 'motivo', width: '20%' },
    { title: 'Data de Criação', value: 'createdAt' },
  ],
  13: [
    { title: 'Responsável', value: 'adm_nome' },
    { title: 'ID Profissional', value: 'id_registro' },
    { title: 'Motivo', value: 'motivo', width: '20%' },
    { title: 'Data de Criação', value: 'createdAt' },
  ],
  14: [
    { title: 'Responsável', value: 'adm_nome' },
    { title: 'ID Profissional', value: 'id_registro' },
    { title: 'Motivo', value: 'motivo', width: '20%' },
    { title: 'Data de Criação', value: 'createdAt' },
  ],
};

export const columnsOpcoes = {
  4: [
    { title: 'Responsável', value: 'adm_nome' },
    { title: 'ID Idioma', value: 'id_registro' },
    { title: 'Idioma', value: 'idioma' },
    { title: 'Motivo', value: 'motivo' },
    { title: 'Data de Criação', value: 'createdAt' },
  ],
  5: [
    { title: 'Responsável', value: 'adm_nome' },
    { title: 'ID Serviço', value: 'id_registro' },
    { title: 'Profissao', value: 'profissao' },
    { title: 'Habilidade', value: 'habilidade' },
    { title: 'Val Cobrar', value: 'valor_cobrar' },
    { title: 'Val Repassar', value: 'valor_repassar' },
    { title: 'Bloqueado ?', value: 'profissao_bloqueada' },
    { title: 'Motivo', value: 'motivo' },
    { title: 'Data de Criação', value: 'createdAt' },
  ],
  6: [
    { title: 'Responsável', value: 'adm_nome' },
    { title: 'ID Área Atuação', value: 'id_registro' },
    { title: 'Atuação', value: 'tipo_estabelecimento' },
    { title: 'Motivo', value: 'motivo' },
    { title: 'Data de Criação', value: 'createdAt' },
  ],
  7: [
    { title: 'Responsável', value: 'adm_nome' },
    { title: 'ID Ecolaridade', value: 'id_registro' },
    { title: 'Escolaridade', value: 'escolaridade' },
    { title: 'Motivo', value: 'motivo' },
    { title: 'Data de Criação', value: 'createdAt' },
  ],
  8: [
    { title: 'Responsável', value: 'adm_nome' },
    { title: 'ID Tempo Exp', value: 'id_registro' },
    { title: 'Tempo Exp', value: 'tempo_experiencia' },
    { title: 'Motivo', value: 'motivo' },
    { title: 'Data de Criação', value: 'createdAt' },
  ],
  9: [
    { title: 'Responsável', value: 'adm_nome' },
    { title: 'ID Habilidade', value: 'id_registro' },
    { title: 'Habilidade', value: 'habilidade' },
    { title: 'Motivo', value: 'motivo' },
    { title: 'Data de Criação', value: 'createdAt' },
  ],
  10: [
    { title: 'Responsável', value: 'adm_nome' },
    { title: 'ID', value: 'id_registro' },
    { title: 'Dia Funcion', value: 'dia' },
    { title: 'Motivo', value: 'motivo' },
    { title: 'Data de Criação', value: 'createdAt' },
  ],
  11: [
    { title: 'Responsável', value: 'adm_nome' },
    { title: 'ID', value: 'id_registro' },
    { title: 'Periodo', value: 'periodo' },
    { title: 'Motivo', value: 'motivo' },
    { title: 'Data de Criação', value: 'createdAt' },
  ],
  12: [
    { title: 'Responsável', value: 'adm_nome' },
    { title: 'ID', value: 'id_registro' },
    { title: 'Blusa', value: 'blusa' },
    { title: 'Calca', value: 'calca' },
    { title: 'Calcado', value: 'calcado' },
    { title: 'Cabelo', value: 'cabelo' },
    { title: 'Barba', value: 'barba' },
    { title: 'Motivo', value: 'motivo' },
    { title: 'Data de Criação', value: 'createdAt' },
  ],
  13: [
    { title: 'Responsável', value: 'adm_nome' },
    { title: 'ID Cupom', value: 'id_registro' },
    { title: 'Cupom', value: 'cupom' },
    { title: 'Desconto', value: 'desconto' },
    { title: 'Status', value: 'status' },
    { title: 'Id Cidade', value: 'id_cidade' },
    { title: 'Motivo', value: 'motivo' },
    { title: 'Data de Criação', value: 'createdAt' },
  ],
  14: [
    { title: 'Responsável', value: 'adm_nome' },
    { title: 'ID', value: 'id_registro' },
    { title: 'Treinamento', value: 'treinamento' },
    { title: 'Motivo', value: 'motivo' },
    { title: 'Data de Criação', value: 'createdAt' },
  ],
};

export const columnsSubTables = {
  4: [],
  5: [],
  6: [],
  7: [],
  8: [],
  9: [],
  10: [],
  11: [],
  12: [],
  13: [],
  14: [],
};

export const tarifas = [
  { label: 'Estado', value: 22 },
  { label: 'Feriados', value: 23 },
];

export const motivosTarifas = {
  22: [{ labael: 'Edição', value: 'Edição' }],
  23: [
    { labael: 'Criação', value: 'Criação' },
    { labael: 'Edição', value: 'Edição' },
    { labael: 'Exclusão', value: 'Exclusão' },
  ],
};

export const columnsTarifas = {
  22: [
    { title: 'Responsável', value: 'adm_nome' },
    { title: 'ID', value: 'id_registro' },
    { title: 'Estado', value: 'estado' },
    { title: 'UF', value: 'uf' },
    { title: 'Tarifa', value: 'tarifa' },
    { title: 'Hora Mínima', value: 'hora_minima' },
    { title: 'Motivo', value: 'motivo' },
    { title: 'Data de Criação', value: 'createdAt' },
  ],
  23: [
    { title: 'Responsável', value: 'adm_nome' },
    { title: 'ID', value: 'id_registro' },
    { title: 'Data', value: 'data' },
    { title: 'Feriado', value: 'nome' },
    { title: 'Tarifa', value: 'tarifa' },
    { title: 'Motivo', value: 'motivo' },
    { title: 'Data de Criação', value: 'createdAt' },
  ],
};

export const recrutamento = [
  { label: 'Recrutamento', value: 24 },
  { label: 'Profissionais', value: 25 },
];

export const motivosRecrutamento = {
  24: [
    { label: 'Criação', value: 'Criação' },
    { label: 'Edição', value: 'Edição' },
    { label: 'Exclusão', value: 'Exclusão' },
    { label: 'Alteração de Status', value: 'Alteração de status' },
  ],
  25: [
    { label: 'Criação', value: 'Criação' },
    { label: 'Edição', value: 'Edição' },
    { label: 'Exclusão', value: 'Exclusão' },
  ],
};

export const columnsRecrutamento = {
  24: [
    { title: 'Responsável', value: 'adm_nome' },
    { title: 'ID', value: 'id_registro' },
    { title: 'Motivo', value: 'motivo' },
    { title: 'Data de Criação', value: 'createdAt' },
  ],
  25: [
    { title: 'Responsável', value: 'adm_nome' },
    { title: 'ID', value: 'id_registro' },
    { title: 'Motivo', value: 'motivo' },
    { title: 'Data de Criação', value: 'createdAt' },
  ],
};

export const columnsRecrutamentoSubTables = {
  24: [
    {
      title: 'Recrutamento',
      list: ['recrutamento'],
      columns: [
        { title: 'Solicitacao', value: 'data_solicitacao' },
        { title: 'Inicio', value: 'previsao_inicio' },
        { title: 'Contrato', value: 'tipo_contrato' },
        { title: 'jornada', value: 'jornada' },
        { title: 'salario', value: 'salario' },
        { title: 'Total Vagas', value: 'total_vagas' },
        { title: 'Status', value: 'status' },
        { title: 'Experiencia', value: 'experiencia' },
        { title: 'Perfil', value: 'perfil' },
        { title: 'Observacao', value: 'observacao' },
        { title: 'Anotacoes', value: 'anotacoes' },
      ],
    },
  ],
  25: [
    {
      title: 'Dados',
      list: ['recrutamento'],
      columns: [
        { title: 'ID Profissional', value: 'id_profissional' },
        { title: 'ID Recrutamento', value: 'id_recrutamento' },
        { title: 'Informações Pro', value: 'infor_pro' },
        { title: 'Status contratacao', value: 'status_contratacao' },
      ],
    },
  ],
};
