import React, {useState, memo} from 'react'
import PropTypes from 'prop-types';
import { GoogleMap, useJsApiLoader, Marker, DirectionsRenderer } from '@react-google-maps/api';
import { Button } from 'rsuite';

const containerStyle = {
  width: '550px',
  height: '600px'
};

function MapContainer({lat, lng, title, estabelecimento}) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDFDc2Ji7FKyONo8Tj97AMKimxsqRc3CAY",
  });

  const [distance, setDistance] = useState('');
  const [duration, setDuration] = useState('')
  const [directionsResponse, setDirectionsResponse] = useState(null);

  // const [origin, setOrigin] = useState('');
  // const [destination, setDestination] = useState('');

  async function calculateRoute() {
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: {
        lat,
        lng
      },
      destination: {
        query: `${estabelecimento.bairro}, ${estabelecimento.logradouro}, ${estabelecimento.numero}`
      },
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING
    });

    setDirectionsResponse(results);
    setDistance(results.routes[0].legs[0].distance.text);
    setDuration(results.routes[0].legs[0].duration.text);
  }

  function clearRoute() {
    setDirectionsResponse(null);
    setDistance('');
    setDuration('');
  }

  return lat && lng && isLoaded ? (
    <>
      <Button style={{marginBottom: 20, padding: 10}} type="button" onClick={!distance ? calculateRoute : clearRoute}>
        {!distance ? 'Mostrar Rota' : 'Ocultar Rota'}
      </Button>
      {distance && duration && <p style={{marginBottom: 10}}><b>Tempo Estimado:</b> {duration} - <b>Distância:</b> {distance}</p>}
      <GoogleMap
        options={{
          streetViewControl: false,
          zoomControl: false
        }}
        mapContainerStyle={containerStyle}
        center={{
          lat,
          lng
        }}
        zoom={15}
      >
        {!distance && <Marker
          position={{
            lat,
            lng
          }}
          title={title}
        />}
        {directionsResponse && <DirectionsRenderer directions={directionsResponse} />}
      </GoogleMap>
      </>
  ) : <></>
}

MapContainer.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  estabelecimento: PropTypes.objectOf.isRequired
};

export default memo(MapContainer)
