/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import EditIcon from '@rsuite/icons/Edit';
import TrashIcon from '@rsuite/icons/Trash';
import NoticeIcon from '@rsuite/icons/Notice';
import CheckRoundIcon from '@rsuite/icons/CheckRound';

import { Table, IconButton, Divider, ButtonToolbar } from 'rsuite';

const { Cell } = Table;

function CelulaVisualizado({ rowData, ...props}) {
    const visualizadoStatus = rowData.visualizado.some(i => i === false  )
  return (
    <Cell {...props}  style={{ padding: '5px', width: '150px'}}>
     <IconButton
                    icon={visualizadoStatus === false ? <CheckRoundIcon/> :  <NoticeIcon/>} 
                    style={{ padding: '5px', background: 'transparent'}}           
                />
    </Cell>
  );
}



export default CelulaVisualizado;
