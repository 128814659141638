import React, { useEffect, useState } from 'react';
import { Button, DatePicker, FlexboxGrid, Input, InputGroup, Table } from 'rsuite';

import { useSelector } from 'react-redux';
import { Container } from '~/styles/tabela';

import SearchInputTable from '~/components/SearchInputTable';
import { TablePagination } from '~/components/TablePagination';
import { Search } from '@rsuite/icons';
import { useDebouncedCallback } from 'use-debounce/lib';
import api from '../../../services/api';

const { HeaderCell, Column, Cell } = Table;

function ListaPopups() {
  const [pagina, setPagina] = useState(1);
  const [data, setData] = useState();
  const [title, setTitle] = useState('');
  const [mensagens, setMensagens] = useState({});
  const [textodigitado, setTextoDigitado] = useState('');
  const carregando = useSelector((state) => state.notificacao.carregando);


  useEffect(() => {
    async function getListPopups(){
      const resposta = await api.get('/popups', {
        params: {
          pagina,
          title,
          data
        }
      })
      setMensagens(resposta.data)
    }
    getListPopups()
  }, [title, data])

  return (
    <Container>
      <h2>Popups</h2>
      <FlexboxGrid align="middle" style={{ gap: '10px' }}>
           <InputGroup style={{ width: '200px' }}>
            <Input
              width={200}
              onChange={useDebouncedCallback(setTitle, 500).callback}
              placeholder="Digite o título"
            />

            <InputGroup.Button>
              <Search />
            </InputGroup.Button>
          </InputGroup>
        <DatePicker
          placeholder="DD/MM/AA"
          value={data}
          onChange={setData}
        />
      </FlexboxGrid>
      <FlexboxGrid style={{ width: '100%' }}>
        <TablePagination
          carregando={carregando}
          dados={mensagens}
          quantidade={mensagens.length}
          onChangePage={setPagina}
        >
          <Column resizable width={150}>
            <HeaderCell>Título da mensagem</HeaderCell>
            <Cell dataKey="title" />
          </Column>
          <Column resizable width={420}>
            <HeaderCell>Mensagem</HeaderCell>
            <Cell dataKey="message" />
          </Column>
        </TablePagination>
      </FlexboxGrid>
    </Container>
  );
}

export default ListaPopups;
