/* eslint-disable react/no-danger */
/* eslint-disable no-return-assign */
import React, { useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar, Divider, Form, Loader, Modal } from 'rsuite';
import { toast } from 'react-toastify';
import { ButtonFullBlue } from '../../../components/Buttons/ButtonFullBlue/styles';

import api from '../../../services/api';
import ModalBody from './ShowAnnouncement/style';

export default function Announcement({
  disabled,
  target,
  notificationCallback,
  quantidade,
  filtros,
  resumo,
  bloqueados,
}) {
  const editorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [editorContent, setEditorContent] = useState('');
  const [communicationTitle, setCommunicationTitle] = useState('');
  const [coverImageUrl, setCoverImageUrl] = useState('');
  const [cover, setCover] = useState();
  const [showModal, setShowModal] = useState(false);
  const [cidades, setCidades] = useState([]);
  const [bairros, setBairros] = useState([]);
  const [profissionais, setProfissionais] = useState([]);
  const [experiencias, setExperiencias] = useState([]);
  const [treinamentos, setTreinamentos] = useState([]);
  const [estabelecimentos, setEstabelecimentos] = useState([]);
  const [trabalhos, setTrabalhos] = useState([]);
  const [loading, setLoading] = useState(false);

  const {
    cidadesFiltradas,
    bairrosFiltrados,
    profissionaisFiltrados,
    experienciasFiltradas,
    treinamentosFiltrados,
    estabelecimentosFiltrados,
    trabalhosFiltrados,
    dataFiltrada,
    rangeAvaliacao,
    filtroPedido,
    interesses
  } = filtros;

  function handleCommunicationTitle(value) {
    setCommunicationTitle(value);
  }

  function handleCoverImage(e) {
    if (e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      const reader = new FileReader();
      setCover(e.target.files[0]);

      reader.readAsDataURL(selectedFile);

      reader.onloadend = () => {
        setCoverImageUrl(reader.result);
      };
    } else {
      setCoverImageUrl('');
    }
  }

  async function handleAddAnnouncementProf() {
    const formData = new FormData();
    formData.append('cover', cover);
    formData.append('summary', resumo);
    formData.append('title', communicationTitle);
    formData.append('body', editorRef.current.getContent());
    formData.append('cidades', cidades);
    formData.append('bairros', bairros);
    formData.append('profissionais', profissionais);
    formData.append('experiencias', experiencias);
    formData.append('treinamentos', treinamentos);
    formData.append('estabelecimentos', estabelecimentos);
    formData.append('trabalhos', trabalhos);
    formData.append('bloqueados', bloqueados);
    formData.append('data', dataFiltrada);
    formData.append('rangeAvaliacao', rangeAvaliacao);
    formData.append('interesses', interesses);
    await api
      .post(`/announcements/${target}`, formData)
      .then((res) => {
        setLoading(false);
        toast.success(res.data.message);
        notificationCallback();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.message);
      });
  }

  async function handleAddAnnouncementClient() {
    const formData = new FormData();
    formData.append('cover', cover);
    formData.append('summary', resumo);
    formData.append('title', communicationTitle);
    formData.append('body', editorRef.current.getContent());
    formData.append('cidades', cidades);
    formData.append('bairros', bairros);
    formData.append('profissionais', profissionais);
    formData.append('estabelecimentos', estabelecimentos);
    formData.append('bloqueados', bloqueados);
    formData.append('data', dataFiltrada);
    formData.append('filtroPedido', filtroPedido.value);
  
    await api
      .post(`/announcements/${target}`, formData)
      .then((res) => {
        setLoading(false);
        toast.success(res.data.message);
        notificationCallback();
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.message);
      });
  }

  return (
    <>
      <Divider>
        <h3 style={{ opacity: disabled ? 0.3 : 1 }}>Comunicado</h3>
      </Divider>
      <Form
        fluid
        onSubmit={() => {
          if (target === 'profissional') {
            setShowModal(true);
            setCidades(cidadesFiltradas.map((cidade) => cidade.id));
            setBairros(bairrosFiltrados.map((bairro) => bairro.id));
            setProfissionais(
              profissionaisFiltrados.map((profissional) => profissional.id)
            );
            setExperiencias(
              experienciasFiltradas.map((experiencia) => experiencia.id)
            );
            setTreinamentos(
              treinamentosFiltrados.map((treinamento) => treinamento.id)
            );
            setEstabelecimentos(
              estabelecimentosFiltrados.map(
                (estabelecimento) => estabelecimento.id
              )
            );
            setTrabalhos(trabalhosFiltrados.map((trabalho) => trabalho.id));
          } else if (target === 'estabelecimento') {
            setShowModal(true);
            setCidades(cidadesFiltradas.map((cidade) => cidade.id));
            setBairros(bairrosFiltrados.map((bairro) => bairro.id));
            setProfissionais(
              profissionaisFiltrados.map((profissional) => profissional.id)
            );
            setEstabelecimentos(
              estabelecimentosFiltrados.map(
                (estabelecimento) => estabelecimento.id
              )
            );
          }
        }}
      >
        <Form.Group>
          <Form.ControlLabel>
            Imagem
            <strong style={{ fontSize: 10, color: 'grey', marginLeft: 5 }}>
              Tamanho obrigatório 400x200
            </strong>
          </Form.ControlLabel>
          <input
            disabled={disabled}
            name="announcement_image"
            type="file"
            accept="image/*"
            onChange={handleCoverImage}
            required
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>Título</Form.ControlLabel>
          <Form.Control
            name="announcement_title"
            disabled={disabled}
            value={communicationTitle}
            // eslint-disable-next-line react/jsx-no-bind
            onChange={handleCommunicationTitle}
            maxlength={16}
            required
          />
        </Form.Group>
        <br />
        <ButtonToolbar>
          <ButtonFullBlue
            onClick={() => setOpen(true)}
            appearance="primary"
            disabled={disabled}
          >
            {' '}
            Preview
          </ButtonFullBlue>
        </ButtonToolbar>

        <Modal open={open} onClose={() => setOpen(false)} size="xs">
          <Modal.Header style={{ textAlign: 'center' }}>
            <Modal.Title>{communicationTitle}</Modal.Title>
          </Modal.Header>
          <ModalBody
            style={{
              textAlign: 'center',
              height: 500,
              fontSize: '16px',
              wordWrap: 'break-word',
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: `<img style='width: 100%; height: 200px; margin-bottom: 20px;'
                          src='${coverImageUrl}' alt='Imagem do Comunicado'>
                          ${editorContent}`,
              }}
            />
          </ModalBody>
          <Modal.Footer>
            <ButtonFullBlue onClick={() => setOpen(false)} appearance="primary">
              Fechar
            </ButtonFullBlue>
          </Modal.Footer>
        </Modal>
        <br />
        <Editor
          onInit={(evt, editor) => (editorRef.current = editor)}
          apiKey="pv8hnike17xcj19zp9782tyg170swsns0wxumltslkzn2j8d"
          init={{
            menubar: 'custom',
            plugins: ['emoticons', 'link'],
            height: 500,
            language: 'pt_BR',
            language_url: '/tiny_lang/pt_BR.js',
            toolbar:
              'emoticons | bold italic underline strikethrough | fontsizeselect | link',
            fontsize_formats: '16px 22px 26px',
            content_style: `
              body {text-align: center;}
             `,
            preview_styles: 'background-color',
            required: true,
            placeholder: 'Limite de 500 caracteres',
          }}
          disabled={disabled}
          onEditorChange={() => {
            if (
              editorRef.current.getContent({ format: 'text' }).length <= 500
            ) {
              setEditorContent(editorRef.current.getContent());
            } else {
              editorRef.current.setContent(editorContent);
            }
          }}
        />
        <br />
        <p>
          Caracteres disponíveis:{' '}
          {editorContent !== ''
            ? 500 - editorRef.current.getContent({ format: 'text' }).length
            : 500}
        </p>
        <br />
        <ButtonFullBlue
          block
          appearance="primary"
          type="submit"
          disabled={disabled}
        >
          Criar Comunicado
        </ButtonFullBlue>
      </Form>
      {/* eslint-disable-next-line prettier/prettier */}
      { }

      <Modal
        backdrop="static"
        open={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        size="xs"
      >
        <Modal.Header>
          <h3>Enviar Comunicado</h3>
        </Modal.Header>
        <Modal.Body>
          {target === 'profissional' ? (
            <p>Comunicado será enviado para {quantidade} usuários</p>
          ) : (
            <p>Comunicado será enviado para {quantidade} clientes</p>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={() => {
              setShowModal(false);
            }}
            appearance="subtle"
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            appearance="primary"
            onClick={() => {
              if (target === 'profissional') {
                handleAddAnnouncementProf();
              } else if (target === 'estabelecimento') {
                handleAddAnnouncementClient();
              }
              setShowModal(false);
              setLoading(true);
            }}
          >
            Enviar
          </Button>
        </Modal.Footer>
      </Modal>

      {loading && <Loader center backdrop />}
    </>
  );
}

Announcement.propTypes = {
  disabled: PropTypes.bool.isRequired,
  target: PropTypes.string.isRequired,
  notificationCallback: PropTypes.func.isRequired,
  quantidade: PropTypes.number.isRequired,
  filtros: PropTypes.objectOf.isRequired,
  resumo: PropTypes.string.isRequired,
  bloqueados: PropTypes.string.isRequired,
};
