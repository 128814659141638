import styled, { css } from "styled-components";
import theme from "~/styles/theme";


export const Card = styled.div`
    display: flex;
    flex-direction: column;
    width: 350px;
    height: 500px;
    border-bottom-left-radius: 4vh;
    border-bottom-right-radius: 4vh;
    margin-left: ${(props) => props.leftMargin || '0px'};
    overflow-y: scroll;
    

`

export const Container = styled.div`
    display: flex;
    width: 100%;
`

export const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;

`

export const ColContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: ${(props) => props.leftMargin || '0px'};
`

export const Header = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid ${theme.darkGray};
    border-top-left-radius: 4vh;
    border-top-right-radius: 4vh;
    background-color: ${(props) => props.color || 'black'};
    width: 100%;
    height: 50px;
    overflow: hidden;
    
    

    h6 {
        color: ${(props) => props.textColor || 'white'};
        font-size: 14px;
        margin-left: 8px;
    }
`

export const InfoDiv = styled.div`
    display: flex;
    cursor: grab;
    padding: 6px;
    justify-content: space-between;
    width: 100%;
    border-bottom: 0.1px solid rgba(206, 211, 224, 1);
    flex-direction: row;
    height: 102px;

    ${props => props.isDragging && css`
        border: 2px dashed rgba(0,0,0, 0.6);
        background: transparent;
        cursor: grabbing;
        box-shadow: none;
        `}

    

`

export const TextDiv = styled.div`
    display: flex;
    width: 100%;
    align-items: ${(props) => props.alignItems};
    flex-direction: column;
    justify-content: ${(props) => props.justify};
    margin-left: ${(props) => props.marginLeft};

    h6 {
        font-size: 13px;
    }

    p {
        font-size: 13px;
        margin: 0px !important;
    }

    ${props => props.isDragging && css`
        h6, p {
            opacity: 0;
        }
    `}


`


