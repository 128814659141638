import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  Container,
  Grid,
  Col,
  Panel,
  Button,
  ButtonToolbar,
  Form,
  Schema,
  Loader,
} from 'rsuite';
import api from '~/services/api';

const { StringType } = Schema.Types;

const model = Schema.Model({
  senha: StringType()
    .minLength(6, 'Tamanho mínimo de 6 caracteres')
    .isRequired('Campo obrigatório'),
  senha_conf: StringType()
    .addRule((valor, dados) => {
      if (valor !== dados.senha) return false;
      return true;
    }, 'Senhas devem ser iguais')
    .isRequired('Campo obrigatório'),
});

function RecuperarSenha() {
  const { tipo } = useParams();
  const query = new URLSearchParams(useLocation().search);
  const [carregando, setCarregando] = useState(false);
  const [camposInput, setCamposInput] = useState({
    novaSenha: '',
    novaSenhaConf: '',
  });

  useEffect(() => {
    if (!query.get('token')) {
      toast.error('Token não encontrado, favor conferir o link');
    }
    if (tipo !== 'profissional' && tipo !== 'estabelecimento') {
      toast.error('url está incorreta, favor entrar em contato com a switch');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function alterarSenha() {
    setCarregando(true);
    try {
      await api.put(`recuperar-senha/${tipo}?token=${query.get('token')}`, {
        senha: camposInput.senha,
      });
      setCamposInput({});
      toast.success('Senha atualizada com sucesso');
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
  }

  return (
    <div className="show-fake-browser login-page">
      <Container>
        <Grid>
          <Col mdOffset={6} md={12}>
            <Panel header={<h3>Alterar Senha</h3>} bordered>
              <Form
                fluid
                model={model}
                formValue={camposInput}
                onChange={setCamposInput}
                onSubmit={(semErro) => semErro && alterarSenha()}
              >
                <Form.Group>
                  <Form.ControlLabel>Nova Senha</Form.ControlLabel>
                  <Form.Control
                    name="senha"
                    type="password"
                    value={camposInput.senha || ''}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>
                    Confirmacao da Nova Senha
                  </Form.ControlLabel>
                  <Form.Control
                    name="senha_conf"
                    type="password"
                    value={camposInput.senha_conf || ''}
                  />
                </Form.Group>
                <ButtonToolbar>
                  <Button appearance="primary" type="submit" block>
                    Salvar
                  </Button>
                </ButtonToolbar>
                {carregando && (
                  <Loader backdrop content="loading..." vertical />
                )}
              </Form>
            </Panel>
          </Col>
        </Grid>
      </Container>
    </div>
  );
}

export default RecuperarSenha;
