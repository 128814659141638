/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';

import PropTypes from 'prop-types';
import { Tabela } from './styles';
import { Container } from '../styles';

export function RegistrosTable(props) {
  const { lista } = props;
  return (
    <Container>
      <Tabela>
        <caption>Registros</caption>
        <thead>
          <tr>
            <th>Id</th>
            <th>Status</th>
            <th>Data</th>
            <th>Início</th>
            <th>Fim</th>
            <th>Profissional</th>
            <th>Profissão</th>
            <th>T.Estado</th>
            <th>T.Dinâmica</th>
            <th>Valor</th>
            <th>Valor Bruto</th>
          </tr>
        </thead>
        <tbody>
          {lista &&
            lista.length > 0 &&
            lista.map((log, index) => (
              <tr key={index}>
                <td>{log?.id}</td>
                <td>{log.status}</td>
                <td>{log.data}</td>
                <td>{log.hora_inicio}</td>
                <td>{log.hora_fim}</td>
                <td>{log.pro}</td>
                <td>{log.prof}</td>
                <td>{log.tarifa_estado}</td>
                <td>{log.tarifa_dinamica}</td>
                <td>{log.valor}</td>
                <td>{log.valor_bruto}</td>
              </tr>
            ))}
        </tbody>
      </Tabela>
    </Container>
  );
}

RegistrosTable.propTypes = {
  lista: PropTypes.arrayOf.isRequired,
};
