/* eslint-disable react/prop-types */
import React from 'react'
import './ContextMenu.css'


const ContextMenu = ({isToggled, itemClickDireito, positionX, positionY, contextMenuRef, buttons}) => {
  return (
   
   
    <menu ref={contextMenuRef} style={{top: `${positionY + 2  }px`, left: `${positionX + 2}px`}} className={`context-menu ${isToggled ? 'active' : ''}`}>
      {buttons.map((button, index)=> {
          function handleClick(e) {
            e.stopPropagation()
            button.onClick(e, itemClickDireito)
          }

          return (
            // eslint-disable-next-line react/button-has-type, react/no-array-index-key
            <button onClick={(e) => handleClick(e)} key={index} className='context-menu-button'>{button.text}</button>
          )
      })}
    </menu>
  )
}


export default ContextMenu