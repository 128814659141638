/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { startOfDay, endOfDay, eachDayOfInterval } from 'date-fns';
import {
  Form,
  Panel,
  Schema,
  Button,
  Divider,
  DateRangePicker,
  Input,
} from 'rsuite';
import { toast } from 'react-toastify';
import api from '~/services/api';
import * as Styled from './styles';
import { ResponsiveFormTextArea } from '../../../components/ResponsiveForm';
import { InputDataRange } from '../../../components/InputDataRange';

const { StringType } = Schema.Types;

function EnviarPopUpProfissional() {
  const [camposInput, setCamposInput] = useState({});
  const [data, setData] = useState([]);


  const [disableCommunication, setDisableCommunication] = useState(true);

  const modelo = Schema.Model({
    titulo: StringType().isRequired('Título é obrigatório'),
    mensagem: StringType().isRequired('Mensagem é obrigatória'),
  });

  async function enviarNotificacoesPopup() {
    let resposta = {}
    try {
      const body = {
        data,
        title: camposInput.titulo,
        message: camposInput.mensagem,
      }
      resposta = await api.post('/popups', body)
      setCamposInput({})
      setData([])
      toast.success('Mensagem popup criada com sucesso');
    } catch (e) {
      if(e.message === 'Erro') {
        toast.error('Já existe um pop up cadastrado com esse título neste período.');
      } else {
        setData([])
        toast.error(e.message);
      }
    }
  }


  return (
    <Styled.Container>
      <Styled.Content>
      <Panel
          header={<h3>Popup de aviso home</h3>}
          shaded
          style={{width: '800px'}}
        >
          <div style={{gap: '5px', display: 'flex', flexDirection: 'column'}}>
          <label htmlFor="data"><strong>Data que a popup fica visível</strong></label>
          <InputDataRange style={{width: '100px'}} id='data' ranges={[]} value={data && data} onChange={setData} />
          </div>

          <Form
            model={modelo}
            onChange={setCamposInput}
            formValue={camposInput}
            onSubmit={enviarNotificacoesPopup}
            style={{marginTop: '20px'}}
          >
            <Form.Group>
              <Form.ControlLabel>Título</Form.ControlLabel>
              <Form.Control
              name="titulo"
              value={camposInput.titulo || ''}
              />
            </Form.Group>
            <ResponsiveFormTextArea
            style={{width: '100px'}}
            label="Mensagem"
            name="mensagem"
            maxLength="500"
            value={camposInput.mensagem || ''}
            placeholder='Limite de 500 caracteres'
            rows={8}
            />

        <Button
            type="submit"
            appearance="primary"
          >
            Enviar
          </Button>
          </Form>
          </Panel>

      </Styled.Content>
    </Styled.Container>
  );
}

export default EnviarPopUpProfissional;
