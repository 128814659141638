/* eslint-disable no-nested-ternary */

import React, { useState, useEffect } from 'react'
import { MdOutlineBlock } from "react-icons/md";
import {
    Modal,
    Button,
    ButtonToolbar,
    Loader
} from 'rsuite';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import {FaTrophy}  from 'react-icons/fa';
import api from '~/services/api';
import { Container, TextDiv } from './styles';




function FavoritarBloquearDialog({ exibir, onFechar, registro, estabelecimento, dadosEstabelecimento }) {

    const [profFavorito, setProfFavorito] = useState(false)
    const [profBloqueado, setProfBloqueado] = useState(false)
    const [carregando, setCarregando] = useState(false);
    const [finishedTimeout, setFinishedTimeout] = useState(false);
    const idProfissional = registro.profissional ? registro.profissional.id : ''
    const idEstabelecimento = dadosEstabelecimento ? dadosEstabelecimento.id : ''



    async function verificarStatus() {
        if(registro.profissional != null && registro.profissional.id !== null && idProfissional !== '' && idEstabelecimento !== ''){
        try {

            
                const response = await api.post(`relatorios/carteira-clientes/verificarStatus`, {
                    params: {
                        idProfissional,
                        idEstabelecimento
                    },
                });
    
    
            
                setProfFavorito(response.data.favorito)
                setProfBloqueado(response.data.bloqueado)
            
            

        } catch (error) {
            toast.error(error.message)
        }
        }else {
            // eslint-disable-next-line no-useless-return
            return;
        }
    }

    useEffect(() => {
        if (!finishedTimeout) {
            setTimeout(() => {
                setFinishedTimeout(true);
            }, 2000);
            return
        }

        verificarStatus()
    }, [verificarStatus]);




    async function favoritarProfissional() {
        setCarregando(true);
        try {

            // eslint-disable-next-line no-unused-expressions
            profBloqueado &&
                (await api.post('relatorios/carteira-clientes/bloquear', {
                    params: {
                        idProfissional,
                        idEstabelecimento,
                    },
                }));
            await api.post('relatorios/carteira-clientes/favoritar', {
                params: {
                    idProfissional: registro.profissional.id,
                    idEstabelecimento: dadosEstabelecimento.id,
                },
            });
            toast.success('Profissional favoritado com sucesso!');
        } catch (error) {
            toast.error(error.message);
        }
        setCarregando(false);
        verificarStatus()

    }

    async function bloquearProfissional() {
        setCarregando(true);
        try {
            // eslint-disable-next-line no-unused-expressions
            profFavorito &&
                (await api.post('relatorios/carteira-clientes/favoritar', {
                    params: {
                        idProfissional,
                        idEstabelecimento
                    },
                }));
            await api.post('relatorios/carteira-clientes/bloquear', {
                params: {
                    idProfissional: registro.profissional.id,
                    idEstabelecimento: dadosEstabelecimento.id,
                },
            });
            toast.success('Status atualizado com sucesso!');
        } catch (error) {
            toast.error(error.message);
        }
        setCarregando(false);
        verificarStatus()
    }

    function onClose() {
        onFechar();
        setProfFavorito(false)
        setProfBloqueado(false)
    }


    return (
        <Modal open={exibir} onClose={onFechar} size="sm">
            <Modal.Body>

                <Container>
                    <TextDiv>
                        <h6>Status Atual do Profissional:</h6>

                        {carregando ? <Loader /> : (
                            <>
                                {profFavorito === true && (
                                    <p style={{ color: 'green' }}>Favorito</p>
                                )}
                                {profBloqueado === true && (
                                    <p style={{ color: 'red' }}>Bloqueado</p>
                                )}
                                {profBloqueado === false && profFavorito === false && (
                                    <p>Nenhum</p>
                                )}
                            </>
                        )
                    }


                    </TextDiv>
                    <TextDiv>
                        <h5>Profissional:</h5>
                        {registro.profissional && (
                            <p>{registro.profissional.nome}</p>
                        )}
                    </TextDiv>
                    <TextDiv>
                        <h5>Estabelecimento:</h5>

                        {estabelecimento && (
                            <p>{estabelecimento}</p>
                        )}

                    </TextDiv>

                </Container>

                <ButtonToolbar style={{ display: 'flex', gap: '14px', justifyContent: 'center', marginTop: '18px' }}>
                    <Button color='green' appearance="primary" style={{ width: "150px", height: '70px' }} startIcon={<FaTrophy />} onClick={async () => favoritarProfissional()} loading={carregando} disabled={carregando}>
                        Favoritar
                    </Button>
                    <Button appearance="primary" color='red' style={{ width: "150px", height: '70px' }} startIcon={<MdOutlineBlock />} loading={carregando} disabled={carregando} onClick={async () => bloquearProfissional()}>Bloquear</Button>
                </ButtonToolbar>






            </Modal.Body>





            <Modal.Footer style={{ marginTop: '20px' }}>
                <Button onClick={() => onClose()} appearance="subtle">
                    Fechar
                </Button>

            </Modal.Footer>
        </Modal>
    )
}

FavoritarBloquearDialog.propTypes = {
    exibir: PropTypes.bool.isRequired,
    onFechar: PropTypes.func.isRequired,
    // eslint-disable-next-line react/require-default-props
    estabelecimento: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    registro: PropTypes.object.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    dadosEstabelecimento: PropTypes.object.isRequired

}

export default FavoritarBloquearDialog