/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { Form, Schema, Button, Modal, SelectPicker } from 'rsuite';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import api from '~/services/api';

import InputMascara from '~/components/InputMascara';
import { mascaraCpfCnpj } from '~/components/Mascaras';
import { ButtonFullBlue } from '../../../../components/Buttons/ButtonFullBlue/styles';

const { NumberType, StringType } = Schema.Types;

const modelo = Schema.Model({
  conta: NumberType().isRequired('Cliente Obrigatório'),
  digito: NumberType()
    .max(9, 'Apenas 1 digito')
    .isRequired('Dígito Obrigatório'),
  cpf_cnpj: StringType().minLength(14, 'Cpf/Cnpj incompleto'),
  agencia: NumberType().isRequired('Agência Obrigatório'),
  banco: StringType().isRequired('Banco Obrigatório'),
  tipo_conta: StringType().isRequired('Tipo Conta Obrigatório'),
});

function AdicionarConta({ exibir, fechar, salvar }) {
  const [camposInput, setCamposInput] = useState({});
  const [bancos, setBancos] = useState([]);

  useEffect(() => {
    const buscarBancos = async () => {
      try {
        const resposta = await api.get('/bancos');
        setBancos(resposta.data);
      } catch (e) {
        toast.error(e.message);
      }
    };

    buscarBancos();
  }, []);

  async function salvarConta() {
    salvar(camposInput);
    setCamposInput({});
  }

  return (
    <Modal open={exibir} onClose={() => fechar()} size="xs">
      <Form
        fluid
        model={modelo}
        onChange={setCamposInput}
        formDefaultValue={camposInput}
        onSubmit={(erro) => erro && salvarConta()}
      >
        <Modal.Header>
          <Modal.Title>Adicionar Conta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ display: 'flex' }}>
            <Form.Group style={{ flexGrow: '1' }}>
              <Form.ControlLabel>Conta</Form.ControlLabel>
              <Form.Control name="conta" />
            </Form.Group>
            <Form.Group style={{ width: '130px', marginLeft: '10px' }}>
              <Form.ControlLabel>Digito</Form.ControlLabel>
              <Form.Control name="digito" />
            </Form.Group>
          </div>

          <Form.Group>
            <Form.ControlLabel>Cpf/Cnpj</Form.ControlLabel>
            <Form.Control
              name="cpf_cnpj"
              accepter={InputMascara}
              mask={mascaraCpfCnpj}
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>agencia</Form.ControlLabel>
            <Form.Control name="agencia" />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>Banco</Form.ControlLabel>
            <Form.Control
              name="banco"
              accepter={SelectPicker}
              placeholder="Selecione"
              block
              data={bancos.map((b) => {
                return {
                  label: `${b.Code} - ${b.Name}`,
                  value: b.Code,
                };
              })}
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>Tipo de Conta</Form.ControlLabel>
            <Form.Control
              name="tipo_conta"
              accepter={SelectPicker}
              block
              placeholder="Selecione"
              searchable={false}
              data={[
                {
                  label: 'Conta Poupança',
                  value: 'Conta Poupança',
                },
                {
                  label: 'Conta Corrente',
                  value: 'Conta Corrente',
                },
              ]}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => fechar()} appearance="subtle">
            Cancelar
          </Button>
          <ButtonFullBlue type="submit" appearance="primary">
            Confirmar
          </ButtonFullBlue>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

AdicionarConta.propTypes = {
  exibir: PropTypes.bool.isRequired,
  fechar: PropTypes.func.isRequired,
  salvar: PropTypes.func.isRequired,
};

export default AdicionarConta;
