/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import { Table, FlexboxGrid, Schema } from 'rsuite';
import { toast } from 'react-toastify';
import { Plus } from '@rsuite/icons';

import { Container } from '~/styles/tabela';

import api from '~/services/api';
import {
  CelulaEditavel,
  CelulaAcao,
} from '~/components/Celulas/components/CelulaEditavel';
import { ModalIcon } from '~/components/ModalCriacao/ModalIcon';
import { Modal2 } from '~/components/ModalCriacao/Modal2';
import { Modal1 } from '~/components/ModalCriacao/Modal1';
import { TabelaSemPaginacao } from '~/components/TabelaSemPaginacao';
import SearchTable from '../components/SearchTable';

const { HeaderCell, Column } = Table;

const { StringType } = Schema.Types;

const modelo = Schema.Model({
  descricao: StringType().isRequired('Descrição Obrigatório'),
});

function QuantidadeColaboradoresOpcoes() {
  const [dados, setDados] = useState({
    quantidadeLista: [],
    quantidadeListaFiltrada: [],
    filtro: '',
    objeto: {},
  });
  const [apagarQuantidadeDialog, setApagarQuantidadeDialog] = useState({
    exibir: false,
    item: {},
  });
  const [carregando, setCarregando] = useState(true);
  const [adicionarQuantidadeDialog, setAdicionarQuantidadeDialog] = useState(false);
  const [camposInput, setCamposInput] = useState({});

  async function buscarQuantidades() {
    const resposta = await api.get('qtd-colaboradores');
    const resposta1 = await api.get('qtd-colaboradores');
    const lista = resposta.data.quantidades
    setDados({
      quantidadeLista: lista,
      quantidadeListaFiltrada: lista,
      listaInicial: resposta1.data.quantidades,
    });
    setCarregando(false);
  }

  useEffect(() => {
    buscarQuantidades();
  }, []);

  function filtrarLista(texto) {
    setDados({
      ...dados,
      quantidadeListaFiltrada: dados.quantidadeLista.filter((descricao) =>
        descricao.quantidade
          .toString()
          .toUpperCase()
          .includes(texto ? texto.toUpperCase() : '')
      ),
      filtro: texto,
    });
  }

  function abrirApagarDialog(item) {
    setApagarQuantidadeDialog({
      exibir: true,
      item,
    });
  }

  function fecharApagarDialog() {
    setApagarQuantidadeDialog({
      exibir: false,
      item: {},
    });
  }

  async function excluirQuantidade() {
    try {
      await api.delete(`/deletar-qtdcolaborador/${apagarQuantidadeDialog.item.id}`);
      toast.success('Quantidade excluida com sucesso');
      fecharApagarDialog();
      buscarQuantidades();
    } catch (e) {
      toast.error(e.message);
    }
  }

  async function adicionarQuantidade() {
    try {
      setCarregando(true);
      await api.post(`/criar-qtdcolaborador`, camposInput);
      toast.success('Quantidade criada com sucesso');
      setCarregando(false);
      setAdicionarQuantidadeDialog(false);
      buscarQuantidades();
    } catch (e) {
      toast.error(e.message);
    }
  }

  function alterarTextoCelula(id, key, value) {
    const nextData = Object.assign([], dados.quantidadeLista);
    nextData.find((item) => item.id === id)[key] = value;
    setDados({ ...dados, quantidadeLista: nextData });
    filtrarLista(dados.filtro);
  }

  async function alterarEstadoCelula(id) {
    const novaLista = Object.assign([], dados.quantidadeLista);
    const item = novaLista.find((descricao) => descricao.id === id);
    const itemIncial = dados.listaInicial.find((descricao) => descricao.id === id);
    if (item.status === 'EDIT' && item.quantidade !== itemIncial.quantidade) {
      try {
        setCarregando(true);
        await api.put(`alterar-qtdcolaborador/${id}`, item);
        toast.success('Quantidade atualizada com sucesso');
        buscarQuantidades();
      } catch (error) {
        toast.error(error.message);
      }
      setCarregando(false);
    } else {
      item.status = item.status ? null : 'EDIT';
      setDados({ ...dados, quantidadeLista: novaLista });
      filtrarLista(dados.filtro);
    }
  }

  return (
    <Container>
      <h2>Quantidade de Colaboradores</h2>
      <FlexboxGrid justify="start" style={{ gap: '10px' }}>
        <SearchTable estilo={{ width: '300px' }} filtrarLista={filtrarLista} />
        <ModalIcon
          style={{ marginTop: '0' }}
          icon={<Plus />}
          onClick={() => {
            setAdicionarQuantidadeDialog(true);
          }}
        />
      </FlexboxGrid>
      <FlexboxGrid style={{ width: '100%', marginTop: 10 }}>
        <TabelaSemPaginacao
          carregando={carregando}
          dados={dados.quantidadeListaFiltrada}
        >
          <Column resizable width={240} sortable>
            <HeaderCell>Nome</HeaderCell>
            <CelulaEditavel dataKey="quantidade" onChange={alterarTextoCelula} />
          </Column>

          <Column resizable width={200}>
            <HeaderCell>Editar</HeaderCell>
            <CelulaAcao
              dataKey="id"
              onClick={alterarEstadoCelula}
              onDelete={abrirApagarDialog}
            />
          </Column>
        </TabelaSemPaginacao>
      </FlexboxGrid>

      <Modal1
        show={adicionarQuantidadeDialog}
        onHide={() => setAdicionarQuantidadeDialog(false)}
        title="Adicionar Quantidade"
        label="Quantidade"
        name="quantidade"
        model={modelo}
        onSubmit={() => adicionarQuantidade()}
        onChange={setCamposInput}
        onClick={() => setAdicionarQuantidadeDialog(false)}
      />

      <Modal2
        show={apagarQuantidadeDialog.exibir}
        onHide={fecharApagarDialog}
        item="Quantidade"
        title="Apagar Quantidade"
        text="Você realmente deseja apagar esta Quantidade de Colaborador?"
        apagarId={apagarQuantidadeDialog.item.id}
        apagarDado={apagarQuantidadeDialog.item.quantidade}
        fecharApagarDialog={fecharApagarDialog}
        excluir={() => excluirQuantidade()}
      />
    </Container>
  );
}

export default QuantidadeColaboradoresOpcoes;
