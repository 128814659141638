import styled, { css } from 'styled-components';
import theme from '~/styles/theme';

export const FullInput = styled.div`
  .rs-auto-complete {
    width: 100% !important;
  }
  .rs-form-control-wrapper {
    width: 100% !important;
  }
`;

export const DivToggles = styled.div`
  display: ${(props) =>
    props.filtroSelecionado === 'filtrados' ? 'none' : 'flex'};
  flex-direction: column;
  align-self: center;
  padding-left: 5px;
  height: 36px;
  justify-content: space-around;
  .rs-btn-toggle {
    background-color: ${theme.primaryColor};
  }
`;

export const StyledTogglesFav = styled.div`
  display: ${(props) =>
    props.filtroSelecionado === 'filtrados' ? 'none' : 'flex'};
  flex-direction: column;
  align-self: center;
  padding-left: 5px;
  height: 36px;
  justify-content: space-around;
  .rs-btn-toggle {
    background-color: ${theme.primaryColor};
  }

  .rs-toggle-checked .rs-toggle-presentation {
    background-color: ${theme.darkGreen};
  }
  .rs-toggle-presentation {
    background-color: ${theme.lightGray};
  }
`;

export const StyledTogglesBloq = styled.div`
  display: ${(props) =>
    props.filtroSelecionado === 'filtrados' ? 'none' : 'flex'};
  flex-direction: column;
  align-self: center;
  padding-left: 5px;
  height: 36px;
  justify-content: space-around;
  .rs-btn-toggle {
    background-color: ${theme.primaryColor};
  }

  .rs-toggle-checked .rs-toggle-presentation {
    background-color: ${theme.darkRed};
  }
  .rs-toggle-presentation {
    background-color: ${theme.lightGray};
  }
`;

export const TogglesFav = styled.div`
  .rs-toggle-checked .rs-toggle-presentation {
    background-color: ${theme.darkGreen};
  }
  .rs-toggle-presentation {
    background-color: ${theme.lightGray};
  }
`;

export const TogglesBloq = styled.div`
  .rs-toggle-checked .rs-toggle-presentation {
    background-color: ${theme.darkRed};
  }
  ${props => props.darkMode ? css`
.rs-toggle-presentation {
      background: ${theme.darkGray};
  }` : css`
  .rs-toggle-presentation {
  background: ${theme.lightGray};
  }`}
`;
