/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-inner-declarations */
import React, { useEffect, useState, useCallback } from 'react';
import {
  Table,
  FlexboxGrid,
  SelectPicker,
  Col,
  Loader,
  CheckPicker,
  Toggle,
} from 'rsuite';
import { toast } from 'react-toastify';
import { eachDayOfInterval, format, parseISO } from 'date-fns';
import { Container } from '~/styles/tabela';
import SearchInputTable from '~/components/SearchInputTable';
import api from '~/services/api';
import { TablePagination } from '~/components/TablePagination';
import CelulaAcao from '~/components/Celulas/components/CelulaAcao';
import { CardConfirmacao } from './components/CardConfirmacao';
import EditarPedido from '../EditarPedido';

import { Row, ToggleRetorno } from './styles';
import { InputDataRange } from '../../../components/InputDataRange';
import ModalEnviarNotificacao from './ModalEnviarNotificacao';

const { HeaderCell, Column, Cell } = Table;

function RetornoConfirmacoes() {
  const [filtroData, setFiltroData] = useState();
  const [datas, setDatas] = useState();
  const [confirmacoes, setConfirmacoes] = useState({
    lista: [],
    quantidade: 0,
  });
  const [confirmacoesFeitasOuNaoFeitas, setConfirmacoesFeitasOuNaoFeitas] = useState()
  const [toDoOrDone, setToDoOrDone] = useState(0);
  const [pagina, setPagina] = useState(1);
  const [carregando, setCarregando] = useState(false);
  const [estados, setEstados] = useState([]);
  const [estadoSelecionado, setEstadoSelecionado] = useState();
  const [estabDigitado, setEstabDigitado] = useState();
  const [proDigitado, setProDigitado] = useState();

  const [profissoes, setProfissoes] = useState([]);

  const [servicoSelecionado, setServicoSelecionado] = useState([]);
  const [pedidoEditando, setPedidoEditando] = useState();
  const [filtroCidades, setFiltroCidades] = useState();
  const [showEditarPedido, setShowEditarPedido] = useState(false);

  const locale = {
    sunday: 'Dom',
    monday: 'Seg',
    tuesday: 'Ter',
    wednesday: 'Qua',
    thursday: 'Qui',
    friday: 'Sex',
    saturday: 'Sab',
    ok: 'Ok',
    today: 'Hoje',
    yesterday: 'Ontem',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos',
  };

  const [orderBy, setOrderBy] = useState('')
    // 'order by reg.hora_inicio asc, pro.nome asc'

  const buscarRelatorios = useCallback(async () => {
    setCarregando(true);
    try {
      const resposta = await api.get('registros/service-confirmations', {
        params: {
          pagina,
          orderBy,
          data: datas,
          estado: estadoSelecionado,
          profissional: proDigitado,
          estabelecimento: estabDigitado,
          cidade: filtroCidades,
          servicos: servicoSelecionado,
          pro_confirmation: toDoOrDone,
        },

      });

      setConfirmacoesFeitasOuNaoFeitas(resposta.data.confirmations)


        const listaFormatada = resposta.data.lista.map((registros) => ({
          ...registros,
          nome: registros.profissional && registros.profissional.nome,
          nome_fantasia: registros.pedido.estabelecimento.nome_fantasia,
          profissao: registros.profissao.profissao,
          data: format(parseISO(registros.data), 'dd/LL/yyyy'),
          inicio: format(new Date(registros.hora_inicio), 'HH:mm'),
          fim: format(new Date(registros.hora_fim), 'HH:mm'),
          cidade: registros.endereco.bairro.cidade.cidade,
          estado: registros.endereco.bairro.cidade.estado.uf,
          pro_confirmation: registros.pro_confirmation,
          id_pedido: registros.pedido.id,
        }));

        let resulServise = [];
        if (resposta.data.lista && resposta.data.lista.length > 0) {
          resulServise = resposta.data.lista && resposta.data.lista.map((servico) => ({
            label: servico.profissao.profissao,
            value: servico.profissao.id,
          }));
        }

        console.log(resposta.data.versao)

        setConfirmacoes({
          lista: listaFormatada,
          quantidade: resposta.data.quantidade,
          servico: resulServise,
        })
        if(confirmacoes.quantidade > 0 && confirmacoes.lista.length === 0) setPagina(1)


      const estados1 = await api.get('estado');
      setEstados(estados1.data);
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
  }, [
    pagina,
    datas,
    estadoSelecionado,
    toDoOrDone,
    estabDigitado,
    proDigitado,
    servicoSelecionado,
    filtroCidades,
    orderBy,
    confirmacoes.quantidade,
    confirmacoes.lista.length,
    setPagina
  ]);

  useEffect(() => {
    buscarRelatorios();
  }, [buscarRelatorios]);

  useEffect(() => {
    const buscarDadosApi = async () => {
      try {
        const resposta = await api.get('profissao');
        setProfissoes(resposta.data);
      } catch (error) {
        toast.error(error.message);
      }
    };

    buscarDadosApi();
  }, []);

  function abrirEditarPedido(pedido) {
    setPedidoEditando(pedido);
    setShowEditarPedido(true);
  }

  function fecharEditarPedido() {
    buscarRelatorios();
    setShowEditarPedido(false);
  }


  async function alteracaoRetorno(e, value) {
    try {
      const returnValue2 = {
        pro_confirmation: !value
      }
      setCarregando(true);
      await api.patch(`registros/service-confirmations/${e.id}`, returnValue2)
      setCarregando(false);
      buscarRelatorios()
      toast.success('Confirmação atualizada com sucesso');
    } catch (err) {
      toast.error(err.message);
    }
  }

  const CelulaAcaoRetorno = ({ rowData, ...props }) => (
    <Cell {...props}>
      <ToggleRetorno>
        <Toggle
          checkedChildren="Feito" unCheckedChildren="A Fazer"
          defaultChecked={rowData.pro_confirmation}
          onChange={() => alteracaoRetorno(rowData, rowData.pro_confirmation)}
        /></ToggleRetorno>
    </Cell>
  );
  return (
    <Container>
      <h4 style={{marginBottom: '10px'}}>Confirmações dos Serviços</h4>
      <FlexboxGrid style={{ gap: '10px' }} justify="start">
        <FlexboxGrid.Item as={Col} col={4}>
          <InputDataRange
            // cleanable={false}
            style={{ marginRight: "0px" }}
            ranges={[]}
            isoWeek
            // oneTap
            locale={locale}
            value={datas}
            onChange={setDatas}
          />
        </FlexboxGrid.Item>

        <FlexboxGrid.Item as={Col} col={4}>
          <SelectPicker
            appearance="default"
            // style={{ marginRight: '15px' }}
            placeholder="Estado"
            data={estados.map((estado) => ({
              label: estado.uf,
              value: estado.id,
            }))}
            value={estadoSelecionado}
            onChange={(value) => setEstadoSelecionado(value)}
          />
        </FlexboxGrid.Item>
        <SearchInputTable
          setTextoDigitado={setProDigitado}
          place="Profissional"
          estilo={{
            width: '200px',
          }}
        />
        <SearchInputTable
          setTextoDigitado={setEstabDigitado}
          estilo={{
            width: '200px',
          }}
          place="Estabelecimento"
        />
        <SearchInputTable
          setTextoDigitado={setFiltroCidades}
          estilo={{
            width: '200px',
          }}
          place="Cidade"
        />
        <CheckPicker
          style={{ width: 193 }}
          appearance="default"
          placeholder="Serviços"
          data={profissoes.map((p) => {
            return {
              label: p.profissao,
              value: p.id,
            };
          })}
          value={servicoSelecionado}
          onChange={(value) => setServicoSelecionado(value)}
        />
            <ModalEnviarNotificacao/>
        </FlexboxGrid>
        <FlexboxGrid>
        <FlexboxGrid.Item as={Col} col={16}>
          <Row style={{width: '246px'}}>
              <CardConfirmacao
                done={confirmacoesFeitasOuNaoFeitas && confirmacoesFeitasOuNaoFeitas[0].done || 0}
                toDo={confirmacoesFeitasOuNaoFeitas && confirmacoesFeitasOuNaoFeitas[0].to_do || 0}
                setToDoOrDone={setToDoOrDone}
                toDoOrDone={toDoOrDone}
              />
          </Row>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <FlexboxGrid style={{ width: '100%' }}>
      <TablePagination
        carregando={carregando}
        dados={confirmacoes.lista}
        quantidade={confirmacoes.quantidade}
        onChangePage={setPagina}
        setOrderBy={setOrderBy}
      >
        <Column resizable width={60}>
          <HeaderCell>Editar</HeaderCell>
          <CelulaAcao
            dataKey="id_pedido"
            onEditar={(pedido) => {
              abrirEditarPedido(pedido);
            }}
          />
        </Column>
        <Column resizable width={190} sortable>
          <HeaderCell>Nome</HeaderCell>
          <Cell dataKey="nome" />
        </Column>
        <Column resizable width={150} sortable>
          <HeaderCell>Estabelecimento</HeaderCell>
          <Cell dataKey="nome_fantasia" />
        </Column>
        <Column resizable width={150} sortable>
          <HeaderCell>Serviços</HeaderCell>
          <Cell dataKey="profissao" />
        </Column>
        <Column resizable width={150} sortable>
          <HeaderCell>Data</HeaderCell>
          <Cell dataKey="data" />
        </Column>

        <Column resizable width={70}>
          <HeaderCell>Inicio</HeaderCell>
          <Cell dataKey="inicio" />
        </Column>
        <Column resizable width={100}>
          <HeaderCell>Fim</HeaderCell>
          <Cell dataKey="fim" />
        </Column>
        <Column resizable width={120} sortable>
          <HeaderCell>Cidade</HeaderCell>
          <Cell dataKey="cidade" />
        </Column>
        <Column resizable width={120} sortable>
          <HeaderCell>Estado</HeaderCell>
          <Cell dataKey="estado" />
        </Column>
        <Column resizable width={100} sortable>
          <HeaderCell>Retorno</HeaderCell>
          <CelulaAcaoRetorno dataKey="pro_confirmation" />
        </Column>

      </TablePagination>
      </FlexboxGrid>

      <EditarPedido
        idPedido={pedidoEditando && pedidoEditando.id_pedido}
        showEditarPedido={showEditarPedido}
        // codPedido={pedidoEditando && pedidoEditando.numero_pedido}
        setShowEditarPedido={setShowEditarPedido}
        fecharEditarPedido={fecharEditarPedido}
      />
    </Container>
  );
}

export default RetornoConfirmacoes;
