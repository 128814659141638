import BlockIcon from '@rsuite/icons/Block';
import React from 'react';
import { Container } from 'rsuite';

export default function NaoAutorizado() {
  return (
    <Container
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 10,
        }}
      >
        <h1>Acesso Negado!</h1>
        <h1 style={{ marginBottom: 10 }}>
          <BlockIcon />
        </h1>
      </div>
      <p>Você não tem acesso a essa área, fale com um administrador.</p>
    </Container>
  );
}
