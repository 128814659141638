/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react'
import { FlexboxGrid, Table } from 'rsuite';
import { TabelaSemPaginacao } from '~/components/TabelaSemPaginacao';
import api from '~/services/api';
import { InputDataRange } from '~/components/InputDataRange';
import { Container } from '~/styles/tabela'
import { obterExtremosMes } from '~/utils/datas';
import SearchInputTable from '~/components/SearchInputTable';
import { FiltrosDiv } from './styles';



const { HeaderCell, Column, Cell } = Table;

const LiberacaoCadastroAdmin = () => {

    const [dados, setDados] = useState([]);
    const [datas, setDatas] = useState(obterExtremosMes(new Date()));
    const [textoDigitado, setTextoDigitado] = useState('')
    const [carregando, setCarregando] = useState(false);
    const locale = {
        sunday: 'Dom',
        monday: 'Seg',
        tuesday: 'Ter',
        wednesday: 'Qua',
        thursday: 'Qui',
        friday: 'Sex',
        saturday: 'Sab',
        ok: 'Ok',
        today: 'Hoje',
        yesterday: 'Ontem',
        hours: 'Horas',
        minutes: 'Minutos',
        seconds: 'Segundos',
    };



    const buscarDadosLiberacao = useCallback(async () => {

        setCarregando(true)

        try {
            const response = await api.get('/liberacao-controle', {
                params: {
                    datas,
                    nome: textoDigitado
                }
            })

            const newLista = response.data.administradores.map((admin) => ({
                ...admin,
                nome: admin.nome === null ? 'Usuário não Especificado' : admin.nome
            }))


            setDados(newLista)


        } catch (error) {
            console.log(error)
        }

        setCarregando(false)
    }, [datas, textoDigitado])





    useEffect(() => {
        buscarDadosLiberacao();
    }, [buscarDadosLiberacao]);


    return (
        <Container>
            <h2>Controle de Liberação de Cadastro</h2>
            <FiltrosDiv>


                <SearchInputTable
                    estilo={{ marginRight: '0px', width: '300px' }}
                    setTextoDigitado={setTextoDigitado}
                />

                <InputDataRange
                    ranges={[]}
                    value={datas}
                    locale={locale}
                    onChange={setDatas}
                    style={{ width: 250, marginLeft: 15}}
                />

            </FiltrosDiv>
            <FlexboxGrid style={{ width: '100%', marginTop: 30 }}>


                <TabelaSemPaginacao
                    dados={dados}
                    carregando={carregando}
                >
                    <Column resizable width={518} sortable>
                        <HeaderCell>Nome</HeaderCell>
                        <Cell dataKey="nome" />
                    </Column>

                    <Column resizable width={518} sortable>
                        <HeaderCell>Quantidade de Liberações</HeaderCell>
                        <Cell dataKey="count" />
                    </Column>



                </TabelaSemPaginacao>


            </FlexboxGrid>
        </Container>
    )
}

export default LiberacaoCadastroAdmin
