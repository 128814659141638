import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Container,
  Grid,
  Col,
  Panel,
  Form,
  Button,
  ButtonToolbar,
  Schema,
  Loader,
} from 'rsuite';
import { logarRequesicao } from '~/store/modulos/autenticacao/actions';

const { StringType } = Schema.Types;

const model = Schema.Model({
  email: StringType().isEmail('Email inválido').isRequired('Campo obrigatório'),
  senha: StringType().isRequired('Campo obrigatório'),
});

function Login() {
  const dispatch = useDispatch();
  const [camposInput, setCamposInput] = useState({ email: null, senha: null });
  const carregando = useSelector((state) => state.autenticacao.carregando);

  function logarUsuario() {
    const { email, senha } = camposInput;
    dispatch(logarRequesicao(email, senha));
  }

  return (
    <div className="show-fake-browser login-page">
      <Container>
        <Grid>
          <Col mdOffset={6} md={12}>
            <Panel header={<h3>Login</h3>} bordered>
              <Form
                fluid
                model={model}
                onChange={setCamposInput}
                onSubmit={(semErro) => semErro && logarUsuario()}
              >
                <Form.Group>
                  <Form.ControlLabel>Email</Form.ControlLabel>
                  <Form.Control name="email" />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>Password</Form.ControlLabel>
                  <Form.Control name="senha" type="password" />
                </Form.Group>
                <ButtonToolbar>
                  <Button appearance="primary" type="submit" block>
                    Entrar
                  </Button>
                </ButtonToolbar>
                {carregando && (
                  <Loader backdrop content="loading..." vertical />
                )}
              </Form>
            </Panel>
          </Col>
        </Grid>
      </Container>
    </div>
  );
}

export default Login;
