import styled from 'styled-components';

export const Container = styled.div`
  flex-grow: 1;
  overflow: auto;

  button {
    margin-top: 30px;
    margin-left: 5px;
  }

  img {
    height: 100px;
  }
`;
