/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable  */
import React, { useState, useEffect, useContext } from 'react';
import { Form, Button, Modal, FlexboxGrid } from 'rsuite';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import api from '~/services/api';
import {
  atualizarCamposInput,
  atualizarCamposCupom,
} from '~/store/modulos/pedido/actions';
import {
  ButtonCleanRed,
  ButtonFullYellow,
  ButtonFullBlue,
} from '../../../../../components/Buttons/ButtonFullBlue/styles';
import { toast } from 'react-toastify';

function ModalCupom({ exibir, fechar }) {
  const { camposInput, cupom } = useSelector((state) => {
    return state.pedido;
  });

  const [carregando, setCarregando] = useState(false);
  const [disabled, setDisabled] = useState();
  const [cupomValido, setCupomValido] = useState('');
  const [cupomId, setCupomId] = useState(false);
  const dispatch = useDispatch();

  async function buscarCupom() {
    if (!cupom.cup || cupom?.cup?.length <= 3) {
      setCupomValido('');
      setDisabled(false);
      return;
    }
    setCarregando(true);
    try {
      const resultado = await api.get(
        `/verificar-cupom/${cupom.cup}?id_estabelecimento=${camposInput.id_estabelecimento}`
      );
      if (resultado?.data?.ativo) {
        toast.success(resultado.data.ativo);
        setCupomId(resultado.data.id);
        setDisabled(true);
        setCupomValido('valido');
      } else {
        toast.success('deu erro');
        setDisabled(false);
        setCupomValido('invalido');
      }
    } catch (error) {
      setCupomValido('invalido');
    }
    setCarregando(false);
  }

  useEffect(() => {
    buscarCupom();
  }, [cupom]);

  return (
    <Modal open={exibir} onClose={() => fechar()} size="xs">
      <Form fluid formDefaultValue={cupom}>
        <Modal.Header>
          <Modal.Title align="center"> Cupom</Modal.Title>
        </Modal.Header>
        <Modal.Body align="center">
          <FlexboxGrid colspan={24} justify="center">
            <Form.Group>
              <Form.Control
                name="cup"
                value={cupom.cup}
                onChange={(value) =>
                  dispatch(atualizarCamposCupom({ ...cupom, cup: value }))
                }
                disabled={disabled}
              />
              {cupomValido === 'valido' ? (
                <p style={{ color: 'green' }}>Cupom válido</p>
              ) : cupomValido === 'invalido' ? (
                <p style={{ color: 'red' }}>Cupom inválido</p>
              ) : (
                ''
              )}
            </Form.Group>
            <ButtonFullBlue
              style={{ color: '#FFF', marginLeft: '15px', marginTop: '3px' }}
              disabled={!disabled}
              onClick={() => {
                dispatch(
                  atualizarCamposInput({
                    ...camposInput,
                    cupom_texto: cupom.cup,
                    id_cupom: cupomId,
                  })
                );
                fechar();
              }}
            >
              Adicionar
            </ButtonFullBlue>
          </FlexboxGrid>

          <ButtonCleanRed
            style={{ width: '300px' }}
            appearance="primary"
            onClick={() => {
              setCupomValido('');
              setDisabled(false);
              dispatch(
                atualizarCamposInput({
                  ...camposInput,
                  cupom_texto: '',
                  id_cupom: null,
                })
              );
              dispatch(atualizarCamposCupom({ ...cupom, cup: '' }));
              setCupomId(null);
              fechar();
            }}
          >
            Remover
          </ButtonCleanRed>
        </Modal.Body>
      </Form>
    </Modal>
  );
}

ModalCupom.propTypes = {
  exibir: PropTypes.bool.isRequired,
  fechar: PropTypes.func.isRequired,
  salvar: PropTypes.func.isRequired,
  newEndereco: PropTypes.func.isRequired,
};

export { ModalCupom };
