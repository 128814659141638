import React, { useState } from 'react';
import { AutoComplete } from 'rsuite';

import { useDebouncedCallback } from 'use-debounce/lib';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import api from '~/services/api';

function FiltroBairros({ onItemAdicionado, setFiltroBairros }) {
  const [bairros, setBairros] = useState([]);

  const buscarEstados = useDebouncedCallback(async (texto) => {
    if(texto === '') setFiltroBairros([])
    if (texto) {
      try {
        const resposta = await api.get('bairro', {
          params: {
            nome: texto,
          },
        });
        setBairros(resposta.data);
      } catch (error) {
        toast.error(error.message);
      }
    }
  }, 500).callback;

  return (
    <AutoComplete
      data={bairros
        .map((b) => ({
          label: b.bairro,
          value: b.bairro,
          id: b.id,
        }))
        .slice(0, 10)}
      onSelect={onItemAdicionado}
      onChange={buscarEstados}
      placeholder="Bairros"
    />
  );
}

FiltroBairros.defaultProps = {
  setFiltroBairros: () => {},
}

FiltroBairros.propTypes = {
  onItemAdicionado: PropTypes.func.isRequired,
  setFiltroBairros: PropTypes.func,
};

export default FiltroBairros;
