import React, { useState } from 'react';
import { AutoComplete } from 'rsuite';
import PropTypes from 'prop-types';
import useSearchNames from '~/services/useSearchNames';

function FiltroEstabelecimentos({ onItemAdicionado, disabled }) {
  const [estabelecimentoNome, setEstabelecimentoNome] = useState([]);

  const buscarNomesEstabelecimentos = useSearchNames(
    setEstabelecimentoNome,
    'estabelecimentos'
  );

  return (
    <AutoComplete
      data={estabelecimentoNome.map((p) => ({
        label: p.nome_fantasia,
        value: p.nome_fantasia,
        id: p.id,
      }))}
      onSelect={onItemAdicionado}
      onChange={buscarNomesEstabelecimentos}
      placeholder="Estabelecimentos"
      disabled={disabled}
    />
  );
}

FiltroEstabelecimentos.propTypes = {
  onItemAdicionado: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

FiltroEstabelecimentos.defaultProps = {
  disabled: false,
};

export default FiltroEstabelecimentos;
