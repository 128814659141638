/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import { store } from '~/store';

export default function RouteWrapper({
  component: Component,
  isPrivate,
  path,
  ...rest
}) {
  const { logado } = store.getState().autenticacao;

  if (path !== '/recuperar-senha/:tipo') {
    if (!logado && isPrivate) {
      return <Redirect to="/" />;
    }

    if (logado && !isPrivate) {
      return <Redirect to="/home" />;
    }
  }

  return (
    <Route path={path} {...rest} render={(props) => <Component {...props} />} />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  path: PropTypes.string.isRequired,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
};
