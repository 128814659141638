/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import { Search } from '@rsuite/icons';

import { Table, Input, InputGroup, FlexboxGrid, SelectPicker } from 'rsuite';
import { useDebouncedCallback } from 'use-debounce/lib';
import { toast } from 'react-toastify';
import { Container } from '~/styles/tabela';
import api from '~/services/api';
import {
  CelulaEditavel,
  CelulaAcao,
} from '~/components/Celulas/components/CelulaEditavel';
import { TabelaSemPaginacao } from '~/components/TabelaSemPaginacao';

const { HeaderCell, Column, Cell } = Table;

function Estado() {
  const [dados, setDados] = useState({
    listaInicial: [],
    habilidadeLista: [],
    habilidadeListaFiltrada: [],
    profissaoLista: [],
    filtro: '',
  });

  const [newDados, setNewDados] = useState({
    inicial: [],
    estadoFiltrado: [],
    filtro: '',
    feriados: [],
    feriadosLista: [],
  });
  const [carregando, setCarregando] = useState(true);
  const [estadoInicial, setEstadoInicial] = useState([]);
  const [atributoSelecionado, setAtributoSelecionado] = useState('estado');

  async function buscarEstado() {
    try {
      const feriado = await api.get('tarifa/estado');
      const feriado2 = await api.get('tarifa/estado');
      setEstadoInicial(feriado2.data);

      setNewDados({
        inicio: feriado2.data,
        estadoFiltrado: feriado.data,
        feriados: feriado.data,
        feriadosLista: feriado.data,
      });
      setCarregando(false);
    } catch (e) {
      setCarregando(false);
      toast.error(e.message);
    }
  }

  useEffect(() => {
    buscarEstado();
  }, []);

  function filtrarLista(texto) {
    setDados({
      ...newDados,
      estadoFiltrado: newDados.estadoFiltrado.filter((estado) =>
        estado[atributoSelecionado]
          .toString()
          .toUpperCase()
          .includes(texto ? texto.toUpperCase() : '')
      ),
      filtro: texto,
    });
  }

  function alterarTextoCelula(id, key, value) {
    const novaLista = Object.assign([], newDados.feriadosLista);
    novaLista.find((item) => item.id === id)[key] = value;
    setNewDados({ ...newDados, feriadosLista: novaLista });
    filtrarLista(newDados.filtro);
  }

  async function alterarEstadoCelula(id) {
    const novaLista = Object.assign([], newDados.feriadosLista);
    const item = novaLista.find((estado) => estado.id === id);
    const itemInicial = newDados.inicio.find((estado) => estado.id === id);

    if (
      item.status === 'EDIT' &&
      (item.tarifa !== itemInicial.tarifa ||
        item.hora_minima !== itemInicial.hora_minima)
    ) {
      try {
        setCarregando(true);
        await api.put(`tarifa/estado/${id}`, item);
        toast.success('Tarifa do estado atualizado com sucesso');
        buscarEstado();
      } catch (error) {
        toast.error(error.message);
      }
      setCarregando(false);
    } else {
      item.status = item.status ? null : 'EDIT';
      setNewDados({ ...newDados, feriadosLista: novaLista });
      filtrarLista(newDados.filtro);
    }
  }

  return (
    <Container>
      <h2>Tarifas Estado</h2>
      <FlexboxGrid justify="start" style={{ gap: '10px' }}>
        <InputGroup style={{ width: '200px' }}>
          <Input
            placeholder="Pesquisar"
            width={200}
            onChange={useDebouncedCallback(filtrarLista, 500).callback}
          />
          <InputGroup.Button>
            <Search />
          </InputGroup.Button>
        </InputGroup>
        <SelectPicker
          value={atributoSelecionado}
          placeholder="Selecione o Status"
          searchable={false}
          data={[
            { value: 'estado', label: 'Estado' },
            { value: 'uf', label: 'UF' },
            { value: 'tarifa', label: 'Tarifa' },
          ]}
          cleanable={false}
          onChange={setAtributoSelecionado}
          style={{ marginRight: '15px' }}
        />
      </FlexboxGrid>
      <FlexboxGrid style={{ width: '100%' }}>
        <TabelaSemPaginacao
          carregando={carregando}
          dados={newDados.estadoFiltrado}
        >
          <Column resizable width={200} sortable>
            <HeaderCell>Estado</HeaderCell>
            <Cell dataKey="estado" />
          </Column>
          <Column resizable width={90} sortable>
            <HeaderCell>UF</HeaderCell>
            <Cell dataKey="uf" />
          </Column>
          <Column resizable width={90} sortable>
            <HeaderCell>Tarifa</HeaderCell>
            <CelulaEditavel dataKey="tarifa" onChange={alterarTextoCelula} />
          </Column>
          {/* <Column resizable width={110} sortable>
          <HeaderCell>Hora Mínima</HeaderCell>
          <CelulaEditavel dataKey="hora_minima" onChange={alterarTextoCelula} />
        </Column> */}
          <Column resizable width={100}>
            <HeaderCell>Editar</HeaderCell>
            <CelulaAcao dataKey="id" onClick={alterarEstadoCelula} />
          </Column>
        </TabelaSemPaginacao>
      </FlexboxGrid>
    </Container>
  );
}

export default Estado;
