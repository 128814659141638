/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import EditIcon from '@rsuite/icons/Edit';
import TrashIcon from '@rsuite/icons/Trash';

import { Table, IconButton, Divider, ButtonToolbar } from 'rsuite';

const { Cell } = Table;

function CelulaAcao({ rowData, onEditar, onDeletar, ...props }) {
  return (
    <Cell {...props} style={{ padding: '5px' }}>
      {onEditar && (
        <ButtonToolbar>
          <IconButton
            appearance="subtle"
            onClick={() => onEditar(rowData)}
            icon={<EditIcon />}
          />
        </ButtonToolbar>
      )}
      {onEditar && onDeletar && <Divider vertical />}
      {onDeletar && (
        <IconButton
          appearance="subtle"
          onClick={() => onDeletar(rowData)}
          color="red"
          icon={<TrashIcon />}
        />
      )}
    </Cell>
  );
}

CelulaAcao.propTypes = {
  // rowData: PropTypes.objectOf,
  onEditar: PropTypes.func,
  onDeletar: PropTypes.func,
  dataKey: PropTypes.string.isRequired,
};

CelulaAcao.defaultProps = {
  // rowData: {},
  onDeletar: null,
  onEditar: null,
};

export default CelulaAcao;
