import React, { useEffect, useCallback, useState, useContext, useRef } from 'react'
import PropTypes from 'prop-types';
import api from '~/services/api';
import { toast } from 'react-toastify';
import theme from "~/styles/theme";
import { useDrop } from 'react-dnd';
import { Button, Loader } from 'rsuite';
import { format, parseISO, eachDayOfInterval } from 'date-fns';
import formatCurrency from '~/utils/formatCurrency';
import PipeContext from '../../ListaPipeline/context'
import EditarRecrutamento from '../../EditarRecrutamento';
import { Card, Header, RowContainer, ColContainer, InfoDiv, TextDiv, VerMais, ContadorDiv } from './styles'
import CardPipe from '../CardPipeline';








const Pipeline = ({ tableId, data, listIndex}) => {

    const textColor = 'white'
    const { move, adicionaItemPipe } = useContext(PipeContext)
    const [loading, setLoading] = useState(false);



    const [{ isOver }, drop] = useDrop(() => ({
        accept: 'CardPipe',
        drop: (item) => move(listIndex, tableId, item),
        collect: (monitor) => ({
            isDragging: !!monitor.isOver()
        })
    })
    )




    async function handleScroll(e) {

        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            setLoading(true)
            await adicionaItemPipe(tableId, listIndex)
                .then(() => {
                    setLoading(false)
                })
                .catch(() => {
                    setLoading(false)
                })
                ;
        }

    }


    // useEffect(() => {
    //     if(!carregando) {
    //         setLoading(false)
    //     }
    // }, [carregando])

    const handleTest = () => {

    }

    return (
        <div ref={drop}>
            {/* <button type='button' onClick={handleTest}>Teste</button> */}

            <ColContainer>
                <Header color={data.cor} textColor={textColor}>
                    <h6>{data.status}</h6>
                </Header>
                <ContadorDiv>
                <p style={{color: 'black'}}>{data.total} Recrutamentos</p>
                </ContadorDiv>
                {/* eslint-disable-next-line react/jsx-no-bind */}
                <Card onScroll={handleScroll}>
                    <ul>
                        {data.cards.map((item, index) =>

                            // eslint-disable-next-line react/no-array-index-key
                            <CardPipe key={item.id} data={item} index={index} listIndex={listIndex} />

                        )}

                    </ul>




                </Card>


                {loading ?
                    <Loader size='lg' style={{ marginLeft: '100px', marginTop: '10px' }} />

                    : ''}

            </ColContainer>


        </div>
    )
}

Pipeline.propTypes = {
    tableId: PropTypes.number.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    data: PropTypes.object.isRequired,
    listIndex: PropTypes.number.isRequired
    // eslint-disable-next-line react/forbid-prop-types
}

export default Pipeline
