/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';

import { FlexboxGrid, Table, CheckPicker, Input } from 'rsuite';

import { InputDataRange } from '~/components/InputDataRange';
import { Container } from '~/styles/tabela';
import { locale } from '~/common/variaveis';
import api from '~/services/api';
import { TableWithSub } from '../tabela';
import { motivosCli } from '../../common';

export const ClientesLogs = () => {
  const [carregando, setCarregando] = useState(true);
  const [data, setData] = useState([]);
  const [motivo, setMotivo] = useState([]);
  const [pagina, setPagina] = useState(1);
  const [pedido, setPedido] = useState();
  const [logsPedido, setLogsPedido] = useState({
    lista: [],
    quantidade: 0,
  });
  const [orderBy, setOrderBy] = useState();

  useEffect(() => {
    const buscaAPI = async () => {
      setCarregando(true);
      try {
        const result = await api.post('/logs/usuarios', {
          pagina,
          categoria: 2,
          id_registro: pedido,
          motivo,
          responsavel: null,
          data_inicio: data[0]
            ? moment(data[0]).format('YYYY-MM-DD')
            : undefined,
          data_fim: data[0] ? moment(data[1]).format('YYYY-MM-DD') : undefined,
        });
        if (result.data.lista.length > 0) {
          setLogsPedido(result.data);
        } else {
          setLogsPedido([
            {
              lista: [],
              quantidade: 0,
            },
          ]);
        }
        setCarregando(false);
      } catch (err) {
        setCarregando(false);
        toast.error('Erro ao realizar a busca dos logs');
      }
    };
    buscaAPI();
  }, [pagina, pedido, motivo, data]);

  return (
    <Container>
      <FlexboxGrid justify="start" style={{ gap: '10px' }}>
        <CheckPicker
          appearance="default"
          style={{ width: 200 }}
          searchable={false}
          value={motivo}
          cleanable={false}
          onChange={(value) => setMotivo(value)}
          data={motivosCli.map((moti) => ({
            label: moti.label,
            value: moti.value,
          }))}
          defaultValue={[{ labael: 'Edição', value: 'Edição' }]}
        />
        <Input
          style={{ width: '200px' }}
          placeholder="ID Cliente	. Ex: 310 "
          onChange={(value) => setPedido(value)}
        />
        <InputDataRange
          style={{ width: '250px' }}
          placeholder="Data"
          ranges={[]}
          onClean={() => setData(['', ''])}
          onChange={(value) => setData(value)}
          locale={locale}
        />
      </FlexboxGrid>

      <TableWithSub
        // title="Profissionais"
        lista={logsPedido.lista}
        quantidade={logsPedido.quantidade}
        paginationBarTop
        paginationBarLow
        setPagina={setPagina}
        columns={[
          { title: 'Responsável', value: 'adm_nome' },
          { title: 'ID Cliente', value: 'id_registro' },
          { title: 'Motivo', value: 'motivo', width: '20%' },
          { title: 'Data de Criação', value: 'createdAt' },
        ]}
        childrens={[
          {
            title: 'Dados',
            list: ['descricao'],
            columns: [
              { title: 'Nome', value: 'nome_fantasia' },
              { title: 'Responsavel', value: 'pessoa_responsavel' },
              { title: 'CNPJ', value: 'cpf_cnpj' },
              { title: 'Email', value: 'email' },
              { title: 'Telefone', value: 'telefone' },
              { title: 'Desconto', value: 'desconto' },
              { title: 'Bloqueado', value: 'perfil_bloqueado' },
            ],
          },
          {
            title: 'Endereço',
            list: ['descricao', 'endereco'],
            columns: [
              { title: 'CEP', value: 'cep' },
              { title: 'Rua', value: 'logradouro' },
              { title: 'Número', value: 'numero' },
              { title: 'Complemento', value: 'complemento' },
              { title: 'Bairro', value: 'bairro' },
              { title: 'Cidade', value: 'cidade' },
              { title: 'UF', value: 'uf' },
            ],
          },
        ]}
      />
    </Container>
  );
};
