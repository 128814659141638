export function verificarCupom() {
  return {
    type: '@pedido/VERIFICAR_CUPOM',
    payload: {},
  };
}

export function salvarCupom(id_cupom) {
  return {
    type: '@pedido/SALVAR_CUPOM',
    payload: { id_cupom },
  };
}

export function buscarPedido(id) {
  return {
    type: '@pedido/BUSCAR_PEDIDO',
    payload: { id },
  };
}

export function buscarPedidoSucesso(pedido, camposInput, registros, cupom) {
  return {
    type: '@pedido/BUSCAR_PEDIDO_SUCESSO',
    payload: { camposInput, registros, pedido, cupom },
  };
}

export function atualizarRegistros(registros) {
  return {
    type: '@pedido/ATUALIZAR_REGISTROS',
    payload: { registros },
  };
}

export function atualizarCamposInput(camposInput) {
  return {
    type: '@pedido/ATUALIZAR_CAMPOS_INPUT',
    payload: { camposInput },
  };
}

export function editaHorario(regist, index){
  return {
    type: '@pedido/ATUALIZAR_REGISTRO_HORARIO',
    payload: { regist, index }
  }
}

export function atualizarCamposCupom(cupom) {
  return {
    type: '@pedido/ATUALIZAR_CAMPOS_CUPOM',
    payload: { cupom },
  };
}

export function atualizarIdEstabelecimento(idEstabelecimento) {
  return {
    type: '@pedido/ATUALIZAR_ID_ESTABELECIMENTO',
    payload: { idEstabelecimento },
  };
}

export function salvarPedido() {
  return {
    type: '@pedido/SALVAR_PEDIDO',
  };
}

export function aceitarRegistro(registro) {
  return {
    type: '@pedido/ACEITAR_REGISTRO',
    payload: { registro },
  };
}

export function importarProfissionaisAceitos({ registro, numero_registro }) {
  return {
    type: '@pedido/IMPORTAR_PROFISSIONAIS',
    payload: { registro, numero_registro },
  };
}

export function confirmarProfissional(registro) {
  return {
    type: '@pedido/CONFIRMAR_PROFISSIONAL',
    payload: { registro },
  };
}

export function cancelarRegistro(registro) {
  return {
    type: '@pedido/CANCELAR_REGISTRO',
    payload: { registro },
  };
}

export function finalizarPedido(registros) {
  return {
    type: '@pedido/FINALIZAR_PEDIDO',
    payload: { registros },
  };
}

export function removerProfissional(registro) {
  return {
    type: '@pedido/REMOVER_PROFISSIONAL',
    payload: { registro },
  };
}

export function marcarFaltaProfissional(registro) {
  return {
    type: '@pedido/MARCAR_FALTA_PROFISSIONAL',
    payload: { registro },
  };
}

export function autorizarPedido() {
  return {
    type: '@pedido/AUTORIZAR_PEDIDO',
  };
}

export function confirmarPedido() {
  return {
    type: '@pedido/CONFIRMAR_PEDIDO',
  };
}

export function cancelarPedido() {
  return {
    type: '@pedido/CANCELAR_PEDIDO',
  };
}

export function criarPedido() {
  return {
    type: '@pedido/CRIAR_PEDIDO',
  };
}

export function criarPedidoAntigo() {
  return {
    type: '@pedido/CRIAR_PEDIDO_ANTIGO',
  };
}

export function requisicaoFinalizada() {
  return {
    type: '@pedido/REQUISICAO_FINALIZADA',
  };
}

export function sairPagina() {
  return {
    type: '@pedido/SAIR_PAGINA',
  };
}

export function dadosRegistrosDia(profissional) {
  return {
    type: '@pedido/REGISTROS_DIA',
    payload: { profissional }
  }
}
