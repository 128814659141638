/* eslint-disable no-console */
/* eslint-disable array-callback-return */
import { all, takeLatest, call, put, select } from 'redux-saga/effects';
import {
  setHours,
  setMinutes,
  format,
  differenceInMinutes,
  isBefore,
  addDays,
  parseISO,
} from 'date-fns';
import moment from 'moment';

import { toast } from 'react-toastify';
import api from '~/services/api';

import {
  requisicaoFinalizada,
  buscarPedidoSucesso,
  buscarPedido as buscarPedidoAction,
  sairPagina,
  salvarCupom,
} from './actions';

function getCamposInput(pedido) {
  const intervalo = pedido.intervalo || 0;
  const horasIntervalo = intervalo / 60;
  const minutosIntervalo = intervalo % 60;

  const camposInput = {
    id: pedido.id,
    id_estabelecimento: pedido.estabelecimento ? pedido.estabelecimento.id : '',
    observacao: pedido.observacao || '',
    obs_profissionais: pedido.obs_profissionais || '',
    valor: pedido.valor.toFixed(2),
    intervalo,
    intervalo_texto: format(
      setHours(setMinutes(new Date(), minutosIntervalo), horasIntervalo),
      'HHmm'
    ),
    hora_inicio: new Date(pedido.registros[0].hora_inicio),
    hora_inicio_texto: format(
      new Date(pedido.registros[0].hora_inicio),
      'HHmm'
    ),
    hora_inicio_texto_format: format(
      new Date(pedido.registros[0].hora_inicio),
      'HH:mm'
    ),
    hora_fim: new Date(pedido.registros[0].hora_fim),
    hora_fim_texto: format(new Date(pedido.registros[0].hora_fim), 'HHmm'),
    data: parseISO(pedido.registros[0].data),
    status: pedido.status,
    nome_fantasia: pedido.estabelecimento
      ? pedido.estabelecimento.nome_fantasia
      : '',
    nome_estabelecimento: pedido.estabelecimento
      ? pedido.estabelecimento.nome_fantasia
      : '',
    pessoa_responsavel: pedido.estabelecimento
      ? pedido.estabelecimento.pessoa_responsavel
      : '',
    endereco: pedido.estabelecimento
      ? `${pedido.estabelecimento.endereco.logradouro}, ${pedido.estabelecimento.endereco.numero}. ${pedido.estabelecimento.endereco.bairro.bairro} - ${pedido.estabelecimento.endereco.bairro.cidade.cidade} / ${pedido.estabelecimento.endereco.bairro.cidade.estado.estado}`
      : '',
    email: pedido.estabelecimento ? pedido.estabelecimento.email : '',
    telefone: pedido.estabelecimento ? pedido.estabelecimento.telefone : '',
    obs: pedido.estabelecimento ? pedido.estabelecimento.obs : '',
    url_pagamento: pedido.url_pagamento,
    status_pagamento: pedido.status_pagamento,
    id_uniforme: pedido.uniforme.id,
    feedback_estb: pedido.feedback_estb,
    tarifa_dinamica: Math.round((pedido.tarifa_dinamica - 1) * 100),
    cupom_texto: pedido.cupom ? pedido.cupom.cupom : '',
    id_cupom: pedido.cupom ? pedido.cupom.id : null,
    tarifa_emergencia: pedido.emergencia
      ? Math.round((pedido.emergencia - 1) * 100)
      : '',
    tarifa_feriado: pedido.tarifa_feriado
      ? Math.round((pedido.tarifa_feriado - 1) * 100)
      : '',
    cpf_cnpj: pedido.estabelecimento.cpf_cnpj,
    refeicao: pedido.estabelecimento.refeicao,
    motivoCancelamento: pedido.motivoCancelamento && pedido.motivoCancelamento.descricao,
  };

  return camposInput;
}

export function* salvarPedido() {
  const { camposInput, registros, pedido } = yield select(
    (state) => state.pedido
  );
  if (!pedido.id) {
    return;
  }

  const horaInicio = camposInput.hora_inicio_texto.substr(0, 2);
  const minutosInicio = camposInput.hora_inicio_texto.substr(2, 3);
  const horaFim = camposInput.hora_fim_texto.substr(0, 2);
  const minutosFim = camposInput.hora_fim_texto.substr(2, 3);
  const horaIntervalo = camposInput.intervalo_texto.substr(0, 2);
  const minutosIntervalo = camposInput.intervalo_texto.substr(2, 3);

  const dataInicio = setMinutes(
    setHours(camposInput.data, horaInicio),
    minutosInicio
  );
  let dataFim = setMinutes(setHours(camposInput.data, horaFim), minutosFim);

  if (isBefore(dataFim, dataInicio)) {
    dataFim = addDays(dataFim, 1);
  }

  const qtdMinutosTotal = differenceInMinutes(dataFim, dataInicio);
  try {
    const intervalo =
      parseInt(horaIntervalo, 10) * 60 + parseInt(minutosIntervalo, 10);
    const registrosLista = registros.map((r) => {
      const [ReghoraInicio] = r.hora_inicio
        ? r.hora_inicio.split(':')
        : [camposInput.hora_inicio_texto.substr(0, 2)];
      const [, RegminutosInicio] = r.hora_fim
        ? r.hora_inicio.split(':')
        : ['', camposInput.hora_inicio_texto.substr(2, 3)];
      const [ReghoraFim] = r.hora_fim
        ? r.hora_fim.split(':')
        : [camposInput.hora_fim_texto.substr(0, 2)];
      const [, RegminutosFim] = r.hora_fim
        ? r.hora_fim.split(':')
        : ['', camposInput.hora_fim_texto.substr(2, 3)];

      const RegdataInicio = setMinutes(
        setHours(camposInput.data, ReghoraInicio),
        RegminutosInicio
      );
      let RegdataFim = setMinutes(
        setHours(camposInput.data, ReghoraFim),
        RegminutosFim
      );

      if (isBefore(RegdataFim, RegdataInicio)) {
        RegdataFim = addDays(RegdataFim, 1);
      }

      if (isBefore(dataFim, dataInicio)) {
        dataFim = addDays(dataFim, 1);
      }

      return {
        id: r.id,
        created_at: r.created_at || new Date(),
        data: setMinutes(setHours(camposInput.data, 0), 0),
        hora_inicio: RegdataInicio || dataInicio,
        hora_fim: RegdataFim || dataFim,
        duracao: qtdMinutosTotal - intervalo,
        id_profissao: r.profissao.id,
        id_profissional: r.profissional != null ? r.profissional.id : null,
        quantidade: r.quantidade,
        avaliacao_prof: r.avaliacao_prof,
        avaliacao_est: r.avaliacao_est,
        feedback_prof: r.feedback_prof,
        tipo_cancelamento: r.tipo_cancelamento,
        valor_eventual: r.valor_eventual,
        status: r.status,
        desc_estab: r.desc_estab,
        desc_cupom: r.desc_cupom,
        tarifa_dinamica:
          Number(r.tarifa_dinamica) / 100 + 1 ||
          Number(camposInput.tarifa_dinamica) / 100 + 1,
        tarifa_feriado: r.tarifa_feriado,
        tarifa_emergencia: r.tarifa_emergencia,
        tarifa_estado: r.tarifa_estado,
        valor_profissao: r.valor_profissao,
        valor_profissao_repasse: r.valor_profissao_repasse,
        endereco: r.endereco
      };
    });
    const body = {
      id_estabelecimento: camposInput.id_estabelecimento,
      observacao: camposInput.observacao,
      obs_profissionais: camposInput.obs_profissionais,
      intervalo: intervalo !== 0 ? intervalo : null,
      emergencia: Number(camposInput.tarifa_emergencia) / 100 + 1,
      tarifa_feriado: Number(camposInput.tarifa_feriado) / 100 + 1,
      registros: registrosLista,
      id_uniforme: camposInput.id_uniforme,
      feedback_estb: camposInput.feedback_estb,
      tarifa_dinamica: Number(camposInput.tarifa_dinamica) / 100 + 1,
      id_cupom: camposInput.id_cupom,
    };
    yield call(api.put, `pedido/${pedido.id}`, body);
    toast.success('Pedido atualizado com sucesso');
  } catch (e) {
    toast.error(e.message);
  }
  yield put(buscarPedidoAction(pedido.id));
}

export function* criarPedido() {
  const { camposInput, registros } = yield select((state) => state.pedido);


  try {
    const body = [];

    camposInput.data.map((data) => {
      const horaIntervalo = camposInput.intervalo_texto.substr(0, 2);
      const minutosIntervalo = camposInput.intervalo_texto.substr(2, 3);

      if (registros.length === 0) {
        throw new Error('É necessário adicionar pelo menos 1 registro');
      }

      const intervalo =
        parseInt(horaIntervalo, 10) * 60 + parseInt(minutosIntervalo, 10);
      const listaRegistros = registros.map((r) => {
        const horaInicio = r.hora_inicio.substr(16, 2);
        const minutosInicio = r.hora_inicio.substr(19, 2);
        const horaFim = r.hora_fim.substr(16, 2);
        const minutosFim = r.hora_fim.substr(19, 2);


        const dataInicio = setMinutes(setHours(data, horaInicio), minutosInicio);
        let dataFim = setMinutes(setHours(data, horaFim), minutosFim);

        if (isBefore(dataFim, dataInicio)) {
          dataFim = addDays(dataFim, 1);
        }

        const horarioInicioSemFuso = dataInicio.toString()
        const horarioFimSemFuso = dataFim.toString()
        const horarioCriacaoSemFuso = new Date().toString()
        const dataCriacaoSemFuso = setMinutes(setHours(data, 0), 0).toString()

        return {
          created_at: horarioCriacaoSemFuso,
          data: dataCriacaoSemFuso,
          hora_inicio: horarioInicioSemFuso,
          hora_fim: horarioFimSemFuso,
          id_profissional: r.id_profissional ? r.id_profissional : null,
          id_profissao: r.profissao.id,
          quantidade: r.quantidade,
          valor_eventual: r.valor_eventual,
        }
      });

      body.push({
        observacao: camposInput.observacao,
        obs_profissionais: camposInput.obs_profissionais,
        intervalo,
        emergencia: (camposInput.tarifa_emergencia === undefined) ? 1 : Number(camposInput.tarifa_emergencia) / 100 + 1,
        id_uniforme: camposInput.id_uniforme,
        registros: listaRegistros,
        tarifa_dinamica: Number(camposInput.tarifa_dinamica) / 100 + 1,
        id_cupom: camposInput.id_cupom,
        tarifa_feriado: Number(camposInput.tarifa_feriado) / 100 + 1,
      });
    });


    yield call(api.post, `pedido/${camposInput.id_estabelecimento}`, body);
    toast.success('Pedido criado com sucesso');
    yield put(sairPagina());
  } catch (e) {
    toast.error(e.message);
    yield put(requisicaoFinalizada());
  }
}

export function* criarPedidoAntigo() {
  const { camposInput, registros } = yield select((state) => state.pedido);

  const horaIntervalo = camposInput.intervalo_texto.substr(0, 2);
  const minutosIntervalo = camposInput.intervalo_texto.substr(2, 3);

  if (registros.length === 0) {
    toast.error('É necessário adicionar pelo menos 1 registro');
    return;
  }

  try {
    const intervalo =
      parseInt(horaIntervalo, 10) * 60 + parseInt(minutosIntervalo, 10);
    const listaRegistros = registros.map((r) => {



      const horaInicio = r.hora_inicio.substr(16, 2);
      const minutosInicio = r.hora_inicio.substr(19, 2);
      const horaFim = r.hora_fim.substr(16, 2);
      const minutosFim = r.hora_fim.substr(19, 2);


      const dataInicio = setMinutes(
        setHours(camposInput.data, horaInicio),
        minutosInicio
      );
      let dataFim = setMinutes(setHours(camposInput.data, horaFim), minutosFim);

      if (isBefore(dataFim, dataInicio)) {
        dataFim = addDays(dataFim, 1);
      }


      return {
        data: setMinutes(setHours(camposInput.data, 0), 0),
        hora_inicio: dataInicio,
        hora_fim: dataFim,
        id_profissao: r.profissao.id,
        quantidade: r.quantidade,
        id_profissional: r.profissional.id,
        avaliacao_prof: r.avaliacao_prof,
      }
    });
    const body = {
      observacao: camposInput.observacao,
      obs_profissionais: camposInput.obs_profissionais,
      intervalo,
      emergencia: true,
      id_uniforme: camposInput.id_uniforme,
      registros: listaRegistros,
      tarifa_dinamica: Number(camposInput.tarifa_dinamica) / 100 + 1,
      id_cupom: camposInput.id_cupom,
    };
    yield call(
      api.post,
      `pedido-antigo/${camposInput.id_estabelecimento}`,
      body
    );
    toast.success('Pedido criado com sucesso');
    yield put(sairPagina());
  } catch (e) {
    toast.error(e.message);
    yield put(requisicaoFinalizada());
  }
}

export function* buscarPedido({ payload }) {
  try {
    const resposta = yield call(api.get, `pedido/${payload.id}`);
    const pedido = resposta.data;
    const camposInput = getCamposInput(pedido);
    const cupom = {
      cup: pedido?.cupom?.cupom
    };

    const registros = pedido.registros.map((r) => ({
      ...r,
      tarifa_dinamica: r.tarifa_dinamica
        ? Math.round((r.tarifa_dinamica - 1) * 100)
        : 0,
      hora_inicio: format(parseISO(r.hora_inicio), 'HH:mm'),
      hora_fim: format(parseISO(r.hora_fim), 'HH:mm'),
      quantidade: 1,
    }));
    yield put(buscarPedidoSucesso(pedido, camposInput, registros, cupom));
  } catch (e) {
    console.tron.error(e);
    toast.error(e.message);
    yield put(requisicaoFinalizada());
  }
}

export function* aceitarRegistro({ payload }) {
  try {
    const { registro } = payload;
    yield call(
      api.put,
      `/registros/aceitar/${registro.id}/${registro.profissional.id}`
    );
    const { pedido } = yield select((state) => state.pedido);
    toast.success('Profissional cadastrado com sucesso');
    yield put(buscarPedidoAction(pedido.id));
  } catch (e) {
    console.tron.error(e);
    toast.error(e.message);
    yield put(requisicaoFinalizada());
  }
}

export function* confirmarProfissional({ payload }) {
  try {
    const { registro } = payload;
    yield call(
      api.put,
      `/registros/confirmar-profissional/${registro.id}/${registro.profissional.id}`
    );
    const { pedido } = yield select((state) => state.pedido);
    toast.success('Profissional confirmado com sucesso');
    yield put(buscarPedidoAction(pedido.id));
  } catch (e) {
    console.tron.error(e);
    toast.error(e.message);
    yield put(requisicaoFinalizada());
  }
}

export function* cancelarRegistro({ payload }) {
  try {
    const { registro } = payload;
    if (!registro.status) {
      throw new Error(
        'Não é possível cancelar um registro que não foi adicionado'
      );
    }
    yield call(api.put, `/registros/cancelar-adm/${registro.id}`);
    const { pedido } = yield select((state) => state.pedido);
    toast.success('Registro cancelado com sucesso');
    yield put(buscarPedidoAction(pedido.id));
  } catch (e) {
    console.tron.error(e);
    toast.error(e.message);
    yield put(requisicaoFinalizada());
  }
}

export function* removerProfissional({ payload }) {
  try {
    const { registro } = payload;
    if (registro.id_profissional === null) {
      throw new Error(
        'Não é possível remover um profissional de um registro que não possui um profissional cadastrado'
      );
    }
    yield call(api.put, `/registros/remover-profissional/${registro.id}`);
    const { pedido } = yield select((state) => state.pedido);
    toast.success('Profissional removido com sucesso');
    yield put(buscarPedidoAction(pedido.id));
  } catch (e) {
    console.tron.error(e);
    toast.error(e.message);
    yield put(requisicaoFinalizada());
  }
}

export function* marcarFaltaProfissional({ payload }) {
  try {
    const { registro } = payload;
    if (registro.id_profissional === null) {
      throw new Error(
        'Não é possível remover um profissional de um registro que não possui um profissional cadastrado'
      );
    }
    yield call(api.put, `/registros/falta/${registro.id}`);
    const { pedido } = yield select((state) => state.pedido);
    toast.success('Falta adicionada com sucesso');
    yield put(buscarPedidoAction(pedido.id));
  } catch (e) {
    console.tron.error(e);
    toast.error(e.message);
    yield put(requisicaoFinalizada());
  }
}

export function* importarCandidatos({ payload }) {
  try {
    const { registro, numero_registro } = payload;
    yield call(
      api.put,
      `/registros/importar-candidatos/${registro.id}?numero_registro=${numero_registro}`
    );
    const { pedido } = yield select((state) => state.pedido);
    toast.success('Cadidadtos Importados com Sucesso!');
    yield put(buscarPedidoAction(pedido.id));
  } catch (e) {
    console.tron.error(e);
    toast.error(e.message);
    yield put(requisicaoFinalizada());
  }
}

export function* finalizarPedido({ payload }) {
  try {
    const { registros } = payload;
    const body = {
      registros,
    };
    const { pedido } = yield select((state) => state.pedido);
    yield call(api.put, `pedidos/finalizar/${pedido.id}`, body);
    toast.success('Pedido finalizado com sucesso');
    yield put(buscarPedidoAction(pedido.id));
  } catch (e) {
    console.tron.error(e);
    toast.error(e.message);
    yield put(requisicaoFinalizada());
  }
}

export function* autorizar() {
  try {
    const { pedido } = yield select((state) => state.pedido);
    yield call(api.put, `pedidos/autorizar/${pedido.id}`);
    yield put(buscarPedidoAction(pedido.id));
    toast.success('Pedido autorizado com sucesso');
  } catch (e) {
    console.tron.error(e);
    toast.error(e.message);
    yield put(requisicaoFinalizada());
  }
}

export function* confirmar() {
  try {
    const { pedido } = yield select((state) => state.pedido);
    yield call(api.put, `pedidos/confirmar/${pedido.id}`);
    yield put(buscarPedidoAction(pedido.id));
    toast.success('Pedido confirmado com sucesso');
  } catch (e) {
    console.tron.error(e);
    toast.error(e.message);
    yield put(requisicaoFinalizada());
  }
}

export function* cancelar() {
  try {
    const { pedido } = yield select((state) => state.pedido);
    yield call(api.put, `pedidos/cancelar/${pedido.id}`);
    yield put(buscarPedidoAction(pedido.id));
    toast.success('Pedido cancelado com sucesso');
  } catch (e) {
    console.tron.error(e);
    toast.error(e.message);
    yield put(requisicaoFinalizada());
  }
}

export function* verificarCupom() {
  try {
    const { camposInput } = yield select((state) => state.pedido);

    if (!camposInput.cupom_texto) {
      toast.error('Cupom inválido');
      yield put(salvarCupom(null));
      return;
    }

    const resposta = yield call(
      api.get,
      `verificar-cupom/${camposInput.cupom_texto}?id_estabelecimento=${camposInput.id_estabelecimento}`
    );
    yield put(salvarCupom(resposta.data.id));
    toast.success('Cupom aplicado com sucesso');
  } catch (e) {
    yield put(salvarCupom(null));
    console.tron.error(e);
    toast.error(e.message);
    yield put(requisicaoFinalizada());
  }
}

export default all([
  takeLatest('@pedido/AUTORIZAR_PEDIDO', autorizar),
  takeLatest('@pedido/CANCELAR_PEDIDO', cancelar),
  takeLatest('@pedido/CONFIRMAR_PEDIDO', confirmar),
  takeLatest('@pedido/BUSCAR_PEDIDO', buscarPedido),
  takeLatest('@pedido/SALVAR_PEDIDO', salvarPedido),
  takeLatest('@pedido/ATUALIZAR_REGISTROS', salvarPedido),
  takeLatest('@pedido/ACEITAR_REGISTRO', aceitarRegistro),
  takeLatest('@pedido/CONFIRMAR_PROFISSIONAL', confirmarProfissional),
  takeLatest('@pedido/CANCELAR_REGISTRO', cancelarRegistro),
  takeLatest('@pedido/REMOVER_PROFISSIONAL', removerProfissional),
  takeLatest('@pedido/MARCAR_FALTA_PROFISSIONAL', marcarFaltaProfissional),
  takeLatest('@pedido/IMPORTAR_PROFISSIONAIS', importarCandidatos),
  takeLatest('@pedido/FINALIZAR_PEDIDO', finalizarPedido),
  takeLatest('@pedido/CRIAR_PEDIDO', criarPedido),
  takeLatest('@pedido/CRIAR_PEDIDO_ANTIGO', criarPedidoAntigo),
  takeLatest('@pedido/VERIFICAR_CUPOM', verificarCupom),
]);
