import React, { useEffect, useState, useCallback } from 'react';
import {
  Radio,
  RadioGroup,
  FlexboxGrid,
  Loader,
  List,
  SelectPicker,
  Divider,
} from 'rsuite';
import { toast } from 'react-toastify';

import api from '~/services/api';
import formatCurrency from '~/utils/formatCurrency';
import { InputDataRange } from '~/components/InputDataRange';
import Graficos from './graficos';
import { Container } from './styles';

function Dashboard() {
  const [dashboard, setDashbaord] = useState({
    repasse: 0,
    faturamento: 0,
    receita: 0,
    faturamentoPorcentagem: 0,
    horas: 0.0,
  });
  const [filtroData, setFiltroData] = useState([]);
  const [conteudo, setConteudo] = useState('tabela');
  const [tipoGrafico, setTipoGrafico] = useState('financeiro');

  const locale = {
    sunday: 'Dom',
    monday: 'Seg',
    tuesday: 'Ter',
    wednesday: 'Qua',
    thursday: 'Qui',
    friday: 'Sex',
    saturday: 'Sab',
    ok: 'Ok',
    today: 'Hoje',
    yesterday: 'Ontem',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos',
  };

  const [carregando, setCarregando] = useState(true);

  const buscarRelatorios = useCallback(async () => {
    setCarregando(true);
    try {
      const resposta = await api.get('relatorios/dashboard', {
        params: {
          datas: filtroData,
        },
      });
      setDashbaord(resposta.data);
    } catch (e) {
      toast.error(e.message);
    }

    setCarregando(false);
  }, [filtroData]);

  useEffect(() => {
    buscarRelatorios();
  }, [buscarRelatorios]);

  return (
    <Container>
      <h2>Dashboard</h2>
      <FlexboxGrid justify="start">
        <RadioGroup
          name="radioList"
          inline
          appearance="picker"
          defaultValue={conteudo}
          onChange={(value) => setConteudo(value)}
          style={{ marginRight: '10px' }}
        >
          <Radio value="tabela">Tabelas</Radio>
          <Radio value="grafico">Gráficos</Radio>
        </RadioGroup>
        {conteudo === 'tabela' ? (
          <InputDataRange
            ranges={[]}
            isoWeek
            locale={locale}
            value={filtroData}
            onChange={setFiltroData}
          />
        ) : (
          <SelectPicker
            style={{ width: '250px' }}
            value={tipoGrafico}
            placeholder="Selecione o Status"
            searchable={false}
            data={[
              { value: 'financeiro', label: 'Financeiro' },
              { value: 'profissionais', label: 'Profissionais Ativos' }, // aqui
              { value: 'clientes', label: 'Clientes Ativos' }, // aqui
              { value: 'maioresClientes', label: 'Maiores Clientes' },
              { value: 'vendasFuncao', label: 'Vendas por Função' },
              { value: 'vendasDias', label: 'Vendas por Dias' },
              { value: 'vendaCidades', label: 'Vendas por Cidades' },
              { value: 'cancelamentosMes', label: 'Cancelamentos por mês' }, // aqui
              { value: 'faltasMes', label: 'Faltas por mês' }, // aqui
            ]}
            cleanable={false}
            onChange={setTipoGrafico}
          />
        )}
      </FlexboxGrid>
      <hr />
      {conteudo === 'tabela' ? (
        <FlexboxGrid justify="center">
          <List style={{ width: '800px', fontSize: '20px' }} bordered size="lg">
            <List.Item style={{ color: '#fff', backgroundColor: '#0e385b ' }}>
              <FlexboxGrid justify="center">
                <h4>DASHBOARD</h4>
              </FlexboxGrid>
            </List.Item>

            <List.Item>
              <FlexboxGrid>
                <FlexboxGrid.Item colspan={11}>
                  <strong>Faturamento</strong>
                </FlexboxGrid.Item>
                <Divider vertical />
                <FlexboxGrid.Item colspan={11}>
                  {dashboard.Faturamento
                    ? formatCurrency(dashboard.Faturamento)
                    : 0}
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </List.Item>
            <List.Item>
              <FlexboxGrid>
                <FlexboxGrid.Item colspan={11}>
                  <strong>Repasse</strong>
                </FlexboxGrid.Item>
                <Divider vertical />
                <FlexboxGrid.Item colspan={11}>
                  {dashboard.Repasse ? formatCurrency(dashboard.Repasse) : 0}
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </List.Item>
            <List.Item>
              <FlexboxGrid>
                <FlexboxGrid.Item colspan={11}>
                  <strong>Receita - Switch</strong>
                </FlexboxGrid.Item>
                <Divider vertical />
                <FlexboxGrid.Item colspan={11}>
                  {dashboard.Receita ? formatCurrency(dashboard.Receita) : 0}
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </List.Item>
            <List.Item>
              <FlexboxGrid>
                <FlexboxGrid.Item colspan={11}>
                  <strong>Pedidos Totais</strong>
                </FlexboxGrid.Item>
                <Divider vertical />
                <FlexboxGrid.Item colspan={11}>
                  {dashboard.PedidosTotais}
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </List.Item>
            <List.Item>
              <FlexboxGrid>
                <FlexboxGrid.Item colspan={11}>
                  <strong>Horas Totais</strong>
                </FlexboxGrid.Item>
                <Divider vertical />
                <FlexboxGrid.Item colspan={11}>
                  {dashboard.HorasTotais}
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </List.Item>
            <List.Item>
              <FlexboxGrid>
                <FlexboxGrid.Item colspan={11}>
                  <strong>Clientes Ativos - (ANO)</strong>
                </FlexboxGrid.Item>
                <Divider vertical />
                <FlexboxGrid.Item colspan={11}>
                  {dashboard.ClientesAtivos}
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </List.Item>
            <List.Item>
              <FlexboxGrid>
                <FlexboxGrid.Item colspan={11}>
                  <strong>Profissionais Ativos - (ANO)</strong>
                </FlexboxGrid.Item>
                <Divider vertical />
                <FlexboxGrid.Item colspan={11}>
                  {dashboard.ProfissionaisAtivos}
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </List.Item>
            <List.Item>
              <FlexboxGrid>
                <FlexboxGrid.Item colspan={11}>
                  <strong>Média de pedidos por semana</strong>
                </FlexboxGrid.Item>
                <Divider vertical />
                <FlexboxGrid.Item colspan={11}>
                  {dashboard.MediaPedidosSemanal}
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </List.Item>
            <List.Item>
              <FlexboxGrid>
                <FlexboxGrid.Item colspan={11}>
                  <strong>Média de horas por shift</strong>
                </FlexboxGrid.Item>
                <Divider vertical />
                <FlexboxGrid.Item colspan={11}>
                  {dashboard.MediaHorasShift}
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </List.Item>
            <List.Item>
              <FlexboxGrid>
                <FlexboxGrid.Item colspan={11}>
                  <strong>Ticket Médio - Por shift</strong>
                </FlexboxGrid.Item>
                <Divider vertical />
                <FlexboxGrid.Item colspan={11}>
                  {dashboard.TicketMedio
                    ? formatCurrency(dashboard.TicketMedio)
                    : 0}
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </List.Item>
            <List.Item>
              <FlexboxGrid>
                <FlexboxGrid.Item colspan={11}>
                  <strong>Receita Média</strong>
                </FlexboxGrid.Item>
                <Divider vertical />
                <FlexboxGrid.Item colspan={11}>
                  {dashboard.ReceitaMedia
                    ? formatCurrency(dashboard.ReceitaMedia)
                    : 0}
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </List.Item>
            <List.Item>
              <FlexboxGrid>
                <FlexboxGrid.Item colspan={11}>
                  <strong>Média intermediação</strong>
                </FlexboxGrid.Item>
                <Divider vertical />
                <FlexboxGrid.Item colspan={11}>
                  {dashboard.MediaIntermediacao}%
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </List.Item>
            <List.Item>
              <FlexboxGrid>
                <FlexboxGrid.Item colspan={11}>
                  <strong>Média de satisfação (avaliação)</strong>
                </FlexboxGrid.Item>
                <Divider vertical />
                <FlexboxGrid.Item colspan={11}>
                  {dashboard.MediaSatisfacao}
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </List.Item>
            <List.Item>
              <FlexboxGrid>
                <FlexboxGrid.Item colspan={11}>
                  <strong>Cancelamentos - Empresas</strong>
                </FlexboxGrid.Item>
                <Divider vertical />
                <FlexboxGrid.Item colspan={11}>
                  {dashboard.CancelamentosEmpresas}
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </List.Item>
            <List.Item>
              <FlexboxGrid>
                <FlexboxGrid.Item colspan={11}>
                  <strong>Faltas - Profissionais</strong>
                </FlexboxGrid.Item>
                <Divider vertical />
                <FlexboxGrid.Item colspan={11}>
                  {dashboard.FaltasProfissionais}
                </FlexboxGrid.Item>
              </FlexboxGrid>
            </List.Item>
          </List>
        </FlexboxGrid>
      ) : (
        <Graficos tipoGrafico={tipoGrafico} />
      )}
      {carregando && <Loader center size="lg" vertical />}
    </Container>
  );
}

export default Dashboard;
