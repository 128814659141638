import React from 'react';
import { FlexboxGrid, List } from 'rsuite';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import formatCurrency from '~/utils/formatCurrency';

export default function Table() {
  const data = useSelector((state) => state.carteiraClientes);

  return (
    <div style={{ textAlign: 'center', marginRight: '70px' }}>
      <h6>Resumo</h6>
      <List
        style={{
          width: '200px',
          fontSize: '10px',
          marginBottom: '20px',
        }}
        bordered
        size="sm"
      >
        <List.Item>
          <FlexboxGrid style={{ justifyContent: 'space-between' }}>
            <FlexboxGrid.Item>
              <strong>Total Pedidos</strong>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item>
              <strong>{data.totalPedidos ? data.totalPedidos : 0}</strong>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </List.Item>
        <List.Item>
          <FlexboxGrid style={{ justifyContent: 'space-between' }}>
            <FlexboxGrid.Item>
              <strong>Cancelamentos</strong>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item>
              <strong>{data.cancelamentos ? data.cancelamentos : 0}</strong>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </List.Item>
        <List.Item>
          <FlexboxGrid style={{ justifyContent: 'space-between' }}>
            <FlexboxGrid.Item>
              <strong>Valor Pedidos</strong>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item>
              <strong>
                {data.valorTotal ? formatCurrency(data.valorTotal) : 0.0}
              </strong>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </List.Item>
        <List.Item>
          <FlexboxGrid style={{ justifyContent: 'space-between' }}>
            <FlexboxGrid.Item>
              <strong>Média Cancel.</strong>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item>
              <strong>
                {data.mediaCancelamentos
                  ? formatCurrency(data.mediaCancelamentos)
                  : 0.0}
              </strong>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </List.Item>
        <List.Item>
          <FlexboxGrid style={{ justifyContent: 'space-between' }}>
            <FlexboxGrid.Item>
              <strong>Avaliação Média</strong>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item>
              <strong>
                {data.avaliacaoMedia ? data.avaliacaoMedia.toFixed(2) : 0.0}
              </strong>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </List.Item>
        <List.Item>
          <FlexboxGrid style={{ justifyContent: 'space-between' }}>
            <FlexboxGrid.Item>
              <strong>Faltas</strong>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item>
              <strong>{data.totalFaltas ? data.totalFaltas : 0}</strong>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </List.Item>
        <List.Item>
          <FlexboxGrid style={{ justifyContent: 'space-between' }}>
            <FlexboxGrid.Item>
              <strong>Duração Média</strong>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item>
              <strong>
                {data.duracaoMedia ? `${data.duracaoMedia} horas` : 0}
              </strong>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </List.Item>
        <List.Item>
          <FlexboxGrid style={{ justifyContent: 'space-between' }}>
            <FlexboxGrid.Item>
              <strong>Primeiro Pedido</strong>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item>
              <strong>
                {data.primeiroPedido
                  ? `${format(
                    new Date(data.primeiroPedido.replace('-', '/')),
                    'dd/MM/yyyy'
                  )}`
                  : '-'}
              </strong>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </List.Item>
        <List.Item>
          <FlexboxGrid style={{ justifyContent: 'space-between' }}>
            <FlexboxGrid.Item>
              <strong>Último Pedido</strong>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item>
              <strong>
                {data.ultimoPedido
                  ? `${format(
                    new Date(data.ultimoPedido.replace('-', '/')),
                    'dd/MM/yyyy'
                  )}`
                  : '-'}
              </strong>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </List.Item>
        <List.Item>
          <FlexboxGrid style={{ justifyContent: 'space-between' }}>
            <FlexboxGrid.Item>
              <strong>Uso Cupom</strong>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item>
              <strong>{data.totalCupons ? data.totalCupons : 0}</strong>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </List.Item>
        <List.Item>
          <FlexboxGrid style={{ justifyContent: 'space-between' }}>
            <FlexboxGrid.Item>
              <strong>Tarifa Dinâmica</strong>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item>
              <strong>{data.tarifaDinamica ? data.tarifaDinamica : 0}</strong>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </List.Item>
      </List>
    </div>
  );
}
