/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import EditIcon from '@rsuite/icons/Edit';
// import TrashIcon from '@rsuite/icons/Trash';
import { Edit, Check, Plus, TrashIcon, ArrowDownLine  } from '@rsuite/icons';
import RemindRoundIcon from '@rsuite/icons/RemindRound';
import NoticeIcon from '@rsuite/icons/Notice';
import CheckRoundIcon from '@rsuite/icons/CheckRound';
import {
  Table,
  IconButton,
  Divider,
  Dropdown,
  Whisper,
  Popover,
  Input,
} from 'rsuite';

import {
  marcarFaltaProfissional,
  removerProfissional,
} from '~/store/modulos/pedido/actions';
import { useDispatch } from 'react-redux';
import { fonte } from './CelulaStatusFormatada';
import CelulaVisualizado from './CelulaVisualizado'
import api from '../../../../../services/api';

const { Cell } = Table;

const fundo = (data) => {
  let cor;
  if (data.status === 'Cancelado') {
    cor = '#ff1616';
  } else if (data.status === 'Faltou') {
    cor = '#8f0350';
  }else {
    cor = ''
  }
  return cor;
};
const disabled = true

function CelulaEdit({
  rowData,
  onEditar,
  onDeletar,
  setRegistroSelecionado,
  setExibirEditarDialog,
  setExibirAceitarDialog,
  setExibirConfirmarDialog,
  setExibirExcluirDialog,
  setExibirCandidatosDialog,
  setFavoritarBloquearDialog,
  setModalAberta,
  copiaAreaTransferencia,
  registros,
  setExibirMelhoresNomesDialog,
  triggerRef,
  ...props
}) {
  let semProfissional = false;
  const dispatch = useDispatch();
  const [candidaturas, setCandidaturas] = useState();

  if (rowData.profissional === null) semProfissional = true;

  // useEffect(() => {
  //   async function resposta1() {
  //     const resposta = await registros.map(async (item) => {
  //       const data = await api.get(
  //         `registro/quantidade-candidaturas/${item.id}`
  //       );
  //       return {
  //         count: data.data.count,
  //         id: item.id,
  //       };
  //     });

  //     Promise.all([...resposta]).then((valores) => {
  //       // console.log(valores);
  //       setCandidaturas(valores);
  //     });
  //   }
  //   resposta1();
  // }, []);

  // const qtdCandidatosFiltradosPorRegistro = (idRegistro) => {
  //   return (
  //     candidaturas &&
  //     candidaturas.filter((item) => {
  //       return item.id === idRegistro;
  //     })
  //   );
  // };

 
  return (
    <Cell
      {...props}
      style={{
        backgroundColor: fundo(rowData),
        padding: '5px',
        display: 'flex',
      }}
    >

      <>

        <IconButton
          appearance="subtle"
          style={{ backgroundColor: fundo(rowData), color: fonte(rowData) }}
          onClick={() => {
            setRegistroSelecionado(rowData.registro);
            setExibirEditarDialog(true);
          }}
          icon={<Edit />}
        />

        <IconButton

          style={{ backgroundColor: 'transparent', color: fonte(rowData)}}

          color="red"
          // icon={<TrashIcon />}
          icon={rowData.registro.visualizado === false ? <NoticeIcon/> : <CheckRoundIcon style={{ backgroundColor: fundo('blue'), color: fonte('red') }} />}
        />
        <IconButton
          appearance="subtle"
          style={{ backgroundColor: fundo(rowData), color: fonte(rowData) }}
          onClick={() => {
            setRegistroSelecionado(rowData.registro);
            setExibirAceitarDialog(true);
          }}
          icon={<Plus />}
        />
        <IconButton
          appearance="subtle"
          style={{ backgroundColor: fundo(rowData), color: fonte(rowData) }}
          onClick={() => {
            setRegistroSelecionado(rowData.registro);
            setExibirConfirmarDialog(true);
            setModalAberta(true);
          }}
          icon={<Check />}
        />
        <Whisper
          placement="rightStart"
          trigger="click"
          triggerRef={triggerRef}
          speaker={
            <Popover full>
              <Dropdown.Menu>
                <Dropdown.Item
                  key="Remover Profissional"
                  eventKey="Remover Profissional"
                  onSelect={() =>
                    dispatch(removerProfissional(rowData.registro))
                  }
                >
                  Remover Profissional
                </Dropdown.Item>
                <Dropdown.Item
                  key="Excluir Registro"
                  eventKey="Excluir Registro"
                  onSelect={() => {
                    setRegistroSelecionado(rowData);
                    setExibirExcluirDialog(true);
                  }}
                >
                  Excluir Registro
                </Dropdown.Item>
                <Dropdown.Item
                  key="Marcar Falta do Profissional"
                  eventKey="Marcar Falta do Profissional"
                  onSelect={() => {
                    dispatch(marcarFaltaProfissional(rowData));
                  }}
                >
                  Marcar Falta do Profissional
                </Dropdown.Item>
                <Dropdown.Item
                  key="Importar Profissionais"
                  eventKey="Importar Profissionais"
                  onSelect={() => {
                    setRegistroSelecionado(rowData.registro);
                    setExibirCandidatosDialog(true);
                  }}
                >
                  Importar Candidatos
                </Dropdown.Item>
                <Dropdown.Item
                  key="Copiar Registro"
                  eventKey="Copiar Registro"
                  id="clipboardCopyRegistry"
                  onClick={() => {
                    copiaAreaTransferencia(rowData.registro);
                  }}
                >
                  Copiar Info
                </Dropdown.Item>
                <Dropdown.Item
                  key="Melhores Nomes"
                  eventKey="Melhores Nomes"
                  onClick={() => {
                    setRegistroSelecionado(rowData.registro);
                    setExibirMelhoresNomesDialog(true);
                  }}
                >
                  Melhores Nomes
                </Dropdown.Item>
                <Dropdown.Item
                  key="Favoritar Bloquear"
                  eventKey="Favoritar Bloquear"
                  onClick={() => {
                    setRegistroSelecionado(rowData.registro);
                    setFavoritarBloquearDialog(true);
                  }}
                  disabled={semProfissional}
                >
                  Favoritar/Bloquear
                </Dropdown.Item>
              </Dropdown.Menu>
            </Popover>
          }
        >
          <IconButton appearance="subtle" icon={<ArrowDownLine />} />
        </Whisper>

        {rowData.visualizado === 'false' ? <IconButton
          appearance="subtle"
          style={{ backgroundColor: fundo(rowData), color: fonte(rowData) }}
          icon={<RemindRoundIcon />}
        />: ''}
      </>
    </Cell>
  );
}


CelulaEdit.propTypes = {
  rowData: PropTypes.object,
  onEditar: PropTypes.func,
  onDeletar: PropTypes.func,
  registros: PropTypes.object,
  setRegistroSelecionado: PropTypes.func,
  setExibirEditarDialog: PropTypes.func,
  setExibirAceitarDialog: PropTypes.func,
  setExibirConfirmarDialog: PropTypes.func,
  setFavoritarBloquearDialog: PropTypes.func,
  setModalAberta: PropTypes.func,
  triggerRef: PropTypes.object,
  setExibirExcluirDialog: PropTypes.func,
  setExibirCandidatosDialog: PropTypes.func,
  copiaAreaTransferencia: PropTypes.func,
  setExibirMelhoresNomesDialog: PropTypes.func,
  dataKey: PropTypes.string.isRequired,
};

CelulaEdit.defaultProps = {
  rowData: {},
  onDeletar: null,
  onEditar: null,
  registros: {},
  setRegistroSelecionado: null,
  setExibirEditarDialog: null,
  setExibirAceitarDialog: null,
  setExibirConfirmarDialog: null,
  setExibirExcluirDialog: null,
  setExibirCandidatosDialog: null,
  setModalAberta: null,
  setFavoritarBloquearDialog: null,
  copiaAreaTransferencia: null,
  setExibirMelhoresNomesDialog: null,
  triggerRef: {},
};

export default CelulaEdit;
