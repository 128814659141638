/* eslint-disable react/prop-types */
import React, { createContext, useState, useEffect } from 'react'

export const DarkModeContext = createContext()

function DarkModeProvider({ children }) {
  const [toggleMode, setToggleMode] = useState(localStorage.getItem("toggle-mode") === 'true')
  // const [toggleMode, setToggleMode] = useState('correia')

  // useEffect(() => {
  //   localStorage.setItem("toggle-mode", toggleMode)
  // }, [toggleMode])

  // const toggleModeFunc = () => {
  //   setToggleMode(!toggleMode)
  // }
  return (
    <DarkModeContext.Provider value={{ toggleMode, setToggleMode }}>
      {children}
    </DarkModeContext.Provider>
  )
}

export default DarkModeProvider
