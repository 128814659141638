import React from 'react';
import { Radio, RadioGroup } from 'rsuite';
import PropTypes from 'prop-types';

export default function FilterUsersNotification({ setBloqueados, bloqueados }) {
  return (
    <>
      <h5>Enviar para:</h5>
      <RadioGroup
        style={{ marginBottom: '30px' }}
        onChange={(value) => {
          setBloqueados(value);
        }}
        value={bloqueados}
        inline
        defaultValue={0}
      >
        <Radio value={0}>Todos</Radio>
        <Radio value={1}>Não Bloqueados</Radio>
        <Radio value={2}>Bloqueados</Radio>
      </RadioGroup>
    </>
  );
}

FilterUsersNotification.propTypes = {
  setBloqueados: PropTypes.func.isRequired,
  bloqueados: PropTypes.number.isRequired,
};
