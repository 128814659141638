/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Divider, Col, Row, Form, Schema, Loader } from 'rsuite';
import { useDispatch, useSelector } from 'react-redux';
import {
  atualizarCamposInput,
  criarPedido,
  criarPedidoAntigo as CriarPedidoAntigoAction,
} from '~/store/modulos/pedido/actions';
import { ButtonFullBlue } from '../../../components/Buttons/ButtonFullBlue/styles';

import { Container } from './styles';
import ListaRegistro from '../components/ListaRegistro';
import CamposDadosPedido from '../components/CamposDadosPedido';

const { StringType, NumberType, ArrayType, DateType } = Schema.Types;

function CriarPedido() {
  const [pedidoAntigo, setPedidoAntigo] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);

  const dispatch = useDispatch();
  const carregando = useSelector((state) => state.pedido.carregando);
  const camposInput = useSelector((state) => state.pedido.camposInput);

  let modelo;
  !pedidoAntigo
    ? (modelo = Schema.Model({
      id_estabelecimento: NumberType('Número inválido').isRequired(
        'Selecione um Cliente'
      ),
      observacao: StringType(),
      obs_profissionais: StringType(),
      intervalo_texto: StringType(),
      id_uniforme: NumberType().isRequired('Uniforme Obrigatório'),
      tarifa_dinamica: NumberType('Apenas números inteiros')
        .max(1000)
        .min(0)
        .isRequired('Tarifa Dinamica obrigatoria'),
      data: ArrayType().of(DateType()).isRequired(),
      hora_inicio_texto: StringType()
        .addRule((valor) => {
          const horas = valor.substr(0, 2);
          const minutos = valor.substr(2, 4);
          if (horas === '' || minutos === '') return false;
          return true;
        }, 'Data inválida')
        .isRequired(),
      hora_fim_texto: StringType()
        .addRule((valor) => {
          const horas = valor.substr(0, 2);
          const minutos = valor.substr(2, 4);
          if (horas === '' || minutos === '') return false;

          return true;
        }, 'Data inválida')
        .isRequired(),
      profissoes: ArrayType().of(NumberType()),
    }))
    : (modelo = Schema.Model({
      id_estabelecimento: NumberType('Número inválido').isRequired(
        'Selecione um Cliente'
      ),
      observacao: StringType(),
      obs_profissionais: StringType(),
      intervalo_texto: StringType(),
      id_uniforme: NumberType().isRequired('Uniforme Obrigatório'),
      data: DateType().isRequired('Dia obrigatório'),
      tarifa_dinamica: NumberType().isRequired('Tarifa Dinamica obrigatoria'),
      hora_inicio_texto: StringType()
        .addRule((valor) => {
          const horas = valor.substr(0, 2);
          const minutos = valor.substr(2, 4);
          if (horas === '' || minutos === '') return false;
          return true;
        }, 'Data inválida')
        .isRequired(),
      hora_fim_texto: StringType()
        .addRule((valor) => {
          const horas = valor.substr(0, 2);
          const minutos = valor.substr(2, 4);
          if (horas === '' || minutos === '') return false;
          return true;
        }, 'Data inválida')
        .isRequired(),
      profissoes: ArrayType().of(NumberType()),
    }));

  let erro = {};

  function exibirMensagemErro() {
    Object.values(erro).forEach((e) => {
      toast.error(e);
    });
  }

  return (
    <Container>
      <Form
        model={modelo}
        onChange={(campos) => {
          dispatch(atualizarCamposInput(campos));
        }}
        formValue={camposInput}
        onSubmit={(semErro) => semErro
          ? pedidoAntigo
            ? dispatch(CriarPedidoAntigoAction())
            : dispatch(criarPedido()) && setSelectedDates([])
            // : dispatch(criarPedido())
          : exibirMensagemErro()
        }
        onError={(err) => {
          erro = err;
        }}
      >
        <CamposDadosPedido
          atualizarCamposInput={atualizarCamposInput}
          setSelectedDates={setSelectedDates}
          selectedDates={selectedDates}
          setPedidoAntigo={setPedidoAntigo}
          pedidoAntigo={pedidoAntigo}
        />
        <Divider />
        {pedidoAntigo ? <ListaRegistro pedidoAntigo /> : <ListaRegistro criacao />}

        <Row style={{ margin: '0', marginBottom: '5px' }}>
          <Col sm={6} />
          <Col sm={12}>
            <ButtonFullBlue
              appearance="primary"
              type="submit"
              size="lg"
              loading={carregando}
              block
            >
              Salvar
            </ButtonFullBlue>
          </Col>
          <Col sm={6} />
        </Row>
      </Form>
      {carregando && <Loader backdrop center />}
    </Container>
  );
}

export default CriarPedido;
