/* eslint-disable react/no-unused-prop-types */
import React, { useContext } from 'react';
import { FlexboxGrid, Panel, Col } from 'rsuite';
import formatCurrency from '~/utils/formatCurrency';
import { DarkModeContext } from '~/common/contexts/darkMode';
import PropTypes from 'prop-types';
import theme from '../../../../styles/theme';

function CardTotais({ numeroProfissionais, totalContratado }) {
  const { toggleMode } = useContext(DarkModeContext)
  const Card = ({ titulo, valor }) => (
    <Panel shaded style={{backgroundColor: toggleMode ? theme.rsuiteGray : 'white'}} >
      <h6 style={{ color: toggleMode ? 'gray' : theme.darkGray }}>
        {titulo}
        <spam style={{ color: toggleMode ? '#1675e0' : theme.primaryColor }}>{valor}</spam>
      </h6>
    </Panel>
  );
  return (
    <FlexboxGrid justify="start" style={{ padding: '20px', gap: 10 }}>
      <FlexboxGrid.Item componentClass={Col}>
        <Card valor={numeroProfissionais} titulo="Nº de Profissionais: " />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item componentClass={Col}>
        <Card
          valor={formatCurrency(totalContratado)}
          titulo="Total Contratado: "
        />
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
}

CardTotais.propTypes = {
  numeroProfissionais: PropTypes.func.isRequired,
  totalContratado: PropTypes.string.isRequired,
  titulo: PropTypes.string.isRequired,
  valor: PropTypes.string.isRequired,
};
export default CardTotais;
