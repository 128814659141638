/* eslint-disable react/no-danger */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */
import React, { useContext, useEffect, useState } from 'react';
import { Edit, Plus } from '@rsuite/icons';
import { FlexboxGrid, Table, IconButton, Toggle, Checkbox, CheckboxGroup } from 'rsuite';
import TrashIcon from '@rsuite/icons/Trash';

import { Container } from '~/styles/tabela';

import SearchInputTable from '~/components/SearchInputTable';
import { TablePagination } from '~/components/TablePagination';
import CelulaAcao from '~/components/Celulas/components/CelulaAcao';
import { toast } from 'react-toastify';
import moment from 'moment';
import { TogglesStatus } from './styles';
import api from '../../../services/api';
import NovaEscala from './components/NovaEscala';
import { ExcluirEscala } from './components/modal/ExcluirEscala';
import { DarkModeContext } from '../../../common/contexts/darkMode';

const { HeaderCell, Column, Cell } = Table;

export default function EscalaFixa() {
  const [pagina, setPagina] = useState(1);
  const [data, setData] = useState([]);
  const [textodigitado, setTextoDigitado] = useState('');
  const [estabelecimento, setEstabelecimento] = useState('');
  const [registro, setRegistro] = useState('');
  const [filtroStatus, setFiltroStatus] = useState('');
  const [escalaSelecionada, setEscalaSelecionada] = useState();
  const [editarNovaEscala, setEditarNovaEscala] = useState(false);
  const [novaEscala, setNovaEscala] = useState(false);
  const [carregando, setCarregando] = useState(true);
  const [exluirEscala, setExluirEscala] = useState();
  const [modalExcluir, setModalExcluir] = useState(false);
  const [atualizarLista, setAtualizarLista] = useState(false);

  const { toggleMode } = useContext(DarkModeContext)


  const [ajusteHorario, setAjusteHorario] = useState(
    {
      lista: [],
      quantidade: 0
    });
  // Comentário para commit
  // e merge
  async function updateDataPage() {
    if(ajusteHorario.quantidade > 0 && ajusteHorario.lista.length === 0) setPagina(1)
    setCarregando(true)
    try {
      const resposta = await api.get('/escala-fixa', {
        params: {
          pagina,
          estabelecimento,
          filtroStatus: filtroStatus !== undefined ? filtroStatus : ''
        }
      })
      const { lista, quantidade } = resposta.data;
      const newList = lista.map((l) => ({
        ...l,
        status: l.status === 'ativo',
        ultimoDiaLancado: moment(l.ultimo_dia_lancado).format('DD/MM/YYYY'),
        ultimoLancamento: moment(l.ultimo_lancamento).format('DD/MM/YYYY'),
      }))

      setAjusteHorario({ lista: newList, quantidade })

    } catch (error) {
      toast.error(error.message);
    }
    setCarregando(false)
  }

  useEffect(() => {
    updateDataPage()
  }, [pagina, estabelecimento, atualizarLista, filtroStatus]);


  async function addEscala(camposEnd) {
    setNovaEscala(false);
    try {

      await api.post(`/escala-fixa`, { ...camposEnd });
      await updateDataPage();
      toast.success('Nova escala adicionada com sucesso.');
    } catch (e) {
      toast.error(e.message);
    }
  }

  async function updateEscala(newEscale) {
    setEditarNovaEscala(false);
    try {
      await api.put(`/escala-fixa/${newEscale.id}`, newEscale);
      await updateDataPage();
      toast.success('Escala atualizada com sucesso.');
    } catch (e) {
      toast.error(e.message);
    }
  }

  async function alteracaostatus(e, value) {
    try {

      const returnValue2 = {
        status: value === true ? 'inativo' : 'ativo'
      }
      setCarregando(true);
      await api.patch(`/escala-fixa/${e.id}`, returnValue2)
      setCarregando(false);
      updateDataPage()
      toast.success('Status atualizado com sucesso');
    } catch (err) {
      toast.error(err.message);
    }
  }

  function abrirEditarEscala(newData) {
    setEscalaSelecionada({ ...newData });
    setEditarNovaEscala(true);
  }

  useEffect(() => {
    if (editarNovaEscala === false)
      setEscalaSelecionada({})
  }, [editarNovaEscala]);


  const CelulaAtivacao = ({ rowData, ...props }) => (
    <Cell {...props}>
      <TogglesStatus>
        <Toggle
          checkedChildren="Ativo" unCheckedChildren="Inativo"
          defaultChecked={rowData.status}
          onChange={() => alteracaostatus(rowData, rowData.status)}
        /></TogglesStatus>
    </Cell>
  );

  const TextoFormatadoCell = ({ rowData, dataKey, ...props }) => (
    // <Cell {...props}>{(rowData[dataKey])}</Cell>
    <Cell {...props}>
      <div
        dangerouslySetInnerHTML={{
          __html: `${rowData[dataKey]}`,
        }}
      />
    </Cell>
  );


  return (
    <Container>
      <h2>Escala Fixa</h2>
      <FlexboxGrid align="middle" style={{ gap: '10px' }}>
        <SearchInputTable
          setTextoDigitado={setEstabelecimento}
          place='Pesquisar por cliente'
          estilo={{ width: '220px' }}
        />

        <div
          style={{ display: 'flex', alignItems: 'center', marginBottom: '10px'}}
        >
          <h6 style={{ color: toggleMode ? '#fff' : 'black', marginRight: 2}}> Nova Escala </h6>
          <IconButton
            style={{ marginTop: '0', marginLeft: 4}}
            icon={<Plus />}
            onClick={() => {
              setNovaEscala(true);
            }}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 16 }}>
          <p>Filtrar por:</p>
          <CheckboxGroup
            name="filtros-escala"
            style={{marginTop: 10}}
            onChange={(valor) => {
              if (valor !== undefined) {
                setFiltroStatus(valor[0])
              }
            }}
          >
            <Checkbox value="inativo">Inativos</Checkbox>
            <Checkbox value="ativo">Ativos</Checkbox>
          </CheckboxGroup>

        </div>

      </FlexboxGrid>
      <FlexboxGrid style={{ width: '100%' }}>
        <TablePagination
          carregando={carregando}
          dados={ajusteHorario.lista}
          quantidade={ajusteHorario.quantidade}
          onChangePage={setPagina}
          wordWrap="break-word"
        >
          <Column resizable width={300}>
            <HeaderCell>Estabelecimento</HeaderCell>
            <Cell dataKey="nome_fantasia" />
          </Column>
          <Column resizable width={250}>
            <HeaderCell>Escala</HeaderCell>
            <TextoFormatadoCell dataKey="escala" />
          </Column>
          <Column resizable width={170}>
            <HeaderCell>OBS</HeaderCell>
            <Cell dataKey="observacao" />
          </Column>
          <Column resizable width={170}>
            <HeaderCell>Data do lançamento</HeaderCell>
            <Cell dataKey="ultimoLancamento" />
          </Column>
          <Column resizable width={190}>
            <HeaderCell>Data do ultimo lançamento</HeaderCell>
            <Cell dataKey="ultimoDiaLancado" />
          </Column>
          <Column resizable width={100}>
            <HeaderCell>Autorizados</HeaderCell>
            <Cell dataKey="autorizados" />
          </Column>
          <Column resizable width={100}>
            <HeaderCell>Confirmados</HeaderCell>
            <Cell dataKey="confirmados" />
          </Column>
          <Column resizable width={120} >
            <HeaderCell>Ativo/Inativo</HeaderCell>
            <CelulaAtivacao dataKey="status" />
          </Column>
          <Column resizable width={60}>
            <HeaderCell>Editar</HeaderCell>
            <CelulaAcao
              dataKey="id"
              onEditar={(rowData) => {
                abrirEditarEscala(rowData);
              }}
            />
          </Column>
          <Column resizable width={100}>
            <HeaderCell align='left'>Excluir</HeaderCell>
            <Cell dataKey="id" align='left' style={{ padding: '5px' }}>
              {(rowData) => (
                <>
                  <IconButton
                    appearance="subtle"
                    onClick={() => {
                      setExluirEscala(rowData)
                      setModalExcluir(true)
                    }}
                    color="red"
                    icon={<TrashIcon />}
                  />
                </>
              )}
            </Cell>
          </Column>
        </TablePagination>
      </FlexboxGrid>


      <NovaEscala
        exibir={novaEscala}
        fechar={() => setNovaEscala(false)}
        salvar={addEscala}
      />
      <NovaEscala
        exibir={editarNovaEscala}
        fechar={() => setEditarNovaEscala(false)}
        salvar={updateEscala}
        newEscala={escalaSelecionada}
      />
      <ExcluirEscala
        modalExcluir={modalExcluir}
        setModalExcluir={setModalExcluir}
        exluirEscala={exluirEscala}
        setAtualizarLista={setAtualizarLista}
      />
    </Container>
  );
}
