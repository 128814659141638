/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { IconButton, Table } from 'rsuite';

import PropTypes from 'prop-types';
import { Edit, InfoRound } from '@rsuite/icons';
import theme from '../../../../styles/theme';

const { Cell } = Table;

export const CelulaRelatorio = ({ rowData, dataKey, ...props }) => (
  <Cell
    {...props}
    style={{
      background: rowData.status === 'Pago' && `${theme.darkGreen}`,
      color: rowData.status === 'Pago' && `${theme.whiteColor}`,
    }}
  >
    {rowData[dataKey]}
  </Cell>
);

export const CelulaRelatorioOpcoes = ({setExibirDetalhesDialog, abrirEditarProfissional, toggleMode, rowData, dataKey, ...props }) => {
  return (
  <Cell {...props} style={{ padding: '5px', background: rowData.status === 'Pago' && `${theme.darkGreen}` }}>
                <IconButton
                  title="Detalhes"
                  appearance="subtle"
                  onClick={() => {
                    setExibirDetalhesDialog({
                      exibir: true,
                      id_profissional: rowData.profissional.id,
                    });
                  }}
                  style={{ color: (rowData.status === 'Pago' || toggleMode) ? `${theme.whiteColor}` : '#575757' }}
                  icon={<InfoRound />}
                />
                <IconButton
                  title="Editar"
                  appearance="subtle"
                  onClick={() => {
                    abrirEditarProfissional(rowData.profissional);
                  }}
                  style={{ color: (rowData.status === 'Pago' || toggleMode) ? `${theme.whiteColor}` : '#575757' }}
                  icon={<Edit />}
                />
          </Cell>
)};

CelulaRelatorio.propTypes = {
  rowData: PropTypes.object,
  dataKey: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

CelulaRelatorio.defaultProps = {
  rowData: {},
  onChange: () => {},
};

CelulaRelatorioOpcoes.propTypes = {
  rowData: PropTypes.object,
  dataKey: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  setExibirDetalhesDialog: PropTypes.func,
  abrirEditarProfissional: PropTypes.func,
  toggleMode: PropTypes.bool,
};

CelulaRelatorioOpcoes.defaultProps = {
  toggleMode: false,
  rowData: {},
  onChange: () => {},
  setExibirDetalhesDialog: () => {},
  abrirEditarProfissional: () => {},
};
