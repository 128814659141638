/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Plus, SortDown, Trash } from '@rsuite/icons';

import { IconButton, Panel } from 'rsuite';
import api from '~/services/api';
import { Manual } from './styles';

import AdicionarManual from '../AdicionarManual';

function ListaManuais({
  manuais,
  onListaAlterada,
  children,
  idEstabelecimento,
}) {
  const [adicionarManualDialog, setAdicionarManualDialog] = useState(false);

  async function adicionarManual({ arquivo, id_profissao }) {
    setAdicionarManualDialog(false);
    try {
      const formData = new FormData();
      formData.append('manual', arquivo);
      await api.post(`/estabelecimento/manual/${idEstabelecimento}`, formData, {
        params: {
          id_profissao,
        },
      });
      toast.success('Manual Adicionado');
      onListaAlterada();
    } catch (e) {
      toast.error(e.message);
    }
  }

  async function removerManual(manual) {
    try {
      await api.delete(`/estabelecimento/manual/${manual.id}`);
      toast.success('Manual removido');
      onListaAlterada();
    } catch (e) {
      toast.error(e.message);
    }
  }

  return (
    <Panel
      header={<h4>Manuais</h4>}
      eventKey="123"
      bordered
      collapsible
      defaultExpanded
    >
      <div
        style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}
      >
        <h6>Adicionar Manual</h6>
        <IconButton
          style={{ marginTop: '0' }}
          icon={<Plus />}
          onClick={(e) => {
            e.preventDefault();
            setAdicionarManualDialog(true);
          }}
        />
      </div>
      {manuais &&
        manuais.map((manual) => (
          <Manual key={manual.id || manual.conta}>
            <h5>Manual: {manual.profissao.profissao}</h5>
            <div>
              <IconButton
                style={{ marginTop: '0' }}
                onClick={() => {
                  window.open(manual.arquivo.caminho);
                }}
                icon={<SortDown />}
                circle
                size="md"
              />
              <IconButton
                style={{ marginTop: '0' }}
                onClick={() => removerManual(manual)}
                icon={<Trash />}
                circle
                size="md"
              />
            </div>
          </Manual>
        ))}
      <AdicionarManual
        exibir={adicionarManualDialog}
        fechar={() => setAdicionarManualDialog(false)}
        // eslint-disable-next-line react/jsx-no-bind
        salvar={adicionarManual}
      />
      {children}
    </Panel>
  );
}

ListaManuais.propTypes = {
  idEstabelecimento: PropTypes.number.isRequired,
  manuais: PropTypes.array.isRequired,
  onListaAlterada: PropTypes.func.isRequired,
  children: PropTypes.arrayOf(PropTypes.element),
};

ListaManuais.defaultProps = {
  children: [],
};

export default ListaManuais;
