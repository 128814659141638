/* eslint-disable no-console */
import React, { useContext, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { DndProvider} from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { toast, ToastContainer } from 'react-toastify';
import './config/ReactotronConfig';

import {
  novaNotificacao,
  getLocation,
  novaNotificacaoNovoPedido,
  novaNotificacaoPedidoCancelado,
  novaNotificacaoNovoProfissional,
  novaNotificacaoNovoEstabelecimento,
  novaNotificacaoLocalizacao,
  novaNotificacaoAjusteDeHorario,
  ReduzirAlteracaoPedido,
  atualizacaoGlobalTempoReal,
  acrescentarContadorFalhaNaCobranca,
  reduzirContadorFalhaNaCobranca,
} from '~/store/modulos/notificacao/actions';
import { CustomProvider, IconButton } from 'rsuite'
import { Unvisible, Visible } from '@rsuite/icons';
import DarkModeProvider, { DarkModeContext } from './common/contexts/darkMode'
import Routes from './routes';
import history from './services/history';

import GlobalStyle from './styles/global';
import firebase from './services/firebase';
import { store, persistor } from './store';
import "rsuite/dist/rsuite.min.css"

function App() {
  const audioCancelamento = new Audio(
    'https://audio-portal.s3.sa-east-1.amazonaws.com/cancelamento.wav'
  );

  const audioNovoPedido = new Audio(
    'https://audio-portal.s3.sa-east-1.amazonaws.com/novo-pedido.wav'
  );
  useEffect(() => {
    firebase.messaging().onMessage((mensagem) => {
      if (mensagem.notification.title.includes('Alteração nas Notificações')) {
        const bodyParse = JSON.parse(mensagem.notification.body);
        const atualizacaoDados = {
          notificacoes: bodyParse.total,
          notificacoesNaoLidas: bodyParse.total,
          pedidosRecebidosNaoLidos: bodyParse.newpedido,
          pedidosCanceladosNaoLidos: bodyParse.pedidoscancelados,
          novosProfissionaisCriadosNaoLidos: bodyParse.newpro,
          novosEstabelecimentosCriadosNaoLidos: bodyParse.newestab,
          // localizacaoNaoLidas: bodyParse.localizacao,
          ajusteDeHorarioNaoLidos: bodyParse.ajustehorario,
        };
        store.dispatch(atualizacaoGlobalTempoReal(atualizacaoDados));
      }
      if (mensagem.notification.title.includes('Profissional novo criado')) {
        store.dispatch(novaNotificacaoNovoProfissional());
      }
      if (mensagem.notification.title.includes('Estabelecimento novo criado')) {
        store.dispatch(novaNotificacaoNovoEstabelecimento());
      }
      if (mensagem.notification.title.includes('Ajuste de horário')) {
        store.dispatch(novaNotificacaoAjusteDeHorario());
      }
      if (mensagem.notification.title.includes('Reduzir Alteração pedido')) {
        store.dispatch(ReduzirAlteracaoPedido());
      }
      if (mensagem.notification.title.includes('Falha na cobrança')) {
        store.dispatch(acrescentarContadorFalhaNaCobranca());
      }
      if (mensagem.notification.title.includes('Reduzir cobrança')) {
        store.dispatch(reduzirContadorFalhaNaCobranca());
      }
      if (mensagem.notification.title.includes('Localização') === false) {
        toast.info(mensagem.notification.title);
      }
      if (mensagem.notification.title.includes('Pedido cancelado')) {
        store.dispatch(novaNotificacaoPedidoCancelado());
        audioCancelamento.volume = 0.3;
        audioCancelamento.play();
      } else if (mensagem.notification.title.includes('Novo pedido')) {
        store.dispatch(novaNotificacaoNovoPedido());
        audioNovoPedido.volume = 0.3;
        audioNovoPedido.play();
      } else if (mensagem.notification.title.includes('Localização')) {
        store.dispatch(novaNotificacaoLocalizacao());
        if (window.location.href.includes('/home/pedidos/registros-dia')) {
          store.dispatch(getLocation());
        }
      }

      store.dispatch(novaNotificacao());
    });
  }, [audioCancelamento, audioNovoPedido]);

  const { toggleMode } = useContext(DarkModeContext)

  return (
    <DndProvider backend={HTML5Backend}>
    <CustomProvider theme={toggleMode ? "dark" : "light"}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Router history={history}>
            <Routes />
            <GlobalStyle />
            <ToastContainer />
          </Router> 
        </PersistGate>
      </Provider>
    </CustomProvider>
    </DndProvider>
  );
}

export default App;
