/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Table } from 'rsuite';

import PropTypes from 'prop-types';
import theme from '../../../../styles/theme';

const { Cell } = Table;

export const CelulaRelatorio = ({ rowData, dataKey, ...props }) => (
  <Cell
    {...props}
    style={{
      background: rowData.status === 'Pago' && `${theme.darkGreen}`,
      color: rowData.status === 'Pago' && `${theme.whiteColor}`,
    }}
  >
    {rowData[dataKey]}
  </Cell>
);

CelulaRelatorio.propTypes = {
  rowData: PropTypes.object,
  dataKey: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

CelulaRelatorio.defaultProps = {
  rowData: {},
  onChange: () => {},
};
