import React, { useState, useContext } from 'react'
import { ButtonBorderBlue } from '~/components/Buttons/ButtonBorderBlue/styles'
import { Modal, Button, InputGroup, AutoComplete, Checkbox } from 'rsuite';
import { Search } from '@rsuite/icons';
import { InputDataRange } from '~/components/InputDataRange';
import useSearchNames from '~/services/useSearchNames';
import JSPDF from 'jspdf';
import { DarkModeContext } from '~/common/contexts/darkMode';
import { format, eachDayOfInterval, parseISO, compareAsc } from 'date-fns';
import { toast } from 'react-toastify';
import { ButtonFullBlue } from '~/components/Buttons/ButtonFullBlue/styles';
import api from '~/services/api';
import { ModalContainer, CheckboxDiv, PrincipalDiv } from './styles';


const ModalPdfEscala = () => {
    const [exibir, setExibir] = useState(false);
    const { toggleMode } = useContext(DarkModeContext)
    const locale = {
        sunday: 'Dom',
        monday: 'Seg',
        tuesday: 'Ter',
        wednesday: 'Qua',
        thursday: 'Qui',
        friday: 'Sex',
        saturday: 'Sab',
        ok: 'Ok',
        today: 'Hoje',
        yesterday: 'Ontem',
        hours: 'Horas',
        minutes: 'Minutos',
        seconds: 'Segundos',
    };
    const [datas, setDatas] = useState();
    const [filtroEstabelecimento, setFiltroEstabelecimento] = useState();
    const [nomesEstabelecimentos, setNomesEstabelecimentos] = useState([]);
    const [filtroConfirmados, setFiltroConfirmados] = useState(false);
    const [filtroSemProfissional, setFiltroSemProfissional] = useState(false);

    const buscarNomesEstabelecimentos = useSearchNames(
        setNomesEstabelecimentos,
        'estabelecimentos',
        setFiltroEstabelecimento
    );

    async function exportPDF() {

        if (!datas) {
            toast.error('Selecione uma data');
            return;
        }

        if(filtroConfirmados && filtroSemProfissional) {
            toast.error('Selecione apenas um filtro')
            return;
        }
        try {

            
            const resposta = await api.get(`/pedidos-adm/estabelecimento/${filtroEstabelecimento}`,
                {
                    params: {
                        data: datas,
                        filtroConfirmados,
                        filtroSemProfissional
                    },
                })
            const lista = resposta.data.lista.sort((a, b) => compareAsc(parseISO(a.data), parseISO(b.data))).map((l) => ({

                ...l,
                nome: l.nome ? l.nome : '-',
                hora_inicio: format(parseISO(l.hora_inicio), 'HH:mm'),
                hora_fim: format(parseISO(l.hora_fim), 'HH:mm'),
                intervalo: l.intervalo
                    ? `${l.intervalo} min`
                    : `00h`,
                data: format(parseISO(l.data), 'dd/LL/yyyy'),
                numero_registro: `${l.numero_registro}-${l.id}`

            }))



            console.log(lista)

            if (lista.length > 0) {
                const unit = 'pt';
                const size = 'A3'; // Use A1, A2, A3 or A4
                const orientation = 'landscape'; // portrait or landscape

                const marginLeft = 40;
                const doc = new JSPDF(orientation, unit, size);

                const title = `${lista[0].nome_fantasia} | ${format(new Date(datas[0]), 'dd/LL/yyyy')} - ${format(new Date(datas[1]), 'dd/LL/yyyy')}`;


                const columns = [
                    { header: 'Registro', dataKey: 'numero_registro' },
                    { header: 'Função', dataKey: 'profissao' },
                    { header: 'Dia', dataKey: 'data' },
                    { header: 'Hora Inicio', dataKey: 'hora_inicio' },
                    { header: 'Hora Fim', dataKey: 'hora_fim' },
                    { header: 'Intervalo', dataKey: 'intervalo' },
                    { header: 'Nome Profissional', dataKey: 'nome' },
                    { header: 'Status', dataKey: 'status' }
                ];




                doc.text(title, marginLeft, 40);
                doc.autoTable({
                    startY: 50,
                    body: lista,
                    theme: 'striped',
                    columns,
                });


                doc.save(
                    `${lista[0].nome_fantasia} - ${format(new Date(datas[0]), 'dd/LL/yyyy')} - ${format(new Date(datas[1]), 'dd/LL/yyyy')}`
                );

                setExibir(false)
                setFiltroConfirmados(false)
                setFiltroSemProfissional(false)
            } else {
                toast.error('Não existe escala nesta data')
            }





        } catch (e) {
            toast.error(e);
            console.log(e)
        }



    }

   

    return (
        <>
            <ButtonBorderBlue appearance="ghost" onClick={() => setExibir(true)} darkMode={toggleMode}>PDF Escala</ButtonBorderBlue>
            <Modal open={exibir} onClose={() => setExibir(false)} size="md">
                <Modal.Header>
                    <Modal.Title><h5>PDF Escala</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <PrincipalDiv>
                        <ModalContainer>

                            <InputGroup style={{ width: '250px' }}>
                                <AutoComplete
                                    name="nome_estabelecimento"
                                    style={{ width: '250px', height: '36px' }}
                                    data={nomesEstabelecimentos.map((p) => ({
                                        label: p.nome_fantasia,
                                        value: p.nome_fantasia,
                                        id: p.id,
                                    }))}
                                    placeholder="Nome do Cliente"
                                    onSelect={async (value, item) => {
                                        setFiltroEstabelecimento(item.id);
                                    }}
                                    onChange={buscarNomesEstabelecimentos}
                                />

                                <InputGroup.Button>
                                    <Search />
                                </InputGroup.Button>
                            </InputGroup>

                            <InputDataRange
                                name="data"
                                style={{ marginRight: '15px', width: '230px' }}
                                ranges={[]}
                                isoWeek
                                locale={locale}
                                onChange={setDatas}
                                value={datas}
                                placeholder='Insira uma Data'
                            />


                        </ModalContainer>
                        <CheckboxDiv>
                            <p>Filtrar somente pedidos:</p>
                            <Checkbox
                                value={filtroConfirmados}
                                style={{ margin: 0, padding: 0 }}
                                onChange={(a, valor) => {
                                    setFiltroConfirmados(valor);
                                }}
                                title="Filtrar dados por Pedidos Confirmados e Aceitos"
                            >
                                Confirmados/Aceitos
                            </Checkbox>
                            <Checkbox
                                value={filtroSemProfissional}
                                style={{ margin: 0, padding: 0 }}
                                onChange={(a, valor) => {
                                    setFiltroSemProfissional(valor);
                                }}
                                title="Filtrar dados por Pedidos sem Profissionais "
                            >
                                Sem Profissionais
                            </Checkbox>
                        </CheckboxDiv>

                    </PrincipalDiv>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setExibir(false)} appearance="subtle">
                        Cancelar
                    </Button>


                    <ButtonFullBlue
                        appearance="primary"
                        // eslint-disable-next-line no-alert
                        onClick={async () => exportPDF()}
                    >
                        Finalizar
                    </ButtonFullBlue>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ModalPdfEscala