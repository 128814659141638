/* eslint-disable react/jsx-no-bind */
/* eslint-disable prettier/prettier */
import React, { useCallback, useEffect, useState } from 'react';
import { RemindFill } from '@rsuite/icons';
import { Table, FlexboxGrid, RadioGroup, Radio, Modal, Button, CheckPicker, SelectPicker, AutoComplete } from 'rsuite';
import { format, parseISO } from 'date-fns';
import { toast } from 'react-toastify';
import { Container } from '~/styles/tabela';
import api from '~/services/api';
import useSearchNames from '~/services/useSearchNames';
import { InputDataRange } from '~/components/InputDataRange';
import { TablePagination } from '~/components/TablePagination';
import { FiltroCidadesPicker } from '~/components/FiltroCidadesPicker';
import CelulaAcao from './components/CelulaAcao';
import { ColContainer } from './styles';



const { Column, Cell, HeaderCell } = Table;

function RelatorioNotificacoes() {
  const [notificacoes, setNotificacoes] = useState([]);
  const [apagarNotificacaoDialog, setApagarNotificacaoDialog] = useState({
    exibir: false,
    notificacao: {},
  });
  const [infoCidades, setInfoCidades] = useState([]);
  const [cidadeSelecionada, setCidadeSelecionada] = useState([]);
  const [procuraUsuario, setProcuraUsuario] = useState('');
  const [tipoNotificacao, setTipoNotificacao] = useState('Profissional');
  const [pagina, setPagina] = useState(1);
  const [usuarios, setUsuarios] = useState([]);
  const [datas, setDatas] = useState();
  const [carregando, setCarregando] = useState(true);
  const [filtroData, setFiltroData] = useState([]);
  const locale = {
    sunday: 'Dom',
    monday: 'Seg',
    tuesday: 'Ter',
    wednesday: 'Qua',
    thursday: 'Qui',
    friday: 'Sex',
    saturday: 'Sab',
    ok: 'Ok',
    today: 'Hoje',
    yesterday: 'Ontem',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos',
  };

  const [orderBy, setOrderBy] = useState('order by created_at desc');



  async function buscarRelatorios() {
    setCarregando(true);
    try {
      const resposta = await api.get('relatorios/notificacoes', {
        params: {
          pagina,
          tipoNotificacao,
          data: filtroData,
          cidades: cidadeSelecionada,
          orderBy,
          usuario: procuraUsuario,
        },
      });

      const cidades = await api.get('cidades');
      setInfoCidades(cidades.data)

      const lista = resposta.data.lista.map((item) => ({
        ...item,
        data_criacao: item.created_at
          ? format(parseISO(item.created_at), 'dd/LL/yyyy')
          : '',
        horario_criacao: item.created_at
          ? format(new Date(item.created_at), 'HH:mm')
          : '',
        data_atividade: item.filtro_data_atividade
          ? `${format(
            parseISO(item.filtro_data_atividade[0]),
            'dd/LL/yyyy'
          )} - ${format(
            parseISO(item.filtro_data_atividade[1]),
            'dd/LL/yyyy'
          )}`
          : '-',
        avaliacao: item.filtro_avaliacao_media
          ? `${item.filtro_avaliacao_media[0]} - ${item.filtro_avaliacao_media[1]}`
          : '-',
        filtros: item.filtrosFormatados !== '' ? item.filtrosFormatados : '-',
      }));
      setNotificacoes({
        lista,
        quantidade: lista.length > 0 ? lista[0].full_count : 0,
      });
      if(notificacoes.quantidade === 0) setPagina(1)
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
  }


  useEffect(() => {
    buscarRelatorios();

  }, [pagina, tipoNotificacao, orderBy, filtroData, cidadeSelecionada, procuraUsuario, notificacoes.quantidade]);






  async function abrirApagarDialog(notificacao) {
    setApagarNotificacaoDialog({
      exibir: true,
      notificacao,
    });
  }

  async function apagarNotificacao() {
    setCarregando(true);
    try {
      await api.delete('relatorios/notificacoes', {
        params: {
          id: apagarNotificacaoDialog.notificacao.id,
        },
      });
      toast.success('Notificação apagada com sucesso!');
      buscarRelatorios();
    } catch (err) {
      toast.error(err.message);
    }
    setCarregando(false);
  }


  const buscarNomesUsuarios = useSearchNames(
    setUsuarios,
    'usuarios',
    setProcuraUsuario
  )


  function test (){

    console.log(procuraUsuario)
  }
  return (
    <Container>
      <h2>Relatório Notificações</h2>
      <FlexboxGrid justify="start">
        <RadioGroup
          name="radioList"
          inline
          appearance="picker"
          defaultValue={tipoNotificacao}
          onChange={(value) => setTipoNotificacao(value)}
          style={{ marginRight: '10px', marginTop: '20px' }}
        >
          <Radio value="Profissional">Profissionais</Radio>
          <Radio value="Estabelecimento">Estabelecimentos</Radio>
        </RadioGroup>
        <ColContainer>
          <p>Data</p>
          <InputDataRange
            name="data"
            style={{ marginRight: '15px', width: '230px' }}
            ranges={[]}
            isoWeek
            locale={locale}
            onChange={setFiltroData}
            value={filtroData}
            placeholder='Insira uma Data'
          />
        </ColContainer>
        <ColContainer>
          <p>Cidades</p>
          <FiltroCidadesPicker
            style={{ width: 224, marginRight: 15 }}
            appearance="default"
            data={infoCidades.map((cidades) => ({
              label: cidades.cidade,
              value: cidades.id,
            }))}
            value={cidadeSelecionada}
            onChange={(value) => setCidadeSelecionada(value)}
          />
        </ColContainer>
        <ColContainer>
          <p>Usuários</p>
          <AutoComplete
            style={{ width: 224 }}
            appearance="default"
            placeholder="Usuários"
            data={usuarios.map((users) => ({
              label: users.nome,
              value: users.nome,
              id: users.id,
            }))}
            onSelect={(value) => setProcuraUsuario(value)}
            onChange={buscarNomesUsuarios}


          />
        </ColContainer>
      </FlexboxGrid>
      <FlexboxGrid style={{ width: '100%' }}>
        <TablePagination
          carregando={carregando}
          dados={notificacoes.lista}
          onChangePage={setPagina}
          quantidade={notificacoes.quantidade}
          virtualized
          setOrderBy={setOrderBy}
        >
          <Column resizable width={110}>
            <HeaderCell>Notificados</HeaderCell>
            <Cell dataKey="total_notificados" />
          </Column>
          <Column resizable width={100} sortable>
            <HeaderCell>Data</HeaderCell>
            <Cell dataKey="data_criacao" />
          </Column>
          <Column resizable width={100} sortable>
            <HeaderCell>Horário</HeaderCell>
            <Cell dataKey="horario_criacao" />
          </Column>
          <Column resizable width={130}>
            <HeaderCell>Usuário</HeaderCell>
            <Cell dataKey="enviado_por" />
          </Column>
          <Column resizable width={150}>
            <HeaderCell>Titulo</HeaderCell>
            <Cell dataKey="titulo" />
          </Column>
          <Column resizable width={250}>
            <HeaderCell>Mensagem</HeaderCell>
            <Cell dataKey="mensagem" />
          </Column>
          {tipoNotificacao === 'Profissional' && (
            <Column resizable width={100}>
              <HeaderCell>Avaliação</HeaderCell>
              <Cell dataKey="avaliacao" />
            </Column>
          )}
          <Column resizable width={250}>
            <HeaderCell>Data Atividade</HeaderCell>
            <Cell dataKey="data_atividade" />
          </Column>

          <Column resizable width={250}>
            <HeaderCell>Filtros</HeaderCell>
            <Cell dataKey="filtros" />
          </Column>
          <Column resizable width={200}>
            <HeaderCell>Deletar</HeaderCell>
            <CelulaAcao dataKey="id" onClick={abrirApagarDialog} />
          </Column>
        </TablePagination>
      </FlexboxGrid>
      <Modal
        backdrop="static"
        open={apagarNotificacaoDialog.exibir}
        onClose={() => setApagarNotificacaoDialog(false)}
        size="xs"
      >
        <Modal.Header>
          <h3>Apagar Notificação</h3>
        </Modal.Header>
        <Modal.Body>
          Você realmente deseja apagar esta notificação? <br /> <br />
          <strong>Notificação:</strong>{' '}
          {apagarNotificacaoDialog.notificacao
            ? apagarNotificacaoDialog.notificacao.titulo
            : ''}{' '}
          <br />
          Essa ação é irreversível{' '}
          <RemindFill
            style={{
              color: '#ffb300',
              fontSize: 24,
            }}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={() => setApagarNotificacaoDialog(false)}
            appearance="subtle"
          >
            Cancelar
          </Button>
          <Button
            onClick={() => {
              apagarNotificacao();
              setApagarNotificacaoDialog(false);
            }}
            appearance="primary"
          >
            Deletar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default RelatorioNotificacoes;
