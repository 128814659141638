export function obterExtremosSemana(data) {
  const dataAtual = new Date(data);

  // Obtenha o dia da semana (0 = Domingo, 1 = Segunda, ..., 6 = Sábado)
  const diaSemana = dataAtual.getDay();

  // Calcule a diferença entre o dia atual e o início da semana (segunda-feira)
  const diferenca = diaSemana - 1;
  const inicioSemana = new Date(dataAtual);
  inicioSemana.setDate(dataAtual.getDate() - diferenca);

  // Adicione 6 dias para obter o final da semana
  const finalSemana = new Date(inicioSemana);
  finalSemana.setDate(inicioSemana.getDate() + 6);

  return  [inicioSemana, finalSemana];
  
}
export function obterExtremosMes(data) {


  const date = new Date(data);

  const primeiroDia = new Date(date.getFullYear(), date.getMonth(), 1);
  const ultimoDia = new Date(date.getFullYear(), date.getMonth() + 1, 0);


  return  [primeiroDia, ultimoDia]
}