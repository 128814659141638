/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { DateRangePicker, CustomProvider } from 'rsuite';
import ptBR from 'rsuite/locales/pt_BR';

export function InputDataRange(props) {
 
  return (<CustomProvider locale={ptBR}>
  <DateRangePicker format="dd/MM/yyyy" placeholder="Data" {...props} />
  </CustomProvider>
  );
}
