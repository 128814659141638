/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell as CellPie, Legend } from 'recharts';
import PropTypes from 'prop-types';
import {
  renderActiveShape,
  colors,
  buscarDados,
} from '../../../../PedidosCancelados/GraficoRecharts/utils/index';

function GraficoPieChart({ route, filtroData, periodoSelecionado = null }) {
  const [graficoMotivo, setGraficoMotivo] = useState([]);
  const [data01, setData01] = useState([]);

  useEffect(() => {
    const data = [];
    graficoMotivo.map((item) => {
      return data.push({ name: item.label, value: Number(item.quantidade) });
    });
    setData01(data);
  }, [graficoMotivo]);

  const [pieIndex, setPieIndex] = useState(0);
  const onPieEnter = (_, index) => {
    setPieIndex(index);
  };

  useEffect(() => {
    buscarDados(setGraficoMotivo, `relatorios/graficos/${route}`, {
      route,
      filtroData,
      periodo: periodoSelecionado,
    });
  }, [route, filtroData, periodoSelecionado]);

  return (
    <>
      <PieChart width={800} height={650} className="pieChartOverLabel">
        <Pie
          onMouseEnter={onPieEnter}
          style={{ marginLeft: '10px' }}
          data={data01}
          cx={240}
          cy={210}
          innerRadius={80}
          outerRadius={160}
          fill="#8884d8"
          paddingAngle={1}
          dataKey="value"
          activeIndex={pieIndex}
          activeShape={renderActiveShape}
        >
          {data01.map((entry, index) => (
            <CellPie
              key={`cell-${index}`}
              fill={colors[index % colors.length]}
            />
          ))}
        </Pie>
        <Legend
          style={{ marginTop: '50px !important' }}
          iconSize={10}
          layout="radial"
        />
      </PieChart>
    </>
  );
}

GraficoPieChart.propTypes = {
  route: PropTypes.func.isRequired,
  filtroData: PropTypes.func.isRequired,
  periodoSelecionado: PropTypes.string.isRequired,
};

export default GraficoPieChart;
