import { Schema } from 'rsuite';

const { StringType, NumberType, ArrayType, DateType, ObjectType } = Schema.Types;

export const modeloEdicao = Schema.Model({
  nome: StringType().isRequired('Nome obrigatório'),
  servico: NumberType().isRequired('Serviço obrigatório'),
  // data_solicitacao: StringType().isRequired('Data obrigatória'),
  data: StringType(),
  quantidade: NumberType().isRequired('Quantidade obrigatória'),
  salario: StringType(),
  // tipo_contrato: StringType().isRequired('Tipo de contrato obrigatório'),
  tipo_contrato: StringType(),
  previsao_inicio: StringType(),
  jornada: StringType(),
  perfil: StringType(),
  beneficio: ArrayType(),
  experiencia: StringType(),
  observacao: StringType(),
  anotacoes: StringType(),
});
