import React, { useEffect, useState, useCallback } from 'react';
import { Edit, InfoRound, Page, Trash } from '@rsuite/icons';

import { IconButton, Table, Modal, Button, FlexboxGrid, Input } from 'rsuite';
import { toast } from 'react-toastify';
import { format, formatDistanceToNow, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import { useDebouncedCallback } from 'use-debounce/lib';
import { Container } from '~/styles/tabela';
import api from '~/services/api';
import { TabelaPaginacao } from '~/components/TabelaPaginacao';
import { ButtonFullBlue } from '../../../components/Buttons/ButtonFullBlue/styles';
import ShowAnnouncement from '../components/ShowAnnouncement/index';
import EditAnnouncement from '../components/EditAnnouncement';
import AnnouncementsEstablishmentFilters from '../components/AnnouncementsEstablishmentFilters';

const { HeaderCell, Column, Cell } = Table;

function AnnouncementsEstablishmentList() {
  const [announcements, setAnnouncements] = useState({
    list: [],
    count: 0,
  });
  const [loading, setLoading] = useState(true);

  const [showAnnouncement, setShowAnnouncement] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [editAnnouncement, setEditAnnouncement] = useState({});
  const [showDialog, setShowDialog] = useState(false);
  const [showEditAnnouncement, setShowEditAnnouncement] = useState(false);
  const [filters, setFilters] = useState();
  const [cidade, setCidade] = useState('');
  const [bairro, setBairro] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [pagina, setPagina] = useState(1);

  const listAnnouncements = useCallback(async () => {
    setLoading(true);
    try{
      const resp = await api.get('announcements/estabelecimento', {
        params: {
          cidade,
          bairro,
          orderBy,
          pagina,
        }
      })
      setAnnouncements({
        list: resp.data.rows.map((announcement) => ({
          ...announcement,
          created_at: formatDistanceToNow(new Date(announcement.createdAt), {
            locale: pt,
          }),
          data_criacao: format(parseISO(announcement.createdAt), 'dd/LL/yyyy'),
          horario_criacao: format(new Date(announcement.createdAt), 'HH:mm'),
        })),
        count: resp.data.count,
      });
    } catch (err) {
      toast.error(err.message)
    }
    // await api
    //   .get('announcements/estabelecimento')
    //   .then((response) => {
    //     setAnnouncements({
    //       list: response.data.rows.map((announcement) => ({
    //         ...announcement,
    //         created_at: formatDistanceToNow(new Date(announcement.createdAt), {
    //           locale: pt,
    //         }),
    //         data_criacao: format(parseISO(announcement.createdAt), 'dd/LL/yyyy'),
    //         horario_criacao: format(new Date(announcement.createdAt), 'HH:mm'),
    //       })),
    //       count: response.data.count,
    //     });

    //     setLoading(false);
    //   })
    //   .catch((_) => {
    //     setLoading(false);
    //   });
    if(announcements.count > 0 && announcements.list.length === 0) setPagina(1);
    setLoading(false)
  }, [cidade, bairro, pagina, orderBy, announcements.count, announcements.list.length]);

  async function getAnnouncement(announcement_id, type) {
    setLoading(true);
    await api
      .get(`/announcements/estabelecimento/${announcement_id}`)
      .then((response) => {
        setEditAnnouncement({
          ...response.data,
          cover: response.data.cover.caminho,
        });
        setLoading(false);
        if (type === 'visualizar') {
          setShowAnnouncement(true);
        } else if (type === 'editar') {
          setShowEditAnnouncement(true);
        } else if (type === 'filters') {
          setShowFilters(true);
        }
      });
  }

  async function getFilter(id) {
    await api
      .get(`announcements/estabelecimento/filters/${id}`)
      .then((response) => {
        setFilters(response.data);
      })
      .catch((err) => toast.error(err.message));
  }

  async function deleteAnnouncement(id) {
    setLoading(true);
    await api
      .delete(`/announcements/estabelecimento/${id}`)
      .then((_) => {
        toast.success('Comunicado deletado com sucesso!');
        listAnnouncements();
        setLoading(false);
      })
      .catch((err) => toast.error(err.message));
  }

  function closeModal() {
    setShowAnnouncement(false);
    setShowFilters(false);
  }

  // async function filterAnnouncementByCity(value) {
  //   if (value !== '') {
  //     setLoading(true);
  //     await api
  //       .get(`announcements/estabelecimento/city/${value}`)
  //       .then((response) => {
  //         setAnnouncements({
  //           list: response.data.rows.map((announcement) => ({
  //             ...announcement,
  //             created_at: formatDistanceToNow(
  //               new Date(announcement.createdAt),
  //               {
  //                 locale: pt,
  //               }
  //             ),
  //             data_criacao: format(parseISO(announcement.createdAt), 'dd/LL/yyyy'),
  //             horario_criacao: format(new Date(announcement.createdAt), 'HH:mm'),
  //           })),
  //           count: response.data.count,
  //         });

  //         setLoading(false);
  //       });
  //   } else {
  //     listAnnouncements();
  //   }
  // }

  // async function filterAnnouncementByDistrict(value) {
  //   if (value !== '') {
  //     setLoading(true);
  //     await api
  //       .get(`announcements/estabelecimento/district/${value}`)
  //       .then((response) => {
  //         setAnnouncements({
  //           list: response.data.rows.map((announcement) => ({
  //             ...announcement,
  //             created_at: formatDistanceToNow(
  //               new Date(announcement.createdAt),
  //               {
  //                 locale: pt,
  //               }
  //             ),
  //             data_criacao: format(parseISO(announcement.createdAt), 'dd/LL/yyyy'),
  //             horario_criacao: format(new Date(announcement.createdAt), 'HH:mm'),
  //           })),
  //           count: response.data.count,
  //         });

  //         setLoading(false);
  //       });
  //   } else {
  //     listAnnouncements();
  //   }
  // }

  useEffect(() => {
    listAnnouncements();
  }, [listAnnouncements]);

  return (
    <Container>
      <h2>Comunicados Estabelecimentos</h2>
      <FlexboxGrid style={{ gap: '10px' }} justify="start">
        <Input
          style={{ width: '250px' }}
          placeholder="Cidade"
          onChange={
            // useDebouncedCallback(filterAnnouncementByCity, 500).callback
            useDebouncedCallback(setCidade, 500).callback
          }
        />

        <Input
          style={{ width: '250px' }}
          placeholder="Bairro"
          onChange={
            // useDebouncedCallback(filterAnnouncementByDistrict, 500).callback
            useDebouncedCallback(setBairro, 500).callback
          }
        />
      </FlexboxGrid>
      <br />
      <FlexboxGrid style={{ width: '100%' }}>
        <TabelaPaginacao
          carregando={loading}
          dados={announcements.list}
          quantidade={announcements.count}
          onChangePage={setPagina}
          setOrderBy={setOrderBy}
        >
          <Column resizable width={120}>
            <HeaderCell>Opções</HeaderCell>
            <Cell dataKey="id" style={{ padding: '5px' }}>
              {(rowData) => (
                <>
                  <IconButton
                    title="Visualizar"
                    onClick={() => {
                      getAnnouncement(rowData.id, 'visualizar');
                    }}
                    appearance="subtle"
                    icon={<Page />}
                  />
                  <IconButton
                    title="Editar"
                    appearance="subtle"
                    onClick={() => {
                      getAnnouncement(rowData.id, 'editar');
                    }}
                    icon={<Edit />}
                  />
                  <IconButton
                    title="Deletar"
                    onClick={() => {
                      setEditAnnouncement(rowData);
                      setShowDialog(true);
                    }}
                    appearance="subtle"
                    icon={<Trash />}
                  />
                </>
              )}
            </Cell>
          </Column>
          <Column resizable width={260} sortable>
            <HeaderCell>Título</HeaderCell>
            <Cell dataKey="title" />
          </Column>

          <Column resizable width={240} sortable>
            <HeaderCell>Resumo</HeaderCell>
            <Cell dataKey="summary" />
          </Column>

          <Column resizable width={150} sortable>
            <HeaderCell>Data de Envio</HeaderCell>
            <Cell dataKey="data_criacao" />
          </Column>
          <Column resizable width={150} sortable>
            <HeaderCell>Horário de Envio</HeaderCell>
            <Cell dataKey="horario_criacao" />
          </Column>
          <Column resizable width={150}>
            <HeaderCell>Filtros</HeaderCell>
            <Cell style={{ padding: '5px' }}>
              {(rowData) => (
                <IconButton
                  title="Filtros"
                  onClick={() => {
                    getAnnouncement(rowData.id, 'filters');
                    getFilter(rowData.id);
                  }}
                  appearance="subtle"
                  icon={<InfoRound />}
                />
              )}
            </Cell>
          </Column>
        </TabelaPaginacao>
      </FlexboxGrid>

      <ShowAnnouncement
        show={showAnnouncement}
        announcement={editAnnouncement}
        // eslint-disable-next-line react/jsx-no-bind
        closeModal={closeModal}
      />
      <EditAnnouncement
        setShowEditAnnouncement={setShowEditAnnouncement}
        target="estabelecimento"
        showEditAnnouncement={showEditAnnouncement}
        announcement={editAnnouncement}
        listAnnouncements={listAnnouncements}
        setLoading={setLoading}
      />
      <AnnouncementsEstablishmentFilters
        show={showFilters}
        announcement={editAnnouncement}
        // eslint-disable-next-line react/jsx-no-bind
        closeModal={closeModal}
        filters={filters}
      />

      <Modal
        backdrop="static"
        open={showDialog}
        onClose={() => {
          setShowDialog(false);
        }}
        size="xs"
      >
        <Modal.Header>
          <h3>Deletar Comunicado</h3>
        </Modal.Header>
        <Modal.Body>
          <p>
            Deseja mesmo deletar <strong>{editAnnouncement.title}</strong>?{' '}
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={() => {
              setShowDialog(false);
            }}
            appearance="subtle"
          >
            Cancelar
          </Button>
          <ButtonFullBlue
            type="button"
            appearance="primary"
            onClick={() => {
              deleteAnnouncement(editAnnouncement.id);
              setShowDialog(false);
            }}
          >
            Deletar
          </ButtonFullBlue>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default AnnouncementsEstablishmentList;
