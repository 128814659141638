
import styled, {css} from "styled-components";
import theme from "~/styles/theme";


export const Card = styled.div`
    display: flex;
    flex-direction: column;
    border: 0.1px solid rgba(206, 211, 224, 1);
    border-bottom-left-radius: 3.2vh;
    border-bottom-right-radius: 3.2vh;
    width: 310px;
    height: 1000px;
    margin-left: ${(props) => props.leftMargin || '0px'};
    overflow-y: scroll;
    
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #CCCCCC;
      border-radius: 50px;
    }
    
    ${props => props.isLoading && css`
        filter: blur(4px);

    `}

`

export const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    z-index: 1;
    

`

export const ColContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 1200px;
    margin-left: ${(props) => props.leftMargin || '0px'};
`

export const Header = styled.div`

    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-left-radius: 1vh;
    border-top-right-radius: 1vh;
    background-color: ${(props) => props.color || 'black'};
    width: 100%;
    height: 44px;
    overflow: hidden;
    
    

    h6 {
        color: ${(props) => props.textColor || 'white'};
        font-size: 14px;
        margin-left: 8px;
    }

    p {
        margin-right: 12px;
        color:${(props) => props.textColor || 'white'};
    }
`

export const ContadorDiv = styled.div`
    display: flex;
    width: 100%;
    height: 26px;
    border-left: 0.1px solid rgba(206, 211, 224, 1);
    border-right: 0.1px solid rgba(206, 211, 224, 1);
    background-color: rgba(247, 249, 255, 1);
    
    p {
        margin-top: 6px;
        margin-left: 5px;
        font-size: 12px;
    }

`

export const InfoDiv = styled.div`
    display: flex;
    padding: 6px;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid ${theme.darkGray};
    flex-direction: row;
    height: 80px;
`

export const VerMais = styled.div`
    display: flex;
    border-left: 1px solid ${theme.darkGray};
    border-bottom: 1px solid ${theme.darkGray};
    border-right: 1px solid ${theme.darkGray};
    justify-content: center;
    border-bottom-left-radius: 4vh;
    border-bottom-right-radius: 4vh;
    height: 50px;
`

export const TextDiv = styled.div`
    display: flex;
    white-space: nowrap;
    align-items: ${(props) => props.alignItems};
    flex-direction: column;
    justify-content: ${(props) => props.justify};
    margin-left: ${(props) => props.marginLeft};




`



