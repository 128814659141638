/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import { Loader, Table, SelectPicker, FlexboxGrid } from 'rsuite';
import { format } from 'date-fns';

import pt from 'date-fns/locale/pt-BR';
import { toast } from 'react-toastify';

import api from '~/services/api';
import formatCurrency from '~/utils/formatCurrency';
import { TabelaPaginacao } from '~/components/TabelaPaginacao';
import { Container } from './styles';

const { Column, Cell, HeaderCell } = Table;

function Dashboard() {
  const [dados, setDados] = useState([]);

  const datas = [
    { value: 2020, label: 2020 },
    { value: 2021, label: 2021 },
    { value: 2022, label: 2022 },
    { value: 2023, label: 2023 },
    { value: 2024, label: 2024 },
    { value: 2025, label: 2025 },
  ];

  const [carregando, setCarregando] = useState(true);

  async function buscarRelatorios(ano = 2021) {
    setCarregando(true);
    try {
      const resposta = await api.get('relatorios/metricas', {
        params: {
          ano,
        },
      });
      const lista = resposta.data.map((item) => {
        return {
          ...item,
          mes: format(new Date(item.mes), 'MMMM', {
            locale: pt,
          }),
          intermediacao: `${item.intermediacao.toFixed(2)} %`,
          faturamento: formatCurrency(item.faturamento),
          repasse: formatCurrency(item.repasse),
          receita: formatCurrency(item.receita),
        };
      });
      setDados(lista);
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
  }

  useEffect(() => {
    buscarRelatorios();
  }, []);

  return (
    <Container>
      <h2>Métricas</h2>

      <FlexboxGrid justify="start">
        <SelectPicker
          searchable={false}
          data={datas}
          onChange={buscarRelatorios}
          defaultValue={2021}
          cleanable={false}
          style={{ width: 300 }}
        />
      </FlexboxGrid>
      <FlexboxGrid style={{ width: '100%' }}>
      <TabelaPaginacao carregando={carregando} dados={dados}>
        <Column resizable width={240} sortable>
          <HeaderCell>Mês</HeaderCell>
          <Cell dataKey="mes" />
        </Column>
        <Column resizable width={150} sortable>
          <HeaderCell>Faturamento</HeaderCell>
          <Cell dataKey="faturamento" />
        </Column>
        <Column resizable width={150} sortable>
          <HeaderCell>Repasse</HeaderCell>
          <Cell dataKey="repasse" />
        </Column>
        <Column resizable width={150} sortable>
          <HeaderCell>Receita</HeaderCell>
          <Cell dataKey="receita" />
        </Column>
        <Column resizable width={150} sortable>
          <HeaderCell>Clientes</HeaderCell>
          <Cell dataKey="clientes" />
        </Column>
        <Column resizable width={150} sortable>
          <HeaderCell>Profissionais</HeaderCell>
          <Cell dataKey="profissionais" />
        </Column>
        <Column resizable width={150} sortable>
          <HeaderCell>Intermediação</HeaderCell>
          <Cell dataKey="intermediacao" />
        </Column>
      </TabelaPaginacao>
      </FlexboxGrid>

      {carregando && <Loader center size="lg" vertical />}
    </Container>
  );
}

export default Dashboard;
