/* eslint-disable react/jsx-no-bind */
import React, { useState, useCallback, useEffect } from 'react';
import { Attachment, Search } from '@rsuite/icons';

import {
  Loader,
  Table,
  FlexboxGrid,
  Input,
  InputGroup,
  IconButton,
} from 'rsuite';
import { startOfMonth, format, parseISO, lastDayOfMonth } from 'date-fns';
import { toast } from 'react-toastify';
import { GerarCSV as CSV } from '~/utils/gerarCSV'

import { Container } from '~/styles/tabela';

import api from '~/services/api';
import { InputDataRange } from '~/components/InputDataRange';
import { TablePagination } from '~/components/TablePagination';
import { ModalPadrao } from '~/components/Modal';
import fortCurrency from '../../../utils/formatCurrency';

const { HeaderCell, Column, Cell } = Table;

function ClientesInadimplentes() {
  const t = new Date();
  const [pagina, setPagina] = useState(1);
  const [dataFiltro, setDataFiltro] = useState([
    startOfMonth(new Date(), { weekStartsOn: 1 }),
    lastDayOfMonth(new Date()),
  ]);
  const [filtroNome, setFiltroNome] = useState('');
  const [clientes, setClientes] = useState({
    profissionais: [],
  });
  const [modalConfirmacao, setModalConfirmacao] = useState(false);

  const [carregando, setCarregando] = useState(false);

  const [orderBy, setOrderBy] = useState('order by data_ativacao desc');
  const [buscarCSV, setBuscarCSV] = useState(false);

  const motalTitulo = 'Gerar Relatório CSV';
  const modalTexto =
    'Deseja realmente gerar o arquivo em csv dos Clientes Inadimplentes com o filtro atual?';

  const buscarClientes = useCallback(async () => {
    setCarregando(true);
    setModalConfirmacao(false)
    try {
      const resposta = await api.get(`/relatorios/clientes/inadimplentes`, {
        params: {
          pagina,
          // datas: dataFiltro,
          estabelecimento: filtroNome,
          csv: buscarCSV
        },
      });

      const lista = resposta.data.lista.map((c) => ({
        // ...c,
        ...(buscarCSV === false && c),
        "Nome Estabelecimento": c.nome_fantasia,
        "Cnpj": c.cpf_cnpj,
        "Pagamento": format(parseISO(c.pagamento), 'dd/LL/yyyy'),
        "Quantidade": c.quantidade,
        "Semana": format(parseISO(c.semana), 'dd/LL/yyyy'),
        "Status": c.status,
        "Total": fortCurrency(c.valor),
      }))

      if(buscarCSV){
        CSV({
          data: lista,
          download: true,
        });

        setCarregando(false);
        toast.success(`Foram exportados ${lista.length} registros.`);
        setBuscarCSV(false);
        return;
      }

      setClientes({ lista, quantidade: lista.length > 0 ? lista[0].full_count : 0 });
      if(clientes.quantidade === 0) setPagina(1)
    } catch (e) {
      if(buscarCSV){
        setCarregando(false);
        toast.error('Erro ao Exportar os dados');
        setBuscarCSV(false);
        return;
      }
      toast.error(e.message);
    }
    setCarregando(false);
  }, [pagina, filtroNome, buscarCSV, clientes.quantidade]);

  useEffect(() => {
    buscarClientes();
  }, [buscarClientes]);


  return (
    <Container>
      <h2>
        Clientes Inadimplentes
        <IconButton
          title="Exportar CSV"
          style={{ marginLeft: '100px' }}
          size="4em"
          icon={<Attachment />}
          onClick={() => {
            setModalConfirmacao(true);
          }}
        />
      </h2>
      <FlexboxGrid justify="start" align="middle">
        <InputGroup style={{ width: '300px', marginRight: '15px' }}>
          <Input
            name="nome_estabelecimento"
            value={filtroNome}
            style={{ marginRight: '10px', width: '300px' }}
            placeholder="Nome do Cliente"
            onChange={setFiltroNome}
          />
          <InputGroup.Button>
            <Search />
          </InputGroup.Button>
        </InputGroup>
      </FlexboxGrid>
      <FlexboxGrid style={{ width: '100%' }}>
        <TablePagination
          carregando={carregando}
          dados={clientes.lista}
          quantidade={clientes.quantidade || 0}
          onChangePage={setPagina}
          setOrderBy={setOrderBy}
        >
          <Column resizable width={240} sortable>
            <HeaderCell>Nome Estabelecimento</HeaderCell>
            <Cell dataKey="Nome Estabelecimento" />
          </Column>

          <Column resizable width={160}>
            <HeaderCell>Cnpj</HeaderCell>
            <Cell dataKey="Cnpj" />
          </Column>
          <Column resizable width={120}>
            <HeaderCell>Pagamento</HeaderCell>
            <Cell dataKey="Pagamento" />
          </Column>
          <Column resizable width={110}>
            <HeaderCell>Quantidade</HeaderCell>
            <Cell dataKey="Quantidade" />
          </Column>
          <Column resizable width={150}>
            <HeaderCell>semana</HeaderCell>
            <Cell dataKey="Semana" />
          </Column>
          <Column resizable width={100}>
            <HeaderCell>Status</HeaderCell>
            <Cell dataKey="Status" />
          </Column>
          <Column resizable width={110}>
            <HeaderCell>Total</HeaderCell>
            <Cell dataKey="Total" />
          </Column>
        </TablePagination>
      </FlexboxGrid>

      <ModalPadrao
        openModal={modalConfirmacao}
        setOpenModal={setModalConfirmacao}
        title={motalTitulo}
        text={modalTexto}
        funcExec={() => {
          setBuscarCSV(true);
          buscarClientes();
        }}
      />

      {carregando && <Loader center size="lg" vertical />}
    </Container>
  );
}

export default ClientesInadimplentes;
