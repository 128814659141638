import { Location } from '@rsuite/icons';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

import {
  Loader,
  Row,
  InputGroup,
  FlexboxGrid,
  AutoComplete,
  SelectPicker,
} from 'rsuite';
import SelectState from '~/components/SelectState';

import api from '~/services/api';
import { anosGraficos, periodoDataGraficos } from '~/common/variaveis';
import { numberToMonth } from '../../../../../utils';

function Graficos() {
  const [data01, setData01] = useState([]);
  const [carregando, setCarregando] = useState(true);
  const [estadoSelecionado, setEstadoSelecionado] = useState();
  const [cidades, setCidades] = useState([]);
  const [cidadeSelecionada, setCidadeSelecionada] = useState();
  const [periodoSelecionado, setPeriodoSelecionado] = useState();
  const [anoSelecionado, setAnoSelecionado] = useState(
    moment(new Date()).format('YYYY')
  );

  async function buscaDados() {
    setCarregando(true);
    try {
      const resposta = await api.get('relatorios/graficos/receita', {
        params: {
          estado: estadoSelecionado,
          cidade: cidadeSelecionada,
          ano: anoSelecionado,
          periodo: periodoSelecionado,
        },
      });
      const dados = [];
      const cidades1 = await api.get('cidade');
      setCidades(cidades1.data);
      resposta.data.map((obj) => {
        return dados.push({
          mes: numberToMonth(Number(obj.mes), true),
          faturamento: Number(obj.faturamento),
          receita: Number(obj.receita),
          repasse: Number(obj.repasse),
        });
      });
      setData01(dados);
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
  }

  useEffect(() => {
    buscaDados();
  }, [
    estadoSelecionado,
    cidadeSelecionada,
    anoSelecionado,
    periodoSelecionado,
  ]);

  return (
    <>
      <FlexboxGrid justify="start">
        <InputGroup style={{ width: '250px', marginRight: '15px' }}>
          <InputGroup.Addon>
            <Location />
          </InputGroup.Addon>
          <AutoComplete
            name="nome_profissional"
            data={cidades
              .map((c) => ({
                label: c.cidade,
                value: c.cidade,
                id: c.id,
              }))
              .slice(0, 10)}
            onSelect={(item) => {
              setCidadeSelecionada(item.id);
            }}
            onEntered={() => {
              setCidadeSelecionada();
            }}
            placeholder="Cidade"
          />
        </InputGroup>
        <SelectState
          selectedState={estadoSelecionado}
          setSelectedStates={setEstadoSelecionado}
        />
        <SelectPicker
          appearance="default"
          style={{ marginLeft: '15px' }}
          searchable={false}
          placeholder="Ano"
          data={anosGraficos}
          value={anoSelecionado}
          onChange={(value) => {
            setAnoSelecionado(value);
            setPeriodoSelecionado(null);
          }}
        />
        {/* <SelectPicker
          appearance="default"
          style={{ marginLeft: '15px' }}
          searchable={false}
          placeholder="Período"
          data={periodoDataGraficos}
          value={periodoSelecionado}
          onChange={(value) => {
            setAnoSelecionado(null);
            setPeriodoSelecionado(value);
          }}
        /> */}
      </FlexboxGrid>

      <Row>
        {data01.length && (
          <BarChart
            width={1100}
            height={450}
            data={data01}
            margin={{
              top: 5,
              right: 10,
              left: 10,
              bottom: 5,
            }}
            barSize={20}
          >
            <XAxis dataKey="mes" />
            <YAxis />
            <Tooltip />
            <Legend />
            <CartesianGrid strokeDasharray="100 3" />
            <Bar dataKey="faturamento" name="Faturamento" fill="#98DD0F" />
            <Bar name="Repasse" dataKey="repasse" fill="#50484A" />
            <Bar name="Receita" dataKey="receita" fill="#87CEEB" />
          </BarChart>
        )}
        {carregando && <Loader center size="lg" vertical />}
      </Row>
    </>
  );
}

export default Graficos;
