/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import { Plus, RemindFill } from '@rsuite/icons';

import {
  Table,
  Button,
  FlexboxGrid,
  Modal,
  Form,
  IconButton,
  Schema,
} from 'rsuite';
import { toast } from 'react-toastify';

import { Container } from '~/styles/tabela';

import api from '~/services/api';
import {
  CelulaEditavel,
  CelulaAcao,
} from '~/components/Celulas/components/CelulaEditavel';
import { TabelaSemPaginacao } from '~/components/TabelaSemPaginacao';
import AdicionarImagemDialog from './components/AdicionarImagemDialog';
import SearchTable from '../components/SearchTable';
import { ButtonFullBlue } from '../../../components/Buttons/ButtonFullBlue/styles';

const { HeaderCell, Column } = Table;

const { StringType } = Schema.Types;

const modelo = Schema.Model({
  blusa: StringType().isRequired('Blusa Obrigatória'),
  calca: StringType().isRequired('Calça Obrigatória'),
  calcado: StringType().isRequired('Calçado Obrigatório'),
  cabelo: StringType().isRequired('Cabelo Obrigatório'),
  barba: StringType().isRequired('Barba Obrigatório'),
});

function Uniformes() {
  const [dados, setDados] = useState({
    listaInicial: [],
    uniformesLista: [],
    uniformesListaFiltrada: [],
    filtro: '',
  });
  const [apagarUniformeDialog, setApagarUniformeDialog] = useState({
    exibir: false,
    uniforme: {},
  });
  const [adicionarImagemDialog, setAdicionarImagemDialog] = useState({
    exibir: false,
    uniforme: {},
  });
  const [carregando, setCarregando] = useState(true);
  const [adicionarUniformeDialog, setAdicionarUniformeDialog] = useState(false);
  const [camposInput, setCamposInput] = useState({});

  async function buscarUniformes() {
    const resposta = await api.get('uniforme');
    const resposta1 = await api.get('uniforme');
    const lista = resposta.data;
    setDados({
      listaInicial: resposta1.data,
      uniformesLista: lista,
      uniformesListaFiltrada: lista,
    });
    setCarregando(false);
  }

  useEffect(() => {
    buscarUniformes();
  }, []);

  function filtrarLista(texto) {
    setDados({
      ...dados,
      uniformesListaFiltrada: dados.uniformesLista.filter((uniforme) =>
        uniforme.blusa
          .toString()
          .toUpperCase()
          .includes(texto ? texto.toUpperCase() : '')
      ),
      filtro: texto,
    });
  }

  function abrirApagarDialog(uniforme) {
    setApagarUniformeDialog({
      exibir: true,
      uniforme,
    });
  }

  function fecharApagarDialog() {
    setApagarUniformeDialog({
      exibir: false,
      uniforme: {},
    });
  }

  async function excluirUniforme() {
    try {
      await api.delete(`/uniforme/${apagarUniformeDialog.uniforme.id}`);
      toast.success('Uniforme excluida com sucesso');
      fecharApagarDialog();
    } catch (e) {
      toast.error(e.message);
    }

    buscarUniformes();
  }

  async function adicionarUniforme() {
    try {
      setCarregando(true);
      await api.post(`/uniforme`, camposInput);
      toast.success('Uniforme criada com sucesso');
      setCarregando(false);
      setAdicionarUniformeDialog(false);
    } catch (e) {
      toast.error(e.message);
    }

    buscarUniformes();
  }

  function alterarTextoCelula(id, key, value) {
    const novaLista = Object.assign([], dados.uniformesLista);
    novaLista.find((item) => item.id === id)[key] = value;
    setDados({ ...dados, uniformesLista: novaLista });
    filtrarLista(dados.filtro);
  }

  async function alterarEstadoCelula(id) {
    const novaLista = Object.assign([], dados.uniformesLista);
    const item = novaLista.find((uniforme) => uniforme.id === id);
    const itemInicial = dados.listaInicial.find(
      (uniforme) => uniforme.id === id
    );
    if (
      item.status === 'EDIT' &&
      (item.blusa !== itemInicial.blusa ||
        item.calca !== itemInicial.calca ||
        item.calcado !== itemInicial.calcado ||
        item.calcado !== itemInicial.cabelo ||
        item.calcado !== itemInicial.barba)
    ) {
      try {
        setCarregando(true);
        await api.put(`uniforme/${id}`, item);
        toast.success('Uniforme atualizado com sucesso');
        buscarUniformes();
      } catch (error) {
        toast.error(error.message);
      }
      setCarregando(false);
    } else {
      item.status = item.status ? null : 'EDIT';
      setDados({ ...dados, uniformesLista: novaLista });
      filtrarLista(dados.filtro);
    }
  }

  return (
    <Container>
      <h2>Uniformes</h2>
      <FlexboxGrid justify="start" style={{ gap: '10px' }}>
        <SearchTable estilo={{ width: '300px' }} filtrarLista={filtrarLista} />
        <IconButton
          style={{ marginTop: '0' }}
          icon={<Plus />}
          onClick={() => {
            setAdicionarUniformeDialog(true);
          }}
        />
      </FlexboxGrid>
      <FlexboxGrid style={{ width: '100%' }}>
      <TabelaSemPaginacao
        carregando={carregando}
        dados={dados.uniformesListaFiltrada}
      >
        <Column resizable width={240} sortable>
          <HeaderCell>Blusa</HeaderCell>
          <CelulaEditavel dataKey="blusa" onChange={alterarTextoCelula} />
        </Column>

        <Column resizable width={240} sortable>
          <HeaderCell>Calca</HeaderCell>
          <CelulaEditavel dataKey="calca" onChange={alterarTextoCelula} />
        </Column>

        <Column resizable width={240} sortable>
          <HeaderCell>Calcado</HeaderCell>
          <CelulaEditavel dataKey="calcado" onChange={alterarTextoCelula} />
        </Column>

        <Column resizable width={240} sortable>
          <HeaderCell>Cabelo</HeaderCell>
          <CelulaEditavel dataKey="cabelo" onChange={alterarTextoCelula} />
        </Column>

        <Column resizable width={240} sortable>
          <HeaderCell>Barba</HeaderCell>
          <CelulaEditavel dataKey="barba" onChange={alterarTextoCelula} />
        </Column>

        <Column resizable width={100}>
          <HeaderCell>Imagem</HeaderCell>
          <CelulaAcao
            dataKey="id"
            onClick={(id) => {
              const uniforme = dados.uniformesLista.find((p) => p.id === id);
              setAdicionarImagemDialog({
                exibir: true,
                uniforme,
              });
            }}
          />
        </Column>

        <Column resizable width={200}>
          <HeaderCell>Editar</HeaderCell>
          <CelulaAcao
            dataKey="id"
            onClick={alterarEstadoCelula}
            onDelete={abrirApagarDialog}
          />
        </Column>
      </TabelaSemPaginacao>
      </FlexboxGrid>
      <AdicionarImagemDialog
        exibir={adicionarImagemDialog.exibir}
        uniforme={adicionarImagemDialog.uniforme}
        onFechar={() => {
          setAdicionarImagemDialog({
            exibir: false,
            uniforme: {},
          });
        }}
      />
      <Modal
        backdrop="static"
        open={adicionarUniformeDialog}
        onClose={() => setAdicionarUniformeDialog(false)}
        size="xs"
      >
        <Form
          model={modelo}
          onChange={setCamposInput}
          onSubmit={(status) => status && adicionarUniforme()}
        >
          <Modal.Header>
            <h3>Adicionar Uniforme</h3>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.ControlLabel>Blusa</Form.ControlLabel>
              <Form.Control name="blusa" />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Calca</Form.ControlLabel>
              <Form.Control name="calca" />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Calcado</Form.ControlLabel>
              <Form.Control name="calcado" />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Cabelo</Form.ControlLabel>
              <Form.Control name="cabelo" />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>barba</Form.ControlLabel>
              <Form.Control name="barba" />
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button
              onClick={() => setAdicionarUniformeDialog(false)}
              appearance="subtle"
            >
              Cancelar
            </Button>
            <ButtonFullBlue type="submit" appearance="primary">
              Adicionar
            </ButtonFullBlue>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        backdrop="static"
        open={apagarUniformeDialog.exibir}
        onClose={fecharApagarDialog}
        size="xs"
      >
        <Modal.Header>
          <h3>Apagar Uniforme</h3>
        </Modal.Header>
        <Modal.Body>
          Você realmente deseja apagar este uniforme? <br /> <br />
          id: {apagarUniformeDialog.uniforme.id} <br />
          blusa: {apagarUniformeDialog.uniforme.blusa} <br />
          calca: {apagarUniformeDialog.uniforme.calca} <br />
          calcado: {apagarUniformeDialog.uniforme.calcado} <br />
          cabelo: {apagarUniformeDialog.uniforme.cabelo} <br />
          barba: {apagarUniformeDialog.uniforme.barba} <br />
          Essa ação é irreversível{' '}
          <RemindFill
            style={{
              color: '#ffb300',
              fontSize: 24,
            }}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={fecharApagarDialog} appearance="subtle">
            Cancelar
          </Button>
          <ButtonFullBlue
            onClick={() => excluirUniforme()}
            appearance="primary"
          >
            Deletar
          </ButtonFullBlue>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default Uniformes;
