import produce from 'immer';

const INITIAL_STATE = {
  lista: [],
  profissionais: [],
  topServices: [],
  quantidade: 0,
  avaliacaoMedia: 0,
  satisfacaoMedia: 0,
  valorTotal: 0,
  mediaCancelamentos: 0,
  totalCupons: 0,
  tarifaDinamica: 0,
  totalPedidos: 0,
  cancelamentos: 0,
  totalFaltas: 0,
  duracaoMedia: 0,
  primeiroPedido: '',
  ultimoPedido: '',
  topServicos: [],
  pedidosPorMes: [],
  services: [],
  pro: [],
};

export default function carteiraCliente(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case 'SUMMARY':
        draft.lista = action.payload.lista;
        draft.profissionais = action.payload.profissionais;
        draft.topServices = action.payload.topServices;
        draft.quantidade = action.payload.quantidade;
        draft.avaliacaoMedia = action.payload.avaliacaoMedia;
        draft.satisfacaoMedia = action.payload.satisfacaoMedia;
        draft.valorTotal = action.payload.valorTotal;
        draft.mediaCancelamentos = action.payload.mediaCancelamentos;
        draft.totalCupons = action.payload.totalCupons;
        draft.tarifaDinamica = action.payload.tarifaDinamica;
        draft.totalPedidos = action.payload.totalPedidos;
        draft.cancelamentos = action.payload.cancelamentos;
        draft.totalFaltas = action.payload.totalFaltas;
        draft.duracaoMedia = action.payload.duracaoMedia;
        draft.primeiroPedido = action.payload.primeiroPedido;
        draft.ultimoPedido = action.payload.ultimoPedido;
        draft.topServicos = action.payload.topServicos;
        draft.pedidosPorMes = action.payload.pedidosPorMes;
        draft.services = action.payload.services;
        draft.pro = action.payload.pro;
        draft.ano = action.payload.ano;
        break;

      default:
    }
  });
}
