/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { Modal, Button } from 'rsuite';
import api from '~/services/api';
import { toast } from 'react-toastify';

export function ModalExcluirProfissional ({setproExcluirModal, proExcluirModal, proExcluir, setNewPro}) {
  const [ carregando, setCarregando ] = useState(false);

  async function excluirPro() {
    const { id, nome } = proExcluir
    setCarregando(true)
    setproExcluirModal(false)
    try {
      await api.delete(`/recrutamento/excluir-pro/${id}`)
      setCarregando(false)
      setNewPro((p) => !p)
      toast.success(`Profissional ${nome} excluido(a) com sucesso.`)
    }catch(e) {
      setCarregando(false)
      toast.error(e.message)
    }
  }

  return (
    <Modal
        open={proExcluirModal}
        onClose={() => setproExcluirModal(false)}
        size="xs"
      >
        <Modal.Header>Excluir Profissional</Modal.Header>
        <Modal.Body>
          <p>Deseja realmente excluir este Profissional?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setproExcluirModal(false)}
            appearance="subtle"
          >
            Cancelar
          </Button>
          <Button
            appearance="primary"
            type="button"
            onClick={() => {
              excluirPro();
            }}
          >
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
  )
}

ModalExcluirProfissional.propTypes = {
  setproExcluirModal: PropTypes.func,
  setNewPro: PropTypes.func.isRequired,
  proExcluirModal: PropTypes.bool,
  proExcluir: PropTypes.objectOf(PropTypes.any),
}

ModalExcluirProfissional.defaultProps = {
  proExcluir: {},
  setproExcluirModal: () => { },
  proExcluirModal: false,
};
