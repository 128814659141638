import styled from 'styled-components';

export const Experiencia = styled.div`
  display: flex;
  margin: 10px;
  padding: 10px;
  border: 1px solid #eee;
  justify-content: space-between;
  span {
    font-size: 18px;
    margin: 5px;
  }

  p {
    font-size: 16px;
    margin: 5px;
  }
`;
