/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Edit, Check, Trash } from '@rsuite/icons';

import { Table, IconButton, Divider } from 'rsuite';

const { Cell } = Table;

function CelulaEditavel({ rowData, dataKey, onChange, ...props }) {
  const editing = rowData.status === 'EDIT' && dataKey !== 'id';
  return (
    <Cell {...props}>
      {editing ? (
        <input
          className="rs-input"
          defaultValue={rowData[dataKey]}
          onChange={(event) => {
            onChange(rowData.id, dataKey, event.target.value);
          }}
        />
      ) : (
        <span className="table-content-edit-span">{rowData[dataKey]}</span>
      )}
    </Cell>
  );
}

function CelulaAcaoHabilidade({ rowData, onClick, onDelete, ...props }) {
  return (
    <Cell {...props} style={{ padding: '6px 0' }}>
      {rowData.status === 'EDIT' ? (
        <IconButton
          appearance="subtle"
          onClick={() => onClick(rowData.id)}
          icon={<Check />}
        />
      ) : (
        <IconButton
          appearance="subtle"
          onClick={() => onClick(rowData)}
          icon={<Edit />}
        />
      )}
      {onDelete && <Divider vertical />}
      {onDelete && (
        <IconButton
          appearance="subtle"
          onClick={() => onDelete(rowData)}
          color="red"
          icon={<Trash />}
        />
      )}
    </Cell>
  );
}

CelulaEditavel.propTypes = {
  rowData: PropTypes.object,
  dataKey: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

CelulaEditavel.defaultProps = {
  rowData: {},
  // eslint-disable-next-line prettier/prettier
  onChange: () => { },
};

CelulaAcaoHabilidade.propTypes = {
  rowData: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
};

CelulaAcaoHabilidade.defaultProps = {
  rowData: {},
  onDelete: null,
};

export { CelulaEditavel, CelulaAcaoHabilidade };
