import React, { useEffect, useState, useCallback } from 'react';
import produce from 'immer';
import {
  Button,
  Checkbox,
  DatePicker,
  FlexboxGrid,
  Input,
  IconButton,
  Modal,
  SelectPicker,
  Table,
  CheckPicker,
  CheckTreePicker,
} from 'rsuite';
import { InputDataRange } from '~/components/InputDataRange';
import { Attachment } from '@rsuite/icons';
import { toast } from 'react-toastify';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import pt from 'date-fns/locale/pt-BR';
import { useDebouncedCallback } from 'use-debounce/lib';
import { Container } from '~/styles/tabela';
import api from '~/services/api';
import CelulaAcao from '~/components/Celulas/components/CelulaAcao';
import { FiltroExperiencias } from '~/components/FiltroExperiencias';
import { FiltroTreinamentos } from '~/components/FiltroTreinamentos';
import SearchInputTable from '~/components/SearchInputTable';
import SelectState from '~/components/SelectState';
import { TablePagination } from '~/components/TablePagination';
import { GerarCSV as CSV } from '~/utils/gerarCSV'
import EditarProfissional from '../EditarProfissional';


const { HeaderCell, Column, Cell } = Table;

function ListaProfissionalBloqueado() {
  const [profissionais, setProfissionais] = useState({
    lista: [],
    quantidade: 0,
  });
  const [experiencias, setExperiencias] = useState([]);
  const [experienciaSelecionada, setExperienciaSelecionada] = useState();
  const [treinamentos, setTreinamentos] = useState([]);
  const [treinamentoSelecionado, setTreinamentoSelecionado] = useState();
  const [interesses, setInteresses] = useState([]);
  const [interesseSelecionado, setInteresseSelecionado] = useState([]);
  const [perfilSelecionado, setPerfilSelecionado] = useState(false);
  const [estadoSelecionado, setEstadoSelecionado] = useState();
  const [cidade, setCidade] = useState('');
  const [bairro, setBairro] = useState('');
  const [analiseSelecionada, setAnaliseSelecionada] = useState()
  const [carregando, setCarregando] = useState(true);
  const [textodigitado, setTextoDigitado] = useState();
  const [pagina, setPagina] = useState(1);
  const [atributoSelecionado, setAtributoSelecioando] = useState('nome');
  const [profissionalEditando, setProfissionalEditando] = useState();
  const [showEditarProfissional, setShowEditarProfissional] = useState(false);
  const [selecionarCertificado, setSelecionarCertificado] = useState(false);
  const [modalConfirmacao, setModalConfirmacao] = useState(false);
  const [dataSelecionada, setDataSelecionada] = useState();
  const [buscarCSV, setBuscarCSV] = useState(false);
  const atributosFiltro = [
    {
      label: 'Nome',
      value: 'nome',
    },
    {
      label: 'Email',
      value: 'email',
    },
    {
      label: 'Cpf',
      value: 'cpf_cnpj',
    },
  ];

  const [orderBy, setOrderBy] = useState('order by Tempo de Cadastro desc');

  const locale = {
    sunday: 'Dom',
    monday: 'Seg',
    tuesday: 'Ter',
    wednesday: 'Qua',
    thursday: 'Qui',
    friday: 'Sex',
    saturday: 'Sab',
    ok: 'Ok',
    today: 'Hoje',
    yesterday: 'Ontem',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos',
  };

  function getInteresse(status) {
    const map = {
      'freelancer': 'Freelancer',
      'emprego': 'Emprego',
      'emprego_freelancer': 'Emprego e Freelancer',
    };

    return map[status];
  }

  const buscarProfissionais = useCallback(async () => {
    setModalConfirmacao(false)
    setCarregando(true);
    try {
      const resposta = await api.get('profissionais/bloqueados', {
        params: {
          [atributoSelecionado]: textodigitado,
          pagina,
          estado: estadoSelecionado,
          treinamentos: treinamentoSelecionado,
          certificado: selecionarCertificado,
          interesses: interesseSelecionado,
          perfil: perfilSelecionado,
          analise: analiseSelecionada,
          experiencias: experienciaSelecionada,
          cidade,
          bairro,
          orderBy,
          data: dataSelecionada,
          csv: buscarCSV
        },
      });

      const lista = resposta.data.lista.map((profissional) => ({

        // ...profissional,
        ...(buscarCSV === false && profissional),
        'Nome': profissional.nome,
        'Email': profissional.email,
        "Telefone": profissional.telefone1,
        'Cpf/Cnpj': profissional.cpf_cnpj,
        'Tempo de Cadastro': formatDistanceToNow(new Date(profissional.data_criacao), {
          locale: pt,
        }),
        'Cidade': profissional.endereco.bairro.cidade.cidade,
        'Estado': profissional.endereco.bairro.cidade.estado.estado,
        'Bairro': profissional.endereco.bairro.bairro,
        'Interesse': getInteresse(profissional.interesses),
      }));
      console.log(lista)
      if (buscarCSV) {
        CSV({
          data: lista,
          download: true,
        });

        setCarregando(false);
        toast.success(`Foram exportados ${lista.length} registros.`);
        setBuscarCSV(false);
        return;
      }

      setProfissionais({ lista, quantidade: lista.length > 0 ? lista[0].full_count : 0 });
      if (profissionais.quantidade === 0) setPagina(1)
    } catch (e) {
      if (buscarCSV) {
        setCarregando(false);
        toast.error('Erro ao Exportar os dados');
        setBuscarCSV(false);
        return;
      }
      toast.error(e.message);
    }
    setCarregando(false);
  }, [
    pagina,
    atributoSelecionado,
    textodigitado,
    analiseSelecionada,
    estadoSelecionado,
    treinamentoSelecionado,
    experienciaSelecionada,
    selecionarCertificado,
    cidade,
    bairro,
    orderBy,
    dataSelecionada,
    interesseSelecionado,
    perfilSelecionado,
    buscarCSV,
    setPagina,
    profissionais.quantidade
  ]);

  useEffect(() => {
    buscarProfissionais();
  }, [buscarProfissionais]);

  useEffect(() => {
    const buscarFiltros = async () => {
      const [experienciasLista, treinamentosLista] = await Promise.all([
        api.get('profissao'),
        api.get('treinamento'),
      ]);

      const newLista = experienciasLista.data.map((map)=>({
        label: map.profissao,
        value: map.id,
      }))

      setTreinamentos(treinamentosLista.data);
      setExperiencias([{
        label: "Experiencias",
        value: 'Todos',
        children: [...newLista]
      }]);
    };

    buscarFiltros();
  }, []);

  function abrirEditarProfissional(profissional) {
    setProfissionalEditando(profissional);
    setShowEditarProfissional(true);
  }

  return (
    <Container>
      <h2>Profissionais Bloqueados
        <IconButton
          title="Exportar CSV"
          style={{ marginLeft: '100px' }}
          size="4em"
          icon={<Attachment />}
          onClick={() => {
            setModalConfirmacao(true);
          }}
        />
      </h2>
      <FlexboxGrid justify="start" style={{ gap: "10px" }}>
        <SearchInputTable setTextoDigitado={setTextoDigitado} />
        <SelectPicker
          appearance="default"
          style={{ marginLeft: '-15px' }}
          searchable={false}
          data={atributosFiltro}
          value={atributoSelecionado}
          cleanable={false}
          onChange={(value) => setAtributoSelecioando(value)}
        />
        <Input
          style={{ width: '250px' }}
          placeholder="Cidade"
          onChange={useDebouncedCallback(setCidade, 500).callback}
        />
        <Input
          style={{ width: '250px' }}
          placeholder="Bairro"
          onChange={useDebouncedCallback(setBairro, 500).callback}
        />
        <SelectState
          selectedState={estadoSelecionado}
          setSelectedStates={setEstadoSelecionado}
        // marginRight
        />
        <FiltroTreinamentos
          appearance="default"
          style={{ width: 224 }}
          searchable={false}
          data={treinamentos.map((treinamento) => ({
            label: treinamento.treinamento,
            value: treinamento.id,
          }))}
          value={treinamentoSelecionado}
          onChange={(value) => setTreinamentoSelecionado(value)}
        />
        <CheckTreePicker
          style={{ width: 224 }}
          defaultExpandAll
          placeholder="Experiencias"
          appearance="default"
          data={experiencias}
          value={experienciaSelecionada}
          onChange={(value) => setExperienciaSelecionada(value)}
        />
        <InputDataRange
          ranges={[]}
          placeholder="Data de Cadastro"
          isoWeek
          value={dataSelecionada}
          locale={locale}
          onChange={setDataSelecionada}
        />
        <CheckPicker
          appearance="default"
          style={{ marginRight: '15px' }}
          searchable={false}
          placeholder="Interesse"
          // data={{ label:"freelancer"}}
          data={[{
            label: 'Freelancer',
            value: 'freelancer',
          },
          {
            label: 'Emprego',
            value: 'emprego',
          },
          {
            label: 'Emprego e Freelancer',
            value: 'emprego_freelancer',
          },
          ]}
          value={interesseSelecionado}
          onChange={(value) => setInteresseSelecionado(value)}
        />
        <SelectPicker
          appearance="default"
          style={{ marginLeft: '-15px', width: 150 }}
          searchable={false}
          placeholder="Perfil"
          data={[{
            label: 'Perfil Analisado',
            value: 'analisado',
          },
          {
            label: 'Perfil Não Analisado',
            value: 'nao_analisado',
          },
          {
            label: 'Todos',
            value: 'todos',
          },
          ]}
          value={analiseSelecionada}
          cleanable={false}
          onChange={(value) => setAnaliseSelecionada(value)}
        />
        <Checkbox
          style={{ marginLeft: '-10px', marginRight: '10px' }}
          checked={perfilSelecionado}
          onChange={(bool, value) => setPerfilSelecionado(value)}
        >Perfil Bom</Checkbox>
        <Checkbox
          value={selecionarCertificado}
          onChange={(a, valor) => {
            setSelecionarCertificado(valor);
          }}
        >
          Enviou Certificado
        </Checkbox>
      </FlexboxGrid>
      <FlexboxGrid style={{ width: '100%' }}>
        <TablePagination
          carregando={carregando}
          dados={profissionais.lista}
          quantidade={profissionais.quantidade}
          onChangePage={setPagina}
          setOrderBy={setOrderBy}
        >
          <Column resizable width={60}>
            <HeaderCell>Editar</HeaderCell>
            <CelulaAcao
              dataKey="id"
              onEditar={(profissional) => {
                abrirEditarProfissional(profissional);
              }}
            />
          </Column>
          <Column resizable width={240} sortable>
            <HeaderCell>Nome</HeaderCell>
            <Cell dataKey="Nome" />
          </Column>

          <Column resizable width={240} sortable>
            <HeaderCell>Email</HeaderCell>
            <Cell dataKey="Email" />
          </Column>

          <Column resizable width={150} sortable>
            <HeaderCell>Cpf/Cnpj</HeaderCell>
            <Cell dataKey="Cpf/Cnpj" />
          </Column>

          <Column resizable width={200} sortable>
            <HeaderCell>Tempo Cadastro</HeaderCell>
            <Cell dataKey="Tempo de Cadastro" />
          </Column>
          <Column resizable width={150}>
            <HeaderCell>Cidade</HeaderCell>
            <Cell dataKey="Cidade" />
          </Column>
          <Column resizable width={80}>
            <HeaderCell>Estado</HeaderCell>
            <Cell dataKey="Estado" />
          </Column>
          <Column resizable width={150}>
            <HeaderCell>Bairro</HeaderCell>
            <Cell dataKey="Bairro" />
          </Column>
          <Column resizable width={150}>
            <HeaderCell>Interesse</HeaderCell>
            <Cell dataKey="Interesse" />
          </Column>
        </TablePagination>
      </FlexboxGrid>
      <EditarProfissional
        idProfissional={profissionalEditando && profissionalEditando.id}
        showEditarProfissional={showEditarProfissional}
        setShowEditarProfissional={setShowEditarProfissional}
        atualizarLista={buscarProfissionais}
      />
      <Modal
        open={modalConfirmacao}
        onClose={() => setModalConfirmacao(false)}
        size="xs"
      >
        <Modal.Header>Gerar Relatório CSV</Modal.Header>
        <Modal.Body>
          <p>
            Deseja realmente gerar o csv dos profissionais com o filtro
            atual?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setModalConfirmacao(false)}
            appearance="subtle"
          >
            Cancelar
          </Button>
          <Button
            appearance="primary"
            type="button"
            onClick={() => {
              setBuscarCSV(true)
              buscarProfissionais();
            }}
          >
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default ListaProfissionalBloqueado;
