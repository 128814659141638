/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, useEffect } from 'react'
import { Container } from '~/styles/tabela'
import api from '~/services/api'
import useSearchNames from '~/services/useSearchNames';
import {
  FlexboxGrid,
  Table,
  InputGroup,
  Input,
  CheckPicker,
  Button,
  Modal,
  AutoComplete
} from 'rsuite'
import { toast } from 'react-toastify';
import SearchIcon from '@rsuite/icons/Search';
import { InputDataRange } from '~/components/InputDataRange';
// import { TabelaSemPaginacao } from '~/components/TabelaSemPaginacao'
import formatCurrency from '~/utils/formatCurrency'
import { format, parseISO } from 'date-fns'
import DropDownCell from './components/DropDownCell';
import { TablePagination } from '../../components/TablePagination';
import { CelulaFormatada } from './components/CelulaFormatada'
import GerarFaturaStark from './components/GerarFaturaStark'
import GerarFaturasSemanais from './components/GerarFaturasSemanais'

const { HeaderCell, Column } = Table;

export default function StarkIndex() {

  const [carregando, setCarregando] = useState(false)
  const [pagina, setPagina] = useState(1);
  const [faturas, setFaturas] = useState([])
  const [orderBy, setOrderBy] = useState('');
  // const [limit, setLimit] = useState();
  const [datas, setDatas] = useState([])
  const [nomesEstabelecimentos, setNomesEstabelecimentos] = useState([])
  const [estabelecimentoSelecionado, setEstabelecimentoSelecionado] = useState({
    label: '',
    value: '',
    id_stark: null
  })
  const [exibir, setExibir] = useState(false);
  const [exibirCancelar, setExibirCancelar] = useState(false);
  const [idFatura, setIdFatura] = useState('');
  const [idBoleto, setIdInvoice] = useState('');
  const [idInvoice, setIdBoleto] = useState('');
  const [pedido, setPedido] = useState();
  const [pedidoII, setPedidoII] = useState();
  const [statusFiltro, setStatusFiltro] = useState([
    'pending',
    'paid',
    'canceled',
  ]);

  const statusFatura = [
    { label: 'Pendente', value: 'pending' },
    { label: 'Pago', value: 'paid' },
    { label: 'Cancelado', value: 'canceled' },
    { label: 'Parcialmente Pago', value: 'partially_paid' },
    { label: 'Devolvido', value: 'refunded' },
    { label: 'Expirado', value: 'expired' },
    { label: 'Autorizado', value: 'authorized' },
  ]

  const locale = {
    sunday: 'Dom',
    monday: 'Seg',
    tuesday: 'Ter',
    wednesday: 'Qua',
    thursday: 'Qui',
    friday: 'Sex',
    saturday: 'Sab',
    ok: 'Ok',
    today: 'Hoje',
    yesterday: 'Ontem',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos',
  };


  const carregarFaturas = useCallback(async () => {

    setCarregando(true)

    function statusBoleto(status_b){
        switch(status_b){
          case 'created':
            status_b = 'Criado'
          break
          case 'registered':
            status_b = 'Pendente'
          break
          case 'canceled':
            status_b = 'Cancelado'
          break
          case 'paid':
            status_b = 'Pago'
          break
          default: status_b = 'Pendente'
          
        }
        return status_b
    }
    function statusInvoice(status_v){
      switch(status_v){
        case 'created':
          status_v = 'Pendente'
        break
        case 'canceled':
          status_v = 'Cancelado'
        break
        case 'paid':
          status_v = 'Pago'
        break
        default: status_v = 'Pendente'
        
      }
      return status_v
  }
    try {
      

      const result = await api.get('/pagamentos/getFaturas', {
        params: {
          pedido,
          status: statusFiltro,
          pagina,
          estabelecimento: estabelecimentoSelecionado.id_stark || null,
          datas
        }
      })
      console.log(result, 'result 91')

      const lista = result.data.faturas.map((map) => ({
        ...map,
        status_boleto: statusBoleto(map.status_boleto),
        status_invoice: statusInvoice(map.status_invoice),
        valor: formatCurrency(map.valor),
        data_fatura: format(parseISO(map.created_at), 'dd/MM/yyyy'),
        data_vencimento: format(parseISO(map.vencimento), 'dd/MM/yyyy'),
      }))
      console.log(lista)

      setFaturas({
        lista,
        quantidade: lista.length > 0 ? lista[0].full_count : 0,
      })
      if (faturas.quantidade === 0) setPagina(1)

    } catch (error) {
      console.log(error)
    }

    setCarregando(false)

  }, [pedido, pagina, faturas.quantidade, datas, estabelecimentoSelecionado.id_stark, statusFiltro])

  // const buscarNomesEstabelecimentos = useSearchNames(
  //   setNomesEstabelecimentos,
  //   'estabelecimentos'
  // );

  useEffect(() => {
    carregarFaturas()
  }, [carregarFaturas])

  const buscarNomesEstabelecimentos = useSearchNames(
    setNomesEstabelecimentos,
    'estabelecimentos'
  );

  async function cancelarFatura(id, id_invoice, id_boleto ) {
    setCarregando(true);
    setExibirCancelar(false);
    await api
      .put(`/pagamentos/cancelarFatura/${id}`, {  
          id_invoice,
          id_boleto,
      })
      .then(async () => {
        await carregarFaturas()
        setCarregando(false);
        toast.success('Fatura cancelada com sucesso!');
      })
      .catch((err) => {
        console.log(err)
        toast.error(err.message);
        setCarregando(false);
      });
  }

  return (
    <Container>
      <h2>Stark</h2>

      <FlexboxGrid justify="start" style={{ gap: '10px' }}>
      <InputGroup
          style={{ width: '300px' }}
        >
          <AutoComplete
            placeholder="Nome do Estabelecimento"
            width={200}
            data={nomesEstabelecimentos.map((p) => ({
              label: p.nome_fantasia,
              value: p.nome_fantasia,
              id_stark: p.id,
            }))}
            onSelect={async (value, item) => {
              setTimeout(() => {
                setEstabelecimentoSelecionado(item);
              }, 1000);
            }}
            onChange={(value) => {
              if (value !== '') {
                buscarNomesEstabelecimentos(value);
              } else {
                setEstabelecimentoSelecionado('');
                carregarFaturas();
              }
            }}
          />
          <InputGroup.Button>
            <SearchIcon />
          </InputGroup.Button>
        </InputGroup>
        <InputDataRange
          ranges={[]}
          isoWeek
          oneTap
          locale={locale}
          onClean={() => {
            setDatas([]);
          }}
          onChange={setDatas}
          value={datas}
          hoverRange="week"
        />
        <CheckPicker
          value={statusFiltro}
          title="Filtro Status"
          toggleComponentClass={Button}
          appearance="default"
          searchable={false}
          placeholder="Selecione Status"
          data={statusFatura}
          onChange={setStatusFiltro}
        />

        <InputGroup
          style={{ width: '150px' }}
        >
          <Input
            value={pedidoII}
            placeholder="N. Pedido"
            onChange={(value) => {
              setPedidoII(value);
              if (value.replace(/[a-z]+|[A-Z]+|-/gm, '').length > 4)
                setPedido(value.replace(/[a-z]+|[A-Z]+|-/gm, ''));
              if (value.length === 0) setPedido(value);
            }}
          />
          <InputGroup.Button>
            <SearchIcon />
          </InputGroup.Button>
        </InputGroup>

        <GerarFaturaStark
          atualizarTabela={carregarFaturas}
        />

        <GerarFaturasSemanais
          atualizarTabela={carregarFaturas}
          style={{ marg: 10 }}
          setCarregando={setCarregando}
        />

      </FlexboxGrid>
      <FlexboxGrid style={{ width: '100%', marginTop: 20 }}>

        <TablePagination
          carregando={carregando}
          dados={faturas.lista}
          onChangePage={setPagina}
          // style={{ width: 1000 }}
          quantidade={faturas.quantidade}
          setOrderBy={setOrderBy}
        >
         <Column resizable width={80}>
          <HeaderCell>Opções</HeaderCell>
          <DropDownCell
            setExibir={setExibir}
            setIdFatura={setIdFatura}
            setIdInvoice={setIdInvoice}
            setIdBoleto={setIdBoleto}
            setExibirCancelar={setExibirCancelar}
            dataKey="id"
          />
        </Column>

          <Column resizable sortable width={200}>
            <HeaderCell>Nome</HeaderCell>
            <CelulaFormatada dataKey="nome_fantasia" />
          </Column>

          <Column resizable sortable width={200}>
            <HeaderCell>Cnpj</HeaderCell>
            <CelulaFormatada dataKey="cpf_cnpj" />
          </Column>

          <Column resizable sortable width={130}>
            <HeaderCell>Valor</HeaderCell>
            <CelulaFormatada dataKey="valor" />
          </Column>

          <Column resizable sortable width={130}>
            <HeaderCell>Data de criação</HeaderCell>
            <CelulaFormatada dataKey="data_fatura" />
          </Column>

          <Column resizable sortable width={130}>
            <HeaderCell>Data de vencimento</HeaderCell>
            <CelulaFormatada dataKey="data_vencimento" />
          </Column>

          <Column resizable sortable width={100}>
            <HeaderCell>Boleto</HeaderCell>
            <CelulaFormatada dataKey="status_boleto" />
          </Column>

          <Column resizable sortable width={100}>
            <HeaderCell>Invoice</HeaderCell>
            <CelulaFormatada dataKey="status_invoice" />
          </Column>

          <Column resizable sortable width={200}>
            <HeaderCell>Pedido</HeaderCell>
            <CelulaFormatada dataKey="numero_pedido" />
          </Column>

        </TablePagination>
      </FlexboxGrid>
      <Modal
        open={exibirCancelar}
        onClose={() => setExibirCancelar(false)}
        size="xs"
      >
        <Modal.Header>Cancelar fatura</Modal.Header>
        <Modal.Body>
          <p>Deseja realmente cancelar a fatura?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setExibirCancelar(false)} appearance="subtle">
            Cancelar
          </Button>
          <Button
            appearance="primary"
            type="button"
            onClick={() => {
              cancelarFatura(idFatura, idBoleto, idInvoice);
            }}
          >
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  )
}

