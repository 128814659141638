import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
} from 'rsuite';
import { useSelector, useDispatch } from 'react-redux';
import { format, isToday } from 'date-fns';
import { dadosRegistrosDia } from '~/store/modulos/pedido/actions';
import MapContainer from './Map';

function MapModal({
  getLocation
}) {
  const profissional = useSelector((state) => state.pedido.profissionalRegistrosDia);
  const dispatch = useDispatch();

  return profissional.nome ? <Modal
  backdrop="static"
  open={!!profissional.nome}
  onClose={() => dispatch(dadosRegistrosDia({}))}
  overflow
  // style={{ height: '500px' }}
>
  <Modal.Header>
    <h5>Última localização de {profissional.nome}</h5>
    <span style={{marginRight: 5}}>
      {profissional.local &&
        format(new Date(profissional.local.hora_localizacao), 'dd/MM/yyyy')}
    </span>
    -
    <span style={{marginLeft: 5}}>
    {profissional.local &&
        format(new Date(profissional.local.hora_localizacao), 'HH:mm')}
    </span>
  </Modal.Header>
  <Modal.Body
    style={{
      overflow: 'hidden',
    }}
  >
    <MapContainer
      title={profissional.nome}
      lat={profissional.local.latitude}
      lng={profissional.local.longitude}
      estabelecimento={{
        logradouro: profissional.rua_estab,
        numero: profissional.numero_estab,
        bairro: profissional.bairro_estab
      }}
    />
  </Modal.Body>
  <br />
  {isToday(new Date(profissional.inicio)) && <Modal.Footer>
    <Button
      onClick={() => {
        dispatch(dadosRegistrosDia({}));
        getLocation(profissional.id_profissional, profissional.id);
      }}
      appearance="primary"
    >
      Capturar Localização
    </Button>
  </Modal.Footer>}
</Modal> : <></>
}

MapModal.propTypes = {
  getLocation: PropTypes.func.isRequired
};

export default MapModal;
