/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import { Form, Button, Modal } from 'rsuite';
import PropTypes from 'prop-types';
import { ButtonFullBlue } from '../../../../components/Buttons/ButtonFullBlue/styles';

function AdicionarCurriculo({ exibir, fechar, salvar }) {
  const [camposInput, setCamposInput] = useState({});
  const [carregando, setCarregando] = useState(false);
  const [arquivo, setArquivo] = useState();

  async function salvarCurriculo() {
    setCarregando(true);
    await salvar({ arquivo });
    setCamposInput({});
    setCarregando(false);
  }

  return (
    <Modal open={exibir} onClose={() => fechar()} size="xs">
      <Form
        fluid
        onChange={setCamposInput}
        formDefaultValue={camposInput}
        onSubmit={(erro) => erro && salvarCurriculo()}
      >
        <Modal.Header>
          <Modal.Title>Adicionar Curriculo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.ControlLabel>Curriculo</Form.ControlLabel>
            <Form.Control
              name="arquivo"
              type="file"
              accept=".pdf"
              onChange={async (_, evento) => {
                setArquivo(evento.target.files[0]);
              }}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => fechar()} appearance="subtle">
            Cancelar
          </Button>
          <ButtonFullBlue
            type="submit"
            loading={carregando}
            appearance="primary"
          >
            Confirmar
          </ButtonFullBlue>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

AdicionarCurriculo.propTypes = {
  exibir: PropTypes.bool.isRequired,
  fechar: PropTypes.func.isRequired,
  salvar: PropTypes.func.isRequired,
};

export default AdicionarCurriculo;
