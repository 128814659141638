import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from "prop-types";
import { Modal, Button } from 'rsuite';
import api from '~/services/api';
import MapComponent  from '../MapComponents'

export function LocalizacaoModal ({setModalLocaliza, modalLocaliza, LocalizaRecrutamento}) {
  const [localizacao, setLocalizacao] = useState({
  });
  const buscarLocalizacao= async () => {
  const resposta = LocalizaRecrutamento ? await api.get(`/profissional/location/address/pedidos/${ LocalizaRecrutamento.id_profissional}?id_estabelecimento=${LocalizaRecrutamento.id_estabelecimento }`, {
   
  }) : <></>;
  setLocalizacao(resposta.data)
}

useEffect(() => {
  buscarLocalizacao();
  
}, [LocalizaRecrutamento]);
  return (
  LocalizaRecrutamento ? <Modal
        open={modalLocaliza}
        onClose={() => setModalLocaliza(false)}
        size="md"
      >
         <Modal.Header>
    <h5>Percurso de {LocalizaRecrutamento.nome}</h5>
   
  </Modal.Header>
  <Modal.Body
    style={{
      overflow: 'hidden',
    }}
  >
     <MapComponent
      title={LocalizaRecrutamento.nome}
      estabelecimento={{   
        cep: localizacao ?localizacao.estabEndereco[0].cep : '',
        uf: localizacao ?localizacao.estabEndereco[0].uf : '',
        cidade: localizacao ?localizacao.estabEndereco[0].cidade : '',
        nome: localizacao ? localizacao.estabEndereco[0].nome_fantasia : '',
        logradouro: localizacao ? localizacao.estabEndereco[0].logradouro : '' ,
        numero: localizacao ? localizacao.estabEndereco[0].numero : '',
        bairro: localizacao ? localizacao.estabEndereco[0].bairro : ''
      }}
      profissional={{
        cep: localizacao ?localizacao.profEndereco[0].cep : '',
        uf: localizacao ?localizacao.profEndereco[0].uf : '',
        cidade: localizacao ?localizacao.profEndereco[0].cidade : '',
        nome: localizacao ?localizacao.profEndereco[0].nome : '',
        logradouro: localizacao ?localizacao.profEndereco[0].logradouro : '',
        numero: localizacao ? localizacao.profEndereco[0].numero : '',
        bairro: localizacao ? localizacao.profEndereco[0].bairro :''
      }}
    />
  </Modal.Body>
  <br />
      </Modal> : <></>
  )
  
}

LocalizacaoModal.propTypes = {
  setModalLocaliza: PropTypes.func,
  modalLocaliza: PropTypes.bool,
  LocalizaRecrutamento: PropTypes.objectOf,

}

LocalizacaoModal.defaultProps = {
  LocalizaRecrutamento: {},
  setModalLocaliza: () => { },
  modalLocaliza: false,
};