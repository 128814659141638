import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Form } from 'rsuite';
import { ButtonFullBlue } from '../Buttons/ButtonFullBlue/styles';

export function Modal1({
  model,
  onChange,
  onSubmit,
  show,
  onHide,
  title,
  label,
  onClick,
  name,
}) {
  return (
    <Modal backdrop="static" open={show} onClose={onHide} size="xs">
      <Form model={model} onChange={onChange} onSubmit={onSubmit}>
        <Modal.Header>
          <h3>{title}</h3>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.ControlLabel>{label}</Form.ControlLabel>
            <Form.Control name={name} />
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={onClick} appearance="subtle">
            Cancelar
          </Button>
          <ButtonFullBlue type="submit" appearance="primary">
            Adicionar
          </ButtonFullBlue>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

Modal1.propTypes = {
  model: PropTypes.arrayOf.isRequired,
  onChange: PropTypes.node.isRequired,
  onSubmit: PropTypes.node.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  name: PropTypes.node.isRequired,
};
