import React from 'react';
import { Button, Modal } from 'rsuite';
import PropTypes from 'prop-types';

export const ModalPadrao = ({
  openModal,
  setOpenModal,
  title,
  csv,
  text,
  carregando,
  funcExec,
}) => (
  <Modal open={openModal} onClose={() => setOpenModal(false)} size="xs">
    <Modal.Header>{title}</Modal.Header>
    <Modal.Body>
      <p>{text}</p>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={() => setOpenModal(false)} appearance="subtle">
        Cancelar
      </Button>
      <Button
        appearance="primary"
        loading={carregando}
        type="button"
        onClick={() => {
          if(csv === true){
            funcExec(true)
          }else{
            funcExec();
          }
          
        }}
      >
        Confirmar
      </Button>
    </Modal.Footer>
  </Modal>
);

ModalPadrao.propTypes = {
  openModal: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  csv: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  carregando: PropTypes.bool,
  setOpenModal: PropTypes.func.isRequired,
  funcExec: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};
