import { Pagination } from 'rsuite';

import styled from 'styled-components';
import theme from '../../styles/theme';

export const StyledPagination = styled(Pagination)`
  .rs-pagination-btn.rs-pagination-btn-active {
    background: ${theme.primaryColor};
    color: white;
  }
`;

export const Container = styled.div`
  flex-grow: 1;

  input {
    position: absolute;
    left: 5px;
    top: 5px;
    width: 90%;
  }

  .rs-pagination > li.rs-pagination-btn-active > a {
    background: ${theme.primaryColor};
    color: #fff;
  }
  & button {
    border: none !important;
  }
`;

export const PaginationBar = styled.div`
  display: flex;
  padding: 20px;
  color: #8e8e93;
  place-content: space-between;
  & span {
    align-self: center;
    font-size: 12px;
  }
`;
