/* eslint-disable prettier/prettier */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { addDays, format, isBefore, setHours, setMinutes } from 'date-fns';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import {
  Modal,
  Form,
  Button,
  Schema,
  SelectPicker,
  AutoComplete,
  Divider
} from 'rsuite';
import {
  ResponsiveForm,
  ResponsiveFormTextArea,
} from '~/components/ResponsiveForm';
import { ButtonFullBlue } from '~/components/Buttons/ButtonFullBlue/styles';
import InputMascara from '~/components/InputMascara';
import useSearchNames from '~/services/useSearchNames';





function EdicaoRegistroCriacao({
  exibir,
  registro,
  onFechar,
  onRegistroEditado
}) {
  const autoCorrectedDatePipe = createAutoCorrectedDatePipe('HH:MM');
  const [profissionais, setProfissionais] = useState([]);
  const [camposInput, setCamposInput] = useState({});
  const [profSelecionado, setProfSelecionado] = useState({
  })

  const buscarNomesProfissionais = useSearchNames(
    setProfissionais,
    'profissionais'
  );

  useEffect(() => {
    setCamposInput({
      ...registro,
      hora_inicio: registro.hora_inicio ? format(new Date(registro.hora_inicio), 'HH:mm') : '',
      hora_fim: registro.hora_fim ? format(new Date(registro.hora_fim), 'HH:mm') : ''
    });
  }, [registro]);


  function editarRegistro() {
    const registroEditado = { ...registro, profissao: {}, profissional: {} };

    const horaInicio = camposInput.hora_inicio.substr(0, 2);
    const minutosInicio = camposInput.hora_inicio.substr(2, 3).replace(":", "");
    const horaFim = camposInput.hora_fim.substr(0, 2);
    const minutosFim = camposInput.hora_fim.substr(2, 3).replace(":", "");
    const dataPedido = registroEditado.data ? new Date(registroEditado.data) : new Date(registro.data)
    const dataInicio = setMinutes(setHours(dataPedido, horaInicio), minutosInicio);

    let newDataFim = setMinutes(setHours(dataPedido, horaFim), minutosFim);

    if (isBefore(newDataFim, dataInicio)) {
      newDataFim = addDays(newDataFim, 1);
    }

    const horarioInicioSemFuso = dataInicio.toString()
    const horarioFimSemFuso = newDataFim.toString()


    registroEditado.hora_inicio = horarioInicioSemFuso
    registroEditado.hora_fim = horarioFimSemFuso
    registroEditado.id_profissional = camposInput.id_profissional
    registroEditado.nome_profissional = camposInput.nome
    onRegistroEditado(registroEditado);
  }


  return (
    <Modal open={exibir} onClose={onFechar} size="sm">
      <Form
        fluid
        formValue={camposInput}
        onChange={(valor) => setCamposInput(valor)}
      >
        <Modal.Header style={{ marginBottom: '15px' }}>
          <Modal.Title>Editar Registro</Modal.Title>
        </Modal.Header>
        
        <Form.Group >
          <ResponsiveForm
            label="Hora de Início"
            name="hora_inicio"
            placeholder="Selecione"
            mask={[/\d/, /\d/, ':', /\d/, /\d/]}
            pipe={autoCorrectedDatePipe}
            accepter={InputMascara}
            value={camposInput.hora_inicio}
          />
        </Form.Group>

        <Form.Group >
          <ResponsiveForm
            label="Hora de Fim"
            name="hora_fim"
            placeholder="Selecione"
            mask={[/\d/, /\d/, ':', /\d/, /\d/]}
            pipe={autoCorrectedDatePipe}
            accepter={InputMascara}
            value={camposInput.hora_fim}
          />
        </Form.Group>

        {/* <Divider />
        
        <div style={{display: 'flex', gap: '20px', flexDirection: 'column'}}>

        <p style={{fontSize: '16px', color: 'black'}}>Adicionar Profissional</p>
        <Form.Group>
          <Form.ControlLabel>Nome do Profissional</Form.ControlLabel>
          <Form.Control
            name="nome_profissional"
            accepter={AutoComplete}
            data={profissionais.map((p) => ({
              faltas: "",
              label: p.nome,
              value: p.nome,
              id: p.id,
            }))}
            onSelect={(value, item) => {
              camposInput.id_profissional = item.id;
              camposInput.nome = item.value
            }}
            onEntered={() => {
              camposInput.id_profissional = null;
            }}
            value={camposInput.nome_profissional}
            onChange={buscarNomesProfissionais}
          />
        </Form.Group>

        </div> */}
      </Form>
      <Modal.Footer style={{ marginTop: '20px' }}>
        <Button onClick={onFechar} appearance="subtle">
          Cancelar
        </Button>
        <ButtonFullBlue onClick={() => editarRegistro()} appearance="primary">
          Salvar
        </ButtonFullBlue>
      </Modal.Footer>
    </Modal>
  )
}


EdicaoRegistroCriacao.propTypes = {
  exibir: PropTypes.bool.isRequired,
  onFechar: PropTypes.func.isRequired,
  registro: PropTypes.object.isRequired,
  onRegistroEditado: PropTypes.func.isRequired,

}


export default EdicaoRegistroCriacao