import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Row,
  RadioGroup,
  Radio
} from 'rsuite';
import {
  BarChart,
  Bar as BarRechart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

export default function Services() {
  const dadosServices = useSelector(
    (state) => state.carteiraClientes.topServicos
  );
  const [infoSelecionada, setInfoSelecionada] = useState('servicos')


  const data = [];

  dadosServices.map((service) => {
    return data.push({
      profissao: service.profissao,
      pedidos: service.quantidade,
      avaliacaoMedia: service.avaliacao_media.toFixed(1),
    });
  });


  return (
    <div style={{ textAlign: 'center', width: '500px', height: '300px' }}>
      <h6>Top Serviços</h6>
      <RadioGroup
        onChange={(value) => {
          setInfoSelecionada(value);
        }}
        value={infoSelecionada}
        inline
        defaultValue="servicos"
        style={{display: 'flex', justifyContent: 'center' }}
      >
        <Radio value="servicos">Serviços</Radio>
        <Radio value="avaliacao">Avaliação Média</Radio>
      </RadioGroup>
      <Row>
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 30,
            bottom: -20,
          }}
          barSize={15}
        >
          <XAxis
            minTickGap={-100}
            style={{ fontSize: 10 }}
            name="profissao"
            dataKey="profissao"
          />

          <YAxis />
          <Tooltip 
          labelStyle={{color: 'black'}}
          />
          <Legend />
          <CartesianGrid strokeDasharray="3 3" />

        {
          infoSelecionada ==='servicos' &&
          <BarRechart dataKey="pedidos" fill="#17395E" name="Pedidos" />
        }
        {
          infoSelecionada === 'avaliacao' &&
          <BarRechart
            name="Avaliação Média"
            dataKey="avaliacaoMedia"
            fill="#6EA213"
          />
        }
        </BarChart>
      </Row>
    </div>
  );
}
