/* eslint-disable react/no-danger */
import React from 'react';
import { Modal } from 'rsuite';
import PropTypes from 'prop-types';
import ModalBody from './style';
import { ButtonFullBlue } from '../../../../components/Buttons/ButtonFullBlue/styles';

export default function ShowAnnouncement({ show, closeModal, announcement }) {
  return (
    <Modal size="xs" open={show} onClose={closeModal}>
      <Modal.Header style={{ textAlign: 'center' }}>
        <Modal.Title>{announcement.title}</Modal.Title>
      </Modal.Header>
      <ModalBody
        style={{
          textAlign: 'center',
          height: 500,
          fontSize: '16px',
          wordWrap: 'break-word',
        }}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: `<img style='width: 100%; height: 200px; margin-bottom: 20px;'
          src='${announcement.cover}' alt='Imagem do Comunicado'>
          ${announcement.body}`,
          }}
        />
      </ModalBody>
      <Modal.Footer>
        <ButtonFullBlue onClick={() => closeModal(true)} appearance="primary">
          Fechar
        </ButtonFullBlue>
      </Modal.Footer>
    </Modal>
  );
}

ShowAnnouncement.propTypes = {
  show: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  announcement: PropTypes.objectOf.isRequired,
};
