import React, {useState, memo, useEffect} from 'react'
import PropTypes from 'prop-types';
import { GoogleMap, useJsApiLoader, Marker, DirectionsRenderer } from '@react-google-maps/api';
import { Button } from 'rsuite';

const containerStyle = {
  width: '1500px',
  height: '800px'
};

function MapComponent({ title, estabelecimento, profissional}) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDFDc2Ji7FKyONo8Tj97AMKimxsqRc3CAY",
  });

  const [distance, setDistance] = useState('');
  const [duration, setDuration] = useState('')
  const [directionsResponse, setDirectionsResponse] = useState(null);

  

  // const [origin, setOrigin] = useState('');
  // const [destination, setDestination] = useState('');

  async function calculateRoute() {
   
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService();
    const results = await directionsService.route({
      origin: {
        query: ` ${profissional.logradouro}, ${profissional.numero} - ${profissional.bairro}, ${profissional.cidade} - ${profissional.uf}, ${profissional.cep}`
      },
      destination: {
        query: ` ${estabelecimento.logradouro}, ${estabelecimento.numero} - ${estabelecimento.bairro}, ${estabelecimento.cidade} - ${estabelecimento.uf}, ${estabelecimento.cep}`
      },
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING
    });

    setDirectionsResponse(results);
    setDistance(results.routes[0].legs[0].distance.text);
    setDuration(results.routes[0].legs[0].duration.text);

  }

  function clearRoute() {
    setDirectionsResponse(null);
    setDistance('');
    setDuration('');
  }
  useEffect(() => {
    if (isLoaded) {
      calculateRoute();
    }
  }, [isLoaded, profissional, estabelecimento]);
  return isLoaded ? (
    <>
      {distance && duration && <p style={{marginBottom: 10}}><b>Tempo Estimado:</b> {duration} - <b>Distância:</b> {distance}</p>}
      <GoogleMap
        options={{
          streetViewControl: false,
          zoomControl: false
        }}
        mapContainerStyle={containerStyle}
      
        zoom={15}
      >
        {!distance && <Marker
         
          title={title}
        />}
        {directionsResponse && <DirectionsRenderer directions={directionsResponse} />}
      </GoogleMap>
      </>
  ) : <></>
}

MapComponent.propTypes = {
  title: PropTypes.string.isRequired,
  estabelecimento: PropTypes.objectOf.isRequired,
  profissional: PropTypes.objectOf.isRequired
};

export default memo(MapComponent)