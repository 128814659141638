/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Table, IconButton } from 'rsuite';

import PropTypes from 'prop-types';
import { FileDownload } from '@rsuite/icons';
import theme from '../../../../styles/theme';

const { Cell } = Table;

export const CelulaAcaoBaixarPdf = ({ rowData, onClick, toggleMode, ...props }) => (
  <Cell
    {...props}
    onClick={() => onClick(rowData)}
    style={{
      background: rowData.status === 'Pago' && `${theme.darkGreen}`,
      padding: '6px 0',
    }}
  >
    <IconButton
      appearance="link"
      style={{ color: (rowData.status === 'Pago' || toggleMode) ? `${theme.whiteColor}` : '#575757' }}
      icon={<FileDownload />}
    />
  </Cell>
);

CelulaAcaoBaixarPdf.propTypes = {
  rowData: PropTypes.object,
  onClick: PropTypes.func,
  toggleMode: PropTypes.bool,
};

CelulaAcaoBaixarPdf.defaultProps = {
  toggleMode: false,
  rowData: {},
  onClick: () => {},
};
