import React from 'react';
import { AutoComplete, Form } from 'rsuite';
import PropTypes from 'prop-types';

export default function InputAutocomplete({
  name,
  onchange,
  list,
  setState,
  placeholder,
  target,
  estilo,
}) {
  const data = [];
  if (target === 'estabelecimentos' && list.length > 0) {
    data.push(
      ...list.map((p) => ({
        label: p.nome_fantasia,
        value: p.nome_fantasia,
        id: p.id,
      }))
    );
  } else if (target === 'profissionais' && list.length > 0) {
    data.push(
      ...list.map((p) => ({
        label: p.nome,
        value: p.nome,
        id: p.id,
      }))
    );
  }


  return (
    <Form.Control
      accepter={AutoComplete}
      name={name}
      data={data}
      onSelect={async (value, item) => {
        setState(item.id);
      }}
      onChange={onchange}
      placeholder={placeholder || ''}
      // style={{ marginRight: '15px' }}
      style={estilo != null ? estilo : { width: '300px', marginRight: '15px' }}
    />
  );
}

InputAutocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  onchange: PropTypes.func.isRequired,
  list: PropTypes.arrayOf.isRequired,
  setState: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  estilo: PropTypes.string.isRequired,
};
