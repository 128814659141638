import React, { useState } from 'react';
import { Col, Row, Grid, SelectPicker } from 'rsuite';
import { InputDataRange } from '~/components/InputDataRange';
import PropTypes from 'prop-types';
import { periodoDataGraficos } from '~/common/variaveis';
import GraficoPieChart from '../GraficoPieChart';
import Tabelas from '../tabelas';

function GraficoVendas({ route, titulo, labels }) {
  const [filtroData, setFiltroData] = useState([]);
  const [periodoSelecionado, setPeriodoSelecionado] = useState();
  const locale = {
    sunday: 'Dom',
    monday: 'Seg',
    tuesday: 'Ter',
    wednesday: 'Qua',
    thursday: 'Qui',
    friday: 'Sex',
    saturday: 'Sab',
    ok: 'Ok',
    today: 'Hoje',
    yesterday: 'Ontem',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos',
  };
  return (
    <Grid fluid>
      <Row justify="start" style={{ marginBottom: '20px' }}>
        <InputDataRange
          ranges={[]}
          isoWeek
          locale={locale}
          value={filtroData}
          onChange={(value) => {
            setPeriodoSelecionado(null);
            setFiltroData(value);
          }}
        // hoverRange="month"
        // oneTap
        />
        <SelectPicker
          appearance="default"
          style={{ marginLeft: '15px' }}
          searchable={false}
          placeholder="Período"
          data={periodoDataGraficos}
          value={periodoSelecionado}
          onChange={(value) => {
            setPeriodoSelecionado(value);
            setFiltroData(null);
          }}
        />
      </Row>
      <Row>
        <Col xs={12}>
          <GraficoPieChart
            route={route}
            titulo={titulo}
            labels={labels}
            filtroData={filtroData}
            periodoSelecionado={periodoSelecionado}
          />
        </Col>
        <Col xs={12}>
          <Tabelas
            route={route}
            titulo={titulo}
            labels={labels}
            filtroData={filtroData}
            periodoSelecionado={periodoSelecionado}
          />
        </Col>
      </Row>
    </Grid>
  );
}

GraficoVendas.propTypes = {
  route: PropTypes.func.isRequired,
  titulo: PropTypes.func.isRequired,
  labels: PropTypes.func.isRequired,
};

export default GraficoVendas;
