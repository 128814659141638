import types from './types';

export function states(data) {
  return {
    type: types.GET_STATES,
    payload: data,
  };
}

export function permissions(data) {
  return {
    type: types.SET_PERMISSIONS,
    payload: data,
  };
}
export function userData(data) {
  return {
    type: types.SET_USER,
    payload: data,
  };
}
