/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';

import { FlexboxGrid, Table, CheckPicker, Input, SelectPicker } from 'rsuite';

import { InputDataRange } from '~/components/InputDataRange';
import { Container } from '~/styles/tabela';
import { locale } from '~/common/variaveis';
import api from '~/services/api';
import { TableWithSub } from '../tabela';
import { motivosTarifas, tarifas, columnsTarifas } from '../../common';

export const TarifasLogs = () => {
  const [categoria, setCategoria] = useState(23);
  const [carregando, setCarregando] = useState(true);
  const [data, setData] = useState([]);
  const [motivo, setMotivo] = useState([]);
  const [pagina, setPagina] = useState(1);
  const [pedido, setPedido] = useState();
  const [logsPedido, setLogsPedido] = useState({
    lista: [],
    quantidade: 0,
  });
  const [orderBy, setOrderBy] = useState();

  useEffect(() => {
    const buscaAPI = async () => {
      setCarregando(true);
      try {
        const result = await api.post('/logs/usuarios', {
          pagina,
          categoria,
          id_registro: pedido,
          motivo,
          responsavel: null,
          data_inicio: data[0]
            ? moment(data[0]).format('YYYY-MM-DD')
            : undefined,
          data_fim: data[0] ? moment(data[1]).format('YYYY-MM-DD') : undefined,
        });
        if (result.data.lista.length > 0) {
          const newRes = {
            quantidade: result.data.quantidade,
            lista: result.data.lista.map((res) => ({
              ...res,
              data: res.descricao.data ? res.descricao.data : undefined,
              nome: res.descricao.nome ? res.descricao.nome : undefined,
              tarifa: res.descricao.tarifa ? res.descricao.tarifa : undefined,
              estado: res.descricao.estado ? res.descricao.estado : undefined,
              uf: res.descricao.uf ? res.descricao.uf : undefined,
              hora_minima: res.descricao.hora_minima
                ? res.descricao.hora_minima
                : undefined,
            })),
          };
          setLogsPedido(newRes);
        } else {
          setLogsPedido({
            lista: [],
            quantidade: 0,
          });
        }
        setCarregando(false);
      } catch (err) {
        setCarregando(false);
        toast.error('Erro ao realizar a busca dos logs');
      }
    };
    buscaAPI();
  }, [pagina, pedido, motivo, data, categoria]);

  return (
    <Container>
      <FlexboxGrid justify="start" style={{ gap: '10px' }}>
        <SelectPicker
          appearance="default"
          style={{ width: '200px' }}
          searchable={false}
          data={tarifas}
          value={categoria}
          cleanable={false}
          onChange={(value) => setCategoria(value)}
          defaultValue={[{ label: 'Edição', value: 'Edição' }]}
        />
        <CheckPicker
          appearance="default"
          style={{ width: 200 }}
          searchable={false}
          value={motivo}
          cleanable={false}
          onChange={(value) => setMotivo(value)}
          data={motivosTarifas[categoria].map((moti) => ({
            label: moti.labael,
            value: moti.value,
          }))}
          defaultValue={[{ labael: 'Edição', value: 'Edição' }]}
        />
        <Input
          style={{ width: '200px' }}
          placeholder={`ID ${tarifas.filter((o) => o.value === categoria)[0].label
            }. Ex: 20`}
          onChange={(value) => setPedido(value)}
        />
        <InputDataRange
          style={{ width: '250px' }}
          placeholder="Data"
          ranges={[]}
          onClean={() => setData(['', ''])}
          onChange={(value) => setData(value)}
          locale={locale}
        />
      </FlexboxGrid>

      <TableWithSub
        // title="Profissionais"
        lista={logsPedido.lista}
        quantidade={logsPedido.quantidade}
        paginationBarTop
        paginationBarLow
        setPagina={setPagina}
        columns={columnsTarifas[categoria]}
        childrens={[]}
      />
    </Container>
  );
};
