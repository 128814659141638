/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import EditIcon from '@rsuite/icons/Edit';
import TrashIcon from '@rsuite/icons/Trash';

import { Table, IconButton, Divider } from 'rsuite';
import { DarkModeContext } from '../../../../common/contexts/darkMode';

const { Cell } = Table;

export const fundo = (data) => {
  let cor;
  if (data.status === 'Confirmado' && data.checkin === 'Realizado') {
    cor = '#37AB00';
  } else if (data.status === 'Confirmado' && data.checkin === 'Aguardando') {
    cor = '#ffffe0';
  } else if (data.status === 'Cancelado') {
    cor = '#ff1616';
  } else if (data.status === 'Aguardando') {
    cor = '#ffffe0';
  } else if (data.status === 'Finalizado') {
    cor = '#464749';
  }
  return cor;
};

function CelulaEdit({ rowData, onEditar, onDeletar, ...props }) {
  console.log('celula-rowdata', rowData);
  const { toggleMode } = useContext(DarkModeContext);
  return (
    <Cell
      {...props}
      style={{ backgroundColor: fundo(rowData), padding: '5px' }}
    >
      {onEditar && (
        <IconButton
          appearance="subtle"
          style={{ color: toggleMode || (rowData.status === 'Confirmado' && rowData.checkin === 'Realizado') ? 'white' : '' }}
          onClick={() => onEditar(rowData)}
          icon={<EditIcon />}
        />
      )}
      {onEditar && onDeletar && <Divider vertical />}
      {onDeletar && (
        <IconButton
          appearance="subtle"
          onClick={() => onDeletar(rowData)}
          color="red"
          icon={<TrashIcon />}
        />
      )}
    </Cell>
  );
}

CelulaEdit.propTypes = {
  rowData: PropTypes.object,
  onEditar: PropTypes.func,
  onDeletar: PropTypes.func,
  dataKey: PropTypes.string.isRequired,
};

CelulaEdit.defaultProps = {
  rowData: {},
  onDeletar: null,
  onEditar: null,
};

export default CelulaEdit;
