/* eslint-disable no-restricted-syntax */
/* eslint-disable spaced-comment */
export function donwloadArquivo(data) {
  const blob = new Blob([data], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('hidden', '');
  a.setAttribute('href', url);
  a.setAttribute('download', 'download.csv');
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a)
};

export function GerarCSV({data, download = true}) {
  const csvRows = [];
  const headers = Object.keys(data[0]);

  csvRows.push(headers.join(';'));

  for (const row of data) {
    const values = headers.map(header => {
      // eslint-disable-next-line prefer-template
      const escaped = ('' + row[header]).replace(/"/g, '\\"');
      // return `"${escaped}"`;
      return row[header];
    });
    // csvRows.push(values.join(','));
    csvRows.push(values.join(';'));
  }
  const csv = csvRows.join('\n');

  if (download) {
    donwloadArquivo(csv);
    return 'Download efetuado com sucesso';
  }
  // eslint-disable-next-line consistent-return
  return csv;
}
