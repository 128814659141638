/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import { Button, Modal } from 'rsuite';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import api from '~/services/api';
import { ButtonFullBlue } from '../../../../components/Buttons/ButtonFullBlue/styles';

function ExcluirCertificado({ exibir, fechar, atualizar, certificado }) {
  const [carregando, setCarregando] = useState(false);

  async function excluirCertificado() {
    setCarregando(true);
    try {
      await api.delete(`/profissional/certificado/${certificado.id}`);
      toast.success('Certificado removido');
      atualizar();
      fechar();
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
  }

  return (
    <Modal open={exibir} onClose={() => fechar()} size="xs">
      <Modal.Header>
        <Modal.Title>Excluir Certificado</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Você irá excluir definitivamente o certificado para o treinamento{' '}
          {certificado.treinamento ? certificado.treinamento.treinamento : ''}{' '}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => fechar()} appearance="subtle">
          Cancelar
        </Button>
        <ButtonFullBlue
          type="submit"
          onClick={excluirCertificado}
          loading={carregando}
          appearance="primary"
        >
          Confirmar
        </ButtonFullBlue>
      </Modal.Footer>
    </Modal>
  );
}

ExcluirCertificado.propTypes = {
  exibir: PropTypes.bool.isRequired,
  fechar: PropTypes.func.isRequired,
  atualizar: PropTypes.func.isRequired,
  certificado: PropTypes.object.isRequired,
};

export default ExcluirCertificado;
