/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Table,
  AutoComplete,
  InputGroup,
  FlexboxGrid,
  Whisper,
  Popover,
} from 'rsuite';
import SearchIcon from '@rsuite/icons/Search';

import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';
import { TablePagination } from '~/components/TablePagination';
import { Container } from '~/styles/tabela';
import api from '~/services/api';

import useSearchNames from '~/services/useSearchNames';
import GraficoRecharts from './GraficoRecharts';

const { HeaderCell, Column, Cell } = Table;

function PedidosCancelados() {
  const [estabelecimentoSelecionado, setEstabelecimentoSelecionado] =
    useState('');
  const [pagina, setPagina] = useState(1);
  const [carregando, setCarregando] = useState(false);
  const [nomesEstabelecimentos, setNomesEstabelecimentos] = useState([]);
  const [pedidos, setPedidos] = useState([]);
  const [orderBy, setOrderBy] = useState('order by created_at desc');

  const buscarNomesEstabelecimentos = useSearchNames(
    setNomesEstabelecimentos,
    'estabelecimentos'
  );

  const buscarPedidos = useCallback(async () => {
    setCarregando(true);
    try {
      const resposta = await api.get('/motivo-cancelamento', {
        params: {
          pagina,
          id_estabelecimento: estabelecimentoSelecionado,
          orderBy,
        },
      });
      const lista = resposta.data.lista.map((motivos) => ({
        ...motivos,
        created_at: format(parseISO(motivos.created_at), 'dd/MM/yyyy'),
        numero_pedido: motivos.pedido.numero_pedido,
        nome_fantasia: motivos.estabelecimento.nome_fantasia,
        horario: format(parseISO(motivos.created_at), 'HH:mm'),
      }));
      setPedidos({
        lista,
        quantidade: resposta.data.quantidade,
      });
      if(pedidos.quantidade > 0 && pedidos.lista.length === 0) setPagina(1)
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
  }, [pagina,estabelecimentoSelecionado, orderBy, pedidos.quantidade]);

  useEffect(() => {
    buscarPedidos();
  }, [buscarPedidos]);

  const MotivoCell = ({ rowData, dataKey, ...props }) => {
    const speaker = (
      <Popover title="Motivo:">
        <p>{`${rowData.outro_motivo ? rowData.obs : rowData.descricao}`} </p>
      </Popover>
    );

    return (
      <Cell {...props}>
        <Whisper placement="left" speaker={speaker}>
          <a href style={{ textDecoration: 'none' }}>
            {rowData[dataKey].toLocaleString()}
          </a>
        </Whisper>
      </Cell>
    );
  };

  return (
    <Container>
      <h2>Pedidos Cancelados</h2>
      <div
        style={{
          display: 'flex',
          margin: '10px',
        }}
      >
        <div style={{ width: '50%' }}>
          <FlexboxGrid justify="start">
            <InputGroup style={{ width: '300px' }}>
              <AutoComplete
                placeholder="Pesquisar"
                width={200}
                data={nomesEstabelecimentos.map((p) => ({
                  id: p.id,
                  label: p.nome_fantasia,
                  value: p.nome_fantasia,
                }))}
                onSelect={async (value, item) => {
                  setTimeout(() => {
                    setEstabelecimentoSelecionado(item.id);
                  }, 1000);
                }}
                onChange={(value) => {
                  if (value !== '') {
                    buscarNomesEstabelecimentos(value);
                  } else {
                    setEstabelecimentoSelecionado('');
                  }
                }}
              />
              <InputGroup.Button>
                <SearchIcon />
              </InputGroup.Button>
            </InputGroup>
          </FlexboxGrid>
        </div>
        <div
          style={{
            display: 'block',
            float: 'right',
            width: '80%',
            margin: 'auto',
            marginTop: '-100px',
            marginLeft: '-100px',
          }}
        >
          <GraficoRecharts id_estabelecimento={estabelecimentoSelecionado} />
        </div>
      </div>
      <FlexboxGrid style={{ width: '100%' }}>
        <TablePagination
          carregando={carregando}
          dados={pedidos.lista}
          onChangePage={setPagina}
          quantidade={pedidos.quantidade}
          setOrderBy={setOrderBy}
        >
          <Column resizable sortable width={200}>
            <HeaderCell>Número do Pedido</HeaderCell>
            <Cell dataKey="numero_pedido" />
          </Column>
          <Column resizable sortable width={200}>
            <HeaderCell>Estabelecimento</HeaderCell>
            <Cell dataKey="nome_fantasia" />
          </Column>
          <Column resizable sortable width={200}>
            <HeaderCell>Data do Cancelamento</HeaderCell>
            <Cell dataKey="created_at" />
          </Column>
          <Column resizable width={200}>
            <HeaderCell>Horário do Cancelamento</HeaderCell>
            <Cell dataKey="horario" />
          </Column>
          <Column resizable sortable width={200}>
            <HeaderCell>Motivo do Cancelamento</HeaderCell>
            <MotivoCell dataKey="descricao" />
          </Column>
        </TablePagination>
      </FlexboxGrid>
    </Container>
  );
}

PedidosCancelados.propTypes = {
  rowData: PropTypes.func,
  dataKey: PropTypes.string.isRequired,
};

PedidosCancelados.defaultProps = {
  rowData: {},
};

export default PedidosCancelados;
