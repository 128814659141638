/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';

import { FlexboxGrid, Table, CheckPicker, Input } from 'rsuite';

import { InputDataRange } from '~/components/InputDataRange';
import { locale } from '~/common/variaveis';
import api from '~/services/api';
import { Container } from '~/styles/tabela';
import { PedidosTable } from './tabela';
import { motivosPedidos } from '../../common';

const { HeaderCell, Column, Cell } = Table;

export const Pedidoslogs = () => {
  const [resultadoLogs, setResultadoLogs] = useState();
  const [carregando, setCarregando] = useState(true);
  const [data, setData] = useState([]);
  const [motivo, setMotivo] = useState([]);
  const [pagina, setPagina] = useState(1);
  const [pedido, setPedido] = useState();
  const [logsPedido, setLogsPedido] = useState({
    lista: [],
    quantidade: 0,
  });

  useEffect(() => {
    const buscaAPI = async () => {
      setCarregando(true);
      try {
        const result = await api.post('/logs/usuarios', {
          pagina,
          categoria: 3,
          id_registro: pedido,
          motivo,
          responsavel: null,
          data_inicio: data[0]
            ? moment(data[0]).format('YYYY-MM-DD')
            : undefined,
          data_fim: data[0] ? moment(data[1]).format('YYYY-MM-DD') : undefined,
        });
        if (result.data.lista.length > 0) {
          setLogsPedido(result.data);
        } else {
          setLogsPedido([
            {
              lista: [],
              quantidade: 0,
            },
          ]);
        }
        setCarregando(false);
      } catch (err) {
        setCarregando(false);
        toast.error('Erro ao realizar a busca dos logs');
      }
    };
    buscaAPI();
  }, [pagina, pedido, motivo, data]);

  return (
    <Container>
      <FlexboxGrid justify="start" style={{ gap: '10px' }}>
        <CheckPicker
          appearance="default"
          style={{ width: 200 }}
          searchable={false}
          value={motivo}
          cleanable={false}
          onChange={(value) => setMotivo(value)}
          data={motivosPedidos.map((moti) => ({
            label: moti.labael,
            value: moti.value,
          }))}
          defaultValue={[{ labael: 'Edição', value: 'Edição' }]}
        />
        <Input
          style={{ width: '200px' }}
          placeholder="Núm Pedido. Ex: 51468 "
          onChange={(value) => setPedido(Number(value))}
        />
        <InputDataRange
          style={{ width: '250px' }}
          placeholder="Data"
          ranges={[]}
          onClean={() => setData(['', ''])}
          onChange={(value) => setData(value)}
          locale={locale}
        // defaultValue={[null, null]}
        />
      </FlexboxGrid>

      <PedidosTable
        lista={logsPedido.lista}
        quantidade={logsPedido.quantidade}
        setPagina={setPagina}
      />
    </Container>
  );
};
