/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Button, Schema, Slider } from 'rsuite';
import { useDispatch, useSelector } from 'react-redux';
import { finalizarPedido } from '~/store/modulos/pedido/actions';
import ModalBody from '~/pages/Mensagens/components/ShowAnnouncement/style';

// const { NumberType } = Schema.Types;

function FinalizarPedidoDialog({ exibir, onFechar, fecharEditarPedido }) {
  const dispatch = useDispatch();
  const registros = useSelector((state) =>
    state.pedido.registros.filter((r) => r.status === 'Confirmado')
  );
  const [camposInput, setCamposInput] = useState({});

  const modelo = Schema.Model({});

  // useEffect(() => {
  //   registros.forEach((r) => {
  //     modelo.schema[r.id] = NumberType()
  //       .min(0)
  //       .max(5)
  //       .isRequired(`Adicionar nota para ${r.profissao.profissao}`);
  //   });
  // }, [registros, modelo]);

  async function finalizar() {
    const lista = registros.map((r) => ({
      id: r.id,
      avaliacao_prof: camposInput[r.id],
    }));
    await dispatch(finalizarPedido(lista));
    onFechar();
    fecharEditarPedido();
  }

  return (
    <Modal
      open={exibir}
      onClose={onFechar}
      size="xs"
      style={{ height: '100%', overflow: 'hidden' }}
    >
      <Form
        fluid
        model={modelo}
        formValue={camposInput}
        onChange={setCamposInput}
        onSubmit={(erro) => erro && finalizar()}
      >
        <Modal.Header>
          <Modal.Title>Finalizar Pedido</Modal.Title>
        </Modal.Header>
        <ModalBody style={{ padding: 10 }}>
          {registros.map((registro) => (
            <Form.Group key={registro.id}>
              <Form.ControlLabel>
                {registro.profissao.profissao} -{' '}
                {registro.profissional && registro.profissional.nome}
              </Form.ControlLabel>
              <Form.Control
                accepter={Slider}
                name={`${registro.id}`}
                value={camposInput[registro.id] || 0}
                min={0}
                max={5}
                defaultValue={0}
                graduated
                progress
                step={1}
              />
            </Form.Group>
          ))}
        </ModalBody>
        <Modal.Footer>
          <Button
            style={{ marginTop: 20 }}
            onClick={onFechar}
            appearance="subtle"
          >
            Cancelar
          </Button>
          <Button style={{ marginTop: 20 }} type="submit" appearance="primary">
            Finalizar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

FinalizarPedidoDialog.propTypes = {
  exibir: PropTypes.bool.isRequired,
  onFechar: PropTypes.func.isRequired,
  fecharEditarPedido: PropTypes.func.isRequired,
};

export default FinalizarPedidoDialog;
