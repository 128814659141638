/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Check, Close, CreditCardPlus } from '@rsuite/icons';

import { Table, IconButton } from 'rsuite';

import PropTypes from 'prop-types';
import theme from '../../../../styles/theme';

const { Cell } = Table;

export const CelulaAcaoRelatorio = ({ rowData, onClick, ...props }) => (
  <Cell
    {...props}
    style={{
      background: rowData.status === 'Pago' && `${theme.darkGreen}`,
      padding: '6px 0',
    }}
  >
    {rowData.status === 'Pago' ? (
      <IconButton
        appearance="link"
        onClick={() => onClick(rowData)}
        color="red"
        icon={<Close />}
      />
    ) : (
      <IconButton
        appearance="link"
        onClick={() => onClick(rowData)}
        color="green"
        icon={<Check />}
      />
    )}
  </Cell>
);

export const CelulaAcaoPagamentoRelatorio = ({
  rowData,
  onClick,
  toggleMode,
  ...props
}) => (
  <Cell
    {...props}
    style={{
      background: rowData.status === 'Pago' && `${theme.darkGreen}`,
      padding: '6px 0',
    }}
  >
    <IconButton
      appearance="link"
      onClick={() => onClick(rowData)}
      // color="cyan"
      style={{ color: (rowData.status === 'Pago' || toggleMode) ? `${theme.whiteColor}` : '#575757' }}
      icon={<CreditCardPlus />}
    />
  </Cell>
);

CelulaAcaoPagamentoRelatorio.propTypes = {
  rowData: PropTypes.object,
  onClick: PropTypes.func,
  toggleMode: PropTypes.bool,
};

CelulaAcaoPagamentoRelatorio.defaultProps = {
  toggleMode: false,
  rowData: {},
  onClick: () => {},
};

CelulaAcaoRelatorio.propTypes = {
  rowData: PropTypes.object,
  onClick: PropTypes.func,
};

CelulaAcaoRelatorio.defaultProps = {
  rowData: {},
  onClick: () => {},
};
