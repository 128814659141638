import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Form, Modal, Schema } from 'rsuite';
import { toast } from 'react-toastify';

const { StringType } = Schema.Types;

const model = Schema.Model({
  password: StringType().isRequired('Preenchimento da senha é obrigatório'),
  passwordConfirmation: StringType().isRequired('Preenchimento da confirmação da senha é obrigatório'),
});

export const ModalNewPassword = ({open, setOpen, novaSenha}) => {
  const [ fildsPassword, setFildsPassword ] = useState({
    password: null,
    passwordConfirmation: null
  })
  const saveNewPassword = async () => {
    try {
      if ( fildsPassword.password !== fildsPassword.passwordConfirmation) {
        toast.error('as senhas devem ser iguais')
        return
      }
      await novaSenha(fildsPassword)
      setFildsPassword({
        password: null,
        passwordConfirmation: null
      })
      setOpen(false)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      size="xs"
    >
      <Form
        fluid
        model={model}
        onChange={setFildsPassword}
        formDefaultValue={fildsPassword}
        onSubmit={(erro) => erro && saveNewPassword()}
      >


        <Modal.Header>Criar Nova Senha</Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.ControlLabel>Senha</Form.ControlLabel>
            <Form.Control name="password" />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>Confirmação da Senha</Form.ControlLabel>
            <Form.Control name="passwordConfirmation" />
          </Form.Group>

        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setOpen(false)}
            appearance="subtle"
          >
            Cancelar
          </Button>
          <Button
            appearance="primary"
            type="button"
            onClick={saveNewPassword}
          >
            Salvar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
};

// ModalNewPassword.defaultProps = {

// }

ModalNewPassword.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  novaSenha: PropTypes.func.isRequired
};

