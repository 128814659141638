/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable  */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Form, Button, FlexboxGrid, Schema, Table, Drawer } from 'rsuite';
const { HeaderCell, Column, Cell } = Table;
import PropTypes from 'prop-types';

import api from '~/services/api';
import { Container } from '~/styles/tabela';
import { ButtonFullBlue } from '~/components/Buttons/ButtonFullBlue/styles';
import { MCancelamento } from './MCancelamento';
import { TCancelamento } from './TCancelamento';
import { CellCancelamento } from './CellTCancelamento';
import { toast } from 'react-toastify';
import { TabelaSemPaginacao } from '~/components/TabelaSemPaginacao';

function ModalCancelarPedido({ exibir, fechar, fecharEditarPedido, setAtualizar }) {
  const { camposInput } = useSelector((state) => {
    return state.pedido;
  });

  const regi = useSelector((state) => state.pedido.registros);
  // const { id } = useSelector((state) => state.pedido);
  const { pedido } = useSelector((state) => state.pedido);

  const { id } = pedido;

  const [carregando, setCarregando] = useState(false);
  const [informacoes, setInformacoes] = useState({});
  const [tipoSelecionado, setTipoSelecionado] = useState('estabelecimento');
  const [motivos, setMotivos] = useState({
    estabelecimento: [],
    admin: [],
    profissional: [],
  });
  const [motivoSelecionado, setMotivoSelecionado] = useState();
  const [camposRegistros, setCamposRegistros] = useState(regi);

  function primeiraLetraMaiuscula(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  async function cancelarPedido() {
    setCarregando(true);
    try {
      let valid = true;

      if (pedido.status === 'Cancelado') {
        setCarregando(false);
        toast.success('Pedido já consta com o status de cancelado no sistema.');
        return;
      }

      if (!tipoSelecionado || !motivoSelecionado) valid = false;
      const newRegi = camposRegistros.map((r) => {
        if (!r.tipo_cancelamento || !r.mcancell) valid = false;

        if(r.tipo_cancelamento == 'faltou' || !r.mcancell) valid = true;

        return {
          id: r.id,
          mcancell: r.mcancell,
          tipo_cancelamento: r.tipo_cancelamento == 'profissional' ? primeiraLetraMaiuscula(r.tipo_cancelamento) : r.tipo_cancelamento,
          id_profissional: r.profissional?.id,
        };
      });

      const dataCancel = {
        id_pedido: id,
        tMotivo: tipoSelecionado,
        motivo: motivoSelecionado,
        registros: newRegi,
      };

      if (!valid) {
        toast.error('É obrigatório preencher todos os inputs');
        setCarregando(false);
        return;
      }

      await api.put(`/pedidos/cancelar/${id}`, dataCancel);
      fechar(true)
      setAtualizar((status) => !status)
      fecharEditarPedido()
      toast.success('Pedido cancelado com sucesso.');
      setCarregando(false);
    } catch (error) {
      setCarregando(false);
      toast.error('Erro ao realizar o cancelado do pedido.');
    }
    setCarregando(false);
  }

  const buscaMotivos = async () => {
    try {
      const resposta = await api.get('/tipos-cancelamento');

      const mEstabelecimento = [];
      const mAdmin = [];
      const mProfissional = [];

      resposta.data.map((mot) => {
        if (mot.tipo === 'estabelecimento')
          mEstabelecimento.push({
            label: mot.descricao,
            value: mot.id,
          });
        if (mot.tipo === 'admin')
          mAdmin.push({
            label: mot.descricao,
            value: mot.id,
          });
        if (mot.tipo === 'profissional')
          mProfissional.push({
            label: mot.descricao,
            value: mot.id,
          });
      });
      const allMotivos = {
        estabelecimento: mEstabelecimento,
        admin: mAdmin,
        profissional: mProfissional,
      };

      setMotivos(allMotivos);
    } catch (err) {
      toast.error('Erro ao realizar a busca de motivos.');
    }
  };

  useEffect(() => {
    setCamposRegistros(regi);
  }, [regi]);

  const modelo = Schema.Model({
    tipo_cancelamento: 1,
    nome_profissional: 'estabelecimento',
  });

  useEffect(() => {
    buscaMotivos();
  }, []);

  return (
    <Container open={exibir} onClose={() => fechar()}>
      {/* <b>Cancelar Pedido</b> */}
      <Drawer open={exibir} onClose={() => fechar()} size="lg" >
        <Form
          fluid
          model={modelo}
          onChange={setInformacoes}
          formValue={informacoes}
        // formDefaultValue={setInformacoes}
        >
          <Drawer.Header>
            <Drawer.Title align="left">
              {' '}
              <b>Cancelar Pedido</b>
            </Drawer.Title>
          </Drawer.Header>
          <Drawer.Body style={{overflow: 'hidden'}}>
            <FlexboxGrid justify="flex-start">
              <TCancelamento
                cell={false}
                marginRight
                selected={tipoSelecionado}
                setSelected={setTipoSelecionado}
                setFields={setCamposRegistros}
              />
              <MCancelamento
                call={false}
                selected={motivoSelecionado}
                tipoSelected={tipoSelecionado}
                setSelected={setMotivoSelecionado}
                setFields={setCamposRegistros}
                motivos={motivos}
              />
            </FlexboxGrid>
            <br />
            <FlexboxGrid style={{ width: '895px' }}>
            <TabelaSemPaginacao carregando={carregando} dados={camposRegistros}>
              <Column resizable width={230} sortable>
                <HeaderCell>Nome do Profissional</HeaderCell>
                <Cell dataKey="profissional.nome" />
              </Column>
              <Column resizable width={150} sortable>
                <HeaderCell>Serviço</HeaderCell>
                <Cell dataKey="profissao.profissao" />
              </Column>
              <Column resizable width={125} sortable>
                <HeaderCell>N Registro</HeaderCell>
                <Cell dataKey="numero_registro" />
              </Column>
              <Column resizable width={170} sortable>
                <HeaderCell>Tipo Cancelamento</HeaderCell>
                <CellCancelamento dataKey="id" setFields={setCamposRegistros} />
              </Column>
              <Column resizable width={220} sortable>
                <HeaderCell>Motivo Cancelamento</HeaderCell>
                <CellCancelamento
                  dataKey="id"
                  setFields={setCamposRegistros}
                  type={'mCancell'}
                  motivos={motivos}
                />
              </Column>
            </TabelaSemPaginacao>
            </FlexboxGrid>
          </Drawer.Body>
          <Drawer.Footer>
            <Button onClick={() => fechar()} appearance="subtle">
              Cancelar
            </Button>
            <ButtonFullBlue
              onClick={() => {
                cancelarPedido();
                // fechar();
              }}
              appearance="primary"
            >
              Salvar
            </ButtonFullBlue>
          </Drawer.Footer>
        </Form>
      </Drawer>
    </Container>
  );
}

ModalCancelarPedido.propTypes = {
  exibir: PropTypes.bool.isRequired,
  fechar: PropTypes.func.isRequired,
  fecharEditarPedido: PropTypes.func.isRequired,
  setAtualizar: PropTypes.func.isRequired,
};

export { ModalCancelarPedido };
