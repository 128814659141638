/* eslint-disable react/forbid-prop-types */
import React, { useState, useContext } from 'react';
import { Modal, Button } from 'rsuite';
import { toast } from 'react-toastify';
import api from '~/services/api';
import { DarkModeContext } from '~/common/contexts/darkMode';
import PropTypes from 'prop-types';
import { ButtonBorderBlue } from '../../../../components/Buttons/ButtonBorderBlue/styles';
import { ButtonFullBlue } from '../../../../components/Buttons/ButtonFullBlue/styles';

function AutorizarPedidosAbertos({ buscarPedidos }) {
  const [exibir, setExibir] = useState(false);
  const { toggleMode } = useContext(DarkModeContext)

  async function autorizarPedidos() {
    try {
      await api.put('pedidos/autorizar-abertos');
      buscarPedidos();
      toast.success('Pedidos Autorizados com Sucesso');
    } catch (error) {
      toast.error(error.message);
    }
    setExibir(false);
  }

  return (
    <>
      <ButtonBorderBlue appearance="ghost" onClick={() => setExibir(true)} darkMode={toggleMode} >
        Autorizar Pedidos
      </ButtonBorderBlue>
      <Modal open={exibir} onClose={() => setExibir(false)} size="sm">
        <Modal.Header>
          <Modal.Title>Autorizar Pedidos Abertos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Deseja mesmo autorizar TODOS pedidos em Aberto</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setExibir(false)} appearance="subtle">
            Cancelar
          </Button>
          <ButtonFullBlue
            appearance="primary"
            onClick={() => autorizarPedidos()}
          >
            Autorizar
          </ButtonFullBlue>
        </Modal.Footer>
      </Modal>
    </>
  );
}

AutorizarPedidosAbertos.propTypes = {
  buscarPedidos: PropTypes.func.isRequired,
};

export default AutorizarPedidosAbertos;
