/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Button, Schema, AutoComplete } from 'rsuite';
import { useDispatch } from 'react-redux';
import { aceitarRegistro } from '~/store/modulos/pedido/actions';
import useSearchNames from '~/services/useSearchNames';
import api from '~/services/api';
import { ButtonFullBlue } from '../../../../components/Buttons/ButtonFullBlue/styles';

const { NumberType, StringType } = Schema.Types;

const modelo = Schema.Model({
  id_profissional: NumberType().isRequired('Profissional Obrigatório'),
  nome_profissional: StringType()
    .addRule((_, dados) => {
      if (!dados.id_profissional) return false;

      return true;
    }, 'Selecione um Profissional')
    .isRequired('Obrigatório'),
});

function AdicionarProfissionalDialog({ exibir, registro, onFechar }) {
  const dispatch = useDispatch();
  const [camposInput, setCamposInput] = useState({});
  const [profissionais, setProfissionais] = useState([]);

  
  useEffect(() => {
    setCamposInput({
      ...registro,
      id_profissao: registro.profissao ? registro.profissao.id : null,
      id_profissional: registro.profissional ? registro.profissional.id : null,
    });
  }, [registro]);

  const buscarNomesProfissionais = useSearchNames(
    setProfissionais,
    'profissionais'
  );

  async function editarRegistro() {
    const registroEditado = { profissional: {} };
    registroEditado.id = registro.id;
    registroEditado.profissional.id = camposInput.id_profissional;
    registroEditado.profissional.nome = camposInput.nome_profissional;

    dispatch(aceitarRegistro(registroEditado));
    onFechar();
    await api.patch(`/registros/visualizado/${registro.id}`, { 
        visualizado: false
    })
  }

  return (
    <Modal open={exibir} onClose={onFechar} size="xs">
      <Form
        fluid
        model={modelo}
        formValue={camposInput}
        onChange={(valor) => setCamposInput(valor)}
        onSubmit={(erro) => erro && editarRegistro()}
      >
        <Modal.Header>
          <Modal.Title>Adicionar Profissional</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {camposInput.status && (
            <Form.Group>
              <Form.ControlLabel>Status</Form.ControlLabel>
              <Form.Control
                name="status"
                value={camposInput.status || ''}
                readOnly
              />
            </Form.Group>
          )}
          {camposInput.status &&
            camposInput.status !== 'Aberto' &&
            camposInput.status !== 'Cancelado' && (
              <Form.Group>
                <Form.ControlLabel>Nome do Profissional</Form.ControlLabel>
                <Form.Control
                  name="nome_profissional"
                  accepter={AutoComplete}
                  data={profissionais.map((p) => ({
                    faltas: "",
                    label: p.nome,
                    value: p.nome,
                    id: p.id,
                  }))}
                  onSelect={(value, item) => {
                    camposInput.id_profissional = item.id;
                  }}
                  onEntered={() => {
                    camposInput.id_profissional = null;
                  }}
                  value={camposInput.nome_profissional}
                  onChange={buscarNomesProfissionais}
                />
              </Form.Group>
            )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onFechar} appearance="subtle">
            Cancelar
          </Button>
          <ButtonFullBlue type="submit" appearance="primary">
            Salvar
          </ButtonFullBlue>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

AdicionarProfissionalDialog.propTypes = {
  exibir: PropTypes.bool.isRequired,
  onFechar: PropTypes.func.isRequired,
  registro: PropTypes.object.isRequired,
};

export default AdicionarProfissionalDialog;
