/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { Form, Schema, Button, Modal, SelectPicker } from 'rsuite';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import api from '~/services/api';
import { ButtonFullBlue } from '../../../../components/Buttons/ButtonFullBlue/styles';

const { NumberType } = Schema.Types;

const modelo = Schema.Model({
  id_treinamento: NumberType().isRequired('Treinamento Obrigatório'),
});

function AdicionarCertificado({ exibir, fechar, salvar }) {
  const [camposInput, setCamposInput] = useState({});
  const [treinamentos, setTreinamentos] = useState([]);
  const [carregando, setCarregando] = useState(false);
  const [arquivo, setArquivo] = useState();

  useEffect(() => {
    const buscarBancos = async () => {
      try {
        const resposta = await api.get('/treinamento');
        setTreinamentos(resposta.data);
      } catch (e) {
        toast.error(e.message);
      }
    };

    buscarBancos();
  }, []);

  async function salvarCertificado() {
    setCarregando(true);
    await salvar({ arquivo, id_treinamento: camposInput.id_treinamento });
    setCamposInput({});
    setCarregando(false);
  }

  return (
    <Modal open={exibir} onClose={() => fechar()} size="xs">
      <Form
        fluid
        model={modelo}
        onChange={setCamposInput}
        formDefaultValue={camposInput}
        onSubmit={(erro) => erro && salvarCertificado()}
      >
        <Modal.Header>
          <Modal.Title>Adicionar Certificado</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.ControlLabel>Treinamento</Form.ControlLabel>
            <Form.Control
              name="id_treinamento"
              accepter={SelectPicker}
              placeholder="Selecione"
              searchable={false}
              block
              data={treinamentos.map((t) => {
                return {
                  label: t.treinamento,
                  value: t.id,
                };
              })}
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>Certificado</Form.ControlLabel>
            <Form.Control
              name="arquivo"
              type="file"
              accept=".pdf"
              onChange={async (_, evento) => {
                setArquivo(evento.target.files[0]);
              }}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => fechar()} appearance="subtle">
            Cancelar
          </Button>
          <ButtonFullBlue
            type="submit"
            loading={carregando}
            appearance="primary"
          >
            Confirmar
          </ButtonFullBlue>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

AdicionarCertificado.propTypes = {
  exibir: PropTypes.bool.isRequired,
  fechar: PropTypes.func.isRequired,
  salvar: PropTypes.func.isRequired,
};

export default AdicionarCertificado;
