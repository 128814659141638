import { toast } from 'react-toastify';
import { useDebouncedCallback } from 'use-debounce';
import api from './api';

function useSearchNames(
  setState,
  target = 'estabelecimentos' || 'cidades'||'estados' || 'profissionais' || 'usuarios',
  setFilter
) {
  const buscarNomes = useDebouncedCallback(async (texto) => {
    if (texto && target === 'estabelecimentos' || target === 'profissionais' || target === 'usuarios'){
      try {

        let urlBusca = ''

        if(target === 'estabelecimentos' || target === 'profissionais') {
          urlBusca = `${target}/nome`
        }

        if(target === 'usuarios') urlBusca = 'usuarios'

        const resposta = await api.get(`${urlBusca}`, {
          params: {
            nome: texto,
          },
        });
        const first = [];
        const others = [];
        if (target === 'estabelecimentos') {
          resposta.data.forEach((res) => {
            if (
              res.nome_fantasia.toLowerCase().indexOf(texto.toLowerCase()) === 0
            ) {
              first.push(res);
            } else {
              others.push(res);
            }
          });
          first.sort((a, b) => {
            if (a.nome_fantasia > b.nome_fantasia) return 1;
            if (a.nome_fantasia < b.nome_fantasia) return -1;
            return 0;
          });
          others.sort((a, b) => {
            if (a.nome_fantasia > b.nome_fantasia) return 1;
            if (a.nome_fantasia < b.nome_fantasia) return -1;
            return 0;
          });
          setState(first.concat(others).slice(0, 10));
        }
        if (target === 'profissionais') {
          resposta.data.forEach((res) => {
            if (res.nome.toLowerCase().indexOf(texto.toLowerCase()) === 0) {
              first.push(res);
            } else {
              others.push(res);
            }
          });
          first.sort((a, b) => {
            if (a.nome > b.nome) return 1;
            if (a.nome < b.nome) return -1;
            return 0;
          });
          others.sort((a, b) => {
            if (a.nome > b.nome) return 1;
            if (a.nome < b.nome) return -1;
            return 0;
          });
          setState(first.concat(others).slice(0, 10));
        }
        if (target === 'usuarios') {
          resposta.data.usuarios.forEach((res) => {
            if (res.nome.toLowerCase().indexOf(texto.toLowerCase()) === 0) {
              first.push(res);
            } else {
              others.push(res);
            }
          });
          first.sort((a, b) => {
            if (a.nome > b.nome) return 1;
            if (a.nome < b.nome) return -1;
            return 0;
          });
          others.sort((a, b) => {
            if (a.nome > b.nome) return 1;
            if (a.nome < b.nome) return -1;
            return 0;
          });
          setState(first.concat(others).slice(0, 10));
        }
        if(texto === '') {
          if (setFilter) {
            setFilter([]);
          }
        }
      } catch (error) {
        toast.error(error.message);
      }
    }else if(texto && target === 'cidades' || target === 'estado' ){
      
      try {
        const resposta = await api.get(`${target}`, {
          params: {
            nome: texto,
          },
        });
        const first = [];
        const others = [];
        if (target === 'cidades') {
          resposta.data.forEach((res) => {
            if (
              res.cidade.toLowerCase().indexOf(texto.toLowerCase()) === 0
            ) {
              first.push(res);
            } else {
              others.push(res);
            }
          });
          first.sort((a, b) => {
            if (a.cidade > b.cidade) return 1;
            if (a.cidade < b.cidade) return -1;
            return 0;
          });
          others.sort((a, b) => {
            if (a.cidade > b.cidade) return 1;
            if (a.cidade < b.cidade) return -1;
            return 0;
          });
          setState(first.concat(others).slice(0, 10));
        }
        if (target === 'estado') {
          resposta.data.forEach((res) => {
            if (res.estado.toLowerCase().indexOf(texto.toLowerCase()) === 0) {
              first.push(res);
            } else {
              others.push(res);
            }
          });
          first.sort((a, b) => {
            if (a.estado > b.estado) return 1;
            if (a.estado < b.estado) return -1;
            return 0;
          });
          others.sort((a, b) => {
            if (a.estado > b.estado) return 1;
            if (a.estado < b.estado) return -1;
            return 0;
          });
          setState(first.concat(others).slice(0, 10));
        }
      } catch (error) {
        toast.error(error.message);
      }
    } else if(texto === '') {
      if (setFilter) {
        setFilter([]);
      }
    }
  }, 500).callback;

  return buscarNomes;
}

export default useSearchNames;
