/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/jsx-boolean-value */
import React, { useState, useEffect } from 'react';
import { Form, Button, Modal } from 'rsuite';
import PropTypes from 'prop-types';
import InputMascara from '~/components/InputMascara';

import api from '~/services/api';
import { ButtonFullBlue } from '../../../../../components/Buttons/ButtonFullBlue/styles';

// const { NumberType } = Schema.Types;

// const modelo = Schema.Model({
//   id_profissao: NumberType().isRequired('Profissao Obrigatória'),
// });

function AdicionarManual({ exibir, fechar, salvar, newEndereco }) {
  const [camposInput, setCamposInput] = useState({});
  const [carregando, setCarregando] = useState(false);
  const [erroCep, setErroCep] = useState();
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (!!newEndereco) {
      setCamposInput({
        cep: newEndereco.cep,
        logradouro: newEndereco.logradouro,
        numero: newEndereco.numero,
        complemento: newEndereco.complemento,
        referencia: newEndereco.referencia,
        bairro: newEndereco?.bairro?.bairro,
        cidade: newEndereco?.bairro?.cidade?.cidade,
        estado: newEndereco?.bairro?.cidade?.estado?.estado,
        uf: newEndereco?.bairro?.cidade?.estado?.uf,
        tipo: 2,
        id: newEndereco?.id,
      });
    }
  }, [newEndereco]);

  async function buscarCep(cep) {
    setCarregando(true);
    try {
      const resultado = await api.get(`/cep/${cep}`);
      const { endereco } = resultado.data;
      if (!endereco.bairro.bairro) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
      setCamposInput({
        cep,
        logradouro: endereco.logradouro,
        numero: endereco.numero,
        complemento: endereco.complemento,
        referencia: endereco.referencia,
        bairro: endereco.bairro.bairro,
        cidade: endereco.bairro.cidade.cidade,
        estado: endereco.bairro.cidade.estado.estado,
        uf: endereco.bairro.cidade.estado.uf,
        id: newEndereco?.id,
      });
      setErroCep();
    } catch (error) {
      setErroCep('Erro ao buscar dados do CEP');
    }
    setCarregando(false);
  }

  async function salvarConta() {
    setCarregando(true);
    await salvar(camposInput);
    setCamposInput({});
    setCarregando(false);
  }

  return (
    <Modal open={exibir} onClose={() => fechar()} size="xs">
      <Form
        fluid
        // model={modelo}
        onChange={setCamposInput}
        formDefaultValue={camposInput}
        onSubmit={() => salvarConta()}
      >
        <Modal.Header>
          <Modal.Title>Endereço</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.ControlLabel>CEP:</Form.ControlLabel>
            <Form.Control
              label="Cep"
              name="cep"
              value={camposInput.cep || ''}
              mask={[/[0-9]/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
              accepter={InputMascara}
              onChange={(texto) => texto.length === 9 && buscarCep(texto)}
              errorMessage={erroCep}
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>Logradouro:</Form.ControlLabel>
            <Form.Control
              name="logradouro"
              value={camposInput.logradouro || ''}
              onChange={(value) =>
                setCamposInput({
                  ...camposInput,
                  logradouro: value,
                })
              }
              disabled={false}
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>Número:</Form.ControlLabel>
            <Form.Control
              name="numero"
              value={camposInput.numero || ''}
              onChange={(value) =>
                setCamposInput({
                  ...camposInput,
                  numero: value,
                })
              }
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>Complemento:</Form.ControlLabel>
            <Form.Control
              name="complemento"
              value={camposInput.complemento || ''}
              onChange={(value) =>
                setCamposInput({
                  ...camposInput,
                  complemento: value,
                })
              }
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>Referencia:</Form.ControlLabel>
            <Form.Control
              name="referencia"
              value={camposInput.referencia || ''}
              onChange={(value) =>
                setCamposInput({
                  ...camposInput,
                  referencia: value,
                })
              }
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>Bairro:</Form.ControlLabel>
            <Form.Control
              name="bairro"
              value={camposInput.bairro || ''}
              disabled={disabled}
              onChange={(value) =>
                setCamposInput({
                  ...camposInput,
                  bairro: value,
                })
              }
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>Cidade:</Form.ControlLabel>
            <Form.Control
              name="cidade"
              value={camposInput.cidade || ''}
              disabled={true}
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>Estado:</Form.ControlLabel>
            <Form.Control
              name="estado"
              value={camposInput.estado || ''}
              disabled={true}
            />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>UF:</Form.ControlLabel>
            <Form.Control
              name="uf"
              value={camposInput.uf || ''}
              disabled={true}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => fechar()} appearance="subtle">
            Cancelar
          </Button>
          <ButtonFullBlue
            type="submit"
            loading={carregando}
            appearance="primary"
          >
            Confirmar
          </ButtonFullBlue>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

AdicionarManual.propTypes = {
  exibir: PropTypes.bool.isRequired,
  fechar: PropTypes.func.isRequired,
  salvar: PropTypes.func.isRequired,
  newEndereco: PropTypes.func.isRequired,
};

export default AdicionarManual;
