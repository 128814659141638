/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { toast } from 'react-toastify';
import { Loader, SelectPicker } from 'rsuite';
import api from '~/services/api';
import PropTypes from 'prop-types';
import { periodoDataGraficos } from '~/common/variaveis';
import { NovoFiltro } from '../../../../../../components/FiltroAnos/index';
import { numberToMonth } from '../../../../../../utils';

export function GraphLineChart({ route, titulo, filtroGrafico }) {
  const [carregando, setCarregando] = useState(true);
  const [anoAtual, setAnoAtual] = useState(new Date().getFullYear());
  const [periodoSelecionado, setPeriodoSelecionado] = useState('all');
  // const labels = [
  //   'Janeiro',
  //   'Fevereiro',
  //   'Março',
  //   'Abril',
  //   'Maio',
  //   'Junho',
  //   'Julho',
  //   'Agosto',
  //   'Setembro',
  //   'Outubro',
  //   'Novembro',
  //   'Dezembro',
  // ];
  const labels = [];

  // eslint-disable-next-line prettier/prettier
  const meses = labels.forEach(function () {});

  const [data01, setData01] = useState([]);
  const [grafico, setGrafico] = useState({
    labels,
    datasets: [],
  });

  const anos = [
    { ano: anoAtual - 2, cor: '#4169e1' },
    { ano: anoAtual - 1, cor: '#A61784' },
    { ano: anoAtual, cor: '#32a900' },
  ];

  useEffect(() => {
    const datasets = anos.map((ano) => {
      return {
        label: `${titulo} ${ano.ano}`,
        data: [],
      };
    });
    setGrafico({ ...grafico, datasets });
  }, [route, filtroGrafico]);

  const labelMesAno = (data) => {
    return periodoSelecionado
      ? `${numberToMonth(Number(data.slice(5, 7)), true)}-${Number(
          data.slice(1, 4)
        )}`
      : numberToMonth(Number(data.slice(5, 7)), true);
  };
  async function buscaDados() {
    setCarregando(true);
    try {
      const { datasets } = grafico;
      const dados = await api.get(`relatorios/graficos/${route}`, {
        params: {
          ano: periodoSelecionado ? null : anoAtual,
          filtroGrafico: filtroGrafico && filtroGrafico,
          periodo: periodoSelecionado,
        },
      });

      const data = [];
      dados.data.map((dado) => {
        return data.push({
          quantidade: Number(dado.quantidade),
          mes: labelMesAno(dado.mes),
        });
      });
      setGrafico({ ...grafico, datasets });
      setData01(data, meses);
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
  }

  useEffect(() => {
    return grafico.datasets.length > 0 && buscaDados();
  }, [grafico.datasets, periodoSelecionado, anoAtual]);

  return (
    <>
      <div style={{ height: '500px' }}>
        <NovoFiltro
          anoAtual={anoAtual}
          setAnoAtual={setAnoAtual}
          setPeriodoSelecionado={setPeriodoSelecionado}
        />
        <SelectPicker
          appearance="default"
          style={{ marginLeft: '15px' }}
          searchable={false}
          placeholder="Período"
          data={periodoDataGraficos}
          value={periodoSelecionado}
          onChange={(value) => {
            setPeriodoSelecionado(value);
          }}
        />
        {Number(data01) !== isNaN && (
          <LineChart
            width={1000}
            height={400}
            data={data01}
            margin={{
              top: 30,
              right: 30,
              left: -20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="100 1" />
            <XAxis dataKey="mes" />
            <YAxis />
            <Tooltip />
            <Legend
              style={{ margin: '50px' }}
              verticalAlign="bottom"
              height={10}
            />
            <Line
              type="monotone"
              dataKey="quantidade"
              stroke="#FF0000"
              strokeWidth={3}
              dot={{ stroke: 'red', strokeWidth: 2 }}
              activeDot={{ r: 8 }}
            />
          </LineChart>
        )}
        {carregando && <Loader center size="lg" vertical />}
      </div>
    </>
  );
}

GraphLineChart.propTypes = {
  route: PropTypes.func.isRequired,
  titulo: PropTypes.func.isRequired,
  filtroGrafico: PropTypes.string.isRequired,
};

export default GraphLineChart;
