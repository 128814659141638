/* eslint-disable react/no-danger */
import React from 'react';
import { Modal, Button, Tag } from 'rsuite';
import PropTypes from 'prop-types';

export default function AnnouncementsEstablishmentFilters({
  show,
  closeModal,
  announcement,
  filters,
}) {
  return (
    <Modal
      size="xs"
      open={show}
      overflow={false}
      backdrop="static"
      onClose={closeModal}
    >
      <Modal.Header>
        <Modal.Title>Filtros: {announcement.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ textAlign: 'center', height: 500 }}>
        {filters && filters.city.length > 0 && (
          <>
            <h4>Cidades</h4>
            {filters.city.map((ct) => {
              return (
                <>
                  <span>
                    <Tag style={{ marginRight: '10px' }}>{ct.cidade}</Tag>
                  </span>
                </>
              );
            })}
          </>
        )}
        {filters && filters.district.length > 0 && (
          <>
            <h4>Bairros</h4>
            {filters.district.map((ct) => {
              return (
                <>
                  <span>
                    <Tag style={{ marginRight: '10px' }}>{ct.bairro}</Tag>
                  </span>
                </>
              );
            })}
          </>
        )}
        {filters && filters.professional.length > 0 && (
          <>
            <h4>Profissionais</h4>
            {filters.professional.map((ct) => {
              return (
                <>
                  <span>
                    <Tag style={{ marginRight: '10px' }}>{ct.nome}</Tag>
                  </span>
                </>
              );
            })}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => closeModal(true)} appearance="primary">
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

AnnouncementsEstablishmentFilters.propTypes = {
  show: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  announcement: PropTypes.objectOf.isRequired,
  filters: PropTypes.objectOf.isRequired,
};
