/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  PaginationBar,
  StyledPagination,
} from '~/components/TablePagination/styles';
import { Divider } from 'rsuite';

export const Th = ({ title = [] }) => {
  return title.map((th) => (
    <th style={th.style ? th.style : {}}>{th.title}</th>
    // <th style={{ width: th.width, height: th.height }}>{th.title}</th>
  ));
};

Th.propTypes = {
  title: PropTypes.arrayOf.isRequired,
};

export const Td = ({ columns = [], log }) => {
  return columns.map((th) => (
    <td style={{ width: th.width, height: th.height }}>{log[th.value]}</td>
  ));
};

Td.propTypes = {
  columns: PropTypes.arrayOf.isRequired,
  log: PropTypes.object.isRequired,
};

export const Pagination = ({ quantidade, setPagina }) => {
  const [page, setPage] = useState(1);
  return (
    <PaginationBar>
      <span style={{ marginLeft: 20 }}>
        <Divider vertical />
        Total: {quantidade}
      </span>
      <StyledPagination
        prev
        next
        maxButtons={5}
        showLengthMenu={false}
        first
        last
        activePage={page}
        total={quantidade}
        onChangePage={(pagina) => {
          setPage(pagina);
          setPagina(pagina);
        }}
      />
    </PaginationBar>
  );
};

Pagination.propTypes = {
  quantidade: PropTypes.number.isRequired,
  setPagina: PropTypes.func.isRequired,
};
