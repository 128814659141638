import React from 'react';
import PropTypes from 'prop-types';
import { Search } from '@rsuite/icons';

import { Input, InputGroup } from 'rsuite';

export default function SearchTable({ filtrarLista, estilo }) {
  return (
    <InputGroup style={estilo != null ? estilo : { width: '300px', marginRight: '15px' }}>
      <Input placeholder="Pesquisar" width={200} onChange={filtrarLista} />
      <InputGroup.Button>
        <Search />
      </InputGroup.Button>
    </InputGroup>
  );
}

SearchTable.propTypes = {
  filtrarLista: PropTypes.func.isRequired,
  estilo: PropTypes.func.isRequired,
};
