import React from 'react';
import { Col } from 'rsuite';
import PropTypes from 'prop-types';
import { Container } from '../styles';
import Receita from './receita';
import GraficoVendas from './components/graficoVendas';
import GraficoCancelamentos from './components/graficoCancelamentos';
import { GraphLineChart } from './components/GraphLineChart';

function Graficos({ tipoGrafico }) {
  return (
    <Container>
      <Col>
        {
          {
            financeiro: <Receita />,
            profissionais: (
              <GraphLineChart
                route="profissionais"
                titulo="Profissionais ativos"
              />
            ),
            clientes: (
              <GraphLineChart route="clientes" titulo="Clientes ativos" />
            ),
            maioresClientes: (
              <GraficoVendas
                route="maiores-clientes"
                titulo="Maiores Clientes"
              />
            ),
            vendasFuncao: (
              <GraficoVendas route="profissoes" titulo="Vendas por Função" />
            ),
            vendasDias: (
              <GraficoVendas
                route="dia-semana"
                titulo="Vendas por Dias"
                labels={[
                  'Domingo',
                  'Segunda-feira',
                  'Terça-feira',
                  'Quarta-feira',
                  'Quinta-feira',
                  'Sexta-feira',
                  'Sábado',
                ]}
              />
            ),
            vendaCidades: (
              <GraficoVendas
                route="vendas-cidades"
                titulo="Vendas por Cidade"
              />
            ),
            cancelamentosMes: (
              <GraficoCancelamentos
                route="cancelamentos"
                titulo="Cancelamentos"
              />
            ),
            faltasMes: (
              <GraphLineChart route="faltas" titulo="Faltas por mês" />
            ),
          }[tipoGrafico]
        }
      </Col>
    </Container>
  );
}

Graficos.propTypes = {
  tipoGrafico: PropTypes.func.isRequired,
};

export default Graficos;
