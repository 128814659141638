/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-danger */
/* eslint-disable no-return-assign */
import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar, Form, Modal } from 'rsuite';
import { toast } from 'react-toastify';
import api from '~/services/api';
import { ButtonFullBlue } from '../../../components/Buttons/ButtonFullBlue/styles';
import ModalBody from './ShowAnnouncement/style';

export default function EditAnnouncement({
  target,
  setShowEditAnnouncement,
  showEditAnnouncement,
  announcement,
  listAnnouncements,
  setLoading,
}) {
  const editorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [editorContent, setEditorContent] = useState('');
  const [communicationTitle, setCommunicationTitle] = useState('');
  const [coverImageUrl, setCoverImageUrl] = useState('');
  const [summary, setSummary] = useState('');
  const [cover, setCover] = useState();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  function handleCommunicationTitle(value) {
    setCommunicationTitle(value);
  }

  function handleSummary(value) {
    setSummary(value);
  }

  function handleCoverImage(e) {
    if (e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      const reader = new FileReader();
      setCover(e.target.files[0]);

      reader.readAsDataURL(selectedFile);

      reader.onloadend = () => {
        setCoverImageUrl(reader.result);
      };
    } else {
      setCoverImageUrl(announcement.cover);
    }
  }

  function setEditorAnnouncementBody(inst) {
    inst.setContent(editorContent);
  }

  async function updateAnnouncement() {
    setLoading(true);
    if (!coverImageUrl) {
      await api
        .put(`/announcements/${target}/${announcement.id}`, {
          data: {
            summary,
            title: communicationTitle,
            body: editorContent,
          },
        })
        .then((response) => {
          toast.success(response.data.message);
          setLoading(false);
          listAnnouncements();
        })
        .catch((err) => {
          toast.error(err.message);
          setLoading(false);
        });
    } else {
      const formData = new FormData();
      formData.append('cover', cover);
      formData.append('summary', summary);
      formData.append('title', communicationTitle);
      formData.append('body', editorContent);
      await api
        .put(`/announcements/${target}/cover/${announcement.id}`, formData)
        .then((response) => {
          toast.success(response.data.message);
          setLoading(false);
          listAnnouncements();
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.message);
        });
    }
  }

  useEffect(() => {
    setCommunicationTitle(announcement.title);
    setEditorContent(announcement.body);
    setSummary(announcement.summary);
  }, [announcement.title, announcement.body, announcement.summary]);

  return (
    <>
      <Modal
        full
        open={showEditAnnouncement}
        onClose={() => {
          setShowEditAnnouncement(false);
        }}
      >
        <Modal.Header>
          <h3>Editar: {announcement.title}</h3>
        </Modal.Header>
        <Modal.Body style={{ padding: '20px' }}>
          {/* eslint-disable-next-line prettier/prettier */}
          <Form fluid onSubmit={() => { }}>
            <Form.Group>
              <Form.ControlLabel>
                Imagem{' '}
                <strong style={{ fontSize: 10, color: 'grey', marginLeft: 5 }}>
                  Tamanho obrigatório 400x200
                </strong>
              </Form.ControlLabel>

              <input
                name="announcement_image"
                type="file"
                accept="image/*"
                onChange={handleCoverImage}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Título</Form.ControlLabel>
              <Form.Control
                name="announcement_title"
                value={communicationTitle}
                onChange={handleCommunicationTitle}
                required
                maxlength={16}
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Resumo</Form.ControlLabel>
              <Form.Control
                name="announcement_summary"
                value={summary}
                onChange={handleSummary}
                required
              />
            </Form.Group>
            <br />
            <ButtonToolbar>
              <ButtonFullBlue
                onClick={() => setOpen(true)}
                appearance="primary"
              >
                {' '}
                Preview
              </ButtonFullBlue>
            </ButtonToolbar>
            <br />
            <Editor
              onInit={(evt, editor) => (editorRef.current = editor)}
              apiKey="pv8hnike17xcj19zp9782tyg170swsns0wxumltslkzn2j8d"
              init={{
                init_instance_callback: setEditorAnnouncementBody,
                menubar: 'custom',
                plugins: ['emoticons'],
                height: 500,
                language: 'pt_BR',
                language_url: '/tiny_lang/pt_BR.js',
                toolbar:
                  'emoticons | bold italic underline strikethrough | fontsizeselect',
                fontsize_formats: '16px 22px 26px',
                content_style: `
                  body {text-align: center;}
                 `,
                preview_styles: 'background-color',
                required: true,
                placeholder: 'Limite de 500 caracteres',
              }}
              onEditorChange={() => {
                if (
                  editorRef.current.getContent({ format: 'text' }).length <= 500
                ) {
                  setEditorContent(editorRef.current.getContent());
                } else {
                  editorRef.current.setContent(editorContent);
                }
              }}
            />
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={() => {
              setShowEditAnnouncement(false);
            }}
            appearance="subtle"
          >
            Cancelar
          </Button>
          <ButtonFullBlue
            type="button"
            appearance="primary"
            onClick={() => {
              setShowConfirmDialog(true);
            }}
          >
            Editar
          </ButtonFullBlue>
        </Modal.Footer>
      </Modal>
      <Modal
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title>Confirmar mudanças</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Deseja confirmar as modificações em{' '}
            <strong>{communicationTitle}</strong>?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setShowConfirmDialog(false)}
            appearance="subtle"
          >
            Cancelar
          </Button>
          <ButtonFullBlue
            onClick={() => {
              setShowConfirmDialog(false);
              setShowEditAnnouncement(false);
              setLoading(true);
              updateAnnouncement();
            }}
            appearance="primary"
          >
            Confirmar
          </ButtonFullBlue>
        </Modal.Footer>
      </Modal>
      <Modal open={open} onClose={() => setOpen(false)} size="xs">
        <Modal.Header style={{ textAlign: 'center' }}>
          <Modal.Title>{announcement.title}</Modal.Title>
        </Modal.Header>
        <ModalBody
          style={{
            textAlign: 'center',
            height: 500,
            fontSize: '16px',
            wordWrap: 'break-word',
          }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: `<img style='width: 100%; height: 200px; margin-bottom: 20px;'
                src='${!coverImageUrl ? announcement.cover : coverImageUrl}'
                alt='Imagem do Comunicado'>
                ${!editorContent ? announcement.body : editorContent}`,
            }}
          />
        </ModalBody>
        <Modal.Footer>
          <Button onClick={() => setOpen(false)} appearance="primary">
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

EditAnnouncement.propTypes = {
  target: PropTypes.string.isRequired,
  setShowEditAnnouncement: PropTypes.func.isRequired,
  listAnnouncements: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  showEditAnnouncement: PropTypes.bool.isRequired,
  announcement: PropTypes.objectOf.isRequired,
};
