import React, { useState, useEffect } from 'react';

import { Container } from '~/styles/tabela';
import { Pedidoslogs } from './components/pedidos';
import { ProfissionaisLogs } from './components/profissionais';
import { ClientesLogs } from './components/clientes';
import { AdministracaoLogs } from './components/administracao';
import { OpcoesLogs } from './components/opcoes';
import { TarifasLogs } from './components/tarifas';
import { RecrutamentoLogs } from './components/recrutamento';
import { NavLogs } from './components/nav';

export default function Logs() {
  const [resultLogs, setResultLogs] = useState('pedidos');

  return (
    <Container>
      <NavLogs active={resultLogs} onSelect={setResultLogs} />
      {
        {
          pedidos: <Pedidoslogs />,
          profissionais: <ProfissionaisLogs />,
          clientes: <ClientesLogs />,
          opções: <OpcoesLogs />,
          tarifas: <TarifasLogs />,
          administração: <AdministracaoLogs />,
          recrutamento: <RecrutamentoLogs />,
        }[resultLogs]
      }
    </Container>
  );
}
