/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Divider, Col, Row, Form, Schema, Loader } from 'rsuite';
import { useDispatch, useSelector } from 'react-redux';
import api from '~/services/api';
import {
  atualizarCamposInput,
  criarPedido,
  criarPedidoAntigo as CriarPedidoAntigoAction,
} from '~/store/modulos/pedido/actions';
import { ButtonFullBlue } from '../../../components/Buttons/ButtonFullBlue/styles';

import { Container } from './styles';
import ListaRegistro from '../../Pedidos/components/ListaRegistro';
import CamposDadosRecrutamento from '../components/CamposDadosRecrutamento';

const { StringType, NumberType, ArrayType, DateType, ObjectType } =
  Schema.Types;

function CriarRecrutamento() {
  const [pedidoAntigo, setPedidoAntigo] = useState(false);

  const dispatch = useDispatch();
  const carregando = useSelector((state) => state.pedido.carregando);
  // const camposInput = useSelector((state) => state.pedido.camposInput);
  const [camposInput, setCamposInput] = useState({});

  const modelo = Schema.Model({
    nome_cliente: StringType().isRequired('Nome obrigatório'),
    servico: NumberType().isRequired('Serviço obrigatório'),
    // data_solicitacao: StringType().isRequired('Data obrigatória'),
    data_solicitacao: ObjectType(),
    quantidade: NumberType().isRequired('Quantidade obrigatória'),
    salario: NumberType(),
    // tipo_contrato: StringType().isRequired('Tipo de contrato obrigatório'),
    tipo_contrato: StringType(),
    previsao_inicio: StringType(),
    jornada: StringType(),
    perfil: StringType(),
    beneficio: ArrayType(),
    experiencia: StringType(),
    observacao: StringType(),
    anotacoes: StringType(),
  });

  let erro = {};

  function exibirMensagemErro() {
    Object.values(erro).forEach((e) => {
      toast.error(e);
    });
  }

  async function salvarRecrutamento() {
    // setCarregando(true);

    try {
      // const [dia, mes, ano] = camposInput.data_solicitacao.split('/');
      const body = {
        id_estabelecimento: camposInput.id_estabelecimento,
        id_servico: camposInput.servico,
        // data_solicitacao: new Date(ano, mes - 1, dia).toISOString(),
        data_solicitacao: camposInput.data_solicitacao,
        total_vagas: camposInput.quantidade,
        salario: camposInput.salario,
        gorjeta: camposInput.gorjeta,
        tipo_contrato: camposInput.tipo_contrato,
        previsao_inicio: camposInput.previsao_inicio,
        jornada: camposInput.jornada,
        perfil: camposInput.perfil,
        beneficios: camposInput.beneficios,
        experiencia: camposInput.experiencia,
        observacao: camposInput.observacao,
        anotacoes: camposInput.anotacoes,
      };

      await api.post(`/recrutamento`, body);
      // const resposta = await api.post(`/recrutamento${camposInput.id}`, body);
      // history.push(`/recrutamento/editar/${resposta.data.id}`);

      setCamposInput({});

      toast.success('Recrutamento criado com sucesso');
    } catch (e) {
      toast.error(e.message);
    }
    // setCarregando(false);
  }

  return (
    <Container>
      <Form
        model={modelo}
        onChange={setCamposInput}
        formValue={camposInput}
        onSubmit={(semErro) =>
          semErro ? salvarRecrutamento() : exibirMensagemErro()
        }
        onError={(err) => {
          erro = err;
        }}
      >
        <CamposDadosRecrutamento
          // atualizarCamposInput={atualizarCamposInput}
          camposInput={camposInput}
          setCamposInput={setCamposInput}
        />
        <Divider />

        <Row style={{ margin: '0', marginBottom: '5px' }}>
          <Col sm={6} />
          <Col sm={12}>
            <ButtonFullBlue
              appearance="primary"
              type="submit"
              size="lg"
              loading={carregando}
              block
            >
              Salvar
            </ButtonFullBlue>
          </Col>
          <Col sm={6} />
        </Row>
      </Form>
      {carregando && <Loader backdrop center />}
    </Container>
  );
}

export default CriarRecrutamento;
