import React from 'react';
import { SelectPicker } from 'rsuite';
import PropTypes from 'prop-types';
import { motivosCancelamentos } from './motivos';

export function MCancelamento({
  cell = false,
  selected,
  setSelected,
  tipoSelected,
  setFields,
  motivos,
  data,
  marginRight,
}) {

  return (
    <div>
      {!cell ? <p>Motivo Cancelamento</p> : ''}

    
      {data.tipo_cancelamento !== 'faltou' && ( 
      <SelectPicker
        label="Motivo Cancelamento"
        placeholder="Selecione o Motivo"
        appearance="default"
        style={{ marginRight: marginRight ? '15px' : 0, width: 300 }}
        searchable={false}
        data={
          motivos.estabelecimento.length > 0
            ? motivos[`${tipoSelected}`]
            : motivosCancelamentos[`${tipoSelected}`]
        }
        value={!cell ? selected : data.mcancell}
        onChange={(value) => {
          if (cell === false) {
            setSelected(value)
            setFields((f) =>
              f.map((r) => ({
                ...r,
                mcancell: value 
              }))
            )
          } else {
            setFields((f) =>
              f.map((r) => ({
                ...r,
                mcancell: r.id === data.id ? value : r.mcancell,
              }))
            )
          }
        }
        }
      />) }
     
    </div>
  );
}

MCancelamento.defaultProps = {
  cell: false,
  selected: '',
  tipoSelected: 'estabelecimento',
  setSelected: () => { },
  data: {
    tcancell: '',
    mcancell: '',
  },
  setFields: () => { },
  marginRight: false,
};

MCancelamento.propTypes = {
  selected: PropTypes.string,
  tipoSelected: PropTypes.string,
  setSelected: PropTypes.func,
  setFields: PropTypes.func,
  data: PropTypes.node,
  cell: PropTypes.bool,
  marginRight: PropTypes.bool,
  motivos: PropTypes.shape({
    estabelecimento: PropTypes.arrayOf.isRequired,
    admin: PropTypes.arrayOf.isRequired,
    profissional: PropTypes.arrayOf.isRequired,
  }).isRequired,
  // motivos: PropTypes.shape({
  //   estabelecimento: PropTypes.arrayOf.isRequired,
  //   admin: PropTypes.arrayOf.isRequired,
  //   profissional: PropTypes.arrayOf.isRequired,
  // }).isRequired,
};
