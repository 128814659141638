/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Divider, Table } from 'rsuite';
import PropTypes from 'prop-types';
import { Container, PaginationBar, StyledPagination } from './styles';

export const TablePagination = ({
  wordWrap,
  dados,
  carregando,
  children,
  quantidade,
  onChangePage,
  setOrderBy,
  headerHeight
}) => {
  const [page, setPage] = useState(1);
  const [sortType, setSortType] = useState();
  const [sortColumn, setSortColumn] = useState();

  if (sortColumn && sortType) {
    setOrderBy(`order by ${sortColumn} desc`);
    if (sortType === 'desc') {
      setOrderBy(`order by ${sortColumn} asc`);
    }

  }

  function handleSortColumn(sortColumn1, sortType1) {
    setSortColumn(sortColumn1);
    setSortType(sortType1);
  }
  return (
    <Container>
      <PaginationBar>
        <span style={{ marginLeft: 20 }}>
          <Divider vertical />
          Total: {quantidade}
        </span>
        <StyledPagination
          prev
          next
          maxButtons={5}
          showLengthMenu={false}
          first
          last
          activePage={page}
          total={quantidade}
          onChangePage={(pagina) => {
            setPage(pagina);
            onChangePage(pagina);
          }}
        />
      </PaginationBar>
      <Table
        loading={carregando}
        data={dados}
        sortType={sortType}
        sortColumn={sortColumn}
        // eslint-disable-next-line react/jsx-no-bind
        onSortColumn={handleSortColumn}
        height={1420}
        wordWrap={wordWrap}
        headerHeight={headerHeight}
        bordered
        cellBordered
      >
        {children}
      </Table>
      <PaginationBar>
        <span style={{ marginLeft: 20 }}>
          <Divider vertical />
          Total: {quantidade}
        </span>
        <StyledPagination
          prev
          next
          maxButtons={5}
          showLengthMenu={false}
          first
          last
          activePage={page}
          total={quantidade}
          onChangePage={(pagina) => {
            setPage(pagina);
            onChangePage(pagina);
          }}
        />
      </PaginationBar>
    </Container >
  );
};

TablePagination.propTypes = {
  dados: PropTypes.array,
  carregando: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  quantidade: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChangePage: PropTypes.func.isRequired,
  setOrderBy: PropTypes.func.isRequired,
};

TablePagination.defaultProps = {
  dados: [],
};
