/* eslint-disable import/named */
/* eslint-disable react/prop-types */
/* eslint-disable no-console */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';

import { Plus, Minus } from '@rsuite/icons';

import { Divider, IconButton } from 'rsuite';

import PropTypes from 'prop-types';
import {
  PaginationBar,
  StyledPagination,
} from '~/components/TablePagination/styles';
import { Linha, Tabela, ContainerTable } from './styles';
import { Container } from '../styles';
import { RegistrosTable } from './Registros';

export function PedidosTable(props) {
  const { lista = [], quantidade = 0, setPagina } = props;
  const [pedidos, setPedidos] = useState([]);
  const [page, setPage] = useState(1);

  const exibirRegistros = (id, ex) => {
    const results = pedidos.map((p) => ({
      ...p,
      exibir: p.id === id ? ex : p.exibir,
      icone: p.id === id ? !ex : p.icone,
    }));
    setPedidos(results);
  };

  useEffect(() => {
    const newLista = lista.map((l) => ({
      ...l,
      exibir: false,
      icone: !!(l.descricao.registros && l.descricao.registros.length > 0),
    }));
    setPedidos(newLista);
  }, [lista]);

  return (
    <Container>
      <PaginationBar>
        <span style={{ marginLeft: 20 }}>
          <Divider vertical />
          Total: {quantidade}
        </span>
        <StyledPagination
          prev
          next
          maxButtons={5}
          showLengthMenu={false}
          first
          last
          activePage={page}
          total={quantidade}
          onChangePage={(pagina) => {
            setPage(pagina);
            setPagina(pagina);
          }}
        />
      </PaginationBar>
      {/* <ContainerTable> */}
      <Tabela>
        <thead className="thTablePedLogs">
          <tr className="thTablePedLogs">
            <th>Reg</th>
            <th>Responsável</th>
            <th>Pedido</th>
            <th>Motivo</th>
            <th>Data</th>
          </tr>
        </thead>
        <tbody>
          {pedidos &&
            pedidos.length > 0 &&
            pedidos.map((log) => (
              <>
                <tr key={log.id} className="trTablePedLogs">
                  <td>
                    {log.icone ? (
                      <IconButton
                        appearance="subtle"
                        onClick={() => exibirRegistros(log.id, true)}
                        icon={<Plus />}
                      />
                    ) : (
                      <IconButton
                        appearance="subtle"
                        onClick={() => exibirRegistros(log.id, false)}
                        icon={<Minus />}
                      />
                    )}
                  </td>
                  <td>{log.adm_nome}</td>
                  <td>{log.id_registro}</td>
                  <td>{log.motivo}</td>
                  <td>{log.createdAt}</td>
                </tr>
                {log.exibir &&
                  log.descricao.registros &&
                  log.descricao.registros.length > 0 && (
                    <Linha key={log.id} colSpan="4" display="none">
                      <td colSpan="5" valign="middle">
                        <RegistrosTable lista={log.descricao.registros} />
                      </td>
                    </Linha>
                  )}
              </>
            ))}
        </tbody>
      </Tabela>
      {/* </ContainerTable> */}
      <PaginationBar>
        <span style={{ marginLeft: 20 }}>
          <Divider vertical />
          Total: {quantidade}
        </span>
        <StyledPagination
          prev
          next
          maxButtons={5}
          showLengthMenu={false}
          first
          last
          activePage={page}
          total={quantidade}
          onChangePage={(pagina) => {
            setPage(pagina);
            setPagina(pagina);
          }}
        />
      </PaginationBar>
    </Container>
  );
}

PedidosTable.propTypes = {
  lista: PropTypes.arrayOf.isRequired,
  quantidade: PropTypes.number.isRequired,
  setPagina: PropTypes.func.isRequired,
};
