import { formatDistanceToNow } from 'date-fns';
import React, { useCallback, useEffect, useState } from 'react';
import pt from 'date-fns/locale/pt-BR';
import { toast } from 'react-toastify';
import {
  Button,
  CheckPicker,
  Checkbox,
  CheckTreePicker,
  Col,
  Form,
  IconButton,
  Input,
  Modal,
  Panel,
  Row,
  Table,
  Grid,
  FlexboxGrid,
} from 'rsuite';
import { Edit, Trash } from '@rsuite/icons';
import { ButtonFullBlue } from '~/components/Buttons/ButtonFullBlue/styles';
import { ResponsiveForm } from '~/components/ResponsiveForm';
import { TabelaPaginacao } from '~/components/TabelaPaginacao';
import api from '~/services/api';
import { Container } from '~/styles/tabela';
import theme from '~/styles/theme';
import permissions from './utils/permissions';

const { Column, Cell, HeaderCell } = Table;
const { ControlLabel } = Form;
console.log(permissions, 'permissions')

function CriarUsuarios() {
  const [carregando, setCarregando] = useState(false);
  const [exibir, setExibir] = useState(false);
  const [exibirEditar, setExibirEditar] = useState(false);
  const [exibirDeletar, setExibirDeletar] = useState(false);
  const [acessos, setAcessos] = useState();
  const [cargo, setCargo] = useState('');
  const [cargoSelecionado, setCargoSelecionado] = useState();
  const [listaCargos, setListaCargo] = useState([]);
  const [roles, setRoles] = useState();
  const [usuarioNome, setUsuarioNome] = useState('');
  const [admin, setAdmin] = useState({
    name: '',
    email: '',
    password: '',
  });
  const [listaUsuarios, setListaUsuarios] = useState([]);
  const [pagina, setPagina] = useState(1);
  const [quantidade, setQuantidade] = useState(0);
  const [usuario, setUsuario] = useState({
    nome: '',
    email: '',
    senha: '',
  });
  const [usuariosSelecionado, setUsuariosSelecionado] = useState();
  const [checkAtivados, setCheckAtivados] = useState(false);
  const footerStyles = {
    padding: '10px 2px',
    borderTop: '1px solid #e5e5e5',
  };

  const footerButtonStyle = {
    float: 'right',
    marginRight: 10,
    marginTop: 2,
  };

  const usuariosLista = useCallback(async () => {
    setCarregando(true);
    await api.get(`/usuarios?pagina=${pagina}`).then((response) => {
      const lista = response.data.usuarios.map((user) => ({
        ...user,
        nomeCargo: user.cargo.nome.includes('role-')
          ? 'Personalizado'
          : user.cargo.nome,
        createdAt: formatDistanceToNow(new Date(user.createdAt), {
          locale: pt,
        }),
      }));
      setListaUsuarios(lista);
      setQuantidade(response.data.quantidade);
      setCarregando(false);
    });
  }, [pagina]);

  async function carregarCargos() {
    await api.get('/roles').then((response) => {
      setListaCargo(response.data);
    });
  }

  async function criarCargo() {
    await api
      .post('/roles-create', {
        name: cargo,
        roles: acessos,
      })
      .then(() => {
        toast.success('Cargo criado com sucesso!');
        carregarCargos();
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  function limparAcessos() {
    setAcessos();
  }

  async function createAdm() {
    await api
      .post('/adm-create', {
        name: admin.name,
        email: admin.email,
        password: admin.password,
        role: cargoSelecionado || null,
        roles: acessos || null,
      })
      .then((response) => {
        toast.success(response.data.message);
        usuariosLista();
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  async function dadosUsuario(id) {
    setCarregando(true);
    await api.get(`/usuarios/${id}`).then((response) => {
      setUsuario(response.data);
      setExibirEditar(true);
      setCarregando(false);
    });
  }

  async function editarUsuario() {
    setCarregando(true);
    await api
      .put(`/usuarios/${usuariosSelecionado}`, {
        ...usuario,
        role: cargoSelecionado,
        roles: acessos,
      })
      .then(() => {
        toast.success('Usuário editado com sucesso!');
        setCarregando(false);
        setExibirEditar(false);
        usuariosLista();
        setCargoSelecionado();
        setAcessos();
      })
      .catch((err) => {
        toast.error(err.message);
        setCarregando(false);
      });
  }
  async function removerCargos() {
    await api
      .post('/roles', {
        roles,
      })
      .then(() => {
        toast.success('Cargos removidos com sucesso!');
        carregarCargos();
        setRoles();
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  async function removerUsuarios() {
    await api
      .delete(`/usuarios/${usuariosSelecionado}`)
      .then(() => {
        usuariosLista();
        toast.success(`${usuarioNome} foi removido!`);
        setExibirDeletar(false);
      })
      .catch((err) => {
        setExibirDeletar(false);
        toast.error(err.message);
      });
  }

  useEffect(() => {
    carregarCargos();
    usuariosLista();
  }, [usuariosLista]);

  return (
    <Container>
      <div>
        <Panel header={<h3>Criar Usuário</h3>} bordered>
          <Form>
            <Grid fluid Style={{ gap: '10px' }}>
              <Row className="show-grid">
                <Col xs={24} sm={24} md={8}>
                  <ResponsiveForm
                    placeholder="Nome Completo"
                    name="nome"
                    type="text"
                    value={admin.name}
                    onChange={(value) => {
                      setAdmin({
                        ...admin,
                        name: value,
                      });
                    }}
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <ResponsiveForm
                    placeholder="Email"
                    name="email"
                    type="email"
                    value={admin.email}
                    onChange={(value) => {
                      setAdmin({
                        ...admin,
                        email: value,
                      });
                    }}
                  />
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <ResponsiveForm
                    placeholder="Senha"
                    name="senha"
                    type="text"
                    value={admin.password}
                    onChange={(value) => {
                      setAdmin({
                        ...admin,
                        password: value,
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={8} style={{ marginTop: '10px' }}>
                  <CheckTreePicker
                    placeholder="Níveis de acesso"
                    onChange={setAcessos}
                    data={permissions}
                    renderExtraFooter={() => {
                      return (
                        <div style={footerStyles}>
                          <ButtonFullBlue
                            style={footerButtonStyle}
                            appearance="primary"
                            size="sm"
                            onClick={() => setExibir(true)}
                          >
                            Salvar como Cargo
                          </ButtonFullBlue>
                        </div>
                      );
                    }}
                    style={{ width: '100%' }}
                  />
                </Col>
                <Col xs={24} sm={24} md={8} style={{ marginTop: '10px' }}>
                  <CheckPicker
                    placeholder="Selecionar cargo existente"
                    data={listaCargos || []}
                    style={{ width: '100%' }}
                    onChange={(value) => {
                      setCargoSelecionado(value[0]);
                      setRoles(value);
                    }}
                    renderExtraFooter={() => {
                      return (
                        <div style={footerStyles}>
                          <ButtonFullBlue
                            disabled={!roles || roles.length < 1}
                            style={{
                              ...footerButtonStyle,
                              backgroundColor: theme.lightRed,
                            }}
                            appearance="primary"
                            size="sm"
                            onClick={() => removerCargos()}
                          >
                            Remover selecionados
                          </ButtonFullBlue>
                        </div>
                      );
                    }}
                  />
                </Col>
                <Col xs={24} sm={24} md={8} style={{ marginTop: '10px' }}>
                  <ButtonFullBlue
                    appearance="primary"
                    type="submit"
                    size="lg"
                    loading={carregando}
                    block
                    onClick={() => {
                      createAdm();
                      limparAcessos();
                    }}
                  >
                    Cadastrar
                  </ButtonFullBlue>
                </Col>
              </Row>
            </Grid>
          </Form>
        </Panel>
      </div>
      <FlexboxGrid style={{ width: '100%' }}>
      <TabelaPaginacao
        carregando={carregando}
        dados={listaUsuarios}
        quantidade={quantidade}
        onChangePage={setPagina}
      >
        <Column resizable width={240} sortable>
          <HeaderCell>Nome</HeaderCell>
          <Cell dataKey="nome" />
        </Column>

        <Column resizable width={240} sortable>
          <HeaderCell>Email</HeaderCell>
          <Cell dataKey="email" />
        </Column>

        <Column resizable width={240} sortable>
          <HeaderCell>Cargo</HeaderCell>
          <Cell dataKey="nomeCargo" />
        </Column>
        <Column resizable width={200} sortable>
          <HeaderCell>Tempo Cadastro</HeaderCell>
          <Cell dataKey="createdAt" />
        </Column>
        <Column resizable width={150}>
          <HeaderCell>Editar</HeaderCell>
          <Cell
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 10,
            }}
            dataKey="id"
          >
            {(rowData) => {
              return (
                <>
                  <IconButton
                    icon={<Edit />}
                    onClick={() => {
                      dadosUsuario(rowData.id);
                      setUsuariosSelecionado(rowData.id);
                    }}
                  />
                  <IconButton
                    icon={<Trash />}
                    onClick={() => {
                      setUsuariosSelecionado(rowData.id);
                      setUsuarioNome(rowData.nome);
                      setExibirDeletar(true);
                    }}
                  />
                </>
              );
            }}
          </Cell>
        </Column>
      </TabelaPaginacao>
      </FlexboxGrid>
      <Modal
        open={exibir}
        onClose={() => {
          setExibir(false);
        }}
        size="sm"
        onHide={() => {
          setCargoSelecionado();
          setAcessos();
        }}
      >
        <Modal.Header>
          <Modal.Title>Criar novo Cargo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Input
            placeholder="Título do cargo"
            onChange={setCargo}
            value={cargo}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setExibir(false)} appearance="subtle">
            Cancelar
          </Button>
          <ButtonFullBlue
            appearance="primary"
            type="button"
            onClick={() => {
              criarCargo();
              limparAcessos();
            }}
          >
            Salvar
          </ButtonFullBlue>
        </Modal.Footer>
      </Modal>

      <Modal
        open={exibirEditar}
        onClose={() => {
          setExibirEditar(false);
        }}
        size="sm"
      >
        <Modal.Header>
          <Modal.Title>Editar Usuário</Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{ display: 'flex', flexDirection: 'column', gap: 20 }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
            <ControlLabel>Nome</ControlLabel>
            <Input
              placeholder="Nome"
              onChange={(value) => {
                setUsuario({
                  ...usuario,
                  nome: value,
                });
              }}
              value={usuario.nome}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
            <ControlLabel>Email</ControlLabel>
            <Input
              placeholder="Email"
              onChange={(value) => {
                setUsuario({
                  ...usuario,
                  email: value,
                });
              }}
              value={usuario.email}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
            <ControlLabel>Nova Senha</ControlLabel>
            <Input
              placeholder="Senha"
              value={usuario.senha}
              onChange={(value) => {
                setUsuario({
                  ...usuario,
                  senha: value,
                });
              }}
            />
          </div>
          <div style={{ display: 'flex', gap: 5 }}>
            <Col xs={24} sm={24} md={8} style={{ marginTop: 30 }}>
              <CheckTreePicker
                placeholder="Níveis de acesso"
                onChange={setAcessos}
                data={permissions}
                style={{ width: 280 }}
              />
            </Col>
            <Col xs={24} sm={24} md={8} style={{ marginTop: 30 }}>
              <CheckPicker
                placeholder="Selecionar cargo existente"
                data={listaCargos || []}
                style={{ width: 280 }}
                onChange={(value) => {
                  setCargoSelecionado(value[0]);
                }}
              />
            </Col>
            <Checkbox
          value={usuario.ativados}
          checked={usuario.ativados}
          style={{ margin: 0, padding: 0 }}
          onChange={(a, value) => {
            setUsuario({
              ...usuario,
              ativados: value,
            });
          }}
          title="Filtrar dados por aguardando checkin"
        >
          Ativado
        </Checkbox>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setExibirEditar(false)} appearance="subtle">
            Cancelar
          </Button>
          <ButtonFullBlue
            appearance="primary"
            type="button"
            onClick={() => editarUsuario()}
          >
            Editar
          </ButtonFullBlue>
        </Modal.Footer>
      </Modal>
      <Modal
        open={exibirDeletar}
        onClose={() => {
          setExibirDeletar(false);
        }}
        size="xs"
      >
        <Modal.Header>
          <Modal.Title>Remover Usuário</Modal.Title>
        </Modal.Header>
        <Modal.Body>Deseja mesmo remover {usuarioNome}?</Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setExibirDeletar(false)} appearance="subtle">
            Cancelar
          </Button>
          <ButtonFullBlue
            style={{ backgroundColor: theme.lightRed }}
            appearance="primary"
            type="button"
            onClick={() => removerUsuarios()}
          >
            Remover
          </ButtonFullBlue>
        </Modal.Footer>
      </Modal>
    </Container >
  );
}

export default CriarUsuarios;
