import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Form, Panel, Col } from 'rsuite';
import { ButtonFullBlue } from '~/components/Buttons/ButtonFullBlue/styles';
import { ResponsiveForm } from '~/components/ResponsiveForm';
import api from '~/services/api';

export default function Usuario() {
  const loggeduser = useSelector((state) => state.setUser.data);
  const [senhaAtual, setSenhaAtual] = useState('');
  const [novaSenha, setNovaSenha] = useState('');

  async function editarSenha() {
    await api
      .put(`/adm/update/${loggeduser.id}`, {
        novaSenha,
        senhaAtual,
      })
      .then(() => {
        toast.success('Senha alterada com sucesso!');
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }

  return loggeduser ? (
    <Panel header={<h3>Dados do Usuário</h3>} style={{ width: '100%' }}>
      <Form>
        <Col md={12} xs={24} sm={24}>
          <ResponsiveForm
            placeholder="Nome Completo"
            name="nome"
            type="text"
            label="Nome"
            value={loggeduser.nome}
            disabled
          />
        </Col>
        <Col md={12} xs={24} sm={24}>
          <ResponsiveForm
            placeholder="Email"
            name="email"
            type="email"
            label="Email"
            value={loggeduser.email}
            disabled
          />
        </Col>
        <Col md={12} xs={24} sm={24}>
          <ResponsiveForm
            label="Senha atual"
            value={senhaAtual}
            onChange={setSenhaAtual}
            name="senha-atual"
            type="password"
          />
        </Col>
        <Col md={12} xs={24} sm={24}>
          <ResponsiveForm
            label="Nova senha"
            value={novaSenha}
            onChange={setNovaSenha}
            name="senha"
            type="password"
          />
        </Col>
        <ButtonFullBlue
          disabled={!(senhaAtual !== '' && novaSenha !== '')}
          appearance="primary"
          onClick={() => editarSenha()}
        >
          Editar Senha
        </ButtonFullBlue>
      </Form>
    </Panel>
  ) : (
    <h1>Carregando...</h1>
  );
}
