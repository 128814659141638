/* eslint-disable prettier/prettier */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Form,
  Button,
  Schema,
  SelectPicker,
  AutoComplete,
} from 'rsuite';
import useSearchNames from '~/services/useSearchNames';
import { ButtonFullBlue } from '../../../../components/Buttons/ButtonFullBlue/styles';

const { NumberType, StringType } = Schema.Types;

function EditarRegistroDialog({
  exibir,
  registro,
  onFechar,
  onRegistroEditado,
  profissoes,
  dadosEstabelecimento
}) {
  const [camposInput, setCamposInput] = useState({});
  const [profissionais, setProfissionais] = useState([]);
  const disabled =  'disabled';
  const [enderecoSelecionado, setEnderecoSelecionado] = useState({
    id: null,
    endereco: {},
    enderecoFormatado: ''
  });

  const modelo = Schema.Model({
    id_profissao: NumberType().isRequired('Serviço Obrigatório'),
    avaliacao_prof: NumberType()
      .min(0, 'Avaliação deve ser maior do que zero')
      .max(5, 'Avaliação deve ser menor do que cinco'),
    avaliacao_est: NumberType()
      .min(0, 'Avaliação deve ser maior do que zero')
      .max(5, 'Avaliação deve ser menor do que cinco'),
    valor_eventual: NumberType('Número incorreto').min(
      0,
      'Apenas valores positivos'
    ),
    nome_profissional:
      camposInput.status &&
        camposInput.status !== 'Aberto' &&
        camposInput.status !== 'Cancelado' &&
        camposInput.status !== 'Autorizado'
        ? StringType().addRule((v, dados) => {
          if (!dados.id_profissional) return false;

          return true;
        }, 'Selecione um Profissional')
        : // .isRequired('Obrigatorio')
        StringType(),
  });

const enderecoRegistro = dadosEstabelecimento && dadosEstabelecimento.end && dadosEstabelecimento.end.map((r) => {
  return {
  id: r.id,
  endereco: r,
  enderecoFormatado: `${r.logradouro}, ${r.numero}. ${r.bairro.bairro} - ${r.bairro.cidade.cidade} / ${r.bairro.cidade.estado.estado}`
}
})

  useEffect(() => {
    setCamposInput({
      ...registro,
      id_profissao: registro.profissao ? registro.profissao.id : null,
      id_profissional: registro.profissional ? registro.profissional.id : null,
      nome_profissional: registro.profissional
        ? registro.profissional.nome
        : null,
      sinal_valor_eventual: registro.valor_eventual >= 0 ? 1 : -1,
      valor_eventual: Math.abs(registro.valor_eventual),
      endereco: registro.endereco ?
       `${registro.endereco.logradouro}, ${registro.endereco.numero === 0 ? 'S/N' : registro.endereco.numero}. ${registro.endereco.bairro.bairro} - ${registro.endereco.bairro.cidade.cidade} / ${registro.endereco.bairro.cidade.estado.estado}` :
        null
  })

  }, [registro]);

  const buscarNomesProfissionais = useSearchNames(
    setProfissionais,
    'profissionais'
  );

  function editarRegistro() {
    const registroEditado = { ...registro, profissao: {}, profissional: {} };

    registroEditado.profissional.id = camposInput.id_profissional;
    registroEditado.profissional.nome = camposInput.nome_profissional;
    registroEditado.profissao.id = camposInput.id_profissao;
    registroEditado.avaliacao_prof = camposInput.avaliacao_prof;
    registroEditado.avaliacao_est = camposInput.avaliacao_est;
    registroEditado.feedback_prof = camposInput.feedback_prof;
    registroEditado.tipo_cancelamento = camposInput.tipo_cancelamento;
    registroEditado.desc_estab = camposInput.desc_estab;
    registroEditado.desc_cupom = camposInput.desc_cupom;
    registroEditado.tarifa_estado = camposInput.tarifa_estado;
    registroEditado.tarifa_emergencia = camposInput.tarifa_emergencia;
    registroEditado.tarifa_dinamica = camposInput.tarifa_dinamica;
    registroEditado.tarifa_fe = camposInput.tarifa_fe;
    registroEditado.valor_eventual =
      camposInput.valor_eventual * camposInput.sinal_valor_eventual;
    registroEditado.hora_inicio = camposInput.hora_inicio;
    registroEditado.hora_fim = camposInput.hora_fim;
    registroEditado.endereco = (enderecoSelecionado.id !== null) ? enderecoSelecionado[0].endereco : registro.endereco
    onRegistroEditado(registroEditado);
    setEnderecoSelecionado({
      id: null,
      endereco: {},
      enderecoFormatado: ''
    })
  }

  return (
    <Modal open={exibir} onClose={onFechar} size="sm">
      <Form
        fluid
        model={modelo}
        formValue={camposInput}
        onChange={(valor) => setCamposInput(valor)}
        onSubmit={(erro) => erro && editarRegistro()
        }
      >
        <Modal.Header>
          <Modal.Title>Editar Registro</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.ControlLabel>Serviço</Form.ControlLabel>
            <Form.Control
            disabled={disabled}
              name="id_profissao"
              accepter={SelectPicker}
              placeholder="Selecione"
              value={camposInput.id_profissao}
              data={profissoes.map((p) => {
                return {
                  label: p.profissao,
                  value: p.id,
                };
              })}
            />
          </Form.Group>
          {camposInput.status && (
            <Form.Group>
              <Form.ControlLabel>Status</Form.ControlLabel>
              <Form.Control
                name="status"
                value={camposInput.status || ''}
                readOnly
              />
            </Form.Group>
          )}
          {camposInput.status && camposInput.status === 'Cancelado' && (
            <Form.Group>
              <Form.ControlLabel>Tipo Cancelamento</Form.ControlLabel>
              <Form.Control
                name="tipo_cancelamento"
                accepter={SelectPicker}
                placeholder="Selecione"
                value={camposInput.tipo_cancelamento}
                searchable={false}
                data={[
                  {
                    label: 'Estabelecimento',
                    value: 'Estabelecimento',
                  },
                  {
                    label: 'Profissional',
                    value: 'Profissional',
                  },
                  {
                    label: 'Administrador',
                    value: 'Administrador',
                  },
                ]}
              />
            </Form.Group>
          )}
          {camposInput.status &&
            camposInput.status !== 'Aberto' &&
            camposInput.status !== 'Cancelado' && (
              <>
                <Form.Group>
                  <Form.ControlLabel>Nome do Profissional</Form.ControlLabel>
                  <Form.Control
                    name="nome_profissional"
                    accepter={AutoComplete}
                    data={profissionais
                      .map((p) => ({
                        label: p.nome,
                        value: p.nome,
                        id: p.id,
                      }))
                      .slice(0, 10)}
                    onSelect={(value, item) => {
                      camposInput.id_profissional = item.id;
                    }}
                    onEntered={() => {
                      camposInput.id_profissional = null;
                    }}
                    value={camposInput.nome_profissional}
                    onChange={buscarNomesProfissionais}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>Valor de Repasse</Form.ControlLabel>
                  <Form.Control
                    name="valor"
                    readOnly
                    value={camposInput.valor || ''}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>Valor Eventual</Form.ControlLabel>
                  <div style={{ display: 'flex' }}>
                    <Form.Control
                      name="valor_eventual"
                      value={camposInput.valor_eventual || ''}
                    />
                    <Form.Control
                      name="sinal_valor_eventual"
                      accepter={SelectPicker}
                      searchable={false}
                      cleanable={false}
                      value={camposInput.sinal_valor_eventual}
                      data={[
                        { value: 1, label: '+' },
                        { value: -1, label: '-' },
                      ]}
                      style={{ width: 'auto' }}
                    />
                  </div>
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>Tarifa Dinâmica %</Form.ControlLabel>
                  <div style={{ display: 'flex', width: '50%' }}>
                    <Form.Control
                      name="tarifa_dinamica"
                      value={camposInput.tarifa_dinamica || ''}
                    />
                  </div>
                </Form.Group>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Form.Group>
                  <Form.ControlLabel>Hora Início</Form.ControlLabel>
                  <div style={{ display: 'flex', width: '50%' }}>
                    <Form.Control
                      name="hora_inicio"
                      value={camposInput.hora_inicio}
                    />
                  </div>
                </Form.Group>
                <Form.Group>
                  <Form.ControlLabel>Hora Fim</Form.ControlLabel>
                  <div style={{ display: 'flex', width: '50%' }}>
                    <Form.Control
                      name="hora_fim"
                      value={camposInput.hora_fim}
                    />
                  </div>
                </Form.Group>
                </div>
                <Form.Group>
                  <Form.ControlLabel>Endereço</Form.ControlLabel>
                    <SelectPicker
                      appearance="default"
                      searchable={false}
                      placeholder={camposInput.endereco}
                      data={enderecoRegistro.map((end) => ({
                        label: end.enderecoFormatado,
                        value: end.id,
                      }))}
                      // value={camposInput.endereco1.id}
                      onChange={(value) => {
                        const endereco = enderecoRegistro.filter((end) => end.id === value)
                        setEnderecoSelecionado(endereco)
                      }
                      }
                    />
                </Form.Group>
              </>
            )}
          {camposInput.status && camposInput.status === 'Finalizado' && (
            <>
              <Form.Group>
                <Form.ControlLabel>
                  Nota dada pelo Estabelecimento
                </Form.ControlLabel>
                <Form.Control
                  name="avaliacao_prof"
                  type="number"
                  value={camposInput.avaliacao_prof || ''}
                />
              </Form.Group>
              <Form.Group>
                <Form.ControlLabel>
                  Nota dada pelo Profissional
                </Form.ControlLabel>
                <Form.Control
                  name="avaliacao_est"
                  type="number"
                  value={camposInput.avaliacao_est || ''}
                />
              </Form.Group>
              <Form.Group>
                <Form.ControlLabel>Feedback do Profissional</Form.ControlLabel>
                <Form.Control
                  name="feedback_prof"
                  rows={2}
                  componentClass="textarea"
                  value={camposInput.feedback_prof || ''}
                />
              </Form.Group>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onFechar} appearance="subtle">
            Cancelar
          </Button>
          <ButtonFullBlue type="submit" appearance="primary">
            Salvar
          </ButtonFullBlue>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

EditarRegistroDialog.propTypes = {
  exibir: PropTypes.bool.isRequired,
  onFechar: PropTypes.func.isRequired,
  onRegistroEditado: PropTypes.func.isRequired,
  registro: PropTypes.object.isRequired,
  profissoes: PropTypes.array.isRequired,
  dadosEstabelecimento: PropTypes.object.isRequired,

};

export default EditarRegistroDialog;
