/* eslint-disable prettier/prettier */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Table } from 'rsuite';

import PropTypes from 'prop-types';
import theme from '../../../styles/theme';

const { Cell } = Table;

export const fundo = (data) => {
  let cor;
  if (data.status === 'Pendente') {
    cor = theme.yellowColor;
  } else if (data.status === 'Pago') {
    cor = theme.darkGreen;
  } else if (data.status === 'Cancelado') {
    cor = theme.lightRed;
  } else if (data.status === 'Parcialmente Pago') {
    cor = theme.lightGreen;
  } else if (data.status === 'Devolvido') {
    cor = theme.darkRed;
  } else if (data.status === 'Expirado') {
    cor = theme.darkGray;
  } else if (data.status === 'Autorizado') {
    cor = theme.primaryColor;
  }
  return cor;
};

export const texto = (data) => {
  let cor;
  if (data.status === 'Confirmado' && data.checkin === 'Realizado') {
    cor = '#fafafa';
  }
  if (data.status === 'Cancelado') {
    cor = '#fafafa';
  } else if (data.status === 'Finalizado') {
    cor = '#fafafa';
  }
  return cor;
};
export const CelulaFormatada = ({ rowData, dataKey, ...props }) => (
  <Cell
    {...props}
    style={{
      background: fundo(rowData),
      color: '#fff', // texto(rowData),
    }}
  >
    {rowData[dataKey]}
  </Cell>
);

CelulaFormatada.propTypes = {
  rowData: PropTypes.object,
  dataKey: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

CelulaFormatada.defaultProps = {
  rowData: {},
  onChange: () => { },
};
