import styled from 'styled-components';
import theme from '../../../../styles/theme';

export const Conta = styled.div`
  display: flex;
  margin: 10px;
  padding: 10px;
  border: ${(props) =>
    props.selecionado ? `1px solid ${theme.primaryColor}` : '1px solid #eee'};
  border-radius: ${(props) => (props.selecionado ? '5px' : 'none')};
  justify-content: space-between;
  span {
    font-size: 18px;
    margin: 5px;
  }

  h5 {
    color: black;
  }

  p {
    font-size: 16px;
    margin: 5px;
  }
`;
