/* eslint-disable react/forbid-prop-types */
import React, { useState, useContext} from 'react'
import { DarkModeContext } from '~/common/contexts/darkMode';
import { useSelector } from'react-redux';
import { Button, Modal} from 'rsuite';
import api from '~/services/api';
import { ButtonBorderBlue } from '~/components/Buttons/ButtonBorderBlue/styles';
import { ButtonFullBlue } from '~/components/Buttons/ButtonFullBlue/styles';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

const ModalNotificacaoEmergencia = ({atributoSelecionado, textodigitado, estadoSelecionado, experienciaSelecionada, treinamentoSelecionado}) => {
    
    const [exibir, setExibir] = useState(false);
    const { toggleMode } = useContext(DarkModeContext);
    const [carregando, setCarregando] = useState(false)
    const loggeduser = useSelector((state) => state.setUser.data);
    
    async function enviarNotificacao() {

        setCarregando(true);

        try {
            const enviarNot = await api.post('/notificacao/disponiveis-emergencia', {
                [atributoSelecionado]: textodigitado,
                estado: estadoSelecionado,
                experiencias: experienciaSelecionada,
                treinamentos: treinamentoSelecionado,
                enviadoPor: loggeduser.nome
            })
            toast.success(enviarNot.data.message)

            setExibir(false)
        } catch (error) {
            console.log(error)
            toast.error(error.message);
            setExibir(false)
        }


        setCarregando(false);
    }
  
  
    return (
    <>
    <ButtonBorderBlue appearance="ghost" onClick={() => setExibir(true)} darkMode={toggleMode}>
        Enviar Notificação
    </ButtonBorderBlue>
    <Modal open={exibir} onClose={() => setExibir(false)} size="sm">
        <Modal.Header>
            <Modal.Title>Enviar Notificação</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h5>Enviar notificação para todos os profissionais disponíveis pra emergência com os filtros atuais?</h5>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={() => setExibir(false)} appearance="subtle">
                Cancelar
            </Button>
            <ButtonFullBlue
                appearance="primary"
                loading={carregando}
                onClick={() => enviarNotificacao()}
            >
                Enviar
            </ButtonFullBlue>
        </Modal.Footer>
    </Modal>
</>
  )
}

ModalNotificacaoEmergencia.propTypes = {    
    atributoSelecionado: PropTypes.string.isRequired,
    textodigitado: PropTypes.string.isRequired,
    estadoSelecionado: PropTypes.string.isRequired,
    experienciaSelecionada: PropTypes.array.isRequired,
    treinamentoSelecionado: PropTypes.array.isRequired
};


export default ModalNotificacaoEmergencia
