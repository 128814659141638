/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { Row, TagPicker, Form } from 'rsuite';
import PropTypes from 'prop-types';

import api from '~/services/api';

function AdicionarTreinamentos({ setTreinamentosProf, treinamentosProf }) {
  const [treinamentos, setTreinamentos] = useState([{}]);

  useEffect(() => {
    api.get('treinamento').then((response) => {
      setTreinamentos(response.data);
    });
  }, []);

  return (
    <Row>
      <Form.Control
        block
        size="lg"
        formValue={(_, itens) => {
          return <h5>{itens.label}</h5>;
        }}
        style={{ padding: '10px' }}
        name="profissoes"
        value={treinamentosProf}
        accepter={TagPicker}
        placeholder="Selecione"
        placement="topStart"
        onChange={(value) => setTreinamentosProf(value)}
        data={treinamentos.map((t) => {
          return {
            label: t.treinamento,
            value: t.id,
          };
        })}
      />
    </Row>
  );
}

AdicionarTreinamentos.propTypes = {
  setTreinamentosProf: PropTypes.func.isRequired,
  treinamentosProf: PropTypes.array.isRequired,
};

export default AdicionarTreinamentos;
