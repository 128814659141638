/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Button, Schema } from 'rsuite';
import { useDispatch } from 'react-redux';
import { importarProfissionaisAceitos } from '~/store/modulos/pedido/actions';

const { StringType } = Schema.Types;

const modelo = Schema.Model({
  numero_registro: StringType().isRequired('Profissional Obrigatório'),
});

function ImportarCandidatosDialog({ exibir, registro, onFechar }) {
  const dispatch = useDispatch();
  const [camposInput, setCamposInput] = useState({});

  function editarRegistro() {
    dispatch(
      importarProfissionaisAceitos({
        registro,
        numero_registro: camposInput.numero_registro,
      })
    );
    onFechar();
  }

  return (
    <Modal open={exibir} onClose={onFechar} size="xs">
      <Form
        fluid
        model={modelo}
        formValue={camposInput}
        onChange={(valor) => setCamposInput(valor)}
        onSubmit={(erro) => erro && editarRegistro()}
      >
        <Modal.Header>
          <Modal.Title>Importar Candidatos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.ControlLabel>Número Registro</Form.ControlLabel>
            <Form.Control
              name="numero_registro"
              value={camposInput.numero_registro || ''}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onFechar} appearance="subtle">
            Cancelar
          </Button>
          <Button type="submit" appearance="primary">
            Salvar
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

ImportarCandidatosDialog.propTypes = {
  exibir: PropTypes.bool.isRequired,
  onFechar: PropTypes.func.isRequired,
  registro: PropTypes.object.isRequired,
};

export default ImportarCandidatosDialog;
