/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Plus, RemindFill, Search } from '@rsuite/icons';

import {
  DatePicker,
  Table,
  Input,
  InputGroup,
  Button,
  FlexboxGrid,
  Modal,
  Form,
  IconButton,
  Schema,
  SelectPicker,
} from 'rsuite';
import { useDebouncedCallback } from 'use-debounce/lib';
import { toast } from 'react-toastify';

import { Container } from '~/styles/tabela';

import api from '~/services/api';
import {
  CelulaEditavel,
  CelulaAcao,
} from '~/components/Celulas/components/CelulaEditavel';
import { TabelaSemPaginacao } from '~/components/TabelaSemPaginacao';
import moment from 'moment';
import { ButtonFullBlue } from '../../../components/Buttons/ButtonFullBlue/styles';

const { HeaderCell, Column, Cell } = Table;

const { StringType, NumberType } = Schema.Types;

const modelo = Schema.Model({
  habilidade: StringType().isRequired('Habilidade Obrigatório'),
  id_profissao: NumberType().isRequired('ID Serviço Obrigatório'),
});

function Habilidades() {
  const [dados, setDados] = useState({
    listaInicial: [],
    feriados: [],
    feriadosLista: [],
  });
  const [apagarFeriadoDialog, setApagarFeriadoDialog] = useState({
    exibir: false,
    feriado: {},
  });
  const [carregando, setCarregando] = useState(true);
  const [adicionarHabilidadeDialog, setHabilidadeDialog] = useState(false);
  const [camposInput, setCamposInput] = useState({});
  const [anoSelecionado, setAnoSelecionado] = useState(
    moment(new Date()).format('YYYY')
  );
  const [atributoSelecionado, setAtributoSelecionado] = useState();
  const [filtroData, setFiltroData] = useState(new Date());
  const year = [
    {
      label: '2020',
      value: '2020',
    },
    {
      label: '2021',
      value: '2021',
    },
    {
      label: '2022',
      value: '2022',
    },
    {
      label: '2023',
      value: '2023',
    },
    {
      label: '2024',
      value: '2024',
    },
  ];
  const range = [
    {
      label: 'Hoje',
      value: new Date(),
    },
  ];
  const locale = {
    sunday: 'Dom',
    monday: 'Seg',
    tuesday: 'Ter',
    wednesday: 'Qua',
    thursday: 'Qui',
    friday: 'Sex',
    saturday: 'Sab',
    ok: 'Ok',
    today: 'Hoje',
    yesterday: 'Ontem',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos',
  };

  async function buscarFeriados() {
    try {
      const feriado = await api.get('tarifa/feriado', {
        params: {
          ano: anoSelecionado,
        },
      });
      const feriado2 = await api.get('tarifa/feriado', {
        params: {
          ano: anoSelecionado,
        },
      });

      setDados({
        listaInicial: feriado2.data,
        feriados: feriado.data,
        feriadosLista: feriado.data,
      });
      setCarregando(false);
    } catch (e) {
      setCarregando(false);
      toast.error(e.message);
    }
  }

  useEffect(() => {
    buscarFeriados();
  }, [anoSelecionado]);

  function filtrarLista(texto) {
    setDados({
      ...dados,
      feriadosLista: dados.feriados.filter((feriado) =>
        feriado.nome
          .toString()
          .toUpperCase()
          .includes(texto ? texto.toUpperCase() : '')
      ),
      filtro: texto,
    });
  }


  function abrirApagarFeriadoDialog(feriado) {
    setApagarFeriadoDialog({
      exibir: true,
      feriado,
    });
  }

  function fecharApagarFeriadoDialog() {
    setApagarFeriadoDialog({
      exibir: false,
      feriado: {},
    });
  }

  async function excluirFeriado() {
    try {
      await api.delete(`/tarifa/feriado/${apagarFeriadoDialog.feriado.id}`);
      toast.success('Feriado excluido com sucesso');
      fecharApagarFeriadoDialog();
      buscarFeriados();
    } catch (e) {
      toast.error(e.message);
    }
  }

  async function adicionarHabilidade() {
    try {
      setCarregando(true);
      camposInput.data = filtroData;
      await api.post(`/tarifa/feriado`, camposInput);
      toast.success('Inclusão de Feriado realizada com sucesso');
      setCarregando(false);
      setHabilidadeDialog(false);
      buscarFeriados();
    } catch (e) {
      toast.error('Erro ao cadastrar um novo feriado.');
      toast.error(e.message);
      setCarregando(false);
    }
  }

  function alterarTextoCelula(id, key, value) {
    const novaLista = Object.assign([], dados.feriadosLista);
    novaLista.find((item) => item.id === id)[key] = value;
    setDados({ ...dados, feriadosLista: novaLista });
    filtrarLista(dados.filtro);
  }

  async function alterarEstadoCelula(id) {
    const novaLista = Object.assign([], dados.feriadosLista);
    const item = novaLista.find((habilidade) => habilidade.id === id);
    const itemInicial = dados.listaInicial.find(
      (habilidade) => habilidade.id === id
    );

    if (
      item.status === 'EDIT' &&
      (item.nome !== itemInicial.nome ||
        item.tarifa !== itemInicial.tarifa ||
        item.repasse !== itemInicial.repasse)
    ) {
      try {
        setCarregando(true);
        await api.put(`tarifa/feriado`, item);
        toast.success('Tarifa atualizada com sucesso');
        buscarFeriados();
      } catch (error) {
        toast.error(error.message);
        setCarregando(false);
      }
      setCarregando(false);
    } else {
      item.status = item.status ? null : 'EDIT';
      setDados({ ...dados, feriadosLista: novaLista });
      filtrarLista();
    }
  }

  return (
    <Container>
      <h2>Tarifas Feriados</h2>
      <FlexboxGrid justify="start" style={{ gap: '10px' }}>
        <InputGroup style={{ width: '300px' }}>
          <Input
            placeholder="Pesquisar"
            width={200}
            onChange={filtrarLista}
          />
          <InputGroup.Button>
            <Search />
          </InputGroup.Button>
        </InputGroup>
        <SelectPicker
          appearance="default"
          searchable={false}
          placeholder="Ano"
          data={year}
          value={anoSelecionado}
          // onChange={setAnoSelecionado}
          onChange={(value) => setAnoSelecionado(value)}
        />

        <IconButton
          style={{ marginTop: '0' }}
          icon={<Plus />}
          onClick={() => {
            setHabilidadeDialog(true);
          }}
        />
      </FlexboxGrid>
      <FlexboxGrid style={{ width: '100%' }}>
        <TabelaSemPaginacao carregando={carregando} dados={dados.feriadosLista}>
          <Column resizable width={120} sortable>
            <HeaderCell>Data</HeaderCell>
            <Cell dataKey="data" />
          </Column>

          <Column resizable width={200} sortable>
            <HeaderCell>Feriado</HeaderCell>
            <CelulaEditavel dataKey="nome" onChange={alterarTextoCelula} />
          </Column>

          <Column resizable width={100} sortable>
            <HeaderCell>Tarifa</HeaderCell>
            <CelulaEditavel dataKey="tarifa" onChange={alterarTextoCelula} />
          </Column>

          <Column resizable width={100} sortable>
            <HeaderCell>Repasse</HeaderCell>
            <CelulaEditavel dataKey="repasse" onChange={alterarTextoCelula} />
          </Column>

          <Column resizable width={100}>
            <HeaderCell>Editar</HeaderCell>
            <CelulaAcao
              dataKey="id"
              onClick={alterarEstadoCelula}
              onDelete={abrirApagarFeriadoDialog}
            />
          </Column>
        </TabelaSemPaginacao>
      </FlexboxGrid>

      <Modal
        backdrop="static"
        open={adicionarHabilidadeDialog}
        onClose={() => setHabilidadeDialog(false)}
        size="xs"
      >
        <Form
          model={modelo}
          onChange={setCamposInput}
          onSubmit={adicionarHabilidade}
        >
          <Modal.Header>
            <h3>Adicionar Feriado</h3>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.ControlLabel>Data</Form.ControlLabel>
              <DatePicker
                placeholder="DD/MM/AA"
                name="data"
                format="dd/MM/yy"
                cleanable={false}
                accepter={DatePicker}
                oneTap
                locale={locale}
                value={filtroData}
                ranges={range}
                onChange={setFiltroData}
              />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Feriado</Form.ControlLabel>
              <Form.Control name="nome" />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Tarifa</Form.ControlLabel>
              <Form.Control name="tarifa" />
            </Form.Group>
            <Form.Group>
              <Form.ControlLabel>Repasse</Form.ControlLabel>
              <Form.Control name="repasse" />
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button
              onClick={() => setHabilidadeDialog(false)}
              appearance="subtle"
            >
              Cancelar
            </Button>
            <ButtonFullBlue
              onClick={() => setHabilidadeDialog(false)}
              type="submit"
              appearance="primary"
            >
              Adicionar
            </ButtonFullBlue>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        backdrop="static"
        open={apagarFeriadoDialog.exibir}
        onCLose={fecharApagarFeriadoDialog}
        size="xs"
      >
        <Modal.Header>
          <h3>Apagar Feriado</h3>
        </Modal.Header>
        <Modal.Body>
          Você realmente deseja apagar este feriado? <br /> <br />
          id: {apagarFeriadoDialog.feriado.id} <br />
          data: {apagarFeriadoDialog.feriado.data} <br />
          feriado: {apagarFeriadoDialog.feriado.nome} <br />
          Essa ação é irreversível{' '}
          <RemindFill
            style={{
              color: '#ffb300',
              fontSize: 24,
            }}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={fecharApagarFeriadoDialog} appearance="subtle">
            Cancelar
          </Button>
          <ButtonFullBlue onClick={() => excluirFeriado()} appearance="primary">
            Deletar
          </ButtonFullBlue>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default Habilidades;
