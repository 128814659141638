export const motivosCancelamentos = {
  estabelecimento: [
    {
      label: 'Meu funcionário não vai mais faltar',
      value: '1',
    },
    {
      label: 'Queda na quantidade de clientes',
      value: '2',
    },
    {
      label: 'Problemas com o profissional confirmado',
      value: '3',
    },
    {
      label: 'Encontrei um profissional por minha conta',
      value: '4',
    },
    {
      label: 'Condições climáticas',
      value: '5',
    },
    {
      label: 'Outro motivo',
      value: '6',
    },
  ],
  admin: [
    {
      label: 'Problemas com o profissional confirmado',
      value: '3',
    },
    {
      label: 'Condições climáticas',
      value: '5',
    },
    {
      label: 'Outro motivo',
      value: '6',
    },
  ],

  profissional: [
    {
      label: 'Problemas com o profissional confirmado',
      value: '3',
    },
    {
      label: 'Encontrei um profissional por minha conta',
      value: '4',
    },
    {
      label: 'Condições climáticas',
      value: '5',
    },
    {
      label: 'Outro motivo',
      value: '6',
    },
  ],

  faltou: [
    {
      label: 'Problemas com o profissional confirmado',
      value: '3',
    },
    {
      label: 'Encontrei um profissional por minha conta',
      value: '4',
    },
    {
      label: 'Condições climáticas',
      value: '5',
    },
    {
      label: 'Outro motivo',
      value: '6',
    },
  ],

  
};
