/* eslint-disable prettier/prettier */
/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Column, CardRow, Button1, Button2, Card } from './styles';
import theme from '../../../../../styles/theme';
import { DarkModeContext } from '../../../../../common/contexts/darkMode';

export function CardsCheckin({
  cancelados,
  faltantes,
  checkin,
  checkinR,
  checkinAF,
  setCheckin,
  titulo,
  texto_1,
  texto_2,
}) {

  const { toggleMode } = useContext(DarkModeContext)

  function clickCheckin(n) {
    return checkin === n ? setCheckin(0) : setCheckin(n);
  }

  return (
    <Card darkMode={toggleMode}>
      <Column resizable>
        <h4 style={{color: toggleMode && 'gray'}}>{titulo}</h4>
        <CardRow>
          <Column resizable>
            <p style={{color: toggleMode && 'gray'}}>{texto_1}</p>
            <Button1
              checkin={checkin}
              numberColor={toggleMode ? '#1675e0' : theme.primaryColor}
              onClick={
                titulo === 'Checkin'
                  ? () => clickCheckin('1')
                  : () => clickCheckin('3')
              }
            >
              {titulo === 'Checkin'
                ? checkinR === null
                  ? '0'
                  : checkinR
                : cancelados === null
                  ? '0'
                  : cancelados}
            </Button1>
          </Column>
          <Column resizable>
            <p style={{color: toggleMode && 'gray'}}>{texto_2}</p>
            <Button2
              checkin={checkin}
              numberColor={theme.darkGray}
              onClick={
                titulo === 'Checkin'
                  ? () => clickCheckin('2')
                  : () => clickCheckin('4')
              }
              darkMode={toggleMode}
            >
              {titulo === 'Checkin'
                ? checkinAF === null
                  ? '0'
                  : checkinAF
                : faltantes === null
                  ? '0'
                  : faltantes}
            </Button2>
          </Column>
        </CardRow>
      </Column>
    </Card>
  );
}

CardsCheckin.propTypes = {
  cancelados: PropTypes.func.isRequired,
  faltantes: PropTypes.func.isRequired,
  checkin: PropTypes.string.isRequired,
  checkinR: PropTypes.func.isRequired,
  checkinAF: PropTypes.func.isRequired,
  setCheckin: PropTypes.func.isRequired,
  titulo: PropTypes.func.isRequired,
  texto_1: PropTypes.func.isRequired,
  texto_2: PropTypes.func.isRequired,
};
