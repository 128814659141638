import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { Modal, Button } from 'rsuite';
import api from '~/services/api';
import { toast } from 'react-toastify';

export function ExcluirEscala({ setModalExcluir, modalExcluir, exluirEscala, setAtualizarLista }) {
  const [carregando, setCarregando] = useState(false);


  async function excluirEsca() {
    // const { id, estabelecimento: { nome_fantasia } } = exluirEscala
    console.log('exluirEscala')
    console.log(exluirEscala)
    if (!exluirEscala.id) throw new Error('Escala não encontrada');
    setCarregando(true)
    try {
      await api.delete(`/escala-fixa/${exluirEscala.id}`)
      setCarregando(false)
      setAtualizarLista((p) => !p)
      toast.success(`Escala excluída com sucesso!`)
      setModalExcluir(false)
    } catch (e) {
      setCarregando(false)
      console.log('erro')
      toast.error(e.message)
    }
  }

  return (
    <Modal
      open={modalExcluir}
      onClose={() => setModalExcluir(false)}
      size="xs"
    >
      <Modal.Header>Excluir Escala</Modal.Header>
      <Modal.Body>
        <p>Deseja realmente excluir esta escala?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => setModalExcluir(false)}
          appearance="subtle"
        >
          Cancelar
        </Button>
        <Button
          appearance="primary"
          type="button"
          onClick={() => {
            excluirEsca();
          }}
        >
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

ExcluirEscala.propTypes = {
  setModalExcluir: PropTypes.func,
  setAtualizarLista: PropTypes.func.isRequired,
  modalExcluir: PropTypes.bool,
  exluirEscala: PropTypes.objectOf,
}

ExcluirEscala.defaultProps = {
  exluirEscala: {},
  setModalExcluir: () => { },
  modalExcluir: false,
};
