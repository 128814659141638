/* eslint-disable no-unused-vars */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import { Table, IconButton } from 'rsuite';
import { InfoRound } from '@rsuite/icons';
import theme from '../../../../styles/theme';

const { Cell } = Table;

export const CelulaAcaoInfo = ({ rowData, onClick, toggleMode, ...props }) => (
  <Cell
    {...props}
    onClick={() => props.abreServicos(rowData)}
    style={{
      background: rowData.status === 'Pago' && `${theme.darkGreen}`,
      padding: '6px 0',
    }}
  >
    <IconButton
      appearance="link"
      style={{ color: (rowData.status === 'Pago' || toggleMode) ? `${theme.whiteColor}` : '#575757' }}
      icon={<InfoRound />}
    />
  </Cell>
);

CelulaAcaoInfo.propTypes = {
  onClick: PropTypes.func.isRequired,
  rowData: PropTypes.func.isRequired,
  abreServicos: PropTypes.func.isRequired,
  toggleMode: PropTypes.bool.isRequired,
};
