/* eslint-disable prettier/prettier */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import MinusRoundIcon from '@rsuite/icons/MinusRound';
import PlusRoundIcon from '@rsuite/icons/PlusRound';
import {
  Modal,
  Form,
  Button,
  Schema,
  SelectPicker,
  IconButton,
  AutoComplete,
} from 'rsuite';
import {
  setHours,
  setMinutes,
  format,
  differenceInMinutes,
  isBefore,
  addDays,
  parseISO,
} from 'date-fns';
import useSearchNames from '~/services/useSearchNames';
import { ButtonFullBlue } from '../../../../components/Buttons/ButtonFullBlue/styles';

const { NumberType, StringType } = Schema.Types;

function AdicionarRegistroDialog({
  exibir,
  pedidoAntigo,
  onFechar,
  onRegistrosAdicionado,
  profissoes,
  criacao
}) {
  const [profissionais, setProfissionais] = useState([]);
  const [addCamposInput, setAddCamposInput] = useState({ quantidade: 1, sinal_valor_eventual: 1, valor_eventual: 0 });
  const camposInput = useSelector((state) => state.pedido.camposInput);
  const modelo = Schema.Model({
    id_profissao: NumberType().isRequired('Serviço Obrigatório'),
    nome_profissional: pedidoAntigo
      ? StringType()
        .addRule((_, dados) => {
          if (!dados.id_profissional) return false;
          return true;
        }, 'Selecione um Profissional')
        .isRequired('Obrigatório')
      : StringType(),
  });

  function adicionarRegistro() {

    const horaInicio = camposInput.hora_inicio_texto.substr(0, 2);
    const minutosInicio = camposInput.hora_inicio_texto.substr(2, 3)
    const horaFim = camposInput.hora_fim_texto.substr(0, 2);
    const minutosFim = camposInput.hora_fim_texto.substr(2, 3)

    const campoInput = pedidoAntigo === true ? camposInput.data : camposInput.data[0]

    const dataInicio = setMinutes(setHours(campoInput, horaInicio), minutosInicio);
    let dataFim = setMinutes(setHours(campoInput, horaFim), minutosFim);

    if (isBefore(dataFim, dataInicio)) {
      dataFim = addDays(dataFim, 1);
    }

    const horarioInicioSemFuso = dataInicio.toString()
    const horarioFimSemFuso = dataFim.toString()

    const registro = { profissao: {}, profissional: {} };
    registro.quantidade = addCamposInput.quantidade;

    if(criacao){
    registro.hora_inicio = horarioInicioSemFuso
    registro.hora_fim = horarioFimSemFuso
    }

    registro.avaliacao_prof = addCamposInput.avaliacao_prof;
    registro.profissao.id = addCamposInput.id_profissao;
    registro.profissional.id = addCamposInput.id_profissional;
    registro.profissional.nome = addCamposInput.nome_profissional;
    registro.valor_eventual =
    addCamposInput.valor_eventual * addCamposInput.sinal_valor_eventual;

    const listaRegistro = [];
    for (let i = 0; i < addCamposInput.quantidade; i += 1) {
      registro.fake_id = new Date().getTime() + Math.random() * 1000
      listaRegistro.push({
        ...registro,
      });
    }


    onRegistrosAdicionado(listaRegistro);
    setAddCamposInput({
      quantidade: 1,
      sinal_valor_eventual: registro.valor_eventual >= 0 ? 1 : -1,
      valor_eventual: Math.abs(registro.valor_eventual),
    });
  }

  // useEffect(() => {
  //   setaddCamposInput({
  //     quantidade: 1,
  //     sinal_valor_eventual: registro.valor_eventual >= 0 ? 1 : -1,
  //     valor_eventual: Math.abs(registro.valor_eventual),
  //   });
  // }, [registro]);

  const buscarNomesProfissionais = useSearchNames(
    setProfissionais,
    'profissionais'
  );


  return (
    <Modal
      open={exibir}
      onClose={() => {
        onFechar();
        setAddCamposInput({ quantidade: 1, sinal_valor_eventual: 1, valor_eventual: 0 });
      }}
      size="xs"
    >
      <Form
        fluid
        model={modelo}
        formValue={addCamposInput}
        onChange={(valor) => setAddCamposInput(valor)}
        onSubmit={(erro) => erro && adicionarRegistro()}
      >
        <Modal.Header>
          <Modal.Title>Adicionar Registro</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.ControlLabel>Serviço</Form.ControlLabel>
            <Form.Control
              name="id_profissao"
              accepter={SelectPicker}
              placeholder="Selecione"
              value={addCamposInput.id_profissao}
              data={profissoes.map((p) => {
                return {
                  label: p.profissao,
                  value: p.id,
                };
              })}
            />
          </Form.Group>
          {pedidoAntigo && (
            <>
              <Form.Group>
                <Form.ControlLabel>Nome do Profissional</Form.ControlLabel>
                <Form.Control
                  name="nome_profissional"
                  accepter={AutoComplete}
                  data={profissionais.map((p) => ({
                    label: p.nome,
                    value: p.nome,
                    id: p.id,
                  }))}
                  onSelect={(value, item) => {
                    addCamposInput.id_profissional = item.id;
                  }}
                  onEntered={() => {
                    addCamposInput.id_profissional = null;
                  }}
                  value={addCamposInput.nome_profissional}
                  onChange={buscarNomesProfissionais}
                />
              </Form.Group>
              <Form.Group>
                <Form.ControlLabel>Nota dada pelo Cliente</Form.ControlLabel>
                <Form.Control
                  name="avaliacao_prof"
                  type="number"
                  value={addCamposInput.avaliacao_prof || ''}
                />
              </Form.Group>
            </>
          )}
          {!pedidoAntigo && (
            <>
              <Form.Group style={{ width: '250px' }}>
                <Form.ControlLabel>Quantidade</Form.ControlLabel>
                <div style={{ display: 'flex' }}>
                  <IconButton
                    style={{ marginTop: '0' }}
                    icon={<MinusRoundIcon />}
                    onClick={() => {
                      setAddCamposInput({
                        ...addCamposInput,
                        quantidade: addCamposInput.quantidade - 1,
                      });
                    }}
                  />
                  <Form.Control
                    name="quantidade"
                    readOnly
                    value={addCamposInput.quantidade}
                    onChange={(valor) =>
                      setAddCamposInput({ ...addCamposInput, quantidade: valor })
                    }
                  />
                  <IconButton
                    style={{ marginTop: '0' }}
                    icon={<PlusRoundIcon />}
                    onClick={() => {
                      setAddCamposInput({
                        ...addCamposInput,
                        quantidade: addCamposInput.quantidade + 1,
                      });
                    }}
                  />
                </div>
              </Form.Group>
              <Form.Group>
                <Form.ControlLabel>Valor Eventual</Form.ControlLabel>
                <div style={{ display: 'flex' }}>
                  <Form.Control
                    name="valor_eventual"
                    value={addCamposInput.valor_eventual || 0}
                  />
                  <Form.Control
                    name="sinal_valor_eventual"
                    accepter={SelectPicker}
                    searchable={false}
                    cleanable={false}
                    placeholder="+"
                    value={addCamposInput.sinal_valor_eventual}
                    data={[
                      { value: 1, label: '+' },
                      { value: -1, label: '-' },
                    ]}
                    style={{ width: 'auto' }}
                  />
                </div>
              </Form.Group>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              onFechar();
              setAddCamposInput({ quantidade: 1 });
            }}
            appearance="subtle"
          >
            Cancelar
          </Button>
          <ButtonFullBlue type="submit" appearance="primary">
            Salvar
          </ButtonFullBlue>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

AdicionarRegistroDialog.propTypes = {
  exibir: PropTypes.bool.isRequired,
  pedidoAntigo: PropTypes.bool,
  onFechar: PropTypes.func.isRequired,
  onRegistrosAdicionado: PropTypes.func.isRequired,
  profissoes: PropTypes.array.isRequired,
  // eslint-disable-next-line react/require-default-props
  criacao: PropTypes.bool
};

AdicionarRegistroDialog.defaultProps = {
  pedidoAntigo: false,
};

export default AdicionarRegistroDialog;
