/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Button } from 'rsuite';
import { toast } from 'react-toastify';
import api from '~/services/api';
import { ButtonFullBlue } from '~/components/Buttons/ButtonFullBlue/styles';

function AdicionarIconeDialog({ exibir, profissao, onFechar }) {
  const [icone, setIcone] = useState();
  const [carregando, setCarregando] = useState(false);

  async function salvarIcone() {
    if (!icone) {
      toast.error('Icone é obrigatório');
      return;
    }
    setCarregando(true);
    try {
      const formData = new FormData();
      formData.append('icone', icone);
      await api.put(`/profissao/${profissao.id}/icone`, formData);
      toast.success('Icone salvo com sucesso!');
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
    onFechar();
  }

  return (
    <Modal backdrop="static" open={exibir} onClose={onFechar} size="xs">
      <Form onSubmit={(status) => status && salvarIcone()}>
        <Modal.Header>
          <h3>Adicionar Icone</h3>
        </Modal.Header>
        <Modal.Body>
          {profissao.icone && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h6>Icone atual: </h6>
              <img src={profissao.icone.caminho} height={100} alt="Icone" />
            </div>
          )}
          <Form.Group>
            <Form.ControlLabel>Serviço</Form.ControlLabel>
            <Form.Control readOnly value={profissao.profissao || ''} />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>Icone</Form.ControlLabel>
            <Form.Control
              name="icone"
              type="file"
              accept="image/*"
              onChange={(_, evento) => {
                setIcone(evento.target.files[0]);
              }}
            />
          </Form.Group>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={onFechar} appearance="subtle">
            Cancelar
          </Button>
          <ButtonFullBlue
            type="submit"
            loading={carregando}
            appearance="primary"
          >
            Adicionar
          </ButtonFullBlue>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

AdicionarIconeDialog.propTypes = {
  exibir: PropTypes.bool.isRequired,
  onFechar: PropTypes.func.isRequired,
  profissao: PropTypes.object.isRequired,
};

export default AdicionarIconeDialog;
