/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Grid, FlexboxGrid, Form, Schema, Loader, Panel, Drawer, Row, Col } from 'rsuite';
import api from '~/services/api';
import PropTypes from 'prop-types';
import { ButtonFullBlue } from '../../../components/Buttons/ButtonFullBlue/styles';

import ListaExperiencias from '../components/ListaExperiencias';
import ListaHabilidades from '../components/ListaHabilidades';
import CamposDadosProfissionalEdicao from '../components/CamposDadosProfissionalEdicao';
import CamposEndereco from '../components/CamposEndereco';
import { Container } from './styles';

import ListaDadosBancarios from '../components/ListaDadosBancarios';
import AdicionarTreinamentos from '../components/AdicionarTreinamentos';
import AdicionarInteresse from '../components/AdicionarInteresse';
import ListaCertificados from '../components/ListaCertificados';
import ListaCurriculos from '../components/ListaCurriculos';
import CampoObservacoesEComentarios from '../components/CampoObservacoesEComentarios';

const { StringType, NumberType, ArrayType } = Schema.Types;

const modelo = Schema.Model({
  nome: StringType().isRequired('Nome obrigatório'),
  email: StringType().isEmail('Email inválido').isRequired('Email obrigatório'),
  cpf_cnpj: StringType().isRequired('Cpf obrigatório'),
  data_nascimento: StringType().isRequired('Data obrigatória'),
  telefone1: StringType()
    .minLength(14, 'Telefone inválido')
    .isRequired('Telefone1 obrigatório'),
  telefone2: StringType().minLength(14, 'Telefone inválido'),
  // foto_perfil: StringType().isRequired('Foto perfil obrigatória'),
  logradouro: StringType().isRequired('Logradouro obrigatório'),
  cep: StringType().isRequired('Cep obrigatorio'),
  numero: NumberType('Número inválido')
    .min(1, 'Número inválido')
    .isRequired('Numero obrigatório'),
  complemento: StringType(),
  bairro: StringType().isRequired('Bairro obrigatório'),
  cidade: StringType().isRequired('Cidade obrigatório'),
  estado: StringType().isRequired('Estado obrigatório'),
  uf: StringType().isRequired('Uf obrigatório'),
  escolaridade: NumberType().isRequired('Escolaridade obrigatória'),
  tipo_estabelecimento: NumberType().isRequired('Preferência obrigatória'),
  idiomas: ArrayType().of(NumberType()),
  obs: StringType(),
});

function EditarProfissional({
  idProfissional,
  showEditarProfissional,
  setShowEditarProfissional,
  atualizarLista,
}) {
  const [carregando, setCarregando] = useState(false);
  const [treinamentos, setTreinamentos] = useState([]);
  const [interesses, setInteresses] = useState()
  const [experiencias, setExperiencias] = useState([]);
  const [dadosBancarios, setDadosBancarios] = useState([]);
  const [camposInput, setCamposInput] = useState({});
  const [excluirPro, setExcluirPro] = useState(false);
  const [documento, setDocumento] = useState();
  const [imagem, setImagem] = useState();
  let erro = {};

  async function buscarProfissional() {
    setCarregando(true);
    try {
      const resposta = await api.get(`profissional/${idProfissional}`);
      console.log(resposta.data)
      const campos = resposta.data;
      console.log(campos.descobriu_switch)

      const dataNascimento = new Date(campos.data_nascimento);
      campos.data_nascimento = dataNascimento.toLocaleDateString('pt-BR');
      campos.logradouro = campos.endereco.logradouro;
      campos.cep = campos.endereco.cep;
      campos.numero = campos.endereco.numero;
      campos.complemento = campos.endereco.complemento || '';
      campos.bairro = campos.endereco.bairro.bairro;
      campos.cidade = campos.endereco.bairro.cidade.cidade;
      campos.estado = campos.endereco.bairro.cidade.estado.estado;
      campos.uf = campos.endereco.bairro.cidade.estado.uf;
      campos.escolaridade = campos.escolaridade
        ? campos.escolaridade.id
        : undefined;
      campos.idiomas = campos.idiomas.map((i) => i.id);
      campos.uniformes = campos.uniformes.map((i) => i.id);
      campos.tipo_estabelecimento = campos.tipo_estabelecimento[0].id;
      if (campos.descobriu_switch.length > 0) {
        campos.descobriu_switch_id = campos.descobriu_switch[0].id;
      } else {
        campos.descobriu_switch = [];
      }
      if (campos.descobriu_switch.length > 0) {
        campos.obs_outro = campos.descobriu_switch[0].profissionais_descobriu_switch.obs_outro;
      } else {
        campos.obs_outro = [];
      }
      if (campos.genero.length > 0) {
        campos.genero = campos.genero[0].id;
      } else {
        campos.genero = [];
      }
      campos.status = campos.status === 'ativo'
      const dados_bancarios = campos.dados_bancarios.map((dados) => {
        return {
          ...dados,
          selecionado: campos.conta && campos.conta.id === dados.id,
        };
      });
      campos.treinamentos = campos.treinamentos.map((i) => i.id);
      campos.curriculo = campos.curriculo[0];
      const [experienciasResposta, habilidadesResposta] = await Promise.all([
        api.get(`profissional/experiencias/${idProfissional}`),
        api.get(`profissional/habilidades/${idProfissional}`),
      ]);

      campos.experiencias = experienciasResposta.data.experiencias;
      campos.habilidades = habilidadesResposta.data.habilidades;
      campos.experiencias.forEach((e) => {
        const habilidades = campos.habilidades.filter(
          (h) => h.profissao.id === e.profissao.id
        );
        campos[e.profissao.id] = habilidades.map((h) => h.id);
      });
      setCamposInput(campos);
      setDadosBancarios(dados_bancarios);
      setExperiencias(campos.experiencias);
      setTreinamentos(campos.treinamentos);
      setInteresses(campos.interesses);
    } catch (_) {
      toast.error('Erro ao carregar dados do profissional');
    }
    setCarregando(false);
  }

  useEffect(() => {
    setTreinamentos([]);
    setExperiencias([]);
    setDadosBancarios([]);
    setCamposInput({});
    setDocumento();
    setImagem()
    return idProfissional && buscarProfissional();
  }, [showEditarProfissional]);

  // function adicionarVerificaoHabilidades(experienciaLista) {
  //   experiencias.forEach((e) => {
  //     delete modelo[e.profissao.id];
  //   });
  //   experienciaLista
  //     .map((exp) => exp.profissao)
  //     .filter((p) => p.profissao !== 'Outros')
  //     .forEach((p) => {
  //       modelo[p.id] = ArrayType()
  //         .of(NumberType())
  //         .isRequired(`Habilidades para ${p.profissao} Obrigatório`);
  //     });
  //   setExperiencias([...experienciaLista]);
  // }

  function exibirMensagemErro() {
    Object.values(erro).forEach((e) => {
      toast.error(e);
    });
  }

  async function buscarDadosBancarios() {
    try {
      const dadosBancariosApi = await api.get(
        `/dados-bancarios/${idProfissional}`
      );
      const contaApi = await api.get(
        `/dados-bancarios/profissional/${idProfissional}`
      );
      setDadosBancarios(
        dadosBancariosApi.data.map((dados) => ({
          ...dados,
          selecionado: contaApi.data && contaApi.data.id === dados.id,
        }))
      );
    } catch (e) {
      toast.error(e.message);
    }
  }

  async function salvarUsuario() {
    const profissoesIdLista = experiencias.map((e) => e.profissao.id);
    setCarregando(true);
    try {
      if (documento) {
        const formData = new FormData();
        formData.append('documento', documento);
        await api.post(`profissional/documento/${idProfissional}`, formData);
      }
      if (imagem) {
        const formData = new FormData();
        formData.append('foto', imagem);
        await api.post(`profissional/foto/${idProfissional}`, formData);
      }

      const [dia, mes, ano] = camposInput.data_nascimento.split('/');
      // let habilidadesPreenchidas = true;

      const body = {
        nome: camposInput.nome,
        email: camposInput.email,
        cpf_cnpj: camposInput.cpf_cnpj,
        data_nascimento: new Date(ano, mes - 1, dia).toISOString(),
        telefone1: camposInput.telefone1,
        telefone2: camposInput.telefone2,
        perfil_bloqueado: camposInput.perfil_bloqueado,
        perfil_analisado: camposInput.perfil_analisado,
        perfil_bom: camposInput.perfil_bom,
        status: camposInput.status,
        pix: camposInput.pix,
        descobriu_switch: camposInput.descobriu_switch_id,
        genero: camposInput.genero,
        obs_outro: camposInput.obs_outro,
        endereco: {
          id: camposInput.endereco.id,
          logradouro: camposInput.logradouro,
          cep: camposInput.cep,
          numero: camposInput.numero,
          complemento: camposInput.complemento,
          bairro: {
            id: camposInput.endereco.bairro.id,
            nome: camposInput.bairro,
            cidade: {
              id: camposInput.endereco.bairro.cidade.id,
              nome: camposInput.cidade,
              estado: {
                id: camposInput.endereco.bairro.cidade.estado.id,
                uf: camposInput.uf,
                nome: camposInput.estado,
              },
            },
          },
        },
        escolaridade: camposInput.escolaridade,
        interesses,
        experiencias: experiencias.map((e) => ({
          id: e.id,
          estabelecimento: e.estabelecimento,
          id_profissao: e.profissao.id,
          id_tempo_experiencia: e.tempo_experiencia.id,
        })),
        tipo_estabelecimento: [camposInput.tipo_estabelecimento],
        idiomas: camposInput.idiomas,
        uniformes: camposInput.uniformes,
        // habilidades: profissoesIdLista
        //   .filter((p) => p !== 'Outros')
        //   .map((p) => {
        //     if (camposInput[p]) {
        //       return camposInput[p];
        //     }
        //     toast.error('Faltam habilidades');
        //     habilidadesPreenchidas = false;
        //     return [];
        //   })
        //   .reduce((anterior, atual) => [...anterior, ...atual], []),
        // treinamentos,
        obs: camposInput.obs,
      };
      // if (habilidadesPreenchidas) {
        await api.put(`profissional/${idProfissional}`, body);
        toast.success('Profissional atualizado com sucesso');
        profissoesIdLista.forEach((id) => {
          delete modelo[id];
        });
      // }
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
    atualizarLista();
  }

  async function recuperarSenha() {
    setCarregando(true);
    try {
      await api.post(
        `/recuperar-senha/profissional?email=${camposInput.email}`
      );
      toast.success('Email enviado com sucesso');
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
  }

  async function novaSenha({ password, passwordConfirmation }) {
    setCarregando(true);
    try {
      const newPassword = {
        id: camposInput.id,
        password,
        passwordConfirmation,
      }
      await api.put(`/new-password/profissional/adm`, newPassword);
      toast.success('Senha alterada com sucesso');
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
  }

  async function enviarConviteIugu() {
    setCarregando(true);
    try {
      await api.post(`/iugu/convite/${idProfissional}`);
      toast.success('Email enviado com sucesso');
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
  }

  function test(){
    console.log(camposInput)
  }

  return (
    <Container>
      <Drawer
        full
        open={showEditarProfissional}
        onClose={() => setShowEditarProfissional(false)}
      >
        <Drawer.Header>
          <Drawer.Title>Editar Profissional</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <Form
            model={modelo}
            onChange={setCamposInput}
            formValue={camposInput}
            onSubmit={(semErro) =>
              semErro ? salvarUsuario() : exibirMensagemErro()
            }
            onError={(err) => {
              erro = err;
            }}
            layout="horizontal"
            id="editarProfissional"
          >
            <Grid fluid>
              <CamposDadosProfissionalEdicao
                editar
                setCamposInput={setCamposInput}
                camposInput={camposInput}
                documentoAlterado={setDocumento}
                imagemAlterada={setImagem}
                recuperarSenha={recuperarSenha}
                novaSenha={novaSenha}
                enviarConviteIugu={enviarConviteIugu}
                // excluirPro={atualizarLista}
                excluirPro={setShowEditarProfissional}
              />
              <Row>
                <Col md={16} sm={24} xs={24}>
                  <CamposEndereco
                    camposInput={camposInput}
                    atualizarCampos={(camposEndereco) => {
                      setCamposInput({ ...camposInput, ...camposEndereco });
                    }}
                  />
                </Col>
                <Col md={8} sm={24} xs={24}>
                  <CampoObservacoesEComentarios
                    camposInput={camposInput}
                    atualizarCampos={(camposObs) => {
                      setCamposInput({ ...camposInput, ...camposObs });
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={8} sm={24} xs={24}>

                  <ListaExperiencias
                    experiencias={experiencias}
                    // onListaAlterada={adicionarVerificaoHabilidades}
                    setExperiencias={setExperiencias}
                  >
                    {/* <Panel header={<h4>Habilidades</h4>} bordered collapsible>
                      <ListaHabilidades experiencias={experiencias} />
                    </Panel> */}
                  </ListaExperiencias>
                </Col>
                <Col md={8} sm={24} xs={24}>
                  <ListaCertificados
                    // setTreinamentos={setTreinamentos}
                    certificados={camposInput.certificados}
                    onListaAlterada={() => {
                      buscarProfissional();
                      atualizarLista();
                    }}
                    idProfissional={idProfissional}
                  />
                  <AdicionarInteresse
                    interessesProf={interesses}
                    setInteressesProf={setInteresses}
                  />
                  <Panel
                    header={<h4>Treinamentos</h4>}
                    bordered
                    collapsible
                    defaultExpanded
                  >
                    <AdicionarTreinamentos
                      treinamentosProf={treinamentos}
                      setTreinamentosProf={setTreinamentos}
                    />
                  </Panel>
                </Col>

                <Col md={8} sm={24} xs={24}>
                  <ListaCurriculos
                    curriculo={camposInput.curriculo}
                    onListaAlterada={buscarProfissional}
                    idProfissional={idProfissional}
                  />
                  <ListaDadosBancarios
                    dadosBancarios={dadosBancarios}
                    onListaAlterada={buscarDadosBancarios}
                    idProfissional={idProfissional}
                  />
                </Col>
              </Row>
            </Grid>
          </Form>
          <Drawer.Actions
            style={{
              position: 'fixed',
              width: '90%',
              bottom: 2,
              zIndex: 999,
            }}
          >
            <FlexboxGrid justify="center">
              <FlexboxGrid.Item colspan={6}>
                <ButtonFullBlue
                  appearance="primary"
                  type="submit"
                  size="lg"
                  loading={carregando}
                  block
                  form="editarProfissional"
                >
                  Salvar
                </ButtonFullBlue>
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </Drawer.Actions>
          {carregando && <Loader center backdrop />}
        </Drawer.Body>
      </Drawer>
    </Container>
  );
}

EditarProfissional.propTypes = {
  idProfissional: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showEditarProfissional: PropTypes.bool.isRequired,
  setShowEditarProfissional: PropTypes.string.isRequired,
  atualizarLista: PropTypes.func.isRequired,
};

EditarProfissional.defaultProps = {
  idProfissional: '',
};

export default EditarProfissional;
