/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { Form, IconButton, Panel, Row, SelectPicker } from 'rsuite';
import api from '~/services/api';

function AdicionarInteresse({
  idProfissional,
  onListaAlterada,
  children,
  setInteressesProf,
  interessesProf
}) {
  const [interesses, setInteresses] = useState()

  useEffect(() => {
    api.get('interesse').then((response) => {
      setInteresses(response.data);
    });
  }, []);

  return (
    <Panel
      header={<h4>Interesses</h4>}
      eventKey="123"
      bordered
      collapsible
      defaultExpanded
    >
<Row>
      <Form.Control
        block
        size="lg"
        formValue={(_, itens) => {
          return <h5>{itens.label}</h5>;
        }}
        style={{ padding: '10px' }}
        name="profissoes"
        value={interessesProf}
        accepter={SelectPicker}
        searchable={false}
        placeholder="Selecione"
        placement="topStart"
        onChange={(value) => setInteressesProf(value)}
        data={[{
          label: 'Freelancer',
          value: 'freelancer',
        },
        {
          label: 'Emprego',
          value: 'emprego',
        },
        {
          label: 'Emprego e Freelancer',
          value: 'emprego_freelancer',
        },
      ]}
      />
    </Row>
    </Panel>
  );
}

AdicionarInteresse.propTypes = {
  onListaAlterada: PropTypes.func,
  setInteressesProf: PropTypes.func.isRequired,
  interessesProf: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.element),
  idProfissional: PropTypes.number,
};

AdicionarInteresse.defaultProps = {
  children: [],
  idProfissional: undefined,
  interessesProf: '',
  onListaAlterada: undefined
};

export default AdicionarInteresse;
