/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useDebouncedCallback } from 'use-debounce/lib';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import formatCurrency from '~/utils/formatCurrency';
import { format, parseISO } from 'date-fns';
import copy from 'copy-to-clipboard';
import { CgProfile } from 'react-icons/cg';
import TrashIcon from '@rsuite/icons/Trash';
import { FaMapMarkedAlt } from 'react-icons/fa';

import { Attachment, Edit, InfoRound } from '@rsuite/icons';
import {
  Input,
  FlexboxGrid,
  Form,
  Loader,
  Drawer,
  Divider,
  Panel,
  Grid,
  Row,
  Col,
  SelectPicker,
  Button,
  Table,
  IconButton,
  Pagination,
  Toggle,
} from 'rsuite';
import SearchInputTable from '~/components/SearchInputTable';
import { FiltroTreinamentos } from '~/components/FiltroTreinamentos';
import { FiltroExperiencias } from '~/components/FiltroExperiencias';
import { TablePagination } from '~/components/TablePagination';
import api from '~/services/api';
import { InputDataRange } from '~/components/InputDataRange';
import { GerarCSV as CSV } from '~/utils/gerarCSV'
import { InformacoesProfissional } from '../components/InformacoesProfissional';
import DetalhesCarteiraDialog from '../../relatorios/CarteiraProfissionais/components/DetalhesCarteiraDialog';
import EditarProfissional from '../../Profissionais/EditarProfissional/index';
import AdicionarProfissionalRecrutamento from '../components/AdicionarProfissionalRecrutamento';
import { ButtonFullBlue } from '../../../components/Buttons/ButtonFullBlue/styles';
import { ButtonBorderBlue } from '../../../components/Buttons/ButtonBorderBlue/styles';
import { Container } from './styles';
import CamposEdicaoRecrutamento from '../components/CamposEdicaoRecrutamento';
import { modeloEdicao } from '../model';
// import { CelulaAcaoStatus } from '../components/CelulaAcaoStatus';
import { ModalExcluirProfissional } from '../components/modal/ExcluirProfissionalRecrutamento';
import  {LocalizacaoModal}  from '../components/modal/MapModal';
import {
  ResponsiveForm,
  ResponsiveFormTextArea,
} from '../../../components/ResponsiveForm';
import { InputsDadosClienteRecrutamento } from '../components/InputsDadosClienteRecrutamento';
import { TogglesStatus } from '../styles';
import { DarkModeContext } from '../../../common/contexts/darkMode';
import { ModalPadrao } from '../../../components/Modal';


const { HeaderCell, Column, Cell } = Table;

function EditarRecrutamento({
  idPedido,
  showEditarPedido,
  codPedido,
  setShowEditarPedido,
  // fecharEditarPedido,
}) {
  const carregando = useSelector((state) => state.pedido.carregando);
  const [newPro, setNewPro] = useState(false);
  const [pagina, setPagina] = useState(1);
  const [orderByRecrut, setOrderByRecrut] = useState('');
  const [textoDigitado, setTextoDigitado] = useState('');
  const [statusValueInput, setStatusValueInput] = useState();
  const [experienciaSelecionada, setExperienciaSelecionada] = useState([]);
  const [treinamentoSelecionado, setTreinamentoSelecionado] = useState();
  const [datas, setDatas] = useState();
  const [atributoSelecionado, setAtributoSelecioando] = useState('nome');
  const [profissoes, setProfissoes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [carregando2, setCarregando2] = useState(false);
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [cidade, setCidade] = useState('');
  const [cpfCnpj, setCpfCnpj] = useState('');
  const [bairro, setBairro] = useState('');
  const [exibirInforPro, setExibirInforPro] = useState(false);
  const [inforPro, setInforPro] = useState('');
  const [exibirDetalhesPro, setExibirDetalhesPro] = useState(false);
  const [showEditarProfissional, setShowEditarProfissional] = useState(false);
  const [editarProfissional, setEditarProfissional] = useState('');
  const [detalhesPro, setDetalhesPro] = useState('');
  const [modalLocaliza, setModalLocaliza] = useState(false);
  const [LocalizaRecrutamento, setLocalizaRecrutamento] = useState(false);
  const [listagemRecrutamento, setListagemRecrutamento] = useState({
    lista: [],
    quantidade: 0,
  });
  const [proExcluirModal, setproExcluirModal] = useState(false);
  const [statusRecrutamento, setStatusRecrutamento] = useState([]);
  const [proExcluir, setproExcluir] = useState();
  const [modalCSVOpen, setModalCSVOpen] = useState(false);
  const [inputsDadosRecrutamento, setInputsDadosRecrutamento] = useState({
    beneficios: [],
    data: '',
  });
  const [inputsDadosListaRecrutamento, setInputsDadosListaRecrutamento] = useState({
    beneficios: [],
    data: '',
  });
  const [dadosPro, setDadosPro] = useState({
    lista: [],
    quantidade: 0,
  });
  const [treinamentos, setTreinamentos] = useState([]);
  const [buscarCSV, setBuscarCSV] = useState(false);
  const { toggleMode } = useContext(DarkModeContext);
  const motalTitulo = 'Gerar Relatório CSV';
  const modalTexto =
    'Deseja realmente gerar o arquivo em csv dos Profissionais com o filtro atual?';

  const locale = {
    sunday: 'Dom',
    monday: 'Seg',
    tuesday: 'Ter',
    wednesday: 'Qua',
    thursday: 'Qui',
    friday: 'Sex',
    saturday: 'Sab',
    ok: 'Ok',
    today: 'Hoje',
    yesterday: 'Ontem',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos',
  };

  let erro = {};
  useEffect(() => {
    const buscarDadosRecrutamento = async () => {
      if (!idPedido) return;
      try {
        const resposta = await api.get(`recrutamento/${idPedido}`);

        setInputsDadosRecrutamento({
          ...resposta.data,
          id_estabelecimento: resposta.data.id_estabelecimento,
          nome: resposta.data.estabelecimento.nome_fantasia,
          servico: resposta.data.id_servico,
          tipo_estabelecimento: resposta.data.estabelecimento.tipo_estabelecimento[0].tipo_estabelecimento,
          data: resposta.data.data_solicitacao
            ? resposta.data.data_solicitacao.split('-').reverse().join('/')
            : '',
          quantidade: resposta.data.total_vagas,
          salario: formatCurrency(resposta.data.salario),
          tipo_contrato: resposta.data.tipo_contrato,
          previsao_inicio: resposta.data.previsao_inicio
            ? resposta.data.previsao_inicio.split('-').reverse().join('/')
            : '',

          jornada: resposta.data.jornada,
          gorjeta: formatCurrency(resposta.data.gorjeta),
          observacao: resposta.data.observacao,
          beneficios_nome: resposta.data.beneficios.map((beneficio) => beneficio.beneficio),
          beneficios: resposta.data.beneficios.map((beneficio) => beneficio.id),
          perfil: resposta.data.perfil,
          experiencia: resposta.data.experiencia,
          anotacoes: resposta.data.anotacoes,
          bairro: resposta.data.estabelecimento.endereco.bairro.bairro,
          cidade: resposta.data.estabelecimento.endereco.bairro.cidade.cidade,
          email: resposta.data.estabelecimento.email,
          telefone: resposta.data.estabelecimento.telefone,
          pessoa_responsavel: resposta.data.estabelecimento.pessoa_responsavel,
          cpf_cnpj: resposta.data.estabelecimento.cpf_cnpj,
          obs: resposta.data.estabelecimento.obs,
          endereco: `${resposta.data.estabelecimento.endereco.logradouro}, ${resposta.data.estabelecimento.endereco.numero}. ${resposta.data.estabelecimento.endereco.bairro.bairro}-${resposta.data.estabelecimento.endereco.bairro.cidade.cidade}/${resposta.data.estabelecimento.endereco.bairro.cidade.estado.uf}`,
        });
        // setInputsDadosRecrutamento(dados)
        // setInputsDadosListaRecrutamento(dados)
        setPagina(1)
      } catch (e) {
        toast.error(e.message);
      }
    };
    buscarDadosRecrutamento();
  }, [idPedido]);

  async function salvarRecrutamento() {
    try {
      await api.put(`recrutamento/${idPedido}`, inputsDadosRecrutamento);
      toast.success('Dados atualizados com sucesso!');
      setShowEditarPedido(false);
    } catch (e) {
      toast.error(e.message);
    }
  }


  function exibirMensagemErro() {
    Object.values(erro).forEach((e) => {
      toast.error(e);
    });
  }

  async function finalizar(semErro) {
    // semErro ? await dispatch(salvarPedido()) : await exibirMensagemErro();
    // fecharEditarPedido();
  }


  const buscarRecrutamento = useCallback(async () => {
    if (idPedido && idPedido > 0) {
      setModalCSVOpen(false);
      setCarregando2(true);
      try {
        const resposta = await api.get('recrutamento-pro', {
          params: {
            id_recrutamento: idPedido,
            pagina,
            nome,
            bairro,
            cidade,
            email,
            cpfCnpj,
            data: datas,
            experiencia: experienciaSelecionada,
            treinamento: treinamentoSelecionado,
            orderByRecrut,
            csv: buscarCSV
          }
        });
        /* ============= */

        const dadosRecrutamento = resposta.data.lista.map((item) => ({
          ...(buscarCSV === false && item),
          "Nome": item.nome,
          "Telefone": item.telefone1,
          "Avaliacao": item.avaliacao.toFixed(1),
          "Recebido": formatCurrency(item.valor_bruto),
          "Horas": item.horas,
          "Shifts": item.shifts,
          "Faltas": item.faltas,
          "Cancelamentos": item.cancelamento,
          "Bairro": item.bairro,
          "Cidade": item.cidade,
          "Data": format(parseISO(item.created_at), 'dd/LL/yyyy'),
        }));

        if(buscarCSV){
          CSV({
            data: dadosRecrutamento,
            download: true,
          });

          setCarregando2(false);
          toast.success(`Foram exportados ${dadosRecrutamento.length} registros.`);
          setBuscarCSV(false);
          return;
        }

        setListagemRecrutamento({
          lista:dadosRecrutamento,
          quantidade: resposta.data.quantidade,
        })

        /* ============= */
        setDadosPro({
          lista: resposta.data.lista,
          quantidade: resposta.data.quantidade,
        });
      } catch (e) {
        if(buscarCSV){
          setCarregando2(false);
          toast.error('Erro ao Exportar os dados');
          setBuscarCSV(false);
          return;
        }
        toast.error(e.message);
      }
      setCarregando2(false);
    }
  }, [
    nome,
    bairro,
    email,
    cpfCnpj,
    datas,
    pagina,
    cidade,
    treinamentoSelecionado,
    experienciaSelecionada,
    idPedido,
    setDadosPro,
    setListagemRecrutamento,
    orderByRecrut,
    buscarCSV
  ]);

  useEffect(() => {
    buscarRecrutamento();
  }, [buscarRecrutamento, pagina]);

  useEffect(() => {
    const buscarDadosApi = async () => {
      try {
        const resposta = await api.get('profissao');
        setProfissoes(resposta.data);
      } catch (error) {
        toast.error(error.message);
      }
    };

    buscarDadosApi();
  }, []);



  useEffect(() => {
    const buscarDadosApi = async () => {
      try {
        const resposta = await api.get('treinamento');
        setTreinamentos(resposta.data);
      } catch (error) {
        toast.error(error.message);
      }
    };

    buscarDadosApi();
  }, []);

  useEffect(() => {
    switch (atributoSelecionado) {
      case 'nome':
        setNome(textoDigitado);
        break;
      case 'cpf_cnpj':
        setCpfCnpj(textoDigitado);
        break;
      case 'email':
        setEmail(textoDigitado);
        break;
      default:
        break;
    }
  }, [textoDigitado, atributoSelecionado, bairro]);

  

  const copiaAreaTransferencia = () => {
    if (
      copy(`
*Tipo de Estabelecimento:* ${inputsDadosRecrutamento.tipo_estabelecimento}
*Bairro:* ${inputsDadosRecrutamento.bairro} - ${inputsDadosRecrutamento.cidade}
*Salário:* ${inputsDadosRecrutamento.salario}
*Gorjeta:* ${inputsDadosRecrutamento.gorjeta}
*Tipo de Contrato:* ${inputsDadosRecrutamento.tipo_contrato}
*Jornada:* ${inputsDadosRecrutamento.jornada}
*Previsão de Início:* ${inputsDadosRecrutamento.previsao_inicio}
*Descrição da Vaga:* ${inputsDadosRecrutamento.profissoes.profissao}
*Benefício(s):* ${inputsDadosRecrutamento.beneficios_nome.join(', ')}
*Experiência(s):* ${inputsDadosRecrutamento.experiencia}
*Perfil:* ${inputsDadosRecrutamento.perfil === null ? '' : inputsDadosRecrutamento.perfil}
*Descrição do Serviço:* ${inputsDadosRecrutamento.observacao === null ? '' : inputsDadosRecrutamento.observacao}
*${inputsDadosRecrutamento.profissoes.profissao.trim()}_${inputsDadosRecrutamento.estabelecimento.endereco.bairro.cidade.estado.uf}_${codPedido}*
`)
    ) {
      document.getElementById('clipboardCopy').textContent = 'Info Copiada';
    }
  };

  async function alteracaoStatus(e, value) {
    try {
      const statusValue = {
        id: e,
        status: value,
      };
      setCarregando2(true);
      await api.patch(`recrutamento/status`, statusValue);
      setCarregando2(false);
      setStatusValueInput(statusValue.status);
      buscarRecrutamento();
      toast.success('Status atualizado com sucesso');
    } catch (error) {
      toast.error(error.message);
    }
  }


  const buscarStatusRecrutamento = useCallback(async () => {
    try {
      const resposta = await api.get('recrutamento-status');
      const data = resposta.data.map((item) => ({
        value: item.id, label: item.status
      }));


      setStatusRecrutamento(data);
    } catch (e) {
      toast.error(e.message);
    }
  }, [ ]);

  useEffect(() => {
    buscarStatusRecrutamento();
  }, [buscarStatusRecrutamento]);

  function fecharEditarProfissional() {
    setShowEditarProfissional(false);
  }
  /* ========================== */

  async function alteracaoStatusContratacao(e, value, setStatus) {

    try {
        const statusValue = {
            id: e.id,
            status: !value,
        };
        await api.patch(`recrutamento-pro/contratar`, statusValue);
        setStatus(!value); 
        toast.success('Profissional atualizado com sucesso');
    } catch (error) {
        toast.error(error.message);
    }
}

const CelulaAcaoStatus2 = ({ rowData, ...props }) => {
    const [status, setStatus] = useState(rowData.status_contratacao);

    return (
        <Cell {...props}>
            <TogglesStatus>
                <Toggle
                    checked={status}
                    checkedChildren="Sim"
                    unCheckedChildren="Não"
                    onChange={() => alteracaoStatusContratacao(rowData, status, setStatus)}
                    accepter={Toggle}
                />
            </TogglesStatus>
        </Cell>
    );
};
  /* ========================== */

  return (
    <Container>
      <Drawer
        full
        open={showEditarPedido}
        onClose={() => setShowEditarPedido(false)}
      >
        <Drawer.Header>
          <Drawer.Title>Editar Recrutamento {codPedido}</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <Form
            id="editarPedido"
            model={modeloEdicao}
            // onChange={(campos) => {
            //   setInputsDadosRecrutamento({ ...inputsDadosRecrutamento, ...campos });
            //   // dispatch(atualizarCamposInput(campos));
            // }}
            onChange={setInputsDadosRecrutamento}
            formValue={inputsDadosRecrutamento}
            onSubmit={(semErro) =>
              semErro ? salvarRecrutamento() : exibirMensagemErro()
            }
            onError={(err) => {
              erro = err;
            }}
          >
            <CamposEdicaoRecrutamento
              inputsDadosRecrutamento={inputsDadosRecrutamento}
              setInputsDadosRecrutamento={setInputsDadosRecrutamento}
              editarPedido
              />
              </Form>
            <Divider style={{ margin: '1px', marginBottom: '10px' }} />

            {/* INPUTEDICAORECRUTAMENTO INICIO */}
      <FlexboxGrid justify="start">
        <SearchInputTable
          estilo={{ marginRight: '10px', width: '250px' }}
          setTextoDigitado={setTextoDigitado}
        />

        <SelectPicker
          appearance="default"
          style={{ marginRight: '10px' }}
          searchable={false}
          data={[
            { label: 'Nome', value: 'nome' },
            { label: 'Email', value: 'email' },
            { label: 'Cpf', value: 'cpf_cnpj' },
          ]}
          value={atributoSelecionado}
          cleanable={false}
          onChange={(value) => setAtributoSelecioando(value)}
        />
         <Input
         value={bairro}
          style={{ width: '200px' }}
          placeholder="Bairro"
          onChange={e => setBairro(e)}
        />

        <Input
        value={cidade}
        style={{width: '200px' }}
        placeholder="Cidade"
        onChange={e => setCidade(e)}
        />

        <InputDataRange
          ranges={[]}
          // isoWeek
          value={datas}
          locale={locale}
          onChange={setDatas}
          style={{ marginRight: '10px' }}
        />

        <FiltroTreinamentos
          appearance="default"
          style={{ marginRight: '10px' }}
          searchable={false}
          data={treinamentos.map((treinamento) => ({
            label: treinamento.treinamento,
            value: treinamento.id,
          }))}
          value={treinamentoSelecionado}
          onChange={(value) => setTreinamentoSelecionado(value)}
        />
        <FiltroExperiencias
          style={{ marginRight: '10px' }}
          appearance="default"
          data={profissoes.map((profissao) => ({
            label: profissao.profissao,
            value: profissao.id,
          }))}
          value={experienciaSelecionada}
          onChange={(value) => setExperienciaSelecionada(value)}
        />

        <Button
          onClick={() => {
            setShowModal(true);
          }}
        >
          Candidatar
        </Button>
        <div>
          <FlexboxGrid
            style={{
              width: '100px',
              display: 'flex',
              marginLeft: '10px',
              marginRight: '40px',
            }}
          >
            <FlexboxGrid.Item>
              <SelectPicker
                style={{ width: 130 }}
                value={statusValueInput || Number(inputsDadosRecrutamento.status)}
                searchable={false}
                data={statusRecrutamento}
                onChange={(value) =>
                  alteracaoStatus(inputsDadosRecrutamento.id, value)
                }
              />
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </div>
        <ButtonBorderBlue
          darkMode={toggleMode}
          id="clipboardCopy"
          onClick={copiaAreaTransferencia}
          type="button"
        >
          Copiar Info
        </ButtonBorderBlue>

        <IconButton
          title="Exportar CSV"
          style={{ marginLeft: '10px' }}
          size="4em"
          icon={<Attachment />}
          onClick={() => {
            setModalCSVOpen(true);
          }}
        />
      </FlexboxGrid>

      <AdicionarProfissionalRecrutamento
        exibir={showModal}
        onFechar={() => setShowModal(false)}
        setNewPro={setNewPro}
        idPedido={idPedido}
      // registro={registroSelecionado}
      />
            {/* INPUTEDICAORECRUTAMENTO FIM */}

            {/* <Divider style={{ margin: '1px' }} /> */}

            {/* LISTAPROFISSIONAISRECRUTAMENTO INICIO */}
        <FlexboxGrid style={{ width: '1590px' }}>
      <TablePagination
        carregando={carregando2}
        dados={listagemRecrutamento.lista}
        quantidade={listagemRecrutamento.quantidade}
        onChangePage={setPagina}
        setOrderBy={setOrderByRecrut}
      >
        <Column resizable width={180}>
          <HeaderCell align="center">Opções</HeaderCell>
          <Cell dataKey="id" style={{ padding: '5px' }}>
            {(rowData) => (
              <>
                <IconButton
                  title="Informações do Profissional"
                  appearance="subtle"
                  onClick={() => {
                    setExibirInforPro(true);
                    setInforPro(rowData.infor_pro);
                  }}
                  icon={<CgProfile />}
                />
                 <IconButton
                    appearance="subtle"
                    onClick={() => {
                      setLocalizaRecrutamento(rowData)
                      setModalLocaliza(true)
                    }}
                    alt="Localização"
                    icon={<FaMapMarkedAlt />}
                  />
                <IconButton
                  title="Detalhes"
                  appearance="subtle"
                  onClick={() => {
                    setExibirDetalhesPro(true);
                    setDetalhesPro(rowData);
                  }}
                  icon={<InfoRound />}
                />
                <IconButton
                  title="Editar"
                  appearance="subtle"
                  onClick={() => {
                    setShowEditarProfissional(true);
                    setEditarProfissional(rowData);
                  }}
                  icon={<Edit />}
                />
              </>
            )}
          </Cell>
        </Column>
        <Column resizable width={220} sortable>
          <HeaderCell>Nome Profissional</HeaderCell>
          <Cell dataKey="Nome" />
        </Column>
        <Column resizable width={150}>
          <HeaderCell>Telefone</HeaderCell>
          <Cell dataKey="Telefone" />
        </Column>
        <Column resizable width={90}>
          <HeaderCell>Avaliação</HeaderCell>
          <Cell dataKey="Avaliacao" />
        </Column>
        <Column resizable width={110}>
          <HeaderCell>Recebido</HeaderCell>
          <Cell dataKey="Recebido" />
        </Column>
        <Column resizable width={70}>
          <HeaderCell>Horas</HeaderCell>
          <Cell dataKey="Horas" />
        </Column>
        <Column resizable width={70}>
          <HeaderCell>Shifts</HeaderCell>
          <Cell dataKey="Shifts" />
        </Column>
        <Column resizable width={70}>
          <HeaderCell>Faltas</HeaderCell>
          <Cell dataKey="Faltas" />
        </Column>
        <Column resizable width={90}>
          <HeaderCell>Cancelamentos</HeaderCell>
          <Cell dataKey="Cancelamentos" />
        </Column>
        <Column resizable width={150} sortable>
          <HeaderCell>Bairro</HeaderCell>
          <Cell dataKey="Bairro" />
        </Column>
        <Column resizable width={110} sortable>
          <HeaderCell>Cidade</HeaderCell>
          <Cell dataKey="Cidade" />
        </Column>
        <Column resizable width={110} sortable>
          <HeaderCell>Data</HeaderCell>
          <Cell dataKey="Data" />
        </Column>
        <Column resizable width={70}>
          <HeaderCell align="left">Excluir</HeaderCell>
          <Cell dataKey="id" align="left" style={{ padding: '5px' }}>
            {(rowData) => (
              <>
                <IconButton
                  appearance="subtle"
                  onClick={() => {
                    setproExcluir(rowData);
                    setproExcluirModal(true);
                  }}
                  color="red"
                  icon={<TrashIcon />}
                />
              </>
            )}
          </Cell>
        </Column>
        <Column resizable width={100} sortable>
          <HeaderCell>Prospectado</HeaderCell>
          <CelulaAcaoStatus2 dataKey="status_contratacao" setNewPro={setNewPro} />
        </Column>
      </TablePagination>
      </FlexboxGrid>
      <LocalizacaoModal
        modalLocaliza={modalLocaliza}
        setModalLocaliza={setModalLocaliza}
        LocalizaRecrutamento={LocalizaRecrutamento}
      />
      <InformacoesProfissional
        exibir={exibirInforPro}
        onFechar={() => setExibirInforPro(false)}
        inforPro={inforPro}
      />
      <DetalhesCarteiraDialog
        exibir={exibirDetalhesPro}
        id_profissional={detalhesPro.id_profissional}
        onFechar={() => setExibirDetalhesPro(false)}
        detalhesPro={detalhesPro}
      />
      <EditarProfissional
        fecharEditarProfissional={fecharEditarProfissional}
        editarPro={editarProfissional}
        showEditarProfissional={showEditarProfissional}
        setShowEditarProfissional={setShowEditarProfissional}
        idProfissional={
          editarProfissional && editarProfissional.id_profissional
        }
        atualizarLista
      />
      <ModalExcluirProfissional
        setproExcluirModal={setproExcluirModal}
        proExcluirModal={proExcluirModal}
        proExcluir={proExcluir}
        setNewPro={setNewPro}
      />
      <ModalPadrao
        openModal={modalCSVOpen}
        setOpenModal={setModalCSVOpen}
        title={motalTitulo}
        text={modalTexto}
        funcExec={() => {
          setBuscarCSV(true)
          buscarRecrutamento();
        }}
      />
            {/* LISTAPROFISSIONAISRECRUTAMENTO FIM */}

            <Divider style={{ margin: '1px' }} />
            <InputsDadosClienteRecrutamento
              inputsDadosRecrutamento={inputsDadosRecrutamento}
            />
          <Drawer.Actions
            style={{
              position: 'fixed',
              width: '90%',
              bottom: 2,
              zIndex: 999,
            }}
          >
            <FlexboxGrid justify="center">
              <FlexboxGrid.Item colspan={6}>
                <ButtonFullBlue
                  appearance="primary"
                  type="submit"
                  loading={carregando}
                  block
                  form="editarPedido"
                >
                  Salvar
                </ButtonFullBlue>
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </Drawer.Actions>
          {carregando && <Loader backdrop center />}
        </Drawer.Body>
      </Drawer>
    </Container>
  );
}

export default EditarRecrutamento;
