import { Panel as Rpanel } from 'rsuite';
import styled from 'styled-components';

export const Image = styled.img`
  width: 100%;
  padding-bottom: 100%;
  border-radius: 50%;
  position: relative;
  background-image: url(${(props) => props.image && props.image.caminho});
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
`;

export const Panel = styled(Rpanel)`
  .rs-grid-container {
    margin: 0;
    width: 100%;
  }
`;
