import styled from 'styled-components';
import { Panel } from 'rsuite';
import theme from '~/styles/theme';

export const Grid = styled.div`
  .rs-panel-body {
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
  }
  display: flex;
  flex-direction: column;
  width: calc(50% - 15px);
  & span {
    padding-top: 5px;
  }
  @media (min-width: 1229px) {
    width: calc(20% - 15px);
  }
`;

export const StyledPanel = styled(Panel)`
  width: 100%;
  background-color: ${props=> props.darkMode === true ? '#1a1d24' : 'white'};

  h4 {
    color: ${props=> props.darkMode === true ? 'gray' : theme.darkGray}
  }
`;
