/* eslint-disable no-inner-declarations */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable prettier/prettier */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { isAfter, isBefore, subDays, format, parseISO } from 'date-fns';
import {
  Row,
  CheckboxGroup,
  Checkbox,
  DatePicker,
  Button,
  AutoComplete,
  SelectPicker,
  InputGroup,
  Form,
  Input,
  FlexboxGrid,
  Col,
  ButtonGroup,
  RadioGroup,
  Radio,
  Dropdown,
  IconButton,
  CheckPicker,
} from 'rsuite';

import { LightbulbFlash } from '@styled-icons/remix-line/LightbulbFlash';
import { Bulb } from '@styled-icons/evaicons-solid/Bulb';
import { Shake } from '@styled-icons/evaicons-solid/Shake';
import { Pointer, PointerDimensions } from '@styled-icons/evil/Pointer';
import { PlusSquare } from '@styled-icons/fa-solid/PlusSquare';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import MultiDatePicker from '@rsuite/multi-date-picker';
import InputMascara from '~/components/InputMascara';
import api from '~/services/api';
import theme from '~/styles/theme';

import {
  ResponsiveForm,
  ResponsiveFormTextArea,
} from '~/components/ResponsiveForm';
import useSearchNames from '~/services/useSearchNames';
import formatCurrency from '~/utils/formatCurrency';
import * as Styled from './style';

function CamposDadosRecrutamento({ camposInput, setCamposInput }) {
  const [pedidos, setPedidos] = useState([]);

  const [profissoes, setProfissoes] = useState([]);
  const [beneficios, setBeneficios] = useState([]);
  const [nomesEstabelecimentos, setNomesEstabelecimentos] = useState([]);

  const layoutResponsivo = { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }
  const layoutResponsivo1 = { width: '100%', marginLeft: '10px' }
  const marginLeft1 = { marginLeft: '10px' }



  const locale = {
    sunday: 'Dom',
    monday: 'Seg',
    tuesday: 'Ter',
    wednesday: 'Qua',
    thursday: 'Qui',
    friday: 'Sex',
    saturday: 'Sab',
    ok: 'Ok',
    today: 'Hoje',
    yesterday: 'Ontem',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos',
  };

  const range = [
    {
      label: 'Hoje',
      value: new Date(),
    },
  ];

  const buscarNomesEstabelecimentos = useSearchNames(
    setNomesEstabelecimentos,
    'estabelecimentos'
  );

  useEffect(() => {
    const buscarDadosApi = async () => {
      try {
        const resposta = await api.get('profissao');
        setProfissoes(resposta.data);
      } catch (error) {
        toast.error(error.message);
      }
    };

    buscarDadosApi();
  }, []);

  useEffect(() => {
    const buscarDadosApi = async () => {
      try {
        const resposta = await api.get('beneficios');

        setBeneficios(resposta.data);
      } catch (error) {
        toast.error(error.message);
      }
    };
    buscarDadosApi();
  }, []);

  return (
    <Styled.Panel
      header={
        <Styled.Header>
          <h5>Dados do Recrutamento.</h5>
        </Styled.Header>
      }
    >
      {/* ----------------------------separação-------------------- */}
      <Styled.Container>
        <Styled.Content style={{ width: '40%' }}>
          <Form.Group style={{ width: '100%' }}>
            <ResponsiveForm
              label="Nome do Cliente"
              name="nome_cliente"
              accepter={AutoComplete}
              data={nomesEstabelecimentos.map((p) => ({
                label: p.nome_fantasia,
                value: p.nome_fantasia,
                id: p.id,
              }))}
              onSelect={async (value, item) => {
                setTimeout(() => {
                  setCamposInput(() => ({
                    ...camposInput,
                    id_estabelecimento: item.id,
                    nome_cliente: item.label,
                  }));
                }, 1000);
              }}
              value={camposInput.nome_cliente}
              onChange={buscarNomesEstabelecimentos}
            />
          </Form.Group>
          <Row style={{ display: 'flex', marginTop: '-10px' }}>
            <Col xs={9} sm={9} md={9}>
              <Form.Group
                style={{ ...layoutResponsivo }}
              >
                <ResponsiveForm
                  style={{ width: '100%' }}
                  label="Data"
                  placeholder="DD/MM/AA"
                  name="data_solicitacao"
                  format="dd/MM/yy"
                  cleanable={false}
                  accepter={DatePicker}
                  oneTap
                  locale={locale}
                  value={
                    camposInput.data_solicitacao && camposInput.data_solicitacao
                  }
                  ranges={range}
                  onChange={(data) =>
                    setCamposInput({ ...camposInput, data_solicitacao: data })
                  }
                />
              </Form.Group>
            </Col>
            <Col xs={15} sm={15} md={15}>
              <Form.Group
                style={{ ...layoutResponsivo }}
              >
                <ResponsiveForm
                  name="servico"
                  label="Serviço"
                  style={{ width: '100%' }}
                  placeholder="Serviço"
                  value={camposInput.servico}
                  data={profissoes.map((p) => {
                    return {
                      label: p.profissao,
                      value: p.id,
                    };
                  })}
                  accepter={SelectPicker}
                  onChange={(s) => setCamposInput((c) => ({ ...c, servico: s }))}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row style={{ display: 'flex' }}>
            <Col xs={9} sm={9} md={9}>
              <Form.Group
                style={{ ...layoutResponsivo }}
              >
                <ResponsiveForm
                  label="Previsão Início"
                  name="previsao_inicio"
                  format="dd/MM/yy"
                  style={{ width: '100%' }}
                  value={
                    (camposInput.previsao_inicio &&
                      camposInput.previsao_inicio) ||
                    ''
                  }
                />
              </Form.Group>
            </Col>
            <Col xs={15} sm={15} md={15}>
              <Form.Group
                style={{ ...layoutResponsivo }}
              >
                <ResponsiveForm
                  name="tipo_contrato"
                  label="Tipo de Contrato"
                  placeholder="Selecione"
                  searchable={false}
                  accepter={SelectPicker}
                  style={{ width: '100%' }}
                  value={camposInput.tipo_contrato}
                  data={[
                    { value: 'CLT', label: 'CLT' },
                    { value: 'Intermitente', label: 'Intermitente' },
                    { value: 'MEI', label: 'MEI' },
                    { value: 'Contrato Parcial', label: 'Contrato Parcial' },
                    { value: 'MEI e CLT', label: 'MEI e CLT' },
                  ]}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row style={{ display: 'flex' }}>
            <Col xs={12} sm={12} md={12}>
              <Form.Group
                style={{ ...layoutResponsivo }}
              >
                <ResponsiveForm
                  style={{ width: '100%' }}
                  label="Jornada"
                  name="jornada"
                  placeholder="Informe a jornada..."
                  value={camposInput.jornada}
                />
              </Form.Group>
            </Col>
            <Col xs={12} sm={12} md={12}>
              <Form.Group
                style={{ ...layoutResponsivo }}
              >
                <ResponsiveForm
                  style={{ width: '100%' }}
                  label="Quantidade"
                  name="quantidade"
                  placeholder="Informe a quantidade..."
                  value={camposInput.quantidade}
                  type="number"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row style={{ display: 'flex' }}>

            <Col xs={12} sm={12} md={12}>
              <Form.Group
                style={{ ...layoutResponsivo }}
              >
                <ResponsiveForm
                  style={{ width: '100%' }}
                  label="Salário"
                  name="salario"
                  placeholder="Informe o valor..."
                  value={camposInput.salario}
                />
              </Form.Group>
            </Col>
            <Col xs={12} sm={12} md={12}>
              <Form.Group
                style={{ ...layoutResponsivo }}
              >
                <ResponsiveForm
                  style={{ width: '100%' }}
                  label="Gorjeta"
                  name="gorjeta"
                  placeholder="Informe o valor..."
                  value={camposInput.gorjeta}
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group>
            <ResponsiveFormTextArea
              label="Experiência"
              name="experiencia"
              rows={4}
              componentClass="textarea"
              value={camposInput.experiencia}
            />
          </Form.Group>
        </Styled.Content>
        {/* ----------------------------separação-------------------- */}
        <Styled.Content style={{ width: '30%' }}>
          <Form.Group>
            <CheckboxGroup
              value={camposInput.beneficios}
              onChange={(value) =>
                setCamposInput({ ...camposInput, beneficios: value })
              }
            >
              <Form.ControlLabel>Benefícios</Form.ControlLabel>
              {beneficios.map((p) => {
                return (
                  <Checkbox
                    style={{ margin: '-7px' }}
                    key={p.id}
                    name="beneficios"
                    label={p.beneficio}
                    value={p.id}
                  >
                    <div
                      style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
                    >
                      {' '}{p.beneficio}
                    </div>
                  </Checkbox>
                );
              })}
            </CheckboxGroup>
          </Form.Group>
          <Form.Group style={{ marginTop: '-10px' }}>
            <ResponsiveFormTextArea
              label="Perfil"
              name="perfil"
              rows={8}
              componentClass="textarea"
              value={camposInput.perfil}
            />
          </Form.Group>
        </Styled.Content>
        {/* ----------------------------separação-------------------- */}
        <Styled.Content style={{ width: '30%' }}>
          <Form.Group>
            <ResponsiveFormTextArea
              label="Descrição do serviço"
              name="observacao"
              rows={5}
              componentClass="textarea"
              value={camposInput.observacao}
            />
          </Form.Group>
          <Form.Group style={{ marginTop: '-10px' }}>
            <ResponsiveFormTextArea
              label="Anotações"
              name="anotacoes"
              rows={12}
              componentClass="textarea"
              value={camposInput.anotações}
            />
          </Form.Group>
        </Styled.Content>

        {/* ----------------------------separação-------------------- */}
      </Styled.Container>
    </Styled.Panel >
  );
}

CamposDadosRecrutamento.propTypes = {
  camposInput: PropTypes.object,
  setCamposInput: PropTypes.func,
};

CamposDadosRecrutamento.defaultProps = {
  camposInput: {},
  setCamposInput: () => { },
};

export default CamposDadosRecrutamento;
