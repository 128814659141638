import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { Modal, Button } from 'rsuite';
import api from '~/services/api';
import { toast } from 'react-toastify';

export function ExcluirRecrutamento ({setModalExcluir, modalExcluir, exluirRecrutamento, setAtualizarLista, buscarRecrutamento}) {
  const [ carregando, setCarregando ] = useState(false);


  async function excluirPro() {
    // const { id, estabelecimento: { nome_fantasia } } = exluirRecrutamento
    if (!exluirRecrutamento.id) throw new Error('Recrutamento não encontrado');
    setCarregando(true)
    try {
      await api.delete(`/recrutamento/${exluirRecrutamento.id}`)
      setCarregando(false)
      setAtualizarLista((p) => !p)
      toast.success(`Recrutamento do Estabelecimento ${exluirRecrutamento.nome_fantasia} excluido(a) com sucesso.`)
      setModalExcluir(false)
      buscarRecrutamento()
    } catch(e) {
      setCarregando(false)
      toast.error(e.message)
    }
  }

  return (
    <Modal
        open={modalExcluir}
        onClose={() => setModalExcluir(false)}
        size="xs"
      >
        <Modal.Header>Excluir Recrutamento</Modal.Header>
        <Modal.Body>
          <p>Deseja realmente excluir este Recrutamento?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => setModalExcluir(false)}
            appearance="subtle"
          >
            Cancelar
          </Button>
          <Button
            appearance="primary"
            type="button"
            onClick={() => {
              excluirPro();
            }}
          >
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
  )
}

ExcluirRecrutamento.propTypes = {
  setModalExcluir: PropTypes.func,
  buscarRecrutamento: PropTypes.func,
  setAtualizarLista: PropTypes.func.isRequired,
  modalExcluir: PropTypes.bool,
  exluirRecrutamento: PropTypes.objectOf,
}

ExcluirRecrutamento.defaultProps = {
  exluirRecrutamento: {},
  buscarRecrutamento: () => {},
  setModalExcluir: () => { },
  modalExcluir: false,
};

