import React, { useState, useCallback, useEffect } from 'react'
import { InputDataRange } from '~/components/InputDataRange';
import { Container } from '~/styles/tabela'
import theme from "~/styles/theme";
import { format, parseISO, eachDayOfInterval } from 'date-fns';
import formatCurrency from '~/utils/formatCurrency';
import useSearchNames from '~/services/useSearchNames';
import api from '~/services/api';
import produce from 'immer';
import { useDebouncedCallback } from 'use-debounce/lib';
import { toast } from 'react-toastify';
import { Attachment, Search } from '@rsuite/icons';
import { Loader, Table, FlexboxGrid, Input, InputGroup, IconButton, AutoComplete, SelectPicker } from 'rsuite';
import { Card, Header, RowContainer, ColContainer, InfoDiv, TextDiv } from './styles'
import PipeContext from './context';
import Pipeline from '../components/CorpoPipeline';
import EditarRecrutamento from '../EditarRecrutamento';
import FiltroCidades from '../../Mensagens/components/FiltroCidades';
import FiltroBairros from '../../Mensagens/components/FiltroBairros';


const ListaPipeline = () => {
    const [profissoes, setProfissoes] = useState([]);
    const [datas, setDatas] = useState();
    const [filtroServico, setFiltroServico] = useState();
    const [statusSelecionado, setStatusSelecionado] = useState();
    const [filtroCidades, setFiltroCidades] = useState([]);
    const [filtroBairros, setFiltroBairros] = useState([]);
    const [filtroProfissional, setFiltroProfissional] = useState();
    const [nomesProfissionais, setNomesProfissionais] = useState([]);
    const [textodigitado, setTextoDigitado] = useState('');
    const [pagina, setPagina] = useState(1);
    const [cidade, setCidade] = useState('');
    const [bairro, setBairro] = useState('');
    const [carregando, setCarregando] = useState(true);
    const [nomesEstabelecimentos, setNomesEstabelecimentos] = useState([]);
    const [filtroEstabelecimento, setFiltroEstabelecimento] = useState();
    const [statuses, setStatuses] = useState([])
    const [resultado, setResultado] = useState([]);
    const [showEditarPedido, setShowEditarPedido] = useState(false);
    const [pedidoEditando, setPedidoEditando] = useState();
    const [selectLoading, setSelectLoading] = useState();
    const [buttonLoading, setButtonLoading] = useState(false)
    const [recrutamento, setRecrutamento] = useState([]);

    const buscarNomesEstabelecimentos = useSearchNames(
        setNomesEstabelecimentos,
        'estabelecimentos',
        setFiltroEstabelecimento
    );
    const locale = {
        sunday: 'Dom',
        monday: 'Seg',
        tuesday: 'Ter',
        wednesday: 'Qua',
        thursday: 'Qui',
        friday: 'Sex',
        saturday: 'Sab',
        ok: 'Ok',
        today: 'Hoje',
        yesterday: 'Ontem',
        hours: 'Horas',
        minutes: 'Minutos',
        seconds: 'Segundos',
    };

    const buscarNomesProfissionais = useSearchNames(
        setNomesProfissionais,
        'profissionais',
        setFiltroProfissional
    );

    const PipelineRecrutamento = useCallback(async () => {
        let datasIntervalo = []
        if (datas && datas.length !== 0) {

            const dataInicio = datas[0].toDateString()
            const dataFim = datas[1]
            datasIntervalo = [
                dataInicio,
                dataFim
            ]
        }

        setCarregando(true);
        try {
            const resposta = await api.get('recrutamento-status');

            const respostaPipe = await api.get('recrutamento/pipe-line', {
                params: {
                    pagina,
                    id_cliente: filtroEstabelecimento,
                    status: statusSelecionado,
                    data: datas,
                    servico: filtroServico,
                    cidade,
                    bairro,
                    profissional: filtroProfissional,
                    numero_rs: textodigitado,
                },
            });

            const result = resposta.data.map((state) => {
                state.pagina = 1

                return state
            })


            setRecrutamento(respostaPipe.data);

            setStatuses(result)

        } catch (e) {
            toast.error(e.message);
        }



    }, [pagina,
        filtroEstabelecimento,
        statusSelecionado,
        datas,
        cidade,
        filtroServico,
        bairro,
        filtroProfissional,
        textodigitado,
    ]);


    const buscarRecrutamento = useCallback(() => {

        const result = statuses.map((state) => {

            const objFilter = recrutamento.filter((obj) => obj.status === state.id.toString())

            state.cards = [...objFilter];

            return state

        })

        setResultado(result)


        setCarregando(false);

    }, [recrutamento, statuses]);

    useEffect(() => {
        PipelineRecrutamento();
    }, [PipelineRecrutamento]);


    useEffect(() => {
        buscarRecrutamento();
    }, [buscarRecrutamento]);

    async function alteraQuantidadeStatus() {
        const resposta = await api.get('recrutamento-status');

        const respostaMap = resposta.data.map((map, index) => {

            setResultado((prevState) => {
                return produce(prevState, draft => {
                    draft[index].total = map.total
                })
            })

            return map
        })




    }

    async function move(toList, idTable, infoItem) {
        // eslint-disable-next-line eqeqeq
        if (idTable == infoItem.item.status || infoItem.item.status === null) {
            return;
        }

        const newItem = { ...infoItem.item, status: String(idTable) }

        setResultado((prevState) => {
            return produce(prevState, draft => {
                draft[toList].cards.unshift(newItem)
                draft[infoItem.listIndex].cards = draft[infoItem.listIndex].cards.filter((item, index) => item.id !== newItem.id)
            })
        })

        const statusValue = {
            id: infoItem.item.id,
            status: String(idTable)
        }

        await api.patch('/recrutamento/status', statusValue)
            .then(() => {
                alteraQuantidadeStatus()
            })
            .catch((e) => {
                toast.error('Ocorreu um erro ao atualizar status')
                PipelineRecrutamento();
                buscarRecrutamento();
            })


    }

    // Necessário para o funcionamento do react-dnd
    function selecionaItem(item, index, listIndex) {

        return { id: item.id, item, index, listIndex }

    }




    async function adicionaItemPipe(idTable, listIndex) {
        setButtonLoading(true)

        const newPagina = Number(resultado[listIndex].pagina) + 1

        if (newPagina !== 1) {

            const result = await api.get(`recrutamento/pipe-line/${idTable}`, {
                params: {
                    pagina: newPagina
                }
            })

            if (result.data.length !== 0) {
                resultado.map((item, index) => {

                    if (String(item.id) === result.data[0].status) {
                        const { cards } = item

                        result.data.map((map) => {
                            const findRecrut = cards.find((find) => map.id === find.id)

                            if (findRecrut === undefined) {

                                setResultado((prevState) => {
                                    return produce(prevState, draft => {
                                        draft[index].cards.push(map)
                                        draft[index].pagina = newPagina

                                    })
                                })
                            }
                            return map
                        })
                    }

                    return item
                })
            }
        }

        setButtonLoading(false)
    }

    function abrirEditarPedido(pedido) {
        setPedidoEditando(pedido);
        setShowEditarPedido(true);
    }

    function fecharEditarPedido() {
        setShowEditarPedido(false);
    }


    useEffect(() => {
        const buscarDadosApi = async () => {
            try {
                const resposta = await api.get('profissao');
                setProfissoes(resposta.data);
            } catch (error) {
                toast.error(error.message);
            }
        };

        buscarDadosApi();
    }, []);

    return (
        <PipeContext.Provider value={{ resultado, move, selecionaItem, adicionaItemPipe, abrirEditarPedido, PipelineRecrutamento}}>
            <Container>
                {/* <button type='button' onClick={handleTest}>Teste</button> */}
                <h2>Pedido R&S</h2>
                <ColContainer gap='6px'>
                    <p>Pesquisar por</p>
                    <FlexboxGrid justify="start" style={{ gap: '10px' }}>
                        <AutoComplete
                            name="nome_estabelecimento"
                            style={{ width: '180px' }}
                            data={nomesEstabelecimentos.map((p) => ({
                                label: p.nome_fantasia,
                                value: p.nome_fantasia,
                                id: p.id,
                            }))}
                            placeholder="Nome do Cliente"
                            onSelect={async (value, item) => {
                                setFiltroEstabelecimento(item.id);
                            }}
                            onChange={buscarNomesEstabelecimentos}
                        />
                        <InputDataRange
                            ranges={[]}
                            // isoWeek
                            value={datas}
                            locale={locale}
                            onChange={setDatas}
                        />
                        <SelectPicker
                            name="id_profissao"
                            placeholder="Serviço"
                            value={filtroServico}
                            data={profissoes.map((p) => {
                                return {
                                    label: p.profissao,
                                    value: p.id,
                                };
                            })}
                            onChange={setFiltroServico}
                        />
                        <Input
                            style={{ width: '250px' }}
                            placeholder="Cidade"
                            onChange={useDebouncedCallback(setCidade, 500).callback}
                        />
                        <Input
                            style={{ width: '250px' }}
                            placeholder="Bairro"
                            onChange={useDebouncedCallback(setBairro, 500).callback}
                        />
                        <div>
                            <InputGroup
                                style={{ width: '210px' }}
                            >
                                <AutoComplete
                                    name="nome_profissional"
                                    width={100}
                                    data={nomesProfissionais.map((p) => ({
                                        label: p.nome,
                                        value: p.nome,
                                        id: p.id,
                                    }))}
                                    placeholder="Profissional"
                                    onSelect={async (value, item) => {
                                        setFiltroProfissional(item.id);
                                    }}
                                    onChange={buscarNomesProfissionais}
                                />
                            </InputGroup>
                        </div>
                        <div>
                            <InputGroup style={{ width: '220px' }}>
                                <Input
                                    width={200}
                                    onChange={useDebouncedCallback(setTextoDigitado, 500).callback}
                                    placeholder="Número Recrutamento"
                                />

                                <InputGroup.Button>
                                    <Search />
                                </InputGroup.Button>
                            </InputGroup>
                        </div>
                    </FlexboxGrid>

                </ColContainer>

                {carregando ?
                    <Loader size='lg' /> : <RowContainer>
                        {resultado.map((status, index) =>

                            <Pipeline
                                key={status.status}
                                tableId={status.id}
                                data={status}
                                listIndex={index}
                                carregando={buttonLoading}
                                
                            
                            />

                        )}
                    </RowContainer>}
                            
                    <EditarRecrutamento
                    idPedido={pedidoEditando && pedidoEditando.id}
                    showEditarPedido={showEditarPedido}
                    codPedido={pedidoEditando && pedidoEditando.numero_rs}
                    setShowEditarPedido={setShowEditarPedido}
                    // eslint-disable-next-line react/jsx-no-bind
                    fecharEditarPedido={fecharEditarPedido}
                />
            </Container>
        </PipeContext.Provider>
    )
}


export default ListaPipeline