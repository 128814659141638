import styled from 'styled-components';
import { Button } from 'rsuite';
import theme from '../../../styles/theme';

export const ButtonFullBlue = styled(Button)`
  background-color: ${theme.primaryColor};
  &:hover {
    background-color: ${theme.secondaryColor};
  }
`;

export const ButtonFullYellow = styled(Button)`
  background-color: ${theme.yellowColor};
  &:hover {
    background-color: ${theme.lightRed};
  }
`;

export const ButtonFullRed = styled(Button)`
  background-color: ${theme.lightRed};
  &:hover {
    background-color: ${theme.darkRed};
  }
`;

export const ButtonCleanRed = styled(Button)`
  background-color: ${theme.lightGray};
  &:hover {
    background-color: ${theme.darkRed};
  }
`;

// darkGray: '#464749',
// lightGray: '#D2D2D2',





