import React from 'react';
import PropTypes from 'prop-types';
import { RemindFill } from '@rsuite/icons';

import { Modal, Button } from 'rsuite';

function ModalDeleteProf({ exibir, id_profissional, onFechar, onExcluir, nome_profissional }) {
  const nomeProfissionalUndefined = nome_profissional
  const nomeProfissionalDefined = nomeProfissionalUndefined || ""
  const nomeProfissional = nomeProfissionalDefined.replace(".", "").replace(":", "").replace(/[0-9]/g, "")
  // const nomeProfissional = nome_profissional?.replace(".", "").replace(":", "").replace(/[0-9]/g, "")
  return (
    <Modal open={exibir} onClose={() => onFechar()} size="xs">
      <Modal.Header>
        <Modal.Title>Excluir Profissional</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Você realmente deseja excluir este profissional? <br /> <br />
        id: {id_profissional || 'Não encontrado'} <br /> <br />
        profissional: {nomeProfissional} <br /><br />
        Essa ação é irreversível{' '}
        <RemindFill
          style={{
            color: '#ffb300',
            fontSize: 24,
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => onFechar()} appearance="subtle">
          Cancelar
        </Button>
        <Button onClick={() => {
          onExcluir()
          onFechar()
        }} appearance="primary">
          Excluir
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ModalDeleteProf.propTypes = {
  exibir: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  id_profissional: PropTypes.number.isRequired,
  nome_profissional: PropTypes.string.isRequired,
  onFechar: PropTypes.func.isRequired,
  onExcluir: PropTypes.func.isRequired,
};

export default ModalDeleteProf;
