import React from 'react';
import { useSelector } from 'react-redux';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import {
  SelectPicker
} from 'rsuite';
import { numberToMonth } from '../../../../../../../utils';

// eslint-disable-next-line react/prop-types
export default function Timeline({ ano, anos, anoSelecionado, setAno }) {
  const dadosQuantidade = useSelector(
    (state) => state.carteiraClientes.pedidosPorMes
  );

  const data = [];

  const anoAtual = new Date().getFullYear()


  dadosQuantidade.map((service) => {
    return data.push({
      pedidos: Number(service.pedidos),
      mes: numberToMonth(Number(service.mes.slice(5, 7)), true),
    });
  });

  return (
    <>
      <div style={{ height: '200px', width: '500px', textAlign: 'center' }}>
        
          <h6>Pedidos - Linha do Tempo {ano}</h6>
          <SelectPicker
            placeholder="Ano"
            data={anos}
            searchable={false}
            defaultValue={2024}
            value={anoSelecionado}
            onChange={(value) => {
              if (value !== null) {
                setAno(value)
              } else {
                setAno(new Date().getFullYear())
              }
            }}
            style={{marginTop: 10}}
          />
        <LineChart
          width={500}
          height={250}
          data={data}
          margin={{
            top: 30,
            right: 50,
          }}
        >
          <CartesianGrid strokeDasharray="100 1" />
          <XAxis style={{ fontSize: 10 }} dataKey="mes" />
          <YAxis />
          <Tooltip />
          <Legend
            style={{ margin: '50px' }}
            verticalAlign="bottom"
            height={10}
          />
          <Line
            type="monotone"
            dataKey="pedidos"
            stroke="#FF0000"
            strokeWidth={3}
            dot={{ stroke: 'red', strokeWidth: 2 }}
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </div>
    </>
  );
}
