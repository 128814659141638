/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { toast } from 'react-toastify';
import { IconButton, Table } from 'rsuite';
import CopyIcon from '@rsuite/icons/Copy';
import PropTypes from 'prop-types';
import theme from '../../../styles/theme';

const { Cell } = Table;

const fundo = (data) => {
  let cor;
  if (data.status === 'Pendente') {
    cor = theme.yellowColor;
  } else if (data.status === 'Pago') {
    cor = theme.darkGreen;
  } else if (data.status === 'Cancelado') {
    cor = theme.lightRed;
  } else if (data.status === 'Parcialmente Pago') {
    cor = theme.lightGreen;
  } else if (data.status === 'Devolvido') {
    cor = theme.darkRed;
  } else if (data.status === 'Expirado') {
    cor = theme.darkGray;
  } else if (data.status === 'Autorizado') {
    cor = theme.primaryColor;
  }
  return cor;
};
const ActionCell = ({ rowData, dataKey, ...props }) => {
  return (
    <Cell
      {...props}
      style={{ padding: '5px', background: fundo(rowData), color: '#fff' }}
    >
      <IconButton
        style={{ color: '#fff' }}
        appearance="link"
        onClick={() => {
          toast.info('Url Copiada');
          navigator.clipboard.writeText(rowData[dataKey]);
        }}
        icon={<CopyIcon />}
      />
    </Cell>
  );
};

ActionCell.propTypes = {
  rowData: PropTypes.func.isRequired,
  dataKey: PropTypes.func.isRequired,
};

export default ActionCell;
