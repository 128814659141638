import { combineReducers } from 'redux';

import autenticacao from './autenticacao/reducer';
import pedido from './pedido/reducer';
import notificacao from './notificacao/reducer';
import carteiraClientes from './carteiraClientes/reducer';
import { states, permissions, setUser } from './global/reducer';

export default combineReducers({
  autenticacao,
  pedido,
  notificacao,
  carteiraClientes,
  states,
  permissions,
  setUser,
});
