/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Form, TagPicker, Loader, FlexboxGrid, Col } from 'rsuite';
import api from '~/services/api';

function ListaHabilidades({ experiencias }) {
  const [profissoesLista, setProfissoesLista] = useState([]);
  const [habilidadeLista, setHabilidadeLista] = useState([]);
  const [carregando, setCarregando] = useState(false);

  useEffect(() => {
    const carregarHabilidades = async () => {
      setCarregando(true);
      const profissoes = experiencias
        .map((exp) => exp.profissao)
        .filter((p) => p.profissao !== 'Outros')
        .filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);
      const habilidadesRequisicao = [];
      profissoes.map(async (p) => {
        const resposta = api.get(`/habilidade/${p.id}`);
        habilidadesRequisicao.push(resposta);
      });
      const habilidades = (await Promise.all(habilidadesRequisicao)).map(
        (requisicao) => ({
          id: requisicao.data[0].profissao.id,
          habilidades: requisicao.data,
        })
      );
      setHabilidadeLista(habilidades);
      setProfissoesLista(profissoes);
      setCarregando(false);
    };

    carregarHabilidades();
  }, [experiencias]);

  return (
    <FlexboxGrid justify="space-between">
      {carregando && <Loader center backdrop />}
      {profissoesLista.length !== 0 && !carregando ? (
        profissoesLista.map((p) => (
          <FlexboxGrid.Item
            componentClass={Col}
            colspan={24}
            md={12}
            key={p.id}
          >
            <Form.ControlLabel>{p.profissao}</Form.ControlLabel>
            <Form.Control
              name={`${p.id}`}
              accepter={TagPicker}
              placeholder={p.profissao}
              placement="topStart"
              style={{ width: '300px' }}
              data={habilidadeLista
                .find((h) => h.id === p.id)
                .habilidades.map((h) => ({
                  label: h.habilidade,
                  value: h.id,
                }))}
            />
          </FlexboxGrid.Item>
        ))
      ) : (
        <h5>Nenhuma Experiência adicionada </h5>
      )}
    </FlexboxGrid>
  );
}

ListaHabilidades.propTypes = {
  experiencias: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ListaHabilidades;
