/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { Table, IconButton, Divider, ButtonToolbar } from 'rsuite';
import PropTypes from 'prop-types';
import EditIcon from '@rsuite/icons/Edit';
import TrashIcon from '@rsuite/icons/Trash';
import { TCancelamento } from '../TCancelamento';
import { MCancelamento } from '../MCancelamento';

const { Cell } = Table;

export function CellCancelamento({
  rowData,
  setFields,
  type,
  motivos,
  ...props
}) {
  return (
    <Cell {...props} style={{ padding: '5px' }}>
      {type === 'tipo_cancelamento' ? (
        <TCancelamento cell setFields={setFields} data={rowData} />
      ) : (
        <MCancelamento
          cell
          tipoSelected={rowData.tipo_cancelamento}
          setFields={setFields}
          data={rowData}
          motivos={motivos}
        />
      )}
    </Cell>
  );
}

CellCancelamento.defaultProps = {
  type: 'tipo_cancelamento',
  // rowData: {},
  // fieldSelected: '',
};

CellCancelamento.propTypes = {
  // rowData: PropTypes.objectOf,
  motivos: PropTypes.node.isRequired,
  // motivos: PropTypes.shape({
  //   estabelecimento: PropTypes.arrayOf.isRequired,
  //   admin: PropTypes.arrayOf.isRequired,
  //   profissional: PropTypes.arrayOf.isRequired,
  // }).isRequired,
  setFields: PropTypes.func.isRequired,
  dataKey: PropTypes.string.isRequired,
  type: PropTypes.string,
};
