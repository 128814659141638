import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: start;
  `

export const  TextDiv = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    text-align: start;



    p {
        font-size: 18px;
        margin-top: 2px;
    }
`