/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Modal, Button } from 'rsuite';
import api from '~/services/api';
import { ButtonFullBlue } from '../../../../components/Buttons/ButtonFullBlue/styles';

function PagamentoDialog({ relatorio, exibir, fechar, recarregar }) {
  const [carregando, setCarregando] = useState(false);
  const [verificado, setVerificado] = useState(false);

  useEffect(() => {
    const buscarSubconta = async () => {
      try {
        if (relatorio.profissional) {
          setCarregando(true);
          const resposta = await api.get(
            `/iugu/profissional/${relatorio.profissional.id}`
          );
          setVerificado(resposta.data.iugu_verificado);
          setCarregando(false);
        }
      } catch (e) {
        toast.error('Erro ao buscar dados da subconta');
        fechar();
      }
    };

    buscarSubconta();
  }, [relatorio, fechar]);

  async function realizarTransferencia() {
    setCarregando(true);
    try {
      await api.post(`/relatorios/profissional/pagamento`, {
        valor: relatorio.valor,
        id_profissional: relatorio.profissional.id,
        semana: relatorio.semana,
      });
      toast.success('Transferência feita com sucesso');
      recarregar();
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
  }

  return (
    <Modal backdrop="static" open={exibir} onClose={fechar} size="sm">
      <Modal.Header>
        <h3>
          Pagar {relatorio.profissional ? relatorio.profissional.nome : ''}
        </h3>
      </Modal.Header>
      <Modal.Body>
        Você realmente deseja realizar o pagamento? <br /> <br />
        Semana: {relatorio.seamana_formatado} <br />
        Valor: {relatorio.valor_formatado} <br />
        {carregando
          ? 'Verificado: Carregando'
          : `Verificado: ${verificado ? 'Sim' : 'Não'} `}
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={fechar} appearance="subtle">
          Cancelar
        </Button>
        <ButtonFullBlue
          onClick={realizarTransferencia}
          appearance="primary"
          disabled={!verificado}
          loading={carregando}
        >
          Pagar
        </ButtonFullBlue>
      </Modal.Footer>
    </Modal>
  );
}

export default PagamentoDialog;
