/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import { useState, useRef } from 'react';
import { IconButton, Input } from 'rsuite';
import PlusIcon from '@rsuite/icons/Plus';


export const RenderInput = ({props, setDadosFuncionamento, dadosFuncionamento}) => {
  const ref = useRef(null);
  const [typing, setTyping] = useState(false);
  const [typingIdServico, setTypingIdServico] = useState();
  const [inputValue, setInputValue] = useState('');


const addTag = () => {
  // const indice = props.value[0].index
  const idProfissao = Number(props && props[0] && props[0].profissao && props[0].profissao.id &&props[0].profissao.id)
  setDadosFuncionamento({
    ...dadosFuncionamento,
    habilidades: dadosFuncionamento.habilidades.map((item) => {
      if(item && item[0] && item[0].profissao && item[0].profissao.id === idProfissao) {
        return [
          ...item,
          {
          habilidade: inputValue,
          id: Math.random(),
          profissao: {
            id: idProfissao
         }
        }]
      }
      return item;
    })
  });
  setTyping(false);
  setInputValue('');
};

const handleButtonClick = (event) => {
  setTyping(true);
  setTypingIdServico(Number(event.currentTarget.dataset.testId))
};

  const idServicoProps = props && props[0] && props[0].profissao && props[0].profissao.id && props[0].profissao.id
  if (typing && (idServicoProps === typingIdServico)) {
    return (
      <Input
      // data-renderInput={index}
        className="tag-input"
        size="xs"
        // name={props['data-prof_id']}
        style={{ width: 70 }}
        value={inputValue || ''}
        // defaultValue={inputValue}
        onChange={setInputValue}
        onBlur={addTag}
        onPressEnter={() => addTag(props)}
      />
    );
  }

  return (
    <IconButton
    className="tag-add-btn"
    ref={ref}
      data-test-id={props && props[0] && props[0].id && props[0].profissao.id}
      // data-icon-button="teste"
      onClick={handleButtonClick}
      icon={<PlusIcon className="plusBtn" />}
      appearance="ghost"
      size="xs"
    />
  );
};
