/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Col, Row, Form, Schema, Loader, Panel, Grid } from 'rsuite';
import { useSelector } from 'react-redux';
import history from '~/services/history';
import api from '~/services/api';
import { ButtonFullBlue } from '../../../components/Buttons/ButtonFullBlue/styles';

import ListaExperiencias from '../components/ListaExperiencias';
import ListaHabilidades from '../components/ListaHabilidades';
import CamposEndereco from '../components/CamposEndereco';
import CamposDadosProfissionalCriacao from '../components/CamposDadosProfissionalCriacao';

import { Container } from './styles';
import CampoObservacoesEComentarios from '../components/CampoObservacoesEComentarios';
import AdicionarInteresse from '../components/AdicionarInteresse';

const { StringType, NumberType, ArrayType } = Schema.Types;

const modelo = Schema.Model({
  nome: StringType().isRequired('Nome obrigatório'),
  senha: StringType()
    .minLength(6, 'Senha muito pequena')
    .isRequired('Senha obrigatório'),
  confirmarSenha: StringType()
    .addRule((valor, campos) => {
      if (valor !== campos.senha) {
        return false;
      }
      return true;
    }, 'Senhas precisam ser iguais')
    .isRequired('Senha obrigatória'),
  email: StringType().isEmail('Email inválido').isRequired('Email obrigatório'),
  cpf_cnpj: StringType()
    .minLength(14, 'Cpf/Cnpj Inválido')
    .addRule((valor) => {
      if (valor.length < 14) return false;
      if (valor.length > 14 && valor.length < 18) return false;
      return true;
    }, 'Cpf/Cnpj inválido')
    .isRequired('Cpf obrigatório'),
  data_nascimento: StringType().isRequired('Data obrigatória'),
  telefone1: StringType().isRequired('Telefone1 obrigatório'),
  // foto_perfil: StringType().isRequired('Foto perfil obrigatória'),
  // documento: ObjectType().isRequired('Documento obrigatório'),
  logradouro: StringType().isRequired('Logradouro obrigatório'),
  cep: StringType().isRequired('Cep obrigatorio'),
  numero: NumberType('Número inválido')
    .min(1, 'Número inválido')
    .isRequired('Numero obrigatório'),
  complemento: StringType(),
  bairro: StringType().isRequired('Bairro obrigatório'),
  obs: StringType(),
  cidade: StringType().isRequired('Cidade obrigatório'),
  estado: StringType().isRequired('Estado obrigatório'),
  uf: StringType().isRequired('Uf obrigatório'),
  escolaridade: NumberType().isRequired('Escolaridade obrigatória'),
  tipo_estabelecimento: NumberType().isRequired('Preferência obrigatória'),
  idiomas: ArrayType().of(NumberType()),
});

function EditarProfissional() {
  const [carregando, setCarregando] = useState(false);
  const loggeduser = useSelector((state) => state.setUser.data);
  const [interesses, setInteresses] = useState('freelancer');
  const [experiencias, setExperiencias] = useState([]);
  const [camposInput, setCamposInput] = useState({});
  const [documento, setDocumento] = useState();
  const [foto, setFoto] = useState();
  let erro = {};

  function exibirMensagemErro() {
    Object.values(erro).forEach((e) => {
      toast.error(e);
    });
  }

  async function salvarUsuario() {
    setCarregando(true);
    const profissoesIdLista = experiencias.map((e) => e.profissao.id);
    try {
      const [dia, mes, ano] = camposInput.data_nascimento.split('/');
      const body = {
        nome: camposInput.nome,
        email: camposInput.email,
        senha: camposInput.senha,
        cpf_cnpj: camposInput.cpf_cnpj,
        id_descobriu_switch: camposInput.id_descobriu_switch,
        data_nascimento: new Date(ano, mes - 1, dia).toISOString(),
        telefone1: camposInput.telefone1,
        telefone2: camposInput.telefone2,
        genero: camposInput.genero,
        obs_outro: camposInput.obs_outro,
        endereco: {
          logradouro: camposInput.logradouro,
          cep: camposInput.cep,
          numero: camposInput.numero,
          complemento: camposInput.complemento,
          bairro: {
            nome: camposInput.bairro,
            cidade: {
              nome: camposInput.cidade,
              estado: {
                uf: camposInput.uf,
                nome: camposInput.estado,
              },
            },
          },
        },
        escolaridade: camposInput.escolaridade,
        experiencias: experiencias.map((e) => ({
          estabelecimento: e.estabelecimento,
          id_profissao: e.profissao.id,
          id_tempo_experiencia: e.tempo_experiencia.id,
        })),
        interesses,
        tipo_estabelecimento: [camposInput.tipo_estabelecimento],
        idiomas: camposInput.idiomas,
        obs: camposInput.obs,
      };

      if (!documento) {
        toast.error('Documento obrigatório!');
        setCarregando(false);
        return;
      }

      const formData = new FormData();
      formData.append('formulario', JSON.stringify(body));
      formData.append('documento', documento);
      formData.append('foto_perfil', foto);

      const resposta = await api.post(
        `profissional/new/${loggeduser && loggeduser.id}`,
        formData
      );
      const { id } = resposta.data.result;
      toast.success('Profissional criado com sucesso');
      history.push(`/home/profissionais/editar/${id}`);
    } catch (e) {
      toast.error(e.message);
    }
    setCarregando(false);
  }

  return (
    <Container>
      <Form
        model={modelo}
        onChange={setCamposInput}
        formValue={camposInput}
        onSubmit={(semErro) =>
          semErro ? salvarUsuario() : exibirMensagemErro()
        }
        onError={(err) => {
          erro = err;
        }}
        layout="horizontal"
      >
        <Grid fluid>
          <CamposDadosProfissionalCriacao
            camposInput={camposInput}
            documentoAlterado={setDocumento}
            imagemAlterada={setFoto}
          />

          <CamposEndereco
            camposInput={camposInput}
            atualizarCampos={(camposEndereco) => {
              setCamposInput({ ...camposInput, ...camposEndereco });
            }}
          />
          <Row>
            <Col md={8} sm={24} xs={24}>
              <ListaExperiencias
                experiencias={experiencias}
                setExperiencias={setExperiencias}
              >
                {/* <Panel header={<h4>Habilidades</h4>} bordered collapsible>
                  <ListaHabilidades experiencias={experiencias} />
                </Panel> */}
              </ListaExperiencias>
            </Col>
            <Col md={8} sm={24} xs={24}>
              <AdicionarInteresse
                interessesProf={interesses}
                setInteressesProf={setInteresses}
              />
            </Col>
            <Col md={8} sm={24} xs={24}>
              <CampoObservacoesEComentarios
                camposInput={camposInput}
                atualizarCampos={(camposObs) => {
                  setCamposInput({ ...camposInput, ...camposObs });
                }}
              />
            </Col>
          </Row>
          <Row style={{ margin: '0', marginBottom: '5px' }}>
            <Col sm={6} />
            <Col sm={12}>
              <ButtonFullBlue
                appearance="primary"
                type="submit"
                size="lg"
                loading={carregando}
                block
              >
                Salvar
              </ButtonFullBlue>
            </Col>
            <Col sm={6} />
          </Row>
        </Grid>
      </Form>
      {carregando && <Loader backdrop center />}
    </Container>
  );
}

export default EditarProfissional;
