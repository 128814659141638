import styled from 'styled-components';
import theme from '~/styles/theme';

export const TogglesStatus = styled.div`
  .rs-toggle-checked .rs-toggle-presentation {
    background-color: ${theme.darkGreen};
  }
  .rs-toggle-presentation {
    background-color: ${theme.lightRed};
  }
`;
