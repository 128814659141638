import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'rsuite';

import 'react-image-lightbox/style.css';
import { DarkModeContext } from '../../common/contexts/darkMode';

function VisualizarImagem({ imagem, titulo }) {
  const { toggleMode } = useContext(DarkModeContext);
  return (
    <div style={{ marginTop: '32px' }}>
      <Button
      style={{color: toggleMode ? 'white' : 'black', backgroundColor: toggleMode ? 'gray' : '#D2D2D2'}}
        size="xs"
        disabled={!imagem}
        onClick={() => window.open(imagem.caminho)}
      >
        {titulo}
      </Button>
    </div>
  );
}

VisualizarImagem.propTypes = {
  imagem: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  titulo: PropTypes.string,
};

VisualizarImagem.defaultProps = {
  titulo: 'Visualizar Imagem',
  imagem: '',
};

export default VisualizarImagem;
