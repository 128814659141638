import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow: auto;

  & + div {
    display: flex;
  }

  h2 {
    margin-bottom: 20px;
    font-size: 36px;
  }

  h6 {
    color: #8bc01d;
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  p {
    font-weight: bold;
    font-size: 16px;
  }
`;
