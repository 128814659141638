import React from 'react';
import { Search } from '@rsuite/icons';

import { InputGroup, Input } from 'rsuite';
import { useDebouncedCallback } from 'use-debounce';
import PropTypes from 'prop-types';

export default function SearchInputTable({ setTextoDigitado, place, estilo }) {
  return (
    <InputGroup
      style={estilo != null ? estilo : { width: '300px', marginRight: '15px' }}
    >
      <Input
        placeholder={place == null ? 'Pesquisar' : place}
        width={200}
        onChange={useDebouncedCallback(setTextoDigitado, 500).callback}
      />
      <InputGroup.Button>
        <Search />
      </InputGroup.Button>
    </InputGroup>
  );
}

SearchInputTable.propTypes = {
  setTextoDigitado: PropTypes.func.isRequired,
  place: PropTypes.func.isRequired,
  estilo: PropTypes.func.isRequired,
};
