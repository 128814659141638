/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Table, Toggle } from 'rsuite';
import { TogglesFav, TogglesBloq } from '../styles';

const { Cell } = Table;

function CelulaAcao({ rowData, onFavoritar, onBloquear, ...props }) {
  return (
    <Cell {...props}>
      <TogglesFav>
        {onFavoritar && (
          <Toggle
            checked={rowData.favorito}
            onChange={() => onFavoritar(rowData)}
          />
        )}
      </TogglesFav>
      <TogglesBloq>
        {onBloquear && (
          <Toggle
            checked={rowData.bloqueado}
            onChange={() => onBloquear(rowData)}
          />
        )}
      </TogglesBloq>
    </Cell>
  );
}

CelulaAcao.propTypes = {
  rowData: PropTypes.object,
  onBloquear: PropTypes.func,
  onFavoritar: PropTypes.func,
  dataKey: PropTypes.string.isRequired,
};

CelulaAcao.defaultProps = {
  rowData: {},
  onFavoritar: null,
  onBloquear: null,
};

export default CelulaAcao;
