/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-prop-types */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { Check, Plus, Trash } from '@rsuite/icons';

import { IconButton, Panel } from 'rsuite';

import api from '~/services/api';
import { Conta } from './styles';
import AdicionarConta from '../AdicionarConta';
import { DarkModeContext } from '../../../../common/contexts/darkMode';

function ListaDadosBancarios({
  idProfissional,
  dadosBancarios,
  onListaAlterada,
  children,
}) {
  const [adicionarContaDialog, setAdicionarContaDialog] = useState(false);

  const { toggleMode } = useContext(DarkModeContext);

  async function adicionarConta(conta) {
    setAdicionarContaDialog(false);
    const newConta = { ...conta, cpf: conta.cpf_cnpj }
    try {
      await api.post(`/dados-bancarios/${idProfissional}`, newConta);
      toast.success('Conta criada');
      onListaAlterada();
    } catch (e) {
      toast.error(e.message);
    }
  }

  async function removerConta(conta) {
    try {
      await api.delete(`/dados-bancarios/${conta.id}`);
      toast.success('Conta removida');
      onListaAlterada();
    } catch (e) {
      toast.error(e.message);
    }
  }

  async function salvarContaFavorita(conta) {
    try {
      await api.put(`/salvar-conta/${conta.id}/${idProfissional}`);
      toast.success('Conta salva');
      onListaAlterada();
    } catch (e) {
      toast.error(e.message);
    }
  }

  return (
    <Panel
      header={<h4>Dados Bancários</h4>}
      eventKey="123"
      bordered
      collapsible
      defaultExpanded
    >
      <div
        style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}
      >
        <h6>Adicionar Conta</h6>
        <IconButton
          style={{ marginTop: '0' }}
          icon={<Plus />}
          onClick={(e) => {
            e.preventDefault();
            setAdicionarContaDialog(true);
          }}
        />
      </div>
      {dadosBancarios &&
        dadosBancarios.map((conta) => (
          <Conta key={conta.id || conta.conta} selecionado={conta.selecionado}>
            <div>
              <h5 style={{color: toggleMode && 'white' }}>Conta: {conta.conta}</h5>
              <p>
                Banco: {conta.banco} <br />
                Cpf/Cnpj: {conta.cpf} <br />
                Agência: {conta.agencia} | Dígito {conta.digito} <br />
                Tipo de conta: {conta.tipo_conta} <br />
                Data: {format(new Date(conta.created_at), 'dd/MM/yyyy hh:mm')}
                <br />
              </p>
            </div>
            <div>
              {conta.selecionado || (
                <IconButton
                  onClick={() => {
                    salvarContaFavorita(conta);
                  }}
                  icon={<Check />}
                  circle
                  size="md"
                />
              )}

              <IconButton
                onClick={() => removerConta(conta)}
                icon={<Trash />}
                circle
                size="md"
              />
            </div>
          </Conta>
        ))}
      <AdicionarConta
        exibir={adicionarContaDialog}
        fechar={() => setAdicionarContaDialog(false)}
        salvar={adicionarConta}
      />
      {children}
    </Panel>
  );
}

ListaDadosBancarios.propTypes = {
  idProfissional: PropTypes.string,
  dadosBancarios: PropTypes.array.isRequired,
  onListaAlterada: PropTypes.func.isRequired,
  children: PropTypes.arrayOf(PropTypes.element),
};

ListaDadosBancarios.defaultProps = {
  children: [],
  idProfissional: ""
};

export default ListaDadosBancarios;
