/* eslint-disable prettier/prettier */
/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Column, CardRow, Button1, Button2, Card } from './styles';
import theme from '../../../../../styles/theme';
import { DarkModeContext } from '../../../../../common/contexts/darkMode';

export function CardConfirmacao({
  toDoOrDone,
  toDo,
  done,
  setToDoOrDone,
}) {
  const { toggleMode } = useContext(DarkModeContext)

  function clickCheckin(n) {
    return toDoOrDone === n ? setToDoOrDone(0) : setToDoOrDone(n);
  }


  return (
    <Card darkMode={toggleMode}>
      <Column resizable>
        <h4 style={{color: toggleMode && 'gray'}}>Confirmações</h4>
        <CardRow>
          <Column resizable>
            <p style={{color: toggleMode && 'gray'}}>Feito</p>
            <Button1
              toDoOrDone={toDoOrDone}
              numberColor='#1675e0'
              onClick={ () => clickCheckin(1)}
            >
              {done}
            </Button1>
          </Column>
          <Column resizable>
            <p style={{color: toggleMode && 'gray'}}>A Fazer</p>
            <Button2
              toDoOrDone={toDoOrDone}
              numberColor={theme.darkGray}
              onClick={() => clickCheckin(2)}
              darkMode={toggleMode}
            >
              {toDo}
            </Button2>
          </Column>
        </CardRow>
      </Column>
    </Card>
  );
}

CardConfirmacao.propTypes = {
  toDoOrDone: PropTypes.string.isRequired,
  toDo: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
  setToDoOrDone: PropTypes.func.isRequired,
};
