import styled from 'styled-components';
import { Modal } from 'rsuite';

const { Body } = Modal;

const ModalBody = styled(Body)`
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &&::-webkit-scrollbar-thumb {
    background-color: grey;
    border-radius: 5px;
  }

  padding-right: 5px;
`;

export default ModalBody;
