/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Panel, Loader, FlexboxGrid, Col, Form } from 'rsuite';

import InputMascara from '~/components/InputMascara';

import api from '~/services/api';
import { ResponsiveFormTextArea } from '~/components/ResponsiveForm';

function CampoObservacoesEComentarios({ atualizarCampos, camposInput }) {
  const [carregando, setCarregando] = useState(false);
  const [disabled, setDisabled] = useState(true);


  return (
    <Panel
      header={
        <div style={{ display: 'flex' }}>
          <h4>Observações e Comentários</h4> {carregando && <Loader size="md" />}
        </div>
      }
      bordered
      collapsible
      defaultExpanded
    >
      <FlexboxGrid justify="space-around" style={{ textAlignLast: 'start' }}>
        <FlexboxGrid.Item componentClass={Col} colspan={24} md={8}>
          <Form.Group>
            <ResponsiveFormTextArea
              name="obs"
              value={camposInput.obs || ''}
              disabled={carregando}
            />
          </Form.Group>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </Panel>
  );
}

CampoObservacoesEComentarios.propTypes = {
  atualizarCampos: PropTypes.func.isRequired,
  camposInput: PropTypes.object,
};

export default CampoObservacoesEComentarios;
