/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import EditIcon from '@rsuite/icons/Edit';
import TrashIcon from '@rsuite/icons/Trash';
import NoticeIcon from '@rsuite/icons/Notice';
import CheckRoundIcon from '@rsuite/icons/CheckRound';

import { Table, IconButton, Divider, ButtonToolbar } from 'rsuite';

const { Cell } = Table;

function CelulaAcaoPedidos({ rowData, onEditar, onDeletar, ...props }) {

  const fundo = (data) => {
    let cor;
    if (data.primeiro_pedido === true) {
      cor = '#FFC757';
    } else {
      cor = '';
    }
    return cor;
  };

  const fonte = (data) => {
    let cor;
    if (data.primeiro_pedido === true) {
      cor = '#000000';
    } else {
      cor = '';
    }
    return cor;
  };

  const visualizadoStatus = rowData && rowData.visualizado && rowData.visualizado.some((i) => i === false);
  const disabled = true;
  return (
    <Cell
      {...props}
      style={{
        padding: '5px',
        background: fundo(rowData),
        color: fonte(rowData),
      }}
    >
      {onEditar && (
        <ButtonToolbar>
          <IconButton
            appearance="subtle"
            style={{ color: fonte(rowData) }}
            onClick={() => onEditar(rowData)}
            icon={<EditIcon />}
          />
          <IconButton
            style={{ backgroundColor: 'transparent' }}
            icon={visualizadoStatus ? <NoticeIcon /> : <CheckRoundIcon />}
          />
        </ButtonToolbar>
      )}
      {onEditar && onDeletar && <Divider vertical />}
      {onDeletar && (
        <IconButton
          appearance="subtle"
          onClick={() => onDeletar(rowData)}
          color="red"
          icon={<TrashIcon />}
        />
      )}
    </Cell>
  );
}

CelulaAcaoPedidos.propTypes = {
  // rowData: PropTypes.objectOf,
  onEditar: PropTypes.func,
  onDeletar: PropTypes.func,
  dataKey: PropTypes.string.isRequired,
};

CelulaAcaoPedidos.defaultProps = {
  // rowData: {},
  onDeletar: null,
  onEditar: null,
};

export default CelulaAcaoPedidos;
