/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import { Plus, RemindFill } from '@rsuite/icons';

import {
  Table,
  Button,
  FlexboxGrid,
  Modal,
  Form,
  IconButton,
  Schema,
} from 'rsuite';
import { toast } from 'react-toastify';

import { Container } from '~/styles/tabela';

import api from '~/services/api';
import {
  CelulaEditavel,
  CelulaAcao,
} from '~/components/Celulas/components/CelulaEditavel';
import { TabelaSemPaginacao } from '~/components/TabelaSemPaginacao';
import SearchTable from '../components/SearchTable';
import { ButtonFullBlue } from '../../../components/Buttons/ButtonFullBlue/styles';

const { HeaderCell, Column } = Table;

const { StringType } = Schema.Types;

const modelo = Schema.Model({
  dia: StringType().isRequired('Dia Obrigatório'),
});

function DiaFuncionamento() {
  const [dados, setDados] = useState({
    listaInicial: [],
    diaFuncionamentoLista: [],
    diaFuncionamentoListaFiltrada: [],
    filtro: '',
  });
  const [carregando, setCarregando] = useState(true);
  const [apagarDiaDialog, setApagarDiaDialog] = useState({
    exibir: false,
    dia: {},
  });
  const [adicionarDiaFuncionamentoDialog, setAdicionarDiaFuncionamentoDialog] =
    useState(false);
  const [camposInput, setCamposInput] = useState({});

  async function buscarDiasFuncionamento() {
    const resposta = await api.get('dia-funcionamento');
    const resposta1 = await api.get('dia-funcionamento');
    const lista = resposta.data;
    setDados({
      listaInicial: resposta1.data,
      diaFuncionamentoLista: lista,
      diaFuncionamentoListaFiltrada: lista,
    });
    setCarregando(false);
  }

  useEffect(() => {
    buscarDiasFuncionamento();
  }, []);

  function filtrarLista(texto) {
    setDados({
      ...dados,
      diaFuncionamentoListaFiltrada: dados.diaFuncionamentoLista.filter(
        (habilidade) =>
          habilidade.dia
            .toString()
            .toUpperCase()
            .includes(texto ? texto.toUpperCase() : '')
      ),
      filtro: texto,
    });
  }

  function abrirApagarDialog(habilidade) {
    setApagarDiaDialog({
      exibir: true,
      dia: habilidade,
    });
  }

  function fecharApagarDialog() {
    setApagarDiaDialog({
      exibir: false,
      dia: {},
    });
  }

  async function excluirDiaFuncionamento() {
    try {
      await api.delete(`/dia-funcionamento/${apagarDiaDialog.dia.id}`);
      toast.success('Dia Funcionamento excluido com sucesso');
      fecharApagarDialog();
      buscarDiasFuncionamento();
    } catch (e) {
      toast.error(e.message);
    }
  }

  async function adicionarDiaFuncionamento() {
    try {
      setCarregando(true);
      await api.post(`/dia-funcionamento`, camposInput);
      toast.success('Dia funcionamento criado com sucesso');
      setCarregando(false);
      setAdicionarDiaFuncionamentoDialog(false);
      buscarDiasFuncionamento();
    } catch (e) {
      toast.error(e.message);
    }
  }

  function alterarTextoCelula(id, key, value) {
    const novaLista = Object.assign([], dados.diaFuncionamentoLista);
    novaLista.find((item) => item.id === id)[key] = value;
    setDados({ ...dados, diaFuncionamentoLista: novaLista });
    filtrarLista(dados.filtro);
  }

  async function alterarEstadoCelula(id) {
    const novaLista = Object.assign([], dados.diaFuncionamentoLista);
    const item = novaLista.find((dia) => dia.id === id);
    const itemInicial = dados.listaInicial.find((dia) => dia.id === id);
    if (item.status === 'EDIT' && item.dia !== itemInicial.dia) {
      try {
        setCarregando(true);
        await api.put(`dia-funcionamento/${id}`, item);
        toast.success('Dia funcionamento atualizado com sucesso');
        buscarDiasFuncionamento();
      } catch (error) {
        toast.error(error.message);
      }
      setCarregando(false);
    } else {
      item.status = item.status ? null : 'EDIT';
      setDados({ ...dados, diaFuncionamentoLista: novaLista });
      filtrarLista(dados.filtro);
    }
  }

  return (
    <Container>
      <h2>Dias de Funcionamento</h2>
      <FlexboxGrid justify="start" style={{ gap: '10px' }}>
        <SearchTable estilo={{ width: '300px' }} filtrarLista={filtrarLista} />
        <IconButton
          style={{ marginTop: '0' }}
          icon={<Plus />}
          onClick={() => {
            setAdicionarDiaFuncionamentoDialog(true);
          }}
        />
      </FlexboxGrid>
      <FlexboxGrid style={{ width: '100%' }}>
        <TabelaSemPaginacao
          carregando={carregando}
          dados={dados.diaFuncionamentoListaFiltrada}
        >
          <Column resizable width={240} sortable>
            <HeaderCell>Dia Funcionamento</HeaderCell>
            <CelulaEditavel dataKey="dia" onChange={alterarTextoCelula} />
          </Column>

          <Column resizable width={200}>
            <HeaderCell>Editar</HeaderCell>
            <CelulaAcao
              dataKey="id"
              onClick={alterarEstadoCelula}
              onDelete={abrirApagarDialog}
            />
          </Column>
        </TabelaSemPaginacao>
      </FlexboxGrid>

      <Modal
        backdrop="static"
        open={adicionarDiaFuncionamentoDialog}
        onClose={() => setAdicionarDiaFuncionamentoDialog(false)}
        size="xs"
      >
        <Form
          model={modelo}
          onChange={setCamposInput}
          onSubmit={(status) => status && adicionarDiaFuncionamento()}
        >
          <Modal.Header>
            <h3>Adicionar Dia de Funcionamento</h3>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.ControlLabel>Dia Funcionamento</Form.ControlLabel>
              <Form.Control name="dia" />
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <Button
              onClick={() => setAdicionarDiaFuncionamentoDialog(false)}
              appearance="subtle"
            >
              Cancelar
            </Button>
            <ButtonFullBlue type="submit" appearance="primary">
              Adicionar
            </ButtonFullBlue>
          </Modal.Footer>
        </Form>
      </Modal>

      <Modal
        backdrop="static"
        open={apagarDiaDialog.exibir}
        onClose={fecharApagarDialog}
        size="xs"
      >
        <Modal.Header>
          <h3>Apagar Dia de Funcionamento</h3>
        </Modal.Header>
        <Modal.Body>
          Você realmente deseja apagar este dia de funcionamento? <br /> <br />
          id: {apagarDiaDialog.dia.id} <br />
          dia: {apagarDiaDialog.dia.dia} <br />
          Essa ação é irreversível{' '}
          <RemindFill
            style={{
              color: '#ffb300',
              fontSize: 24,
            }}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={fecharApagarDialog} appearance="subtle">
            Cancelar
          </Button>
          <ButtonFullBlue
            onClick={() => excluirDiaFuncionamento()}
            appearance="primary"
          >
            Deletar
          </ButtonFullBlue>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default DiaFuncionamento;
