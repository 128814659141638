import firebase from 'firebase';

const firebaseConfig = {
  apiKey: 'AIzaSyD9jrkBAvnqzd3-GEj1iiF0FXpbLaDQGcc',
  authDomain: 'switchapp-9cd57.firebaseapp.com',
  databaseURL: 'https://switchapp-9cd57.firebaseio.com',
  projectId: 'switchapp-9cd57',
  storageBucket: 'switchapp-9cd57.appspot.com',
  messagingSenderId: '366045938836',
  appId: '1:366045938836:web:158b50ccd4ef64a417a081',
  measurementId: 'G-FHV7QSY86E',
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// firebase.firestore().collection('').doc().onSnapshot(function(message) {
//   document.getElementById('').innerHTML = message.data().
// })

export default firebase;
