export function logarRequesicao(email, senha) {
  return {
    type: '@autenticacao/LOGAR_REQUISICAO',
    payload: { email, senha },
  };
}

export function logarSucesso(token) {
  return {
    type: '@autenticacao/LOGAR_SUCESSO',
    payload: { token },
  };
}

export function logarFalha() {
  return {
    type: '@autenticacao/LOGAR_FALHA',
  };
}

export function deslogar() {
  return {
    type: '@autenticacao/DESLOGAR',
  };
}

export function retirarCarregamento() {
  return {
    type: '@autenticacao/RETIRAR_CARREGAMENTO',
  };
}
