import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';

const InputMask = ({ onChange, ...rest }) => {
  return (
    <MaskedInput
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      style={{ width: '100%' }}
      className="rs-input"
      onChange={(event) => {
        const valor = event.target.value.replace(/[^a-z0-9-.()/ ]/g, '');
        onChange(valor);
      }}
    />
  );
};

InputMask.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default InputMask;
