import styled from 'styled-components';

export const Input = {
  width: '300px',
};

export const Container = styled.div`
  width: 100%;
  padding: 20px;
  margin: 10px 10px 20px 10px;
  display: flex;
  flex-direction: column;
  overflow: auto;

  & + div {
    display: flex;
  }

  h2 {
    margin-bottom: 20px;
    font-size: 36px;
  }
`;
