/* eslint-disable react/forbid-prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { format, parseISO, eachDayOfInterval } from 'date-fns';
import PropTypes from 'prop-types';
import { Modal, Form, Button, Schema, Drawer, Table, FlexboxGrid } from 'rsuite';
import { useDispatch, useSelector } from 'react-redux';
import { importarProfissionaisAceitos } from '~/store/modulos/pedido/actions';
import { TabelaSemPaginacao } from '~/components/TabelaSemPaginacao';
import { toast } from 'react-toastify';
import api from '../../../../services/api';

const { StringType } = Schema.Types;

const { Cell, Column, HeaderCell } = Table;


const modelo = Schema.Model({
  numero_registro: StringType().isRequired('Profissional Obrigatório'),
});

function ImportarMelhoresNomesDialog({ exibir, registro, onFechar }) {
  const camposInput = useSelector((state) => state.pedido)
  const [carregando, setCarregando] = useState(true);
  const [listaMelhoresNomes, setListaMelhoresNomes] = useState();
  const [dados, setDados] = useState({
    nomeFantasia: '',
    funcao: '',
    data: '',
    horario: ''
  });

  const idProfissao = registro?.profissao?.id
  const idCidade = camposInput.pedido?.estabelecimento?.endereco?.bairro?.cidade?.id
  const idEstabelecimento = camposInput.pedido?.estabelecimento?.id




  useEffect(() => {
    const buscarMelhoresNomes = async () => {
      setCarregando(true);
    try {
      if(!idProfissao || !idEstabelecimento || !idCidade) {
        return
      }
      const resposta = await api.get(`registros/melhores-nomes`, {
        params: {
          id_profissao: idProfissao,
          id_estabelecimento: idEstabelecimento,
          id_cidade: idCidade
        },
      });
      setListaMelhoresNomes(resposta.data.lista.map((item) => ({
        ...item,
        aproveitamento: `${Number(item.aproveitamento) * 100 }%`
      })));
      setCarregando(false);
      setDados({
        nomeFantasia: camposInput.pedido?.estabelecimento?.nome_fantasia,
        funcao: camposInput?.registros[0]?.profissao?.profissao,
        data: format(parseISO(camposInput?.registros[0]?.data), 'dd/LL/yyyy'),
        horario: camposInput?.camposInput?.hora_inicio_texto_format
      })
    } catch (e) {
      toast.error(e.message);
      setCarregando(false);
    }
    }
    buscarMelhoresNomes()
  }, [
    idProfissao,
    idEstabelecimento,
    idCidade
  ])

  return (
    <Drawer open={exibir} onClose={onFechar} full>

        <Drawer.Header>
          <Drawer.Title>Melhores nomes para a vaga | {dados.nomeFantasia} | {dados.funcao && dados.funcao} | {dados.data} | {dados.horario}H
          </Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
        <FlexboxGrid style={{ width: '840px' }}>
        {listaMelhoresNomes &&
          <TabelaSemPaginacao
          carregando={carregando}
          dados={listaMelhoresNomes && listaMelhoresNomes}
        >
         <Column resizable width={240} sortable>
          <HeaderCell>Nome Profissional</HeaderCell>
          <Cell dataKey="nome" />
        </Column>
        <Column resizable width={150} sortable>
          <HeaderCell>Avaliação Média</HeaderCell>
          <Cell dataKey="avaliacao" />
        </Column>
        <Column resizable width={150} sortable>
          <HeaderCell>Shifts</HeaderCell>
          <Cell dataKey="shift" />
        </Column>
        <Column resizable width={150} sortable>
          <HeaderCell>Aproveitamento</HeaderCell>
          <Cell dataKey="aproveitamento" />
        </Column>
        <Column resizable width={150} sortable>
          <HeaderCell>Bairro</HeaderCell>
          <Cell dataKey="bairro" />
        </Column>
        </TabelaSemPaginacao>
}
        </FlexboxGrid>
        </Drawer.Body>
    </Drawer>
  );
}

ImportarMelhoresNomesDialog.propTypes = {
  exibir: PropTypes.bool.isRequired,
  onFechar: PropTypes.func.isRequired,
  registro: PropTypes.object.isRequired,
};

export default ImportarMelhoresNomesDialog;
