import styled from 'styled-components';
import theme from './theme';

export const Container = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  

  & + div {
    display: flex;
  }

  h2 {
    margin-bottom: 20px;
    font-size: 36px;
  }
`;
