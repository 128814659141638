/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-prop-types */
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Plus, Trash } from '@rsuite/icons';
import FileDownloadIcon from '@rsuite/icons/FileDownload';

import { IconButton, Panel, Button } from 'rsuite';
import api from '~/services/api';
import { Manual } from './styles';

import AdicionarCurriculo from '../AdicionarCurriculo';
import ExcluirCurriculo from '../ExcluirCurriculo';
import { DarkModeContext } from '../../../../common/contexts/darkMode';

function ListaCurriculo({
  idProfissional,
  curriculo,
  onListaAlterada,
  children,
}) {
  const [adicionarCurriculoDialog, setAdicionarCurriculoDialog] =
    useState(false);
  const [excluirCurriculoDialog, setExcluirCurriculoDialog] = useState({
    exibir: false,
    curriculo: {},
  });

  const { toggleMode } = useContext(DarkModeContext);

  async function adicionarCurriculo({ arquivo }) {
    setAdicionarCurriculoDialog(false);
    try {
      const formData = new FormData();
      formData.append('curriculo', arquivo);
      await api.post(`/profissional/curriculo/${idProfissional}`, formData);
      toast.success('Curriculo Adicionado');
      onListaAlterada();
    } catch (e) {
      toast.error(e.message);
    }
  }

  return (
    <Panel
      header={<h4>Curriculo</h4>}
      eventKey="123"
      bordered
      collapsible
      defaultExpanded
    >
      <div
        style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}
      >
        <Button
          style={{ marginTop: '0' }}
          onClick={(e) => {
            e.preventDefault();
            setAdicionarCurriculoDialog(true);
          }}
        >
          <h6>
            Adicionar Curriculo <Plus />
          </h6>
        </Button>
      </div>
      {curriculo && (
        <Manual key={curriculo.arquivo.id}>
          <div>
            <h5 style={{color: toggleMode && 'white' }}>Curriculo:</h5>
            {curriculo.arquivo.arquivo}
          </div>
          <div>
            <IconButton
              style={{ marginTop: '0' }}
              onClick={() => {
                window.open(curriculo.arquivo.caminho);
              }}
              icon={<FileDownloadIcon />}
              circle
              size="md"
            />
            <IconButton
              style={{ marginTop: '0' }}
              onClick={() =>
                setExcluirCurriculoDialog({
                  exibir: true,
                  curriculo,
                })
              }
              icon={<Trash />}
              circle
              size="md"
            />
          </div>
        </Manual>
      )}
      <AdicionarCurriculo
        exibir={adicionarCurriculoDialog}
        fechar={() => setAdicionarCurriculoDialog(false)}
        salvar={adicionarCurriculo}
      />
      <ExcluirCurriculo
        exibir={excluirCurriculoDialog.exibir}
        curriculo={excluirCurriculoDialog.curriculo}
        fechar={() => {
          setExcluirCurriculoDialog({
            exibir: false,
            curriculo: {},
          });
        }}
        atualizar={onListaAlterada}
      />
      {children}
    </Panel>
  );
}

ListaCurriculo.propTypes = {
  curriculo: PropTypes.array.isRequired,
  onListaAlterada: PropTypes.func.isRequired,
  children: PropTypes.arrayOf(PropTypes.element),
  idProfissional: PropTypes.number.isRequired,
};

ListaCurriculo.defaultProps = {
  children: [],
};

export default ListaCurriculo;
