/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable prettier/prettier */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { isAfter, isBefore, subDays, format, parseISO } from 'date-fns';
import {
  CheckboxGroup,
  Checkbox,
  CheckPicker,
  TagPicker,
  DatePicker,
  Button,
  AutoComplete,
  SelectPicker,
  InputGroup,
  Form,
  Input,
  FlexboxGrid,
  Col,
  ButtonGroup,
  RadioGroup,
  Radio,
  Dropdown,
  IconButton,
} from 'rsuite';

import { LightbulbFlash } from '@styled-icons/remix-line/LightbulbFlash';
import { Bulb } from '@styled-icons/evaicons-solid/Bulb';
import { Shake } from '@styled-icons/evaicons-solid/Shake';
import { Pointer, PointerDimensions } from '@styled-icons/evil/Pointer';
import { PlusSquare } from '@styled-icons/fa-solid/PlusSquare';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import MultiDatePicker from '@rsuite/multi-date-picker';
import InputMascara from '~/components/InputMascara';
import api from '~/services/api';
import theme from '~/styles/theme';
import { FiltroExperiencias } from '~/components/FiltroExperiencias';

import {
  atualizarIdEstabelecimento,
  autorizarPedido,
  confirmarPedido,
  cancelarPedido,
} from '~/store/modulos/pedido/actions';
import {
  ResponsiveForm,
  ResponsiveFormTextArea,
} from '~/components/ResponsiveForm';
import useSearchNames from '~/services/useSearchNames';
import formatCurrency from '~/utils/formatCurrency';
import * as Styled from './style';
import { HidePicker } from './style';

const autoCorrectedDatePipe = createAutoCorrectedDatePipe('HH:MM');

function CamposEdicaoRecrutamento({
  inputsDadosRecrutamento,
  setInputsDadosRecrutamento,
  pedidoAntigo,
  setPedidoAntigo,
  editarPedido,
  finalizarPedido,
  children,
  fecharEditarPedido,
  atualizarCamposInput,
}) {
  const [pedidos, setPedidos] = useState([]);

  const dispatch = useDispatch();
  const { camposInput } = useSelector((state) => {
    return state.pedido;
  });

  const [profissoes, setProfissoes] = useState([]);
  const [uniformes, setUniformes] = useState([]);
  const [beneficios, setBeneficios] = useState([]);
  const [nomesEstabelecimentos, setNomesEstabelecimentos] = useState([]);
  const [valueChecked, setValueChecked] = useState([1, 4]);

  const locale = {
    sunday: 'Dom',
    monday: 'Seg',
    tuesday: 'Ter',
    wednesday: 'Qua',
    thursday: 'Qui',
    friday: 'Sex',
    saturday: 'Sab',
    ok: 'Ok',
    today: 'Hoje',
    yesterday: 'Ontem',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos',
  };

  const layoutResponsivo = { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }

  const buscarNomesEstabelecimentos = useSearchNames(
    setNomesEstabelecimentos,
    'estabelecimentos'
  );

  useEffect(() => {
    const buscarDadosApi = async () => {
      try {
        const resposta = await api.get('profissao');
        setProfissoes(resposta.data);
      } catch (error) {
        toast.error(error.message);
      }
    };

    buscarDadosApi();
  }, []);

  useEffect(() => {
    const buscarDadosApi = async () => {
      try {
        const resposta = await api.get('beneficios');
        setBeneficios(resposta.data);
      } catch (error) {
        toast.error(error.message);
      }
    };

    buscarDadosApi();
  }, []);

  async function alterarStatus(funcao) {
    fecharEditarPedido();
  }

  const verificaBeneficio = (b) => {
    let status = false;
    inputsDadosRecrutamento.id_beneficios.map((beneficio) => {
      const response = beneficio === b;
      if (response) {
        status = true;
      }
      return response;
    });
    return status;
  };

  const handleCheck = (v, checked) =>
    setValueChecked(checked.filter((item) => item === v));

  return (
    <Styled.Panel
      header={
        <Styled.Header>
          <h5>Dados Recrutamento</h5>
        </Styled.Header>
      }
    >
      {/* ----------------------------separação-------------------- */}

      <Styled.Container>
        <Styled.Content style={{ width: '40%' }}>
          <Form.Group style={{ width: '100%' }}>
            <ResponsiveForm
              label="Nome do Cliente"
              name="nome"
              accepter={AutoComplete}
              data={nomesEstabelecimentos.map((p) => ({
                label: p.nome_fantasia,
                value: p.nome_fantasia,
                id: p.id,
              }))}
              onSelect={async (value, item) => {
                setTimeout(() => {
                  setInputsDadosRecrutamento(() => ({
                    ...inputsDadosRecrutamento,
                    id_estabelecimento: item.id,
                    nome: value,
                  }));
                }, 1000);
              }}
              value={inputsDadosRecrutamento.nome}
              onChange={buscarNomesEstabelecimentos}
            />
          </Form.Group>
          <div style={{ display: 'flex', marginTop: '-10px' }}>
            <Form.Group style={{ width: '60%', ...layoutResponsivo }}>
              <ResponsiveForm
                style={{ width: '100%' }}
                label="Data"
                format="dd/MM/yy"
                name="data"
                keepCharPositions
                value={inputsDadosRecrutamento.data || ''}
              />
            </Form.Group>
            <Form.Group
              style={{ ...layoutResponsivo, width: '100%', marginLeft: '10px' }}
            >
              <ResponsiveForm
                style={{ width: '100%' }}
                label="Serviço"
                name="servico"
                placeholder="Selecione"
                accepter={SelectPicker}
                onSelect={(value) => setInputsDadosRecrutamento({ ...inputsDadosRecrutamento, servico: value })}
                value={inputsDadosRecrutamento.servico}
                data={profissoes.map((profissao) => ({
                  label: profissao.profissao,
                  value: profissao.id,
                }))}
              />
            </Form.Group>
          </div>

          <div style={{ display: 'flex', marginTop: '-10px' }}>
            <Form.Group
              style={{ width: '60%', ...layoutResponsivo }}
            >
              <ResponsiveForm
                label="Previsão Início"
                name="previsao_inicio"
                style={{ width: '100%' }}
                format="dd/MM/yy"
                value={inputsDadosRecrutamento.previsao_inicio || ''}
              />
            </Form.Group>
            <Form.Group
              style={{ width: '100%', marginLeft: '10px', ...layoutResponsivo }}
            >
              <ResponsiveForm
                name="tipo_contrato"
                label="Tipo de Contrato"
                placeholder="Selecione"
                searchable={false}
                accepter={SelectPicker}
                style={{ width: '100%' }}
                value={inputsDadosRecrutamento.tipo_contrato}
                data={[
                  { value: 'CLT', label: 'CLT' },
                  { value: 'Intermitente', label: 'Intermitente' },
                  { value: 'MEI', label: 'MEI' },
                  { value: 'Contrato Parcial', label: 'Contrato Parcial' },
                  { value: 'MEI e CLT', label: 'MEI e CLT' },
                ]}
                onChange={(value) => setInputsDadosRecrutamento(
                  { ...inputsDadosRecrutamento, tipo_contrato: value }
                )}
              />
            </Form.Group>
          </div>
          <div style={{ display: 'flex', marginTop: '-10px' }}>
            <Form.Group
              style={{ width: '100%', ...layoutResponsivo }}
            >
              <ResponsiveForm
                label="Jornada"
                name="jornada"
                placeholder="Informe a jornada..."
                value={inputsDadosRecrutamento.jornada}
              />
            </Form.Group>
            <Form.Group
              style={{ width: '100%', marginLeft: '10px', ...layoutResponsivo }}
            >
              <ResponsiveForm
                label="Quantidade"
                name="quantidade"
                placeholder="Informe a quantidade..."
                value={inputsDadosRecrutamento.quantidade}
                type="number"
              />
            </Form.Group>
          </div>
          <div style={{ display: 'flex', marginTop: '-10px' }}>
            <Form.Group
              style={{ width: '100%', ...layoutResponsivo }}
            >
              <ResponsiveForm
                label="Salário"
                name="salario"
                placeholder="Informe o valor..."
                value={inputsDadosRecrutamento.salario}
              />
            </Form.Group>
            <Form.Group
              style={{ width: '100%', marginLeft: '10px', ...layoutResponsivo }}
            >
              <ResponsiveForm
                label="Gorjeta"
                name="gorjeta"
                placeholder="Informe o valor..."
                value={inputsDadosRecrutamento.gorjeta}
              />
            </Form.Group>
          </div>
          <Form.Group style={{ marginTop: '-10px' }}>
            <ResponsiveFormTextArea
              label="Experiência"
              name="experiencia"
              rows={4}
              componentClass="textarea"
              value={inputsDadosRecrutamento.experiencia}
            />
          </Form.Group>
        </Styled.Content>

        {/* ----------------------------separação-------------------- */}

        <Styled.Content style={{ width: '30%' }}>
          <Form.Group>
            <CheckboxGroup
              value={inputsDadosRecrutamento.beneficios}
              onChange={(value) =>
                setInputsDadosRecrutamento({
                  ...inputsDadosRecrutamento,
                  beneficios: value,
                })
              }
            >
              <Form.ControlLabel>Benefícios</Form.ControlLabel>

              {beneficios.map((p, index) => {
                return (
                  <Checkbox
                    key={index}
                    name="beneficios"
                    label={p.beneficio}

                    value={p.id}
                    style={{ margin: '-7px' }}
                  > <div
                    style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
                  >
                      {' '}{p.beneficio}
                    </div></Checkbox>
                );
              })}
            </CheckboxGroup>
          </Form.Group>

          <Form.Group>
            <ResponsiveFormTextArea
              label="Perfil"
              name="perfil"
              rows={7}
              componentClass="textarea"
              value={inputsDadosRecrutamento.perfil}
            />
          </Form.Group>
        </Styled.Content>
        {/* ----------------------------separação-------------------- */}
        <Styled.Content style={{ width: '30%' }}>
          <Form.Group>
            <ResponsiveFormTextArea
              label="Descrição do serviço"
              name="observacao"
              rows={4}
              componentClass="textarea"
              value={inputsDadosRecrutamento.observacao}
            />
          </Form.Group>
          <Form.Group>
            <ResponsiveFormTextArea
              label="Anotações"
              name="anotacoes"
              rows={12}
              componentClass="textarea"
              value={inputsDadosRecrutamento.anotacoes}
            />
          </Form.Group>
        </Styled.Content>

        {/* ----------------------------separação-------------------- */}

        {children}
      </Styled.Container>
    </Styled.Panel>
  );
}

CamposEdicaoRecrutamento.propTypes = {
  editarPedido: PropTypes.bool,
  pedidoAntigo: PropTypes.bool,
  finalizarPedido: PropTypes.func,
  // fecharEditarPedido: PropTypes.func.isRequired,
  // atualizarCamposInput: PropTypes.func.isRequired,
  // setPedidoAntigo: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

CamposEdicaoRecrutamento.defaultProps = {
  editarPedido: false,
  pedidoAntigo: false,
  children: [],
  finalizarPedido: () => { },
};

export default CamposEdicaoRecrutamento;
