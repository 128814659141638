import React from 'react'
import { Form, Col, Grid, Panel, Row } from 'rsuite'
import Prop from 'prop-types';
import { ResponsiveForm, ResponsiveFormTextArea } from '../../../../components/ResponsiveForm'
import { Input } from '../../EditarRecrutamento/styles'

export const InputsDadosClienteRecrutamento = ({ inputsDadosRecrutamento }) => {
  return (
    <Panel header={<h5>Dados do Cliente</h5>}>
      <Grid fluid style={{ gap: '10px' }}>
        <Form>
        <Row>
          <Col md={8} sm={24} xs={24}>
            <ResponsiveForm
              label="Nome Fantasia"
              name="nome"
              style={Input}
              readOnly
              value={inputsDadosRecrutamento.nome || ''}
            />
          </Col>
          <Col md={8} sm={24} xs={24}>
            <ResponsiveForm
              label="Email"
              name="email"
              style={Input}
              readOnly
              value={inputsDadosRecrutamento.email || ''}
            />
          </Col>
          <Col md={8} sm={24} xs={24}>
            <ResponsiveForm
              label="Pessoa Responsável"
              name="pessoa_responsavel"
              style={Input}
              readOnly
              value={inputsDadosRecrutamento.pessoa_responsavel || ''}
            />
          </Col>
        </Row>
        <Row>
          <Col md={8} sm={24} xs={24}>
            <ResponsiveForm
              label="Endereço"
              name="endereco"
              style={Input}
              readOnly
              value={inputsDadosRecrutamento.endereco || ''}
            />
          </Col>
          <Col md={8} sm={24} xs={24}>
            <ResponsiveForm
              label="Telefone"
              name="telefone"
              style={Input}
              readOnly
              value={inputsDadosRecrutamento.telefone || ''}
            />
          </Col>
          <Col md={8} sm={24} xs={24}>
            <ResponsiveForm
              label="CNPJ"
              name="cpf_cnpj"
              style={Input}
              readOnly
              value={inputsDadosRecrutamento.cpf_cnpj || ''}
            />
          </Col>
        </Row>
        <Row>
          <Col md={24} sm={24} xs={24}>
            <ResponsiveFormTextArea
              label="Observação"
              name="obs"
              value={inputsDadosRecrutamento.obs || ''}
              disabled
            />
          </Col>
        </Row>
        </Form>
      </Grid>
    </Panel>
  )
}

InputsDadosClienteRecrutamento.propTypes = {
  inputsDadosRecrutamento: Prop.string.isRequired,
}
